import React from 'react';
import {styled} from '@mui/material/styles';

const StyledLabel = styled('label')`
  margin-bottom: 10px;
  display: ${(props) => (props.fullWidth ? 'block' : 'inline-block')};
  font-weight: 500;
`;

const FieldLabel = (props) => {
  const {htmlFor, children, ...rest} = props;

  return (
    <StyledLabel htmlFor={htmlFor} {...rest}>
      {children}
    </StyledLabel>
  );
};

export default FieldLabel;
