import React from 'react';
import NoPermission from './NoPermission';
import SupportMessage from './SupportMessage';

const NoPagePermission = () => {
  return (
    <NoPermission title="You don't have access to this page.">
      <div>It looks like you haven't been given the correct permissions to access this page.</div>
      <SupportMessage />
    </NoPermission>
  );
};
export default NoPagePermission;
