import React, {FC, useContext, useState, useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import StepperForm from '../Form/StepperForm';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {api, put} from '../../utils/Request';
import {InitialSPIVFormValues, ValidSPIVFormValues} from '../../types';
import {
  stepSPIVDetailsValidationSchema,
  stepSPIVValuesValidationSchema,
  stepFinanceDetailsValidationSchema,
} from './validationSchema';
import StepSPIVDetails from './StepSPIVDetails';
import StepSPIVValues from './StepSPIVValues';
import StepSPIVAttachments from './StepSPIVAttachments';
import StepSPIVFinanceDetails from './StepSPIVFinanceDetails';

const formSteps = ['SPIV Details', 'Add SPIV Values', 'Finance Details', 'Attachments'];

const breakPoint = 1840;
const StyledStepperForm = styled(StepperForm)(({theme}) => ({
  '&.PageContentContainer-root': {
    [theme.breakpoints.up(breakPoint)]: {
      width: '94rem',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    minWidth: '80rem',
    width: 'auto',
    display: 'inline-block',
    paddingLeft: '4.5rem',
    paddingRight: '4.5rem',
    marginLeft: '2.25rem',
    marginRight: '2.25rem',
  },
}));

type Props = {
  title: string;
  initialValues: InitialSPIVFormValues;
  indexOverride?: number;
};

const SPIVForm: FC<Props> = ({title, initialValues, indexOverride}) => {
  const navigate = useNavigate();
  const [draftCreationFinished, setDraftCreationFinished] = useState(false);
  const [dealId, setDealId] = useState<string>();
  const {showLoading, hideLoading} = useContext(LoadingContext);

  useEffect(() => {
    // true once, after deal finalisation
    if (dealId && draftCreationFinished) {
      navigate(Consts.RouterPath.SPIVSummary.replace(':id', dealId));
    }
  }, [dealId, draftCreationFinished, navigate]);

  const getStepContent = (
    index: number,
    handleBack: (values: ValidSPIVFormValues) => void,
    handleNext: (values: ValidSPIVFormValues) => void
  ) => {
    const contentIndex = indexOverride ?? index;
    switch (contentIndex) {
      case 0:
        return (
          <StepSPIVDetails
            step={1}
            title={formSteps[index]}
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
            validationSchema={stepSPIVDetailsValidationSchema}
          />
        );
      case 1:
        return (
          <StepSPIVValues
            step={2}
            title={formSteps[index]}
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
            validationSchema={stepSPIVValuesValidationSchema}
            style={{minWidth: '90rem', width: 'calc(100% - 4rem)'}}
          />
        );
      case 2:
        return (
          <StepSPIVFinanceDetails
            step={3}
            title={formSteps[index]}
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
            validationSchema={stepFinanceDetailsValidationSchema}
          />
        );
      case 3:
        return (
          <StepSPIVAttachments
            step={4}
            title={formSteps[index]}
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
          />
        );
      default:
        return null;
    }
  };

  const finishDealCreation = async (id: string) => {
    showLoading();
    try {
      await put(api(Consts.Api.SpivFinishCreation.replace(':id', id)));
      setDraftCreationFinished(true);
      setDealId(id);
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleShowRouteChangeMessage = useCallback(
    (values: InitialSPIVFormValues): boolean =>
      !draftCreationFinished && !!values.id && values.status === Consts.AgreementStatusEnum.Draft,
    [draftCreationFinished]
  );

  return (
    <StyledStepperForm
      title={title}
      initialValues={initialValues}
      onSubmit={async (values) => {
        if (!values.draftMode) {
          await finishDealCreation(values.id);
        } else {
          navigate(Consts.RouterPath.SPIVSummary.replace(':id', values.id));
        }
      }}
      steps={formSteps}
      getStepContent={getStepContent}
      routeChangeMessage="Your SPIV has been saved to My Drafts"
      showRouteChangeMessage={handleShowRouteChangeMessage}
      indexOverride={indexOverride}
    />
  );
};

export default SPIVForm;
