import React, {FC, useCallback, useContext, useState, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import Box from '@mui/material/Box';
import LoadingContext from '../../../app/LoadingContext';
import Consts from '../../../app/Consts';
import {useAppSelector} from '../../../app/store';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {
  MixAndMatchSummaryResponse,
  Config,
  EntityActionType,
  ClaimListResponse,
  ClaimListItemViewModel,
} from '../../../types';
import {api, post, put, get, del} from '../../../utils/Request';
import {isValidDate} from '../../../utils/DateUtils';
import {HeadingComponent} from '../../../components/HeadingComponent';
import {DeleteAgreementOrRebateConfirmModal} from '../../../components/Modal';
import EditAgreementOrRebateConfirmModal from '../../../components/Modal/EditAgreementOrRebateConfirmModal';
import {MixNMatchIcon, EditIcon, DeleteIcon, DuplicateIcon} from '../../../components/Icons';

type HeadingButton = {
  label: string;
  icon: React.ReactNode;
  style: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  role: string;
  disabled?: boolean;
  disabledText?: string;
  isLocked?: boolean;
};

type Props = {
  mixAndMatchData: MixAndMatchSummaryResponse;
  onCancel: (mixAndMatchSummary: MixAndMatchSummaryResponse) => void;
};

export const isMixAndMatchLocked = (configs: Config | undefined, endAt: string): boolean => {
  if (!configs) {
    return false;
  }
  const lockDate = new Date(configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs]);
  const cutoffDate = new Date(
    configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
  );
  if (isValidDate(lockDate) && isValidDate(cutoffDate)) {
    return new Date() >= lockDate && new Date(endAt) < cutoffDate;
  }
  return false;
};

export const shouldConfirmEdit = (claimsResponse: ClaimListResponse | null): boolean => {
  if (!claimsResponse) {
    return false;
  }
  return claimsResponse.data.some((claim: ClaimListItemViewModel) =>
    [
      Consts.ClaimStatusEnum.Raised,
      Consts.ClaimStatusEnum.Invoiced,
      Consts.ClaimStatusEnum.Finalised,
      Consts.ClaimStatusEnum.SentToSupplier,
    ].includes(claim.status)
  );
};

const MixAndMatchSummaryHeading: FC<Props> = ({mixAndMatchData, onCancel}) => {
  const {id, endAt, isDeleted, isDeletable, isCancellable, claimingStatus} = mixAndMatchData;

  const configs = useAppSelector((state) => state.configs.data);
  const navigate = useNavigate();
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const [openCancelConfirmModal, setOpenCancelConfirmModal] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [raisedClaimsResponse, setRaisedClaimsResponse] = useState<ClaimListResponse | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [ctrlKey, setCtrlKey] = useState(false);

  const isCancelled = mixAndMatchData?.claimingStatus === Consts.AgreementStatusEnum.Cancelled;

  const editMixAndMatch = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const routerPath = Consts.RouterPath.EditMixAndMatch.replace(':id', `${id}`);
      if (event.ctrlKey) {
        window.open(routerPath, '_blank');
      } else {
        navigate(routerPath);
      }
    },
    [navigate, id]
  );

  const handleCancel = async () => {
    try {
      showLoading();
      const response: AxiosResponse<MixAndMatchSummaryResponse> = await put(
        api(Consts.Api.MixAndMatchCancel.replace(':id', `${id}`))
      );
      onCancel(response.data);
    } catch (error: any) {
      alertService.alert({
        message: error.message,
        response: error.response,
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleDelete = async () => {
    try {
      showLoading();
      await del(api(Consts.Api.MixAndMatch.replace(':id', `${id}`)));
      navigate(Consts.RouterPath.MyDeals);
    } catch (error: any) {
      alertService.alert({
        message: error.message,
        response: error.response,
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleNavAction = useCallback(
    async (type: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.persist();
      setCtrlKey(event.ctrlKey);
      switch (type) {
        case 'duplicate': {
          try {
            showLoading();
            const response: AxiosResponse<MixAndMatchSummaryResponse> = await post(
              api(Consts.Api.MixAndMatchDuplicate.replace(':id', `${id}`))
            );
            navigate(Consts.RouterPath.EditMixAndMatch.replace(':id', `${response.data.id}`));
          } catch (error: any) {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          } finally {
            hideLoading();
          }
          break;
        }
        case 'edit': {
          try {
            showLoading();
            const response: AxiosResponse<ClaimListResponse> = await get(
              api(Consts.Api.MixAndMatchClaims.replace(':id', `${id}`))
            );
            const data = response.data;
            if (shouldConfirmEdit(data)) {
              setRaisedClaimsResponse(data);
              setOpenEditModal(true);
            } else {
              editMixAndMatch(event);
            }
          } catch (error: any) {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          } finally {
            hideLoading();
          }
          break;
        }
        case 'delete': {
          if (!isDeletable && isCancellable) {
            setOpenCancelConfirmModal(true);
          } else if (isDeletable) {
            setOpenDeleteConfirmModal(true);
          }
          break;
        }
        default:
          break;
      }
    },
    [editMixAndMatch, hideLoading, navigate, id, showLoading, isDeletable, isCancellable]
  );

  const headingButtons: HeadingButton[] = useMemo(() => {
    const buttons: HeadingButton[] = [
      {
        label: 'Duplicate Mix & Match',
        icon: <DuplicateIcon style={{width: '1.3125rem'}} />,
        style: {color: '#626262', fontWeight: 400},
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleNavAction('duplicate', event),
        role: Consts.UserRoleEnum.AddOrUpdateDeals,
      },
    ];
    if (!isDeleted && !isCancelled) {
      buttons.push({
        label: 'Edit Mix & Match',
        icon: <EditIcon style={{width: '1.3125rem'}} />,
        style: {color: '#626262', fontWeight: 400},
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleNavAction('edit', event),
        role: Consts.UserRoleEnum.AddOrUpdateDeals,
        isLocked: isMixAndMatchLocked(configs, endAt),
      });
    }
    if (!isDeleted && !isCancelled && claimingStatus !== Consts.AgreementStatusEnum.Finished) {
      buttons.push({
        label: 'Delete Mix & Match',
        icon: <DeleteIcon style={{width: '1.3125rem'}} />,
        style: {color: '#d0021b', fontWeight: 400},
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleNavAction('delete', event),
        role: Consts.UserRoleEnum.DeleteDeals,
        isLocked: isMixAndMatchLocked(configs, endAt),
        disabled: !isDeletable && !isCancellable,
        disabledText:
          'Mix and Match can’t be deleted or cancelled due to claims advanced past Ready to Process status.',
      });
    }
    return buttons;
  }, [
    isDeletable,
    endAt,
    isDeleted,
    handleNavAction,
    configs,
    isCancellable,
    isCancelled,
    claimingStatus,
  ]);

  return (
    <>
      {isCancelled ? (
        <Box
          sx={{
            width: 'calc(100% + 6.25rem)',
            height: '3rem',
            backgroundColor: '#E02020',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '1.25rem',
            marginLeft: '-3.125rem',
            marginTop: '-3.125rem',
          }}
          data-testid="cancelled-banner"
        >
          Cancelled
        </Box>
      ) : null}
      <HeadingComponent
        headingIcon={<MixNMatchIcon style={{width: '3.75rem'}} />}
        headingLabel="Mix & Match"
        buttons={headingButtons}
        style={{padding: '0rem 0rem 3rem 0rem'}}
      />
      <DeleteAgreementOrRebateConfirmModal
        entityActionType={EntityActionType.MixAndMatch}
        open={openCancelConfirmModal}
        onCancel={() => setOpenCancelConfirmModal(false)}
        onOk={() => {
          setOpenCancelConfirmModal(false);
          handleCancel();
        }}
        cancelText="Go back"
        okText="Confirm and cancel"
        children={
          <>
            This Mix & Match has already started and cannot be deleted. If you proceed it will be
            cancelled and no claims will be created,
            <strong> this cannot be reversed</strong>.
          </>
        }
      />
      <DeleteAgreementOrRebateConfirmModal
        entityActionType={EntityActionType.MixAndMatch}
        open={openDeleteConfirmModal}
        onCancel={() => setOpenDeleteConfirmModal(false)}
        onOk={() => {
          setOpenDeleteConfirmModal(false);
          handleDelete();
        }}
        cancelText="Cancel"
        okText="Confirm and delete"
        children="If you proceed, this Mix and Match will be deleted overnight and cannot be reversed."
      />
      <EditAgreementOrRebateConfirmModal
        entityActionType={EntityActionType.MixAndMatch}
        referenceId={`${id}`}
        ctrlKey={ctrlKey}
        open={openEditModal}
        claimsResponse={raisedClaimsResponse}
        onOk={() => {
          setOpenEditModal(false);
        }}
        onCancel={() => setOpenEditModal(false)}
        modalTitle="Claim advanced past Ready to Process"
        modalSubtitle="There has been at least one claim advanced past Ready to Process against this Mix & Match, some fields will not be editable."
      />
    </>
  );
};

export default MixAndMatchSummaryHeading;
