import * as Yup from 'yup';
import Consts from '../../../app/Consts';
import {isNullish} from '../../../utils';

export const stepMixAndMatchFinanceDetailsValidationSchema = Yup.object().shape({
  financeDetails: Yup.array()
    .of(
      Yup.object().shape({
        claimVendorName: Yup.string().required(Consts.ValidationMessage.Required),
        claimAmount: Yup.number()
          .typeError('Invalid format (use numbers only)')
          .min(0, 'The value must be greater than or equal to 0')
          .test(
            'percentage value less than 100',
            Consts.ValidationMessage.PercentageValue,
            function (value) {
              const claimAmountType = this.resolve(Yup.ref('claimAmountType'));
              if (claimAmountType === Consts.AmountTypeEnum.Percentage) {
                return !isNullish(value) && Number(value) <= 100;
              }
              return true;
            }
          )
          .test('required for ClaimVendor', Consts.ValidationMessage.Required, function (value) {
            const claimOnGroup = this.resolve(Yup.ref('claimOnGroup'));
            if (claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.ClaimVendor) {
              return true;
            } else {
              return !isNullish(value);
            }
          })
          .nullable(),
        claimAmountType: Yup.string()
          .test('required for ClaimVendor', Consts.ValidationMessage.Required, function (value) {
            const claimOnGroup = this.resolve(Yup.ref('claimOnGroup'));
            if (claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.ClaimVendor) {
              return true;
            } else {
              return !isNullish(value);
            }
          })
          .nullable(),
        claimInterval: Yup.string().required(Consts.ValidationMessage.Required),
        claimDaysAfterExpiry: Yup.number()
          .when('claimInterval', function (claimInterval, schema) {
            if (claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry) {
              return Yup.number()
                .required(Consts.ValidationMessage.Required)
                .typeError(Consts.ValidationMessage.NumberOnly)
                .min(0)
                .max(365);
            }
            return schema;
          })
          .nullable(),
        supplierApprovalNumber: Yup.string()
          .test(
            'max length check',
            'Supplier approval number cannot exceed 100 characters',
            function (value?: string) {
              return !value || value.length <= 100;
            }
          )
          .nullable(),
        gstType: Yup.string()
          .test('required for ClaimVendor', Consts.ValidationMessage.Required, function (value) {
            const claimOnGroup = this.resolve(Yup.ref('claimOnGroup'));
            if (claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.ClaimVendor) {
              return true;
            } else {
              return !isNullish(value);
            }
          })
          .nullable(),
      })
    )
    .min(1, 'At least one finance detail must be added to this mix and match deal'),
});
