import Consts from '../app/Consts';

const getClaimFrequency = (agreementType, claimFrequency, claimDaysAfterExpiry) => {
  if (agreementType === Consts.AgreementTypeEnum.Deal) {
    return getDealClaimFrequency(claimFrequency, claimDaysAfterExpiry);
  }
  return getRebateClaimFrequency(claimFrequency);
};

const getDealClaimFrequency = (claimFrequency, claimDaysAfterExpiry) => {
  const frequency = Consts.DealClaimInterval.find((item) => item.value === claimFrequency)?.label;
  if (claimFrequency === Consts.DealClaimIntervalEnum.DaysAfterExpiry) {
    return frequency.replace('Set', claimDaysAfterExpiry);
  }
  return frequency;
};

const getRebateClaimFrequency = (claimFrequency) => {
  return Consts.RebateClaimInterval.find((item) => item.value === claimFrequency)?.label;
};

export {getDealClaimFrequency, getRebateClaimFrequency};
export default getClaimFrequency;
