import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import {FilterListIcon} from '../Icons';
import {styled} from '@mui/material/styles';

const Counter = styled('div')`
  display: flex;
  background-color: #ffec0f;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.9375rem;
`;

const IndicatorContainer = styled(Grid)`
  align-items: center;
  width: auto;
  min-height: 3.0625rem;
  background-color: #fff;
  border: 1px solid #cbd2d8;
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled('div')`
  flex: 1;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
`;

type Props = {
  count: number;
  onClick: () => void;
};

const FiltersIndicator: FC<Props> = ({count, onClick}) => (
  <IndicatorContainer container onClick={onClick} role="button">
    <FilterListIcon />
    <Title>Filters</Title>
    {count ? (
      <Grid item>
        <Counter>{count}</Counter>{' '}
      </Grid>
    ) : null}
  </IndicatorContainer>
);

export default FiltersIndicator;
