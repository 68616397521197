import React, {FC, useContext} from 'react';
import Grid from '@mui/material/Grid';
import {useFormikContext} from 'formik';
import * as Yup from 'yup';
import {AxiosResponse} from 'axios';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import add from 'date-fns/add';
import {post, api, put} from '../../utils/Request';
import {DateType, daysBetweenInTZ} from '../../utils/DateUtils';
import LoadingContext from '../../app/LoadingContext';
import {alertService, AlertType, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {RebateFormValues, RebateFormBag, RebateResponse, EditRebateRequest} from '../../types';
import InputField from '../Form/InputField';
import FieldLabel from '../Form/FieldLabel';
import FieldGrid from '../Form/FieldGrid';
import DatePickerField from '../Form/DatePickerField';
import FormStep from '../Form/FormStep';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import LocationField from '../Form/LocationField';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';
import {EntityActionType} from '../Modal';
import OngoingSwitch from '../Form/OngoingSwitch';
import {ErrorBox} from '../Alert';

export const maxClaimEndAtValidationSchema = (
  maximumClaimEndAt: RebateFormValues['maximumClaimEndAt']
) =>
  Yup.object().shape({
    endAt: Yup.date().test(
      'max-claim-endat-validation',
      `Sorry, you can't edit the end date to less than ${format(
        new Date(maximumClaimEndAt ?? ''),
        'dd/MM/yyyy'
      )} (as claim(s) have been raised for this period)`,
      (value) => {
        if (!value || !maximumClaimEndAt) {
          return true;
        }
        return !isBefore(add(new Date(value), {days: 1}), new Date(maximumClaimEndAt));
      }
    ),
  });

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  lastStepButtonText?: string;
  handleBack: (values: RebateFormValues, bag: RebateFormBag) => void;
  handleNext: (values: RebateFormValues, bag: RebateFormBag) => void;
};

const StepRebateDetails: FC<Props> = ({
  step,
  title,
  validationSchema,
  totalStep,
  lastStepButtonText = 'Finished',
  handleBack,
  handleNext,
}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<RebateFormValues>();
  const {
    setFieldValue,
    values: {
      agreementEndAt,
      agreementId,
      agreementStartAt,
      endAt,
      hasActiveClaim,
      hasRaisedClaim,
      isOngoing,
      maximumClaimEndAt,
      startAt,
    },
  } = bag;
  const showDateRangeWarning =
    startAt && endAt && daysBetweenInTZ(endAt, startAt) > config.rebateDateRangeWarningDaysBetween;
  const showStartDateWarning = startAt && startAt < agreementStartAt;
  const showEndDateWarning = !isOngoing && endAt && agreementEndAt && endAt > agreementEndAt;
  const showMaxClaimError =
    !!maximumClaimEndAt && !!endAt && isBefore(new Date(endAt), new Date(maximumClaimEndAt));

  function updateFormValues(rebateResponse: RebateResponse) {
    setFieldValue('id', rebateResponse.id);
  }

  async function createOrEditRebate(values: RebateFormValues, bag: RebateFormBag) {
    showLoading();
    if (values.id && values.id > 0) {
      const requestData: EditRebateRequest = {
        description: values.description,
        startAt: values.startAt,
        endAt: values.endAt,
        locationCriteria: values.locationCriteria,
      };
      return await put(api(Consts.Api.Rebate.replace(':id', `${values.id}`)), requestData)
        .then((response: AxiosResponse<RebateResponse>) => {
          updateFormValues(response.data);
          alertService.clear(defaultAlertId);
          handleNext(values, bag);
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        })
        .finally(() => {
          hideLoading();
        });
    } else {
      const requestData = {
        description: values.description,
        startAt: values.startAt,
        endAt: values.endAt,
        locationCriteria: values.locationCriteria,
      };
      return await post(
        api(Consts.Api.ContractAgreementRebates.replace(':agreementId', `${agreementId}`)),
        requestData
      )
        .then((response: AxiosResponse<RebateResponse>) => {
          updateFormValues(response.data);
          alertService.clear(defaultAlertId);
          handleNext(values, bag);
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        })
        .finally(() => {
          hideLoading();
        });
    }
  }

  return (
    <>
      <FormStep step={step} title={title}>
        <ActiveClaimWarning
          entityActionType={EntityActionType.Rebate}
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
          maximumClaimEndAt={maximumClaimEndAt}
        />
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="description">Rebate Description</FieldLabel>
          <InputField
            id="description"
            name="description"
            fullWidth
            placeholder="Add your description here"
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="isOngoing" fullWidth>
            Is this rebate ongoing?
          </FieldLabel>
          <OngoingSwitch id="isOngoing" name="isOngoing" />
        </FieldGrid>
        <FieldGrid container spacing={1}>
          <Grid item xs={6}>
            <FieldLabel htmlFor="startAt" fullWidth>
              Start Date
            </FieldLabel>
            <DatePickerField
              dateType={DateType.RangeStart}
              id="startAt"
              name="startAt"
              placeholder="Type or choose a start date"
              minDate={Consts.Date.RebateStartDateMin}
              fullWidth
            />
          </Grid>
          {isOngoing ? null : (
            <Grid item xs={6}>
              <FieldLabel htmlFor="endAt" fullWidth>
                End Date
              </FieldLabel>
              <DatePickerField
                dateType={DateType.RangeEnd}
                id="endAt"
                name="endAt"
                placeholder="Type or choose an end date"
                minDate={maximumClaimEndAt ?? startAt}
                maxDate={Consts.Date.MaxEndDateMonthsFromNow}
                fullWidth
                validationSchema={
                  !!maximumClaimEndAt ? maxClaimEndAtValidationSchema(maximumClaimEndAt) : undefined
                }
              />
            </Grid>
          )}
          {showDateRangeWarning || showStartDateWarning || showEndDateWarning ? (
            <Grid item xs={12}>
              <ErrorBox type={AlertType.Warning}>
                {showDateRangeWarning ? (
                  <div>
                    The difference between start date and end date is greater than{' '}
                    {config.rebateDateRangeWarningDaysBetween} days.
                  </div>
                ) : null}
                {showStartDateWarning ? (
                  <div>The start date is set before the start date of contract agreement.</div>
                ) : null}
                {showEndDateWarning ? (
                  <div>The end date is set after the end date of contract agreement.</div>
                ) : null}
              </ErrorBox>
            </Grid>
          ) : null}
          {showMaxClaimError ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Error} data-testid="max-claim-endat-error">
                <div>{`Sorry, you can't edit the end date to less than ${format(
                  new Date(maximumClaimEndAt),
                  'dd/MM/yyyy'
                )} (as claim(s) have been raised for this period)`}</div>
              </ErrorBox>
            </Grid>
          ) : null}
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel>Location Assignment</FieldLabel>
          <LocationField id="locationCriteria" name="locationCriteria" />
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={createOrEditRebate}
        step={step}
        totalStep={totalStep}
        lastStepButtonText={lastStepButtonText}
      />
    </>
  );
};

export default StepRebateDetails;
