import React from 'react';
import Grid from '@mui/material/Grid';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {clearRebateFilters} from '../../../app/unreleasedRebatesReducer';
import {selectRebateNumberOfFilters} from '../../../app/selectors';
import {LinkButton} from '../../Button';

function ClearRebateFiltersButton() {
  const dispatch = useAppDispatch();
  const selectedRebateFiltersCount = useAppSelector(selectRebateNumberOfFilters);

  return (
    <>
      {selectedRebateFiltersCount > 0 ? (
        <Grid container justifyContent="flex-end" sx={{marginTop: '0.625rem'}}>
          <LinkButton
            onClick={() => {
              dispatch(clearRebateFilters());
            }}
          >
            Clear
          </LinkButton>
        </Grid>
      ) : null}
    </>
  );
}

export default ClearRebateFiltersButton;
