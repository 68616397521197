import React, {Component} from 'react';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {ai} from './telemetryService';

/**
 * This Component provides telemetry with Azure App Insights
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */

type Props = {
  children: React.ReactNode;
};

type State = {
  initialised: boolean;
};

class TelemetryProvider extends Component<Props, State> {
  state = {
    initialised: false,
  };

  componentDidMount() {
    const {initialised} = this.state;
    if (!Boolean(initialised)) {
      ai.initialize();
      this.setState({initialised: true});
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const TrackingWrapper = !!ai.reactPlugin
  ? withAITracking(ai.reactPlugin, TelemetryProvider)
  : TelemetryProvider;

export default TrackingWrapper;
