import React from 'react';
import {NumericFormat, NumericFormatProps} from 'react-number-format';

type Props = NumericFormatProps & {
  onChange: (event: {target: {name: string; value: string}}) => void;
  name: string;
  prefix?: string;
  suffix?: string;
};

const NumericFormatBase = React.forwardRef(
  ({onChange, prefix, suffix, decimal, ...props}: any, ref: React.Ref<any>) => {
    return (
      <NumericFormat
        getInputRef={ref}
        decimalScale={decimal ?? 2}
        fixedDecimalScale
        thousandSeparator=","
        valueIsNumericString
        prefix={prefix ?? ''}
        suffix={suffix ?? ''}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: `${values.floatValue ?? ''}`,
            },
          });
        }}
        {...props}
      />
    );
  }
);

const NumberFormatInput = React.forwardRef((props: Props, ref: React.Ref<any>) => (
  <NumericFormatBase {...props} ref={ref} />
));

export const PercentageFormatField = React.forwardRef((props: Props, ref: React.Ref<any>) => (
  <NumericFormatBase {...props} decimal={0} ref={ref} />
));

export const CurrencyFormatField = React.forwardRef((props: Props, ref: React.Ref<any>) => (
  <NumericFormatBase {...props} prefix="$" ref={ref} />
));

export const WholeNumberFormatField = React.forwardRef((props: Props, ref: React.Ref<any>) => (
  <NumericFormatBase {...props} decimalScale={0} ref={ref} />
));

export default NumberFormatInput;
