import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {ContractAgreementResponse} from '../../../types';
import {
  ContractAgreementForm,
  ContractAgreementFormValues,
} from '../../../components/ContractAgreementForm';
import {api, get} from '../../../utils/Request';

const EditContractAgreement = () => {
  let {id = ''} = useParams();
  const [initialValues, setInitialValues] = useState<ContractAgreementFormValues | null>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const updateFormValues = useCallback(
    (data: ContractAgreementResponse) => {
      setInitialValues({
        id,
        claimVendor: {
          code: data.claimVendorCode,
          name: data.claimVendorName,
          isActive: data.claimVendorActive,
        },
        ownedByStaffCode: data.ownedByStaffCode,
        description: data.description,
        startAt: data.startAt,
        endAt: data.endAt,
        rebateValueCount: data.rebateValueCount,
        status: data.status,
        hasRaisedClaim: data.hasRaisedClaim,
        hasActiveClaim: data.hasActiveClaim,
        isOngoing: !data.endAt,
        groupClaimsOnSingleInvoice: data.groupClaimsOnSingleInvoice,
      });
    },
    [id]
  );

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.ContractAgreement.replace(':id', id)))
      .then((response: AxiosResponse<ContractAgreementResponse>) => {
        hideLoading();
        alertService.clear(defaultAlertId);
        updateFormValues(response.data);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [showLoading, hideLoading, id, updateFormValues]);

  return initialValues ? (
    <ContractAgreementForm
      title="Edit Contract Agreement"
      initialValues={initialValues}
      isEditing
    />
  ) : null;
};

export default EditContractAgreement;
