import React, {FC} from 'react';
import {connectStats} from 'react-instantsearch-dom';
import {ErrorBox} from '../../Alert';

type Props = {
  processingTimeMS: number;
  nbHits: number;
  resultLabel?: string;
  pluralResultLabel?: string;
};

const Stats: FC<Props> = ({nbHits, resultLabel = 'result', pluralResultLabel = 'results'}) => {
  if (nbHits > 0) {
    return null;
  }
  return (
    <ErrorBox>
      {`Your criteria resulted in 0 ${pluralResultLabel} being applied. Please review your options so that at
      least 1 ${resultLabel} is selected.`}
    </ErrorBox>
  );
};

export default connectStats(Stats);
