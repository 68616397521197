import * as Yup from 'yup';
import Consts from '../../app/Consts';
import * as R from 'ramda';

const stepRebatValueValidationSchema = Yup.object({
  gstType: Yup.mixed().oneOf(
    R.map(R.prop('value'), Consts.GstType),
    Consts.ValidationMessage.Required
  ),
  rebateType: Yup.mixed().oneOf(
    R.map(R.prop('value'), Consts.RebateTypes),
    Consts.ValidationMessage.Required
  ),
  writebackType: Yup.mixed().oneOf(
    R.map(R.prop('value'), Consts.WritebackType),
    Consts.ValidationMessage.Required
  ),
  amountType: Yup.mixed().oneOf(
    R.map(R.prop('value'), Consts.AmountType),
    Consts.ValidationMessage.Required
  ),
  amount: Yup.number()
    .typeError(Consts.ValidationMessage.NumberOnly)
    .required(Consts.ValidationMessage.Required)
    .test('percentage value', Consts.ValidationMessage.PercentageValueWith100, function (value) {
      if (this.parent.amountType === Consts.AmountTypeEnum.Percentage) {
        return value !== undefined && value <= 100 && value >= -100;
      }
      return true;
    }),
  productCriteria: Yup.mixed().test(
    'upload or select product use product selector',
    'Please select or upload SKU',
    function (value) {
      if (!value && this.parent.uploadedProductCount <= 0) {
        return false;
      }
      return true;
    }
  ),
});

export default stepRebatValueValidationSchema;
