import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import {styled} from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {api, get, del} from '../../utils/Request';
import {createOrderFromParams} from '../../utils/common';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {useAppSelector} from '../../app/store';
import {selectLoggedInStaffCode} from '../../app/selectors';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {
  DraftListResponse,
  DraftListRequestParams,
  DraftItem,
  TableColumn,
  Order,
} from '../../types';
import {TableTabPanel} from '../../components/TableTabPanel';
import {SimpleDataTable} from '../../components/SimpleDataTable';
import OutlinedButton from '../../components/Button/OutlinedButton';
import {PageHeading} from '../../components/PageHeading';
import {DeleteActionButton} from '../../components/Table/ActionIconButton';
import {formatDate} from '../../utils/DateUtils';
import {SearchInputField} from '../SearchInputField';

const PREFIX = 'DraftListing';

const classes = {
  root: `${PREFIX}-root`,
  containerRoot: `${PREFIX}-containerRoot`,
  draftActionsButtons: `${PREFIX}-draftActionsButtons`,
  actionsContainer: `${PREFIX}-actionsContainer`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.containerRoot}`]: {
    paddingLeft: '3rem',
    paddingRight: '2.625rem',
    maxWidth: '80rem',
  },

  [`& .${classes.draftActionsButtons}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.actionsContainer}`]: {
    margin: '3rem 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.page,
    boxShadow: 'none',
    border: 'none',
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    margin: '3rem 0',
  },
}));

const DeleteButton = styled(DeleteActionButton)`
  margin-left: 1.25rem;
`;

const ContinueButton = styled(OutlinedButton)`
  width: 10.375rem;
  height: 3.25rem;
  margin-left: 0;
`;

const DraftType = {
  Deal: 'Deal',
  ContractAgreement: 'Contract Agreement',
  Rebate: 'Rebate',
  Spiv: 'SPIV',
  MixAndMatch: 'Mix & Match',
};

const DraftListing = ({my = false}) => {
  const navigate = useNavigate();
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<DraftItem[]>([]);
  const [pagination, setPagination] = useState(Consts.DefaultPagination);
  const [requestData, setRequestData] = useState<DraftListRequestParams>({
    entityCode: config.entityCode,
    ...Consts.DefaultPagination,
    orderBy: 'createdAt:desc',
  });
  const [order, setOrder] = useState<Order>({
    orderBy: 'createdAt',
    orderByDirection: 'desc',
  });

  const fetchDrafts = useCallback(async () => {
    try {
      setLoading(true);
      const requestParams: DraftListRequestParams = {
        ...(my ? {ownedByStaffCode: loggedInStaffCode} : {}),
        ...requestData,
      };
      const response: AxiosResponse<DraftListResponse> = await get(api(Consts.Api.Drafts), {
        params: requestParams,
      });
      const {data, ...pagination} = response.data;
      setRows(data);
      setPagination(pagination);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      setLoading(false);
    }
  }, [requestData, loggedInStaffCode, my]);

  useEffect(() => {
    fetchDrafts();
  }, [fetchDrafts]);

  const refreshData = (data: Partial<DraftListRequestParams>) => {
    setRequestData((prevRequestData) => ({
      ...prevRequestData,
      ...data,
    }));
  };

  const viewDraft = (id: DraftItem['id'], type: DraftItem['type']) => {
    navigate(
      Consts.RouterPath[`Edit${type}` as keyof typeof Consts.RouterPath].replace(':id', `${id}`)
    );
  };

  const deleteDraft = async (id: DraftItem['id'], type: DraftItem['type']) => {
    setLoading(true);
    try {
      await del(api(Consts.Api.Drafts), {
        params: {
          id,
          type,
        },
      });
      alertService.clear(defaultAlertId);
      setRequestData((prevRequestData) => ({
        ...prevRequestData,
        ...Consts.DefaultPagination,
      }));
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setRequestData((prevRequestData) => ({
      ...prevRequestData,
      currentPage: 1,
      searchText: value,
    }));
  };

  const handleOrderBy = (order: Pick<Order, 'orderBy'>) => {
    const newOrderBy = order.orderBy;
    setRequestData((prevRequestData) => ({
      ...prevRequestData,
      orderBy: newOrderBy,
    }));
    createOrderFromParams(newOrderBy, setOrder);
  };

  const columns: TableColumn<DraftItem>[] = [
    {
      id: 'createdAt',
      label: 'Created Date',
      minWidth: 88,
      render: (rowData: DraftItem) => formatDate(rowData.createdAt),
      sortable: true,
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: 150,
      sortable: true,
      render: (rowData: DraftItem) => {
        return DraftType[rowData.type as keyof typeof DraftType];
      },
    },
    {id: 'description', label: 'Description', minWidth: 250, sortable: true},
    {id: 'ownedByStaffName', label: 'Owner', sortable: true},
    {
      id: 'actions',
      label: '',
      minWidth: 100,
      render: (rowData: DraftItem) => (
        <Root className={classes.draftActionsButtons}>
          <ContinueButton onClick={() => viewDraft(rowData.id, rowData.type)}>
            Continue
          </ContinueButton>
          <DeleteButton onClick={() => deleteDraft(rowData.id, rowData.type)} />
        </Root>
      ),
    },
  ];

  return (
    <Root className={classes.root}>
      <PageHeading title={my ? 'My Drafts' : 'All Drafts'} />
      <Container
        maxWidth={false}
        classes={{
          root: classes.containerRoot,
        }}
      >
        <Paper className={classes.actionsContainer}>
          <Grid container>
            <SearchInputField placeholder="Search drafts" width="26rem" onSearch={handleSearch} />
          </Grid>
        </Paper>
        <TableTabPanel loading={loading} className={classes.tabPanelRoot}>
          <SimpleDataTable
            columns={columns}
            rows={rows}
            pagination={pagination}
            onChangePage={refreshData}
            onChangePageSize={refreshData}
            onOrderBy={handleOrderBy}
            sortOrderBy={order.orderBy}
            sortOrder={order.orderByDirection}
          />
        </TableTabPanel>
      </Container>
    </Root>
  );
};

export default DraftListing;
