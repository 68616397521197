import React, {FC, useMemo} from 'react';
import {hasRole} from '../../app/Permission';
import {useAppSelector} from '../../app/store';
import {selectProfileRoles} from '../../app/selectors';
import NoPagePermission from '../NoPermission/NoPagePermission';

type Props = {
  requiredRoles: {all?: string[]; any?: string[]};
  children: React.ReactNode;
  alt?: React.ReactNode;
};

const RequireAuth: FC<Props> = ({children, requiredRoles, alt}) => {
  const userRoles = useAppSelector(selectProfileRoles);
  const {all, any} = requiredRoles;

  const hasPermissions = useMemo(() => {
    const hasRoles = (userRoles ?? []).length > 0;
    const hasAll = !!all ? all.every((role) => hasRole(role, userRoles)) : true;
    const hasAny = !!any ? any.some((role) => hasRole(role, userRoles)) : true;
    return hasRoles && hasAll && hasAny;
  }, [userRoles, all, any]);

  if (hasPermissions) {
    return <>{children}</>;
  }
  return alt || alt === null ? <>{alt}</> : <NoPagePermission />;
};

export default RequireAuth;
