import React, {FC, useCallback} from 'react';
import {styled} from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import FormHelperText from '@mui/material/FormHelperText';
import {useField} from 'formik';
import Consts from '../../../../app/Consts';
import config from '../../../../app/Config';
import AutoCompleteSearch from '../../../Form/Agolia/AutoCompleteSearch';
import Label from './Label';

const StaticVendorField = styled(TextField)`
  background-color: #f2f4f5;
`;

const ErrorHelperText = styled(FormHelperText)(({theme}) => ({
  color: theme.palette.error.main,
}));

export type ClaimVendorSearchItem = {
  code: string;
  name: string;
  isActive: number;
};

type Props = {
  name: string;
  disabled?: boolean;
  onSelect: (item?: ClaimVendorSearchItem) => void;
};

const ClaimVendorSearch: FC<Props> = ({disabled, name, onSelect}) => {
  const [field, meta] = useField(name);

  const {touched, error} = meta;
  const helperText = touched && error ? error : undefined;

  const getSelectedValueCallback = useCallback((item: ClaimVendorSearchItem) => {
    return {code: item.code, name: item.name, isActive: item.isActive};
  }, []);

  const renderDisplayTextCallback = useCallback((item: ClaimVendorSearchItem | string) => {
    if (typeof item === 'string') {
      return item;
    } else if (!!item.name) {
      return item.name;
    } else {
      return '';
    }
  }, []);

  const renderListItemCallback = useCallback((hit: ClaimVendorSearchItem) => <>{hit.name}</>, []);
  return (
    <Stack spacing={1}>
      <Label htmlFor="claimVendorCode" sx={{position: 'relative'}}>
        Claim Vendor
      </Label>
      {disabled ? (
        <StaticVendorField
          disabled
          type="text"
          variant="outlined"
          fullWidth
          value={field.value ?? ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <AutoCompleteSearch
          confirm={undefined}
          defaultRefinement={field.value}
          defaultValue={field.value ?? null}
          filters={`entityCode:"${config.entityCode}" AND isActive:1`}
          fullWidth
          getSelectedValue={getSelectedValueCallback}
          indexName={Consts.AlgoliaIndex.ClaimVendors}
          noResultMessage="We haven't found any claim vendors that match the text you entered."
          onConfirm={undefined}
          onSelected={onSelect}
          placeholder="Start typing a claim vendor"
          renderDisplayText={renderDisplayTextCallback}
          renderListItem={renderListItemCallback}
          name={name}
        />
      )}
      {!!helperText ? <ErrorHelperText>{helperText}</ErrorHelperText> : null}
    </Stack>
  );
};

export default ClaimVendorSearch;
