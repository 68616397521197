import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {api, get} from '../utils/Request';
import Consts from './Consts';
import config from './Config';
import _debounce from 'lodash/debounce';

export interface Supplier {
  name: string;
  number: number;
  entityCode: string;
}

export interface SuppliersState {
  suppliers: Supplier[];
  suppliersError: any;
  loadingSuppliers: boolean;
}

export const initialState: SuppliersState = {
  suppliers: [],
  suppliersError: null,
  loadingSuppliers: false,
};

export interface SearchSuppliersRequest {
  text: string;
  pageSize: number;
}

export const debounceSearchForSuppliers = _debounce(
  (dispatch: any, inputText?: string) => searchForSuppliers(dispatch, inputText),
  250
);

const searchForSuppliers = (dispatch: any, inputText = '') => {
  const request: SearchSuppliersRequest = {
    text: inputText,
    pageSize: 20,
  };
  dispatch(searchSuppliers(request));
};

const sliceName = 'suppliers';
export const searchSuppliers = createAsyncThunk(
  `${sliceName}/searchForSuppliers`,
  async (request: SearchSuppliersRequest, thunkAPI) => {
    return get(api(Consts.Api.Suppliers), {
      params: {
        searchText: request.text,
        entityCode: config.entityCode,
        pageSize: request.pageSize,
      },
    }).then((response) => response.data);
  }
);

const suppliersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchSuppliers.pending.type, (state) => {
        state.loadingSuppliers = true;
      })
      .addCase(searchSuppliers.fulfilled.type, (state, action: any) => {
        state.loadingSuppliers = false;
        state.suppliers = action.payload.data;
      })
      .addCase(searchSuppliers.rejected.type, (state, action: any) => {
        state.loadingSuppliers = false;
        state.suppliersError = action.error;
      });
  },
});

const suppliersReducer = suppliersSlice.reducer;
export default suppliersReducer;
