import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import format from 'date-fns/format';
import {ErrorBox} from '../Alert';
import {AlertType} from '../../app/AlertService';

export type Props = {
  entityActionType: string;
  hasActiveClaim?: boolean;
  hasRaisedClaim?: boolean;
  maximumClaimEndAt?: string | null;
};

const ActiveClaimWarning: FC<Props> = ({
  entityActionType,
  hasActiveClaim,
  hasRaisedClaim,
  maximumClaimEndAt,
}) => {
  const maximumClaimEndAtDate = maximumClaimEndAt ? new Date(maximumClaimEndAt) : null;
  return (
    <>
      {hasActiveClaim ? (
        <Grid item xs={12} sx={{marginTop: '-2.875rem', marginBottom: '3.375rem'}}>
          <ErrorBox type={AlertType.Info}>
            There is at least one claim that is not Raised for this {entityActionType}, any changes
            will update these claims overnight.
          </ErrorBox>
        </Grid>
      ) : null}
      {hasRaisedClaim ? (
        <Grid item xs={12} sx={{marginTop: '-2.875rem', marginBottom: '3.375rem'}}>
          <ErrorBox type={AlertType.Info}>
            There is at least one claim in Ready to Raise status for this {entityActionType}, any
            changes will not update the amount on these claims.
          </ErrorBox>
        </Grid>
      ) : null}
      {!!maximumClaimEndAtDate ? (
        <Grid item xs={12} sx={{marginTop: '-2.875rem', marginBottom: '3.375rem'}}>
          <ErrorBox type={AlertType.Info}>
            You will not be able to edit the end date to less than{' '}
            {format(maximumClaimEndAtDate, 'dd/MM/yyyy')} (as claim(s) have been raised for this
            period)
          </ErrorBox>
        </Grid>
      ) : null}
    </>
  );
};

export default ActiveClaimWarning;
