import React, {FC, useState, useEffect} from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import type {ValidationError} from 'yup';
import IconLabelButton from '../Button/IconLabelButton';
import {EditIcon} from '../Icons';
import {CancelActionButton, SaveActionButton} from '../Table';

export const supplierApprovalNumberValidationSchema = Yup.object().shape({
  approvalNumber: Yup.string().test(
    'max length check',
    'Supplier approval number cannot exceed 100 characters',
    function (value?: string) {
      return !value || value.length <= 100;
    }
  ),
});

type Props = {
  isEditable?: boolean;
  approvalNumber?: string | null;
  onApprovalNumberUpdated?: (approvalNumber: string) => Promise<void>;
  onError?: (errorMessage: ValidationError | null) => void;
};

const EditableApprovalNumber: FC<Props> = ({
  isEditable,
  approvalNumber,
  onApprovalNumberUpdated,
  onError,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<string | undefined | null>();
  const [error, setError] = useState<ValidationError | null>(null);
  const [touched, setTouched] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    if (approvalNumber && !value && value !== '') {
      setValue(approvalNumber);
      setTouched(true);
    }
  }, [approvalNumber, value]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    const inputValue = event.target.value;
    try {
      setIsValidating(true);
      await supplierApprovalNumberValidationSchema.validate({approvalNumber: inputValue});
      setError(null);
      onError?.(null);
      setValue(inputValue);
    } catch (error) {
      setError(error as ValidationError);
      onError?.(error as ValidationError);
    } finally {
      setIsValidating(false);
    }
    setValue(inputValue);
  };

  const handleCancel = () => {
    setValue(approvalNumber);
    setError(null);
    onError?.(null);
    setIsEditing(false);
  };

  const handleSave = () => {
    onApprovalNumberUpdated?.(value ?? '').then(() => setIsEditing(false));
  };

  if (isEditing) {
    return (
      <Stack direction="column" spacing={2}>
        <TextField
          variant="outlined"
          autoFocus
          fullWidth
          type="text"
          name="approvalNumber"
          value={value ?? ''}
          onChange={handleChange}
          error={touched && Boolean(error)}
          helperText={touched && (error?.message ?? '')}
        />
        <Stack direction="row" spacing={1} sx={{justifyContent: 'flex-end'}}>
          <SaveActionButton
            size="small"
            disabled={Boolean(error) || isValidating}
            onClick={handleSave}
            style={{
              height: '1.5rem',
              width: '1.5rem',
              padding: '0.25rem',
              borderRadius: '0.75rem !important',
            }}
          />
          <CancelActionButton
            size="small"
            onClick={handleCancel}
            disabled={undefined}
            style={{
              height: '1.5rem',
              width: '1.5rem',
              padding: '0.25rem',
              borderRadius: '0.75rem !important',
            }}
          />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      direction="column"
      sx={{
        svg: {width: '1.0625rem !important'},
        alignItems: 'flex-start',
      }}
    >
      <Box sx={{overflowWrap: 'anywhere'}}>{approvalNumber}</Box>
      {isEditable ? (
        <IconLabelButton
          sx={{
            fontSize: '1rem',
            fontWeight: 'normal',
            padding: '0.375rem',
            lineHeight: 'normal',
            textDecoration: 'underline',
            height: 'unset',
          }}
          icon={<EditIcon />}
          label="Edit"
          onClick={() => {
            setIsEditing(true);
          }}
        />
      ) : null}
    </Stack>
  );
};

export default EditableApprovalNumber;
