import React, {FC, useContext, useCallback} from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Consts from '../../app/Consts';
import {alertService, defaultAlertId} from '../../app/AlertService';
import LoadingContext from '../../app/LoadingContext';
import getClaimFrequency from '../../utils/ClaimFrequencyUtils';
import {formatDate} from '../../utils/DateUtils';
import {api, post} from '../../utils/Request';
import {getDealType} from '../../utils/TypesStatusUtils';
import {ClaimListItemViewModel, TableColumn} from '../../types';
import {Amount} from '../Amount';
import {ConfirmModal} from '../Modal';
import {SimpleDataTable} from '../SimpleDataTable';
import {ClaimStatus} from '../Status';

const styles = {
  okButton: {
    marginLeft: '1.25rem',
    width: '12.5rem',
  },
  cancelButton: {
    width: '12.5rem',
  },
  root: {
    width: 'auto',
    overflow: 'auto',
  },
  actionSection: {
    justifyContent: 'center',
  },
};

type Props = {
  claim: ClaimListItemViewModel;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};

type RowData = ClaimListItemViewModel & {newStatus: string};

const RollbackModal: FC<Props> = ({claim, open, onOk, onCancel}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const handleRollback = useCallback(async () => {
    try {
      showLoading();
      await post(api(Consts.Api.ClaimsRollback), {
        claimId: claim.id,
      });
      onOk();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  }, [claim.id, showLoading, hideLoading, onOk]);

  const columns: TableColumn<RowData>[] = [
    {id: 'claimVendorName', label: 'Claim Vendor', minWidth: 80},
    {id: 'description', label: 'Rebate / Deal Description', minWidth: 150},
    {id: 'ownedByStaffName', label: 'Owner', minWidth: 80},
    {
      id: 'type',
      label: 'Type',
      render: (rowData: RowData) => `Deal: ${getDealType(rowData.rebateType)}`,
    },
    {id: 'id', label: 'ID'},
    {
      id: 'startAt',
      label: 'Claim Period Start Date',
      minWidth: 80,
      render: (rowData: RowData) => formatDate(rowData.startAt),
    },
    {
      id: 'endAt',
      label: 'Claim Period End Date',
      minWidth: 80,
      render: (rowData: RowData) => formatDate(rowData.endAt),
    },
    {
      id: 'claimFrequency',
      label: 'Claim Frequency',
      minWidth: 120,
      render: (rowData: RowData) =>
        getClaimFrequency(
          rowData.agreementType,
          rowData.claimFrequency,
          rowData.claimDaysAfterExpiry
        ),
    },
    {
      id: 'totalClaimAmount',
      label: 'Claim Amount',
      render: (rowData: RowData) => (
        <Amount
          value={rowData.totalClaimAmount}
          postfix={rowData.totalClaimAmount !== rowData.accrualAmount ? '*' : ''}
        />
      ),
    },
    {
      id: 'status',
      label: 'Current Status',
      style: {paddingRight: '0'},
      render: (rowData: RowData) => <ClaimStatus status={rowData.status} />,
    },
    {
      id: 'arrow',
      style: {padding: '0'},
      render: (rowData: RowData) => (
        <ArrowRightAltIcon
          style={{
            padding: '0',
          }}
        />
      ),
    },
    {
      id: 'newStatus',
      label: 'New Status',
      style: {paddingLeft: '0'},
      render: () => <ClaimStatus status={Consts.ClaimStatusEnum.ReadyToProcess} />,
    },
  ];

  const subtitle = () => (
    <Stack direction="column">
      <Box>
        The below claim status is about to be rolled back, please review and ensure all details are
        correct
      </Box>
      <Box sx={{color: 'red !important'}}>
        If you proceed, the Deal can be edited and the claim amount may change
      </Box>
    </Stack>
  );

  return (
    <ConfirmModal
      title="Review and Confirm"
      renderSubtitle={subtitle}
      open={open}
      onOk={handleRollback}
      onCancel={onCancel}
      okText="Proceed"
      cancelText="Back"
      styles={styles}
    >
      <div>
        <SimpleDataTable columns={columns} rows={[claim]} />
      </div>
    </ConfirmModal>
  );
};

export default RollbackModal;
