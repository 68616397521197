import React, {useContext} from 'react';
import {styled} from '@mui/material/styles';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {selectLoggedInStaffCode} from '../../../app/selectors';
import config from '../../../app/Config';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {useAppSelector} from '../../../app/store';
import {api, get} from '../../../utils/Request';
import {OutlinedButton} from '../../Button';

const DownloadButton = styled(OutlinedButton)`
  width: 220px;
`;

interface DownloadContractAgreementListingCsvButtonProps {
  my: boolean;
  requestData: any;
}

export default function DownloadContractAgreementListingCsvButton(
  props: DownloadContractAgreementListingCsvButtonProps
) {
  const {my, requestData} = props;
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);

  const downloadContractAgreements = () => {
    showLoading();
    get(api(Consts.Api.ContractAgreementsDownload), {
      params: {
        entityCode: config.entityCode,
        ownedByStaffCode: my ? loggedInStaffCode : null,
        ...requestData,
      },
      responseType: 'blob',
    })
      .then(({data}: any) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = my ? 'MyContractAgreements.csv' : 'AllContractAgreements.csv';
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error: any) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  return <DownloadButton onClick={downloadContractAgreements}>Download CSV Report</DownloadButton>;
}
