import React, {FC, useEffect, useState, useContext, useCallback, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {AxiosError, AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {
  DealAgreementSummaryResponse,
  DealValuesForDealSummaryResponse,
  DealValuesForSummaryData,
  Pagination,
} from '../../../types';
import {api, get} from '../../../utils/Request';
import {ChevronRightIcon, LocationsIcon} from '../../../components/Icons';
import ButtonsContainer from '../../../components/Container/ButtonsContainer';
import Button from '../../../components/Button/Button';
import DealSummaryValuesTable from '../../../components/DealForm/DealSummaryValuesTable';
import {SplitTextComponent} from '../../../components/SplitTextComponent';
import DynamicContainerWithHeader from '../../../components/Container/DynamicContainerWithHeader';
import {OutlinedButton} from '../../../components/Button';
import FinanceAccountsSummary from '../../../components/DealSummary/FinanceAccountsSummary';
import SupplierApprovalNumberEditable from './SupplierApprovalNumberEditable';

const DownloadButton = styled(Button)`
  width: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 3.625rem;
  background-color: #000000;
  color: #ffffff;
`;
const SummaryReportDownloadButton = styled(OutlinedButton)`
  border-color: black;
  font-size: 1rem;
  width: auto;
  height: 3.625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;

type Props = {
  dealData: DealAgreementSummaryResponse;
  onUpdate: (data: DealAgreementSummaryResponse) => void;
  onLocationClick: () => void;
};

const DealInformationTabContent: FC<Props> = ({dealData, onUpdate, onLocationClick}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [rows, setRows] = useState<DealValuesForSummaryData[]>([]);
  const [pagination, setPagination] = useState(Consts.DefaultPagination);

  useEffect(() => {
    setPagination({
      pageSize: dealData.dealValues.pageSize,
      currentPage: dealData.dealValues.currentPage,
      totalCount: dealData.dealValues.totalCount,
      totalPages: dealData.dealValues.totalPages,
    });
    setRows(dealData.dealValues.data);
  }, [dealData.dealValues]);

  const downloadSummaryReport = async () => {
    showLoading();
    try {
      const response = await get(
        api(Consts.Api.SummaryReportDownload.replace(':id', `${dealData.id}`)),
        {
          responseType: 'blob',
        }
      );

      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([response.data]));
      link.href = url;
      link.download = response.request
        .getResponseHeader('Content-Disposition')
        .split('filename="')[1]
        .split('";')[0];
      link.click();
      link.remove();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const downloadDealValues = async () => {
    showLoading();
    try {
      const {data} = await get(
        api(Consts.Api.DealValuesDownload.replace(':id', `${dealData.id}`)),
        {
          responseType: 'blob',
        }
      );
      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([data]));
      link.href = url;
      link.download = 'DealValues.csv';
      link.click();
      link.remove();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const loadDealValues = useCallback(
    async (dealId: number, paging: Partial<Pagination>) => {
      showLoading();
      try {
        const response: AxiosResponse<DealValuesForDealSummaryResponse> = await get(
          api(Consts.Api.DealAgreementSummaryValues.replace(':id', `${dealId}`)),
          {params: paging || {}}
        );

        if (response.data.data) {
          setRows(response.data.data);
          setPagination({
            pageSize: response.data.pageSize,
            currentPage: response.data.currentPage,
            totalCount: response.data.totalCount,
            totalPages: response.data.totalPages,
          });
        } else {
          setPagination(Consts.DefaultPagination);
          setRows([]);
        }

        alertService.clear(defaultAlertId);
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load deal values', response: error.response},
        });
      } finally {
        hideLoading();
      }
    },
    [showLoading, hideLoading]
  );

  const claimFrequencyLabel = useMemo(() => {
    if (!dealData?.claimInterval) {
      return null;
    }
    return dealData?.claimInterval === 'DaysAfterExpiry'
      ? Consts.DealClaimInterval.find(
          (interval) => interval.value === dealData.claimInterval
        )?.label.replace('Set', `${dealData?.claimDaysAfterExpiry ?? ''}`)
      : Consts.DealClaimInterval.find((interval) => interval.value === dealData.claimInterval)
          ?.label;
  }, [dealData]);

  const handleUpdateSANSuccess = (supplierApprovalNumber?: string) => {
    onUpdate({
      ...dealData,
      supplierApprovalNumber,
    });
  };

  const handleUpdateSANError = (error: AxiosError) => {
    alertService.alert({
      ...{message: error.message, response: error.response},
      id: defaultAlertId,
    });
  };

  return (
    <Stack spacing={12}>
      <Stack
        direction={{md: 'column', lg: 'row'}}
        spacing={8}
        justifyContent="space-between"
        alignItems={{xs: 'stretch', md: 'flex-start'}}
        sx={{width: '100%'}}
      >
        <DynamicContainerWithHeader title="Finance Details" style={{width: '100%'}}>
          <SplitTextComponent
            flexibleHeight
            leftLabel="Finance Accounts"
            rightLabel={<FinanceAccountsSummary financeAccounts={dealData.financeAccounts} />}
          />
          <SplitTextComponent leftLabel="Claim Frequency" rightLabel={claimFrequencyLabel} />
          <SplitTextComponent
            leftLabel="Returns"
            rightLabel={dealData.excludeReturns ? 'Excluded' : 'Included'}
          />
          <SplitTextComponent
            leftLabel="Supplier Approval Number"
            rightLabelFullWidth
            rightLabel={
              <SupplierApprovalNumberEditable
                dealData={dealData}
                onSave={handleUpdateSANSuccess}
                onError={handleUpdateSANError}
                direction="row"
              />
            }
          />
        </DynamicContainerWithHeader>
        <DynamicContainerWithHeader title="Location" style={{width: '100%'}}>
          <SplitTextComponent
            leftLabel={
              dealData.locationCriteria
                ? `${dealData.locationCriteria.resultCount} locations`
                : 'All Locations'
            }
            buttonLabel="View Locations"
            icon={<LocationsIcon />}
            buttonIcon={<ChevronRightIcon />}
            style={{color: '#DA6A00'}}
            onButtonClick={onLocationClick}
          />
        </DynamicContainerWithHeader>
      </Stack>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Deal Values</Typography>
          <ButtonsContainer>
            {dealData.dealValues.dealType === Consts.StandardDealTypeEnum.Purchases && (
              <SummaryReportDownloadButton onClick={downloadSummaryReport}>
                Download Purchases Summary Report
              </SummaryReportDownloadButton>
            )}

            {dealData.dealValues.dealType === Consts.StandardDealTypeEnum.Sales && (
              <SummaryReportDownloadButton onClick={downloadSummaryReport}>
                Download Written Scanbacks Summary Report
              </SummaryReportDownloadButton>
            )}

            <DownloadButton onClick={downloadDealValues}>Download CSV Report</DownloadButton>
          </ButtonsContainer>
        </Stack>
        <DealSummaryValuesTable
          values={rows}
          skuSelectorMode={rows.every((value) => !!value.productCode)}
          dealType={dealData.dealValues.dealType}
          pagination={pagination}
          onPagination={(currentPage, pageSize) => {
            loadDealValues(dealData.id, {currentPage, pageSize});
          }}
        />
      </Stack>
    </Stack>
  );
};

export default DealInformationTabContent;
