import React, {FC, ReactNode} from 'react';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {auTheme, nzTheme} from './components/Theme';
import {getDomainConfig, TenantCountry} from './utils/domainUtil';

const RebatesThemeProvider: FC<{children: ReactNode}> = ({children}) => {
  const domainConfig = getDomainConfig();
  const theme = domainConfig.country === TenantCountry.NZ ? nzTheme : auTheme;
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
};

export default RebatesThemeProvider;
