import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';

const Title = styled('div')`
  font-weight: 500;
  margin-bottom: 0.3125rem;
`;
const InfoItem = styled(Grid)`
  padding: 0.3125rem;
  width: 100%;
  word-break: break-word;
`;

type Props = {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const DetailItem: FC<Props> = ({title, children, style}) => (
  <InfoItem item style={{...(style ?? {})}}>
    <Title>{title}</Title>
    <div>{children}</div>
  </InfoItem>
);

export default DetailItem;
