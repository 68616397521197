import React, {useRef, useState} from 'react';
import {OutlinedButton} from '../Button';

const FileUploader = ({
  handleFile: upload,
  elementRef = null,
  confirm: ConfirmModal = null,
  clickUpload = null,
  accept = {'text/csv': ['.csv']},
  buttonText = 'Bulk Upload',
  multiple = false,
  component: Component = OutlinedButton,
  ...props
}) => {
  const hiddenFileInput = useRef(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleClick = (event) => {
    if (ConfirmModal) {
      setConfirmModalOpen(true);
    } else if (clickUpload) {
      clickUpload();
    } else {
      getRef().current.click();
    }
  };
  const getRef = () => {
    return elementRef || hiddenFileInput;
  };
  const handleChange = (event) => {
    const files = event.target.files;
    upload(multiple ? files : files[0]);
    //https://github.com/ngokevin/react-file-reader-input/issues/11
    event.target.value = '';
  };
  return (
    <>
      <Component onClick={handleClick} {...props}>
        {buttonText}
      </Component>
      <input
        type="file"
        accept={accept}
        ref={getRef()}
        onChange={handleChange}
        style={{display: 'none'}}
      />
      {ConfirmModal && (
        <ConfirmModal
          open={confirmModalOpen}
          onCancel={() => setConfirmModalOpen(false)}
          onOk={() => {
            setConfirmModalOpen(false);
            getRef().current.click();
          }}
        />
      )}
    </>
  );
};
export default FileUploader;
