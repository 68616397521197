import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Button, {ButtonProps} from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const ActionButton = styled(Button)`
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 1.125rem;
  &:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`;

type TooltipProps = {
  disabled?: boolean;
  disabledText?: string;
  children: React.ReactNode;
};

const OptionalTooltip: FC<TooltipProps> = ({disabled, disabledText, children}) => (
  <>
    {disabled && disabledText ? (
      <Tooltip title={disabledText} sx={{cursor: 'not-allowed'}} placement="top">
        <span>{children}</span>
      </Tooltip>
    ) : (
      children
    )}
  </>
);

export type IconLabelButtonProps = {
  startIcon?: boolean;
  icon: React.ReactNode;
  label?: string;
  disabledText?: string;
  disabled?: boolean;
} & ButtonProps;

const IconLabelButton: FC<IconLabelButtonProps> = ({
  startIcon = true,
  icon,
  label,
  onClick,
  disabledText,
  disabled,
  ...props
}) => {
  const actionButtonVariant = disabled ? 'text' : 'contained';
  return (
    <OptionalTooltip disabled={disabled} disabledText={disabledText}>
      <>
        {startIcon ? (
          <ActionButton
            variant={actionButtonVariant}
            disabled={disabled}
            startIcon={icon}
            onClick={onClick}
            {...props}
          >
            {label}
          </ActionButton>
        ) : (
          <ActionButton
            variant={actionButtonVariant}
            disabled={disabled}
            endIcon={icon}
            onClick={onClick}
            {...props}
          >
            {label}
          </ActionButton>
        )}
      </>
    </OptionalTooltip>
  );
};

export default IconLabelButton;
