import React, {FC, useContext, useState} from 'react';
import * as Yup from 'yup';
import {styled} from '@mui/material/styles';
import Consts from '../../../../app/Consts';
import LoadingContext from '../../../../app/LoadingContext';
import {
  EditFinanceDetailsRequest,
  FinanceDetailsResponse,
  DealAgreementSummaryResponse,
} from '../../../../types';
import {api, get, put} from '../../../../utils/Request';
import EditableField from '../../../../components/EditableField';

const PREFIX = 'SupplierApprovalNumberEditable';

const classes = {
  container: `${PREFIX}-container`,
};

const Root = styled('div')(() => ({
  [`& .${classes.container}`]: {
    width: 'fit-content',
  },
}));

export const supplierApprovalNumberValidationSchema = Yup.object().shape({
  supplierApprovalNumber: Yup.string().test(
    'max length check',
    'Supplier approval number cannot exceed 100 characters',
    function (value?: string) {
      return !value || value.length <= 100;
    }
  ),
});

type Props = {
  dealData?: DealAgreementSummaryResponse;
  onSave: (newValue?: string) => void;
  onError: (error: any) => void;
  direction?: 'row' | 'column';
};

type UpdateSupplierApprovalNumberRequest = EditFinanceDetailsRequest & {
  supplierApprovalNumber: string;
};

const SupplierApprovalNumberEditable: FC<Props> = ({dealData, onSave, onError, direction}) => {
  const [dealFinanceDetails, setDealFinanceDetails] = useState<{
    [x: string]: FinanceDetailsResponse;
  }>();

  const {showLoading, hideLoading} = useContext(LoadingContext);

  async function updateSupplierApprovalNumber(supplierApprovalNumber?: string) {
    if (!dealData) {
      return;
    }
    try {
      showLoading();
      onSave(supplierApprovalNumber);
      let requestData = {
        supplierApprovalNumber,
      };
      if (!dealFinanceDetails?.[dealData.id]) {
        const response = await get(
          api(Consts.Api.DealFinanceDetails.replace(':id', `${dealData.id}`))
        );
        setDealFinanceDetails({[dealData.id]: response.data});
        requestData = {
          ...(response?.data ?? {}),
          ...requestData,
        } as UpdateSupplierApprovalNumberRequest;
      } else {
        requestData = {
          ...(dealFinanceDetails[dealData.id] ?? {}),
          ...requestData,
        } as UpdateSupplierApprovalNumberRequest;
      }
      await put(api(Consts.Api.DealFinanceDetails.replace(':id', `${dealData.id}`)), requestData);
    } catch (error) {
      onError(error);
      onSave(dealData.supplierApprovalNumber);
    } finally {
      hideLoading();
    }
  }
  return (
    <Root className={classes.container}>
      <EditableField
        onSave={updateSupplierApprovalNumber}
        value={dealData?.supplierApprovalNumber ?? ''}
        direction={direction}
        readOnly={!dealData}
        validationSchema={supplierApprovalNumberValidationSchema}
        name="supplierApprovalNumber"
      />
    </Root>
  );
};

export default SupplierApprovalNumberEditable;
