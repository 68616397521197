import React, {FC, createRef, useEffect, useState, useRef, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import {AxiosResponse} from 'axios';
import {api, get} from '../../utils/Request';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {AgreementLookupItem} from '../../types';
import {useContainerDimensions} from '../Hook/useContainerDimensions';
import {LinkButton} from '../Button';
import {SearchIcon} from '../Icons';
import {ListItem, List, ListContainer} from '../Form/Agolia/StyledList';
import Spinner from '../Spinner/Spinner';

const ClearButton = styled(LinkButton)`
  &:focus {
    border: 1px solid #cbd2d8;
  }
`;

const StyledListContainer = styled(List)`
  position: relative;
`;

const EllipsisListItem = styled(ListItem)`
  woptione-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const StyledMessageContainer = styled('span')`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  line-height: normal;
  font-style: italic;
`;

export const getOptionDisplay = (option: Partial<AgreementLookupItem>) => {
  return `${option.agreementId} - ${option.vendorName} ${option.description}`;
};

type DefaultValue = Pick<AgreementLookupItem, 'agreementId' | 'vendorName' | 'description'>;

type Props = {
  startAt: string | null;
  endAt: string | null;
  defaultValue: DefaultValue | null;
  autoResultListWidth?: boolean;
  errorMessage?: string;
  onSelected: (option: AgreementLookupItem | null) => void;
  fullWidth?: boolean;
  placeholder?: string;
};

const AgreementSearch: FC<Props> = ({
  startAt,
  endAt,
  defaultValue,
  autoResultListWidth = true,
  errorMessage,
  onSelected,
  fullWidth,
  placeholder,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<Partial<AgreementLookupItem> | null>(defaultValue);
  const [options, setOptions] = useState<AgreementLookupItem[]>([]);
  const [searchText, setSearchText] = useState('');
  const [cursor, setCursor] = useState(0);
  const [listRefs, setListRefs] = useState<React.RefObject<HTMLDivElement>[]>([]);
  const [loading, setLoading] = useState(false);

  const searchBoxRef = useRef<HTMLElement | null>(null);
  const containerRef = useRef(null);
  const {width} = useContainerDimensions(containerRef);

  useEffect(() => {
    if (!open) {
      return;
    }
    setLoading(true);
    const timeoutID = setTimeout(() => {
      get(api(Consts.Api.ContractAgreementLookup), {
        params: {
          entityCode: config.entityCode,
          countLimit: 20,
          searchText: searchText,
          startAt: startAt,
          endAt: endAt,
        },
      })
        .then((response: AxiosResponse<AgreementLookupItem[]>) => {
          setLoading(false);
          alertService.clear(defaultAlertId);
          setOptions(response.data);
        })
        .catch((error) => {
          setLoading(false);
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    }, 200);

    return () => {
      clearTimeout(timeoutID);
      setLoading(false);
    };
  }, [searchText, open, startAt, endAt]);

  useEffect(() => {
    if (options.length > 0) {
      setListRefs(Array.from({length: options.length}).map(() => createRef()));
    }
  }, [options]);

  useEffect(() => {
    if (value) {
      setSearchText(getOptionDisplay(value));
    }
  }, [value]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (options.length <= 0) {
      setCursor(0);
    }
    if (cursor > options.length) {
      setCursor(0);
    }
    if (event.key === 'ArrowUp' && cursor > 0) {
      setCursor((prevCursor) => {
        let cursor = prevCursor - 1;
        listRefs[cursor]?.current?.scrollIntoView?.({block: 'nearest'});
        return cursor;
      });
    } else if (event.key === 'ArrowDown' && cursor < options.length - 1) {
      setCursor((prevCursor) => {
        let cursor = prevCursor + 1;
        listRefs[cursor]?.current?.scrollIntoView({block: 'nearest'});
        return cursor;
      });
    } else if (event.key === 'Enter' && open && options.length > 0) {
      const option = options[cursor];
      setOpen(false);
      setValue(option);
      onSelected(option);
    }
  };

  const clearSearch = () => {
    setSearchText('');
    setValue(null);
    onSelected(null);
  };

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        {searchText ? (
          <ClearButton type="button" onClick={clearSearch}>
            Clear
          </ClearButton>
        ) : (
          <SearchIcon />
        )}
      </InputAdornment>
    ),
  };

  const renderSearchList = useMemo(() => {
    if (loading) {
      return (
        <ListItem sx={{height: '5.625rem'}}>
          <Spinner loading={true} />
        </ListItem>
      );
    }
    if (options && options.length > 0) {
      return options.map((option, index) => {
        return (
          <EllipsisListItem
            ref={listRefs[index]}
            selected={cursor === index}
            key={option.agreementId}
            onClick={() => {
              setOpen(false);
              setValue(option);
              onSelected(option);
            }}
            onMouseDown={(event) => event.preventDefault()}
          >
            {getOptionDisplay(option)}
          </EllipsisListItem>
        );
      });
    }
    return (
      <ListItem onMouseDown={(event) => event.preventDefault()}>
        <StyledMessageContainer>
          We haven't found any agreement that matches the text you entered.
        </StyledMessageContainer>
      </ListItem>
    );
  }, [loading, options, cursor, listRefs, onSelected]);

  const handlesSearchBlur = () => {
    setOpen(false);
    clearSearch();
  };

  const handleSearchClick = () => {
    searchBoxRef?.current?.focus();
    setOpen((open) => !open);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpen(true);
    setSearchText(event.currentTarget.value);
  };

  return (
    <ListContainer ref={containerRef}>
      <TextField
        variant="outlined"
        onKeyDown={handleKeyDown}
        type="text"
        inputRef={searchBoxRef}
        autoComplete="off"
        value={searchText}
        error={!!errorMessage}
        helperText={!open && errorMessage}
        placeholder={placeholder}
        fullWidth={fullWidth}
        onBlur={handlesSearchBlur}
        onClick={handleSearchClick}
        onChange={handleSearchChange}
        InputProps={inputProps}
        {...props}
      />

      {open ? (
        <Popper
          open={open}
          anchorEl={searchBoxRef.current}
          style={autoResultListWidth ? {minWidth: width} : {width: width}}
          placement="bottom-start"
        >
          <StyledListContainer data-testid="search-results">{renderSearchList}</StyledListContainer>
        </Popper>
      ) : null}
    </ListContainer>
  );
};

export default AgreementSearch;
