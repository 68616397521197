/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as R from 'ramda';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {SearchIcon} from '../../Icons/Icon';
import {
  debounceSearchForSuppliers,
  searchSuppliers,
  SearchSuppliersRequest,
  Supplier,
} from '../../../app/suppliersReducer';

export interface SuppliersSearchProps {
  value: Supplier | null;
  onChange: (_: any, option: Supplier | null) => void;
}

function SuppliersSearch(props: SuppliersSearchProps) {
  const {value, onChange} = props;
  const {suppliers, loadingSuppliers} = useAppSelector((x) => x.suppliers);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const financialAccountsRequest: SearchSuppliersRequest = {
      text: value?.name ?? '',
      pageSize: 20,
    };

    dispatch(searchSuppliers(financialAccountsRequest));
  }, []);

  return (
    <Autocomplete
      popupIcon={null}
      options={suppliers}
      value={value}
      getOptionLabel={(option: Supplier) => option.name}
      loading={loadingSuppliers}
      filterOptions={(x: any) => x}
      filterSelectedOptions={false}
      onInputChange={(_: any, newInputValue: string) => {
        debounceSearchForSuppliers(dispatch, newInputValue);
      }}
      onChange={(e: any, value: Supplier | null) => onChange(e, value)}
      isOptionEqualToValue={(option, value) => {
        return R.equals(option, value);
      }}
      renderInput={(params) => {
        return (
          <TextField
            variant="outlined"
            {...params}
            sx={{paddingRight: 0}}
            placeholder="All suppliers"
            name="suppliers"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    {loadingSuppliers && <CircularProgress color="inherit" size={20} />}
                    <SearchIcon
                      style={{
                        position: 'absolute',
                        right: 18,
                        top: 18,
                        width: 17.49,
                        height: 17.49,
                      }}
                    />
                  </InputAdornment>
                </>
              ),
            }}
          />
        );
      }}
    />
  );
}

export default SuppliersSearch;
