import React from 'react';
import {styled} from '@mui/material/styles';
import {CustomSelect} from '../Select';

const PREFIX = 'TableCellSelect';

const classes = {
  root: `${PREFIX}-root`,
  selectMenu: `${PREFIX}-selectMenu`,
};

const StyledCustomSelect = styled(CustomSelect)(() => ({
  [`&.${classes.root}`]: {
    backgroundColor: '#F2F4F5',
    borderRadius: 'unset',
    fontSize: '15px',
  },

  [`& .${classes.selectMenu}`]: {
    minHeight: '16px',
    height: '16px',
  },
}));

const TableCellSelect = ({onChanged, disabled = false, ...props}) => (
  <StyledCustomSelect
    disabled={disabled}
    classes={classes}
    className={classes.root}
    onChanged={onChanged}
    {...props}
  />
);

export default TableCellSelect;
