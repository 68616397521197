import React, {FC, useState, useContext, useCallback, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {api, get, del} from '../../../utils/Request';
import {AttachmentType} from '../../../types';
import LoadingContext from '../../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {DeleteActionButton} from '../../../components/Table';
import {DownloadActionButton} from '../../../components/Table/ActionIconButton';
import Attachment from '../../../components/Attachment/Attachment';
import FilesUploader, {
  classes as fileUploaderClasses,
} from '../../../components/DragDrop/FilesUploader';
import RequireAuth from '../../../components/Auth/RequireAuth';
import Button from '../../../components/Button/Button';

const FileUploaderRoot = styled('div')(() => ({
  width: '100%',
  [`& .${fileUploaderClasses.root}`]: {
    border: 'none !important',
    padding: '0 !important',
  },
  [`& .${fileUploaderClasses.headingButton}`]: {
    alignSelf: 'flex-end',
  },
  [`& .${fileUploaderClasses.title}`]: {
    fontSize: '1.625rem !important',
    marginBottom: '0px !important',
  },
  [`& .${fileUploaderClasses.subTitle}`]: {
    fontSize: '1.125rem !important',
    color: '#000 !important',
    marginTop: '0.75rem !important',
  },
  [`& .${fileUploaderClasses.headingContainer}`]: {
    marginBottom: '1.875rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [`& .${fileUploaderClasses.emptyContainer}`]: {
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 6px #00000029',
  },
}));

const UploadButton = styled(Button)`
  height: 3.625rem;
`;

type Props = {
  spivId: string;
};

const SPIVSummaryAttachments: FC<Props> = ({spivId}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [attachments, setAttachments] = useState<AttachmentType[]>();

  const fetchAttachments = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<AttachmentType[]> = await get(
        api(Consts.Api.Attachments).replace(':id', spivId) + '?referenceType=Agreement'
      );
      setAttachments(response.data ?? []);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  }, [spivId, showLoading, hideLoading]);

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  const uploadComplete = ({data}: {data: AttachmentType[]}) => {
    setAttachments((prevAttachments) => [...(prevAttachments ?? []), ...data]);
  };

  const deleteAttachment = async (attachmentId: string) => {
    try {
      showLoading();
      await del(
        api(Consts.Api.Attachment).replace(':id', attachmentId) + '?referenceType=Agreement'
      );
      setAttachments((prevAttachments) => prevAttachments?.filter((x) => x.id !== attachmentId));
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const downloadAttachment = async (attachment: AttachmentType) => {
    try {
      showLoading();
      const response = await get(
        api(Consts.Api.Attachment).replace(':id', attachment.id) +
          '/Download?referenceType=Agreement',
        {
          responseType: 'blob',
        }
      );
      const data = response.data;
      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([data]));
      link.href = url;
      link.download = attachment.fileName;
      link.click();
      link.remove();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };
  return (
    <FileUploaderRoot>
      <FilesUploader
        maxSizeInMb={20}
        multiple={true}
        apiEndpoint={api(Consts.Api.Attachment).replace(':id', spivId) + '?referenceType=Agreement'}
        accept={{
          'image/png': [],
          'image/jpeg': [],
          'image/jpg': [],
          'application/pdf': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // for docx
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
          'message/rfc822': ['.eml'], // for eml
          'text/csv': ['.csv'], // for csv
          'application/vnd.ms-outlook': ['.msg'], // for msg
        }}
        title="There are no attachments in this deal yet."
        subTitle="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
        onComplete={uploadComplete}
        noFiles={attachments?.length === 0}
        showHeadingButton={attachments?.length !== 0}
        showBottomButton={false}
        button={UploadButton}
        buttonLabel="Add your first attachment"
        continueAddButtonLabel="Add New Attachment"
        heading="Attachments"
      >
        <div>
          {attachments?.map?.((attachment: AttachmentType, index: number) => (
            <Attachment
              key={index}
              fileName={attachment.fileName}
              sx={{padding: '0.625rem 1.875rem'}}
            >
              <DownloadActionButton
                style={{
                  marginRight: '1rem',
                  color: 'green',
                }}
                onClick={() => {
                  downloadAttachment(attachment);
                }}
              ></DownloadActionButton>
              <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.AddOrUpdateDeals]}} alt={null}>
                <DeleteActionButton
                  onClick={() => {
                    deleteAttachment(attachment.id);
                  }}
                ></DeleteActionButton>
              </RequireAuth>
            </Attachment>
          ))}
        </div>
      </FilesUploader>
    </FileUploaderRoot>
  );
};

export default SPIVSummaryAttachments;
