import * as Yup from 'yup';
import Consts from '../../../app/Consts';

export const stepFinanceDetailsValidationSchema = Yup.object({
  claimInterval: Yup.string().required(Consts.ValidationMessage.Required),
  claimDaysAfterExpiry: Yup.number().when('claimInterval', function (claimInterval, schema) {
    if (claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry) {
      return Yup.number()
        .required(Consts.ValidationMessage.Required)
        .typeError(Consts.ValidationMessage.NumberOnly)
        .min(0)
        .max(365);
    }
    return schema;
  }),
  supplierApprovalNumber: Yup.string()
    .test(
      'max length check',
      'Supplier approval number cannot exceed 100 characters',
      (value?: string) => !value || value.length <= 100
    )
    .nullable(),
  comment: Yup.string().nullable(),
  excludeReturns: Yup.boolean().nullable(),
});
