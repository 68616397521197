import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export interface PageHeadingProps {
  title: string;
  children?: React.ReactNode;
}

const PageHeading = (props: PageHeadingProps) => {
  const {title, children} = props;
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '8rem',
        boxShadow: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        borderBottom: '1px solid #cbd2d8',
        width: '100%',
      }}
      {...props}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          flexBasis: '255px',
          padding: '0 3rem',
        }}
      >
        <Typography variant="h4">{title}</Typography>
      </Box>
      {children}
    </Paper>
  );
};

export {PageHeading};
