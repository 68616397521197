import React, {FC} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Autocomplete, {AutocompleteProps} from '@mui/material/Autocomplete';
import * as R from 'ramda';

type ACProps = Omit<
  AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>,
  'renderInput'
> & {
  renderInput?: (params: any) => React.ReactNode;
};

export type Props = ACProps & {
  name?: string;
  onInputBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  errorText?: string;
  placeholder?: string;
};

const CustomAutocomplete: FC<Props> = ({
  loading = false,
  onInputBlur,
  placeholder = 'All',
  name,
  disabled,
  errorText,
  renderInput,
  ...autoCompleteProps
}) => {
  const _renderInput = (params: TextFieldProps) =>
    renderInput ? (
      renderInput(params)
    ) : (
      <TextField
        variant="outlined"
        {...params}
        placeholder={placeholder}
        onBlur={onInputBlur}
        name={name}
        id={name}
        error={!!errorText}
        helperText={errorText}
        InputProps={{
          ...params.InputProps,
          name,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps?.endAdornment ?? null}
            </React.Fragment>
          ),
        }}
      />
    );

  return (
    <Autocomplete
      popupIcon={<ExpandMore />}
      disabled={disabled}
      renderInput={_renderInput}
      isOptionEqualToValue={(option, value) => R.equals(option, value)}
      autoHighlight
      fullWidth
      loadingText="loading..."
      {...autoCompleteProps}
    />
  );
};

export default CustomAutocomplete;
