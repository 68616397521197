import React, {useEffect} from 'react';
import {CustomAutoComplete} from '../../CustomAutoComplete';
import {
  debounceSearchForFinancialAccounts,
  FinanceAccount,
  searchFinancialAccounts,
  SearchFinancialAccountsRequest,
} from '../../../app/financialAccountsReducer';
import {useAppDispatch, useAppSelector} from '../../../app/store';

export interface FinanceAccountsMultipleSearchProps {
  value: FinanceAccount[] | null;
  onChange: (_: any, option: FinanceAccount[]) => void;
}

function FinanceAccountsMultipleSearch(props: FinanceAccountsMultipleSearchProps) {
  const {value, onChange} = props;
  const {financialAccounts, loadingFinancialAccounts} = useAppSelector((x) => x.financialAccounts);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const financialAccountsRequest: SearchFinancialAccountsRequest = {
      text: '',
      pageSize: 20,
    };

    dispatch(searchFinancialAccounts(financialAccountsRequest));
  }, [dispatch]);

  return (
    <CustomAutoComplete
      options={financialAccounts}
      value={value}
      placeholder={value && value.length > 0 ? '' : 'All financial accounts'}
      name="financialAccounts"
      multiple
      getOptionLabel={(option: FinanceAccount) => option.name}
      loading={loadingFinancialAccounts}
      onChange={(e: any, value: FinanceAccount[]) => onChange(e, value)}
      onInputChange={(_: any, newInputValue: string) => {
        debounceSearchForFinancialAccounts(dispatch, newInputValue);
      }}
    />
  );
}

export default FinanceAccountsMultipleSearch;
