import {styled} from '@mui/material/styles';

const LinkButton = styled('button')`
  background: none;
  border: unset;
  color: #626262;
  text-decoration: underline;
  font-size: 1rem;
  &:focus,
  &:active {
    outline: unset;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus-visible {
    border: 1px solid #cbd2d8;
  }
`;

export default LinkButton;
