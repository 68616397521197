import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMore from '@mui/icons-material/ExpandMore';
import * as R from 'ramda';

const PREFIX = 'CustomSelect';

const classes = {
  root: `${PREFIX}-root`,
  menuItem: `${PREFIX}-menuItem`,
  menuPaper: `${PREFIX}-menuPaper`,
};

const StyledSelect = styled(Select)(({theme}) => ({
  [`&.${classes.root}`]: {
    backgroundColor: '#FFF',
  },

  [`& .${classes.menuItem}`]: {
    height: `${itemHeight}px`,
  },

  [`& .${classes.menuPaper}`]: {
    maxHeight: `${itemHeight * 6 + 10}px`,
  },
}));

const itemHeight = 50;

const CustomSelect = ({
  options,
  defaultValue,
  disabled = false,
  onChanged = (option) => {},
  ...props
}) => {
  const [option, setOption] = useState(defaultValue);
  useEffect(() => {
    setOption(defaultValue);
  }, [defaultValue]);
  return (
    <StyledSelect
      disabled={disabled}
      IconComponent={ExpandMore}
      classes={{root: classes.root}}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        classes: {paper: classes.menuPaper},
      }}
      value={option ? option.value : ''}
      onChange={(event) => {
        const selectedvalue = event.target.value;
        const selectedOption = options.find((option) => R.equals(option.value, selectedvalue));
        setOption(selectedOption);
        onChanged(selectedOption);
      }}
      variant="outlined"
      {...props}
    >
      {options.map((each) => {
        return (
          <MenuItem value={each.value} key={`${each.value}`} classes={{root: classes.menuItem}}>
            {each.label}
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
};

export default CustomSelect;
