import React, {useCallback, useState} from 'react';
import {styled} from '@mui/material/styles';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import {OutlinedButton} from '../Button';
import {ExcelIcon} from '../Icons';
import {post} from '../../utils/Request';
import config from '../../app/Config';

const PREFIX = 'Dropzone';

const classes = {
  root: `${PREFIX}-root`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  circularProgress: `${PREFIX}-circularProgress`,
  cancelIcon: `${PREFIX}-cancelIcon`,
};

const DropZoneContainer = styled('div')`
  height: 364px;
  border: 1px solid #dedede;
  background: #f7f9fa padding-box;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const StyledDropZoneContainer = styled(DropZoneContainer)(({theme}) => ({
  [`& .${classes.circularProgress}`]: {
    marginLeft: 5,
  },

  [`& .${classes.cancelIcon}`]: {
    color: theme.palette.error.main,
    fontSize: 30,
  },
}));

const CancelToken = axios.CancelToken;
let source;

const BorderLinearProgress = LinearProgress;

const ProgressContainer = styled('div')`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
`;
const ProgressBarContainer = styled('div')`
  display: flex;
  > div {
    flex: 1;
  }
  width: 100%;
  align-items: center;
`;

const ProgressMessageContainer = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  color: #626262;
`;

const DropzoneTitle = styled('div')`
  font-size: 20px;
  font-weight: 500;
`;
const DropzoneIcon = styled('div')`
  margin-bottom: 15px;
`;

const DragDropTitle = styled(DropzoneTitle)`
  margin-bottom: 15px;
`;
const CompleteMessage = 'Complete';
const ErrorMessage = 'Error happened';

const Dropzone = ({
  onError = (error) => {},
  onComplete = (response) => {},
  apiEndpoint,
  additionalData,
}) => {
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      setAcceptedFile(acceptedFiles[0]);

      let data = new FormData();
      data.append('file', acceptedFiles[0]);
      data.append('data', additionalData);

      source = CancelToken.source();
      const requestConfig = {
        onUploadProgress: function (progressEvent) {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
          setProgressMessage(progress === 100 ? 'Processing...' : `${progress}% complete`);
        },
        cancelToken: source.token,
        params: {
          entityCode: config.entityCode,
        },
        // https://github.com/axios/axios/issues/4406
        transformRequest: (data) => data,
      };
      post(apiEndpoint, data, requestConfig)
        .then((response) => {
          setProgressMessage(CompleteMessage);
          onComplete(response);
        })
        .catch(function (error) {
          if (axios.isCancel(error)) {
            return;
          }
          if (error) {
            setProgressMessage(ErrorMessage);
            onError(error);
          }
        });
    },
    [apiEndpoint, onComplete, onError, additionalData]
  );

  const cancelUpload = () => {
    if (source) {
      source.cancel('Operation canceled by the user.');
    }
    setProgressMessage('');
    setAcceptedFile(null);
  };
  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop,
    accept: {'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []},
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });
  return (
    <StyledDropZoneContainer {...getRootProps()} data-testid="dropzone">
      <input {...getInputProps()} />
      {acceptedFile ? (
        <ProgressContainer>
          <DropzoneTitle>Uploading your file</DropzoneTitle>
          <ProgressBarContainer>
            <div>
              <BorderLinearProgress
                variant="determinate"
                value={uploadProgress}
                classes={{
                  root: classes.root,
                  colorPrimary: classes.colorPrimary,
                }}
              />
            </div>
            <IconButton onClick={cancelUpload} size="large">
              <CancelIcon classes={{root: classes.cancelIcon}} />
            </IconButton>
          </ProgressBarContainer>
          {progressMessage && (
            <ProgressMessageContainer>
              <span>{progressMessage}</span>
              {uploadProgress === 100 &&
                progressMessage !== CompleteMessage &&
                progressMessage !== ErrorMessage && (
                  <CircularProgress size={30} classes={{root: classes.circularProgress}} />
                )}
            </ProgressMessageContainer>
          )}
        </ProgressContainer>
      ) : (
        <>
          <DropzoneIcon>
            <ExcelIcon />
          </DropzoneIcon>
          <div>
            <DragDropTitle>Drag and drop your Excel file</DragDropTitle>
            <OutlinedButton type="button" onClick={open}>
              Or choose a file
            </OutlinedButton>
          </div>
        </>
      )}
    </StyledDropZoneContainer>
  );
};

export default Dropzone;
