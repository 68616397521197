import {FormikContextType} from 'formik';
import {ClaimListItemViewModel} from './claim';
import {Pagination, QueryCriteria, RecursiveUndefined, Supplier, FinanceAccount} from './shared';

/**
 * FORM TYPES *****************************************************************
 */

export enum DataMode {
  Edit = 'Edit',
  Add = 'Add',
  Display = 'Display',
}

export type ValidSPIVFormValues = {
  id: number;
  accrualTaxAmount: number;
  amount: number;
  claimDaysAfterExpiry?: number;
  claims?: ClaimListItemViewModel[];
  claimsFetched?: boolean;
  claimInterval: string;
  claimVendorActive: boolean;
  claimVendorCode: string;
  claimVendorName: string;
  claimVendorSuppliers: Supplier[];
  comment?: string;
  departmentDescription: string;
  departmentNumber: number;
  description: string;
  draftMode: boolean;
  endAt: string;
  excludeReturns: boolean;
  gstType: string;
  hasActiveClaim: boolean;
  hasRaisedClaim: boolean;
  isDeleted: boolean;
  isFinalClaimRaised: boolean;
  locationCriteria: QueryCriteria | null;
  spivValueCount: number;
  spivValues: SpivValuesResponse;
  startAt: string;
  status: string;
  supplierApprovalNumber?: string | null;
  suppliers: Supplier[];
  DealCutoffDate?: string | null;
  DealLockDate?: string | null;
};
export type InitialSPIVFormValues = RecursiveUndefined<ValidSPIVFormValues>;
export type SPIVFormBag<T = ValidSPIVFormValues> = FormikContextType<T>;

/**
 * REQUEST TYPES **************************************************************
 */

/**
 * put v1/Spivs/{id}
 */
export type EditSPIVRequest = {
  claimVendorCode: string;
  departmentNumber?: number;
  description: string;
  endAt?: string;
  locationCriteria?: QueryCriteria | null;
  startAt?: string;
};

/**
 * post v1/Spivs
 */
export type CreateSPIVRequest = {
  claimVendorCode: string;
  departmentNumber: number | null;
  description: string;
  endAt: string | null;
  locationCriteria: QueryCriteria | null;
  startAt: string;
};

/**
 * post /v1/Spivs/{id}/SpivValues
 */
export type CreateSpivValueRequest = {
  agreementId?: number;
  amount: number | null;
  amountType?: string;
  capType?: string;
  capValue?: number;
  gstType: string | null;
  minSellPrice?: number;
  minSellPriceType?: string;
  payableIncentiveAmountToStaff: number;
  productCode: string;
  productDescription?: string;
  ticketPrice?: number;
};

/**
 * put /v1/Spivs/{id}/SpivValues/{spivValueId}
 */

export type EditSpivValueRequest = {
  amount: number;
  amountType: string;
  capType?: string;
  capValue?: number;
  gstType: string;
  minSellPrice?: number;
  minSellPriceType?: string;
  payableIncentiveAmountToStaff: number;
  productCode: string;
};

/**
 * put /v1/Spivs/{id}/FinanceDetails
 */

export type EditSpivFinanceDetailsRequest = {
  claimDaysAfterExpiry?: number;
  claimInterval: string;
  comment?: string;
  excludeReturns?: boolean;
  supplierApprovalNumber?: string;
};

/**
 * RESPONSE TYPES *************************************************************
 */

/**
 * post v1/Spivs
 * put|get v1/Spivs/{id}
 * post /v1/Spivs/{id}/Duplicate
 */
export type SPIVResponse = {
  id: number;
  claimVendorActive: boolean;
  claimVendorCode: string;
  claimVendorName: string;
  claimVendorSuppliers: Supplier[];
  departmentNumber: number;
  description: string;
  endAt: string;
  hasActiveClaim: boolean;
  hasRaisedClaim: boolean;
  isFinalClaimRaised: boolean;
  locationCriteria: QueryCriteria;
  ownedByStaffCode: number;
  ownedByStaffName: string;
  spivValueCount: number;
  startAt: string;
  status: string;
};

/**
 * put /v1/Spivs/{id}/SpivValues/{spivValueId}
 * post /v1/Spivs/{id}/SpivValues
 */
export type SPIVValue = {
  id: number;
  agreementId: number;
  amount: number;
  amountType: string;
  capType?: string;
  capValue?: number;
  gstType: string;
  minSellPrice?: number;
  minSellPriceType?: string;
  payableIncentiveAmountToStaff: number;
  productCode: string;
  productDescription: string;
  ticketPrice?: number;
  isSupplierMismatch?: boolean;
};

export type LocalSPIVValue = RecursiveUndefined<SPIVValue> & {dataMode?: string};

export type ValidLocalSPIVValue = SPIVValue & {dataMode?: string};

/**
 * get /v1/Spivs/{id}/SpivValues
 */
export type SpivValuesResponse = {
  data: SPIVValue[];
  isClaimVendorGstFree: boolean;
  suppliers: Supplier[];
} & Pagination;

export type SPIVValueForSPIVSummaryResponse = SPIVValue & {
  accruedTotal: number;
  amountSoldOrPurchased: number;
  gstTotal: number;
  type: string;
};

export type SpivValuesForSpivSummaryResponse = {
  data: SPIVValueForSPIVSummaryResponse[];
  dealType: string; // 'SPIV' for summary page
} & Pagination;

/**
 * get /v1/Spivs/{id}/summary
 */
export type SPIVSummaryResponse = {
  id: number;
  accrualTaxAmount: number;
  amount: number;
  claimDaysAfterExpiry?: number;
  claimInterval?: string;
  claimVendorName: string;
  createdAt: string;
  departmentDescription: string;
  description: string;
  endAt: string;
  excludeReturns: boolean;
  financeAccounts: FinanceAccount[];
  gstType: string;
  isCancellable?: boolean;
  isDeletable: boolean;
  isDeleted: boolean;
  lastModifiedAt: string;
  lastModifiedByStaffCode: number;
  lastModifiedByStaffName: string;
  locationCriteria: QueryCriteria | null;
  ownedByStaffCode: number;
  ownedByStaffName: string;
  spivValues: SpivValuesForSpivSummaryResponse;
  startAt: string;
  status: string;
  supplierApprovalNumber?: string | null;
  totalSpivPaidToStaffBeforeCancellation?: number;
};

/**
 * get /v1/Spivs/{id}/FinanceDetails
 */
export type SpivFinanceDetailsResponse = {
  claimDaysAfterExpiry: number | null;
  claimInterval: string;
  comment: string | null;
  excludeReturns: boolean | null;
  supplierApprovalNumber: string | null;
};
