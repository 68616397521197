import React, {FC, useContext, useMemo, useCallback, useEffect, useState} from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Consts from '../../app/Consts';
import {alertService, defaultAlertId} from '../../app/AlertService';
import LoadingContext from '../../app/LoadingContext';
import getClaimFrequency from '../../utils/ClaimFrequencyUtils';
import {formatDate} from '../../utils/DateUtils';
import {api, post} from '../../utils/Request';
import {getDealType, getRebateType} from '../../utils/TypesStatusUtils';
import {ClaimListItemViewModel, TableColumn} from '../../types';
import {Amount} from '../Amount';
import {ConfirmModal} from '../Modal';
import {SimpleDataTable} from '../SimpleDataTable';
import {ClaimStatus} from '../Status';
import {RaiseWarnIcon} from './ClaimAdvanceIcon';
import ClaimWarnList from './ClaimWarnList';
import ClaimAdvancementWarning from './ClaimAdvancementWarning';

const styles = {
  okButton: {
    marginLeft: '1.25rem',
    width: '12.5rem',
  },
  cancelButton: {
    width: '12.5rem',
  },
  root: {
    width: 'auto',
    overflow: 'auto',
  },
  actionSection: {
    justifyContent: 'center',
  },
};

const ClaimStatusAdvanceOrderMapping = {
  [Consts.ClaimStatusEnum.ReadyToProcess]: Consts.ClaimStatusEnum.SentToSupplier,
  [Consts.ClaimStatusEnum.ReadyToSend]: Consts.ClaimStatusEnum.SentToSupplier,
  [Consts.ClaimStatusEnum.SentToSupplier]: Consts.ClaimStatusEnum.Finalised,
  [Consts.ClaimStatusEnum.Finalised]: Consts.ClaimStatusEnum.Invoiced,
  [Consts.ClaimStatusEnum.Invoiced]: Consts.ClaimStatusEnum.Raised,
};

const AdvanceInfo = {
  [Consts.ClaimStatusEnum.Raised]: {
    Message: 'Advancing will generate a file to be imported into Dynamics 365',
    Icon: <RaiseWarnIcon />,
  },
};

export const claimWithNextStatus = (claim: ClaimListItemViewModel): RowData => {
  let newStatus = ClaimStatusAdvanceOrderMapping[claim.status];
  // handle adjustment claims
  if (Boolean(claim.parentClaimId)) {
    newStatus = {
      [Consts.ClaimStatusEnum.ReadyToProcess]:
        claim.totalClaimAmount === 0
          ? Consts.ClaimStatusEnum.NoClaimRaised
          : Consts.ClaimStatusEnum.Invoiced,
      [Consts.ClaimStatusEnum.Invoiced]: Consts.ClaimStatusEnum.Raised,
    }[claim.status];
  } else if (claim.totalClaimAmount === 0) {
    newStatus = Consts.ClaimStatusEnum.NoClaimRaised;
  }
  return {
    ...claim,
    doNotEmailSupplier: false,
    newStatus,
  };
};

type Props = {
  claims: ClaimListItemViewModel[];
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};

type RowData = ClaimListItemViewModel & {newStatus: string; doNotEmailSupplier?: boolean};

const ClaimAdvanceModal: FC<Props> = ({claims, open, onOk, onCancel}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [claimsToAdvance, setClaimsToAdvance] = useState<RowData[]>([]);

  useEffect(() => {
    const filteredClaims = claims
      .filter(
        (x) =>
          x.status !== Consts.ClaimStatusEnum.Raised &&
          x.status !== Consts.ClaimStatusEnum.NoClaimRaised
      )
      .map(claimWithNextStatus);
    setClaimsToAdvance(filteredClaims);
  }, [claims]);

  const columns: TableColumn<RowData>[] = [
    {
      id: 'extra-info',
      label: '',
      render: (rowData: RowData) => {
        return rowData.newStatus && AdvanceInfo[rowData.newStatus]
          ? AdvanceInfo[rowData.newStatus].Icon
          : null;
      },
    },
    {id: 'claimVendorName', label: 'Claim Vendor', minWidth: 80},
    {id: 'description', label: 'Rebate / Deal Description', minWidth: 150},
    {id: 'ownedByStaffName', label: 'Owner', minWidth: 80},
    {
      id: 'type',
      label: 'Type',
      render: (rowData: RowData) =>
        rowData.agreementType === Consts.AgreementTypeEnum.Deal
          ? `Deal: ${getDealType(rowData.rebateType)}`
          : `Rebate: ${getRebateType(rowData.rebateType)}`,
    },
    {id: 'id', label: 'ID'},
    {
      id: 'startAt',
      label: 'Claim Period Start Date',
      minWidth: 80,
      render: (rowData: RowData) => formatDate(rowData.startAt),
    },
    {
      id: 'endAt',
      label: 'Claim Period End Date',
      minWidth: 80,
      render: (rowData: RowData) => formatDate(rowData.endAt),
    },
    {
      id: 'claimFrequency',
      label: 'Claim Frequency',
      minWidth: 120,
      render: (rowData: RowData) =>
        getClaimFrequency(
          rowData.agreementType,
          rowData.claimFrequency,
          rowData.claimDaysAfterExpiry
        ),
    },
    {
      id: 'totalClaimAmount',
      label: 'Claim Amount',
      render: (rowData: RowData) => (
        <Amount
          value={rowData.totalClaimAmount}
          postfix={rowData.totalClaimAmount !== rowData.accrualAmount ? '*' : ''}
        />
      ),
    },
    {
      id: 'status',
      label: 'Current Status',
      style: {paddingRight: '0'},
      render: (rowData: RowData) => <ClaimStatus status={rowData.status} />,
    },
    {
      id: 'arrow',
      style: {padding: '0'},
      render: (rowData: RowData) => (
        <ArrowRightAltIcon
          style={{
            // arrow is mispositioned when email checkbox full row removes padding
            ...(rowData.newStatus === Consts.ClaimStatusEnum.Invoiced
              ? {padding: '1rem 0 0 0'}
              : {padding: '0'}),
          }}
        />
      ),
    },
    {
      id: 'newStatus',
      label: 'New Status',
      style: {paddingLeft: '0'},
      render: (rowData: RowData) => <ClaimStatus status={rowData.newStatus} />,
    },
    {
      id: 'doNotEmailSupplier',
      additionalLine: 1,
      colspanLeft: 1,
      colspan: 12,
      style: {paddingTop: '0'},
      isShown: (rowData: RowData) => rowData.newStatus === Consts.ClaimStatusEnum.Invoiced,
      render: (rowData: RowData) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={rowData.doNotEmailSupplier}
              onChange={(e) => {
                const newClaimsToAdvance = claimsToAdvance.map((x) => {
                  if (x.id === rowData.id) {
                    return {...x, doNotEmailSupplier: e.target.checked};
                  }
                  return x;
                });
                setClaimsToAdvance(newClaimsToAdvance);
              }}
            />
          }
          label="Don't send email to supplier"
        />
      ),
    },
  ];
  const advanceClaims = useCallback(() => {
    const claimsAdvance = claimsToAdvance.map((x) => ({
      id: x.id,
      newStatus: x.newStatus,
      doNotEmailSupplier: x.doNotEmailSupplier,
    }));

    showLoading();
    post(api(Consts.Api.ClaimsAdvance), {
      claims: claimsAdvance,
    })
      .then(() => {
        hideLoading();
        onOk();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
        onCancel();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimsToAdvance]);

  const renderWarnInfo = useMemo(() => {
    const newStatuses = claimsToAdvance.map((x) => x.newStatus);
    const warnings = [];
    for (const key in AdvanceInfo) {
      if (newStatuses.indexOf(key) >= 0) {
        warnings.push(AdvanceInfo[key]);
      }
    }
    return <ClaimWarnList warns={warnings} />;
  }, [claimsToAdvance]);

  const subtitle = useCallback(
    () => (
      <>
        <div>
          The below claims({claimsToAdvance.length}) are about to be advanced, please review and
          ensure all details are correct.
        </div>
        <ClaimAdvancementWarning claims={claims} />
      </>
    ),
    [claimsToAdvance, claims]
  );

  return (
    <ConfirmModal
      title="Review and Confirm"
      renderSubtitle={subtitle}
      open={open}
      onOk={advanceClaims}
      onCancel={onCancel}
      okText="Proceed"
      cancelText="Go back"
      styles={styles}
    >
      <div>
        <SimpleDataTable columns={columns} rows={claimsToAdvance} />
        {renderWarnInfo}
      </div>
    </ConfirmModal>
  );
};

export default ClaimAdvanceModal;
