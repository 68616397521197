import React, {FC, useEffect, useRef, useState} from 'react';
import {SchemaOf} from 'yup';
import {styled} from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StepButton from '@mui/material/StepButton';
import {Formik, Form} from 'formik';
import Debug from './Debug';
import {PageContentContainer} from '../Container';
import {StepIcon} from '../Icons';
import Prompt from '../Prompt';
import {alertService, defaultAlertId} from '../../app/AlertService';
import FormErrorScroller from './FormErrorScroller';

const PREFIX = 'StepperForm';

const classes = {
  root: `${PREFIX}-root`,
  stepperRoot: `${PREFIX}-stepperRoot`,
  stepperTitle: `${PREFIX}-stepperTitle`,
  stepper: `${PREFIX}-stepper`,
  step: `${PREFIX}-step`,
  stepButton: `${PREFIX}-stepButton`,
  stepIcon: `${PREFIX}-stepIcon`,
  stepIconLabel: `${PREFIX}-stepIconLabel`,
  stepIconContainer: `${PREFIX}-stepIconContainer`,
  stepLabel: `${PREFIX}-stepLabel`,
  stepActive: `${PREFIX}-stepActive`,
  formContent: `${PREFIX}-formContent`,
  actionSection: `${PREFIX}-actionSection`,
  pageContentContainer: `${PREFIX}-pageContentContainer`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.stepperRoot}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '129.5px',
    boxShadow: 'none',
    backgroundColor: 'rgba(255,255,255,0.85)',
    paddingLeft: '51px',
    paddingRight: '43px',
  },

  [`& .${classes.stepperTitle}`]: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    flexBasis: '255px',
  },

  [`& .${classes.stepper}`]: {
    padding: 'unset',
    flexGrow: 2,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 1,
    '& .MuiStepConnector-root': {
      display: 'none',
    },
  },

  [`& .${classes.step}`]: {
    display: 'flex',
    height: '100%',
    flex: '1 auto',
    justifyContent: 'center',
    boxSizing: 'border-box',
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },

  [`& .${classes.stepButton}`]: {
    padding: 'unset',
    margin: 'unset',
  },

  [`& .${classes.stepIcon}`]: {
    width: '36px !important',
    height: '36px !important',
    color: `${theme.palette.background.stepIcon}`,
  },

  [`& .${classes.stepIconLabel}`]: {
    fontWeight: `${theme.typography.fontWeightMedium} !important`,
    fontSize: '10px !important',
  },

  [`& .${classes.stepIconContainer}`]: {
    paddingRight: '10px',
  },

  [`& .${classes.stepLabel}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    color: 'unset',
  },

  [`& .${classes.stepActive}`]: {
    borderBottom: `6px solid ${theme.palette.primary.main}`,
  },

  [`& .${classes.formContent}`]: {
    backgroundColor: 'unset',
  },

  [`& .${classes.actionSection}`]: {
    marginRight: '31px',
    '& >button': {
      marginRight: '31px',
    },
  },

  [`& .${classes.pageContentContainer}`]: {
    [theme.breakpoints.up('lg')]: {
      width: '1500px',
    },
  },
}));

type StepContentProps = {
  step: number;
  title: string;
  totalStep: number;
  handleBack: (values: any) => void;
  handleNext: (values: any, bag: any) => void;
  lastStepButtonText?: string;
  validationSchema?: SchemaOf<any>;
  style?: React.CSSProperties;
};

type StepContent = React.ReactElement<StepContentProps>;

type Props = {
  initialValues: any;
  onSubmit: (values: any, bag: any) => void;
  steps: string[];
  getStepContent: (index: number, handleBack: any, handleNext: any) => StepContent | null;
  title: string;
  contentHeader?: React.ReactNode;
  routeChangeMessage?: string;
  showRouteChangeMessage?: (values: any) => boolean;
  indexOverride?: number;
};

const StepperForm: FC<Props> = ({
  initialValues,
  onSubmit,
  steps,
  getStepContent,
  title,
  contentHeader = null,
  routeChangeMessage = '',
  showRouteChangeMessage = (values) => false,
  indexOverride,
}) => {
  const titleRef = useRef<HTMLDivElement | null>(null);

  const [activeStepIndex, setActiveStepIndex] = useState(indexOverride ?? 0);
  const [snapshot, setSnapshot] = useState(initialValues);

  useEffect(() => {
    if (titleRef.current && titleRef.current.scrollIntoView) {
      titleRef.current.scrollIntoView({block: 'start'});
    }
  }, [activeStepIndex]);

  const handleNext = (values: any, bag: any) => {
    setSnapshot(values);
    if (activeStepIndex < steps.length - 1) {
      setActiveStepIndex((prevActiveStepIndex) => prevActiveStepIndex + 1);
      bag.setTouched({});
    } else {
      bag.submitForm();
    }
  };

  const handleBack = (values: any) => {
    setSnapshot(values);
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (stepIndex: number) => () => {
    setActiveStepIndex(stepIndex);
  };

  let stepContent = getStepContent(activeStepIndex, handleBack, handleNext) as StepContent;

  const handleSubmit = async (values: any, bag: any) => {
    let isLastStep = activeStepIndex === steps.length - 1;
    if (isLastStep) {
      return onSubmit(values, bag);
    }
  };

  return (
    <Root className={classes.root} ref={titleRef}>
      <Paper className={classes.stepperRoot}>
        <div className={classes.stepperTitle}>
          <Typography variant="h4">{title}</Typography>
        </div>
        <Stepper activeStep={activeStepIndex} className={classes.stepper}>
          {steps.map((label, index) => {
            const labelProps = {
              StepIconProps: {
                classes: {
                  root: classes.stepIcon,
                  text: classes.stepIconLabel,
                },
              },
              classes: {
                label: classes.stepLabel,
                iconContainer: classes.stepIconContainer,
              },
            };

            return (
              <Step
                key={label}
                className={`${classes.step} ${activeStepIndex === index ? classes.stepActive : ''}`}
              >
                <StepButton onClick={handleStep(index)} className={classes.stepButton}>
                  <StepLabel {...labelProps} StepIconComponent={StepIcon}>
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Paper>

      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={stepContent?.props?.validationSchema}
        validateOnMount
      >
        {(formik) => (
          <>
            <Form autoComplete="off">
              {contentHeader}
              <PageContentContainer style={stepContent?.props?.style ?? {}}>
                {
                  <>
                    <div>
                      <Paper elevation={0} className={classes.formContent}>
                        {stepContent}
                      </Paper>
                    </div>
                  </>
                }
                {process.env.NODE_ENV !== 'production' && <Debug />}
              </PageContentContainer>
            </Form>
            <Prompt
              condition={showRouteChangeMessage?.(formik.values)}
              message={() => {
                alertService.info(routeChangeMessage, {
                  id: defaultAlertId,
                  keepAfterRouteChange: true,
                });
              }}
            />
            <FormErrorScroller />
          </>
        )}
      </Formik>
    </Root>
  );
};

export default StepperForm;
