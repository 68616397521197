import React, {FC, useState} from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

type Props = {
  options: Array<{label: string; value: string | boolean}>;
  confirm?: FC<{open: boolean; onCancel: () => void; onOk: () => void}> | null;
  verticalDisplay?: boolean;
  name: string;
  value: string | boolean;
  onChanged: (value: string | boolean) => void;
  disabled?: boolean;
};

const CustomRadioGroup: FC<Props> = ({
  options,
  confirm: ConfirmModal,
  verticalDisplay = false,
  name,
  value,
  disabled = false,
  onChanged,
}) => {
  const [open, setOpen] = useState(false);
  const [valueToChange, setValueToChange] = useState<string | null>(null);

  const useBoolean = options.every((x) => typeof x.value === 'boolean');
  function onOk() {
    setOpen(false);
    if (valueToChange) {
      onChanged(useBoolean ? valueToChange === 'true' : valueToChange ?? '');
      setValueToChange(null);
    }
  }
  function onClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (ConfirmModal) {
      setOpen(true);
      setValueToChange(event.target.value);
    } else {
      onChanged(useBoolean ? event.target.value === 'true' : event.target.value);
    }
  }
  const isMatch = (value1: string | boolean, value2: string | boolean) => {
    return useBoolean ? value1.toString() === value2.toString() : value1 === value2;
  };
  return (
    <>
      <RadioGroup
        name={name}
        value={value}
        onChange={onClick}
        sx={{
          '&.MuiFormGroup-root': {
            flexDirection: verticalDisplay ? 'column' : 'row',
            justifyContent: 'space-between',
            width: '100%',
            label: {
              flex: verticalDisplay ? 'auto' : '1',
              border: '1px solid #CBD2D8',
              backgroundColor: '#FFF',
              height: '50px',
              margin: verticalDisplay ? '0 0 5px 0' : '0 8px 0 0',
              '&:last-child': verticalDisplay
                ? {}
                : {
                    margin: 0,
                  },
              '& .MuiRadio-root': {
                height: '100%',
              },
            },
          },
        }}
      >
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            disabled={disabled}
            value={option.value}
            control={<Radio checked={isMatch(option.value, value)} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {ConfirmModal ? (
        <ConfirmModal
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          onOk={onOk}
        />
      ) : null}
    </>
  );
};

export default CustomRadioGroup;
