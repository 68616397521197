import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './app/store';
import {TelemetryProvider} from './utils/appInsights';
import RebatesThemeProvider from './RebatesThemeProvider';
import PageSpinner from './components/Spinner/PageSpinner';
import LoadingProvider from './components/LoadingProvider';
import ErrorBoundary from './components/ErrorBoundary';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <RebatesThemeProvider>
    <TelemetryProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <LoadingProvider>
            <App />
            <PageSpinner />
          </LoadingProvider>
        </Provider>
      </ErrorBoundary>
    </TelemetryProvider>
  </RebatesThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
