import React, {useEffect, useContext, useState, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {api, get} from '../../../utils/Request';
import {RebateFormValues, RebateResponse} from '../../../types';
import RebateForm from '../../../components/RebateForm';

const EditRebate = () => {
  const {id} = useParams();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [initialValues, setInitialValues] = useState<RebateFormValues | null>(null);

  const updateFormValues = useCallback(
    (data: RebateResponse) => {
      setInitialValues({
        id: Number(id),
        agreementId: data.agreementId,
        description: data.description,
        claimVendorSuppliers: data.claimVendorSuppliers,
        claimVendorName: data.claimVendorName,
        agreementDescription: data.agreementDescription,
        startAt: data.startAt,
        agreementStartAt: data.agreementStartAt,
        endAt: data.endAt,
        agreementEndAt: data.agreementEndAt,
        isOngoing: !data.endAt,
        locationCriteria: data.locationCriteria,
        status: data.status,
        draftMode: data.status === Consts.RebateStatusEnum.Draft,
        suppliers: [],
        maximumClaimEndAt: data.maximumClaimEndAt,
        //these data are not needed in step 1
        rebateType: Consts.RebateTypeEnum.Sales,
        productCriteria: null,
        uploadedProductCount: 0,
        excludeReturns: false,
        amount: '',
        amountType: Consts.AmountTypeEnum.Percentage,
        writebackType: null,
        splitAccounts: [],
        accountOptions: [],
        splitAccountAmountType: Consts.SplitAccountAmountTypeEnum.Percentage,
        claimInterval: '',
        externalId: '',
        financeAccountCleared: false,
        hasRaisedClaim: data.hasRaisedClaim,
        hasActiveClaim: data.hasActiveClaim,
        isClaimVendorGstFree: data.isClaimVendorGstFree,
        gstType: data.isClaimVendorGstFree ? Consts.GstTypeEnum.Free : Consts.GstTypeEnum.Exclusive,
      });
    },
    [id]
  );
  useEffect(() => {
    if (!id) {
      return;
    }
    showLoading();
    get(api(Consts.Api.Rebate.replace(':id', id)))
      .then((response: AxiosResponse<RebateResponse>) => {
        alertService.clear(defaultAlertId);
        updateFormValues(response.data);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [showLoading, hideLoading, id, updateFormValues]);

  return initialValues ? (
    <RebateForm title="Edit Rebate" initialValues={initialValues} isEditing />
  ) : null;
};

export default EditRebate;
