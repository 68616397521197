import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Pagination} from '../../../types';
import {StaticButton} from '../../Button';
import PageNavigator from './PageNavigator';
import SectionContainer from './SectionContainer';
import PageCounter from './PageCounter';
import PageSetting from './PageSetting';

const PREFIX = 'TablePaginationWithAddButton';

const classes = {
  addRowButton: `${PREFIX}-addRowButton`,
  iconButton: `${PREFIX}-iconButton`,
};

const TablePaginationContainer = styled(SectionContainer)`
  justify-content: space-between;
  height: 3.75rem;
  padding: 1.25rem 1.125rem 0rem;
`;

const StyledTablePaginationContainer = styled(TablePaginationContainer)({
  [`& .${classes.addRowButton}`]: {
    padding: 0,
  },
  [`& .${classes.iconButton}`]: {
    padding: '0.3125rem',
  },
});

const StyledDivider = styled(Divider)`
  height: 1.875rem;
  margin: 1rem 1.125rem;
`;

type Props = {
  disabled: boolean;
  addButtonText: string;
  onAdd: () => void;
  pagination: Pagination;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
};

const TablePaginationWithAddButton: FC<Props> = ({
  disabled,
  addButtonText,
  onAdd,
  pagination,
  onChangePage,
  onChangePageSize,
}) => (
  <StyledTablePaginationContainer>
    <StaticButton
      disabled={disabled}
      type="button"
      onClick={onAdd}
      classes={{root: classes.addRowButton}}
      startIcon={<AddCircleOutlineIcon />}
    >
      {addButtonText}
    </StaticButton>
    <PageNavigator pagination={pagination} onChangePage={onChangePage} />
    <SectionContainer>
      <PageCounter pagination={pagination} />
      <StyledDivider orientation="vertical" />
      <PageSetting pagination={pagination} onChangePageSize={onChangePageSize} />
    </SectionContainer>
  </StyledTablePaginationContainer>
);

export default TablePaginationWithAddButton;
