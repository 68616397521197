import React, {FC, useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';
import {useFormik} from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {ClaimDetailsViewModel} from '../../types';
import {api, get, post} from '../../utils/Request';
import useDelayLoading from '../../components/Hook/useDelayLoading';
import {PageTabs} from '../../components/PageTabs';
import {TableTabPanel} from '../../components/TableTabPanel';
import EditableApprovalNumber from '../../components/Claim/EditableApprovalNumber';
import {CurrencyFormatField} from '../../components/NumberFormatInput';

const PREFIX = 'ClaimAdjustment';

const classes = {
  root: `${PREFIX}-root`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
  paper: `${PREFIX}-paper`,
  typography: `${PREFIX}-typography`,
  fieldValues: `${PREFIX}-fieldValues`,
  backContainer: `${PREFIX}-backContainer`,
  divider: `${PREFIX}-divider`,
  container: `${PREFIX}-container`,
  titleSpacing: `${PREFIX}-titleSpacing`,
  button: `${PREFIX}-button`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.tabPanelRoot}`]: {
    margin: '4rem',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    maxWidth: '60rem',
    minWidth: '21rem',
    margin: 'auto',
  },

  [`& .${classes.typography}`]: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },

  [`& .${classes.fieldValues}`]: {
    color: theme.palette.text.primary,
  },

  [`& .${classes.backContainer}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.divider}`]: {
    backgroundColor: theme.palette.black.main,
  },

  [`& .${classes.container}`]: {
    padding: theme.spacing(8, 14, 0, 14),
  },

  [`& .${classes.titleSpacing}`]: {
    marginTop: theme.spacing(5),
  },

  [`& .${classes.button}`]: {
    width: '14rem',
    fontSize: '1.375rem',
    fontWeight: 700,
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    '&:hover': {
      color: theme.palette.black.main,
    },
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(12),
  },
}));

const tabList = [
  {
    label: 'Adjustment details',
    value: null,
  },
];

export const validationSchema = yup.object({
  claimId: yup.number().required('Claim ID is required').positive('Claim ID must be positive'),
  claimAmountTaxExcluded: yup
    .number()
    .required('Claim amount is required')
    .notOneOf([0], 'Claim amount cannot be equal to 0')
    .typeError('Claim amount must be a number'),
  rebateApprovalNumber: yup
    .string()
    .max(100, 'Rebate approval number cannot be longer than 100 characters'),
  comment: yup.string(),
});
type CreateClaimAdjustmentRequest = yup.InferType<typeof validationSchema>;

export const validateRequestBody = (
  values: CreateClaimAdjustmentRequest
): CreateClaimAdjustmentRequest => ({
  claimId: values.claimId,
  claimAmountTaxExcluded: Number(values.claimAmountTaxExcluded),
  rebateApprovalNumber: values.rebateApprovalNumber,
  comment: values.comment?.trim(),
});

const ClaimAdjustment: FC = () => {
  const navigate = useNavigate();
  const {id = ''} = useParams<{id: string}>();

  const [loading, setLoading] = useDelayLoading(false);
  const [data, setData] = useState<ClaimDetailsViewModel | null>(null);

  const handleNavAway = () => {
    navigate(-1);
  };

  const handleCreateAdjustment = (requestData: CreateClaimAdjustmentRequest) => {
    setLoading(true);
    post(api(Consts.Api.ClaimAdjustment), validateRequestBody(requestData))
      .then(() => {
        setLoading(false);
        formik.resetForm();
        formik.validateForm();
        alertService.success('Claim adjustment successfully created', {id: defaultAlertId});
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      claimId: undefined,
      claimAmountTaxExcluded: undefined,
      rebateApprovalNumber: undefined,
      comment: undefined,
    } as never,
    validationSchema,
    onSubmit: handleCreateAdjustment,
  });

  useEffect(() => {
    const setParentValuesFromData = (data: ClaimDetailsViewModel) => {
      if (data.id) {
        formik.setFieldValue('claimId', data.id);
      }
      if (data.approvalNumber) {
        formik.setFieldValue('rebateApprovalNumber', data.approvalNumber);
      }
    };

    setLoading(true);
    get(api(Consts.Api.ClaimDetails.replace(':id', id)))
      .then((response) => {
        setLoading(false);
        setParentValuesFromData(response.data);
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setLoading]);

  const handleApprovalNumberError = (error: yup.ValidationError | null) => {
    if (error) {
      formik.setFieldError('rebateApprovalNumber', error.message);
    } else {
      formik.setFieldError('rebateApprovalNumber', undefined);
    }
  };

  const handleUpdateApprovalNumber = async (approvalNumber: string) => {
    formik.setFieldValue('rebateApprovalNumber', approvalNumber);
  };

  const noop = () => {};
  return (
    <Root className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <PageTabs title="Claim Adjustment" value={null} onChange={noop} tabsList={tabList} />
        <TableTabPanel loading={loading} className={classes.tabPanelRoot}>
          <Paper className={classes.paper}>
            <Grid container className={classes.root}>
              <Grid item>
                <Box className={classes.backContainer} onClick={handleNavAway}>
                  <Typography className={classes.typography} variant="h5">
                    Back
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Container className={classes.container}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography className={classes.typography} variant="h5" noWrap>
                        Claim Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Container className={classes.container}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item md={6} xs={12}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>Rebate</Grid>
                        <Grid item>
                          <Typography className={classes.fieldValues}>
                            {data?.agreementDescription ?? ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>Finance Account</Grid>
                        <Grid item>
                          <Typography className={classes.fieldValues}>
                            {data?.financeAccountNames ?? ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>Approval Number</Grid>
                        <Grid item>
                          <EditableApprovalNumber
                            isEditable
                            approvalNumber={formik.values.rebateApprovalNumber ?? ''}
                            onApprovalNumberUpdated={handleUpdateApprovalNumber}
                            onError={handleApprovalNumberError}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>Original Claim ID</Grid>
                        <Grid item>
                          <Typography className={classes.fieldValues}>{data?.id ?? ''}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Container className={classes.container}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography
                        className={`${classes.typography} ${classes.titleSpacing}`}
                        variant="h5"
                        noWrap
                      >
                        Adjustment Details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Container className={classes.container}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>
                          <Typography color="textPrimary">
                            New claim amount? (GST Exclusive)
                          </Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            name="claimAmountTaxExcluded"
                            fullWidth
                            autoFocus
                            onBlur={formik.handleBlur}
                            value={formik.values.claimAmountTaxExcluded ?? ''}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.claimAmountTaxExcluded &&
                              Boolean(formik.errors.claimAmountTaxExcluded)
                            }
                            helperText={
                              formik.touched.claimAmountTaxExcluded &&
                              formik.errors.claimAmountTaxExcluded
                            }
                            placeholder="$0.00"
                            InputProps={{
                              inputComponent: CurrencyFormatField as any,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>
                          {' '}
                          <Typography color="textPrimary">Comments (Optional) </Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            name="comment"
                            fullWidth
                            multiline
                            maxRows={8}
                            value={formik.values.comment ?? ''}
                            onChange={formik.handleChange}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                            inputProps={{'aria-label': 'comment'}}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Container className={classes.container}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    size="large"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    Create
                  </Button>
                </Container>
              </Grid>
            </Grid>
          </Paper>
        </TableTabPanel>
      </form>
    </Root>
  );
};

export default ClaimAdjustment;
