import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import {FinanceAccount} from '../../types';
import {getDisplayAmountValue} from '../../utils/AmountUtils';

const PREFIX = 'FinanceAccountsSummary';

const classes = {
  root: `${PREFIX}-root`,
  financeAccount: `${PREFIX}-financeAccount`,
  name: `${PREFIX}-name`,
  amountInc: `${PREFIX}-amountInc`,
  amountGst: `${PREFIX}-amountGst`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    minWidth: '17rem',
  },

  [`& .${classes.financeAccount}`]: {
    '&:not(:last-child)': {
      borderBottom: '1px solid #cbd2d8',
    },
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.75rem 0',
  },

  [`& .${classes.name}`]: {
    width: '40%',
    textAlign: 'left',
  },

  [`& .${classes.amountInc}`]: {
    fontWeight: 'bold',
    width: '30%',
    textAlign: 'center',
  },

  [`& .${classes.amountGst}`]: {
    fontWeight: 'normal',
    width: '30%',
    textAlign: 'right',
  },
}));

export const accountsTestId = 'finance-accounts-summary';

export type FinanceAccountsSummaryProps = {
  financeAccounts: FinanceAccount[];
};

const FinanceAccountsSummary: FC<FinanceAccountsSummaryProps> = ({financeAccounts}) => {
  return (
    <Root className={classes.root}>
      {financeAccounts?.length > 0 ? (
        <div data-testid={accountsTestId}>
          {financeAccounts.map((financeAccount) => (
            <div className={classes.financeAccount} key={financeAccount.name}>
              <span className={classes.name}>{financeAccount.name}</span>
              <span className={classes.amountInc}>
                {getDisplayAmountValue(financeAccount.amountInclusiveGst, '$')} inc.{' '}
              </span>
              <span className={classes.amountGst}>
                {getDisplayAmountValue(financeAccount.amountGst, '$')} GST
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </Root>
  );
};

export default FinanceAccountsSummary;
