import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Tab, {TabProps} from '@mui/material/Tab';
import TabTooltip from './TabTooltip';

const PREFIX = 'TabComponent';

const classes = {
  root: `${PREFIX}-root`,
  tabActive: `${PREFIX}-tabActive`,
  tabDisabled: `${PREFIX}-tabDisabled`,
};

const TabRoot = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '0 1rem',
    fontSize: '1rem',
    color: theme.palette.gray.mid,
    opacity: 0.7,
    '& .MuiTab-root': {
      minWidth: '8rem',
      fontSize: '1rem',
      '&.Mui-selected': {
        color: theme.palette.black.main,
        opacity: 1,
      },
    },
  },
  [`& .${classes.tabDisabled}`]: {
    backgroundColor: '#e1e1e1',
    opacity: '0.2 !important',
  },
}));

type Props = TabProps & {
  label: string;
  value: string | null;
  disabled?: boolean;
  tooltipText?: string;
};

const TabComponent: FC<Props> = ({label, value, disabled, tooltipText, ...tabsProps}) => {
  if (tooltipText && disabled) {
    return (
      <TabTooltip title={tooltipText}>
        <TabRoot className={classes.root}>
          <Tab
            label={label}
            value={value}
            classes={{
              root: classes.tabDisabled,
            }}
            disabled={disabled}
            {...tabsProps}
          />
        </TabRoot>
      </TabTooltip>
    );
  } else {
    return (
      <TabRoot className={classes.root}>
        <Tab label={label} value={value} disabled={disabled} {...tabsProps} />
      </TabRoot>
    );
  }
};

export default TabComponent;
