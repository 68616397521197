import * as Yup from 'yup';
import Consts from '../../app/Consts';
import {isValidDate} from '../../utils/DateUtils';

const stepContractAgreementDetailsValidationSchema = Yup.object({
  claimVendor: Yup.object().shape({
    name: Yup.string(),
    code: Yup.string().required(Consts.ValidationMessage.Required),
  }),
  description: Yup.string()
    .required(Consts.ValidationMessage.Required)
    .test(
      'desc-control-validation',
      Consts.ValidationMessage.AgreementNonPrintableCharsError,
      function (value) {
        // eslint-disable-next-line no-control-regex
        return !/[\u0000-\u001F\u007F]/g.test(value ?? '');
      }
    ),
  groupClaimsOnSingleInvoice: Yup.boolean(),
  startAt: Yup.date()
    .required(Consts.ValidationMessage.Required)
    .nullable()
    .typeError(Consts.ValidationMessage.Date.FormatError)
    .min(Consts.Date.AgreementStartDateMin, Consts.ValidationMessage.Date.MinStartDateRangeError),
  endAt: Yup.mixed().when(
    ['startAt', 'isOngoing'],
    //@ts-ignore
    function (startAt, isOngoing, schema) {
      if (!isOngoing) {
        let validation = Yup.date()
          .required(Consts.ValidationMessage.Required)
          .nullable()
          .typeError(Consts.ValidationMessage.Date.FormatError)
          .max(
            Consts.Date.MaxEndDateMonthsFromNow,
            Consts.ValidationMessage.Date.MaxEndDateRangeError
          );
        if (startAt && isValidDate(startAt)) {
          validation = validation.min(startAt, Consts.ValidationMessage.Date.EndDateRangeError);
        }
        return validation;
      }
      return schema;
    }
  ),
  id: Yup.number(),
});

export default stepContractAgreementDetailsValidationSchema;
