import {styled} from '@mui/material/styles';
import OutlinedButton from './OutlinedButton';

const AdvanceButton = styled(OutlinedButton)(() => ({
  [`&.MuiButton-root`]: {
    width: '220px !important',
    backgroundColor: '#000 !important',
    color: '#FFF !important',
    '&:hover': {
      backgroundColor: '#626262 !important',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.24) !important',
    },
  },
}));

export default AdvanceButton;
