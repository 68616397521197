import React, {useEffect, useState, useContext} from 'react';
import {styled} from '@mui/material/styles';
import {AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {hasRole} from '../../../app/Permission';
import {useAppSelector} from '../../../app/store';
import {selectProfileRoles} from '../../../app/selectors';
import {AttachmentType, AttachmentUploadResponse} from '../../../types';
import {api, getCached, del} from '../../../utils/Request';
import Button from '../../../components/Button/Button';
import FilesUploader, {
  classes as fileUploaderClasses,
} from '../../../components/DragDrop/FilesUploader';
import {DeleteActionButton} from '../../../components/Table';
import {DownloadActionButton} from '../../../components/Table/ActionIconButton';
import Attachment from '../../../components/Attachment/Attachment';

const FileUploaderRoot = styled('div')(() => ({
  width: '100%',
  [`& .${fileUploaderClasses.root}`]: {
    border: 'none !important',
    padding: '0 !important',
  },
  [`& .${fileUploaderClasses.headingButton}`]: {
    alignSelf: 'flex-end',
  },
  [`& .${fileUploaderClasses.title}`]: {
    fontSize: '1.625rem !important',
    marginBottom: '0px !important',
  },
  [`& .${fileUploaderClasses.subTitle}`]: {
    fontSize: '1.125rem !important',
    color: '#000 !important',
    marginTop: '0.75rem !important',
  },
  [`& .${fileUploaderClasses.headingContainer}`]: {
    marginBottom: '1.875rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [`& .${fileUploaderClasses.emptyContainer}`]: {
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 6px #00000029',
  },
}));

const UploadButton = styled(Button)`
  height: 3.625rem;
`;

const DealInformationTabContent = () => {
  const {id = ''} = useParams<{id: string}>();
  const profileRoles = useAppSelector(selectProfileRoles);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  const canDeleteAttachment = hasRole(Consts.UserRoleEnum.AddOrUpdateDeals, profileRoles);

  useEffect(() => {
    showLoading();
    const fetchAttachments = async () => {
      try {
        const response: AxiosResponse<AttachmentType[]> = await getCached(
          api(Consts.Api.DealAttachments.replace(':dealId', id))
        );
        setAttachments(response.data);
        alertService.clear(defaultAlertId);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    };
    fetchAttachments();
  }, [id, hideLoading, showLoading]);

  const deleteAttachment = async (attachmentId: string) => {
    try {
      showLoading();
      await del(
        api(Consts.Api.DealAttachment).replace(':dealId', id).replace(':id', attachmentId),
        {
          cache: {
            update: {
              [api(Consts.Api.DealAttachments).replace(':dealId', id).replace(':id', attachmentId)]:
                'delete',
            },
          },
        }
      );
      setAttachments((prevAttachments) => prevAttachments.filter((x) => x.id !== attachmentId));
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const downloadAttachment = async (attachment: AttachmentType) => {
    try {
      showLoading();
      const {data} = await getCached(
        api(Consts.Api.DealAttachment).replace(':dealId', id).replace(':id', attachment.id),
        {
          responseType: 'blob',
        }
      );
      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([data]));
      link.href = url;
      link.download = attachment.fileName;
      link.click();
      link.remove();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const uploadComplete = (response: AxiosResponse<AttachmentUploadResponse[]>) => {
    setAttachments((prevAttachments) => prevAttachments.concat(response.data));
  };

  return (
    <FileUploaderRoot>
      <FilesUploader
        maxSizeInMb={20}
        multiple={true}
        apiEndpoint={api(Consts.Api.DealAttachments.replace(':dealId', id))}
        accept={{
          'image/png': [],
          'image/jpeg': [],
          'image/jpg': [],
          'application/pdf': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // for docx
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
          'message/rfc822': ['.eml'], // for eml
          'text/csv': ['.csv'], // for csv
          'application/vnd.ms-outlook': ['.msg'], // for msg
        }}
        title="There are no attachments in this deal yet."
        subTitle="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
        onComplete={uploadComplete}
        noFiles={attachments.length === 0}
        showHeadingButton={attachments.length !== 0}
        showBottomButton={false}
        button={UploadButton}
        buttonLabel="Add your first attachment"
        continueAddButtonLabel="Add New Attachment"
        heading="Attachments"
      >
        <div>
          {attachments.map((attachment: AttachmentType, index: number) => (
            <Attachment
              key={index}
              fileName={attachment.fileName}
              sx={{padding: '0.625rem 1.875rem'}}
            >
              <DownloadActionButton
                style={{
                  marginRight: canDeleteAttachment ? '1rem' : '0',
                  color: 'green',
                }}
                onClick={() => {
                  downloadAttachment(attachment);
                }}
                data-testid="download-attachment-button"
              ></DownloadActionButton>
              {canDeleteAttachment && (
                <DeleteActionButton
                  onClick={() => {
                    deleteAttachment(attachment.id);
                  }}
                  data-testid="delete-attachment-button"
                ></DeleteActionButton>
              )}
            </Attachment>
          ))}
        </div>
      </FilesUploader>
    </FileUploaderRoot>
  );
};

export default DealInformationTabContent;
