import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ClaimVendor} from '../components/Form/Agolia/VendorSearch';
import {Order, Pagination} from '../types';
import Consts from './Consts';
import {Owner} from './ownersReducer';

export interface ContractAgreementFilter {
  claimVendor: ClaimVendor | null;
  startAt: string | null;
  endAt: string | null;
  owner: Owner | null;
  status: string | null;
}

export interface ContractAgreementsState {
  searchText: string | null;
  order: Order;
  pagination: Pagination;

  filterSelection: ContractAgreementFilter;
}

export const initialState: ContractAgreementsState = {
  searchText: null,
  order: {
    orderBy: 'status',
    orderByDirection: 'asc',
  },
  filterSelection: {
    claimVendor: null,
    startAt: null,
    endAt: null,
    owner: null,
    status: null,
  },
  pagination: {
    totalCount: Consts.DefaultPagination.totalCount,
    totalPages: Consts.DefaultPagination.totalPages,
    pageSize: Consts.DefaultPagination.pageSize,
    currentPage: Consts.DefaultPagination.currentPage,
  },
};

const sliceName = 'contractAgreements';

const contractAgreements = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setContractAgreementsSearch(state, action: PayloadAction<string | null>) {
      state.searchText = action.payload;
    },
    setContractAgreementsFilter(state, action: PayloadAction<ContractAgreementFilter>) {
      state.filterSelection = action.payload;
    },
    setContractAgreementsOwnerFilter(state, action: PayloadAction<Owner | null>) {
      state.filterSelection = {...state.filterSelection, owner: action.payload};
    },
    setContractAgreementsPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setContractAgreementsOrder(state, action: PayloadAction<Order>) {
      state.order = action.payload;
    },
  },
});

const contractAgreementsReducer = contractAgreements.reducer;
export const {
  setContractAgreementsSearch,
  setContractAgreementsFilter,
  setContractAgreementsOwnerFilter,
  setContractAgreementsPagination,
  setContractAgreementsOrder,
} = contractAgreements.actions;
export default contractAgreementsReducer;
