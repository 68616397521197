import {styled} from '@mui/material/styles';

const PageContainer = styled('div')`
  display: flex;
  flex: auto;
  width: 100%;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.background.page};
  overflow: auto;
  height: 100%;
`;

/** @component */
export default PageContainer;
