import React, {FC, useCallback, useContext, useState} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {AxiosResponse} from 'axios';
import {useFormikContext} from 'formik';
import LoadingContext from '../../../app/LoadingContext';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId, AlertType} from '../../../app/AlertService';
import {
  ValidMixAndMatchFormValues,
  ErrorMap,
  MixAndMatchGroupResponse,
  MixAndMatchGroupsResponse,
} from '../../../types';
import {api, post, del} from '../../../utils/Request';
import {ErrorBox} from '../../Alert';
import {StaticButton} from '../../Button';
import GroupConfirmModal from '../GroupConfirmModal';
import {isBundleType, mnmDisabled} from '../mixAndMatchUtils';
import GroupProductsTable from './GroupProductsTable';
import GroupAmountInputs from './GroupAmountInputs';
import BundleAmountInput from './BundleAmountInput';
import ClaimGroupSwitch from './ClaimGroupSwitch';

const GroupContainer = styled(Stack)(({theme}) => ({
  padding: '2rem',
  borderRadius: '0.5rem',
}));

type Props = {
  errors: ErrorMap;
};

const MixAndMatchGroups: FC<Props> = ({errors}) => {
  const [removeGroupModalOpen, setRemoveGroupModalOpen] = useState(false);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    values: {id, groups, type},
    setFieldValue,
  } = bag;

  const disabledActions = mnmDisabled(bag.values).actions;
  const handleAddGroup = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<MixAndMatchGroupsResponse> = await post(
        api(Consts.Api.MixAndMatchGroupsAdditionalBundleGroup.replace(':id', `${id}`))
      );
      setFieldValue('groups', response.data.data);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  }, [id, setFieldValue, showLoading, hideLoading]);

  const handleRemoveGroup = async () => {
    try {
      setRemoveGroupModalOpen(false);
      showLoading();
      const response: AxiosResponse<MixAndMatchGroupsResponse> = await del(
        api(Consts.Api.MixAndMatchGroupsAdditionalBundleGroup.replace(':id', `${id}`))
      );
      setFieldValue('groups', response.data.data);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleCheckBeforeRemoveGroup = () => {
    // additional group is always the last in array of 3
    const hasProducts = (groups?.[2]?.products?.data?.length ?? 0) > 0;
    if (hasProducts) {
      setRemoveGroupModalOpen(true);
    } else {
      handleRemoveGroup();
    }
  };

  const showGroups = !!type && groups && groups.length > 0;

  if (!showGroups) {
    return null;
  }

  const isBundle = isBundleType(type);
  const productSelectorToolImplemented = false;

  return (
    <Stack direction="column" spacing={2} sx={{marginBottom: '2rem', width: '100%'}}>
      <Stack direction="column" spacing={4}>
        {groups.map((group: MixAndMatchGroupResponse, index: number) => (
          <GroupContainer
            key={group.id}
            direction="column"
            spacing={4}
            sx={{
              position: 'relative',
              background: index === 0 ? '#F2F4F5' : index === 1 ? '#F2F9F2' : '#FFF9F0',
            }}
          >
            {index === 0 && productSelectorToolImplemented ? (
              <ErrorBox
                type={AlertType.Info}
                sx={{
                  border: 'solid 1px #005EAB',
                  width: 'fit-content',
                  padding: '0.5rem 1rem',
                }}
              >
                <div>Use the product selector tool in the tool bar to easily select products.</div>
              </ErrorBox>
            ) : null}
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="h6">{group.description}</Typography>
              {index === 2 && isBundle ? (
                <StaticButton
                  type="button"
                  onClick={handleCheckBeforeRemoveGroup}
                  sx={{height: 0}}
                  endIcon={<ClearOutlinedIcon />}
                  disabled={disabledActions.removeGroup}
                >
                  Remove
                </StaticButton>
              ) : null}
            </Stack>
            <Stack direction="column" spacing={1}>
              <ClaimGroupSwitch groupId={group.id} />
            </Stack>
            <GroupProductsTable groupId={group.id} />
            {!!errors[group.id]?.products ? (
              <ErrorBox
                data-testid="mixandmatch-products-errorbox"
                type={AlertType.Warning}
                sx={{
                  border: 'solid 1px #DA6A00',
                  paddingTop: '0rem',
                  paddingBottom: '0rem',
                  width: 'fit-content',
                  svg: {
                    width: '1.5rem',
                  },
                }}
              >
                {errors[group.id]?.products}
              </ErrorBox>
            ) : null}
            <GroupAmountInputs
              groupId={group.id}
              inputDetail={Consts.MixAndMatchTypeMapping[type]?.groupInputConfig[index]}
              errors={errors[group.id]}
            />
          </GroupContainer>
        ))}
      </Stack>
      {isBundle ? (
        <Stack direction="column" gap="2rem" sx={{alignItems: 'flex-start', marginTop: '1rem'}}>
          <BundleAmountInput />
          <StaticButton
            type="button"
            disabled={groups.length >= 3 || !id || disabledActions.addGroup}
            onClick={handleAddGroup}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add another product to this bundle?
          </StaticButton>
        </Stack>
      ) : null}
      <GroupConfirmModal
        onOk={handleRemoveGroup}
        onCancel={() => setRemoveGroupModalOpen(false)}
        open={removeGroupModalOpen}
        title="Are you sure?"
        body="This action will remove this product group, permanently deleting any deal value data that you have entered. Would you like to continue?"
      />
    </Stack>
  );
};

export default MixAndMatchGroups;
