import React from 'react';
import {useField, useFormikContext} from 'formik';
import Switch from './Switch';

const OngoingSwitch = ({disabled = false, onChanged = () => {}, ...props}) => {
  const [field, , helpers] = useField(props.name);
  const {setValue} = helpers;
  const bag = useFormikContext();
  const {setFieldValue} = bag;

  function updateValue(checked) {
    if (checked) {
      setValue(true);
    } else {
      setValue(false);
    }
  }
  function onSwitch(checked) {
    updateValue(checked);
    onChanged(checked);
    if (checked) {
      setFieldValue('endAt', null);
    }
  }
  return (
    <Switch
      disabled={disabled}
      {...props}
      color="primary"
      onChanged={onSwitch}
      defaultValue={field.value}
    />
  );
};

export default OngoingSwitch;
