import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {api, get} from '../utils/Request';
import Consts from './Consts';
import config from './Config';
import _debounce from 'lodash/debounce';

export interface Owner {
  code: number;
  name: string;
}

export interface OwnersState {
  owners: Owner[];
  ownersError: any;
  loadingOwners: boolean;
}

export const initialState: OwnersState = {
  owners: [],
  ownersError: null,
  loadingOwners: false,
};

export interface SearchOwnersRequest {
  text: string;
  pageSize: number;
}

export const debounceSearchForOwners = _debounce(
  (dispatch: any, inputText?: string) => searchForOwners(dispatch, inputText),
  250
);

const searchForOwners = (dispatch: any, inputText = '') => {
  const searchOwnersRequest: SearchOwnersRequest = {
    text: inputText,
    pageSize: 20,
  };
  dispatch(searchOwners(searchOwnersRequest));
};

export const searchOwners = createAsyncThunk(
  'searchOwners',
  async (request: SearchOwnersRequest, thunkAPI) => {
    return get(api(Consts.Api.Owners), {
      params: {
        entityCode: config.entityCode,
        searchText: request.text,
        pageSize: request.pageSize,
      },
    }).then((response) => response.data);
  }
);

const ownersSlice = createSlice({
  name: 'owners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchOwners.pending.type, (state) => {
        state.loadingOwners = true;
      })
      .addCase(searchOwners.fulfilled.type, (state, action: any) => {
        state.loadingOwners = false;
        state.owners = action.payload.data;
      })
      .addCase(searchOwners.rejected.type, (state, action: any) => {
        state.loadingOwners = false;
        state.ownersError = action.error;
      });
  },
});

const ownersReducer = ownersSlice.reducer;
export default ownersReducer;
