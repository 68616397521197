import React, {FC, useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {api, put} from '../../utils/Request';
import {RebateFormValues} from '../../types';
import StepperForm from '../Form/StepperForm';
import StepRebateDetails from './StepRebateDetails';
import StepRebateValue from './StepRebateValue';
import StepFinanceDetails from './StepFinanceDetails';
import stepRebateDetailsValidationSchema from './StepRebateDetailsValidationSchema';
import stepRebateValueValidationSchema from './StepRebateValueValidationSchema';
import stepFinanceDetailsValidationSchema from './StepFinanceDetailsValidationSchema';
import StepTagsAndAttachments from './StepTagsAndAttachments';
import stepTagsAndAttachmentsValidationSchema from './StepTagsAndAttachmentsValidationSchema';

const ContentHeaderContainer = styled('div')`
  line-height: 3.375rem;
  padding-left: 3.125rem;
  color: #626262;
  background: #f7f9fa;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 1.125rem;
  & > span {
    font-weight: 500;
    color: #000;
  }
`;
function getSteps() {
  return ['Rebate Details', 'Add Rebate Value', 'Finance Details', 'Attachments'];
}

type Props = {
  title: string;
  initialValues: any;
  isEditing: boolean;
};

const RebateForm: FC<Props> = ({title, initialValues, isEditing}) => {
  const navigate = useNavigate();
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const [draftCreationFinished, setDraftCreationFinished] = useState(false);
  const [agreementId, setAgreementId] = useState<number>();

  const lastStepButtonText = isEditing ? 'Finish' : 'Create Rebate';

  useEffect(() => {
    // true once, after rebate finalisation
    if (agreementId && draftCreationFinished) {
      navigate(Consts.RouterPath.ContractAgreementSummary.replace(':id', `${agreementId}`));
    }
  }, [agreementId, draftCreationFinished, navigate]);

  function getStepContent(
    index: number,
    handleBack: (values: RebateFormValues) => void,
    handleNext: (values: RebateFormValues) => void
  ) {
    let step = index + 1;
    switch (index) {
      case 0:
        return (
          <StepRebateDetails
            step={step}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={handleNext}
            lastStepButtonText={lastStepButtonText}
            validationSchema={stepRebateDetailsValidationSchema}
          />
        );
      case 1:
        return (
          <StepRebateValue
            step={step}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={handleNext}
            lastStepButtonText={lastStepButtonText}
            validationSchema={stepRebateValueValidationSchema}
          />
        );
      case 2:
        return (
          <StepFinanceDetails
            step={step}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={handleNext}
            lastStepButtonText={lastStepButtonText}
            validationSchema={stepFinanceDetailsValidationSchema}
          />
        );
      case 3:
        return (
          <StepTagsAndAttachments
            step={step}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={handleNext}
            validationSchema={stepTagsAndAttachmentsValidationSchema}
          />
        );
      default:
        return null;
    }
  }
  function finishRebateCreation(values: RebateFormValues) {
    if (!values.id) {
      return;
    }
    showLoading();
    put(api(Consts.Api.FinishRebateCreation.replace(':id', `${values.id}`)))
      .then(() => {
        setDraftCreationFinished(true);
        setAgreementId(values.agreementId);
        hideLoading();
        alertService.clear(defaultAlertId);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }
  const getContentHeader = () => {
    return (
      <ContentHeaderContainer data-testid="rebate-form-content-header">
        You are{' '}
        {isEditing ? (
          <>
            editing <span>rebate ID: {initialValues.id}</span> for{' '}
          </>
        ) : (
          'adding a rebate for '
        )}
        <span>{initialValues.claimVendorName}</span> under contract agreement{' '}
        <span>{initialValues.agreementId}</span> ("
        <i>{initialValues.agreementDescription}</i>")
      </ContentHeaderContainer>
    );
  };

  return (
    <StepperForm
      title={title}
      initialValues={initialValues}
      onSubmit={async (values: RebateFormValues) => {
        if (values.draftMode) {
          navigate(
            Consts.RouterPath.ContractAgreementSummary.replace(':id', `${values.agreementId}`)
          );
        } else {
          finishRebateCreation(values);
        }
      }}
      steps={getSteps()}
      contentHeader={getContentHeader()}
      getStepContent={getStepContent}
      routeChangeMessage="Your rebate has been saved to My Drafts"
      showRouteChangeMessage={(values) =>
        !draftCreationFinished && values.id && values.status === Consts.RebateStatusEnum.Draft
      }
    />
  );
};

export default RebateForm;
