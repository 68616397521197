import {Order} from '../types';

export const createOrderFromParams = (
  orderParam: string,
  callback: (order: Order) => void
): Order | null => {
  const newOrderBySplit = orderParam.split(':');
  if (newOrderBySplit && newOrderBySplit.length === 2) {
    const [orderBy, orderByDirection] = newOrderBySplit as [string, Order['orderByDirection']];
    const newOrder: Order = {
      orderBy,
      orderByDirection,
    };
    callback(newOrder);
    return newOrder;
  }
  return null;
};
