import React, {FC, useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {type AxiosResponse} from 'axios';
import {styled} from '@mui/material/styles';
import {api, del, getCached} from '../../../utils/Request';
import Consts from '../../../app/Consts';
import {hasRole} from '../../../app/Permission';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {useAppSelector} from '../../../app/store';
import {selectProfileRoles} from '../../../app/selectors';
import LoadingContext from '../../../app/LoadingContext';
import {AttachmentUploadResponse} from '../../../types';
import FilesUploader, {
  classes as fileUploaderClasses,
} from '../../../components/DragDrop/FilesUploader';
import Button from '../../../components/Button/Button';
import {DeleteActionButton, DownloadActionButton} from '../../../components/Table/ActionIconButton';
import AttachmentItem from '../../../components/Attachment/Attachment';

const UploadButton = styled(Button)`
  height: 3.625rem;
`;

const FileUploaderRoot = styled('div')(() => ({
  width: '100%',
  [`& .${fileUploaderClasses.root}`]: {
    border: 'none !important',
    padding: '0 !important',
  },
  [`& .${fileUploaderClasses.headingButton}`]: {
    alignSelf: 'flex-end',
  },
  [`& .${fileUploaderClasses.title}`]: {
    fontSize: '1.625rem !important',
    marginBottom: '0px !important',
  },
  [`& .${fileUploaderClasses.subTitle}`]: {
    fontSize: '1.125rem !important',
    color: '#000 !important',
    marginTop: '0.75rem !important',
  },
  [`& .${fileUploaderClasses.headingContainer}`]: {
    marginBottom: '1.875rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [`& .${fileUploaderClasses.emptyContainer}`]: {
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 6px #00000029',
  },
}));

const RebateAttachments: FC = () => {
  const {id = ''} = useParams<{id: string}>();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const profileRoles = useAppSelector(selectProfileRoles);
  const [attachments, setAttachments] = useState<AttachmentUploadResponse[]>([]);

  const canDeleteAttachment = hasRole(Consts.UserRoleEnum.AddOrUpdateDeals, profileRoles);

  useEffect(() => {
    showLoading();
    getCached(api(Consts.Api.RebateAttachments.replace(':rebateId', id)))
      .then((response) => {
        setAttachments(response.data);
        hideLoading();
        alertService.clear(defaultAlertId);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [id, hideLoading, showLoading]);

  const uploadComplete = (response: AxiosResponse<AttachmentUploadResponse[]>) => {
    setAttachments((prevAttachments) => prevAttachments.concat(response.data));
  };

  const deleteAttachment = (attachmentId: string) => {
    showLoading();
    del(api(Consts.Api.RebateAttachment).replace(':rebateId', id).replace(':id', attachmentId), {
      cache: {
        update: {
          [api(Consts.Api.RebateAttachments).replace(':rebateId', id)]: 'delete',
        },
      },
    })
      .then(() => {
        hideLoading();
        setAttachments((prevAttachments) => prevAttachments.filter((x) => x.id !== attachmentId));
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const downloadAttachment = (attachment: AttachmentUploadResponse) => {
    showLoading();
    getCached(
      api(Consts.Api.RebateAttachment).replace(':rebateId', id).replace(':id', attachment.id),
      {
        responseType: 'blob',
      }
    )
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = attachment.fileName;
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  return (
    <FileUploaderRoot>
      <FilesUploader
        maxSizeInMb={20}
        multiple={true}
        apiEndpoint={api(Consts.Api.RebateAttachments.replace(':rebateId', id))}
        accept={{
          'image/png': [],
          'image/jpeg': [],
          'image/jpg': [],
          'application/pdf': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // for docx
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
          'message/rfc822': ['.eml'], // for eml
          'text/csv': ['.csv'], // for csv
          'application/vnd.ms-outlook': ['.msg'], // for msg
        }}
        title="There are no attachments in this rebate yet."
        subTitle="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
        onComplete={uploadComplete}
        noFiles={attachments.length === 0}
        showHeadingButton={attachments.length !== 0}
        showBottomButton={false}
        button={UploadButton}
        buttonLabel={'Add your first attachment'}
        continueAddButtonLabel={'Add New Attachment'}
        heading="Attachments"
      >
        <div>
          {attachments.map((attachment, index) => {
            return (
              <AttachmentItem
                key={index}
                fileName={attachment.fileName}
                sx={{padding: '10px 30px'}}
              >
                <DownloadActionButton
                  style={{
                    marginRight: canDeleteAttachment ? '15px' : '0',
                    color: 'green',
                  }}
                  onClick={() => {
                    downloadAttachment(attachment);
                  }}
                ></DownloadActionButton>
                {canDeleteAttachment ? (
                  <DeleteActionButton
                    onClick={() => {
                      deleteAttachment(attachment.id);
                    }}
                  ></DeleteActionButton>
                ) : null}
              </AttachmentItem>
            );
          })}
        </div>
      </FilesUploader>
    </FileUploaderRoot>
  );
};

export default RebateAttachments;
