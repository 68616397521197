import React, {useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import {styled} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {UnreleasedAmountsTabType} from '../../../app/unreleasedAmountsReducer';
import {
  resetUnreleasedProducts,
  searchUnreleasedProducts,
  SearchUnreleasedProductsRequest,
  toggleProductSelected,
  UnreleasedProductData,
  updateProductCurrentPage,
  updateProductOrderBy,
  updateProductPageSize,
} from '../../../app/unreleasedProductsReducer';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {TableColumn, Pagination} from '../../../types';
import {SimpleDataTable} from '../../SimpleDataTable';
import {TableTabPanel} from '../../TableTabPanel';

const PREFIX = 'UnreleasedProductTable';

const classes = {
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};

const MarkedForReleaseCheckbox = styled(Checkbox)(({theme}) => ({
  '&.Mui-disabled': {
    color: theme.palette.green.main,
    cursor: 'not-allowed',
  },
}));

const Root = styled('div')(() => ({
  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    margin: '50px 0',
  },
}));

function UnreleasedProductTable() {
  const dispatch = useAppDispatch();
  const {tabType} = useAppSelector((s) => s.unreleasedAmounts);

  const {
    data,
    departmentFilters,
    supplierFilter,
    seasonCodeFilter,
    searchText,
    pageSize,
    totalCount,
    totalPages,
    currentPage,
    orderBy,
  } = useAppSelector((s) => s.unreleasedProducts);
  const pagination = {pageSize, totalCount, totalPages, currentPage};

  const columns: TableColumn<UnreleasedProductData>[] = [
    {
      id: 'selection',
      label: '',
      render: (rowData: UnreleasedProductData) => {
        return (
          <>
            {rowData.markedForRelease ? (
              <MarkedForReleaseCheckbox checked disabled />
            ) : (
              <Checkbox
                checked={rowData.selected}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                  dispatch(toggleProductSelected(rowData.id));
                }}
              />
            )}
          </>
        );
      },
    },
    {id: 'productCode', label: 'Product Code', sortable: true},
    {id: 'productDescription', label: 'Product Description'},
    {id: 'departmentNumber', label: 'Department Number'},
    {id: 'departmentName', label: 'Department Name'},
    {id: 'supplierNumber', label: 'Supplier Number', sortable: true},
    {id: 'supplierName', label: 'Supplier Name'},
    {id: 'seasonCode', label: 'Season Code'},
    {
      id: 'unreleasedRebate',
      label: 'Unreleased Rebate',
      sortable: true,
      render: (rowData: UnreleasedProductData) => {
        return getDisplayAmountValue(rowData.unreleasedRebate, '$');
      },
    },
  ];

  useEffect(() => {
    const request: SearchUnreleasedProductsRequest = {
      searchText,
      pageSize,
      currentPage,
      orderBy,
      seasonCode: seasonCodeFilter,
      supplierNumber: supplierFilter?.number ?? null,
      departmentNumbers: departmentFilters.map((x) => x.number),
    };
    dispatch(searchUnreleasedProducts(request));
  }, [
    dispatch,
    tabType,
    departmentFilters,
    supplierFilter,
    seasonCodeFilter,
    searchText,
    orderBy,
    currentPage,
    pageSize,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetUnreleasedProducts());
    };
  }, [dispatch]);

  return (
    <Root>
      {tabType === UnreleasedAmountsTabType.Product && (
        <TableTabPanel className={classes.tabPanelRoot} data-testid="unreleased-products-table">
          <SimpleDataTable
            columns={columns}
            rows={data}
            pagination={pagination}
            onChangePage={({currentPage}: Pick<Pagination, 'currentPage'>) => {
              dispatch(updateProductCurrentPage(currentPage));
            }}
            onChangePageSize={({pageSize}: Pick<Pagination, 'pageSize'>) => {
              dispatch(updateProductCurrentPage(1));
              dispatch(updateProductPageSize(pageSize));
            }}
            onOrderBy={(e) => {
              dispatch(updateProductOrderBy(e.orderBy));
            }}
            sortOrderBy="unreleasedRebate"
            sortOrder="desc"
          />
        </TableTabPanel>
      )}
    </Root>
  );
}

export default UnreleasedProductTable;
