import React, {FC, Fragment, useState, useMemo, useCallback} from 'react';
import {styled} from '@mui/material/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as R from 'ramda';
import Consts from '../../app/Consts';
import {Pagination, TableColumn, Order, AdditionalLineMode} from '../../types';
import PageNavigator from '../Table/Pagination/PageNavigator';
import PageCounter from '../Table/Pagination/PageCounter';
import PageSetting from '../Table/Pagination/PageSetting';
import SectionContainer from '../Table/Pagination/SectionContainer';

const TABLEROW_PREFIX = 'SimpleDataTable-TableRow';

const tableRowClasses = {
  root: `${TABLEROW_PREFIX}-root`,
  adjustmentRow: `${TABLEROW_PREFIX}-adjustmentRow`,
  expandedView: `${TABLEROW_PREFIX}-expandedView`,
  hover: `${TABLEROW_PREFIX}-hover`,
  cancelled: `${TABLEROW_PREFIX}-cancelled`,
  highlightRow: `${TABLEROW_PREFIX}-highlightRow`,
};

const StyledTableRow = styled(TableRow)<{nocursor?: string}>(({theme, nocursor}) => ({
  [`&.${tableRowClasses.root}`]: {
    backgroundColor: theme.palette.white.main,
  },
  [`&.${tableRowClasses.adjustmentRow}`]: {
    backgroundColor: theme.palette.primary['200' as keyof typeof theme.palette.primary],
  },
  [`&.${tableRowClasses.expandedView}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  [`&.${tableRowClasses.hover}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    '&:hover': {
      cursor: nocursor === 'true' ? 'default' : 'pointer',
    },
  },
  [`&.${tableRowClasses.cancelled}`]: {
    backgroundColor: theme.palette.red.tint,
  },
  [`&.${tableRowClasses.highlightRow}`]: {
    backgroundColor: theme.palette.gray.lightMid,
  },
}));

const LIGHT_THEME_PREFIX = 'SimpleDataTable-LightTheme';

const lightThemeClasses = {
  root: `${LIGHT_THEME_PREFIX}-root`,
  tableContainer: `${LIGHT_THEME_PREFIX}-tableContainer`,
  tableHeading: `${LIGHT_THEME_PREFIX}-tableHeading`,
  tableHeadingFirst: `${LIGHT_THEME_PREFIX}-tableHeadingFirst`,
  tableBody: `${LIGHT_THEME_PREFIX}-tableBody`,
  firstCell: `${LIGHT_THEME_PREFIX}-firstCell`,
  highlight: `${LIGHT_THEME_PREFIX}-highlight`,
  hover: `${LIGHT_THEME_PREFIX}-hover`,
  sortIcon: `${LIGHT_THEME_PREFIX}-sortIcon`,
  sortLabelActive: `${LIGHT_THEME_PREFIX}-sortLabelActive`,
  sortLabel: `${LIGHT_THEME_PREFIX}-sortLabel`,
};

const DARK_THEME_PREFIX = 'SimpleDataTable-DarkTheme';

const darkThemeClasses = {
  root: `${DARK_THEME_PREFIX}-root`,
  tableContainer: `${DARK_THEME_PREFIX}-tableContainer`,
  tableHeading: `${DARK_THEME_PREFIX}-tableHeading`,
  tableHeadingFirst: `${DARK_THEME_PREFIX}-tableHeadingFirst`,
  tableBody: `${DARK_THEME_PREFIX}-tableBody`,
  firstCell: `${DARK_THEME_PREFIX}-firstCell`,
  highlight: `${DARK_THEME_PREFIX}-highlight`,
  hover: `${DARK_THEME_PREFIX}-hover`,
  sortIcon: `${DARK_THEME_PREFIX}-sortIcon`,
  sortLabelActive: `${DARK_THEME_PREFIX}-sortLabelActive`,
  sortLabel: `${DARK_THEME_PREFIX}-sortLabel`,
};

const StylePaper = styled(Paper)(({theme}) => ({
  [`&.${lightThemeClasses.root}, &.${darkThemeClasses.root}`]: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
  },
  [`& .${lightThemeClasses.tableContainer}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  [`& .${darkThemeClasses.tableContainer}`]: {
    backgroundColor: theme.palette.gray.extraLight,
  },
  [`& .${lightThemeClasses.tableHeading}`]: {
    fontSize: '16px',
    color: theme.palette.gray.mid,
    backgroundColor: theme.palette.white.main,
    height: '42px',
  },
  [`& .${darkThemeClasses.tableHeading}`]: {
    fontSize: '16px',
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    height: '50px',
  },
  [`& .${lightThemeClasses.tableHeadingFirst}`]: {
    fontSize: '16px',
    color: theme.palette.gray.mid,
    backgroundColor: theme.palette.white.main,
    paddingLeft: '25px',
  },
  [`& .${darkThemeClasses.tableHeadingFirst}`]: {
    fontSize: '16px',
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
    paddingLeft: '25px',
    height: '50px',
  },
  [`& .${lightThemeClasses.tableBody}, & .${darkThemeClasses.tableBody}`]: {
    fontSize: '16px',
    whiteSpace: 'pre-wrap',
  },
  [`& .${lightThemeClasses.hover}, & .${darkThemeClasses.hover}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  [`& .${lightThemeClasses.firstCell}, & .${darkThemeClasses.firstCell}`]: {
    fontSize: '16px',
    paddingLeft: '25px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  [`& .${lightThemeClasses.highlight}, & .${darkThemeClasses.highlight}`]: {
    fontWeight: 500,
  },
  [`& .${lightThemeClasses.sortIcon}`]: {
    opacity: '1',
  },
  [`& .${darkThemeClasses.sortIcon}`]: {
    color: `${theme.palette.white.main} !important`,
    opacity: 0.5,
    '&:hover': {
      color: theme.palette.white.main,
      opacity: 1,
    },
  },
  [`& .${darkThemeClasses.sortLabel}`]: {
    color: theme.palette.gray.mid,
    '&:hover': {
      color: theme.palette.white.main,
    },
    '&.MuiTableSortLabel-root.Mui-active': {
      color: theme.palette.white.main,
    },
  },
}));

const TablePaginationContainer = styled(SectionContainer)`
  justify-content: space-between;
  height: 60px;
  padding: 10px 18px 0px;
  background-color: #f2f4f5;

  input,
  .MuiSelect-select {
    background-color: #ffffff;
  }
`;

const SpacerTableRow = styled(TableRow)`
  background: rgba(0, 0, 0, 0.04);
  height: 1rem;
`;

type Props = {
  columns: TableColumn[];
  rows: any[];
  pagination?: Pagination;
  onChangePage?: (pagination: Pick<Pagination, 'currentPage'>) => void;
  onChangePageSize?: (pagination: Pick<Pagination, 'pageSize' | 'currentPage'>) => void;
  onRowClick?: (id: number, ctrlKeyPressed: boolean) => void;
  firstColumnSticky?: boolean;
  stickyHeader?: boolean;
  darkTheme?: boolean;
  highlightColumn?: string | null;
  highlightRow?: string;
  onOrderBy?: ({orderBy}: Pick<Order, 'orderBy'>) => void;
  sortOrder?: Order['orderByDirection'];
  sortOrderBy?: string;
  hideRowSpacer?: boolean;
};

const getAdditionalLineClass = (rowData: any, standAloneRow: boolean) => {
  if (rowData.parentClaimId) {
    return tableRowClasses.adjustmentRow;
  } else if (standAloneRow) {
    return tableRowClasses.expandedView;
  } else if (rowData.status === Consts.AgreementStatusEnum.Cancelled) {
    return tableRowClasses.cancelled;
  } else if (!!rowData.parentClaimId) {
    return tableRowClasses.adjustmentRow;
  }
  return tableRowClasses.root;
};

const SimpleDataTable: FC<Props> = ({
  columns,
  rows,
  pagination,
  onChangePage,
  onChangePageSize,
  onRowClick,
  firstColumnSticky = false,
  stickyHeader = false,
  darkTheme = false,
  highlightColumn = 'description',
  highlightRow,
  onOrderBy,
  sortOrder,
  sortOrderBy = '',
  hideRowSpacer,
}) => {
  const [orderBy, setOrderBy] = useState(sortOrderBy);
  const [order, setOrder] = useState<Order['orderByDirection']>(sortOrder);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const classes = darkTheme ? darkThemeClasses : lightThemeClasses;

  const getMainRowClass = (rowData: any, hovered: boolean) => {
    if (hovered) {
      return tableRowClasses.hover;
    } else if (!!rowData.parentClaimId) {
      return tableRowClasses.adjustmentRow;
    } else if (rowData.status === Consts.AgreementStatusEnum.Cancelled) {
      return tableRowClasses.cancelled;
    }
    return '';
  };

  const handlePageSizeChange = (value: number) => {
    onChangePageSize?.({
      pageSize: value,
      currentPage: 1,
    });
  };
  const handlePageChange = (currentPage: Pagination['currentPage']) => {
    onChangePage?.({
      currentPage,
    });
  };

  const handleRowClick = (id: number, event: React.MouseEvent<Element>) => {
    var selection = window.getSelection();
    if (selection?.type !== 'Range' && onRowClick) {
      onRowClick(id, event.ctrlKey);
    }
  };
  const sort = (property: string) => (_event: React.MouseEvent) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    onOrderBy?.({orderBy: `${property}:${newOrder}`});
  };
  const mainColumns = useMemo(() => columns.filter((x) => !x.additionalLine), [columns]);

  /**
   * Group columns by additionalLine property
   * Does not include columns with falsey additionalLine
   * returns TableColumn[][] where TableColumn[] is a group of columns with the same additionalLine
   */
  const additionalColumnsGroup = useMemo(
    () =>
      R.groupWith(
        (a: TableColumn, b: TableColumn) => a.additionalLine === b.additionalLine,
        R.filter((x) => Boolean(x.additionalLine), columns)
      ),
    [columns]
  );
  /**
   * Only returns a group of columns if at least one of the columns is shown
   */
  const getShownAdditionalColumnsGroup = (columnsGroups: TableColumn[][], rowData: any) =>
    columnsGroups.filter((groupedColumns) => groupedColumns.some((c) => c.isShown?.(rowData)));

  /**
   * Returns a tuple of
   * 1) is first child (boolean) and
   * 2) total claim amount if last child (number | null)
   */
  const getAdjChildData = useCallback(
    (claimId: number, parentClaimId: number): [boolean, number | undefined] => {
      if (!parentClaimId || !claimId) {
        return [false, undefined];
      }
      const childRows = rows.filter((row) => row.parentClaimId === parentClaimId);
      const parentRow = rows.find((row) => row.id === parentClaimId);
      const totalClaimAmount =
        Number(parentRow?.totalClaimAmount ?? 0) +
        childRows.reduce((acc, row) => acc + Number(row.totalClaimAmount), 0);

      const claimIndex = childRows.findIndex((row) => row.id === claimId);
      const isLastChildClaim = claimIndex === childRows.length - 1;
      return [claimIndex === 0, isLastChildClaim ? totalClaimAmount : undefined];
    },
    [rows]
  );

  const renderColumns = (column: TableColumn, rowData: any, rowIndex: number) => {
    if (column.render) {
      if (!!rowData.parentClaimId) {
        const [isFirstOfChildClaims, totalClaimAmount] = getAdjChildData(
          rowData.id,
          rowData.parentClaimId
        );
        return column.render(rowData, rowIndex, isFirstOfChildClaims, totalClaimAmount);
      } else {
        return column.render(rowData, rowIndex);
      }
    } else {
      return rowData[column.id];
    }
  };

  const renderTable = () => {
    return (
      <Table stickyHeader={stickyHeader}>
        <TableHead className={classes.tableHeading}>
          <StyledTableRow
            classes={{
              root: tableRowClasses.root,
            }}
          >
            {mainColumns.map((column, index) => (
              <TableCell
                key={index}
                className={index === 0 ? classes.tableHeadingFirst : classes.tableHeading}
                align={column.align}
                style={
                  firstColumnSticky && index === 0
                    ? {minWidth: column.minWidth, position: 'sticky', zIndex: 99}
                    : {minWidth: column.minWidth}
                }
              >
                {column.sortable ? (
                  <TableSortLabel
                    classes={{
                      icon: classes.sortIcon,
                      root: classes.sortLabel,
                      active: classes.sortLabelActive,
                    }}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={sort(column.id)}
                    IconComponent={orderBy === column.id ? ExpandMore : UnfoldMoreIcon}
                  >
                    {column.label ?? null}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((rowData, rowIndex) => {
            const shownAdditionalColumnsGroup = getShownAdditionalColumnsGroup(
              additionalColumnsGroup,
              rowData
            );
            // Show spacer row unless the next row is a child claim adjustment
            const showSpacer =
              shownAdditionalColumnsGroup.length === 0 &&
              !Boolean(rows[rowIndex + 1]?.parentClaimId) &&
              !hideRowSpacer;
            const showAddColSpacer =
              shownAdditionalColumnsGroup.length > 0 && !Boolean(rows[rowIndex + 1]?.parentClaimId);
            return (
              <Fragment key={rowIndex}>
                <StyledTableRow
                  key={rowIndex}
                  hover
                  onClick={(event) => handleRowClick(rowData.id, event)}
                  className={`${tableRowClasses.root} ${getMainRowClass(
                    rowData,
                    rowIndex === hoveredRow
                  )}
                   ${highlightRow === `${rowData.id}` ? tableRowClasses.highlightRow : ''}`}
                  onMouseEnter={() => setHoveredRow(rowIndex)}
                  onMouseLeave={() => setHoveredRow(null)}
                  nocursor={`${!Boolean(onRowClick)}`}
                >
                  {/**
                   * Map data row cells to headers,
                   * mainColumns excludes additional lines
                   * which are displayed at column.render
                   * */}
                  {mainColumns.map((column, colIndex) => {
                    let style: React.CSSProperties =
                      firstColumnSticky && colIndex === 0
                        ? {
                            ...column.style,
                            position: 'sticky',
                            zIndex: 98,
                            top: 0,
                            left: 0,
                            backgroundColor: '#ffffff',
                          }
                        : {...column.style};

                    if (shownAdditionalColumnsGroup.length !== 0) {
                      style = {...style, borderBottom: 'none', paddingBottom: 0};
                    }
                    return (
                      <TableCell
                        key={column.id}
                        className={`${colIndex === 0 ? classes.firstCell : classes.tableBody} ${
                          column.cellOnClick ? classes.hover : ''
                        } ${
                          highlightColumn !== null && highlightColumn === column.id
                            ? classes.highlight
                            : ''
                        }`}
                        style={style}
                        onClick={(event) => {
                          var selection = window.getSelection();
                          if (selection?.type !== 'Range' && column.cellOnClick) {
                            column.cellOnClick(rowData, colIndex, event.ctrlKey);
                          }
                        }}
                      >
                        {renderColumns(column, rowData, rowIndex)}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
                {showSpacer ? <SpacerTableRow /> : null}
                {shownAdditionalColumnsGroup.map((groupedColumns, groupIndex) => {
                  // column group includes expanded view component (only case where standaloneRow is true)
                  let standaloneRow = groupedColumns.some(
                    (c) => c.additionalLineMode === AdditionalLineMode.Standalone
                  );
                  return (
                    <StyledTableRow
                      key={`${rowIndex}-${groupIndex}`}
                      hover
                      onClick={(event) =>
                        !standaloneRow ? handleRowClick(rowData.id, event) : null
                      }
                      className={`${
                        rowIndex === hoveredRow && !standaloneRow ? tableRowClasses.hover : ''
                      } ${getAdditionalLineClass(rowData, standaloneRow)}`}
                      onMouseEnter={() => (!standaloneRow ? setHoveredRow(rowIndex) : null)}
                      onMouseLeave={() => (!standaloneRow ? setHoveredRow(null) : null)}
                      nocursor={`${!Boolean(onRowClick)}`}
                    >
                      {groupedColumns.map((column, columnIndex) => {
                        let style = column.style;
                        if (groupIndex !== shownAdditionalColumnsGroup.length - 1) {
                          style = {...style, borderBottom: 'none'};
                        }
                        return (
                          <Fragment key={`${rowIndex}-${column.additionalLine}-${columnIndex}`}>
                            {column.colspanLeft ? (
                              <TableCell
                                key={`${rowIndex}-${column.additionalLine}-${columnIndex}-left`}
                                colSpan={column.colspanLeft}
                                style={style}
                              ></TableCell>
                            ) : null}
                            {
                              <TableCell style={style} colSpan={column.colspan}>
                                {column.render
                                  ? column.render(rowData, rowIndex)
                                  : rowData[column.id]}
                              </TableCell>
                            }
                            {column.colspanRight ? (
                              <TableCell
                                style={style}
                                key={`${rowIndex}-${column.additionalLine}-${columnIndex}-right`}
                                colSpan={column.colspanRight}
                              ></TableCell>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
                {showAddColSpacer ? <SpacerTableRow /> : null}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  return (
    <StylePaper className={classes.root}>
      <TableContainer className={classes.tableContainer}>{renderTable()}</TableContainer>
      {pagination ? (
        <TablePaginationContainer>
          <PageCounter pagination={pagination} />
          <PageNavigator pagination={pagination} onChangePage={handlePageChange} />
          <PageSetting pagination={pagination} onChangePageSize={handlePageSizeChange} />
        </TablePaginationContainer>
      ) : null}
    </StylePaper>
  );
};

export {SimpleDataTable, AdditionalLineMode};
