import React from 'react';
import {styled} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import {EditIcon, DeleteIcon, TickIcon, CrossIcon} from '../Icons';

const PREFIX = 'ActionIconButton';

const classes = {
  root: `${PREFIX}-root`,
  childPulsate: `${PREFIX}-childPulsate`,
};

const UnstyledActionIconButton = ({disabled, icon: ActionIcon, onClick, ...props}) => {
  return (
    <IconButton
      disabled={disabled}
      TouchRippleProps={{
        classes: {
          childPulsate: classes.childPulsate,
        },
      }}
      onClick={onClick}
      classes={{root: classes.root}}
      {...props}
      size="large"
    >
      <ActionIcon sx={{opacity: disabled ? 0.2 : 1}} />
    </IconButton>
  );
};

const ActionIconButton = styled(UnstyledActionIconButton)(() => ({
  [`&.${classes.root}`]: {
    borderRadius: '10px',
  },

  [`& .${classes.childPulsate}`]: {
    borderRadius: '10px',
  },
}));

const EditActionButton = ({style = {}, disabled = false, onClick, ...props}) => {
  return (
    <ActionIconButton
      sx={{
        backgroundColor: '#F2F4F5',
        ...style,
      }}
      icon={EditIcon}
      disabled={disabled}
      onClick={onClick}
      {...props}
    />
  );
};

const DeleteActionButton = ({style = {}, disabled = false, onClick, ...props}) => {
  return (
    <ActionIconButton
      sx={{
        backgroundColor: '#D0021B1A',
        ...style,
      }}
      icon={DeleteIcon}
      disabled={disabled}
      onClick={onClick}
      {...props}
    />
  );
};

const SaveActionButton = (props) => {
  const {style, disabled, onClick, ...rest} = props;
  return (
    <ActionIconButton
      sx={{
        backgroundColor: '#0287021A',
        ...style,
      }}
      icon={TickIcon}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    />
  );
};

const DownloadActionButton = ({style, disabled = false, onClick, ...props}) => {
  return (
    <ActionIconButton
      sx={{
        backgroundColor: '#0287021A',
        ...style,
      }}
      icon={GetAppIcon}
      disabled={disabled}
      onClick={onClick}
      {...props}
    />
  );
};
const CancelActionButton = (props) => {
  const {style, disabled = false, onClick, ...rest} = props;
  return (
    <ActionIconButton
      sx={{
        backgroundColor: '#F2F4F5',
        ...style,
      }}
      icon={CrossIcon}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    />
  );
};

export default ActionIconButton;
export {
  EditActionButton,
  DeleteActionButton,
  SaveActionButton,
  CancelActionButton,
  DownloadActionButton,
};
