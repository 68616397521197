import React, {FC, useState, useEffect, useRef} from 'react';
import Consts from '../../../../app/Consts';
import {GroupProductSkuOverlap, Pagination, TableColumn} from '../../../../types';
import {formatDate} from '../../../../utils/DateUtils';
import {SimpleDataTable} from '../../../SimpleDataTable';

type Props = {
  data?: GroupProductSkuOverlap[];
};

const BulkUploadSKUOverlapTable: FC<Props> = ({data}) => {
  const isInitialMount = useRef(true);
  const [pagination, setPagination] = useState({
    ...Consts.DefaultPagination,
    pageSize: 10,
  });
  const [rows, setRows] = useState<GroupProductSkuOverlap[]>([]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (Array.isArray(data) && data.length > 0) {
        setRows(data.slice(0, pagination.pageSize));
        setPagination({
          ...Consts.DefaultPagination,
          totalCount: data.length,
          pageSize: pagination.pageSize,
          totalPages: Math.ceil(data.length / pagination.pageSize),
        });
      }
    }
  }, [data, pagination.pageSize]);

  const handlePagination = (newPagination: Partial<Pagination>) => {
    const nextPagination = {
      ...pagination,
      ...newPagination,
    };
    setPagination({
      ...nextPagination,
      totalPages: Math.ceil(nextPagination.totalCount / nextPagination.pageSize),
    });
    setRows(
      data?.slice(
        (nextPagination.currentPage - 1) * nextPagination.pageSize,
        nextPagination.currentPage * nextPagination.pageSize
      ) || []
    );
  };

  const columns: TableColumn<GroupProductSkuOverlap>[] = [
    {
      id: 'sku',
      label: 'Overlapping SKU',
    },
    {
      id: 'mixAndMatchId',
      label: 'M&M Id',
    },
    {
      id: 'mixAndMatchDescription',
      label: 'M&M Description',
    },
    {
      id: 'owner',
      label: 'Owner',
      render: (row) => row.owner?.name,
    },
    {
      id: 'department',
      label: 'Department',
      render: (row) => row.departments?.join(', '),
    },
    {
      id: 'startDate',
      label: 'Start Date',
      render: (row) => formatDate(row.startDate),
    },
    {
      id: 'endDate',
      label: 'End Date',
      render: (row) => formatDate(row.endDate),
    },
  ];

  return (
    <SimpleDataTable
      columns={columns}
      rows={rows}
      pagination={pagination}
      onChangePage={handlePagination}
      onChangePageSize={handlePagination}
      hideRowSpacer
    />
  );
};

export default BulkUploadSKUOverlapTable;
