import axios from 'axios';
import {setupCache} from 'axios-cache-interceptor';
import {buildConfig} from '.';

const cachedAxios = setupCache?.(axios);

type AxiosConfig = {
  tokenRequest?: boolean;
  params?: Record<string, any>;
  responseType?: string;
};

export const getCached = async (url: string, config?: AxiosConfig): Promise<any> => {
  const newConfig = await buildConfig(config ?? {});
  const cachedConfig = {
    id: url,
    cache: {
      ttl: 1000 * 60, // invalidate cache after 1 minute. Balance between stale data and performance
    },
  };
  return cachedAxios.get(url, {...newConfig, ...cachedConfig});
};
