import React, {FC, useState} from 'react';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {EditIcon} from '../Icons';
import IconLabelButton from '../Button/IconLabelButton';
import {CancelActionButton, SaveActionButton} from '../Table';

type Props = TextFieldProps & {
  readOnly?: boolean;
  value?: string;
  onSave: (newValue?: string) => Promise<void>;
  direction?: 'row' | 'column';
  validationSchema?: any;
  name?: string;
};

const EditableField: FC<Props> = ({readOnly, value, onSave, direction, validationSchema, name}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState<string | undefined | null>(value);
  const [error, setError] = useState<string | null>(null);
  const [isValidating, setIsValidating] = useState(false);

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setNewValue(value);
    setIsEditing(false);
  };

  const handleSave = () => {
    onSave(newValue ?? undefined);
    setIsEditing(false);
  };

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (validationSchema && name) {
      setIsValidating(true);
      try {
        await validationSchema.validate({[name]: inputValue});
        setError(null);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsValidating(false);
      }
    }
    setNewValue(inputValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  if (isEditing) {
    return (
      <Stack direction="column" spacing={2}>
        <TextField
          variant="outlined"
          autoFocus
          type="text"
          defaultValue={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          fullWidth
          error={!!error}
          helperText={error}
          sx={{
            '& .MuiFormHelperText-root': {
              position: 'absolute',
              top: '42px',
            },
          }}
        />
        <Stack direction="row" spacing={1} sx={{justifyContent: 'flex-end'}}>
          <SaveActionButton
            size="small"
            type="submit"
            onClick={handleSave}
            disabled={!!error || isValidating}
            style={{
              height: '1.5rem',
              width: '1.5rem',
              padding: '0.25rem',
              borderRadius: '0.75rem !important',
            }}
          />
          <CancelActionButton
            size="small"
            onClick={handleCancel}
            style={{
              height: '1.5rem',
              width: '1.5rem',
              padding: '0.25rem',
              borderRadius: '0.75rem !important',
            }}
          />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      direction={direction ?? 'column'}
      sx={{
        svg: {width: '1.0625rem !important'},
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      }}
    >
      <span>{value}</span>
      {!readOnly ? (
        <IconLabelButton
          sx={{
            fontSize: '1rem',
            fontWeight: 'normal',
            padding: '0.375rem',
            lineHeight: 'normal',
            textDecoration: 'underline',
            height: 'unset',
          }}
          icon={<EditIcon />}
          label="Edit"
          onClick={handleEdit}
          data-testid="edit-button"
        />
      ) : null}
    </Stack>
  );
};

export default EditableField;
