import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Department, Pagination, Order, ClaimTypeRequestParam} from '../types';
import {ClaimVendor} from '../components/Form/Agolia/VendorSearch';
import Consts from './Consts';
import {FinanceAccount} from './financialAccountsReducer';
import {Owner} from './ownersReducer';

export interface ClaimSideFilters {
  claimFrequency: any;
  claimVendor: ClaimVendor | null;
  departments: Department[] | null;
  endAt: string | null;
  excludeNartaInternational: boolean | null;
  financeAccounts: FinanceAccount[] | null;
  onlyReturnAdjustedClaims: boolean | null;
  owner: Owner | null;
  paymentPriority: any;
  startAt: string | null;
  status: string | null;
  types: ClaimTypeRequestParam[] | null;
}

export interface ClaimsState {
  searchText: string | null;
  order: Order;
  pagination: Pagination;

  filterSelection: ClaimSideFilters;
}

export const initialState: ClaimsState = {
  searchText: null,
  order: {
    orderBy: 'endAt',
    orderByDirection: 'desc',
  },
  filterSelection: {
    claimVendor: null,
    startAt: null,
    endAt: null,
    claimFrequency: null,
    types: [],
    paymentPriority: null,
    departments: [],
    financeAccounts: [],
    owner: null,
    excludeNartaInternational: null,
    onlyReturnAdjustedClaims: null,
    status: null,
  },
  pagination: {
    totalCount: Consts.DefaultPagination.totalCount,
    totalPages: Consts.DefaultPagination.totalPages,
    pageSize: Consts.DefaultPagination.pageSize,
    currentPage: Consts.DefaultPagination.currentPage,
  },
};

const sliceName = 'claims';

export const claimsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setClaimsSearch(state, action: PayloadAction<string | null>) {
      state.searchText = action.payload;
    },
    setClaimsFilter(state, action: PayloadAction<ClaimSideFilters>) {
      state.filterSelection = action.payload;
    },
    setClaimsOwnerFilter(state, action: PayloadAction<Owner | null>) {
      state.filterSelection = {...state.filterSelection, owner: action.payload};
    },
    setClaimsPagination(state, action: PayloadAction<Partial<Pagination>>) {
      const next = Object.entries(action.payload).reduce((acc: any, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {});
      state.pagination = {
        ...state.pagination,
        ...next,
      };
    },
    setClaimsOrder(state, action: PayloadAction<Order>) {
      state.order = action.payload;
    },
  },
});

const claimsReducer = claimsSlice.reducer;

export const {
  setClaimsSearch,
  setClaimsFilter,
  setClaimsOwnerFilter,
  setClaimsPagination,
  setClaimsOrder,
} = claimsSlice.actions;
export default claimsReducer;
