import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Consts from '../../app/Consts';
import IconButton from '../Button/IconButton';
import RequireAuth from '../Auth/RequireAuth';
import UserProfile from './UserProfile';
import AddNewModal from './AddNewModal';
import NewDealTypeModal from './NewDealTypeModal';
import Logo from './Logo';

const PREFIX = 'TopNav';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  divider: `${PREFIX}-divider`,
  profile: `${PREFIX}-profile`,
};

const StyledAppBar = styled(AppBar)(({theme}) => ({
  [`&.${classes.root}`]: {
    margin: 'auto',
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    backgroundColor: theme.palette.domainSpecific.header.background,
  },

  [`& .${classes.logo}`]: {
    '& img': {
      height: '3.875rem',
    },
  },

  [`& .${classes.divider}`]: {
    marginLeft: '1.875rem',
    marginRight: '1.875rem',
  },

  [`& .${classes.profile}`]: {
    display: 'flex',
    minWidth: '22.625rem',
  },
}));

const EnvironmentHeader = styled('h1')`
  color: ${(props) => props.theme.palette.domainSpecific.envHeader.color};
  font-size: 3rem;
  padding: 0;
  border-bottom: 1px solid #88888871;
`;

function TopNav() {
  const [modalOpen, setModalOpen] = useState(false);
  const [dealTypeModalOpen, setDealTypeModalOpen] = useState(false);
  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const upperCaseEnvironment = process.env.REACT_APP_REBATES_ENV?.toUpperCase();
  const isDevOrUat = upperCaseEnvironment === 'DEV' || upperCaseEnvironment === 'UAT';

  return (
    <>
      <StyledAppBar position="fixed" className={classes.root}>
        <Toolbar>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            columnSpacing={2}
          >
            <Grid item>
              <div className={classes.logo}>
                <Logo />
              </div>
            </Grid>
            <Grid item>
              {isDevOrUat ? <EnvironmentHeader>{upperCaseEnvironment}</EnvironmentHeader> : null}
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2} wrap="nowrap">
                <Grid item sx={{minWidth: '16.125rem'}}>
                  <RequireAuth
                    requiredRoles={{
                      all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements],
                      any: [
                        Consts.UserRoleEnum.AddOrUpdateDeals,
                        Consts.UserRoleEnum.AddOrUpdateContractAgreements,
                      ],
                    }}
                    alt={null}
                  >
                    <IconButton icon={AddIcon} onClick={handleOpen} size="large">
                      Add New
                    </IconButton>
                  </RequireAuth>
                </Grid>
                <Grid item className={classes.profile}>
                  <Divider orientation="vertical" flexItem className={classes.divider} />
                  <UserProfile />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </StyledAppBar>
      <RequireAuth
        requiredRoles={{
          any: Object.values(Consts.UserRoleEnum),
        }}
        alt={null}
      >
        <AddNewModal
          open={modalOpen}
          onClose={handleClose}
          onSelectDealOption={() => setDealTypeModalOpen(true)}
        />
        <NewDealTypeModal open={dealTypeModalOpen} onClose={() => setDealTypeModalOpen(false)} />
      </RequireAuth>
    </>
  );
}

export default TopNav;
