import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {
  SeasonCode,
  updateDepartmentFilter,
  updateSeasonCodeFilter,
  updateSupplierFilter,
} from '../../../app/unreleasedProductsReducer';
import {Department} from '../../../types';
import {CustomAutoComplete} from '../../CustomAutoComplete';
import DepartmentMultipleSearch from '../../Form/DepartmentMultipleSearch/DepartmentMultipleSearch';
import FieldLabel from '../../Form/FieldLabel';
import ClearProductFiltersButton from './ClearProductFiltersButton';
import SuppliersSearch from '../../Form/SuppliersSearch/SuppliersSearch';
import {Supplier} from '../../../app/suppliersReducer';

const FilterGrid = styled(Grid)`
  margin-top: 1.875rem;
`;

function ByProductFilters() {
  const dispatch = useAppDispatch();
  const {departmentFilters, supplierFilter, seasonCodeFilter} = useAppSelector(
    (x) => x.unreleasedProducts
  );

  return (
    <Box data-testid="unreleased-product-filter">
      <ClearProductFiltersButton />
      <FilterGrid>
        <FieldLabel>Departments</FieldLabel>
        <DepartmentMultipleSearch
          value={departmentFilters}
          onChange={(_: any, option: Department[]) => {
            dispatch(updateDepartmentFilter(option));
          }}
        />
      </FilterGrid>
      <FilterGrid>
        <FieldLabel>Suppliers</FieldLabel>
        <SuppliersSearch
          onChange={(_: any, option: Supplier | null) => {
            dispatch(updateSupplierFilter(option));
          }}
          value={supplierFilter}
        />
      </FilterGrid>
      <FilterGrid>
        <FieldLabel>Season Code</FieldLabel>
        <CustomAutoComplete
          options={Object.values(SeasonCode).filter((value) => typeof value === 'string')}
          value={seasonCodeFilter}
          getOptionLabel={(option: any) => option}
          placeholder="All Season Codes"
          onChange={(_: any, option: any) => {
            dispatch(updateSeasonCodeFilter(option));
          }}
        />
      </FilterGrid>
    </Box>
  );
}

export default ByProductFilters;
