import React, {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
import SectionContainer from './SectionContainer';

const PREFIX = 'PageNavigator';

const classes = {
  pageSetter: `${PREFIX}-pageSetter`,
  pageSetterRoot: `${PREFIX}-pageSetterRoot`,
  iconButton: `${PREFIX}-iconButton`,
};

const PageNavigatorContainer = styled(SectionContainer)`
  margin: 0 10px;
`;

const StyledPageNavigatorContainer = styled(PageNavigatorContainer)({
  [`& .${classes.pageSetter}`]: {
    padding: '10px 14px',
    textAlign: 'center',
    width: '30px',
  },
  [`& .${classes.pageSetterRoot}`]: {
    margin: '0 10px',
  },
  [`& .${classes.iconButton}`]: {
    padding: '5px',
  },
});

const PageIndicatorContainer = styled(SectionContainer)`
  margin: 0 10px;
`;

const PageNavigator = ({pagination, onChangePage}) => {
  const [curPage, setCurPage] = useState(pagination.currentPage);
  useEffect(() => {
    setCurPage(pagination.currentPage);
  }, [pagination]);
  function getPage(nextPage) {
    if (nextPage < 1 || nextPage > pagination.totalPages) {
      return curPage;
    }
    return nextPage;
  }
  return (
    <StyledPageNavigatorContainer>
      <IconButton
        classes={{root: classes.iconButton}}
        size="medium"
        type="button"
        onClick={() => {
          const page = getPage(curPage - 1);
          if (page !== curPage) {
            onChangePage(page);
          }
        }}
      >
        <ChevronLeftRoundedIcon fontSize="inherit" />
      </IconButton>
      <PageIndicatorContainer>
        <span>Page </span>
        <TextField
          variant="outlined"
          classes={{root: classes.pageSetterRoot}}
          InputProps={{
            classes: {
              input: classes.pageSetter,
            },
          }}
          value={curPage ?? 1}
          onChange={(event) => {
            const value = event.target.value.replace(/[^\d]+/g, '');
            if (value > 0 && value <= pagination.totalPages) {
              setCurPage(value);
              onChangePage(value);
            }
          }}
        />
        <span>of {pagination.totalPages === 0 ? 1 : pagination.totalPages}</span>
      </PageIndicatorContainer>
      <IconButton
        classes={{root: classes.iconButton}}
        size="medium"
        type="button"
        onClick={() => {
          const page = getPage(curPage + 1);
          if (page !== curPage) {
            onChangePage(page);
          }
        }}
      >
        <ChevronRightRounded fontSize="inherit" />
      </IconButton>
    </StyledPageNavigatorContainer>
  );
};

export default PageNavigator;
