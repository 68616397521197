import React from 'react';
import Grid from '@mui/material/Grid';
import {ErrorBox} from '../Alert';

const RaisedClaimWarning = () => (
  <Grid item xs={12} sx={{marginTop: '-2.875rem', marginBottom: '1.875rem'}}>
    <ErrorBox>The claim is already raised.</ErrorBox>
  </Grid>
);

export default RaisedClaimWarning;
