import * as Yup from 'yup';
import Consts from '../../app/Consts';
import * as R from 'ramda';
import {
  getDisplayAmountValue,
  getFixedValue,
  roundToTwoDecimalPlaces,
} from '../../utils/AmountUtils';

const stepFinanceDetailsValidationSchema = Yup.object({
  splitAccountAmountType: Yup.string().oneOf(R.values(Consts.SplitAccountAmountTypeEnum)),
  dealType: Yup.string()
    .required(Consts.ValidationMessage.Required)
    .oneOf(R.values(Consts.StandardDealTypeEnum)),
  claimInterval: Yup.string()
    .required(Consts.ValidationMessage.Required)
    .typeError(Consts.ValidationMessage.Required)
    .when('dealType', function (dealType, schema) {
      const options = filterClaimInterval(dealType);
      return schema.oneOf(
        options.map((x) => x.value),
        'This option is not valid for selected deal type'
      );
    }),
  claimDaysAfterExpiry: Yup.string().when('claimInterval', function (claimInterval, schema) {
    if (claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry) {
      return Yup.number()
        .required(Consts.ValidationMessage.Required)
        .typeError(Consts.ValidationMessage.NumberOnly)
        .min(0)
        .max(365);
    }
    return schema;
  }),
  totalAmount: Yup.number()
    .typeError(Consts.ValidationMessage.NumberOnly)
    .required(Consts.ValidationMessage.Required),
  splitAccounts: Yup.array()
    .min(1, 'Select one finance account at least')
    .required()
    .test('account-duplicates', "The finance account can't be the same", function (value) {
      let allAccountIds = value?.filter((x) => x.accountId).map((x) => x.accountId) ?? [];
      let hasDuplicates = R.any(
        (x) => x.length > 1,
        R.values(R.groupBy(R.identity, allAccountIds))
      );
      return !hasDuplicates;
    })
    .test('total-percentage', Consts.ValidationMessage.PercentageTotal, function (value) {
      if (this.parent.splitAccountAmountType === Consts.SplitAccountAmountTypeEnum.Percentage) {
        return value?.map((x) => x.amount).reduce((a, b) => a + b, 0) === 100;
      }
      return true;
    })
    .test('total-amount', "The total amount doesn't match", function (value) {
      if (this.parent.splitAccountAmountType === Consts.SplitAccountAmountTypeEnum.Value) {
        const totalSum = value
          ?.map((x) => x.amount)
          .reduce((a, b) => roundToTwoDecimalPlaces(a + b), 0);
        const matches = getFixedValue(totalSum) === getFixedValue(this.parent.totalAmount);
        if (!matches) {
          return this.createError({
            message: `The sum of finance accounts amount ($${getDisplayAmountValue(
              totalSum
            )}) is not equal to the total amount (${getDisplayAmountValue(
              this.parent.totalAmount,
              '$'
            )})`,
          });
        }
      }
      return true;
    })

    .when(
      ['splitAccountAmountType', 'totalAmount'],
      // @ts-ignore yup types are not correct, works fine
      function (splitAccountAmountType, totalAmount, schema) {
        if (splitAccountAmountType === Consts.SplitAccountAmountTypeEnum.Percentage) {
          return schema.of(
            Yup.object().shape({
              accountId: Yup.number()
                .required(Consts.ValidationMessage.Required)
                .typeError(Consts.ValidationMessage.Required),
              amount: Yup.number()
                .typeError(Consts.ValidationMessage.NumberOnly)
                .required(Consts.ValidationMessage.Required)
                .positive(Consts.ValidationMessage.PercentageValue)
                .max(100, Consts.ValidationMessage.PercentageValue),
            })
          );
        } else {
          return schema.of(
            Yup.object().shape({
              accountId: Yup.number().required(Consts.ValidationMessage.Required),
              amount:
                totalAmount > 0
                  ? Yup.number()
                      .required(Consts.ValidationMessage.Required)
                      .typeError(Consts.ValidationMessage.NumberOnly)
                      .max(
                        totalAmount,
                        `The value must not exceed the total amount value(${getDisplayAmountValue(
                          totalAmount,
                          '$'
                        )})`
                      )
                      .positive(Consts.ValidationMessage.PositiveValue)
                  : Yup.number()
                      .typeError(Consts.ValidationMessage.NumberOnly)
                      .required(Consts.ValidationMessage.Required)
                      .min(
                        totalAmount,
                        `The value must be bigger than the total amount value(${getDisplayAmountValue(
                          totalAmount,
                          '$'
                        )})`
                      )
                      .negative(Consts.ValidationMessage.NegativeValue),
            })
          );
        }
      }
    ),
  supplierApprovalNumber: Yup.string().test(
    'max length check',
    'Supplier approval number cannot exceed 100 characters',
    function (value?: string) {
      return !value || value.length <= 100;
    }
  ),
});

export function filterClaimInterval(dealType: string | null) {
  if (dealType === Consts.StandardDealTypeEnum.Sales) {
    return Consts.DealClaimInterval.filter(
      (x) =>
        x.value !== Consts.DealClaimIntervalEnum.StartOfRebate &&
        x.value !== Consts.DealClaimIntervalEnum.Immediately
    );
  }
  if (dealType === Consts.StandardDealTypeEnum.Purchases) {
    return Consts.DealClaimInterval.filter(
      (x) =>
        x.value !== Consts.DealClaimIntervalEnum.StartOfRebate &&
        x.value !== Consts.DealClaimIntervalEnum.Immediately
    );
  }
  if (dealType === Consts.StandardDealTypeEnum.FixedAmount) {
    return Consts.DealClaimInterval.filter(
      (x) =>
        x.value === Consts.DealClaimIntervalEnum.StartOfRebate ||
        x.value === Consts.DealClaimIntervalEnum.EndOfRebate ||
        x.value === Consts.DealClaimIntervalEnum.Immediately ||
        x.value === Consts.DealClaimIntervalEnum.DoNotClaim ||
        x.value === Consts.DealClaimIntervalEnum.DaysAfterExpiry
    );
  }
  if (dealType === Consts.StandardDealTypeEnum.PriceProtection) {
    return Consts.DealClaimInterval.filter(
      (x) =>
        x.value === Consts.DealClaimIntervalEnum.EndOfRebate ||
        x.value === Consts.DealClaimIntervalEnum.Immediately ||
        x.value === Consts.DealClaimIntervalEnum.DoNotClaim
    );
  }
  return [];
}
export default stepFinanceDetailsValidationSchema;
