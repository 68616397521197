import React, {FC, useState} from 'react';
import {styled} from '@mui/material/styles';
import MaterialSwitch, {SwitchProps} from '@mui/material/Switch';

const PREFIX = 'Switch';

const classes = {
  focusVisible: `${PREFIX}-focusVisible`,
};

export const StyledMaterialSwitch = styled(MaterialSwitch)(({theme}) => ({
  width: '5rem',
  height: '2rem',
  padding: 0,
  overflow: 'unset',

  '& .MuiSwitch-switchBase': {
    padding: 3,
    color: theme.palette.white.main,
    marginLeft: 0,
    '&.Mui-checked': {
      transform: 'translateX(48px)',
      padding: 2,
      color: '#FFF',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 'none',
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        },
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disable': {
      transform: 'translateX(48px)',
      padding: 2,
      color: theme.palette.white.main,
    },
    '&.Mui-disabled': {
      '&.Mui-checked + MuiSwitch-track': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },

  '& .MuiSwitch-thumb': {
    width: '1.75rem',
    height: '1.75rem',
  },
  '& .MuiSwitch-track': {
    borderRadius: '1rem',
    border: `1px solid #CBD2D8`,
    opacity: 1,
    backgroundColor: '#CBD2D8',
    transition: theme.transitions.create(['background-color', 'border']),
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      color: theme.palette.black.main,
      textAlign: 'center',
      fontSize: '0.875rem',
      fontWeight: '500',
    },
    '&:before': {
      content: '"Yes"',
      left: '8px',
      opacity: 0,
    },
    '&:after': {
      content: '"No"',
      right: '0.5rem',
      color: theme.palette.white.main,
    },
  },
}));

type Props = {
  disabled?: boolean;
  onChanged?: (checked: boolean) => void;
  confirm?: React.ElementType;
  defaultValue?: boolean;
} & Omit<SwitchProps, 'defaultValue'>;

const Switch: FC<Props> = ({
  disabled = false,
  onChanged = (checked) => {},
  confirm: ConfirmModal = null,
  defaultValue = false,
  ...props
}) => {
  const [checked, setChecked] = useState<boolean>(defaultValue);
  const [valueToChange, setValueToChange] = useState<boolean | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const onConfirm = () => {
    setConfirmModalOpen(false);
    setChecked(!!valueToChange);
    onChanged(!!valueToChange);
    setValueToChange(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ConfirmModal) {
      setValueToChange(event.target.checked);
      setConfirmModalOpen(true);
    } else {
      setChecked(event.target.checked);
      onChanged(event.target.checked);
    }
  };

  return (
    <>
      <StyledMaterialSwitch
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        {...props}
      />
      {ConfirmModal ? (
        <ConfirmModal
          open={confirmModalOpen}
          onCancel={() => setConfirmModalOpen(false)}
          onOk={onConfirm}
        />
      ) : null}
    </>
  );
};

export default Switch;
