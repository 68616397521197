import * as React from 'react';
import {styled} from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import Warning from '@mui/icons-material/Warning';
import Check from '@mui/icons-material/Check';
import PropTypes from 'prop-types';

const PREFIX = 'CustomStepIcon';

const customClasses = {
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
  active: `${PREFIX}-active`,
  completed: `${PREFIX}-completed`,
  error: `${PREFIX}-error`,
};

const Root = styled('span')(({theme}) => ({
  /* Styles applied to the root element. */
  [`&.${customClasses.root}`]: {
    display: 'block',
    color: theme.palette.text.disabled,
  },
  [`& .${customClasses.completed}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${customClasses.active}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${customClasses.error}`]: {
    color: theme.palette.error.main,
  },

  /* Styles applied to the SVG text element. */
  [`& .${customClasses.text}`]: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
}));

const StepIcon = React.forwardRef(function StepIcon(props, ref) {
  const {completed = false, icon, active = false, error = false, classes} = props;
  if (typeof icon === 'number' || typeof icon === 'string') {
    const className = `${classes.root} ${active ? customClasses.active : ''} ${
      error ? customClasses.error : ''
    } ${completed ? customClasses.completed : ''}`;

    const getIcon = () => {
      if (error) {
        return <Warning className={className} ref={ref} />;
      }

      if (completed) {
        return (
          <SvgIcon className={className} ref={ref}>
            <circle cx="12" cy="12" r="12" />
            <Check viewBox="-6 -6 36 36" htmlColor="#000" />
          </SvgIcon>
        );
      }

      return (
        <SvgIcon className={className} ref={ref}>
          <circle cx="12" cy="12" r="12" />
          <text
            className={`${classes.text} ${customClasses.text}`}
            x="12"
            y="16"
            textAnchor="middle"
          >
            {icon}
          </text>
        </SvgIcon>
      );
    };
    return <Root className={customClasses.root}>{getIcon()}</Root>;
  }

  return icon;
});

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * Mark the step as failed.
   */
  error: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default StepIcon;
