import React from 'react';
import {useField} from 'formik';
import Switch from './Switch';
import Consts from '../../app/Consts';

const NartaSwitch = ({confirm, disabled = false, onConfirmChange = () => {}, ...props}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(props.name);
  const {setValue} = helpers;
  function updateValue(checked) {
    if (checked) {
      setValue({code: Consts.Narta.Code, name: Consts.Narta.Name, isActive: true});
    } else {
      setValue({code: '', name: '', isActive: null});
    }
  }
  function onSwitch(checked) {
    if (confirm) {
      onConfirmChange();
    }
    updateValue(checked);
  }
  return (
    <Switch
      disabled={disabled}
      {...props}
      color="primary"
      confirm={confirm}
      onChanged={onSwitch}
      defaultValue={field.value.code === Consts.Narta.Code}
    />
  );
};

export default NartaSwitch;
