import React from 'react';
import {ConfirmModal2} from './Modal';

const styles = {
  ok1Button: {
    marginLeft: '10px',
    marginRight: '10px',
    width: '340px',
    padding: '5px 10px',
  },
  ok2Button: {
    width: '340px',
    padding: '5px 10px',
  },
  cancelButton: {
    width: '340px',
    padding: '5px 10px',
  },
  content: {
    textAlign: 'left',
  },
  root: {
    width: '730px',
  },
};

const BulkUploadConfirmModal = ({
  open,
  onKeepAdding,
  onReplace,
  onCancel,
  clearedText = 'all the data that is currently on the page',
  ...otherProps
}) => {
  return (
    <ConfirmModal2
      open={open}
      onOk1={onReplace}
      ok1Text="Clear data and replace"
      onOk2={onKeepAdding}
      ok2Text="Keep data and add"
      onCancel={onCancel}
      cancelText="Keep my data and go back"
      styles={styles}
      {...otherProps}
    >
      <div>
        <div>
          <strong>Clear data and replace:</strong> This action will permanently{' '}
          <strong>clear and replace</strong> {clearedText}.
        </div>
        <div>
          <strong>Keep data and add:</strong> This action will <strong>keep</strong> the original
          data and <strong>add</strong> the extra data you have selected.
        </div>
        Which action do you like to continue?
      </div>
    </ConfirmModal2>
  );
};

export default BulkUploadConfirmModal;
