import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';
import {api, get, post} from '../utils/Request';
import {alertService} from './AlertService';
import config from './Config';
import Consts from './Consts';

export enum UnreleasedType {
  Deal = 'Deal',
  ContractAgreement = 'ContractAgreement',
}

export enum UnreleasedRebateType {
  Purchases = 'Purchases',
  Sales = 'Sales',
  FixedAmount = 'FixedAmount',
}

export interface UnreleasedAmountsState {
  data: UnreleasedRebateData[];
  isLoading: boolean;
  isAdvancing: boolean;
  showAdvanceModal: boolean;
  searchText: string | null;
  orderBy: string;

  totalCount: number;
  totalPages: number;
  pageSize: number;
  currentPage: number;

  typeFilter: UnreleasedType | null;
  rebateTypeFilter: UnreleasedRebateType | null;
  startDateFilter: string | null;
  endDateFilter: string | null;
}

export const initialState: UnreleasedAmountsState = {
  data: [],
  isLoading: false,
  isAdvancing: false,
  showAdvanceModal: false,
  searchText: null,
  orderBy: 'unreleasedRebate:desc',

  totalCount: 0,
  totalPages: 0,
  pageSize: 10,
  currentPage: 1,

  typeFilter: null,
  rebateTypeFilter: null,
  startDateFilter: null,
  endDateFilter: null,
};

export interface UnreleasedRebateData {
  id: string;
  selected: boolean;
  markedForRelease: boolean;
  agreementId: number;
  agreementDescription: string;
  agreementType: UnreleasedType;
  rebateType: UnreleasedRebateType;
  rebateId: number;
  rebateDescription: string;
  startDate: string;
  endDate: string;
  unreleasedRebate: number;
}

const sliceName = 'unreleasedRebates';
export interface SearchUnreleasedRebatesRequest {
  searchText: string | null;
  pageSize: number;
  currentPage: number;
  agreementType: UnreleasedType | null;
  rebateType: UnreleasedRebateType | null;
  startAt: string | null;
  endAt: string | null;
  orderBy: string;
}
export interface SearchUnreleasedRebatesResponse {
  data: UnreleasedRebateData[];
  pageSize: number;
  currentPage: number;
  totalCount: number;
  totalPages: number;
}

export const searchUnreleasedRebates = createAsyncThunk(
  `${sliceName}/searchUnreleasedRebates`,
  async (request: SearchUnreleasedRebatesRequest, thunkAPI) => {
    return get(api(Consts.Api.UnreleasedAmountsRebates), {
      params: {
        searchText: request.searchText,
        pageSize: request.pageSize,
        currentPage: request.currentPage,
        rebateType: request.rebateType,
        agreementType: request.agreementType,
        startAt: request.startAt,
        endAt: request.endAt,
        orderBy: request.orderBy,
      },
    }).then((response: AxiosResponse<SearchUnreleasedRebatesResponse>) => {
      const {data, ...restOfResponse} = response.data;

      const result: SearchUnreleasedRebatesResponse = {
        data: data.map((rebate) => {
          return {...rebate, selected: false};
        }),
        ...restOfResponse,
      };

      return result;
    });
  }
);

export interface AdvanceUnreleasedAmountsRequest {
  ids: string[];
}

export const advanceUnreleasedRebates = createAsyncThunk(
  `${sliceName}/advanceUnreleasedRebates`,
  async (request: AdvanceUnreleasedAmountsRequest, thunkAPI) => {
    const data = request.ids.map((id) => {
      return {
        id,
        type: 'Rebate',
      };
    });
    return post(api(Consts.Api.UnreleasedAmountsAdvance + `?entityCode=${config.entityCode}`), {
      data,
    });
  }
);

const unreleasedRebates = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    updateTypeFilter(state, action: PayloadAction<UnreleasedType | null>) {
      state.typeFilter = action.payload;
    },
    updateRebateTypeFilter(state, action: PayloadAction<UnreleasedRebateType | null>) {
      state.rebateTypeFilter = action.payload;
    },
    updateStartDateFilter(state, action: PayloadAction<string | null>) {
      state.startDateFilter = action.payload;
    },
    updateEndDateFilter(state, action: PayloadAction<string | null>) {
      state.endDateFilter = action.payload;
    },
    clearRebateFilters(state) {
      state.typeFilter = null;
      state.rebateTypeFilter = null;
      state.startDateFilter = null;
      state.endDateFilter = null;
    },
    updateRebateSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    toggleRebateSelected(state, action: PayloadAction<string>) {
      const existingRebate = state.data.filter((x) => x.id === action.payload)[0];
      if (existingRebate) {
        existingRebate.selected = !existingRebate.selected;
      }
    },
    toggleShowRebateAdvanceModal(state) {
      state.showAdvanceModal = !state.showAdvanceModal;
    },
    updateRebatesPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    updateRebatesCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    updateRebatesOrderBy(state, action: PayloadAction<string>) {
      state.orderBy = action.payload;
    },
    resetUnreleasedRebates() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUnreleasedRebates.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        searchUnreleasedRebates.fulfilled.type,
        (state, action: PayloadAction<SearchUnreleasedRebatesResponse>) => {
          state.data = action.payload.data;
          state.pageSize = action.payload.pageSize;
          state.currentPage = action.payload.currentPage;
          state.totalCount = action.payload.totalCount;
          state.totalPages = action.payload.totalPages;
          state.isLoading = false;
        }
      )
      .addCase(searchUnreleasedRebates.rejected.type, (state) => {
        state.isLoading = false;
        alertService.error('Failed to search Unreleased Amounts by Rebates.');
      })
      .addCase(advanceUnreleasedRebates.pending.type, (state) => {
        state.isAdvancing = true;
      })
      .addCase(advanceUnreleasedRebates.fulfilled.type, (state) => {
        state.isAdvancing = false;
        state.showAdvanceModal = false;
      })
      .addCase(advanceUnreleasedRebates.rejected.type, (state) => {
        state.isAdvancing = false;
        state.showAdvanceModal = false;
        alertService.error('Failed to Advance Rebate Unreleased Amounts.');
      });
  },
});

const unreleasedRebatesReducer = unreleasedRebates.reducer;
export const {
  updateRebateSearchText,
  updateTypeFilter,
  updateRebateTypeFilter,
  updateStartDateFilter,
  updateEndDateFilter,
  clearRebateFilters,
  toggleRebateSelected,
  toggleShowRebateAdvanceModal,
  updateRebatesPageSize,
  updateRebatesCurrentPage,
  resetUnreleasedRebates,
  updateRebatesOrderBy,
} = unreleasedRebates.actions;
export default unreleasedRebatesReducer;
