import React from 'react';
import {ConfirmModal} from '.';

const styles = {
  okButton: {
    width: '340px',
  },
  cancelButton: {
    width: '340px',
  },
};

const UnSavedChangesConfirmModal = ({open, onOk, onCancel, ...otherProps}) => {
  return (
    <ConfirmModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Ignore changes and continue"
      cancelText="Go back"
      styles={styles}
      {...otherProps}
    >
      <div>
        It looks like you have unsaved changes on the page. If you continue, these changes will be
        lost. What would you like to do?
      </div>
    </ConfirmModal>
  );
};

export default UnSavedChangesConfirmModal;
