import React from 'react';
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import TabComponent from '../TabComponent';

const PREFIX = 'PageTabs';

const classes = {
  root: `${PREFIX}-root`,
  tabsHeading: `${PREFIX}-tabsHeading`,
  tabs: `${PREFIX}-tabs`,
  tabActiveIndicator: `${PREFIX}-tabActiveIndicator`,
  flexContainer: `${PREFIX}-flexContainer`,
};

const StyledPaper = styled(Paper)<{tabcount: number}>(({theme, tabcount}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '8rem',
    boxShadow: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    paddingLeft: '51px',
    paddingRight: '43px',
    borderBottom: '1px solid #cbd2d8',
    '& .MuiTabs-scroller': {
      overflowX: 'auto !important',
    },
  },
  [`& .${classes.tabsHeading}`]: {
    flex: '1 0 16rem',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    maxWidth: '25rem',
  },
  [`& .${classes.tabs}`]: {
    padding: 'unset',
    flexGrow: 2,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
  },
  [`& .${classes.tabActiveIndicator}`]: {
    borderBottom: `6px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
  },
  [`& .${classes.flexContainer}`]: {
    height: '100%',
    justifyContent: tabcount <= 2 ? 'normal' : 'space-between',
  },
}));

export interface PageTabsProps {
  title: string;
  value: string | boolean | null;
  ariaLabel?: string;
  onChange(event: React.ChangeEvent<{}>, value: any): void;
  tabsList: Tab[];
  children?: React.ReactNode;
}

export type Tab = {
  label: string;
  value: string | null;
};

function PageTabs(props: PageTabsProps) {
  const {title, ariaLabel, tabsList, onChange, value, children} = props;
  return (
    <StyledPaper className={classes.root} tabcount={tabsList.length}>
      <div className={classes.tabsHeading}>
        <Typography variant="h4">{title}</Typography>
      </div>

      <Tabs
        onChange={onChange}
        value={value}
        aria-label={ariaLabel}
        className={classes.tabs}
        classes={{
          flexContainer: classes.flexContainer,
          indicator: classes.tabActiveIndicator,
        }}
      >
        {tabsList.map((filter, index) => (
          <TabComponent key={index} label={filter.label} value={filter.value} />
        ))}
        {children}
      </Tabs>
    </StyledPaper>
  );
}

export {PageTabs};
