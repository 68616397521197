import React, {FC, useState} from 'react';
import Stack from '@mui/material/Stack';
import * as R from 'ramda';
import {Amount} from '../Amount';
import IconLabelButton from '../Button/IconLabelButton';
import {EditIcon} from '../Icons';
import {CancelActionButton, SaveActionButton, TableCellInputField} from '../Table';

type Props = {
  value: number | null;
  onUpdate?: (value: number | null) => void;
  onEdit?: () => void;
  onSave?: (value: number) => Promise<void>;
  buttonLabel?: string;
};

const EditableAmount: FC<Props> = ({
  value,
  onUpdate,
  onEdit,
  //onSave function need to return a promise, so that following action can be executed.
  onSave,
  buttonLabel = 'Edit Amount',
}) => {
  const [originValue, setOriginValue] = useState<number | null>(value);
  const [amount, setAmount] = useState<number | null>(value);
  const [isEditing, setEditing] = useState(false);
  const hasError = R.isEmpty(amount) || R.isNil(amount) || isNaN(amount);
  const errorMessage = hasError ? 'Please input a number' : null;

  const handleSaveClick = async () => {
    if (onSave && value !== null) {
      await onSave(value);
      setEditing(false);
    } else {
      setEditing(false);
    }
  };

  const handleOnChange = (value: string) => {
    if (onUpdate) {
      onUpdate(Number(value));
    }
    setAmount(Number(value));
  };

  const handleCancelClick = () => {
    if (onUpdate) {
      onUpdate(originValue);
    }
    setAmount(originValue);
    setEditing(false);
  };

  const handleEditClick = () => {
    if (onEdit) {
      onEdit();
    } else {
      setOriginValue(value);
      setEditing(true);
    }
  };

  if (isEditing) {
    return (
      <Stack direction="column" spacing={2}>
        <TableCellInputField
          showNegativeColor
          fullWidth
          error={hasError}
          helperText={errorMessage}
          defaultValue={amount ? amount.toString() : ''}
          onChanged={handleOnChange}
        />
        <Stack direction="row" spacing={1} sx={{justifyContent: 'flex-end'}}>
          <SaveActionButton
            size="small"
            disabled={hasError}
            onClick={handleSaveClick}
            style={{
              height: '1.5rem',
              width: '1.5rem',
              padding: '0.25rem',
              borderRadius: '0.75rem !important',
            }}
          />
          <CancelActionButton
            size="small"
            onClick={handleCancelClick}
            style={{
              height: '1.5rem',
              width: '1.5rem',
              padding: '0.25rem',
              borderRadius: '0.75rem !important',
            }}
          />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      direction="column"
      sx={{
        svg: {width: '1.0625rem !important'},
        alignItems: 'flex-start',
      }}
    >
      {amount === null ? '-' : <Amount value={amount} />}
      <IconLabelButton
        sx={{
          fontSize: '1rem',
          fontWeight: 'normal',
          padding: '0.375rem',
          lineHeight: 'normal',
          textDecoration: 'underline',
          height: 'unset',
        }}
        icon={<EditIcon />}
        label={buttonLabel}
        onClick={handleEditClick}
      />
    </Stack>
  );
};

export default EditableAmount;
