import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'DynamicContainerWithHeader';

const classes = {
  root: `${PREFIX}-root`,
  contentGroupWrapper: `${PREFIX}-contentGroupWrapper`,
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.contentGroupWrapper}`]: {
    paddingTop: '1.5rem',
  },
});

type Props = {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const DynamicContainerWithHeader: FC<Props> = ({title, children, style}) => (
  <Root className={classes.root} style={{...(style ?? {})}}>
    <Typography variant="h5">{title}</Typography>
    <div className={classes.contentGroupWrapper}>{children}</div>
  </Root>
);

export default DynamicContainerWithHeader;
