import React, {FC, useState, useEffect, useContext, useCallback, Fragment} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as R from 'ramda';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {
  AdjustmentListItem,
  Pagination,
  CreateOrEditAdjustmentRequest,
  AgreementLookupItem,
  RecursiveUndefined,
  SelectOption,
  TError,
  TableErrors,
  TableColumn,
} from '../../types';
import {isNegative} from '../../utils/AmountUtils';
import {
  DateType,
  formatDate,
  formatToISOByTZ,
  isValidDate,
  utcToTZDate,
} from '../../utils/DateUtils';
import {post, api, put, del} from '../../utils/Request';
import {
  TableCellInputField,
  TableCellUnitField,
  TableCellSelect,
  SaveActionButton,
  CancelActionButton,
  DeleteActionButton,
  EditActionButton,
} from '../Table';
import SkuSearch from '../Form/Agolia/SkuSearch';
import TablePaginationWithAddButton from '../Table/Pagination/TablePaginationWithAddButton';
import DatePicker from '../Form/DatePicker';
import {RedSpan} from '../RedSpan';
import {AgreementSearch} from '../AgreementSearch';
import {Amount} from '../Amount';

const PREFIX = 'AdjustmentValuesTable';

const classes = {
  tableCell: `${PREFIX}-tableCell`,
  tableCellNote: `${PREFIX}-tableCellNote`,
  tableHead: `${PREFIX}-tableHead`,
  editTableCellSpan: `${PREFIX}-editTableCellSpan`,
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.tableCellNote}`]: {
    paddingTop: 0,
  },
  [`& .${classes.tableHead}`]: {
    '& th': {
      borderTop: '1px solid rgba(224, 224, 224, 1);',
    },
  },
  [`& .${classes.editTableCellSpan}`]: {
    padding: '1.125rem 0.875rem',
  },
}) as typeof TableContainer; // https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop

const ValidateRule = {
  Required: 'Required',
  NumberOnly: 'NumberOnly',
  DateOnly: 'DateOnly',
};

const CellContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;
const ActionCellContainer = styled('div')`
  display: flex;
`;
const DataMode = {
  Edit: 'Edit',
  Add: 'Add',
  Display: 'Display',
};

const ApiAction = {
  Add: 'Add',
  Edit: 'Edit',
  Delete: 'Delete',
};

type Data = {
  dataMode: string;
  agreementStartAt?: string;
  agreementEndAt?: string;
  entityCode: string;
} & Omit<
  AdjustmentListItem,
  'canDelete' | 'createdAt' | 'createdByStaffCode' | 'createdByStaffName'
>;
type InitialData = RecursiveUndefined<Data>;
type Column<T> = Omit<TableColumn<T>, 'id'>;

type Props = {
  initialValues?: AdjustmentListItem[];
  onTableDataUpdating: (isUpdating: boolean) => void;
  onTableDataUpdated: (values: AdjustmentListItem[]) => void;
};

export const convertValuesToData = (
  values: AdjustmentListItem[],
  entityCode: string
): InitialData[] =>
  values.map(({canDelete, createdAt, createdByStaffCode, createdByStaffName, ...rest}) => ({
    dataMode: DataMode.Display,
    entityCode,
    ...rest,
  }));

// rowData is validated in validateRow function
export const convertDataToRequest = (
  rowData: InitialData,
  entityCode: string
): CreateOrEditAdjustmentRequest => ({
  agreementId: rowData.agreementId as number,
  amount: rowData.amount as number,
  endAt: rowData.endAt as string,
  entityCode,
  gstType: rowData.gstType as string,
  ...(rowData.note ? {note: rowData.note} : {}),
  ...(rowData.productCode ? {productCode: rowData.productCode} : {}),
  reason: rowData.reason as string,
  startAt: rowData.startAt as string,
});

const AdjustmentValuesTable: FC<Props> = ({
  initialValues = [],
  onTableDataUpdating,
  onTableDataUpdated,
}) => {
  const [keepAdding, setKeepAdding] = useState(false);
  const [values, setValues] = useState<AdjustmentListItem[]>(initialValues);
  const [data, setData] = useState<InitialData[]>([]);
  const [tableErrors, setTableErrors] = useState<TableErrors>({});
  const [pagination, setPagination] = useState({
    ...Consts.DefaultPagination,
    totalCount: values.length,
    totalPages: Math.ceil(values.length / Consts.DefaultPagination.pageSize),
  });
  const [action, setAction] = useState<string | undefined>();

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    onTableDataUpdated(values);
  }, [values, onTableDataUpdated]);

  // check if there are unsaved changes
  useEffect(() => {
    const isRowEditing = data.some((item) => item.dataMode === DataMode.Edit);
    const addRowIndex = data.findIndex((item) => item.dataMode === DataMode.Add);
    const dataChanged = Object.values(tableErrors[addRowIndex] || {}).some(
      (item) => item.touched === true
    );
    onTableDataUpdating(isRowEditing || dataChanged);
  }, [data, onTableDataUpdating, tableErrors]);

  useEffect(() => {
    setPagination((prevPagination) => {
      const totalPages = Math.ceil(values.length / prevPagination.pageSize) || 1;
      const newPagination = {...prevPagination, totalCount: values.length, totalPages: totalPages};
      if (totalPages < prevPagination.currentPage || action === ApiAction.Add) {
        newPagination.currentPage = totalPages || 1;
      }
      return newPagination;
    });
  }, [values, action]);

  const getEmptyRowData = useCallback((values?: RecursiveUndefined<AdjustmentListItem>[]) => {
    let emptyRowData: InitialData = {
      dataMode: DataMode.Add,
      id: undefined,
      startAt: undefined,
      endAt: undefined,
      adjustmentType: Consts.AdjustmentTypeEnum.Sku,
      agreementId: undefined,
      agreementDescription: '',
      agreementVendorName: '',
      productCode: undefined,
      productDescription: undefined,
      entityCode: config.entityCode,
      reason: Consts.AdjustmentReasonEnum.DocumentMismatch,
      amount: undefined,
      gstType: Consts.GstTypeEnum.Exclusive,
      note: undefined,
    };
    if (values && values.length > 0) {
      const prevRowData = values[values.length - 1];
      emptyRowData.startAt = prevRowData.startAt;
      emptyRowData.endAt = prevRowData.endAt;
    }
    return emptyRowData;
  }, []);

  const getPageData = useCallback(
    (values: AdjustmentListItem[], pagination: Pagination): InitialData[] => {
      const currentPageIndex = pagination.currentPage - 1;
      const pageData = values.slice(
        currentPageIndex * pagination.pageSize,
        currentPageIndex * pagination.pageSize + pagination.pageSize
      );
      return convertValuesToData(pageData, config.entityCode);
    },
    []
  );

  //auto add new row if keepAdding
  useEffect(() => {
    const pageData = getPageData(values, pagination);
    if (values.length === 0) {
      setKeepAdding(true);
      const newRowData = getEmptyRowData();
      setData([newRowData]);
    } else if (keepAdding) {
      setData([...pageData, getEmptyRowData(values)]);
    } else {
      setData(pageData);
    }
  }, [values, getEmptyRowData, keepAdding, getPageData, pagination]);

  const {showLoading, hideLoading} = useContext(LoadingContext);
  const onPagination = (currentPage: number, pageSize: number) => {
    setPagination((prevPagination) => {
      const totalPages = Math.ceil(prevPagination.totalCount / pageSize) || 1;
      if (currentPage > totalPages) {
        currentPage = 1;
      }
      return {...prevPagination, currentPage, pageSize, totalPages};
    });
  };

  const ValidateWithRules = (
    rules: (string | ((value: any, data: InitialData) => any))[],
    value: any,
    rowData?: InitialData
  ) => {
    let errorMessage;
    const isEmptyOrNil = R.isEmpty(value) || R.isNil(value);

    rules.some((rule) => {
      if (rule === ValidateRule.Required && isEmptyOrNil) {
        errorMessage = Consts.ValidationMessage.Required;
        return true;
      } else if (rule === ValidateRule.NumberOnly && !isEmptyOrNil) {
        const hasError = isNaN(value);
        if (hasError) {
          errorMessage = Consts.ValidationMessage.NumberOnly;
          return true;
        }
      } else if (rule === ValidateRule.DateOnly) {
        if (!isValidDate(new Date(value))) {
          errorMessage = Consts.ValidationMessage.Date.FormatError;
          return true;
        }
      } else if (typeof rule === 'function' && !!rowData) {
        const message = rule(value, rowData);
        if (message) {
          errorMessage = message;
          return true;
        }
      }
      return false;
    });
    return errorMessage;
  };

  const getUpdatedErrors = (
    propName: string,
    errorMessage: string | undefined,
    rowIndex: number,
    errors: TableErrors
  ): TableErrors => {
    if (errorMessage) {
      return R.assocPath([rowIndex, propName, 'error'], errorMessage, errors);
    } else {
      return R.dissocPath([rowIndex, propName, 'error'], errors);
    }
  };

  const shouldShowError = (propName: string, rowIndex: number, errors: TableErrors) => {
    return R.pathSatisfies(
      (prop: TError) => !!(prop && prop.error && prop.touched),
      [rowIndex, propName],
      errors
    );
  };

  const validateRow = (
    rowData: InitialData | undefined,
    rowIndex: number,
    propTouched?: string
  ) => {
    if (!rowData) {
      return false;
    }
    let isValid = true;
    setTableErrors((prevTableErrors) => {
      let newErrors = {...prevTableErrors};

      //check startAt
      let startAtErrorMessage = ValidateWithRules(
        [
          ValidateRule.Required,
          ValidateRule.DateOnly,
          (value: string, rowData: InitialData) => {
            const startDate = utcToTZDate(value);
            const minDate = formatToISOByTZ(
              new Date(Consts.Date.AdjustmentMinDate),
              DateType.RangeStart
            );
            const maxDate = formatToISOByTZ(new Date(), DateType.RangeStart);
            if (startDate < new Date(minDate) || startDate > new Date(maxDate)) {
              return Consts.ValidationMessage.Date.AdjustmentDateRangeError;
            }
            if (rowData.agreementId && rowData.agreementEndAt) {
              if (startDate > new Date(rowData.agreementEndAt)) {
                return `${
                  Consts.ValidationMessage.Date.AgreementAdjustmentStartDateRangeError
                } ${formatDate(rowData.agreementEndAt)}`;
              }
            }
          },
        ],
        rowData.startAt,
        rowData
      );
      newErrors = getUpdatedErrors('startAt', startAtErrorMessage, rowIndex, newErrors);

      //check endAt
      let endAtErrorMessage = ValidateWithRules(
        [
          ValidateRule.Required,
          ValidateRule.DateOnly,
          (value, rowData) => {
            const date = utcToTZDate(value);
            const minDate = formatToISOByTZ(
              new Date(Consts.Date.AdjustmentMinDate),
              DateType.RangeEnd
            );
            const maxDate = formatToISOByTZ(new Date(), DateType.RangeEnd);
            if (date < new Date(minDate) || date > new Date(maxDate)) {
              return Consts.ValidationMessage.Date.AdjustmentDateRangeError;
            }
            if (rowData.startAt && date < new Date(rowData.startAt)) {
              return Consts.ValidationMessage.Date.EndDateRangeError;
            }
            if (rowData.agreementId && rowData.agreementStartAt) {
              if (date < new Date(rowData.agreementStartAt)) {
                return `${
                  Consts.ValidationMessage.Date.AgreementAdjustmentEndDateRangeError
                } ${formatDate(rowData.agreementStartAt)}`;
              }
            }
          },
        ],
        rowData.endAt,
        rowData
      );
      newErrors = getUpdatedErrors('endAt', endAtErrorMessage, rowIndex, newErrors);

      //check agreement/sku
      if (rowData.adjustmentType === Consts.AdjustmentTypeEnum.Agreement) {
        let errorMessage = ValidateWithRules([ValidateRule.Required], rowData.agreementId);

        newErrors = getUpdatedErrors('agreementId', errorMessage, rowIndex, newErrors);
      } else {
        let skuErrorMessage = ValidateWithRules([ValidateRule.Required], rowData.productCode);
        newErrors = getUpdatedErrors('productCode', skuErrorMessage, rowIndex, newErrors);
      }

      //check amount
      let amountErrorMessage = ValidateWithRules(
        [ValidateRule.Required, ValidateRule.NumberOnly],
        rowData.amount
      );
      newErrors = getUpdatedErrors('amount', amountErrorMessage, rowIndex, newErrors);

      if (propTouched) {
        newErrors = R.assocPath([rowIndex, propTouched, 'touched'], true, newErrors);
        if (propTouched === 'startAt' && rowData.endAt) {
          newErrors = R.assocPath([rowIndex, 'endAt', 'touched'], true, newErrors);
        } else if (propTouched === 'endAt' && rowData.startAt) {
          newErrors = R.assocPath([rowIndex, 'startAt', 'touched'], true, newErrors);
        }
      } else {
        //update all props to touched if no propTouched provided.
        if (newErrors && R.has(`${rowIndex}`, newErrors)) {
          Object.keys(newErrors[rowIndex]).forEach(
            (key) => (newErrors[rowIndex][key]['touched'] = true)
          );
        }
      }
      if (newErrors && R.has(`${rowIndex}`, newErrors)) {
        const hasError = Object.values(newErrors[rowIndex]).some((x) => x.error);
        if (hasError) {
          isValid = false;
        }
      }
      if (isNaN(rowData.amount as never)) {
        isValid = false;
      }
      return newErrors;
    });

    return isValid;
  };

  const onSave = (rowData: InitialData, rowIndex: number) => {
    const isValid = validateRow(rowData, rowIndex);
    if (!isValid || !rowData.id) {
      return;
    }
    const requestData = convertDataToRequest(rowData, config.entityCode);
    put(api(Consts.Api.Adjustment.replace(':id', `${rowData.id}`)), requestData)
      .then((response) => {
        hideLoading();
        alertService.clear(defaultAlertId);
        setValues((prevValues) => {
          const newRowData = {
            ...response.data,
            dataMode: DataMode.Display,
          };
          const newValues = [...prevValues];
          newValues.splice(getValuesIndexByDataIndex(rowIndex), 1, newRowData);
          return newValues;
        });
        setAction(ApiAction.Edit);
        setTableErrors(R.dissocPath([rowIndex], tableErrors));
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const onAdd = (rowData: InitialData, index: number) => {
    const isValid = validateRow(rowData, index);
    if (!isValid) {
      return;
    }
    const requestData = convertDataToRequest(rowData, config.entityCode);
    showLoading();
    post(api(Consts.Api.Adjustments), requestData)
      .then((response) => {
        setValues((prevValues) => [...prevValues, response.data]);
        setAction(ApiAction.Add);
        setTableErrors((prevErrors) => {
          return R.dissocPath([index], prevErrors);
        });
        hideLoading();
        alertService.clear(defaultAlertId);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const onEdit = (rowData: InitialData, index: number) => {
    const newRowData = {
      ...rowData,
      dataMode: DataMode.Edit,
    };
    setData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1, newRowData);
      return newData;
    });
  };

  const getValuesIndexByDataIndex = (dataIndex: number) => {
    return dataIndex + pagination.pageSize * (pagination.currentPage - 1);
  };

  const onDelete = (rowData: InitialData, rowIndex: number) => {
    if (rowData.dataMode === DataMode.Add) {
      setTableErrors((prevErrors) => {
        return R.dissocPath([rowIndex], prevErrors);
      });
      setData((prevData) => {
        const newData = [...prevData];
        newData.splice(rowIndex, 1);
        return newData;
      });
      setKeepAdding(false);
    } else {
      showLoading();
      del(api(Consts.Api.Adjustment.replace(':id', `${rowData.id}`)))
        .then(() => {
          hideLoading();
          alertService.clear(defaultAlertId);
          setValues((prevData) => {
            const newData = [...prevData];
            newData.splice(getValuesIndexByDataIndex(rowIndex), 1);
            return newData;
          });
          setAction(ApiAction.Delete);
          setTableErrors((prevErrors) => {
            return R.dissocPath([rowIndex], prevErrors);
          });
        })
        .catch((error) => {
          hideLoading();
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    }
  };

  const onCancelEdit = (index: number) => {
    const pageData = getPageData(values, pagination);
    let newRowData = {...pageData[index]};
    newRowData.dataMode = DataMode.Display;
    setData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1, newRowData);
      return newData;
    });
    setTableErrors((prevErrors) => {
      return R.dissocPath([index], prevErrors);
    });
  };

  const getColumns = (): Column<InitialData>[] => {
    const columns: Column<InitialData>[] = [
      {
        field: 'startAt',
        title: 'Start Date',
        render: (rowData: InitialData) => (rowData.startAt ? formatDate(rowData.startAt) : ''),
        renderEdit: renderStartDateColumn,
        editStyle: {width: '9.75rem', minWidth: '9.75rem'},
      },
      {
        field: 'endAt',
        title: 'End Date',
        render: (rowData: InitialData) => (rowData.endAt ? formatDate(rowData.endAt) : ''),
        renderEdit: renderEndDateColumn,
        editStyle: {width: '9.75rem', minWidth: '9.75rem'},
      },
      {
        field: 'adjustmentType',
        title: 'Adjustment Type',
        render: (rowData: InitialData) => {
          if (!rowData.adjustmentType) {
            rowData.adjustmentType = rowData.agreementId
              ? Consts.AdjustmentTypeEnum.Agreement
              : Consts.AdjustmentTypeEnum.Sku;
          }
          const adjustmentType = R.find(
            R.propEq('value', rowData.adjustmentType),
            Consts.AdjustmentType
          );
          return adjustmentType ? adjustmentType.label : null;
        },
        renderEdit: renderEditAdjustmentTypeColumn,
        editStyle: {width: '7.625rem'},
      },
      {
        field: 'sku/agreement',
        title: 'SKU / Agreement',
        render: renderSkuOrAgreementColumn,
        renderEdit: renderEditSkuOrAgreementColumn,
        editStyle: {minWidth: '12.5rem'},
      },
      {
        field: 'adjustmentReason',
        title: 'Adjustment Reason',
        render: (rowData: InitialData) => {
          const reason = R.find(R.propEq('value', rowData.reason), Consts.AdjustmentReason);
          return reason ? reason.label : null;
        },
        renderEdit: renderEditAdjustmentReasonColumn,
        editStyle: {width: '6.25rem'},
      },
      {
        field: 'amount',
        title: 'Amount',
        render: renderValueColumn,
        renderEdit: renderEditValueColumn,
        editStyle: {width: '9.375rem', minWidth: '7.75rem'},
      },
      {
        field: 'effectOnNetPurchases',
        title: 'Effect on Net Purchases',
        render: renderEffectOnNetpurchasesColumn,
        editStyle: {width: '6.25rem'},
      },
      {
        field: 'gstType',
        title: 'GST',
        render: (rowData: InitialData) => rowData.gstType,
        renderEdit: renderEditGstColumn,
        editStyle: {width: '7.125rem'},
      },
      {
        field: null,
        title: '',
        render: renderActionColumn,
        style: {width: '6.25rem'},
        isAction: true,
      },
    ];
    return columns.filter((x) => !x.hide);
  };

  const updateStateData = (rowData: InitialData, key: string, index: number, value: any) => {
    let newRowData = {...rowData, [key]: value};
    validateRow(newRowData, index, key);
    setData((prev) => {
      let newData = [...prev];
      newData.splice(index, 1, newRowData);
      return newData;
    });
  };

  const noteColumn: Column<InitialData> = {
    field: 'note',
    title: '',
    colspan: 6,
    colspanLeft: 2,
    colspanRight: 1,
    style: {
      fontStyle: 'italic',
      opacity: 0.7,
    },
    render: (rowData) => rowData.note,
    renderEdit: (rowData, rowIndex) => {
      return (
        <CellContainer>
          <TableCellInputField
            fullWidth
            defaultValue={rowData.note ?? undefined}
            placeholder={'Notes'}
            onChanged={(value) => {
              updateStateData(rowData, 'note', rowIndex, value);
            }}
          />
        </CellContainer>
      );
    },
  };

  const renderStartDateColumn = (rowData: InitialData, rowIndex: number) => {
    const key = 'startAt';
    const showError = shouldShowError(key, rowIndex, tableErrors);
    return (
      <DatePicker
        dateType={DateType.RangeStart}
        value={rowData.startAt ?? ''}
        onChanged={(value) => {
          updateStateData(rowData, key, rowIndex, value);
        }}
        placeholder="Type/choose"
        showError={showError}
        errorMessage={R.path([rowIndex, key, 'error'], tableErrors)}
      />
    );
  };

  const renderEndDateColumn = (rowData: InitialData, rowIndex: number) => {
    const key = 'endAt';
    const showError = shouldShowError(key, rowIndex, tableErrors);
    return (
      <DatePicker
        dateType={DateType.RangeEnd}
        value={rowData.endAt ?? ''}
        onChanged={(value) => {
          updateStateData(rowData, key, rowIndex, value);
        }}
        placeholder="Type/choose"
        showError={showError}
        errorMessage={R.path([rowIndex, key, 'error'], tableErrors)}
      />
    );
  };

  const renderEditAdjustmentTypeColumn = (rowData: InitialData, rowIndex: number) => {
    const defaultValue = R.find(R.propEq('value', rowData.adjustmentType), Consts.AdjustmentType);
    return (
      <TableCellSelect
        style={{minWidth: '6.25rem'}}
        options={Consts.AdjustmentType}
        defaultValue={defaultValue}
        onChanged={(option: SelectOption) => {
          const {
            agreementId,
            agreementDescription,
            agreementVendorName,
            agreementStartAt,
            agreementEndAt,
            productCode,
            productDescription,
            ...rest
          } = rowData;
          const adjustmentType = option.value;
          if (adjustmentType === Consts.AdjustmentTypeEnum.Sku) {
            const newRowData = {
              ...rest,
              productCode,
              productDescription,
            };
            updateStateData(newRowData, 'adjustmentType', rowIndex, adjustmentType);
            setTableErrors((prevErrors) => {
              return R.dissocPath([rowIndex, 'agreementId'], prevErrors);
            });
          } else {
            const newRowData = {
              ...rest,
              agreementId,
              agreementDescription,
              agreementVendorName,
              agreementStartAt,
              agreementEndAt,
            };
            updateStateData(newRowData, 'adjustmentType', rowIndex, adjustmentType);
            setTableErrors((prevErrors) => {
              return R.dissocPath([rowIndex, 'productCode'], prevErrors);
            });
          }
        }}
      />
    );
  };

  const renderEffectOnNetpurchasesColumn = (rowData: InitialData) => {
    const floatValue = parseFloat(`${rowData.amount}`);
    if (isNaN(floatValue) || floatValue === 0) {
      return <span className={classes.editTableCellSpan}>{Consts.EffectOnNetPurchases.None}</span>;
    }
    const negative = isNegative(floatValue);
    const effectText = negative
      ? Consts.EffectOnNetPurchases.Decrease
      : Consts.EffectOnNetPurchases.Increase;
    return negative ? (
      <RedSpan className={classes.editTableCellSpan}>{effectText}</RedSpan>
    ) : (
      <span className={classes.editTableCellSpan}>{effectText}</span>
    );
  };

  const renderValueColumn = (rowData: InitialData) => {
    return <Amount value={rowData.amount}></Amount>;
  };

  const renderEditValueColumn = (rowData: InitialData, rowIndex: number) => {
    let defaultType = R.find(
      R.propEq('value', Consts.AmountTypeEnum.FixedAmount),
      Consts.AmountType
    );
    let showError = shouldShowError('amount', rowIndex, tableErrors);
    let defaultLabel = defaultType?.label;
    return (
      <CellContainer sx={{maxWidth: '15.625rem'}}>
        <TableCellUnitField
          key={defaultLabel}
          defaultValue={defaultLabel}
          style={{maxWidth: '2.5rem'}}
        />
        <TableCellInputField
          showNegativeColor
          unitInLeft
          fullWidth
          error={showError}
          helperText={showError ? R.path([rowIndex, 'amount', 'error'], tableErrors) : undefined}
          defaultValue={`${rowData.amount ?? ''}`}
          placeholder={'Amount'}
          onChanged={(value) => {
            updateStateData(rowData, 'amount', rowIndex, value);
          }}
        />
      </CellContainer>
    );
  };

  const renderEditAdjustmentReasonColumn = (rowData: InitialData, index: number) => {
    const defaultValue = R.find(R.propEq('value', rowData.reason), Consts.AdjustmentReason);
    return (
      <TableCellSelect
        fullWidth
        style={{minWidth: '7rem'}}
        options={Consts.AdjustmentReason}
        defaultValue={defaultValue}
        onChanged={(option: SelectOption) => {
          updateStateData(rowData, 'reason', index, option.value);
        }}
      />
    );
  };

  const renderEditGstColumn = (rowData: InitialData, index: number) => {
    const defaultValue = R.find(R.propEq('value', rowData.gstType), Consts.GstType);
    return (
      <TableCellSelect
        fullWidth
        style={{minWidth: '7rem'}}
        options={Consts.GstType.filter((x) => x.value !== Consts.GstTypeEnum.Free)}
        defaultValue={defaultValue}
        onChanged={(option: SelectOption) => {
          updateStateData(rowData, 'gstType', index, option.value);
        }}
      />
    );
  };

  const renderActionColumn = (rowData: InitialData, index: number) => {
    let leftActionButtonStyle = {marginRight: '0.25rem'};
    if (rowData.dataMode === DataMode.Edit) {
      return (
        <ActionCellContainer>
          <SaveActionButton style={leftActionButtonStyle} onClick={() => onSave(rowData, index)} />
          <CancelActionButton onClick={() => onCancelEdit(index)} />
        </ActionCellContainer>
      );
    }
    if (rowData.dataMode === DataMode.Add) {
      let disabled = rowData.dataMode === DataMode.Add && data.length === 1;
      return (
        <ActionCellContainer>
          <SaveActionButton style={leftActionButtonStyle} onClick={() => onAdd(rowData, index)} />
          <DeleteActionButton disabled={disabled} onClick={() => onDelete(rowData, index)} />
        </ActionCellContainer>
      );
    }
    let disabled =
      rowData.dataMode !== DataMode.Edit && R.any(R.propEq('dataMode', DataMode.Edit), data);
    return (
      <ActionCellContainer>
        <EditActionButton
          style={leftActionButtonStyle}
          disabled={disabled}
          onClick={() => onEdit(rowData, index)}
        />
        <DeleteActionButton disabled={disabled} onClick={() => onDelete(rowData, index)} />
      </ActionCellContainer>
    );
  };

  const getAgreementSearchText = (rowData: InitialData) => {
    if (rowData.agreementId) {
      return `${rowData.agreementId} - ${rowData.agreementVendorName} ${rowData.agreementDescription}`;
    }
    return null;
  };

  const renderSkuOrAgreementColumn = (rowData: InitialData, rowIndex: number) => {
    if (!rowData.adjustmentType) {
      rowData.adjustmentType = rowData.agreementId
        ? Consts.AdjustmentTypeEnum.Agreement
        : Consts.AdjustmentTypeEnum.Sku;
    }
    if (rowData.adjustmentType === Consts.AdjustmentTypeEnum.Sku) {
      return rowData.productCode ? `${rowData.productCode} ${rowData.productDescription}` : null;
    } else {
      return getAgreementSearchText(rowData);
    }
  };

  const renderEditSkuOrAgreementColumn = (rowData: InitialData, rowIndex: number) => {
    if (rowData.adjustmentType === Consts.AdjustmentTypeEnum.Sku) {
      return renderEditSkuColumn(rowData, rowIndex);
    } else {
      return renderEditAgreementColumn(rowData, rowIndex);
    }
  };

  const renderEditSkuColumn = (rowData: InitialData, rowIndex: number) => {
    const key = 'productCode';
    let showError = shouldShowError(key, rowIndex, tableErrors);
    return (
      <SkuSearch
        fullWidth
        defaultValue={
          rowData.productCode
            ? {
                code: rowData.productCode,
                name: rowData.productDescription,
              }
            : null
        }
        errorMessage={showError && R.path([rowIndex, key, 'error'], tableErrors)}
        onChanged={(item: {code: string; name: string}) => {
          if (!item) {
            return;
          }
          setData((prevData) => {
            const editedRow = prevData.find((x) => x.id === rowData.id);
            if (!editedRow) {
              return prevData;
            }
            const nextRow = {
              ...editedRow,
              productCode: item.code,
              productDescription: item.name,
            };
            validateRow(nextRow, rowIndex, key);
            let newData = [...prevData];
            newData.splice(rowIndex, 1, nextRow);
            return [...newData];
          });
        }}
      />
    );
  };
  const handleAgreementSearchSelect = (
    rowData: InitialData,
    rowIndex: number,
    option?: AgreementLookupItem | null
  ) => {
    if (!option) {
      return;
    }
    let newRowData = {
      ...rowData,
      agreementDescription: option.description,
      agreementVendorName: option.vendorName,
      agreementStartAt: option.startAt,
      agreementEndAt: option.endAt,
    };
    updateStateData(newRowData, 'agreementId', rowIndex, option.agreementId);
  };

  const renderEditAgreementColumn = (rowData: InitialData, rowIndex: number) => {
    const key = 'agreementId';
    let showError = shouldShowError(key, rowIndex, tableErrors);
    const defaultValue = rowData.agreementId
      ? {
          agreementId: rowData.agreementId ?? null,
          description: rowData.agreementDescription ?? '',
          vendorName: rowData.agreementVendorName ?? '',
        }
      : null;
    return (
      <AgreementSearch
        fullWidth
        placeholder="Typing vendor or agreement"
        startAt={rowData.startAt ?? ''}
        endAt={rowData.endAt ?? ''}
        defaultValue={defaultValue}
        errorMessage={showError ? tableErrors?.[rowIndex]?.[key]?.error ?? '' : ''}
        onSelected={(option) => handleAgreementSearchSelect(rowData, rowIndex, option)}
      />
    );
  };

  function onChangePage(currentPage: number) {
    setTableErrors({});
    onPagination(currentPage, pagination.pageSize);
  }

  function onChangePageSize(pageSize: number) {
    setTableErrors({});
    onPagination(1, pageSize);
  }

  function renderCell(column: Column<InitialData>, rowData: InitialData, rowIndex: number) {
    let rowElement = null;
    if (
      (rowData.dataMode === DataMode.Add || rowData.dataMode === DataMode.Edit) &&
      column.renderEdit
    ) {
      rowElement = column.renderEdit(rowData, rowIndex);
    } else if (column.render) {
      if (column.isAction) {
        rowElement = <ActionCellContainer>{column.render(rowData, rowIndex)}</ActionCellContainer>;
      } else {
        rowElement = <CellContainer>{column.render(rowData, rowIndex)}</CellContainer>;
      }
    } else if (column.field) {
      rowElement = rowData[column.field as never] ?? null;
    }
    return rowElement;
  }

  function renderRow(rowData: InitialData, rowIndex: number) {
    const columns = getColumns();
    const displayMode = rowData.dataMode === DataMode.Display || !rowData.dataMode;
    const showNote = !displayMode || rowData.note;
    const noteStyle = displayMode ? noteColumn.style : noteColumn.editStyle;
    return (
      <Fragment key={`${rowIndex}`}>
        <TableRow key={`${rowIndex}`}>
          {columns.map((column, columnIndex) => {
            let style = column.style;
            if (!displayMode && column.editStyle) {
              style = column.editStyle;
            }
            //remove bottom border if show note.
            if (showNote) {
              style = {...style, borderBottom: 'none'};
              if (displayMode) {
                style = {...style, paddingBottom: 0};
              }
            }

            return (
              <TableCell
                classes={{root: displayMode ? '' : classes.tableCell}}
                key={`${rowIndex}-${columnIndex}`}
                style={style}
              >
                {renderCell(column, rowData, rowIndex)}
              </TableCell>
            );
          })}
        </TableRow>

        {showNote ? (
          <TableRow key={`${rowIndex}-note`}>
            <>
              {noteColumn.colspanLeft ? (
                <TableCell
                  key={`${rowIndex}-note-left`}
                  colSpan={noteColumn.colspanLeft}
                ></TableCell>
              ) : null}
              <TableCell
                key={`${rowIndex}-note`}
                colSpan={noteColumn.colspan || 1}
                classes={{
                  root: displayMode
                    ? classes.tableCellNote
                    : `${classes.tableCell} ${classes.tableCellNote}`,
                }}
                style={noteStyle}
              >
                {renderCell(noteColumn, rowData, rowIndex)}
              </TableCell>
              {noteColumn.colspanRight ? (
                <TableCell
                  key={`${rowIndex}-note-right`}
                  colSpan={noteColumn.colspanRight}
                ></TableCell>
              ) : null}
            </>
          </TableRow>
        ) : null}
      </Fragment>
    );
  }

  function AddRow() {
    let emptyRowData = getEmptyRowData(values);
    setData([...data, emptyRowData]);
    setKeepAdding(true);
  }

  return (
    <StyledTableContainer component={Paper}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {getColumns()
              .filter((x) => !x.additionalLine)
              .map((column, index) => {
                return (
                  <TableCell key={index} style={column.style}>
                    {column.title}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>{data.map(renderRow)}</TableBody>
      </Table>
      <TablePaginationWithAddButton
        addButtonText="Add another adjustment value"
        disabled={data.some((item) => item.dataMode === DataMode.Add)}
        onAdd={AddRow}
        pagination={pagination}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
    </StyledTableContainer>
  );
};

export default AdjustmentValuesTable;
