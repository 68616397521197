import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {
  advanceUnreleasedRebates,
  AdvanceUnreleasedAmountsRequest,
  searchUnreleasedRebates,
  SearchUnreleasedRebatesRequest,
  toggleShowRebateAdvanceModal,
  UnreleasedRebateData,
} from '../../../app/unreleasedRebatesReducer';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {formatDate} from '../../../utils/DateUtils';
import {TableColumn} from '../../../types';
import {ConfirmModal} from '../../Modal';
import {SimpleDataTable} from '../../SimpleDataTable';
import Spinner from '../../Spinner/Spinner';
import {advanceModalStyles, selectedRebateData, selectedRebateDataCount} from '../Common';

const columns: TableColumn[] = [
  {id: 'agreementId', label: 'Agreement ID'},
  {id: 'agreementDescription', label: 'Agreement Description'},
  {id: 'agreementType', label: 'Type'},
  {id: 'rebateType', label: 'Rebate Type'},
  {id: 'rebateId', label: 'Rebate ID'},
  {id: 'rebateDescription', label: 'Rebate Description'},
  {
    id: 'startDate',
    label: 'Start Date',
    render: (rowData: UnreleasedRebateData, index) => formatDate(rowData.startDate),
  },
  {
    id: 'endDate',
    label: 'End Date',
    render: (rowData: UnreleasedRebateData, index) => formatDate(rowData.endDate),
  },
  {
    id: 'unreleasedRebate',
    label: 'Unreleased Rebate',
    render: (rowData: UnreleasedRebateData, rowIndex) => {
      return getDisplayAmountValue(rowData.unreleasedRebate, '$');
    },
  },
];

function AdvanceRebatesModal() {
  const dispatch = useAppDispatch();
  const {
    typeFilter,
    rebateTypeFilter,
    startDateFilter,
    endDateFilter,
    searchText,
    orderBy,
    pageSize,
    currentPage,
    showAdvanceModal,
    isAdvancing,
  } = useAppSelector((s) => s.unreleasedRebates);
  const selectedRows = useAppSelector(selectedRebateData);
  const selectedCount = useAppSelector(selectedRebateDataCount);

  async function advanceAndSearchRebates() {
    const advanceRequest: AdvanceUnreleasedAmountsRequest = {
      ids: selectedRows.map((x) => x.id),
    };
    await dispatch(advanceUnreleasedRebates(advanceRequest));
    const searchRequest: SearchUnreleasedRebatesRequest = {
      searchText,
      pageSize,
      currentPage,
      agreementType: typeFilter,
      rebateType: rebateTypeFilter,
      startAt: startDateFilter,
      endAt: endDateFilter,
      orderBy: orderBy,
    };
    await dispatch(searchUnreleasedRebates(searchRequest));
  }
  return (
    <ConfirmModal
      styles={advanceModalStyles}
      title="Review and Confirm"
      renderSubtitle={() => (
        <>
          <div>The below unreleased amounts ({selectedCount}) are about to be released. </div>
          <b>
            This cannot be undone. Once a Rebate has been released, no Products can be released
            until tomorrow.
          </b>
          <div>Please review and ensure all details are correct.</div>
        </>
      )}
      open={showAdvanceModal}
      onOk={async () => {
        await advanceAndSearchRebates();
      }}
      onCancel={() => {
        dispatch(toggleShowRebateAdvanceModal());
      }}
      okText="Proceed"
      cancelText="Go back"
    >
      <Spinner loading={isAdvancing} />
      <div>
        <SimpleDataTable
          columns={columns}
          rows={selectedRows}
          highlightColumn={undefined}
          pagination={undefined}
        />
      </div>
    </ConfirmModal>
  );
}

export default AdvanceRebatesModal;
