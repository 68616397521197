import {useCallback, useState} from 'react';

// This is to delay for {delay} when setting the value to true;
// when setting the value to false, it will work immediately, and clear the timer for delay.
const useDelayLoading = (value, delay = 200) => {
  const [loading, setLoading] = useState(value);
  const [, setTimer] = useState(null);
  const setDelayLoading = useCallback(
    (newValue) => {
      if (newValue) {
        setTimer(
          setTimeout(() => {
            setLoading(newValue);
          }, delay)
        );
      } else {
        setTimer((prevTimer) => {
          if (prevTimer) {
            clearTimeout(prevTimer);
          }
          setLoading(false);
          return false;
        });
      }
    },
    [delay]
  );
  return [loading, setDelayLoading];
};

export default useDelayLoading;
