/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@mui/material/Grid';
import React, {useEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import {DealSideFilter} from '../../../app/dealsReducer';
import {useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode, selectLoggedInStaffDisplayName} from '../../../app/selectors';
import {Owner} from '../../../app/ownersReducer';
import {FinanceAccount} from '../../../app/financialAccountsReducer';
import Consts from '../../../app/Consts';
import {DateType, isValidDate} from '../../../utils/DateUtils';
import {Department} from '../../../types';
import FieldLabel from '../../Form/FieldLabel';
import DatePicker from '../../Form/DatePicker';
import SideFilters from '../../SideFilters/SideFilters';
import {LinkButton} from '../../Button';
import {VendorSearch} from '../../Form/Agolia';
import OwnersSearch from '../../Form/OwnersSearch/OwnersSearch';
import FinancialAccounts from '../../Form/FinancialAccountsSearch/FinancialAccountsSearch';
import DepartmentMultipleSearch from '../../Form/DepartmentMultipleSearch/DepartmentMultipleSearch';
import {CustomAutoComplete} from '../../CustomAutoComplete';

const FilterGrid = styled(Grid)`
  margin-top: 30px;
`;
const ClearGrid = styled(Grid)`
  margin-top: 10px;
`;

export interface DealListingSideFilterProps {
  my: boolean;
  defaultSelection: DealSideFilter;
  onChange(filter: any): void;
  onClose(): void;
}

const DealListingSideFilter = (props: DealListingSideFilterProps) => {
  const {defaultSelection, onChange, onClose, my} = props;
  const [selection, setSelection] = useState(defaultSelection);
  const firstUpdate = useRef(true);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const displayName = useAppSelector(selectLoggedInStaffDisplayName);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const dateIsSelectedCorrectly = (date: string | null) => {
      return date === null || isValidDate(date);
    };
    if (dateIsSelectedCorrectly(selection?.startAt) && dateIsSelectedCorrectly(selection?.endAt)) {
      onChange(selection);
    }
  }, [selection]);

  const filters = [
    {
      label: 'Claim Vendor',
      field: (
        <VendorSearch
          defaultValue={selection.claimVendor}
          onChange={(item) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, claimVendor: item};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Finance Acc.',
      field: (
        <FinancialAccounts
          value={selection.financeAccount}
          onChange={(_: any, option: FinanceAccount | null) => {
            setSelection((prevSelection) => {
              const newSelection: DealSideFilter = {
                ...prevSelection,
                financeAccount: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Deal Type',
      field: (
        <CustomAutoComplete
          options={Consts.DealTypes}
          value={selection.dealTypes}
          placeholder={
            selection.dealTypes && selection.dealTypes?.length > 0 ? '' : 'Choose a deal type'
          }
          multiple
          onChange={(_: any, option: {label: string; value: string}[]) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                dealTypes: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Start Date',
      field: (
        <DatePicker
          value={selection.startAt}
          dateType={DateType.RangeStart}
          placeholder="Choose a start date"
          onChanged={(value: string) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, startAt: value};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'End Date',
      field: (
        <DatePicker
          dateType={DateType.RangeEnd}
          value={selection.endAt}
          placeholder="Choose an end date"
          onChanged={(value: any) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, endAt: value};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Owner',
      field: (
        <OwnersSearch
          value={my ? {name: displayName ?? '', code: loggedInStaffCode} : selection.owner}
          my={my}
          onChange={(_: any, option: Owner | null) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                owner: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Department',
      field: (
        <DepartmentMultipleSearch
          value={selection.departments}
          onChange={(_: any, option: Department[]) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                departments: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
  ];

  const onClear = () => {
    const arrayFilters = ['dealTypes', 'departments'];
    setSelection((prevSelection) => {
      const newSelection = Object.keys(prevSelection).reduce((acc, key) => {
        if (arrayFilters.includes(key)) {
          acc[key as keyof Pick<DealSideFilter, 'dealTypes' | 'departments'>] = [];
        } else {
          acc[key as keyof Omit<DealSideFilter, 'dealTypes' | 'departments'>] = null;
        }
        return acc;
      }, {} as DealSideFilter);

      if (my) {
        newSelection.owner = prevSelection.owner;
      }
      return newSelection;
    });
  };

  const anyFilterHasValue = (filter: any) => Object.values(filter).some((x) => !!x);

  const relevantFiltersHaveValue = () => {
    const {departments, status, dealTypes, ...filterWithoutArrays} = selection;
    const hasAnyDepartmentsSelected = departments && departments.length > 0;
    const hasAnyDealTypesSelected = dealTypes && dealTypes.length > 0;
    if (my) {
      const {owner, ...selectionWithoutOwners} = filterWithoutArrays;
      return (
        anyFilterHasValue(selectionWithoutOwners) ||
        hasAnyDepartmentsSelected ||
        hasAnyDealTypesSelected
      );
    }
    return (
      anyFilterHasValue(filterWithoutArrays) || hasAnyDepartmentsSelected || hasAnyDealTypesSelected
    );
  };

  return (
    <SideFilters onClose={onClose}>
      {relevantFiltersHaveValue() ? (
        <ClearGrid container justifyContent="flex-end">
          <LinkButton name="clearFilters" onClick={onClear}>
            Clear
          </LinkButton>
        </ClearGrid>
      ) : null}
      {filters.map((item) => (
        <FilterGrid key={item.label}>
          <FieldLabel>{item.label}</FieldLabel>
          {item.field}
        </FilterGrid>
      ))}
    </SideFilters>
  );
};

export default DealListingSideFilter;
