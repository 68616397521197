import React, {FC, useContext} from 'react';
import {styled} from '@mui/material/styles';
import {api, get} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {AdjustmentRequestParams} from '../../types';
import {OutlinedButton} from '../Button';

const DownloadButton = styled(OutlinedButton)`
  width: 13.75rem;
`;

export type DownloadAdjustmentCsvButtonProps = {
  requestData: AdjustmentRequestParams;
};

const DownloadAdjustmentCsvButton: FC<DownloadAdjustmentCsvButtonProps> = ({requestData}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const downloadCsvReport = () => {
    showLoading();
    get(api(Consts.Api.AdjustmentsDownload), {
      params: {
        entityCode: config.entityCode,
        ...requestData,
      },
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = 'Adjustments.csv';
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  return <DownloadButton onClick={downloadCsvReport}>Download CSV Report</DownloadButton>;
};

export default DownloadAdjustmentCsvButton;
