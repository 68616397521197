import {RootState} from '../store';

export const selectUnreleasedLoading = (state: RootState): boolean =>
  state.unreleasedProducts.isLoading || state.unreleasedRebates.isLoading;
export const selectUnreleasedAmountsTabType = (state: RootState): string =>
  state.unreleasedAmounts.tabType;
export const selectProductNumberOfFilters = (state: RootState) => {
  const departmentFilter = state.unreleasedProducts.departmentFilters.length > 0 ? 1 : 0;
  const supplierFilter = state.unreleasedProducts.supplierFilter ? 1 : 0;
  const seasonCodeFilter = state.unreleasedProducts.seasonCodeFilter != null ? 1 : 0;

  return departmentFilter + supplierFilter + seasonCodeFilter;
};
export const selectProductDataCount = (state: RootState): number =>
  state.unreleasedProducts.data.filter((product) => product.selected).length;
export const selectRebateDataCount = (state: RootState): number =>
  state.unreleasedRebates.data.filter((x) => x.selected).length;
export const selectRebateNumberOfFilters = (state: RootState) =>
  [
    state.unreleasedRebates.typeFilter,
    state.unreleasedRebates.rebateTypeFilter,
    state.unreleasedRebates.startDateFilter,
    state.unreleasedRebates.endDateFilter,
  ].filter((x) => x != null).length;
