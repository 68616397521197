import React, {FC, useCallback, useContext, useEffect} from 'react';
import {AxiosResponse} from 'axios';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {useFormikContext} from 'formik';
import {DateType} from '../../utils/DateUtils';
import {post, api, put, del} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import config from '../../app/Config';
import Consts from '../../app/Consts';
import {useAppSelector} from '../../app/store';
import {selectLoggedInStaffCode} from '../../app/selectors';
import {ContractAgreementResponse} from '../../types';
import InputField from '../Form/InputField';
import FieldLabel from '../Form/FieldLabel';
import FieldGrid from '../Form/FieldGrid';
import NartaSwitch from '../Form/NartaSwitch';
import {VendorSearchField} from '../Form/Agolia';
import DatePickerField from '../Form/DatePickerField';
import FormStep from '../Form/FormStep';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import OngoingSwitch from '../Form/OngoingSwitch';
import {ContractAgreementClaimVendorChangeConfirmation} from '../Modal/ClaimVendorChangeConfirmation';
import {ContractAgreementFormValues} from './ContractAgreementForm';

type Props = {
  step: number;
  title: string;
  validationSchema: any;
  totalStep: number;
  lastStepButtonText: string;
  handleBack: (values: ContractAgreementFormValues) => void;
  handleNext: (id: string) => void;
};

const StepContractAgreementDetails: FC<Props> = ({
  step,
  title,
  validationSchema,
  totalStep,
  lastStepButtonText,
  handleBack,
  handleNext,
}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const bag = useFormikContext<ContractAgreementFormValues>();
  const {
    setFieldValue,
    values: {rebateValueCount, id, hasRaisedClaim, claimVendor, isOngoing},
  } = bag;

  const updateFormValues = (updatedValues: {id: number; status: string}) => {
    Object.keys(updatedValues).forEach((key) => {
      setFieldValue(key as string, updatedValues[key as keyof typeof updatedValues], false);
    });
  };

  function getUpdatedFormValues(responseData: ContractAgreementResponse) {
    return {
      id: responseData.id,
      status: responseData.status,
    };
  }

  useEffect(() => {
    let hasId = id && Number(id) > 0;
    if (!hasId) {
      setFieldValue('ownedByStaffCode', loggedInStaffCode);
    }
  }, [setFieldValue, loggedInStaffCode, id]);

  const handleSingleInvoiceChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('groupClaimsOnSingleInvoice', event.target.checked);
    },
    [setFieldValue]
  );

  async function createOrEditContractAgreement(values: ContractAgreementFormValues) {
    showLoading();
    if (values.id && Number(values.id) > 0) {
      const requestData = {
        description: values.description,
        startAt: values.startAt,
        endAt: values.endAt,
        claimVendorCode: values.claimVendor.code,
        ownedByStaffCode: values.ownedByStaffCode,
        groupClaimsOnSingleInvoice: values.groupClaimsOnSingleInvoice,
        lastUpdatedByStaffCode: loggedInStaffCode,
      };
      return await put(api(Consts.Api.ContractAgreement.replace(':id', values.id)), requestData)
        .then((response: AxiosResponse<ContractAgreementResponse>) => {
          const updatedValues = getUpdatedFormValues(response.data);
          updateFormValues(updatedValues);
          hideLoading();
          alertService.clear(defaultAlertId);
          handleNext(`${updatedValues.id}`);
        })
        .catch((error) => {
          hideLoading();
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    }
    const requestData = {
      description: values.description,
      startAt: values.startAt,
      endAt: values.endAt,
      entityCode: config.entityCode,
      claimVendorCode: values.claimVendor.code,
      ownedByStaffCode: loggedInStaffCode,
      createdByStaffCode: loggedInStaffCode,
      groupClaimsOnSingleInvoice: values.groupClaimsOnSingleInvoice,
    };
    return await post(api(Consts.Api.ContractAgreements), requestData)
      .then((response) => {
        const updatedValues = getUpdatedFormValues(response.data);
        updateFormValues(updatedValues);
        hideLoading();
        alertService.clear(defaultAlertId);
        handleNext(`${updatedValues.id}`);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }

  const onConfirmClaimVendorChange = useCallback(() => {
    if (!id) {
      return;
    }
    showLoading();
    del(api(Consts.Api.ContractAgreementRebateValues.replace(':id', id)))
      .then(() => {
        hideLoading();
        setFieldValue('rebateValueCount', 0);
      })
      .catch((error) => {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to clear rebate values', response: error.response},
        });
        hideLoading();
      });
  }, [hideLoading, showLoading, id, setFieldValue]);

  return (
    <>
      <FormStep step={step} title={title}>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="isNarta" fullWidth>
            Is this agreement being processed through NARTA?
          </FieldLabel>
          <NartaSwitch
            disabled={hasRaisedClaim}
            id="isNarta"
            name="claimVendor"
            confirm={
              rebateValueCount > 0 && id ? ContractAgreementClaimVendorChangeConfirmation : null
            }
            onConfirmChange={onConfirmClaimVendorChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="claimVendor">Claim Vendor</FieldLabel>
          <VendorSearchField
            disabled={hasRaisedClaim || claimVendor.code === Consts.Narta.Code}
            id="claimVendor"
            name="claimVendor"
            fullWidth
            confirm={
              rebateValueCount > 0 && id ? ContractAgreementClaimVendorChangeConfirmation : null
            }
            onConfirmChange={onConfirmClaimVendorChange}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="description">Agreement Description</FieldLabel>
          <InputField
            id="description"
            name="description"
            fullWidth
            placeholder="Add your description here"
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="groupClaimsOnSingleInvoice"
                onChange={handleSingleInvoiceChange}
                checked={bag.values.groupClaimsOnSingleInvoice ?? false}
              />
            }
            label="All claims to appear on single invoice"
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="isOngoing" fullWidth>
            Is this agreement ongoing?
          </FieldLabel>
          <OngoingSwitch id="isOngoing" name="isOngoing" />
        </FieldGrid>
        <Grid container spacing={1}>
          <FieldGrid item xs={6}>
            <FieldLabel htmlFor="startAt" fullWidth>
              Start Date
            </FieldLabel>
            <DatePickerField
              dateType={DateType.RangeStart}
              id="startAt"
              name="startAt"
              placeholder="Type or choose a start date"
              minDate={Consts.Date.AgreementStartDateMin}
              fullWidth
            />
          </FieldGrid>
          {isOngoing ? null : (
            <FieldGrid item xs={6}>
              <FieldLabel htmlFor="endAt" fullWidth>
                End Date
              </FieldLabel>
              <DatePickerField
                dateType={DateType.RangeEnd}
                id="endAt"
                name="endAt"
                placeholder="Type or choose an end date"
                maxDate={Consts.Date.MaxEndDateMonthsFromNow}
                fullWidth
              />
            </FieldGrid>
          )}
        </Grid>
      </FormStep>
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={createOrEditContractAgreement}
        step={step}
        totalStep={totalStep}
        lastStepButtonText={lastStepButtonText}
      />
    </>
  );
};

export default StepContractAgreementDetails;
