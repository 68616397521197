import React, {FC, Fragment, useMemo, useCallback, useEffect, useState} from 'react';
import {AxiosResponse} from 'axios';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import qs from 'qs';
import Consts from '../../app/Consts';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {api, get} from '../../utils/Request';
import {getDisplayAmountValue} from '../../utils/AmountUtils';
import {ClaimListItemViewModel, InvoiceReviewResponse, InvoiceReviewListItem} from '../../types';
import {ConfirmModal} from '../Modal';

const styles = {
  okButton: {
    marginLeft: '1.25rem',
    width: '12.5rem',
  },
  cancelButton: {
    width: '12.5rem',
  },
  root: {
    width: '100%',
    overflow: 'auto',
    margin: 'auto 8rem',
  },
  actionSection: {
    justifyContent: 'center',
  },
};

type Props = {
  claims: ClaimListItemViewModel[];
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};

type GroupedListItem = {
  [key: string]: InvoiceReviewListItem[];
};

const TableLoader = ({count}: {count: number}) => (
  <>
    {[...Array(count)].map((_row, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      </TableRow>
    ))}
  </>
);

export const groupItems = (items: InvoiceReviewListItem[]) =>
  items.reduce((acc, item) => {
    if (!acc[item.invoiceGroup]) {
      acc[item.invoiceGroup] = [];
    }
    acc[item.invoiceGroup].push(item);
    return acc;
  }, {} as {[key: string]: InvoiceReviewListItem[]});

const InvoiceReviewModal: FC<Props> = ({claims, open, onOk, onCancel}) => {
  const [loading, setLoading] = useState(false);
  const [groupedListItems, setGroupedListItems] = useState<GroupedListItem>({});
  const [error, setError] = useState(false);

  const requestClaimIds = useMemo(() => claims.map((claim) => claim.id), [claims]);

  const groupByInvoiceGroup = useCallback(groupItems, []);

  // modal does not unmount so clear last data on close
  useEffect(() => {
    if (!open) {
      setGroupedListItems({});
      setError(false);
    }
  }, [open]);

  useEffect(() => {
    if (requestClaimIds.length > 0 && open) {
      setLoading(true);
      setError(false);
      get(api(Consts.Api.ClaimsInvoiceReview), {
        params: {
          claimIds: requestClaimIds,
        },
        paramsSerializer: (params: any) => {
          return qs.stringify(params, {skipNulls: true, arrayFormat: 'repeat'});
        },
      })
        .then((response: AxiosResponse<InvoiceReviewResponse>) => {
          setGroupedListItems(groupByInvoiceGroup(response.data.data));
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [requestClaimIds, groupByInvoiceGroup, open]);

  const subtitle = useCallback(
    () => (
      <div>
        The below claims ({claims.length}) will appear on invoices as shown below. Please review and
        ensure all details are correct.
      </div>
    ),
    [claims]
  );

  return (
    <ConfirmModal
      title="Review and Confirm"
      renderSubtitle={subtitle}
      open={open}
      onOk={onOk}
      okDisabled={error || loading}
      onCancel={onCancel}
      okText="Continue"
      cancelText="Back"
      styles={styles}
    >
      <div>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 800}}>
            <TableHead>
              <TableRow sx={{'td, th': {borderBottom: '1px solid #000'}}}>
                <TableCell>Invoice</TableCell>
                <TableCell align="left">Claim Vendor</TableCell>
                <TableCell align="left">Invoice Description</TableCell>
                <TableCell align="left">Claim Amount</TableCell>
                <TableCell align="left" sx={{width: '8rem'}}>
                  Invoice Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader count={claims.length} />
              ) : (
                <>
                  {Object.entries(groupedListItems).map(([group, items], groupIndex) => (
                    <Fragment key={group}>
                      {groupIndex !== 0 ? (
                        <Divider
                          variant="fullWidth"
                          component="tr"
                          sx={{border: '1px solid #000'}}
                        />
                      ) : null}
                      {items.map((row, index) => (
                        <Fragment key={index}>
                          <TableRow
                            key={index}
                            sx={{
                              border: '0',
                              'td, th': {
                                border: '0',
                                paddingTop: '1.5rem',
                                paddingBottom: !!items[index + 1] ? '0.25rem' : '1.5rem',
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index === 0 ? row.invoiceGroup : null}
                            </TableCell>
                            <TableCell align="left">{row.claimVendorName}</TableCell>
                            <TableCell align="left">{row.invoiceDescription}</TableCell>
                            <TableCell align="left">
                              {getDisplayAmountValue(row.claimAmount, '$')}
                            </TableCell>
                            <TableCell
                              align="left"
                              size="small"
                              sx={{fontWeight: 'bold', width: '8rem'}}
                            >
                              {!!items[index + 1]
                                ? null
                                : getDisplayAmountValue(row.invoiceTotal, '$')}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                      <Divider variant="fullWidth" component="tr" sx={{border: '1px solid #000'}} />
                      <Box sx={{height: '0.75rem'}} component="tr" />
                    </Fragment>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {error ? <Box sx={{margin: '2rem 0'}}>Invoice review unavailable</Box> : null}
      </div>
    </ConfirmModal>
  );
};

export default InvoiceReviewModal;
