import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import isAfter from 'date-fns/isAfter';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {useAppSelector} from '../../../app/store';
import {selectConfigsData} from '../../../app/selectors';
import {api, get} from '../../../utils/Request';
import {Config, InitialSPIVFormValues, SPIVResponse} from '../../../types';
import {PageContainer, PageContentContainer} from '../../../components/Container';
import SPIVForm from '../../../components/SPIVForm';
import {ITSupportLink} from '../../../components/Link';

const EditSPIV = () => {
  const configs = useAppSelector(selectConfigsData) as Config;
  let {id = ''} = useParams<{id: string}>();
  const [initialValues, setInitialValues] = useState<InitialSPIVFormValues | null>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const spivId = parseInt(id, 10);

  const updateFormValues = useCallback(
    (data: SPIVResponse) => {
      setInitialValues({
        id: spivId,
        amount: undefined,
        accrualTaxAmount: undefined,
        claimDaysAfterExpiry: undefined,
        claimInterval: undefined,
        claimVendorActive: data.claimVendorActive,
        claimVendorCode: data.claimVendorCode,
        claimVendorName: data.claimVendorName,
        claimVendorSuppliers: data.claimVendorSuppliers,
        comment: '',
        excludeReturns: true,
        departmentDescription: undefined,
        departmentNumber: data.departmentNumber,
        description: data.description,
        draftMode: data.status === Consts.AgreementStatusEnum.Draft,
        endAt: data.endAt,
        gstType: undefined,
        hasActiveClaim: data.hasActiveClaim,
        hasRaisedClaim: data.hasRaisedClaim,
        isDeleted: undefined,
        isFinalClaimRaised: data.isFinalClaimRaised,
        locationCriteria: data.locationCriteria,
        spivValues: {
          data: [],
          isClaimVendorGstFree: false,
          suppliers: [],
          ...Consts.DefaultPagination,
        },
        spivValueCount: 0,
        startAt: data.startAt,
        status: data.status,
        supplierApprovalNumber: '',
        suppliers: [],
        DealCutoffDate: configs
          ? configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
          : null,
        DealLockDate: configs
          ? configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
          : null,
      });
    },
    [spivId, configs]
  );

  const isLocked = (initialValues: InitialSPIVFormValues | null) => {
    if (!configs || !initialValues) {
      return false;
    }
    if (initialValues.status === Consts.AgreementStatusEnum.Draft) {
      return false;
    }
    const cutoffDate = configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs];
    const lockDate = configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs];
    return (
      cutoffDate &&
      lockDate &&
      initialValues.endAt &&
      isAfter(new Date(), new Date(lockDate)) &&
      isAfter(new Date(cutoffDate), new Date(initialValues.endAt))
    );
  };

  const loadSPIV = useCallback(
    async (id: number) => {
      showLoading();
      try {
        const response: AxiosResponse<SPIVResponse> = await get(
          api(Consts.Api.Spiv.replace(':id', `${id}`))
        );
        alertService.clear(defaultAlertId);
        updateFormValues(response.data);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    },
    [showLoading, hideLoading, updateFormValues]
  );

  useEffect(() => {
    if (spivId) {
      loadSPIV(spivId);
    }
  }, [spivId, loadSPIV]);

  if (isLocked(initialValues)) {
    return (
      <PageContainer>
        <PageContentContainer>
          The deal is locked and cannot be edited anymore. If you want to unlock it, please contact{' '}
          {Consts.ItSupport} at <ITSupportLink />
        </PageContentContainer>
      </PageContainer>
    );
  }
  return initialValues ? <SPIVForm title="Edit SPIV" initialValues={initialValues} /> : null;
};

export default EditSPIV;
