import React from 'react';
import {styled} from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import {NavLink as RouterLink, useLocation} from 'react-router-dom';

const PREFIX = 'NavList';

const classes = {
  root: `${PREFIX}-root`,
  subheaderRoot: `${PREFIX}-subheaderRoot`,
  selectedStyle: `${PREFIX}-selectedStyle`,
};

const StyledList = styled(List)(({theme}) => ({
  [`&.${classes.root}`]: {
    minWidth: '3.125rem',
  },

  [`& .${classes.subheaderRoot}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.selectedStyle}`]: {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledListSubHeader = styled(ListSubheader)`
  font-size: 1.2rem;
  color: #000;
  margin-top: 2.5rem;
`;

const StyledListItemText = styled(ListItemText)`
  color: ${(props) => props.theme.palette.navLink.main};
  font-size: 1rem;
`;

export interface NavItem {
  link: string;
  text: string;
  icon?: JSX.Element;
  show?: boolean;
}

export interface NavListProps {
  headerText: string;
  headerIcon?: JSX.Element;
  items: NavItem[];
}
const NavList = (props: NavListProps) => {
  const {headerText, headerIcon, items} = props;
  const location = useLocation();

  return (
    <StyledList
      subheader={
        <StyledListSubHeader disableSticky={true} classes={{root: classes.subheaderRoot}}>
          {headerIcon && (
            <ListItemIcon
              classes={{
                root: classes.root,
              }}
            >
              {headerIcon}
            </ListItemIcon>
          )}
          {headerText}
        </StyledListSubHeader>
      }
    >
      {items.map((item: NavItem, index: number) => (
        <ListItemButton
          key={item.text}
          component={RouterLink}
          to={item.link}
          classes={{selected: classes.selectedStyle}}
          selected={location.pathname === item.link}
          sx={{
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
            },
          }}
        >
          <ListItemIcon
            classes={{
              root: classes.root,
            }}
          >
            {item.icon}
          </ListItemIcon>
          <StyledListItemText primary={item.text} />
        </ListItemButton>
      ))}
    </StyledList>
  );
};

export default NavList;
