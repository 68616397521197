import React, {useContext} from 'react';
import {styled} from '@mui/material/styles';
import qs from 'qs';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {selectLoggedInStaffCode} from '../../../app/selectors';
import config from '../../../app/Config';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {useAppSelector} from '../../../app/store';
import {get, api} from '../../../utils/Request';
import {OutlinedButton} from '../../Button';

const DownloadButton = styled(OutlinedButton)`
  width: 220px;
`;

interface DownloadDealCsvButtonProps {
  my: boolean;
  requestData: any;
}

export default function DownloadDealCsvButton(props: DownloadDealCsvButtonProps) {
  const {my, requestData} = props;
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);

  const downloadDeals = () => {
    showLoading();
    get(api(Consts.Api.DealAgreementsDownload), {
      params: {
        entityCode: config.entityCode,
        ownedByStaffCode: my ? loggedInStaffCode : null,
        ...requestData,
      },
      paramsSerializer: (params: any) => {
        const result = qs.stringify(params, {skipNulls: true, arrayFormat: 'repeat'});
        return result;
      },
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = my ? 'MyDeals.csv' : 'AllDeals.csv';
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  return <DownloadButton onClick={downloadDeals}>Download CSV Report</DownloadButton>;
}
