import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconLabelButton from '../Button/IconLabelButton';

const WrapperBox = styled('div')<{flexibleHeight?: boolean}>`
  width: 100%;
  height: ${(props) => (props.flexibleHeight ? '100%' : '112px')};
  min-height: ${(props) => (props.flexibleHeight ? '112px' : 'auto')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffffff;
  padding: 0 35px;
  font-size: 18px;
  color: #000000;
  border-bottom: 1px solid #cbd2d8;
  box-sizing: border-box;

  > span {
    &:last-child {
      color: #626262;
    }
  }
`;

const IconLabelGroup = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 10px;
  svg {
    width: 45px;
    margin-right: 20px;
  }
`;

type Props = {
  leftLabel: string;
  rightLabel?: string | React.ReactNode;
  onButtonClick?: () => void;
  buttonLabel?: string;
  buttonIcon?: React.ReactNode;
  icon?: React.ReactNode;
  flexibleHeight?: boolean;
  style?: React.CSSProperties;
  rightLabelFullWidth?: boolean;
};

const SplitTextComponent: FC<Props> = ({
  leftLabel,
  rightLabel,
  onButtonClick,
  buttonLabel,
  buttonIcon,
  icon,
  flexibleHeight = false,
  rightLabelFullWidth = false,
  ...props
}) => {
  return (
    <WrapperBox flexibleHeight={flexibleHeight}>
      <IconLabelGroup>
        {icon}
        {leftLabel}
      </IconLabelGroup>
      <Box sx={{width: rightLabelFullWidth ? '100%' : 'auto'}}>
        {buttonIcon ? (
          <IconLabelButton
            startIcon={false}
            icon={buttonIcon}
            label={buttonLabel}
            onClick={onButtonClick}
            {...props}
          />
        ) : (
          rightLabel
        )}
      </Box>
    </WrapperBox>
  );
};

export {SplitTextComponent};
