import Consts from '../../app/Consts';
import {DealFormValues} from '../../types';

type NonFormFields =
  | 'nartaSwitch'
  | 'skuSelectorMode'
  | 'dealValueAmount'
  | 'dealValueGSTType'
  | 'dealValueSKU';

type DealFormDisabledFields = {
  [k in keyof Partial<DealFormValues>]: boolean;
} & {[key in NonFormFields]: boolean};

type DealFormDisabledActions = {
  editDealValues: boolean;
  addDealValue: boolean;
  deleteDealValue: boolean;
  dealValueBulkUpload: boolean;
};

export const dealFormDisabled = ({
  isFinalClaimRaised = false,
  hasRaisedClaim = false,
  dealType = '',
  claimVendor,
}: DealFormValues): {fields: DealFormDisabledFields; actions: DealFormDisabledActions} => {
  const fixedAmountClaimDisabled = !(
    hasRaisedClaim && dealType === Consts.StandardDealTypeEnum.FixedAmount
  );

  return {
    fields: {
      // page 1 details
      nartaSwitch: isFinalClaimRaised || hasRaisedClaim,
      claimVendor: isFinalClaimRaised || hasRaisedClaim || claimVendor.code === Consts.Narta.Code,
      description: isFinalClaimRaised || hasRaisedClaim,
      departmentNumber: isFinalClaimRaised || hasRaisedClaim,
      startAt: isFinalClaimRaised && fixedAmountClaimDisabled,
      startTime: isFinalClaimRaised && fixedAmountClaimDisabled,
      endAt: isFinalClaimRaised && fixedAmountClaimDisabled,
      endTime: isFinalClaimRaised && fixedAmountClaimDisabled,
      locationCriteria: isFinalClaimRaised && fixedAmountClaimDisabled,
      // page 2 values
      suppliers: isFinalClaimRaised && fixedAmountClaimDisabled,
      skuSelectorMode:
        dealType === Consts.StandardDealTypeEnum.PriceProtection ||
        isFinalClaimRaised ||
        hasRaisedClaim,
      dealType: isFinalClaimRaised || hasRaisedClaim,
      dealValueAmount: isFinalClaimRaised || hasRaisedClaim,
      dealValueGSTType: isFinalClaimRaised || hasRaisedClaim,
      dealValueSKU: isFinalClaimRaised && fixedAmountClaimDisabled,
      // page 3 finance details
      splitAccounts: isFinalClaimRaised || hasRaisedClaim,
      claimDaysAfterExpiry: isFinalClaimRaised || hasRaisedClaim,
      claimInterval: isFinalClaimRaised || hasRaisedClaim,
      excludeReturns:
        dealType !== Consts.StandardDealTypeEnum.Sales || isFinalClaimRaised || hasRaisedClaim,
    },
    actions: {
      editDealValues: isFinalClaimRaised && fixedAmountClaimDisabled,
      addDealValue: isFinalClaimRaised || hasRaisedClaim,
      deleteDealValue: isFinalClaimRaised || hasRaisedClaim,
      dealValueBulkUpload: isFinalClaimRaised || hasRaisedClaim,
    },
  };
};
