import React, {FC} from 'react';
import {styled, SxProps, Theme} from '@mui/material/styles';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const AttachmentDiv = styled('div')`
  background-color: #fff;
  padding: 0.625rem 1.25rem;
  display: flex;
  > span {
    flex: 1;
  }
  margin-bottom: 1rem;
  border: 1px solid #dedede;
  align-items: center;
`;

type Props = {
  fileName: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const Attachment: FC<Props> = ({fileName, children, sx}) => (
  <AttachmentDiv sx={{...(sx ?? {})}}>
    <DescriptionOutlinedIcon sx={{fontSize: '2.5rem'}} />
    <span>{fileName}</span>
    {children}
  </AttachmentDiv>
);

export default Attachment;
