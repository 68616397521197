import React, {FC, useState} from 'react';
import {AxiosResponse} from 'axios';
import Consts from '../../app/Consts';
import {BulkUploadWarningResponse, BulkUploadLineWarnings, BulkUploadLine} from '../../types';
import {WarnIcon} from '../Icons';
import ErrorSection, {ErrorList, ErrorListItem} from './ErrorSection';
import {ConfirmModal} from '.';

const styles = {
  okButton: {
    width: '21.25rem',
  },
  cancelButton: {
    width: '21.25rem',
  },
};

type Props = {
  open: boolean;
  response: AxiosResponse<BulkUploadWarningResponse>;
  onClose: () => void;
  onReupload: () => void;
};

const BulkUploadWarningModal: FC<Props> = ({open, response, onClose, onReupload}) => {
  const [currentErrror, setCurrentError] = useState(Consts.ProductBulkUploadErrorType.None);
  if (!response) {
    return null;
  }
  const warning = response.data?.warning;

  if (!warning) {
    return null;
  }

  const renderSubtitle = () => {
    if (warning.lineWarnings) {
      return <div>Please review the below information and choose how to proceed.</div>;
    }
  };

  const renderLineWarnings = (warning: BulkUploadLineWarnings) => {
    if (warning && warning.lineWarnings && warning.lineWarnings.length > 0) {
      let lineWarnings = warning.lineWarnings;
      let count = lineWarnings.length;
      let expanded = currentErrror === Consts.ProductBulkUploadErrorType.LineWarnings;
      return (
        <ErrorSection
          title={`${count} ${count > 1 ? 'lines have warnings' : 'line has warnings'}`}
          toggleButtonText="View Warnings"
          expanded={expanded}
          onToggle={() => {
            setCurrentError(
              expanded
                ? Consts.ProductBulkUploadErrorType.None
                : Consts.ProductBulkUploadErrorType.LineWarnings
            );
          }}
          sectionIcon={WarnIcon}
        >
          {expanded ? (
            <ErrorList>
              {lineWarnings.map((lineError: BulkUploadLine) => {
                let message = lineError.warningMessages.join(', ');
                return (
                  <ErrorListItem key={lineError.line}>
                    Line {lineError.line} - {message}
                  </ErrorListItem>
                );
              })}
            </ErrorList>
          ) : null}
        </ErrorSection>
      );
    }
  };

  return (
    <ConfirmModal
      title="Upload Information"
      open={open}
      onOk={onClose}
      onCancel={onReupload}
      okText="Continue with current upload"
      cancelText="Upload Again"
      renderSubtitle={renderSubtitle}
      styles={styles}
    >
      {renderLineWarnings(warning)}
    </ConfirmModal>
  );
};

export default BulkUploadWarningModal;
