import React, {FC} from 'react';
import {useFormikContext} from 'formik';
import Switch from '../Form/Switch';
import {ValidSPIVFormValues} from '../../types';
import Consts from '../../app/Consts';

type Props = {
  confirm: React.ElementType | undefined;
  disabled: boolean;
  id: string;
  onConfirmChange: () => void;
};

const SPIVNartaSwitch: FC<Props> = ({confirm, disabled, onConfirmChange, id}) => {
  const bag = useFormikContext<ValidSPIVFormValues>();
  const {
    values: {claimVendorCode},
    setFieldValue,
  } = bag;

  const updateValue = (checked: boolean) => {
    if (checked) {
      setFieldValue('claimVendorCode', Consts.Narta.Code);
      setFieldValue('claimVendorName', Consts.Narta.Name);
      setFieldValue('claimVendorActive', true);
    } else {
      setFieldValue('claimVendorCode', '');
      setFieldValue('claimVendorName', '');
      setFieldValue('claimVendorActive', null);
    }
  };

  const onSwitch = (checked: boolean) => {
    if (confirm) {
      onConfirmChange();
    }
    updateValue(checked);
  };

  return (
    <Switch
      disabled={disabled}
      name="claimVendorCode"
      id={id}
      color="primary"
      confirm={confirm}
      onChanged={onSwitch}
      defaultValue={claimVendorCode === Consts.Narta.Code}
    />
  );
};

export default SPIVNartaSwitch;
