import React from 'react';
import {ConfirmModal} from '.';

const styles = {
  okButton: {
    width: '340px',
  },
  cancelButton: {
    width: '340px',
  },
};

const ClaimVendorChangeConfirmation = ({open, onOk, onCancel, ...props}) => {
  return (
    <ConfirmModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Clear data and continue"
      cancelText="Keep my data and go back"
      styles={styles}
      {...props}
    >
      {props.children}
    </ConfirmModal>
  );
};
export const DealClaimVendorChangeConfirmation = ({open, onOk, onCancel, ...props}) => {
  return (
    <ClaimVendorChangeConfirmation open={open} onOk={onOk} onCancel={onCancel} {...props}>
      <div>
        This action will permanently clear all the deal value data that you currently have. Would
        you like to continue?
      </div>
    </ClaimVendorChangeConfirmation>
  );
};
export const ContractAgreementClaimVendorChangeConfirmation = ({
  open,
  onOk,
  onCancel,
  ...props
}) => {
  return (
    <ClaimVendorChangeConfirmation open={open} onOk={onOk} onCancel={onCancel} {...props}>
      <div>
        This action will permanently clear all the rebates that you currently have. Would you like
        to continue?
      </div>
    </ClaimVendorChangeConfirmation>
  );
};

export const SPIVClaimVendorChangeConfirmation = ({open, onOk, onCancel, ...props}) => (
  <ClaimVendorChangeConfirmation open={open} onOk={onOk} onCancel={onCancel} {...props}>
    <div>
      This action will permanently clear all the SPIV value data that you currently have. Would you
      like to continue?
    </div>
  </ClaimVendorChangeConfirmation>
);
