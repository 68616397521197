import React, {useEffect, useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {api, get} from '../../../utils/Request';
import LoadingContext from '../../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {RebateFormValues, ContractAgreementResponse} from '../../../types';
import RebateForm from '../../../components/RebateForm';

const CreateRebate = () => {
  const {agreementId} = useParams();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [initialValues, setInitialValues] = useState<RebateFormValues | null>(null);

  useEffect(() => {
    if (!agreementId) {
      return;
    }
    showLoading();
    get(api(Consts.Api.ContractAgreement.replace(':id', agreementId)))
      .then((response: AxiosResponse<ContractAgreementResponse>) => {
        alertService.clear(defaultAlertId);

        const {
          claimVendorSuppliers,
          description,
          claimVendorName,
          startAt,
          endAt,
          isClaimVendorGstFree,
        } = response.data;
        setInitialValues({
          accountOptions: [],
          agreementDescription: description,
          agreementEndAt: endAt,
          agreementId: Number(agreementId),
          agreementStartAt: startAt,
          amount: '',
          amountType: Consts.AmountTypeEnum.Percentage,
          claimInterval: Consts.RebateClaimIntervalEnum.EndOfRebate,
          claimVendorName,
          claimVendorSuppliers,
          description: '',
          draftMode: true,
          endAt,
          excludeReturns: false,
          externalId: '',
          financeAccountCleared: false,
          gstType: Consts.GstTypeEnum.Exclusive,
          hasActiveClaim: false,
          hasRaisedClaim: false,
          isClaimVendorGstFree: isClaimVendorGstFree,
          isOngoing: !endAt,
          locationCriteria: null,
          productCriteria: null,
          rebateType: Consts.RebateTypeEnum.Sales,
          splitAccountAmountType: Consts.SplitAccountAmountTypeEnum.Percentage,
          splitAccounts: [],
          startAt,
          status: Consts.RebateStatusEnum.Draft,
          suppliers: [],
          uploadedProductCount: 0,
          writebackType: Consts.WritebackTypeEnum.None,
        });
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [agreementId, hideLoading, showLoading, setInitialValues]);

  return initialValues ? (
    <RebateForm title="Add A New Rebate" initialValues={initialValues} isEditing={false} />
  ) : null;
};

export default CreateRebate;
