import React from 'react';
import {useField} from 'formik';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CheckboxField({label, ...props}) {
  const {errorText, style, ...rest} = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const {touched, error} = meta;
    if (touched && error) {
      return error;
    }
  }
  return (
    <div style={style}>
      <FormControlLabel
        control={<Checkbox checked={field.value || false} {...field} {...rest} />}
        label={label}
      />
      <FormHelperText>{_renderHelperText()}</FormHelperText>
    </div>
  );
}
