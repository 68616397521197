import React, {FC} from 'react';
import {ConfirmModal} from '.';

const styles = {
  okButton: {
    width: '21rem',
  },
  cancelButton: {
    width: '21rem',
  },
};

type props = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};

export const ClearSPIVValueDataConfirmModal: FC<props> = ({open, onOk, onCancel}) => (
  <ConfirmModal
    open={open}
    onOk={onOk}
    onCancel={onCancel}
    okText="Clear data and continue"
    cancelText="Keep my data and go back"
    styles={styles}
  >
    <div>
      This action will permanently clear and replace all the SPIV value data that you currently have
      on the page. Would you like to continue?
    </div>
  </ConfirmModal>
);
