import React, {useEffect, useState, useContext, useCallback, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import {useParams} from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import isValidDate from 'date-fns/isValid';
import {AxiosResponse, AxiosResponseHeaders} from 'axios';
import * as Yup from 'yup';
import Consts from '../../../app/Consts';
import {alertService, AlertType, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {SPIVSummaryResponse, Config, EntityActionType} from '../../../types';
import {api, get, put} from '../../../utils/Request';
import {getDisplayAmountValue} from '../../../utils';
import {MoneyGraphIcon, ChevronRightIcon, LocationsIcon} from '../../../components/Icons';
import ButtonsContainer from '../../../components/Container/ButtonsContainer';
import Button from '../../../components/Button/Button';
import {OutlinedButton} from '../../../components/Button';
import {TableTabPanel} from '../../../components/TableTabPanel';
import {SplitTextComponent} from '../../../components/SplitTextComponent';
import LocationSelector from '../../../components/Form/Agolia/LocationSelector';
import {SplitIconAmountComponent} from '../../../components/SplitIconAmountComponent';
import {TabsComponent} from '../../../components/TabsComponent';
import DynamicContainerWithHeader from '../../../components/Container/DynamicContainerWithHeader';
import {DeleteAgreementorRebateWithClaimForbiddenModal} from '../../../components/Modal';
import {ErrorBox} from '../../../components/Alert';
import FinanceAccountsSummary from '../../../components/DealSummary/FinanceAccountsSummary';
import EditableField from '../../../components/EditableField';
import SPIVSummaryValuesTable from './SPIVSummaryValuesTable';
import SPIVSummaryDetails from './SPIVSummaryDetails';
import SPIVSummaryHeading from './SPIVSummaryHeading';
import SPIVSummaryAttachments from './SPIVSummaryAttachments';

const PREFIX = 'SPIVSummary';

const classes = {
  root: `${PREFIX}-root`,
  pageFlexColumn: `${PREFIX}-pageFlexColumn`,
  flexContent: `${PREFIX}-flexContent`,
  flexContentCentered: `${PREFIX}-flexContentCentered`,
  dealValuesTable: `${PREFIX}-dealValuesTable`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  fixWidthContainerSm: `${PREFIX}-fixWidthContainerSm`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
  tabPanelContainerRoot: `${PREFIX}-tabPanelContainerRoot`,
  tabPanelContentWrapper: `${PREFIX}-tabPanelContentWrapper`,
  contentGroupWrapper: `${PREFIX}-contentGroupWrapper`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.pageFlexColumn}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.flexContent}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  [`& .${classes.flexContentCentered}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 2.5rem',
  },

  [`& .${classes.dealValuesTable}`]: {
    width: '100%',
    padding: '0',
  },

  [`& .${classes.contentWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 1.625rem 0.5rem',
    boxShadow: 'none',
    border: 'none',
    boxSizing: 'border-box',
  },

  [`& .${classes.fixWidthContainerSm}`]: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    backgroundColor: theme.palette.gray.extraLight,
  },

  [`& .${classes.tabPanelContainerRoot}`]: {
    width: '100%',
    maxWidth: '100%',
    padding: '5.25rem 1.5rem 6.25rem',
  },

  [`& .${classes.tabPanelContentWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 1.625rem 5.625rem',
    boxShadow: 'none',
    border: 'none',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.gray.extraLight,
  },

  [`& .${classes.contentGroupWrapper}`]: {
    paddingTop: '1.5rem',
  },
}));

const DownloadButton = styled(Button)`
  width: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 3.625rem;
  background-color: #000000;
  color: #ffffff;
`;

const SummaryReportDownloadButton = styled(OutlinedButton)`
  border-color: black;
  font-size: 1rem;
  width: auto;
  height: 3.625rem;
  padding-left: 0.78125rem;
  padding-right: 0.78125rem;
`;

const TabsIndex = {
  SPIVInformation: 'SPIVInformation',
  Attachments: 'Attachments',
};
const Tabs = [
  {
    value: TabsIndex.SPIVInformation,
    label: 'SPIV Information',
  },
  {
    value: TabsIndex.Attachments,
    label: 'Attachments',
  },
];

export const supplierApprovalNumberValidationSchema = Yup.object().shape({
  supplierApprovalNumber: Yup.string().test(
    'max length check',
    'Supplier approval number cannot exceed 100 characters',
    function (value?: string) {
      return !value || value.length <= 100;
    }
  ),
});

export const isSPIVLocked = (configs: Config | undefined, endAt: string): boolean => {
  if (!configs) {
    return false;
  }
  const lockDate = new Date(configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs]);
  const cutoffDate = new Date(
    configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
  );
  if (isValidDate(lockDate) && isValidDate(cutoffDate)) {
    return new Date() >= lockDate && new Date(endAt) < cutoffDate;
  }
  return false;
};

const SPIVSummary = () => {
  const {id = ''} = useParams<{id: string}>();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [selectedTab, setSelectedTab] = useState(TabsIndex.SPIVInformation);
  const [spivData, setSPIVData] = useState<SPIVSummaryResponse | undefined>();
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);
  const [openDeleteWithClaimForbiddenModal, setOpenDeleteWithClaimForbiddenModal] = useState(false);

  const isCancelled = spivData?.status === Consts.AgreementStatusEnum.Cancelled;

  const setData = useCallback((responseData: SPIVSummaryResponse) => {
    setSPIVData(responseData);
  }, []);

  const fetchSummary = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<SPIVSummaryResponse> = await get(
        api(Consts.Api.SpivSummary.replace(':id', id))
      );
      setData(response.data);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  }, [id, hideLoading, showLoading, setData]);

  useEffect(() => {
    if (id) {
      fetchSummary();
    }
  }, [fetchSummary, id, setData]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const downloadDealValues = async () => {
    try {
      showLoading();
      const response = await get(api(Consts.Api.SpivValuesDownload.replace(':id', id)), {
        responseType: 'blob',
      });
      let filename = 'Summary Report - Deal';
      const contentDisposition = (response.headers as AxiosResponseHeaders)?.get(
        'Content-Disposition'
      );
      if (contentDisposition) {
        const filenameMatch = `${contentDisposition}`.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length > 1) {
          filename = filenameMatch[1];
        }
      }
      const data = response.data;
      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([data]));
      link.href = url;
      link.download = filename;
      link.click();
      link.remove();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const downloadSummaryReport = async () => {
    try {
      showLoading();
      const response = await get(api(Consts.Api.SpivSummaryReportDownload.replace(':id', id)), {
        responseType: 'blob',
      });
      let filename = 'SPIV Summary Report - SPIV';
      const contentDisposition = (response.headers as AxiosResponseHeaders)?.get(
        'Content-Disposition'
      );
      if (contentDisposition) {
        const filenameMatch = `${contentDisposition}`.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length > 1) {
          filename = filenameMatch[1];
        }
      }
      const data = response.data;
      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([data]));
      link.href = url;
      link.download = filename;
      link.click();
      link.remove();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const claimFrequencyLabel = useMemo(() => {
    if (!spivData?.claimInterval) {
      return null;
    }
    return spivData?.claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry
      ? Consts.DealClaimInterval.find(
          (interval) => interval.value === spivData.claimInterval
        )?.label.replace('Set', `${spivData?.claimDaysAfterExpiry ?? ''}`)
      : Consts.DealClaimInterval.find((interval) => interval.value === spivData.claimInterval)
          ?.label;
  }, [spivData]);

  const updateSupplierApprovalNumber = async (supplierApprovalNumber?: string) => {
    try {
      const {claimInterval, claimDaysAfterExpiry, excludeReturns} = spivData ?? {};
      showLoading();
      const requestData = {
        supplierApprovalNumber,
        claimInterval,
        ...(claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry && {
          claimDaysAfterExpiry,
        }),
        excludeReturns,
      };
      await put(api(Consts.Api.SpivFinanceDetails.replace(':id', `${spivData?.id}`)), requestData);
      setSPIVData(
        (prevData) =>
          ({
            ...(prevData ?? {}),
            supplierApprovalNumber,
          } as SPIVSummaryResponse)
      );
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Root className={classes.root}>
      {spivData ? (
        <div className={classes.pageFlexColumn}>
          <Container maxWidth={false}>
            {spivData.isDeleted ? (
              <ErrorBox type={AlertType.Info}>
                This SPIV is deleted - it will be removed overnight
              </ErrorBox>
            ) : null}
            <SPIVSummaryHeading spivData={spivData} onCancel={setData} />
            <Paper className={classes.contentWrapper}>
              <SPIVSummaryDetails spivData={spivData} />
              <div className={classes.fixWidthContainerSm}>
                <SplitIconAmountComponent
                  icon={<MoneyGraphIcon />}
                  label="Total Amount Accrued"
                  amount={spivData.amount}
                  taxAmount={spivData.accrualTaxAmount ?? 0}
                  gstType={spivData.gstType}
                />
              </div>
            </Paper>
          </Container>
          <div>
            <TabsComponent
              tabs={Tabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
            />
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.SPIVInformation}
              className={classes.tabPanelRoot}
            >
              <Container className={classes.tabPanelContainerRoot}>
                <Paper className={classes.tabPanelContentWrapper}>
                  <div className={classes.flexContent}>
                    <DynamicContainerWithHeader title="Finance Details" style={{width: '50%'}}>
                      {isCancelled ? (
                        <SplitTextComponent
                          flexibleHeight
                          leftLabel="SPIVs paid to staff before cancellation"
                          rightLabel={
                            <Box sx={{fontWeight: 700}}>
                              {getDisplayAmountValue(
                                spivData.totalSpivPaidToStaffBeforeCancellation,
                                '$',
                                ' ex'
                              )}
                            </Box>
                          }
                        />
                      ) : (
                        <>
                          <SplitTextComponent
                            flexibleHeight
                            leftLabel="Finance Accounts"
                            rightLabel={
                              <FinanceAccountsSummary financeAccounts={spivData.financeAccounts} />
                            }
                          />
                          <SplitTextComponent
                            leftLabel="Claim Frequency"
                            rightLabel={claimFrequencyLabel}
                          />
                        </>
                      )}
                      <SplitTextComponent
                        leftLabel="Returns"
                        rightLabel={spivData.excludeReturns ? 'Excluded' : 'Included'}
                      />
                      <SplitTextComponent
                        leftLabel="Supplier Approval Number"
                        rightLabelFullWidth
                        rightLabel={
                          <EditableField
                            onSave={updateSupplierApprovalNumber}
                            value={spivData?.supplierApprovalNumber ?? ''}
                            direction="row"
                            readOnly={false}
                            validationSchema={supplierApprovalNumberValidationSchema}
                            name="supplierApprovalNumber"
                          />
                        }
                      />
                    </DynamicContainerWithHeader>
                    <DynamicContainerWithHeader title="Location" style={{width: '45%'}}>
                      <SplitTextComponent
                        leftLabel={
                          spivData.locationCriteria
                            ? `${spivData.locationCriteria.resultCount} locations`
                            : 'All Locations'
                        }
                        buttonLabel="View Locations"
                        icon={<LocationsIcon />}
                        buttonIcon={<ChevronRightIcon />}
                        style={{color: '#DA6A00'}}
                        onButtonClick={() => setLocationSelectorOpen(true)}
                      />
                    </DynamicContainerWithHeader>
                  </div>
                </Paper>
                <div className={classes.flexContentCentered}>
                  <Typography variant="h5">SPIV Values</Typography>
                  {isCancelled ? null : (
                    <ButtonsContainer>
                      <SummaryReportDownloadButton onClick={downloadSummaryReport}>
                        Download SPIV Summary Report
                      </SummaryReportDownloadButton>
                      <DownloadButton onClick={downloadDealValues}>
                        Download CSV Report
                      </DownloadButton>
                    </ButtonsContainer>
                  )}
                </div>
                <div className={classes.dealValuesTable}>
                  <SPIVSummaryValuesTable
                    spivValues={spivData?.spivValues}
                    spivId={spivData.id}
                    hideColumns={{amountSoldOrPurchased: isCancelled}}
                  />
                </div>
              </Container>
            </TableTabPanel>
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.Attachments}
              className={classes.tabPanelRoot}
            >
              <Container className={classes.tabPanelContainerRoot}>
                <Paper className={classes.tabPanelContentWrapper}>
                  <SPIVSummaryAttachments spivId={id} />
                </Paper>
              </Container>
            </TableTabPanel>
          </div>
          {locationSelectorOpen ? (
            <LocationSelector
              fullScreen
              locationCriteria={spivData.locationCriteria}
              open={locationSelectorOpen}
              handleClose={() => setLocationSelectorOpen(false)}
              readOnly
            />
          ) : null}
          <DeleteAgreementorRebateWithClaimForbiddenModal
            entityActionType={EntityActionType.Deal}
            open={openDeleteWithClaimForbiddenModal}
            onOk={() => setOpenDeleteWithClaimForbiddenModal(false)}
          />
        </div>
      ) : null}
    </Root>
  );
};

export default SPIVSummary;
