import React, {FC} from 'react';
import {styled} from '@mui/material/styles';

const PREFIX = 'PageContentContainer';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: 'auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '59rem',
    },
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.pageContent,
    alignSelf: 'flex-start',
    margin: 'auto',
    marginTop: '5.25rem',
    marginBottom: '6.25rem',
    padding: '5.625rem',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '0.625rem',
  },
}));

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const PageContentContainer: FC<Props> = ({className, style = {}, children}) => (
  <Root className={`${classes.root} ${className || ''}`} style={style}>
    {children}
  </Root>
);

export default PageContentContainer;
