import React, {FC} from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {styled} from '@mui/material/styles';
import {FilterListIcon} from '../Icons';

const TitleContainer = styled(Grid)`
  justify-content: space-between;
  align-items: center;
`;
const Title = styled('div')`
  font-size: 1.5rem;
  flex: 1;
  font-weight: 500;
  margin-left: 0.25rem;
`;
const FilterContainer = styled('div')`
  background-color: #fff;
  padding: 3rem 2.25rem;
  overflow: auto;
  border-bottom: 1px solid rgb(203, 210, 216);
`;

type Props = {
  onClose: () => void;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

const SideFilters: FC<Props> = ({onClose, children, style = {}}) => (
  <FilterContainer sx={{borderRight: '1px solid #cbd2d8', width: '16rem', ...style}}>
    <TitleContainer container>
      <FilterListIcon />
      <Title>Filters</Title>
      <IconButton name="close-filter" aria-label="close-filter" onClick={onClose} size="large">
        <CloseIcon />
      </IconButton>
    </TitleContainer>
    <Grid>{children}</Grid>
  </FilterContainer>
);

export default SideFilters;
