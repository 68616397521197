export enum TenantCompany {
  JBH = 'JBH',
  TGG = 'TGG',
}
export enum TenantCountry {
  AU = 'AU',
  NZ = 'NZ',
}
export enum TenantEntityCode {
  JBAU = 'JBAU',
  JBNZ = 'JBNZ',
}
export type TenantHeaders = {'x-jbg-company': TenantCompany; 'x-jbg-country': TenantCountry};
export type DomainConfig = {
  country: TenantCountry;
  company: TenantCompany;
  headers: TenantHeaders;
  entityCode: TenantEntityCode;
};

export const getDomainConfig = (): DomainConfig => {
  let country = process.env.REACT_APP_COUNTRY ?? TenantCountry.AU;
  let company = process.env.REACT_APP_ORG ?? TenantCompany.JBH;
  let entityCode =
    process.env.REACT_APP_COUNTRY === TenantCountry.AU
      ? TenantEntityCode.JBAU
      : TenantEntityCode.JBNZ;
  const location = window.location;
  if (location.host.includes('com.au')) {
    country = TenantCountry.AU;
    entityCode = TenantEntityCode.JBAU;
  } else if (location.host.includes('co.nz')) {
    country = TenantCountry.NZ;
    entityCode = TenantEntityCode.JBNZ;
  }
  if (location.host.includes('jbhifi')) {
    company = TenantCompany.JBH;
  }
  return {
    country,
    company,
    headers: {
      'x-jbg-company': company,
      'x-jbg-country': country,
    },
    entityCode,
  };
};
