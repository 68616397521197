import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import {styled} from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import {LinkButton} from '../Button';

const PREFIX = 'SearchInputField';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTextField = styled(TextField)(({theme}) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.white.main,
  },
}));

const SearchInputField = ({placeholder, onSearch, width, defaultValue = ''}) => {
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [text, setText] = useState(defaultValue ?? '');

  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    setText(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        onSearch(value);
      }, 500)
    );
  };

  return (
    <StyledTextField
      variant="outlined"
      sx={{width: width ?? '100%'}}
      placeholder={placeholder}
      className={classes.root}
      onChange={handleSearch}
      value={text}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {text && (
              <LinkButton
                type="button"
                onClick={() => {
                  setText('');
                  onSearch('');
                }}
              >
                Clear
              </LinkButton>
            )}
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export {SearchInputField};
