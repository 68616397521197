import React from 'react';
import Consts from '../../../app/Consts';
import {useAppSelector} from '../../../app/store';
import {selectConfigsData} from '../../../app/selectors';
import SPIVForm from '../../../components/SPIVForm';
import {InitialSPIVFormValues} from '../../../types';

export const createSPIVInitialValues: InitialSPIVFormValues = {
  id: undefined,
  accrualTaxAmount: undefined,
  amount: undefined,
  claimDaysAfterExpiry: undefined,
  claimInterval: undefined,
  claimVendorActive: false,
  claimVendorCode: undefined,
  claimVendorName: undefined,
  claimVendorSuppliers: [],
  comment: undefined,
  departmentDescription: undefined,
  departmentNumber: undefined,
  description: '',
  draftMode: true,
  endAt: undefined,
  excludeReturns: true,
  gstType: undefined,
  hasActiveClaim: false,
  hasRaisedClaim: false,
  isDeleted: false,
  isFinalClaimRaised: false,
  locationCriteria: null,
  spivValues: {
    data: [],
    isClaimVendorGstFree: false,
    suppliers: [],
    ...Consts.DefaultPagination,
  },
  spivValueCount: undefined,
  startAt: undefined,
  status: undefined,
  supplierApprovalNumber: null,
  suppliers: [],
  DealCutoffDate: null,
  DealLockDate: null,
};

const CreateSPIV = () => {
  // configs are added to form values so they can be accessed as this.parent in the validation schema
  const configs = useAppSelector(selectConfigsData);

  const DealCutoffDate = configs
    ? configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
    : null;
  const DealLockDate = configs
    ? configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
    : null;
  return (
    <SPIVForm
      title="Add SPIV"
      initialValues={{...createSPIVInitialValues, DealCutoffDate, DealLockDate}}
    />
  );
};

export default CreateSPIV;
