import FieldLabel from '../../../components/Form/FieldLabel';
import {styled} from '@mui/material/styles';

const AccountFieldLabel = styled(FieldLabel)`
font-style: italic;
font-weight: normal;
margin-top: 10px;
}
`;

export default AccountFieldLabel;
