import React, {FC} from 'react';
import Button, {ButtonProps} from '@mui/material/Button';

type Props = {
  children: React.ReactNode;
} & ButtonProps;

const OutlinedButton: FC<Props> = ({children, ...props}) => (
  <Button
    variant="outlined"
    sx={{
      borderRadius: 'unset',
      borderColor: 'black',
      fontSize: '1rem',
      backgroundColor: '#ffffff',
    }}
    {...props}
  >
    {children}
  </Button>
);

export default OutlinedButton;
