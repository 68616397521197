import React from 'react';
import Box from '@mui/material/Box';
import Consts from '../../app/Consts';

const HeaderWithGst = ({header, gstType}) => {
  return (
    <div>
      {header}
      {gstType ? (
        <Box sx={{whiteSpace: 'nowrap'}}>
          {gstType === Consts.GstTypeEnum.Free
            ? 'GST free'
            : Consts.GstTypeEnum.Inclusive === gstType
            ? 'GST inc.'
            : 'GST ex.'}
        </Box>
      ) : null}
    </div>
  );
};

export default HeaderWithGst;
