import {useMemo, useState} from 'react';
import {AppConfigurationClient, GetConfigurationSettingResponse} from '@azure/app-configuration';

const client = new AppConfigurationClient(
  process.env.REACT_APP_APP_CONFIG_CONNECTION_STRING ?? "Endpoint='';Id='';Secret=''"
);
const APP_CONFIG_FEATURE_FLAG_PREFIX = '.appconfig.featureflag/FOH.Rebates.';
/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} flagKey App Config Feature Flag key
 * @returns the feature flag for the specified key
 */
export const useFeatureFlag = (flagKey: string) => {
  const [enabled, setEnabled] = useState(false);
  const rebatesEnv = process.env.REACT_APP_REBATES_ENV?.toUpperCase();
  const label = rebatesEnv === 'PROD' ? undefined : rebatesEnv;

  useMemo(async () => {
    if (flagKey && flagKey.toString().trim().length) {
      try {
        const result: GetConfigurationSettingResponse = await client.getConfigurationSetting({
          key: `${APP_CONFIG_FEATURE_FLAG_PREFIX}${flagKey.toString().trim()}`,
          ...(label ? {label} : {}),
        });
        if (result) {
          setEnabled(JSON.parse(result.value ?? '')?.enabled ?? false);
        }
      } catch (error) {
        setEnabled(false);
      }
    }
  }, [flagKey, label]);

  return {enabled};
};
