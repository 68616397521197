import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import {isNegative} from '../../utils/AmountUtils';

const PREFIX = 'TableCellInputField';

const classes = {
  root: `${PREFIX}-root`,
  inputBase: `${PREFIX}-inputBase`,
  disabled: `${PREFIX}-disabled`,
  input: `${PREFIX}-input`,
  negativeInput: `${PREFIX}-negativeInput`,
  notchedOutline: `${PREFIX}-notchedOutline`,
  unitInLeft: `${PREFIX}-unitInLeft`,
  warningHelperText: `${PREFIX}-warningHelperText`,
};

const StyledTextField = styled(TextField)(({theme}) => ({
  [`&.${classes.root}`]: {},

  '& .MuiFormHelperText-root': {
    width: '100%',
  },

  [`& .${classes.inputBase}`]: {
    borderRadius: 'unset',
    fontSize: '0.9375rem',
  },

  [`& .${classes.disabled}`]: {
    borderColor: theme.palette.sectionDivider.main,
    '&& $notchedOutline': {
      borderColor: theme.palette.sectionDivider.main,
    },
  },

  [`& .${classes.input}`]: {
    color: 'initial',
    height: '1rem',
  },

  [`& .${classes.negativeInput}`]: {
    height: '1rem',
    color: theme.palette.red.main,
  },

  [`& .${classes.notchedOutline}`]: {
    borderColor: (props: {warning: boolean}) =>
      props.warning ? theme.palette.orange.main : theme.palette.gray.lightMid,
  },

  [`& .${classes.unitInLeft}`]: {
    marginLeft: '-1px',
  },

  [`& .${classes.warningHelperText}`]: {
    color: theme.palette.orange.main,
  },
}));

type Props = Partial<TextFieldProps> & {
  type?: string;
  readOnly?: boolean;
  onChanged?: (value: string) => void;
  error?: boolean;
  warning?: boolean;
  helperText?: React.ReactNode;
  digits?: number;
  unitInLeft?: boolean;
  showNegativeColor?: boolean;
  regexValidation?: RegExp;
  sx?: React.CSSProperties;
};

const TableCellInputField: FC<Props> = ({
  type = 'text',
  readOnly = false,
  onChanged,
  error,
  warning,
  helperText,
  digits = 2,
  unitInLeft = false,
  showNegativeColor = false,
  regexValidation,
  sx = {},
  onChange,
  InputProps,
  ...props
}) => {
  const muiInputProps = {
    InputProps: {
      ...(InputProps ? InputProps : {}),
      classes: {
        root: classes.inputBase,
        input: isNegative(props.value) && showNegativeColor ? classes.negativeInput : classes.input,
        disabled: classes.disabled,
        notchedOutline: `${classes.notchedOutline} ${unitInLeft && classes.unitInLeft}`,
      },
    },
    FormHelperTextProps: {
      classes: {
        root: warning ? classes.warningHelperText : '',
      },
    },
    inputProps: {
      readOnly,
    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (regexValidation) {
      if (regexValidation.test(event.target.value)) {
        onChange?.(event);
        onChanged?.(event.target.value);
      }
    } else {
      onChange?.(event);
      onChanged?.(event.target.value);
    }
  };

  return (
    <StyledTextField
      variant="outlined"
      type={type}
      error={error}
      disabled={readOnly}
      helperText={helperText}
      {...props}
      onChange={handleChange}
      classes={{root: classes.root}}
      {...muiInputProps}
      sx={{...(readOnly ? {backgroundColor: '#F2F4F5'} : sx)}}
    />
  );
};

export default TableCellInputField;
