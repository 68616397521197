import {useMemo} from 'react';
import {useFormikContext} from 'formik';
import {getTouchedErrors} from '../../utils';

const FormErrorScroller = () => {
  const {errors, touched, isValidating} = useFormikContext();
  const touchedErrors = useMemo(
    () => (isValidating ? getTouchedErrors(errors, touched) : []),
    [errors, touched, isValidating]
  );
  if (isValidating) {
    if (touchedErrors.length > 0) {
      const firstErrorField = touchedErrors[0];
      const namedFieldElement = Array.from(
        document.querySelectorAll(`[name="${firstErrorField}"]`)
      ).find((element) => element.tagName.toLowerCase() !== 'meta');
      if (namedFieldElement) {
        namedFieldElement.scrollIntoView?.({
          inline: 'center',
          block: 'start',
          behavior: 'smooth',
        });
      } else {
        const idFieldElement = document.getElementById(firstErrorField);
        if (idFieldElement) {
          idFieldElement.scrollIntoView?.({inline: 'center', block: 'start', behavior: 'smooth'});
        }
      }
    }
  }
  return null;
};

export default FormErrorScroller;
