import React, {FC} from 'react';
import Tooltip, {TooltipProps} from '@mui/material/Tooltip';

type Props = TooltipProps & {
  title: string;
  children: React.ReactNode;
};

const TabTooltip: FC<Props> = ({title, children, ...tooltipProps}) => (
  <Tooltip title={title} {...tooltipProps}>
    <span>{children}</span>
  </Tooltip>
);

export default TabTooltip;
