import React, {FC} from 'react';
import {styled} from '@mui/material/styles';

const CardContainer = styled('div')`
  background: #f7f9fa 0% 0% no-repeat padding-box;
  border: 1px solid #d6d6d6;
  border-radius: 1.25rem;
  display: flex;
  padding: 2.5rem 2.5rem 1.25rem 2.5rem;
  margin-bottom: 1.875rem;
  cursor: pointer;
  &:hover {
    background-color: #f2f4f5;
  }
  > svg {
    width: 7.5rem;
  }
`;

const CardDescription = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2.5rem;
  text-align: left;
`;

const CardDetail = styled('p')`
  color: #626262;
  line-height: 1.625rem;
  margin-top: 0.625rem;
`;

const CardDescHeader = styled('div')`
  font-size: 1.375rem;
  font-weight: 500;
`;

const disabledStyle = {
  opacity: 0.6,
  cursor: 'not-allowed',
};

type Props = {
  icon: React.ElementType;
  onClick: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
};

const SelectionCard: FC<Props> = ({icon: Component, onClick, title, children, disabled}) => (
  <CardContainer onClick={onClick} style={disabled ? disabledStyle : {}} role="button">
    <Component />
    <CardDescription>
      <CardDescHeader>{title}</CardDescHeader>
      <CardDetail>{children}</CardDetail>
    </CardDescription>
  </CardContainer>
);

export default SelectionCard;
