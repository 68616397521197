import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Order, Pagination} from '../types';
import Consts from './Consts';
import {ClaimVendor} from '../components/Form/Agolia/VendorSearch';
import {FinanceAccount} from './financialAccountsReducer';
import {Owner} from './ownersReducer';

export interface RebateListingFilter {
  claimFrequency: any;
  claimVendor: ClaimVendor | null;
  endAt: string | null;
  financeAccount: FinanceAccount | null;
  owner: Owner | null;
  startAt: string | null;
  status: string | null;
}

export interface RebatesState {
  searchText: string | null;
  order: Order;
  pagination: Pagination;
  filterSelection: RebateListingFilter;
}

export const initialState: RebatesState = {
  searchText: null,
  order: {
    orderBy: 'status',
    orderByDirection: 'asc',
  },
  filterSelection: {
    claimVendor: null,
    financeAccount: null,
    claimFrequency: null,
    startAt: null,
    endAt: null,
    owner: null,
    status: null,
  },
  pagination: {
    totalCount: Consts.DefaultPagination.totalCount,
    totalPages: Consts.DefaultPagination.totalPages,
    pageSize: Consts.DefaultPagination.pageSize,
    currentPage: Consts.DefaultPagination.currentPage,
  },
};

const sliceName = 'rebates';

const rebates = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setRebatesSearch(state, action: PayloadAction<string | null>) {
      state.searchText = action.payload;
    },
    setRebatesFilter(state, action: PayloadAction<RebateListingFilter>) {
      state.filterSelection = action.payload;
    },
    setRebatesOwnerFilter(state, action: PayloadAction<Owner | null>) {
      state.filterSelection = {...state.filterSelection, owner: action.payload};
    },
    setRebatesPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setRebatesOrder(state, action: PayloadAction<Order>) {
      state.order = action.payload;
    },
  },
});

const rebatesReducer = rebates.reducer;
export const {
  setRebatesSearch,
  setRebatesFilter,
  setRebatesOwnerFilter,
  setRebatesPagination,
  setRebatesOrder,
} = rebates.actions;
export default rebatesReducer;
