import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const StepDivider = styled(Divider)`
  margin-top: 26px;
  margin-bottom: 56px;
  height: 1.5px;
  color: ${({theme}) => theme.palette.sectionDivider.main};
`;

export default StepDivider;
