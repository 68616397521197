import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Button, OutlinedButton} from '../Button';

const StyledContent = styled('div')`
  text-align: center;
  margin: auto;
  background: #ffffff padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  width: 700px;
  height: fit-content;
  padding: 80px 70px 70px 70px;
  &:focus {
    outline: none;
  }
`;
const SingleActionButtonWidth = '236px';

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  overflow: scroll;
`;

const Title = styled('div')`
  font-size: 32px;
  font-weight: 500;
`;
const Subtitle = styled('div')`
  margin-top: 17px;
  font-size: 16px;
  line-height: 26px;
`;
const TitleContainer = styled('div')`
  flex: 1;
  padding-left: 24px;
`;

const ModalTitleSection = styled('div')`
  margin-bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

type BaseModalProps = {
  open: boolean;
  title?: React.ReactNode;
  onClose?: () => void;
  renderSubtitle?: () => React.ReactNode;
  children: React.ReactNode;
};

type ConfirmModalProps = {
  onOk: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  okDisabled?: boolean;
  styles?: {[key: string]: React.CSSProperties};
} & BaseModalProps;

type ConfirmModal2Props = {
  onOk1?: () => void;
  onOk2?: () => void;
  ok1Text?: string;
  ok2Text?: string;
} & ConfirmModalProps;

const CustomModal: FC<BaseModalProps> = ({open, title, renderSubtitle, onClose, children}) => (
  <StyledModal open={open} onClose={onClose}>
    <StyledContent>
      <ModalTitleSection>
        <TitleContainer>
          <Title>{title}</Title>
          {renderSubtitle ? <Subtitle>{renderSubtitle()}</Subtitle> : null}
        </TitleContainer>
        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </ModalTitleSection>
      {children}
    </StyledContent>
  </StyledModal>
);

const ContentSection = styled('div')`
  font-size: 20px;
  line-height: 30px;
`;
const ActionSection = styled('div')`
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
`;

const CancelButton = styled(OutlinedButton)`
  height: 70px;
  font-size: 18px;
  padding: 5px 50px;
  font-weight: 500;
  margin-left: 0;
`;
const OkButton = styled(Button)`
  padding: 5px 50px;
  font-weight: 500;
  font-size: 18px;
`;

const ConfirmModalTitleSection = styled('div')`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const ConfirmModal: FC<ConfirmModalProps> = ({
  open,
  title = 'Are you sure?',
  renderSubtitle,
  onOk,
  onCancel,
  okText = 'Ok',
  cancelText = 'Cancel',
  children,
  okDisabled = false,
  styles,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  return (
    <StyledModal open={open} onClose={onCancel || onOk} onClick={handleClick}>
      <StyledContent sx={{...styles?.root}}>
        <ConfirmModalTitleSection>
          <TitleContainer>
            <Title>{title}</Title>
            {renderSubtitle ? <Subtitle>{renderSubtitle()}</Subtitle> : null}
          </TitleContainer>
          <IconButton onClick={onCancel || onOk} size="large">
            <CloseIcon />
          </IconButton>
        </ConfirmModalTitleSection>
        <ContentSection sx={{...styles?.content}}>{children}</ContentSection>
        <ActionSection
          sx={{
            ...styles?.actionSection,
          }}
        >
          {onCancel ? (
            <CancelButton
              sx={{...styles?.cancelButton, ...{width: !onOk ? SingleActionButtonWidth : 'auto'}}}
              disableRipple
              onClick={onCancel}
            >
              {cancelText}
            </CancelButton>
          ) : null}
          {onOk ? (
            <OkButton
              sx={{...styles?.okButton, ...{width: !onCancel ? SingleActionButtonWidth : 'auto'}}}
              onClick={onOk}
              disabled={okDisabled}
            >
              {okText}
            </OkButton>
          ) : null}
        </ActionSection>
      </StyledContent>
    </StyledModal>
  );
};

const ConfirmModal2: FC<ConfirmModal2Props> = ({
  open,
  title = 'Are you sure?',
  renderSubtitle,
  onOk1,
  onOk2,
  onCancel,
  ok1Text = 'Ok',
  ok2Text = 'Ok',
  cancelText = 'Cancel',
  children,
  styles,
}) => (
  <StyledModal open={open} onClose={onCancel || onOk1 || onOk2}>
    <StyledContent sx={{...styles?.root}}>
      <ConfirmModalTitleSection>
        <TitleContainer>
          <Title>{title}</Title>
          {renderSubtitle && <Subtitle>{renderSubtitle()}</Subtitle>}
        </TitleContainer>
        <IconButton onClick={onCancel || onOk1 || onOk2} size="large">
          <CloseIcon />
        </IconButton>
      </ConfirmModalTitleSection>
      <ContentSection sx={{...styles?.content}}>{children}</ContentSection>
      <ActionSection sx={{...styles?.actionSection}}>
        {onCancel ? (
          <CancelButton sx={{...styles?.cancelButton}} disableRipple={true} onClick={onCancel}>
            {cancelText}
          </CancelButton>
        ) : null}
        {onOk1 ? (
          <OkButton sx={{...styles?.ok1Button}} onClick={onOk1}>
            {ok1Text}
          </OkButton>
        ) : null}
        {onOk2 ? (
          <OkButton
            sx={{
              ...styles?.ok2Button,
            }}
            onClick={onOk2}
          >
            {ok2Text}
          </OkButton>
        ) : null}
      </ActionSection>
    </StyledContent>
  </StyledModal>
);

export default CustomModal;
export {ConfirmModal, ConfirmModal2};
