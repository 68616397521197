import Consts from '../app/Consts';
import {getDisplayAmountValue} from './AmountUtils';

type RebateType = {label: string; value: string};
type GstType = {label: string; value: string};
type WritebackType = {label: string; value: string};

export const getDealType = (type?: string): string | undefined =>
  Consts.StandardDealTypes.find((x) => x.value === type)?.label;

export const getRebateType = (type?: string): string | undefined =>
  Consts.RebateTypes.find((rebateType: RebateType) => rebateType.value === type)?.label;

export const getGSTType = (type?: string): string | undefined =>
  Consts.GstType.find((gstType: GstType) => gstType.value === type)?.label;

export const getWritebackType = (type: string | null): string | undefined =>
  Consts.WritebackType.find((writebackType: WritebackType) => writebackType.value === type)?.label;

export const getDealSubType = (type: string) => (type === 'Spiv' ? 'SPIV' : 'Mix & Match');

export const getDisplayAmount = (type: string | undefined, amount: number): string => {
  if (type === Consts.AmountTypeEnum.FixedAmount) {
    return getDisplayAmountValue(amount, '$');
  }
  if (type === Consts.AmountTypeEnum.ValuePerUnit) {
    return getDisplayAmountValue(amount, '$', ' per unit');
  }
  return getDisplayAmountValue(amount, '', '%');
};
