import React, {Component, ReactNode} from 'react';
import Stack from '@mui/material/Stack';

interface ErrorBoundaryProps {
  message?: string;
  children?: ReactNode;
}

interface StateProps {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, StateProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(): StateProps {
    return {hasError: true};
  }

  render() {
    const {hasError} = this.state;
    const {children} = this.props;
    if (hasError) {
      return (
        <Stack
          direction="column"
          spacing={4}
          sx={{
            justifyContent: 'center',
            width: '100vw',
            alignItems: 'center',
            height: 'calc(100vh - 120px)',
          }}
        >
          <h4>Something has gone wrong.</h4>
          <p>
            Please relaunch the app. If the problem persists, please contact IT for more support.
          </p>
        </Stack>
      );
    }
    return children ?? null;
  }
}

export default ErrorBoundary;
