import React, {FC, useContext, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router';
import {AxiosResponse} from 'axios';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import getClaimFrequency from '../../utils/ClaimFrequencyUtils';
import {formatDate} from '../../utils/DateUtils';
import {api, get} from '../../utils/Request';
import {getDealType, getRebateType} from '../../utils/TypesStatusUtils';
import {
  TableColumn,
  EntityActionType,
  Pagination,
  ClaimListResponse,
  ClaimListItemViewModel,
} from '../../types';
import {Amount} from '../Amount';
import TriangleWarnIcon from '../Icons/TriangleWarnIcon';
import {SimpleDataTable} from '../SimpleDataTable';
import {ClaimStatus} from '../Status';
import {ConfirmModal} from '.';

const styles = {
  okButton: {
    marginLeft: '1.25rem',
    width: '12.5rem',
  },
  cancelButton: {
    width: '12.5rem',
  },
  root: {
    width: 'auto',
  },
  content: {
    fontSize: 'initial',
    lineHeight: 'normal',
    marginTop: '3.125rem',
  },
  actionSection: {
    justifyContent: 'center',
  },
};

type Props = {
  entityActionType: EntityActionType;
  referenceId: string;
  ctrlKey: boolean;
  claimsResponse: ClaimListResponse | null;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  modalTitle?: string;
  modalSubtitle?: string;
  okText?: string;
  cancelText?: string;
};

const EditAgreementOrRebateConfirmModal: FC<Props> = ({
  entityActionType,
  referenceId,
  ctrlKey,
  claimsResponse,
  open,
  onOk,
  onCancel,
  modalTitle,
  modalSubtitle,
  okText,
  cancelText,
}) => {
  const navigate = useNavigate();
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const [claimPagination, setClaimPagination] = useState({
    ...Consts.DefaultPagination,
    pageSize: Consts.PageSize[0],
  });
  const [raisedClaims, setRaisedClaims] = useState<ClaimListItemViewModel[]>([]);

  const hideRaisedOn = !claimsResponse?.data?.some(
    (claim) => claim.status === Consts.ClaimStatusEnum.Raised
  );

  const getPagination = (claimsResponse: ClaimListResponse | null) => {
    return claimsResponse
      ? {
          pageSize: claimsResponse.pageSize,
          totalCount: claimsResponse.totalCount,
          totalPages: claimsResponse.totalPages,
          currentPage: claimsResponse.currentPage,
        }
      : {
          ...Consts.DefaultPagination,
          pageSize: Consts.PageSize[0],
        };
  };

  useEffect(() => {
    setRaisedClaims(claimsResponse ? claimsResponse.data || [] : []);
    setClaimPagination(getPagination(claimsResponse));
  }, [claimsResponse]);

  const getColumns = () => {
    const columns: TableColumn<ClaimListItemViewModel>[] = [
      {id: 'claimVendorName', label: 'Claim Vendor', minWidth: 80},
      {id: 'description', label: 'Rebate / Deal Description', minWidth: 150},
      {id: 'ownedByStaffName', label: 'Owner', minWidth: 80},
      {
        id: 'type',
        label: 'Type',
        render: (rowData) =>
          rowData.agreementType === Consts.AgreementTypeEnum.Deal
            ? `Deal: ${getDealType(rowData.rebateType)}`
            : `Rebate: ${getRebateType(rowData.rebateType)}`,
      },
      {id: 'id', label: 'ID'},
      {
        id: 'startAt',
        label: 'Claim Period Start Date',
        minWidth: 80,
        render: (rowData) => formatDate(rowData.startAt),
      },
      {
        id: 'endAt',
        label: 'Claim Period End Date',
        minWidth: 80,
        render: (rowData) => formatDate(rowData.endAt),
      },
      {
        id: 'claimFrequency',
        label: 'Claim Frequency',
        minWidth: 120,
        render: (rowData) =>
          getClaimFrequency(
            rowData.agreementType,
            rowData.claimFrequency,
            rowData.claimDaysAfterExpiry
          ),
      },
      {
        id: 'claimAmount',
        label: 'Claim Amount',
        render: (rowData) => <Amount value={rowData.totalClaimAmount} />,
      },
      {
        id: 'lastModifiedAt',
        label: 'Raised On',
        minWidth: 80,
        render: (rowData) => formatDate(rowData.lastModifiedAt),
        hide: hideRaisedOn,
      },
      {
        id: 'status',
        label: 'Status',
        render: (rowData) => <ClaimStatus status={rowData.status} />,
      },
    ];
    return columns.filter((x) => !x.hide);
  };

  function onChangePage({currentPage}: Pick<Pagination, 'currentPage'>) {
    onPagination(currentPage, claimPagination.pageSize);
  }
  function onChangePageSize(pagination: Pick<Pagination, 'pageSize'>) {
    onPagination(1, pagination.pageSize);
  }
  const getClaimsApiRoute = () => {
    switch (entityActionType) {
      case EntityActionType.Deal:
      case EntityActionType.SPIV:
        return Consts.Api.DealAgreementClaims.replace(':id', referenceId);
      case EntityActionType.ContractAgreement:
        return Consts.Api.ContractAgreementClaims.replace(':id', referenceId);
      default:
        return Consts.Api.RebateClaims.replace(':id', referenceId);
    }
  };
  const getEditRoute = () => {
    switch (entityActionType) {
      case EntityActionType.Deal:
        return Consts.RouterPath.EditDeal.replace(':id', referenceId);
      case EntityActionType.ContractAgreement:
        return Consts.RouterPath.EditContractAgreement.replace(':id', referenceId);
      case EntityActionType.SPIV:
        return Consts.RouterPath.EditSpiv.replace(':id', referenceId);
      default:
        return Consts.RouterPath.EditRebate.replace(':id', referenceId);
    }
  };
  const onPagination = (
    currentPage: Pagination['currentPage'],
    pageSize: Pagination['pageSize']
  ) => {
    showLoading();
    const newPagination = {...claimPagination, currentPage, pageSize};
    const apiRoute = getClaimsApiRoute();
    get(api(apiRoute), {
      params: {
        status: Consts.ClaimStatusEnum.Raised,
        ...newPagination,
      },
    })
      .then((response: AxiosResponse<ClaimListResponse>) => {
        const data = response.data;
        hideLoading();
        setRaisedClaims(data.data);
        setClaimPagination(getPagination(data));
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const handleOk = () => {
    onOk();
    const routerPath = getEditRoute();
    if (ctrlKey) {
      window.open(routerPath, '_blank');
    } else {
      navigate(routerPath);
    }
  };
  return (
    <ConfirmModal
      open={open}
      onOk={handleOk}
      onCancel={onCancel}
      okText={okText ?? 'Proceed'}
      cancelText={cancelText ?? 'Go back'}
      styles={styles}
      title={
        <Grid container sx={{justifyContent: 'center', align: 'center'}}>
          <TriangleWarnIcon sx={{fontSize: '2.25rem'}} />
          {modalTitle ?? 'Claim already raised'}
        </Grid>
      }
      renderSubtitle={() =>
        modalSubtitle ??
        `There has been at least one claim raised against this ${entityActionType}, some fields will not be editable.`
      }
    >
      <div>
        <SimpleDataTable
          columns={getColumns()}
          rows={raisedClaims}
          highlightColumn={null}
          pagination={claimPagination}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
      </div>
    </ConfirmModal>
  );
};

export default EditAgreementOrRebateConfirmModal;
