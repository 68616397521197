import React, {FC} from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {ConfirmModal} from '../Modal';

const styles = {
  root: {
    width: '48rem',
  },
  okButton: {
    marginLeft: '1.25rem',
    width: '12.5rem',
    fontSize: '1.75rem',
    fontWeight: 'bold',
  },
  actionSection: {
    justifyContent: 'center',
  },
};

const SuccessTickIcon = styled(CheckCircleRoundedIcon)(({theme}) => ({
  '&.MuiSvgIcon-root': {
    color: theme.palette.green.main,
    fontSize: '3rem',
  },
}));

const MessageContent = styled('div')`
  display: grid;
  flex-direction: column;
  gap: 3rem;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 3rem 0;
`;

type Props = {
  count: number;
  open: boolean;
  onOk: () => void;
};

const BulkUploadCompleteModal: FC<Props> = ({count, open, onOk}) => {
  const subtitle = () => 'Excel file has been successfully uploaded';
  const countText = `${count} line${count === 1 ? '' : 's'} uploaded`;
  return (
    <ConfirmModal
      title="Upload Successful"
      renderSubtitle={subtitle}
      open={open}
      onOk={onOk}
      okText="OK"
      styles={styles}
    >
      <MessageContent>
        <Divider />
        <Box sx={{display: 'flex', gap: '1rem', flexDirection: 'column', alignItems: 'flex-start'}}>
          <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <SuccessTickIcon />
            <Box sx={{fontSize: '1.5rem', fontWeight: '700'}}>{countText}</Box>
          </Box>
          <Box
            sx={{
              fontSize: '0.875rem',
              fontWeight: '500',
              lineHeight: 0,
              marginLeft: '4rem',
              textWrap: 'nowrap',
              marginBottom: '-1rem',
            }}
          >
            You’ll receive a success/failure email for any claims updated to Invoiced status. This
            can take up to 10 minutes
          </Box>
        </Box>
        <Divider />
      </MessageContent>
    </ConfirmModal>
  );
};

export default BulkUploadCompleteModal;
