import React from 'react';
import Consts from '../../app/Consts';
import Link from './Link';

const ITSupportLink = (props) => {
  return (
    <Link href={`mailto:${Consts.ITSupportEmail}`} {...props}>
      {Consts.ITSupportEmail}
    </Link>
  );
};

export default ITSupportLink;
