import React, {FC} from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {ButtonProps} from '@mui/material/Button';
import {StaticButton} from '.';

type Props = {
  children: React.ReactNode;
} & ButtonProps;

const ExpandButton: FC<Props> = ({children, ...otherProps}) => (
  <StaticButton startIcon={<AddCircleOutlineIcon />} {...otherProps}>
    {children}
  </StaticButton>
);

export default ExpandButton;
