import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import ExpandButton from '../../Button/ExpandButton';
import LinkButton from '../../Button/LinkButton';

const PREFIX = 'CriteriaSection';

const classes = {
  hidden: `${PREFIX}-hidden`,
};

const Root = styled('div')(() => ({
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
}));

const CriteriaContainer = styled('div')`
  background-color: #f2f4f5;
  padding: 30px;
`;

const Title = styled('span')`
  font-size: 18px;
  font-weight: 700;
`;
const TitleContainer = styled('div')`
  margin-bottom: 10px;
`;

const CriteriaSection = ({title, children, readOnly = false}) => {
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen((isOpen) => !isOpen);
  };

  return (
    <Root>
      {!readOnly && (
        <ExpandButton onClick={handleButtonClick} className={open ? classes.hidden : ''}>
          {title}
        </ExpandButton>
      )}
      <CriteriaContainer className={open ? '' : classes.hidden}>
        <TitleContainer>
          <Title>{title}</Title>
          <LinkButton type="button" onClick={handleButtonClick}>
            Close
          </LinkButton>
        </TitleContainer>
        {children}
      </CriteriaContainer>
    </Root>
  );
};

export default CriteriaSection;
