import React from 'react';
import {styled} from '@mui/material/styles';
import Consts from '../../app/Consts';

const PREFIX = 'ClaimStatus';

const classes = {
  root: `${PREFIX}-root`,
  [Consts.ClaimStatusEnum.Finalised]: `${PREFIX}-${Consts.ClaimStatusEnum.Finalised}`,
  [Consts.ClaimStatusEnum.ReadyToSend]: `${PREFIX}-${Consts.ClaimStatusEnum.ReadyToSend}`,
  [Consts.ClaimStatusEnum.SentToSupplier]: `${PREFIX}-${Consts.ClaimStatusEnum.SentToSupplier}`,
  [Consts.ClaimStatusEnum.Raised]: `${PREFIX}-${Consts.ClaimStatusEnum.Raised}`,
  [Consts.ClaimStatusEnum.NoClaimRaised]: `${PREFIX}-${Consts.ClaimStatusEnum.NoClaimRaised}`,
  [Consts.ClaimStatusEnum.ReadyToProcess]: `${PREFIX}-${Consts.ClaimStatusEnum.ReadyToProcess}`,
  [Consts.ClaimStatusEnum.Invoiced]: `${PREFIX}-${Consts.ClaimStatusEnum.Invoiced}`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    fontSize: '0.875rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.3125rem',
    textAlign: 'center',
    minHeight: '2.5rem',
    userSelect: 'none',
  },
  [`&.${classes[Consts.ClaimStatusEnum.Finalised]}`]: {
    color: theme.palette.red.main,
    backgroundColor: theme.palette.red.tint,
  },
  [`&.${classes[Consts.ClaimStatusEnum.ReadyToSend]}`]: {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.blue.light,
  },
  [`&.${classes[Consts.ClaimStatusEnum.SentToSupplier]}`]: {
    color: theme.palette.orange.main,
    backgroundColor: theme.palette.orange.tint,
  },
  [`&.${classes[Consts.ClaimStatusEnum.Raised]}`]: {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.gray.lightMid,
  },
  [`&.${classes[Consts.ClaimStatusEnum.NoClaimRaised]}`]: {
    color: theme.palette.gray.dark,
    backgroundColor: theme.palette.gray.light,
  },
  [`&.${classes[Consts.ClaimStatusEnum.ReadyToProcess]}`]: {
    color: theme.palette.green.main,
    backgroundColor: theme.palette.green.tint,
  },
  [`&.${classes[Consts.ClaimStatusEnum.Invoiced]}`]: {
    color: theme.palette.black.main,
    backgroundColor: 'rgba(240, 221, 0, 0.3)',
  },
}));

const ClaimStatus = ({status}) => {
  return (
    <Root className={`${classes.root} ${classes.status} ${classes[status]}`}>
      {Consts.ClaimStatus[status]}
    </Root>
  );
};

export default ClaimStatus;
