import React, {FC} from 'react';
import {useField, useFormikContext} from 'formik';
import * as Yup from 'yup';
import isValid from 'date-fns/isValid';
import {DateType} from '../../utils/DateUtils';
import DatePicker from './DatePicker';

type Props = {
  dateType?: string;
  id: string;
  name: string;
  placeholder: string;
  fullWidth?: boolean;
  minDate?: string;
  maxDate?: string;
  validationSchema?: any | ((props: {startAt?: MUIParsableDate; endAt?: MUIParsableDate}) => any);
  disabled?: boolean;
  onChange?: (date: MUIParsableDate) => void;
};

const DatePickerField: FC<Props> = ({
  dateType,
  fullWidth,
  name,
  placeholder,
  minDate,
  maxDate,
  validationSchema,
  disabled,
  onChange,
  ...other
}) => {
  type FormType = Yup.InferType<typeof validationSchema>;
  const {values} = useFormikContext<FormType>();
  const [field, meta, helpers] = useField({name, ...other});
  const {setValue} = helpers;

  function _renderHelperText() {
    const {touched, error} = meta;
    if (touched && error) {
      return error;
    }
  }

  const validateDate = async (date: MUIParsableDate) => {
    const dateValue = new Date(date as Date);
    const timeValue = name === 'startAt' ? values.startTime : values.endTime;
    if (isValid(new Date(timeValue))) {
      dateValue.setHours(Number(new Date(timeValue).getHours()));
      dateValue.setMinutes(Number(new Date(timeValue).getMinutes()));
    }
    // resets form value if validation exists and fails
    try {
      await validationSchema?.validate({[name]: dateValue});
    } catch (error) {
      try {
        await validationSchema?.validate({[name]: meta.initialValue});
        setValue(meta.initialValue);
      } catch (error) {
        setValue(null);
      }
    }
  };

  const handleAccept = async (date: MUIParsableDate) => {
    if (!isValid(date)) {
      return;
    }
    const dateValue = new Date(date as Date);
    await validateDate(dateValue);
  };

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (!validationSchema) {
      field.onBlur(event);
    } else {
      const [day, month, year] = event.target.value.split('/');
      const dateValue = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      await validateDate(dateValue);
    }
  };

  const handleOnChanged = (date: MUIParsableDate) => {
    setValue(date);
    onChange?.(date);
  };

  return (
    <DatePicker
      dateType={dateType ?? DateType.Default}
      placeholder={placeholder}
      value={field.value}
      onChanged={handleOnChanged}
      showError={Boolean(meta.touched && meta.error)}
      errorMessage={_renderHelperText()}
      minDate={minDate}
      maxDate={maxDate}
      onAccept={handleAccept}
      onBlur={handleBlur}
      fullWidth={fullWidth}
      disabled={disabled}
      {...other}
    />
  );
};

export default DatePickerField;
