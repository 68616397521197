import React, {FC, useState, useEffect, useCallback, useContext} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {getDisplayCommasValue, numberWithCommas, getDisplayAmountValue} from '../../../utils';
import {api, get} from '../../../utils/Request';
import {
  SPIVValueForSPIVSummaryResponse,
  SpivValuesForSpivSummaryResponse,
  Pagination,
  AtLeastOne,
} from '../../../types';
import PageNavigator from '../../../components/Table/Pagination/PageNavigator';
import PageCounter from '../../../components/Table/Pagination/PageCounter';
import PageSetting from '../../../components/Table/Pagination/PageSetting';
import SectionContainer from '../../../components/Table/Pagination/SectionContainer';

const PREFIX = 'SPIVSummaryValuesTable';

const classes = {
  tableHeading: `${PREFIX}-tableHeading`,
  tableHeadingCell: `${PREFIX}-tableHeadingCell`,
  tableCell: `${PREFIX}-tableCell`,
  tableFirstRow: `${PREFIX}-tableFirstRow`,
  tableRow: `${PREFIX}-tableRow`,
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableHeading}`]: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
  },
  [`& .${classes.tableHeadingCell}`]: {
    color: '#FFFFFF',
  },
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.tableFirstRow}`]: {
    paddingLeft: '1.875rem',
  },
  [`& .${classes.tableRow}`]: {
    boxShadow: '0px 3px 6px #00000029',
  },
});

const CellContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;

const TablePaginationContainer = styled(SectionContainer)`
  justify-content: space-between;
  height: 3.75rem;
  padding: 1.25rem 1.125rem 0;
  background-color: #f2f4f5;

  input,
  .MuiSelect-select {
    background-color: #ffffff;
  }
`;

type CustomTableColumn = {
  field: string;
  title: string | React.ReactNode;
  render?: (rowData: SPIVValueForSPIVSummaryResponse, rowIndex: number) => React.ReactNode;
  hide?: boolean;
};

type Props = {
  spivValues: SpivValuesForSpivSummaryResponse;
  spivId: number;
  hideColumns: AtLeastOne<SPIVValueForSPIVSummaryResponse, boolean> | undefined;
};

const SPIVSummaryValuesTable: FC<Props> = ({spivValues, spivId, hideColumns}) => {
  const [data, setData] = useState<SPIVValueForSPIVSummaryResponse[]>([]);
  const [pagination, setPagination] = useState<Pagination>(Consts.DefaultPagination);
  const {showLoading, hideLoading} = useContext(LoadingContext);

  useEffect(() => {
    const {data, dealType, ...pagination} = spivValues;
    setData(spivValues.data);
    setPagination(pagination);
  }, [spivValues]);

  const getColumns = () => {
    const columns: CustomTableColumn[] = [
      {
        field: 'id',
        title: 'ID',
      },
      {
        field: 'amount',
        title: 'SPIV Claim Amount',
        render: renderAmountColumn,
      },
      {
        field: 'gstType',
        title: 'GST',
        render: (rowData) => rowData.gstType,
      },
      {
        field: 'sku',
        title: 'SKU',
        render: renderSkuColumn,
      },
      {
        field: 'ticketPrice',
        title: 'Ticket Price',
        render: (rowData) => renderPriceColumn(rowData.ticketPrice),
      },
      {
        field: 'payableIncentiveAmountToStaff',
        title: 'Staff SPIV payment',
        render: (rowData) => renderPriceColumn(rowData.payableIncentiveAmountToStaff),
      },
      {
        field: 'capValue',
        title: <span>Unit or $ Cap (ex. GST)</span>,
        render: renderCapColumn,
      },
      {
        field: 'minSellPrice',
        title: 'Min sell price',
        render: (rowData) => {
          if (rowData.minSellPriceType === Consts.SPIVMinSellPriceTypeEnum.GoSell) {
            return 'Go Sell';
          }
          return getDisplayAmountValue(rowData.minSellPrice, '$');
        },
      },
      {
        field: 'amountSoldOrPurchased',
        title: 'Amount Sold',
        render: (rowData) => rowData.amountSoldOrPurchased,
        hide: hideColumns?.amountSoldOrPurchased,
      },
      {
        field: 'accruedTotal',
        title: 'Accrued Total (ex)',
        render: (rowData) => getDisplayAmountValue(rowData.accruedTotal, '$'),
        hide: hideColumns?.accruedTotal,
      },
      {
        field: 'gstTotal',
        title: 'GST Total',
        render: (rowData) => getDisplayAmountValue(rowData.gstTotal, '$'),
      },
    ];
    return columns.filter((x) => !x.hide);
  };

  const loadSPIVValues = useCallback(
    async (pagination: Partial<Pagination>) => {
      try {
        showLoading();
        const response: AxiosResponse<SpivValuesForSpivSummaryResponse> = await get(
          api(Consts.Api.SpivSummaryValues.replace(':id', `${spivId}`)),
          {
            params: pagination ?? {},
          }
        );
        const {data, dealType, ...rest} = response.data ?? {};
        setData(data);
        setPagination(rest);
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load deal values', response: error.response},
        });
      } finally {
        hideLoading();
      }
    },
    [spivId, showLoading, hideLoading]
  );

  const renderAmountColumn = (rowData: SPIVValueForSPIVSummaryResponse) => {
    const unit = Consts.AmountType.find((x) => x.value === rowData.amountType);
    if (unit?.value === Consts.AmountTypeEnum.FixedAmount) {
      return getDisplayAmountValue(rowData.amount, '$');
    }
    if (unit?.value === Consts.AmountTypeEnum.ValuePerUnit) {
      return getDisplayAmountValue(rowData.amount, '$', ' per unit');
    }
    return getDisplayAmountValue(rowData.amount, '', '%');
  };

  const renderCapColumn = (rowData: SPIVValueForSPIVSummaryResponse) => {
    const unit = Consts.DealCapType.find((item) => item.value === rowData.capType);
    const value = numberWithCommas(rowData.capValue);
    if (!value || !unit) {
      return null;
    }
    if (unit.value === Consts.DealCapTypeEnum.UnitCount) {
      const capValue = rowData.capValue ?? 0;
      return getDisplayCommasValue(capValue, '', capValue > 1 ? ' Units' : ' Unit');
    }
    return getDisplayAmountValue(rowData.capValue, '$');
  };

  const renderPriceColumn = (price?: number | null) => getDisplayCommasValue(price, '$');

  const renderSkuColumn = (rowData: SPIVValueForSPIVSummaryResponse) =>
    rowData.productCode ? `${rowData.productCode} ${rowData.productDescription}` : null;

  const onChangePage = (currentPage: Pagination['currentPage']) => {
    loadSPIVValues({currentPage, pageSize: pagination.pageSize});
  };

  const onChangePageSize = (pageSize: Pagination['pageSize']) => {
    loadSPIVValues({currentPage: 1, pageSize});
  };

  const renderCell = (
    column: CustomTableColumn,
    row: SPIVValueForSPIVSummaryResponse,
    rowIndex: number
  ): React.ReactNode | string | number | null => {
    if (column.render) {
      return <CellContainer>{column.render(row, rowIndex)}</CellContainer>;
    }
    return row[column.field as keyof SPIVValueForSPIVSummaryResponse] as string | number | null;
  };

  const renderRow = (row: SPIVValueForSPIVSummaryResponse, rowIndex: number) => (
    <TableRow className={classes.tableRow} key={rowIndex}>
      {getColumns().map((column, columnIndex) => (
        <TableCell className={columnIndex === 0 ? classes.tableFirstRow : ''} key={columnIndex}>
          {renderCell(column, row, rowIndex)}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <StyledTableContainer>
      <Table>
        <TableHead className={classes.tableHeading}>
          <TableRow>
            {getColumns().map((column, index) => (
              <TableCell
                className={
                  index === 0
                    ? `${classes.tableHeadingCell} ${classes.tableFirstRow}`
                    : classes.tableHeadingCell
                }
                key={index}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{data.map((row, index) => renderRow(row, index))}</TableBody>
      </Table>
      <TablePaginationContainer>
        <PageCounter pagination={pagination} />
        <PageNavigator pagination={pagination} onChangePage={onChangePage} />
        <PageSetting pagination={pagination} onChangePageSize={onChangePageSize} />
      </TablePaginationContainer>
    </StyledTableContainer>
  );
};

export default SPIVSummaryValuesTable;
