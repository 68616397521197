import React, {useCallback} from 'react';
import {styled} from '@mui/material/styles';
import AutoCompleteSearch from './AutoCompleteSearch';
import Consts from '../../../app/Consts';
import config from '../../../app/Config';

const InactiveText = styled('span')`
  font-style: italic;
  color: #626262;
`;

export interface ClaimVendor {
  code: string;
  name: string;
  isActive: number;
}

export interface VendorSearchProps {
  defaultValue: ClaimVendor | null;
  onChange: (item: ClaimVendor) => void;
  filters?: any;
  defaultRefinement?: any;
}

const VendorSearch = (props: VendorSearchProps) => {
  const {onChange, defaultRefinement, defaultValue, filters} = props;

  const getSelectedValueCallback = useCallback((item: ClaimVendor) => {
    return {code: item.code, name: item.name, isActive: item.isActive};
  }, []);

  const renderDisplayTextCallback = useCallback(
    (item: ClaimVendor) => `${item.name}${item.isActive ? '' : ' (Inactive)'}`,
    []
  );

  const renderListItemCallback = useCallback((hit: ClaimVendor) => {
    return (
      <>
        {hit.name} {hit.isActive ? null : <InactiveText>&nbsp;(Inactive)</InactiveText>}
      </>
    );
  }, []);

  const onSelectedCallback = useCallback(
    (item: ClaimVendor) => {
      onChange(item);
    },
    [onChange]
  );

  return (
    <AutoCompleteSearch
      name="claimVendor"
      fullWidth
      indexName={Consts.AlgoliaIndex.ClaimVendors}
      defaultRefinement={defaultRefinement}
      defaultValue={defaultValue}
      noResultMessage="We haven't found any claim vendors that match the text you entered."
      placeholder="All claim vendors"
      filters={filters ? filters : `entityCode:"${config.entityCode}" AND isActive:1`}
      getSelectedValue={getSelectedValueCallback}
      renderDisplayText={renderDisplayTextCallback}
      renderListItem={renderListItemCallback}
      onSelected={onSelectedCallback}
      confirm={null}
      onConfirm={undefined}
    />
  );
};
export default VendorSearch;
