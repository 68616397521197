import React, {FC, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import Consts from '../../app/Consts';

export interface Claim {
  status: (typeof Consts.ClaimStatusEnum)[keyof typeof Consts.ClaimStatusEnum];
}

export interface ClaimAdvancementWarningProps {
  claims: Claim[];
}

const WarningText = styled('span')`
  color: red;
  font-weight: bold;
  font-size: 1.125rem;
`;
const ClaimAdvancementWarning: FC<ClaimAdvancementWarningProps> = ({claims}) => {
  const hasAnySentToSupplier = useMemo(
    () => claims?.filter((c) => c.status === Consts.ClaimStatusEnum.SentToSupplier)?.length > 0,
    [claims]
  );
  return (
    <>
      {hasAnySentToSupplier ? (
        <WarningText>
          DO NOT Proceed if you do not have approval from the Supplier. No further updates to this
          claim can be made, including the claim amount, if you proceed.
        </WarningText>
      ) : null}
    </>
  );
};

export default ClaimAdvancementWarning;
