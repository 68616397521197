import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import Grid from '@mui/material/Grid';
import * as R from 'ramda';
import {AxiosResponse} from 'axios';
import {api, put, get} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {RebateFormValues, RebateFormBag, RebateValueResponse, SelectOption} from '../../types';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import FieldLabel from '../Form/FieldLabel';
import FieldGrid from '../Form/FieldGrid';
import FormStep from '../Form/FormStep';
import RadioGroupField from '../Form/RadioGroupField';
import ProductField from '../Form/ProductField';
import AmountField from '../Form/AmountField';
import CustomRadioGroup from '../Form/RadioGroup';
import {TableCellSelect} from '../Table';
import {DownloadTemplatesModal, EntityActionType, FinanceAccountClearModal} from '../Modal';
import {CustomAutoComplete} from '../CustomAutoComplete';
import {LinkButton, StaticButton} from '../Button';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';

type SavedRebateValues = Omit<RebateFormValues, 'id'> & {id: number};
type Bag = RebateFormBag<SavedRebateValues>;

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  lastStepButtonText?: string;
  handleBack: (values: SavedRebateValues, bag: Bag) => void;
  handleNext: (values: SavedRebateValues, bag: Bag) => void;
};

const StepRebateValue: FC<Props> = ({
  step,
  title,
  validationSchema,
  totalStep,
  lastStepButtonText = 'Finished',
  handleBack,
  handleNext,
}) => {
  const [financeAccountClearedModalOpen, setFinanceAccountClearedModalOpen] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [searchTextForSupplier, setSearchTextForSupplier] = useState('');
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);
  const [writebackTypeOptions, setWritebackTypeOptions] = useState(Consts.WritebackType);
  const [productSelectorMode, setProductSelectorMode] = useState(true);

  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<SavedRebateValues>();
  const {
    setFieldValue,
    values: {
      id,
      rebateType,
      amountType,
      amount,
      gstType,
      writebackType,
      suppliers,
      claimVendorSuppliers,
      claimVendorName,
      hasActiveClaim,
      isClaimVendorGstFree,
      hasRaisedClaim,
    },
  } = bag;
  const updateFormValues = useCallback(
    (data: RebateValueResponse) => {
      setFieldValue('rebateType', data.rebateType);
      setFieldValue('amount', data.amount);
      setFieldValue('amountType', data.amountType);
      setFieldValue('writebackType', data.writebackType || Consts.WritebackTypeEnum.None);
      setFieldValue('gstType', data.gstType);
      setFieldValue('productCriteria', data.productCriteria);
      setFieldValue('uploadedProductCount', data.uploadedProductCount);
      setFieldValue('products', data.products);
      setFieldValue('suppliers', data.suppliers);
      setProductSelectorMode(data.uploadedProductCount <= 0);
    },
    [setFieldValue]
  );

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.RebateValue.replace(':id', `${id}`)))
      .then((response: AxiosResponse<RebateValueResponse>) => {
        alertService.clear(defaultAlertId);
        updateFormValues(response.data);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [showLoading, hideLoading, id, updateFormValues]);

  useEffect(() => {
    if (rebateType === Consts.RebateTypeEnum.Sales) {
      setWritebackTypeOptions(
        Consts.WritebackType.filter(
          (x) => x.value !== Consts.WritebackTypeEnum.BuyInDealsAndDisplayAllowance
        )
      );
      if (writebackType === Consts.WritebackTypeEnum.BuyInDealsAndDisplayAllowance) {
        setFieldValue('writebackType', Consts.WritebackTypeEnum.None);
      }
    } else {
      setWritebackTypeOptions(Consts.WritebackType);
    }
  }, [rebateType, writebackType, setFieldValue]);

  useEffect(() => {
    setLoadingSuppliers(true);
    get(api(Consts.Api.Suppliers), {
      params: {
        searchText: searchTextForSupplier,
        entityCode: config.entityCode,
      },
    })
      .then((response) => {
        setSupplierOptions(response.data.data);
      })
      .catch((error) => {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load suppliers', response: error.response},
        });
      })
      .finally(() => {
        setLoadingSuppliers(false);
      });
  }, [searchTextForSupplier]);

  const saveRebateValue = (values: SavedRebateValues, bag: Bag) => {
    showLoading();
    const requestData = {
      gstType: values.gstType,
      rebateType: values.rebateType,
      amount: Number(values.amount),
      amountType: values.amountType,
      writebackType:
        values.writebackType === Consts.WritebackTypeEnum.None ? null : values.writebackType,
      productCriteria: values.productCriteria,
      suppliers: suppliers.map((x) => x.number),
    };
    return put(api(Consts.Api.RebateValue.replace(':id', `${values.id}`)), requestData)
      .then((response: AxiosResponse<RebateValueResponse>) => {
        alertService.clear(defaultAlertId);
        const data = response.data || {};
        setFieldValue('products', data.products);
        if (data.financeAccountCleared) {
          setFinanceAccountClearedModalOpen(true);
          setFieldValue('financeAccountCleared', true);
        } else {
          handleNext(values, bag);
        }
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  let amountTypeOption = R.find(R.propEq('value', amountType), Consts.AmountType);
  let gstTypeOption = R.find(R.propEq('value', gstType), Consts.GstType);
  const financeAccountClearAcknowleged = () => {
    setFinanceAccountClearedModalOpen(false);
    handleNext(bag.values, bag);
  };
  function renderHeader() {
    return (
      <>
        <LinkButton
          type="button"
          onClick={() => {
            setTemplatesModalOpen(true);
          }}
        >
          Download Excel Templates
        </LinkButton>
        <DownloadTemplatesModal
          title={'Download Excel Templates'}
          fileNames={['Rebate Products Template.xlsx']}
          open={templatesModalOpen}
          onClose={() => {
            setTemplatesModalOpen(false);
          }}
        />
      </>
    );
  }
  const gstOptions = isClaimVendorGstFree
    ? Consts.GstType.filter((x) => x.value === Consts.GstTypeEnum.Free)
    : Consts.GstType;
  return (
    <>
      <FormStep step={step} title={title} renderHeader={renderHeader}>
        <ActiveClaimWarning
          entityActionType={EntityActionType.Rebate}
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
        />
        <FieldGrid item xs={12}>
          <FieldLabel sx={{fontSize: '1.25rem', marginBotom: '1rem'}} fullWidth>
            This rebate is going to be calculated based on...
          </FieldLabel>
          <RadioGroupField name="rebateType" value={rebateType} options={Consts.RebateTypes} />
        </FieldGrid>
        <Grid container spacing={1}>
          <FieldGrid item xs={6}>
            <FieldLabel htmlFor="amount" fullWidth>
              Claim Value
            </FieldLabel>
            <Grid container alignItems="flex-start">
              <TableCellSelect
                sx={{minWidth: '6.25rem'}}
                options={Consts.AmountType.filter(
                  (x) => x.value !== Consts.AmountTypeEnum.FixedAmount
                )}
                defaultValue={amountTypeOption}
                onChanged={(option: SelectOption) => {
                  setFieldValue('amountType', option.value);
                }}
              />
              <AmountField
                warning={
                  amountType === Consts.AmountTypeEnum.Percentage &&
                  Number(amount) > config.rebatePercentageAmountWarningThreshold
                }
                warningText={`The value is greater than ${config.rebatePercentageAmountWarningThreshold}%`}
                id="amount"
                name="amount"
                placeholder="Add your value here"
                sx={{flex: '1'}}
              />
            </Grid>
          </FieldGrid>
          <FieldGrid item xs={6}>
            <FieldLabel htmlFor="gstType" fullWidth>
              GST
            </FieldLabel>
            <TableCellSelect
              id="gstType"
              name="gstType"
              disabled={isClaimVendorGstFree}
              options={gstOptions}
              defaultValue={gstTypeOption}
              onChanged={(option: SelectOption) => {
                setFieldValue('gstType', option.value);
              }}
              fullWidth
            />
          </FieldGrid>
        </Grid>
        <FieldGrid item xs={12}>
          <FieldLabel sx={{fontSize: '1.25rem', marginBotom: '1rem'}} fullWidth>
            Include Writebacks
          </FieldLabel>
          <RadioGroupField
            name="writebackType"
            value={writebackType}
            verticalDisplay
            options={writebackTypeOptions}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel>SKUs are included based on...</FieldLabel>
          <CustomRadioGroup
            name="productSelectorMode"
            options={[
              {label: 'Product selection tool', value: true},
              {label: 'Bulk upload', value: false},
            ]}
            value={productSelectorMode}
            onChanged={(mode) => {
              setProductSelectorMode(Boolean(mode));
            }}
          />
          <Grid>
            <ProductField
              productSelectorMode={productSelectorMode}
              id="productCriteria"
              name="productCriteria"
              onOpenTemplate={() => {
                setTemplatesModalOpen(true);
              }}
            />
          </Grid>
        </FieldGrid>
        {!productSelectorMode ? (
          <FieldGrid item xs={12}>
            <Grid container alignItems="baseline">
              <FieldLabel fullWidth>Supplier(s)</FieldLabel>
              <StaticButton
                onClick={() => {
                  setFieldValue('suppliers', claimVendorSuppliers);
                }}
              >
                Fill in with suppliers of {claimVendorName} ({claimVendorSuppliers.length})
              </StaticButton>
            </Grid>
            <CustomAutoComplete
              loading={loadingSuppliers}
              options={R.union(supplierOptions, suppliers)}
              value={suppliers}
              disableCloseOnSelect
              onInputBlur={() => {
                setSearchTextForSupplier('');
              }}
              multiple
              limitTags={8}
              getOptionLabel={(option) => option.name}
              onInputChange={(event, newInputValue, reason) => {
                if (reason !== 'reset') {
                  setSearchTextForSupplier(newInputValue);
                }
              }}
              inputValue={searchTextForSupplier}
              onChange={(event, newValue) => {
                setFieldValue('suppliers', [...newValue]);
              }}
              placeholder="Search suppliers"
              getLimitTagsText={(more) => `+ ${more} suppliers`}
            />
          </FieldGrid>
        ) : null}
      </FormStep>
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={saveRebateValue}
        step={step}
        totalStep={totalStep}
        lastStepButtonText={lastStepButtonText}
      />

      <FinanceAccountClearModal
        open={financeAccountClearedModalOpen}
        onClose={financeAccountClearAcknowleged}
      />
    </>
  );
};

export default StepRebateValue;
