import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import {Link, LinkProps} from 'react-router-dom';

const PrimaryLink = styled(Link)`
  background-color: #000000;
  color: #ffffff;
  width: 13.75rem;
  height: 3.25rem;
  border-radius: 0;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

type Props = {
  url: string;
  children: React.ReactNode;
} & Omit<LinkProps, 'to'>;

const ButtonLink: FC<Props> = ({children, url, state, ...props}) => (
  <PrimaryLink to={url} state={state} {...props}>
    {children}
  </PrimaryLink>
);

export default ButtonLink;
