import React from 'react';
import {styled} from '@mui/material/styles';

const PageCountContainer = styled('div')`
  font-style: italic;
  color: #626262;
`;

const PageCounter = ({pagination, ...props}) => {
  const from =
    pagination.totalCount > 0 ? (pagination.currentPage - 1) * pagination.pageSize + 1 : 0;
  const to = Math.min(pagination.currentPage * pagination.pageSize, pagination.totalCount);
  return (
    <PageCountContainer>
      Showing {from} to {to} of {pagination.totalCount} entries
    </PageCountContainer>
  );
};

export default PageCounter;
