import React, {FC, useState, useEffect, useContext, useCallback} from 'react';
import Grid from '@mui/material/Grid';
import {useFormikContext} from 'formik';
import * as R from 'ramda';
import {AxiosResponse} from 'axios';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {useAppSelector, useAppDispatch} from '../../app/store';
import {selectDealsTemplateModalOpen} from '../../app/selectors';
import {setDealsTemplateModalOpen} from '../../app/dealsReducer';
import LoadingContext from '../../app/LoadingContext';
import {get, api, post} from '../../utils/Request';
import {
  ValidSPIVFormValues,
  SPIVFormBag,
  EntityActionType,
  SupplierResponse,
  Supplier,
} from '../../types';
import {ErrorBox} from '../Alert';
import {LinkButton, StaticButton} from '../Button';
import {CustomAutoComplete} from '../CustomAutoComplete';
import RaisedClaimWarning from '../Claim/RaisedClaimWarning';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';
import FieldGrid from '../Form/FieldGrid';
import FieldLabel from '../Form/FieldLabel';
import FormStep from '../Form/FormStep';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import DownloadTemplatesModal from '../Modal/DownloadTemplatesModal';
import UnSavedChangesConfirmModal from '../Modal/UnSavedChangesConfirmModal';
import SPIVValuesTable from './SPIVValuesTable';
import SPIVDetailsHeader from './SPIVDetailsHeader';
import {spivDisabled} from './spivUtils';

type Props = {
  step: number;
  title: string;
  validationSchema: any;
  totalStep: number;
  onBack: (values: ValidSPIVFormValues, bag: SPIVFormBag) => void;
  onNext: (values: ValidSPIVFormValues, bag: SPIVFormBag) => void;
  style?: React.CSSProperties;
};

const StepSPIVValues: FC<Props> = ({step, title, validationSchema, totalStep, onBack, onNext}) => {
  const [unSavedChangesConfirmModalOpen, setUnSavedChangesConfirmModalOpen] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [searchTextForSupplier, setSearchTextForSupplier] = useState('');
  const [supplierOptions, setSupplierOptions] = useState<Supplier[]>([]);
  const [isEditingRow, setIsEditingRow] = useState(false);
  const bag = useFormikContext<ValidSPIVFormValues>();
  const {
    values: {
      id,
      claimVendorName,
      claimVendorSuppliers,
      hasActiveClaim,
      hasRaisedClaim,
      isFinalClaimRaised,
      suppliers,
    },
    setFieldValue,
    errors,
    touched,
  } = bag;

  const {showLoading, hideLoading} = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const templateOpenGlobal = useAppSelector(selectDealsTemplateModalOpen);

  const {
    fields: disabledFields,
    actions: {disableUpdateOnNextStepTwo},
  } = spivDisabled(bag.values);

  const loadSuppliers = useCallback(async (searchText: string) => {
    setLoadingSuppliers(true);
    try {
      const response: AxiosResponse<SupplierResponse> = await get(api(Consts.Api.Suppliers), {
        params: {...(searchText ? {searchText} : {}), entityCode: config.entityCode},
      });
      setSupplierOptions(response.data.data);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to load suppliers', response: error.response},
      });
    } finally {
      setLoadingSuppliers(false);
    }
  }, []);

  useEffect(() => {
    loadSuppliers(searchTextForSupplier);
  }, [searchTextForSupplier, loadSuppliers]);

  const onConfirmIgnoreChangesAndContinue = () => {
    onNext(bag.values, bag);
  };

  const handleNext = async (values: ValidSPIVFormValues, bag: SPIVFormBag) => {
    if (isEditingRow) {
      setUnSavedChangesConfirmModalOpen(true);
    } else {
      if (!id) {
        return;
      }
      showLoading();
      try {
        if (!disableUpdateOnNextStepTwo) {
          await post(api(Consts.Api.DealAgreementSuppliers.replace(':id', `${id}`)), {
            suppliers: suppliers.map((x) => x.number),
          });
        }
        onNext(values, bag);
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: error.message, response: error.response},
        });
      } finally {
        hideLoading();
      }
    }
  };

  const renderHeader = () => (
    <>
      <LinkButton
        type="button"
        onClick={() => {
          dispatch(setDealsTemplateModalOpen(true));
        }}
      >
        Download Excel Templates
      </LinkButton>
      <DownloadTemplatesModal
        title={'Download Excel Templates'}
        fileNames={['SPIVs Template (Deals).xlsx']}
        open={templateOpenGlobal}
        onClose={() => {
          dispatch(setDealsTemplateModalOpen(false));
        }}
      />
    </>
  );

  const handleTableDataUpdating = (isUpdating: boolean) => {
    setIsEditingRow(isUpdating);
  };

  const spivValueError =
    typeof errors?.spivValues?.data === 'string' ? errors.spivValues.data : null;
  return (
    <>
      <SPIVDetailsHeader />
      <FormStep step={step} title={title} renderHeader={renderHeader}>
        <ActiveClaimWarning
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
          entityActionType={EntityActionType.Deal}
        />
        {hasRaisedClaim ? <RaisedClaimWarning /> : null}
        <FieldGrid item xs={12}>
          <Grid container alignItems="baseline">
            <FieldLabel fullWidth>Supplier(s)</FieldLabel>
            <StaticButton
              onClick={() => {
                setFieldValue('suppliers', claimVendorSuppliers);
              }}
              disabled={isFinalClaimRaised}
            >
              Fill in with suppliers of {claimVendorName} ({claimVendorSuppliers.length})
            </StaticButton>
          </Grid>
          <CustomAutoComplete
            loading={loadingSuppliers}
            options={R.union(supplierOptions, suppliers)}
            value={suppliers}
            disableCloseOnSelect
            onInputBlur={() => {
              setSearchTextForSupplier('');
            }}
            multiple
            limitTags={8}
            getOptionLabel={(option) => option.name}
            onInputChange={(event, newInputValue, reason) => {
              if (reason !== 'reset') {
                setSearchTextForSupplier(newInputValue);
              }
            }}
            inputValue={searchTextForSupplier}
            onChange={(event, newValue) => {
              setFieldValue('suppliers', [...newValue]);
            }}
            placeholder="Search suppliers"
            getLimitTagsText={(more) => `+ ${more} suppliers`}
            disabled={disabledFields.suppliers}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <SPIVValuesTable onTableDataUpdating={handleTableDataUpdating} />
        </FieldGrid>
        {!!spivValueError && !!touched?.spivValues ? (
          <FieldGrid item xs={12}>
            <ErrorBox>{spivValueError}</ErrorBox>
          </FieldGrid>
        ) : null}
      </FormStep>
      <UnSavedChangesConfirmModal
        open={unSavedChangesConfirmModalOpen}
        onOk={onConfirmIgnoreChangesAndContinue}
        onCancel={() => {
          setUnSavedChangesConfirmModalOpen(false);
        }}
      />
      <StepperFormActionSection
        handleBack={onBack}
        handleNext={handleNext}
        step={step}
        totalStep={totalStep}
      />
    </>
  );
};

export default StepSPIVValues;
