/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useMemo, useState, useRef} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import {selectLoggedInStaffCode, selectLoggedInStaffDisplayName} from '../../app/selectors';
import config from '../../app/Config';
import Consts from '../../app/Consts';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {useAppSelector} from '../../app/store';
import {FinanceAccount} from '../../app/financialAccountsReducer';
import {ClaimSideFilters} from '../../app/claimsReducer';
import {Owner} from '../../app/ownersReducer';
import {DateType, isValidDate} from '../../utils/DateUtils';
import {api, get} from '../../utils/Request';
import {Department, ClaimTypeRequestParam} from '../../types';
import SideFilters from '../SideFilters/SideFilters';
import {VendorSearch} from '../Form/Agolia';
import FieldLabel from '../Form/FieldLabel';
import DatePicker from '../Form/DatePicker';
import {LinkButton} from '../Button';
import useDelayLoading from '../Hook/useDelayLoading';
import {CustomAutoComplete} from '../CustomAutoComplete';
import OwnersSearch from '../Form/OwnersSearch/OwnersSearch';
import DepartmentMultipleSearch from '../Form/DepartmentMultipleSearch/DepartmentMultipleSearch';
import FinanceAccountsMultipleSearch from '../Form/FinanceAccountsMultipleSearch/FinanceAccountsMultipleSearch';

const claimTypeOptions: ClaimTypeRequestParam[] = [
  {
    label: 'Deal: Sales',
    value: {
      agreementType: Consts.AgreementTypeEnum.Deal,
      rebateType: Consts.StandardDealTypeEnum.Sales,
    },
  },
  {
    label: 'Deal: Purchases',
    value: {
      agreementType: Consts.AgreementTypeEnum.Deal,
      rebateType: Consts.StandardDealTypeEnum.Purchases,
    },
  },
  {
    label: 'Deal: Fixed Amount',
    value: {
      agreementType: Consts.AgreementTypeEnum.Deal,
      rebateType: Consts.StandardDealTypeEnum.FixedAmount,
    },
  },
  {
    label: 'Deal: SPIV',
    value: {
      agreementType: Consts.AgreementTypeEnum.Deal,
      agreementSubType: Consts.AgreementSubTypeEnum.Spiv,
    },
  },
  {
    label: 'Deal: Mix & Match',
    value: {
      agreementType: Consts.AgreementTypeEnum.Deal,
      agreementSubType: Consts.AgreementSubTypeEnum.MixAndMatch,
    },
  },
  {
    label: 'Rebate: Sales',
    value: {
      agreementType: Consts.AgreementTypeEnum.ContractAgreement,
      rebateType: Consts.RebateTypeEnum.Sales,
    },
  },
  {
    label: 'Rebate: Purchases',
    value: {
      agreementType: Consts.AgreementTypeEnum.ContractAgreement,
      rebateType: Consts.RebateTypeEnum.Purchases,
    },
  },
];
const claimFrequencyOptions = [
  {label: 'At the start of deal/rebate', value: Consts.DealClaimIntervalEnum.StartOfRebate},
  {label: 'At the end of deal/rebate', value: Consts.DealClaimIntervalEnum.EndOfRebate},
  {label: 'Every month', value: Consts.DealClaimIntervalEnum.Monthly},
  {label: 'Every quarter', value: Consts.DealClaimIntervalEnum.Quarterly},
  {label: 'Every six months', value: Consts.RebateClaimIntervalEnum.SemiAnnually},
  {label: 'Every year', value: Consts.RebateClaimIntervalEnum.Annually},
  {label: 'Do not claim', value: Consts.DealClaimIntervalEnum.DoNotClaim},
  {label: 'Immediately', value: Consts.DealClaimIntervalEnum.Immediately},
  {label: 'Set days past expiry date', value: Consts.DealClaimIntervalEnum.DaysAfterExpiry},
];
const FilterGrid = styled(Grid)`
  margin-top: 1.875rem;
`;
const ClearGrid = styled(Grid)`
  margin-top: 0.625rem;
`;

export interface ClaimSideFilterProps {
  defaultSelection: ClaimSideFilters;
  onClose: () => void;
  onChange: (filters: any) => void;
  my: boolean;
}

const ExcludeLabel = styled(FormControlLabel)`
  margin-top: 0.5rem;
`;

const ClaimSideFilter: FC<ClaimSideFilterProps> = ({defaultSelection, my, onChange, onClose}) => {
  const [selection, setSelection] = useState<ClaimSideFilters>(defaultSelection);
  const [paymentPriorities, setPaymentPriorities] = useState([]);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const displayName = useAppSelector(selectLoggedInStaffDisplayName);
  const [paymentPriorityLoading, setPaymentPriorityLoading] = useDelayLoading(true);
  const firstUpdate = useRef(true);

  useEffect(() => {
    setPaymentPriorityLoading(true);
    get(api(Consts.Api.PaymentPriorities), {params: {entityCode: config.entityCode}})
      .then((response) => {
        setPaymentPriorityLoading(false);
        setPaymentPriorities(response.data);
      })
      .catch((error) => {
        setPaymentPriorityLoading(false);
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [setPaymentPriorityLoading]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const dateIsSelectedCorrectly = (date: string | null) => {
      return date === null || isValidDate(date);
    };
    if (dateIsSelectedCorrectly(selection?.startAt) && dateIsSelectedCorrectly(selection?.endAt)) {
      onChange(selection);
    }
  }, [selection]);

  const filters = [
    {
      label: 'Claim Vendor',
      field: (
        <>
          <VendorSearch
            defaultValue={selection.claimVendor}
            onChange={(item) => {
              setSelection((prevSelection) => {
                const newSelection = {...prevSelection, claimVendor: item};
                return newSelection;
              });
            }}
          />
          <ExcludeLabel
            control={
              <Checkbox
                checked={selection.excludeNartaInternational ?? false}
                onChange={(_, checked) => {
                  setSelection((prevSelection) => {
                    const newSelection = {...prevSelection, excludeNartaInternational: checked};
                    return newSelection;
                  });
                }}
              />
            }
            label="Exclude Narta International"
          />
          <ExcludeLabel
            control={
              <Checkbox
                checked={selection.onlyReturnAdjustedClaims ?? false}
                onChange={(_, checked) => {
                  setSelection((prevSelection) => {
                    const newSelection = {...prevSelection, onlyReturnAdjustedClaims: checked};
                    return newSelection;
                  });
                }}
              />
            }
            label="Only show adjusted claims"
          />
        </>
      ),
    },
    {
      label: 'Owner',
      field: (
        <OwnersSearch
          value={my ? {name: displayName ?? '', code: loggedInStaffCode} : selection.owner}
          my={my}
          onChange={(_: any, option: Owner | null) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                owner: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Type',
      field: (
        <CustomAutoComplete
          options={claimTypeOptions}
          value={selection.types}
          getOptionLabel={(option: any) => option.label}
          placeholder={(selection.types ?? []).length > 0 ? '' : 'All types'}
          multiple
          onChange={(_: any, option: ClaimTypeRequestParam[]) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                types: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Finance Account',
      field: (
        <FinanceAccountsMultipleSearch
          value={selection.financeAccounts}
          onChange={(_: any, option: FinanceAccount[]) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                financeAccounts: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Claim Frequency',
      field: (
        <CustomAutoComplete
          options={claimFrequencyOptions}
          value={selection.claimFrequency}
          getOptionLabel={(option: any) => option.label}
          name="claimFrequency"
          onChange={(_: any, option: any) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                claimFrequency: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Start Date',
      field: (
        <DatePicker
          value={selection.startAt}
          dateType={DateType.RangeStart}
          placeholder="Choose a start date"
          onChanged={(value) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, startAt: value};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'End Date',
      field: (
        <DatePicker
          dateType={DateType.RangeEnd}
          value={selection.endAt}
          placeholder="Choose an end date"
          onChanged={(value) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, endAt: value};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Payment Priority',
      field: (
        <CustomAutoComplete
          options={paymentPriorities}
          value={selection.paymentPriority}
          loading={paymentPriorityLoading}
          name="paymentPriority"
          onChange={(_: any, option: any) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                paymentPriority: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Department',
      field: (
        <DepartmentMultipleSearch
          value={selection.departments}
          onChange={(_: any, option: Department[]) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                departments: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
  ];

  const onClear = () => {
    setSelection((prevSelection) => {
      const newSelection = Object.keys(prevSelection).reduce(
        (acc, k) => ({...acc, [k]: null}),
        prevSelection
      );

      if (my) {
        newSelection.owner = prevSelection.owner;
      }

      newSelection.status = prevSelection.status;
      newSelection.departments = [];
      newSelection.financeAccounts = [];
      newSelection.types = [];

      return newSelection;
    });
  };

  const anyFilterHasValue = (selection: Partial<ClaimSideFilters>): boolean =>
    Object.values(selection).some((x) => !!x);

  const relevantFiltersHaveValue = useMemo(() => {
    // need to pull out the array type filters and treat them differently to single value filters
    const {departments, financeAccounts, types, status, ...releventFilters} = selection;
    const hasAnyDepartmentsSelected = departments && departments.length > 0;
    const hasAnyFinanceAccountsSelected = financeAccounts && financeAccounts.length > 0;
    const hasAnyTypesSelected = types && types.length > 0;
    if (my) {
      const {owner, ...selectionWithoutOwners} = releventFilters;
      return (
        anyFilterHasValue(selectionWithoutOwners) ||
        hasAnyDepartmentsSelected ||
        hasAnyFinanceAccountsSelected ||
        hasAnyTypesSelected
      );
    }
    return (
      anyFilterHasValue(releventFilters) ||
      hasAnyDepartmentsSelected ||
      hasAnyFinanceAccountsSelected ||
      hasAnyTypesSelected
    );
  }, [selection, my]);

  return (
    <SideFilters onClose={onClose}>
      <>
        {relevantFiltersHaveValue ? (
          <ClearGrid container justifyContent="flex-end">
            <LinkButton aria-label="clear-selection" onClick={onClear}>
              Clear
            </LinkButton>
          </ClearGrid>
        ) : null}

        {filters.map((item) => (
          <FilterGrid key={item.label}>
            <FieldLabel>{item.label}</FieldLabel>
            {item.field}
          </FilterGrid>
        ))}
      </>
    </SideFilters>
  );
};

export default ClaimSideFilter;
