import React, {FC, ChangeEvent, FocusEvent} from 'react';
import TextField from '@mui/material/TextField';

type Props = {
  value?: string;
  onChange: (comment: string) => void;
  helperText?: string;
};

const CommentField: FC<Props> = ({value, onChange, helperText}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    event.currentTarget.setSelectionRange(
      event.currentTarget.value.length,
      event.currentTarget.value.length
    );
  };

  return (
    <TextField
      autoFocus
      type="text"
      multiline
      fullWidth
      value={value ?? ''}
      onChange={handleChange}
      inputProps={{onFocus: handleFocus}}
      helperText={helperText}
      error={!!helperText}
    />
  );
};

export default CommentField;
