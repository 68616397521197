import React from 'react';
import {Outlet} from 'react-router-dom';
import Box from '@mui/material/Box';
import {useAppSelector} from '../app/store';
import {selectProfileHasRoles, selectProfileLoading, selectConfigsLoading} from '../app/selectors';
import {TopNav} from '../components/TopNav';
import {Alert} from '../components/Alert';
import LeftNav from '../components/LeftNav';

const Layout = () => {
  const profileLoading = useAppSelector(selectProfileLoading);
  const profileHasRoles = useAppSelector(selectProfileHasRoles);
  const configsLoading = useAppSelector(selectConfigsLoading);

  const showAppContent = profileHasRoles && !profileLoading && !configsLoading;

  return (
    <>
      <Alert />
      <TopNav />
      {showAppContent ? (
        <>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              height: 'calc(100vh - 7.5rem)',
              background: 'white',
              overflowY: 'auto',
              minWidth: '18.75rem',
            }}
          >
            <LeftNav />
          </Box>
          {/* content container: whatever is returned in routes */}
          <Box sx={{overflowX: 'auto', width: '100%', marginBottom: '8rem'}}>
            <Outlet />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default Layout;
