/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {styled} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import * as R from 'ramda';
import qs from 'qs';
import Consts from '../../../app/Consts';
import config from '../../../app/Config';
import {RootState, useAppDispatch, useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode, selectProfileRoles} from '../../../app/selectors';
import {
  ContractAgreementFilter,
  setContractAgreementsFilter,
  setContractAgreementsPagination,
  setContractAgreementsSearch,
  setContractAgreementsOrder,
} from '../../../app/contractAgreementsReducer';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import {hasRole} from '../../../app/Permission';
import {
  ContractAgreementListResponse,
  ContractAgreementData,
  ListPageParamOptions,
  Order,
  Pagination,
  TableColumn,
} from '../../../types';
import {api, get} from '../../../utils/Request';
import {formatDate} from '../../../utils/DateUtils';
import {createOrderFromParams} from '../../../utils/common';
import {SimpleDataTable} from '../../SimpleDataTable';
import {Status} from '../../Status';
import {SearchInputField} from '../../SearchInputField';
import ButtonLink from '../../Button/ButtonLink';
import ButtonsContainer from '../../Container/ButtonsContainer';
import {TableTabPanel} from '../../TableTabPanel';
import {PageTabs} from '../../PageTabs';
import FiltersIndicator from '../../FiltersIndicator';
import ListFilterContainer from '../../ListFilterContainer';
import DownloadContractAgreementListingCsvButton from './DownloadContractAgreementListingCsvButton';
import ContractAgreementSideFilter from './ContractAgreementSideFilter';

const PREFIX = 'ContractAgreementListing';

const classes = {
  root: `${PREFIX}-root`,
  containerRoot: `${PREFIX}-containerRoot`,
  containerWidthLg: `${PREFIX}-containerWidthLg`,
  textFieldRoot: `${PREFIX}-textFieldRoot`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.containerRoot}`]: {
    paddingLeft: '51px',
    paddingRight: '43px',
  },

  [`& .${classes.containerWidthLg}`]: {
    maxWidth: '2000px',
  },

  [`& .${classes.textFieldRoot}`]: {
    width: '415px',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    margin: '50px 0',
  },
}));

const columns: TableColumn<ContractAgreementData>[] = [
  {id: 'id', label: 'ID', minWidth: 48},
  {id: 'description', label: 'Description', minWidth: 150, sortable: true},
  {id: 'claimVendorName', label: 'Claim Vendor', minWidth: 80, sortable: true},
  {
    id: 'startAt',
    label: 'Start Date',
    minWidth: 80,
    render: (rowData: ContractAgreementData) => formatDate(rowData.startAt),
    sortable: true,
  },
  {
    id: 'endAt',
    label: 'End Date',
    minWidth: 80,
    render: (rowData: ContractAgreementData) => formatDate(rowData.endAt),
    sortable: true,
  },
  {id: 'ownedByStaffName', label: 'Owner', minWidth: 80, sortable: true},
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    render: (rowData: ContractAgreementData) => <Status status={rowData.status} />,
    sortable: true,
  },
];

type GetContractAgreementsOptions = ListPageParamOptions<ContractAgreementFilter>;

const ContractAgreementListing = ({my = false}) => {
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const roles = useAppSelector(selectProfileRoles);
  const isUserAllowedToCreateAgreements = hasRole(
    Consts.UserRoleEnum.AddOrUpdateContractAgreements,
    roles
  );

  const {filterSelection, pagination, searchText, order} = useSelector(
    (state: RootState) => state.contractAgreements
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<ContractAgreementData[]>([]);
  const [openSideFilters, setOpenSideFilters] = useState(false);
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);
  const filterRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const setFilterSelection = (newFilters: ContractAgreementFilter) => {
    dispatch(setContractAgreementsFilter(newFilters));
  };

  const setPagination = (pagination: Pagination) => {
    dispatch(setContractAgreementsPagination(pagination));
  };

  const setSearchText = (searchText: string | null) => {
    dispatch(setContractAgreementsSearch(searchText));
  };

  const setOrder = (newOrder: Order) => {
    dispatch(setContractAgreementsOrder(newOrder));
  };

  const setData = useCallback((responseData: ContractAgreementListResponse) => {
    const contractAgreements = responseData.data;
    setRows(contractAgreements);
    setLoading(false);
    setPagination({
      totalPages: responseData.totalPages,
      currentPage: responseData.currentPage,
      pageSize: responseData.pageSize,
      totalCount: responseData.totalCount,
    });
  }, []);

  const getContractAgreements = useCallback(
    (params: GetContractAgreementsOptions, singleOrderBy?: boolean) => {
      setLoading(true);

      get(api(Consts.Api.ContractAgreements), {
        params: {
          entityCode: config.entityCode,
          ownedByStaffCode: my ? loggedInStaffCode : null,
          ...params,
        },
        paramsSerializer: (params: GetContractAgreementsOptions) => {
          const {orderBy, ...rest} = params;
          const nextParams =
            singleOrderBy || !isDefaultOrder
              ? params
              : {...rest, orderBy: [orderBy, 'description:asc']};
          const result = qs.stringify(nextParams, {skipNulls: true, arrayFormat: 'repeat'});
          return result;
        },
      })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        });
    },
    [my, setData, loggedInStaffCode, isDefaultOrder]
  );

  const mapGetContractAgreementsParams = (options: GetContractAgreementsOptions) => {
    let result: any = {};

    const claimVendorCode = options?.claimVendor?.code ?? undefined;
    const startAt = options?.startAt ?? undefined;
    const endAt = options?.endAt ?? undefined;
    const ownedByStaffCode = my ? loggedInStaffCode : options?.owner?.code ?? undefined;
    const contractStatus = options?.status ?? undefined;

    const searchText = options?.searchText ?? undefined;
    const totalCount = options?.totalCount ?? undefined;
    const totalPages = options?.totalPages ?? undefined;
    const pageSize = options?.pageSize ?? undefined;
    const currentPage = options?.currentPage ?? undefined;
    const orderBy =
      options?.orderBy && options?.orderByDirection
        ? `${options.orderBy}:${options.orderByDirection}`
        : undefined;

    const queryParams = [
      {name: 'claimVendorCode', value: claimVendorCode},
      {name: 'startAt', value: startAt},
      {name: 'endAt', value: endAt},
      {name: 'ownedByStaffCode', value: ownedByStaffCode},
      {name: 'status', value: contractStatus},
      {name: 'searchText', value: searchText},
      {name: 'totalPages', value: totalPages},
      {name: 'pageSize', value: pageSize},
      {name: 'currentPage', value: currentPage},
      {name: 'totalCount', value: totalCount},
      {name: 'orderBy', value: orderBy},
    ];

    queryParams.forEach((x) => {
      if (x.value) {
        result[x.name] = x.value;
      }
    });

    return result;
  };

  useEffect(() => {
    const request = mapGetContractAgreementsParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...order,
    });
    getContractAgreements(request);
  }, []);

  const handleFilterByStatusChange = (_: any, newStatus: string) => {
    setFilterSelection({...filterSelection, status: newStatus});
    setPagination({...pagination, currentPage: 1});

    const request = mapGetContractAgreementsParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...order,
      status: newStatus,
      currentPage: 1,
    });
    getContractAgreements(request);
  };

  const handleAgreementsSearch = (newSearchText: string) => {
    setSearchText(newSearchText);
    setPagination({...pagination, currentPage: 1});

    const request = mapGetContractAgreementsParams({
      searchText: newSearchText,
      ...pagination,
      ...filterSelection,
      ...order,
      currentPage: 1,
    });
    getContractAgreements(request);
  };

  const handlePagination = (newPagination: Partial<Pagination>) => {
    const request = mapGetContractAgreementsParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...newPagination,
      ...order,
    });

    getContractAgreements(request);
  };

  const handleUpdateFilters = (selection: ContractAgreementFilter) => {
    setFilterSelection(selection);
    const request = mapGetContractAgreementsParams({
      searchText,
      ...pagination,
      currentPage: 1,
      ...filterSelection,
      ...order,
      ...selection,
    });
    getContractAgreements(request);
  };

  const viewContractAgreement = (id: number, ctrlKeyPressed: boolean) => {
    const routerPath = Consts.RouterPath.ContractAgreementSummary.replace(':id', `${id}`);
    if (ctrlKeyPressed) {
      window.open(routerPath, '_blank');
    } else {
      navigate(routerPath);
    }
  };

  const handleOrderBy = (newOrderBy: string) => {
    setIsDefaultOrder(false);
    const newOrder = createOrderFromParams(newOrderBy, setOrder);
    const request = mapGetContractAgreementsParams({
      searchText,
      ...pagination,
      ...filterSelection,
      ...(newOrder ? newOrder : {}),
    });
    getContractAgreements(request, true);
  };

  const toggleSideFilters = () => {
    if (!openSideFilters) {
      filterRef?.current?.scrollIntoView?.({block: 'start', behavior: 'smooth'});
    }
    setOpenSideFilters((open) => !open);
  };

  const filterCount = () => {
    const {status, ...releventFilters} = filterSelection;
    if (my) {
      const {owner, ...releventFiltersWithoutOwner} = releventFilters;
      const result = R.filter((x: any) => x, R.values(releventFiltersWithoutOwner)).length;
      return result;
    } else {
      return R.filter((x: any) => x, R.values(releventFilters)).length;
    }
  };

  const status = Consts.ContractAgreementsFilterStatus(my).filter(
    (x) => x.value === filterSelection?.status ?? null
  )[0];
  const statusTitle = status?.label ?? null;
  const statusValue = status?.value ?? null;

  return (
    <Root className={classes.root} ref={filterRef}>
      <PageTabs
        title={statusTitle}
        value={statusValue}
        onChange={handleFilterByStatusChange}
        // @ts-ignore
        tabsList={Consts.ContractAgreementsFilterStatus(my)}
        ariaLabel="Filter contract agreements by status"
      />

      <Grid container wrap="nowrap">
        {openSideFilters && (
          <Grid item>
            <ContractAgreementSideFilter
              my={my}
              defaultSelection={filterSelection}
              onClose={() => setOpenSideFilters(false)}
              onChange={handleUpdateFilters}
            />
          </Grid>
        )}
        <Grid item sx={{width: '100%'}}>
          <Container
            maxWidth="lg"
            classes={{
              root: classes.containerRoot,
              maxWidthLg: classes.containerWidthLg,
            }}
          >
            <ListFilterContainer>
              <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                <FiltersIndicator count={filterCount()} onClick={toggleSideFilters} />
                <SearchInputField
                  placeholder="Search contract agreements"
                  width="26rem"
                  onSearch={handleAgreementsSearch}
                  defaultValue={searchText ?? ''}
                />
              </Stack>

              <ButtonsContainer>
                <DownloadContractAgreementListingCsvButton
                  my={my}
                  requestData={mapGetContractAgreementsParams({
                    searchText,
                    ...pagination,
                    ...filterSelection,
                    ...order,
                  })}
                />
                {isUserAllowedToCreateAgreements && (
                  <ButtonLink url={Consts.RouterPath.CreateContractAgreement}>
                    Add New Agreement
                  </ButtonLink>
                )}
              </ButtonsContainer>
            </ListFilterContainer>
            <TableTabPanel loading={loading} className={classes.tabPanelRoot}>
              <SimpleDataTable
                columns={columns}
                rows={rows}
                pagination={pagination}
                onChangePage={handlePagination}
                onChangePageSize={handlePagination}
                onOrderBy={(o) => handleOrderBy(o.orderBy)}
                sortOrderBy={order.orderBy}
                sortOrder={order.orderByDirection}
                onRowClick={viewContractAgreement}
                highlightColumn={order.orderBy}
              />
            </TableTabPanel>
          </Container>
        </Grid>
      </Grid>
    </Root>
  );
};

export default ContractAgreementListing;
