function numberWithCommas(x) {
  if (isNaN(x) || x === null) {
    return '';
  }
  //https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript?page=1&tab=votes#tab-top
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

function getDisplayCommasValue(value, prefix = '', postfix = '') {
  if (isNaN(value) || value === null) {
    return value || '';
  }
  let sign = '';
  if (value < 0) {
    sign = '-';
  }
  const strValue = value + '';

  let displayValue = numberWithCommas(sign === '-' ? strValue.slice(1) : strValue);
  return sign + prefix + displayValue + postfix;
}
function isNegative(value) {
  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) {
    return false;
  }
  return floatValue < 0;
}
function isPositive(value) {
  const parsedValue = parseFloat(value);
  const isNumber = !isNaN(parsedValue) && isFinite(parsedValue);
  const isPositive = parsedValue > 0;
  return isNumber && isPositive;
}
function getDisplayAmountValue(value, prefix = '', postfix = '', digits = 2) {
  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) {
    return value;
  }
  const fixedValue = floatValue.toFixed(digits);
  return getDisplayCommasValue(fixedValue, prefix, postfix);
}
function getFixedValue(value, maxDigits = 2) {
  if (value === null || value === undefined) {
    return '';
  }
  const valueWithoutComma = `${value}`.replace(/,/g, '');
  const floatValue = parseFloat(valueWithoutComma);
  if (isNaN(floatValue)) {
    return valueWithoutComma;
  }
  let newValue = floatValue.toFixed(maxDigits);
  if (newValue * 1 === floatValue * 1) {
    const numberParts = valueWithoutComma.split('.');
    if (numberParts.length !== 2 || numberParts[1].length <= maxDigits) {
      newValue = valueWithoutComma;
    }
  }
  return newValue;
}

function roundToTwoDecimalPlaces(number) {
  return Math.round(number * 100) / 100;
}

const isNullish = (value) => value === null || value === undefined;

export {
  getDisplayAmountValue,
  getDisplayCommasValue,
  getFixedValue,
  isNegative,
  isNullish,
  isPositive,
  numberWithCommas,
  roundToTwoDecimalPlaces,
};
