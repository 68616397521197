import React, {FC, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import StepContractAgreementDetails from './StepContractAgreementDetails';
import StepperForm from '../Form/StepperForm';
import Consts from '../../app/Consts';
import {api, put} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import stepContractAgreementDetailsValidationSchema from './StepContractAgreementDetailsValidationSchema';

function getSteps() {
  return ['Agreement Details'];
}

export type ContractAgreementFormValues = {
  id?: string;
  claimVendor: {
    code: string;
    name: string;
    isActive: boolean;
  };
  ownedByStaffCode: number | null;
  description: string;
  startAt: string | null;
  endAt: string | null;
  rebateValueCount: number;
  status: string;
  hasRaisedClaim: boolean;
  hasActiveClaim: boolean;
  isOngoing: boolean;
  groupClaimsOnSingleInvoice: boolean;
};

type Props = {
  title: string;
  initialValues: ContractAgreementFormValues;
  isEditing?: boolean;
};

const ContractAgreementForm: FC<Props> = ({title, initialValues, isEditing}) => {
  const navigate = useNavigate();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const lastStepButtonText = isEditing ? 'Finish' : 'Create Agreement';

  function finishContractAgreementCreation(id: string) {
    showLoading();
    put(api(Consts.Api.FinishContractAgreementCreation.replace(':id', id)))
      .then(() => {
        hideLoading();
        alertService.clear(defaultAlertId);
        navigate(Consts.RouterPath.ContractAgreementSummary.replace(':id', id));
      })
      .catch((error) => {
        hideLoading();
        alertService.info('Your contract agreement has been saved to My Drafts', {
          id: defaultAlertId,
          keepAfterRouteChange: true,
        });
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }

  function getStepContent(
    index: number,
    handleBack: (values: ContractAgreementFormValues) => void
  ) {
    let step = index + 1;
    switch (index) {
      case 0:
        return (
          <StepContractAgreementDetails
            step={step}
            title={getSteps()[index]}
            totalStep={getSteps().length}
            handleBack={handleBack}
            handleNext={finishContractAgreementCreation}
            lastStepButtonText={lastStepButtonText}
            validationSchema={stepContractAgreementDetailsValidationSchema}
          />
        );
      default:
        return null;
    }
  }

  return (
    <StepperForm
      title={title}
      initialValues={initialValues}
      onSubmit={(_values, bag) => {
        bag.formSubmit();
      }}
      steps={getSteps()}
      getStepContent={getStepContent}
    />
  );
};

export default ContractAgreementForm;
