import React from 'react';
import {MessageBody, MessageContainer, MessageHeader} from '../Container';
import {InformationIcon} from '../Icons';

const NoPermission = ({title, children}) => {
  return (
    <MessageContainer>
      <div>
        <InformationIcon />
      </div>
      <MessageHeader>{title}</MessageHeader>
      <MessageBody>{children}</MessageBody>
    </MessageContainer>
  );
};
export default NoPermission;
