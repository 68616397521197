import React, {FC, useState, useEffect, useContext, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import format from 'date-fns/format';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingContext from '../../../../app/LoadingContext';
import Consts from '../../../../app/Consts';
import {useAppSelector} from '../../../../app/store';
import {selectLoggedInStaffCode, selectLoggedInStaffDisplayName} from '../../../../app/selectors';
import {alertService, defaultAlertId} from '../../../../app/AlertService';
import {utcToTZDate} from '../../../../utils/DateUtils';
import {api, getCached, post, put} from '../../../../utils/Request';
import {TableColumn} from '../../../../types';
import {SimpleDataTable} from '../../../../components/SimpleDataTable';
import CommentField from './CommentField';
import EditActions from './EditActions';

export type Comment = {
  comment: string;
  createdByStaffName: string;
  lastUpdatedAt?: string;
  id?: number;
};

type EditCommentRequest = {
  UpdatedComment: string;
  UpdatedByStaffCode: number;
};

type AddCommentRequest = {
  Comment: string;
  CreatedByStaffCode: number;
};

export const formatCommentErrorMsg = (error: any) => {
  const errors = Object.values(error?.response?.data?.errors ?? {});
  if (errors.length === 0) {
    return error.message ?? '';
  }
  return errors.flatMap((e) => e).join('\n');
};

const RebateComments: FC = () => {
  const {id = ''} = useParams<{id: string}>();
  const [comments, setComments] = useState<Comment[]>([]);
  const [editedComment, setEditedComment] = useState<Comment | undefined>(undefined);
  const [commentFieldDirty, setCommentFieldDirty] = useState(false);

  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const loggedInUserDisplayName = useAppSelector(selectLoggedInStaffDisplayName);
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const editedCommentIsNew = !!editedComment && !editedComment?.id;
  const hasCommentRequiredError = !editedComment?.id && editedComment?.comment?.trim() === '';

  const helperText = commentFieldDirty && hasCommentRequiredError ? 'Comment is required' : '';

  useEffect(() => {
    showLoading();
    getCached(api(Consts.Api.RebateComment.replace(':id', id)))
      .then(({data}) => {
        setComments(data);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(hideLoading);
  }, [id, showLoading, hideLoading]);

  const saveComment = useCallback(() => {
    showLoading();
    if (!!editedComment?.id) {
      const request: EditCommentRequest = {
        UpdatedComment: editedComment?.comment ?? '',
        UpdatedByStaffCode: loggedInStaffCode,
      };
      put(api(`${Consts.Api.RebateComment.replace(':id', id)}/${editedComment.id}`), request, {
        cache: {
          update: {
            [api(`${Consts.Api.RebateComment.replace(':id', id)}`)]: 'delete',
          },
        },
      })
        .then((response) => {
          setEditedComment(undefined);
          setComments(response.data);
        })
        .catch((error) => {
          alertService.alert({
            message: formatCommentErrorMsg(error),
          });
        })
        .finally(hideLoading);
    } else {
      const request: AddCommentRequest = {
        Comment: editedComment?.comment as string,
        CreatedByStaffCode: loggedInStaffCode,
      };
      post(api(Consts.Api.RebateComment.replace(':id', id)), request, {
        cache: {
          update: {
            [api(`${Consts.Api.RebateComment.replace(':id', id)}`)]: 'delete',
          },
        },
      })
        .then((response) => {
          setEditedComment(undefined);
          setComments(response.data);
        })
        .catch((error) => {
          alertService.alert({
            message: formatCommentErrorMsg(error),
          });
        })
        .finally(hideLoading);
    }
  }, [editedComment, hideLoading, id, showLoading, loggedInStaffCode]);

  const handleEdit = (comment: Comment) => {
    setEditedComment({...comment, createdByStaffName: loggedInUserDisplayName});
    setComments((comments) => comments.filter((comment) => !!comment.id));
    setCommentFieldDirty(false);
  };

  const handleSave = () => {
    saveComment();
    setCommentFieldDirty(false);
  };

  const handleCancel = () => {
    setEditedComment(undefined);
    setComments((comments) => comments.filter((comment) => !!comment.id));
    setCommentFieldDirty(false);
  };

  const handleCommentChange = (comment: string) => {
    setCommentFieldDirty(true);
    setEditedComment((editedComment: Comment | undefined) => ({
      ...(editedComment ?? {createdByStaffName: loggedInUserDisplayName}),
      comment,
    }));
  };

  const handleAddCommentClick = () => {
    if (!editedCommentIsNew) {
      setEditedComment({
        comment: '',
        createdByStaffName: loggedInUserDisplayName,
      });
      setComments((comments) => [
        {
          comment: '',
          createdByStaffName: loggedInUserDisplayName,
        },
        ...comments,
      ]);
    }
  };

  const columns: TableColumn<Comment>[] = [
    {
      id: 'comment',
      label: 'Comments',
      render: (rowData: Comment) =>
        !!editedComment && editedComment.id === rowData.id ? (
          <CommentField
            value={editedComment?.comment ?? ''}
            onChange={handleCommentChange}
            helperText={helperText}
          />
        ) : (
          <div>{rowData.comment}</div>
        ),
      minWidth: '20rem',
      style: {cursor: 'auto', width: '100%', paddingRight: '3rem'},
    },
    {
      id: 'createdByStaffName',
      label: 'Created By',
      render: (rowData: Comment) => <div>{rowData.createdByStaffName}</div>,
      minWidth: '12rem',
      style: {cursor: 'auto'},
    },
    {
      id: 'lastUpdatedAt',
      label: 'Date',
      render: (rowData: Comment) => (
        <div>
          {format(utcToTZDate(new Date(rowData.lastUpdatedAt ?? Date.now())), 'dd/MM/yyyy')}
        </div>
      ),
      minWidth: '8rem',
      style: {cursor: 'auto'},
    },
    {
      id: 'edit',
      label: '',
      render: (rowData: Comment) => (
        <EditActions
          onEdit={() => handleEdit(rowData)}
          onSave={handleSave}
          onCancel={handleCancel}
          editing={editedComment?.id === rowData.id}
          saveDisabled={hasCommentRequiredError}
        />
      ),
      minWidth: '6rem',
      style: {cursor: 'auto'},
    },
  ];

  return (
    <Stack
      direction="column"
      spacing={5}
      sx={{
        padding: '0 2.5rem',
        maxHeight: 'calc(100vh - 24rem)',
        overflowY: 'scroll',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <Box sx={{fontSize: '1.75rem', fontWeight: 'bold'}}>Comments</Box>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            backgroundColor: '#fff',
            borderColor: '#000',
            fontWeight: 'bold',
            marginRight: '2rem',
          }}
          onClick={handleAddCommentClick}
          disabled={editedCommentIsNew}
        >
          Add Comment
        </Button>
      </Stack>
      <Box sx={{padding: '0 2rem'}}>
        <SimpleDataTable columns={columns} rows={comments} />
      </Box>
    </Stack>
  );
};

export default RebateComments;
