import React, {FC} from 'react';
import nzLogo from '../NZLogo.png';
import auLogo from '../AULogo.png';
import {getDomainConfig, TenantCountry} from '../../utils/domainUtil';

const Logo: FC = () => {
  const {country} = getDomainConfig();
  const headerLogo = country === TenantCountry.NZ ? nzLogo : auLogo;
  return <img alt="logo" src={headerLogo} />;
};

export default Logo;
