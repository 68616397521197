import React, {FC, useState} from 'react';
import {useField} from 'formik';
import {styled} from '@mui/material/styles';
import TextField, {TextFieldProps} from '@mui/material/TextField';

const PREFIX = 'AmountField';

const classes = {
  input: `${PREFIX}-input`,
  warningHelperText: `${PREFIX}-warningHelperText`,
  notchedOutlineWarning: `${PREFIX}-notchedOutlineWarning`,
};

const StyledTextField = styled(TextField)(() => ({
  [`& .${classes.input}`]: {
    height: '16px',
  },

  [`& .${classes.warningHelperText}`]: {
    color: '#ff9800',
  },

  [`& .${classes.notchedOutlineWarning}`]: {
    borderColor: '#ff9800',
  },
}));

type Props = TextFieldProps & {
  warning?: boolean;
  warningText?: string;
  name: string;
};

const AmountField: FC<Props> = ({warning, warningText, name, multiline, ...rest}) => {
  const [field, meta, helpers] = useField({name});
  const {setValue} = helpers;
  const [focus, setFocus] = useState(false);
  const {touched, error} = meta;
  const hasError = touched && error;
  function _renderHelperText() {
    if (hasError) {
      return error;
    }
    if (warning) {
      return warningText;
    }
  }

  const formatValue = () => {
    if (focus) {
      return field.value;
    } else {
      if (field.value === '' || field.value === undefined || field.value === null) {
        return '';
      } else {
        return Number(field.value).toLocaleString(undefined, {minimumFractionDigits: 2});
      }
    }
  };

  const styleProps = {
    InputProps: {
      classes: {
        input: multiline ? '' : classes.input,
        notchedOutline: warning ? classes.notchedOutlineWarning : '',
      },
    },
    FormHelperTextProps: {
      classes: {
        root: !hasError && warning ? classes.warningHelperText : '',
      },
    },
  };
  const fieldAttributes = {
    ...field,
    name,
    value: formatValue(),
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const regex = /^-?\d*\.?\d{0,2}$/;
      if (regex.test(newValue) || newValue === '') {
        setValue(newValue);
      }
    },
    onFocus: (_event: React.FocusEvent<HTMLInputElement>) => {
      setFocus(true);
    },
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
      if (field.onBlur) {
        field.onBlur(event);
      }
      setFocus(false);
    },
  };

  return (
    <StyledTextField
      variant="outlined"
      type="text"
      error={!!meta.touched && !!meta.error}
      helperText={_renderHelperText()}
      {...fieldAttributes}
      {...rest}
      {...styleProps}
    />
  );
};

export default AmountField;
