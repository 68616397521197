import React, {FC} from 'react';
import Button, {ButtonProps} from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

const StyledBackButton = styled(Button)`
  color: ${({theme}) => theme.palette.button.reverse};
  opacity: 1;
  border: none;
  background: none;
  text-transform: none;
  font-size: ${({theme}) => theme.typography.body1.fontSize};
  &:hover {
    background: none;
    opacity: 0.8;
  }
`;

const BackButton: FC<ButtonProps> = (props) => {
  return <StyledBackButton {...props} startIcon={<KeyboardArrowLeft />} />;
};

export default BackButton;
