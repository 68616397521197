/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import * as R from 'ramda';
import {useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode, selectLoggedInStaffDisplayName} from '../../../app/selectors';
import {Owner} from '../../../app/ownersReducer';
import {ContractAgreementFilter} from '../../../app/contractAgreementsReducer';
import {DateType, isValidDate} from '../../../utils/DateUtils';
import FieldLabel from '../../Form/FieldLabel';
import DatePicker from '../../Form/DatePicker';
import SideFilters from '../../SideFilters/SideFilters';
import {LinkButton} from '../../Button';
import {VendorSearch} from '../../Form/Agolia';
import OwnersSearch from '../../Form/OwnersSearch/OwnersSearch';

const FilterGrid = styled(Grid)`
  margin-top: 30px;
`;
const ClearGrid = styled(Grid)`
  margin-top: 10px;
`;

export interface ContractAgreementSideFilterProps {
  my: boolean;
  defaultSelection: ContractAgreementFilter;
  onChange(filter: any): void;
  onClose(): void;
}

const ContractAgreementSideFilter = (props: ContractAgreementSideFilterProps) => {
  const {defaultSelection, onChange, onClose, my} = props;
  const [selection, setSelection] = useState(defaultSelection);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const displayName = useAppSelector(selectLoggedInStaffDisplayName);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const dateIsSelectedCorrectly = (date: string | null) => {
      return date === null || isValidDate(date);
    };
    if (dateIsSelectedCorrectly(selection?.startAt) && dateIsSelectedCorrectly(selection?.endAt)) {
      onChange(selection);
    }
  }, [selection]);

  const filters = [
    {
      label: 'Claim Vendor',
      field: (
        <VendorSearch
          defaultValue={selection.claimVendor}
          onChange={(item) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, claimVendor: item};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Start Date',
      field: (
        <DatePicker
          value={selection.startAt}
          dateType={DateType.RangeStart}
          placeholder={'Choose a start date'}
          onChanged={(value: string) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, startAt: value};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'End Date',
      field: (
        <DatePicker
          dateType={DateType.RangeEnd}
          value={selection.endAt}
          placeholder={'Choose an end date'}
          onChanged={(value: any) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, endAt: value};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Owner',
      field: (
        <OwnersSearch
          value={my ? {name: displayName ?? '', code: loggedInStaffCode} : selection.owner}
          my={my}
          onChange={(_: any, option: Owner | null) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                owner: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
  ];

  const onClear = () => {
    setSelection((prevSelection) => {
      const newSelection = R.mapObjIndexed(
        (value, key, obj) => null,
        prevSelection
      ) as ContractAgreementFilter;
      if (my) {
        newSelection.owner = prevSelection.owner;
      }
      return newSelection;
    });
  };

  const anyFilterHasValue = () => {
    return R.any((x) => !!x, R.values(selection));
  };
  const relevantFiltersHaveValue = () => {
    if (my) {
      const {owner, ...selectionWithoutOwners} = selection;
      return R.any((x) => !!x, R.values(selectionWithoutOwners));
    }
    return anyFilterHasValue();
  };

  return (
    <SideFilters onClose={onClose}>
      {relevantFiltersHaveValue() && (
        <ClearGrid container justifyContent="flex-end">
          <LinkButton name="clearFilters" onClick={onClear}>
            Clear
          </LinkButton>
        </ClearGrid>
      )}

      {filters.map((item) => (
        <FilterGrid key={item.label}>
          <FieldLabel>{item.label}</FieldLabel>
          {item.field}
        </FilterGrid>
      ))}
    </SideFilters>
  );
};

export default ContractAgreementSideFilter;
