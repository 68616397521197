import React, {FC, useContext, useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {api, del, get} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {RebateFormValues, RebateFormBag, AttachmentType} from '../../types';
import FieldGrid from '../Form/FieldGrid';
import FieldLabel from '../Form/FieldLabel';
import FormStep from '../Form/FormStep';
import OptionalIndicator from '../Form/OptionalIndicator';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import FilesUploader from '../DragDrop/FilesUploader';
import Attachment from '../Attachment/Attachment';

type SavedRebateValues = Omit<RebateFormValues, 'id'> & {id: number};
type Bag = RebateFormBag<SavedRebateValues>;

type Props = {
  step: number;
  title: string;
  validationSchema: any; //required
  totalStep: number;
  handleBack: (values: SavedRebateValues, bag: Bag) => void;
  handleNext: (values: SavedRebateValues, bag: Bag) => void;
  style?: React.CSSProperties;
};

type UploadResponse = AxiosResponse<AttachmentType[]>;

const StepTagsAndAttachments: FC<Props> = ({
  step,
  title,
  validationSchema,
  totalStep,
  handleBack,
  handleNext,
}) => {
  const bag = useFormikContext<SavedRebateValues>();
  const {
    values: {id},
  } = bag;

  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.RebateAttachments.replace(':rebateId', `${id}`)))
      .then((response: UploadResponse) => {
        setAttachments(response.data);
        alertService.clear(defaultAlertId);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [id, hideLoading, showLoading]);

  function uploadComplete(response: UploadResponse) {
    setAttachments((prevAttachments) => prevAttachments.concat(response.data));
  }

  function deleteAttachment(attachmentId: AttachmentType['id']) {
    showLoading();
    del(api(Consts.Api.RebateAttachment).replace(':rebateId', id).replace(':id', attachmentId))
      .then(() => {
        setAttachments((prevAttachments) => prevAttachments.filter((x) => x.id !== attachmentId));
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }

  return (
    <>
      <FormStep step={step} title={title}>
        <FieldGrid item xs={12}>
          <FieldLabel fullWidth>
            Add Attachments
            <OptionalIndicator />
          </FieldLabel>
          {attachments !== null ? (
            <FilesUploader
              maxSizeInMb={20}
              multiple
              apiEndpoint={api(Consts.Api.RebateAttachments.replace(':rebateId', `${id}`))}
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'image/jpg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // for docx
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
                'message/rfc822': ['.eml'], // for eml
                'text/csv': ['.csv'], // for csv
                'application/vnd.ms-outlook': ['.msg'], // for msg
              }}
              title="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
              onComplete={uploadComplete}
              noFiles={attachments.length === 0}
            >
              <div>
                {attachments.map((attachment, index) => (
                  <Attachment key={index} fileName={attachment.fileName}>
                    <IconButton
                      onClick={() => {
                        deleteAttachment(attachment.id);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Attachment>
                ))}
              </div>
            </FilesUploader>
          ) : null}
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={handleNext}
        step={step}
        totalStep={totalStep}
      />
    </>
  );
};

export default StepTagsAndAttachments;
