import React, {useState, useContext, useRef} from 'react';
import {useField, useFormikContext} from 'formik';
import * as R from 'ramda';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ProductSelector from './Agolia/ProductSelector';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {api, get, post} from '../../utils/Request';
import {ErrorBox} from '../Alert';
import ClearRebateSkuSelectionConfirmModal from '../Modal/ClearRebateSkuSelectionConfirmModal';
import {ExternalLinkIcon, WarnIcon} from '../Icons';
import {FileUploader} from '../FileUploader';
import BulkUploadWarningModal from '../Modal/BulkUploadWarningModal';
import {RebateSkuBulkUploadErrorModal} from '../Modal';
import {BulkUploadIconButton, LinkButton, StaticButton} from '../Button';
import StaticField from './StaticField';
import BulkUploadConfirmModal from '../Modal/BulkUploadConfirmModal';

const PREFIX = 'ConnectedProductField';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
};

const Root = styled('div')(() => ({
  [`& .${classes.tooltip}`]: {
    maxHeight: '400px',
    maxWidth: '500px',
    overflow: 'auto',
  },
}));

const ProductGrid = styled(Grid)`
  padding: 30px 0 0 0;
`;

const ProductField = ({productSelectorMode, onOpenTemplate = () => {}, ...props}) => {
  const [productSelectorOpen, setProductSelectorOpen] = useState(false);
  const [clearSkuSelectionConfirmModalOpen, setClearSkuSelectionConfirmModalOpen] = useState(false);
  const [clearExistingDuringBulkUpload, setClearExistingDuringBulkUpload] = useState(false);
  const [bulkUploadConfirmModalOpen, setBulkUploadConfirmModalOpen] = useState(false);
  const [bulkUploadResponse, setBulkUploadResponse] = useState({});
  const uploadElementRef = useRef(null);
  const [bulkUploadWarningModalOpen, setBulkUploadWarningModalOpen] = useState(false);
  const [bulkUploadErrorModalOpen, setBulkUploadErrorModalOpen] = useState(false);
  const [field, meta, helpers] = useField(props);
  const {setValue} = helpers;
  const applyCriteria = (criteria) => {
    setValue(criteria);
  };
  const bag = useFormikContext();
  const {showLoading, hideLoading} = useContext(LoadingContext);

  const {
    setFieldValue,
    values: {
      uploadedProductCount,
      id,
      suppliers,
      departments,
      departmentNumber,
      products,
      claimVendorSuppliers,
    },
  } = bag;
  const downloadSkus = () => {
    showLoading();
    get(api(Consts.Api.RebateProductDownload.replace(':id', id)), {
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = `Skus of rebate ${id}.xlsx`;
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };
  const cancelSkuSelectionChange = () => {
    setClearSkuSelectionConfirmModalOpen(false);
  };
  const confirmSkuSelectionChange = () => {
    setClearSkuSelectionConfirmModalOpen(false);
    setProductSelectorOpen(true);
  };
  const clickProductSelectionTool = () => {
    if (uploadedProductCount > 0) {
      setClearSkuSelectionConfirmModalOpen(true);
    } else {
      setProductSelectorOpen(true);
    }
  };
  async function bulkUploadSkus(file) {
    let data = new FormData();
    data.append('file', file);

    showLoading();
    post(api(Consts.Api.RebateProducts.replace(':id', id)), data, {
      params: {
        suppliers: suppliers.map((x) => x.number),
        clearExisting: clearExistingDuringBulkUpload,
      },
      // https://github.com/axios/axios/issues/4406
      transformRequest: (data) => data,
    })
      .then((response) => {
        hideLoading();
        alertService.clear(defaultAlertId);
        const data = response.data;
        const rebate = data.rebate;
        setFieldValue('productCriteria', rebate.productCriteria);
        setFieldValue('uploadedProductCount', rebate.uploadedProductCount);
        setFieldValue('products', rebate.products);
        setBulkUploadResponse(response);
        var warning = data.warning;
        if (warning && warning.lineWarnings.length > 0) {
          setBulkUploadWarningModalOpen(true);
        }
      })
      .catch(function (error) {
        hideLoading();
        if (error.response && error.response.status === 403) {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        } else {
          let response = error.response;
          setBulkUploadResponse(response);
          setBulkUploadErrorModalOpen(true);
        }
      });
  }
  const getProductResult = () => {
    let productResult = 'Please select SKUs';
    const productCriteria = field.value;
    if (productSelectorMode && productCriteria && productCriteria.resultCount) {
      productResult = `${productCriteria.resultCount} ${
        productCriteria.resultCount === 1 ? 'SKU' : 'SKUs'
      } selected`;
    }
    if (!productSelectorMode && uploadedProductCount > 0) {
      productResult = `${uploadedProductCount} ${
        uploadedProductCount === 1 ? 'SKU' : 'SKUs'
      } selected`;
    }
    return productResult;
  };
  const getSupplierMismatchSkus = () => {
    if (products && products.length > 0 && suppliers.length > 0) {
      const selectedSuppliers = suppliers.map((x) => x.number);
      return products.filter((x) => R.intersection(selectedSuppliers, x.suppliers).length === 0);
    }
    return [];
  };
  const supplierMismatchSkus = getSupplierMismatchSkus();
  const handleBulkUpload = () => {
    if (uploadedProductCount > 0) {
      setBulkUploadConfirmModalOpen(true);
    } else {
      uploadElementRef.current.click();
    }
  };
  return (
    <Root>
      <ProductGrid>
        <Grid container justifyContent="space-between" alignItems="center">
          {productSelectorMode ? (
            <StaticButton startIcon={<ExternalLinkIcon />} onClick={clickProductSelectionTool}>
              Select products
            </StaticButton>
          ) : (
            <FileUploader
              accept={{'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []}}
              component={BulkUploadIconButton}
              elementRef={uploadElementRef}
              handleFile={bulkUploadSkus}
              style={{width: 'unset', height: 'unset'}}
              confirm={field.value !== null ? ClearRebateSkuSelectionConfirmModal : null}
              clickUpload={field.value === null && handleBulkUpload}
            />
          )}
          <Box flexGrow={1}>
            <Grid container alignItems="center">
              {!productSelectorMode && supplierMismatchSkus.length > 0 ? (
                <Tooltip
                  classes={classes}
                  interactive
                  placement="top-start"
                  title={
                    <>
                      {supplierMismatchSkus.map((x, index) => (
                        <div key={index}>
                          {x.productCode} - {Consts.ValidationMessage.ProductSupplierMismatch}
                        </div>
                      ))}
                    </>
                  }
                >
                  <WarnIcon />
                </Tooltip>
              ) : null}
              <StaticField
                name={field.name}
                sx={{flex: 1, marginLeft: '5px'}}
                value={getProductResult()}
              />
            </Grid>
          </Box>
          {!productSelectorMode && !field.value && uploadedProductCount > 0 && (
            <LinkButton onClick={downloadSkus}>Download SKUs</LinkButton>
          )}
        </Grid>
        {!!meta.error && !!meta.touched && (
          <Grid item xs={12}>
            <ErrorBox id={`${field.name}-helper-text`}>{meta.error}</ErrorBox>
          </Grid>
        )}
      </ProductGrid>
      {productSelectorOpen && (
        <ProductSelector
          fullScreen
          productCriteria={field.value}
          defaultBuyerDepartments={
            departments && departments.length > 0 && departmentNumber
              ? departments.filter((x) => x.number === departmentNumber)
              : []
          }
          defaultSuppliers={claimVendorSuppliers}
          open={productSelectorOpen}
          handleClose={() => setProductSelectorOpen(false)}
          onApplyCriteria={(criteria) => {
            setProductSelectorOpen(false);
            applyCriteria(criteria);
            setFieldValue('uploadedProductCount', 0);
            setFieldValue('skuWarnings', []);
          }}
        />
      )}
      <ClearRebateSkuSelectionConfirmModal
        onCancel={cancelSkuSelectionChange}
        onOk={confirmSkuSelectionChange}
        open={clearSkuSelectionConfirmModalOpen}
      />
      <BulkUploadWarningModal
        open={bulkUploadWarningModalOpen}
        response={bulkUploadResponse}
        onReupload={() => {
          setBulkUploadWarningModalOpen(false);
          uploadElementRef.current.click();
        }}
        onClose={() => {
          setBulkUploadWarningModalOpen(false);
        }}
      />
      <RebateSkuBulkUploadErrorModal
        open={bulkUploadErrorModalOpen}
        response={bulkUploadResponse}
        onClose={() => {
          setBulkUploadErrorModalOpen(false);
        }}
        onOpenTemplate={() => {
          setBulkUploadErrorModalOpen(false);
          onOpenTemplate();
        }}
      />
      <BulkUploadConfirmModal
        open={bulkUploadConfirmModalOpen}
        onKeepAdding={() => {
          setClearExistingDuringBulkUpload(false);
          setBulkUploadConfirmModalOpen(false);
          uploadElementRef.current.click();
        }}
        onReplace={() => {
          setClearExistingDuringBulkUpload(true);
          setBulkUploadConfirmModalOpen(false);
          uploadElementRef.current.click();
        }}
        onCancel={() => {
          setBulkUploadConfirmModalOpen(false);
        }}
      />
    </Root>
  );
};

export default ProductField;
