import {createTheme} from '@mui/material/styles';

type DomainSpecificPalette = {
  addButton: {background: string; color: string};
  header: {background: string};
  userButton: {background: string; border: string; content: string};
  userProfile: {name: string; role: string};
  envHeader: {color: string};
};

interface PaletteCustomisations {
  black: {main: string};
  blue: {main: string; light: string};
  button: {main: string; reverse: string};
  drawerBorder: {main: string};
  expandArrow: {main: string};
  expandHint: {main: string};
  gray: {mid: string; extraLight: string; light: string; lightMid: string; dark: string};
  green: {main: string; light: string; tint: string};
  link: {main: string};
  navLink: {main: string};
  orange: {main: string; tint: string};
  placeholder: {main: string};
  red: {main: string; light: string; tint: string};
  sectionDivider: {main: string};
  white: {main: string};
  domainSpecific: DomainSpecificPalette;
}

interface ZIndexCustomisations {
  popper: number;
}

export interface TypeBackgroundCustomisations {
  page: string;
  pageContent: string;
  button: string;
  stepIcon: string;
}

declare module '@mui/material/styles' {
  interface TypeBackground extends TypeBackgroundCustomisations {}
  interface Palette extends PaletteCustomisations {}
  interface PaletteOptions extends PaletteCustomisations {}
  interface ZIndex extends ZIndexCustomisations {}
}

export const colours = {
  primary: {
    200: 'rgba(255, 236, 15, 0.2)', //jb yellow 20% opacity
    light: '#FFF589',
    main: '#FFEC0F', //jb yellow
    dark: '#E5D62B',
  },
  secondary: {
    light: '#E5D62B',
    main: '#AFA206',
    dark: '#8B8003',
  },
  error: {
    main: '#D0021B',
  },
  white: {
    main: '#FFFFFF',
  },
  gray: {
    extraLight: '#F7F9FA',
    light: '#F2F4F5',
    lightMid: '#CBD2D8',
    mid: '#9AA4B1',
    dark: '#626262',
  },
  black: {
    main: '#000000',
  },
  red: {
    main: '#E02020',
    light: '#D0021B1A',
    tint: '#FDF2F2',
  },
  green: {
    main: '#028702',
    light: '#E6F2E6',
    tint: '#F2F9F2',
  },
  blue: {
    main: '#005EAB',
    light: '#E3F2FD',
  },
  orange: {
    main: '#DA6A00',
    tint: '#FFF9F0',
  },
};

export const auTheme = createTheme({
  palette: {
    ...colours,
    navLink: {
      main: colours.gray.dark,
    },
    button: {
      main: colours.white.main,
      reverse: colours.black.main,
    },
    background: {
      default: colours.gray.light,
      page: colours.gray.light,
      pageContent: 'rgba(255,255,255,0.85)',
      button: '#050505',
      stepIcon: '#D7DFE2',
    },
    placeholder: {
      main: '#A5A5A5',
    },
    expandHint: {
      main: colours.orange.main,
    },
    link: {
      main: colours.orange.main,
    },
    expandArrow: {
      main: colours.secondary.main,
    },
    drawerBorder: {
      main: '#00001A',
    },
    sectionDivider: {
      main: colours.gray.lightMid,
    },
    domainSpecific: {
      addButton: {
        background: colours.black.main,
        color: colours.white.main,
      },
      header: {
        background: colours.primary.main,
      },
      userButton: {
        background: colours.primary.light,
        border: '#E6D719',
        content: colours.secondary.main,
      },
      userProfile: {
        name: colours.black.main,
        role: colours.secondary.dark,
      },
      envHeader: {
        color: colours.black.main,
      },
    },
  },
  typography: {
    h4: {
      fontSize: '1.875rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.625rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.375rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  zIndex: {
    popper: 2000,
  },
  mixins: {
    toolbar: {
      minHeight: '7.5rem',
      height: '7.5rem',
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        icon: {
          top: 'calc(50% - 10px)',
        },
        select: {
          minHeight: '1rem',
          height: '1rem',
          lineHeight: '1rem',
          '&.Mui-disabled': {
            backgroundColor: colours.gray.light,
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.60)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => ({
          letterSpacing: 'unset',
          color: 'rgba(0, 0, 0, 0.87)',
          height: '3.25rem',
          borderRadius: 'unset',
          fontSize: '1rem',
          whiteSpace: 'nowrap',
          '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          ...(ownerState?.variant === 'outlined' && {
            border: '1px solid rgba(0, 0, 0, 0.23)',
          }),
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          '&:after': {
            borderBottom: '2px solid #000',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: colours.gray.lightMid,
        },
        root: {
          borderRadius: 'unset',
          backgroundColor: colours.white.main,
          '&.Mui-disabled': {
            backgroundColor: colours.gray.light,
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        input: {
          height: '1rem',
          '&.Mui-disabled': {
            backgroundColor: colours.gray.light,
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.60)',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: colours.green.main,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          marginTop: '0.4375rem',
          letterSpacing: 'unset',
          '&.Mui-error': {
            color: colours.error.main,
          },
        },
        contained: {
          marginLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: colours.green.main,
          },
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          lineHeight: '1.625rem',
          marginTop: '0.625rem',
          padding: '1rem 1.625rem',
          alignItems: 'center',
        },
        icon: {
          '& >svg': {
            width: 35,
            height: 35,
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          lineHeight: '2.1975rem',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'unset',
          backgroundColor: 'unset',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        },
        head: {
          lineHeight: 'normal',
          letterSpacing: 'unset',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            background: 'rgba(0, 0, 0, 0.08)',
          },
        },
      },
    },
  },
});

export const nzTheme = createTheme({
  ...auTheme,
  palette: {
    ...auTheme.palette,
    domainSpecific: {
      addButton: {
        background: colours.white.main,
        color: colours.black.main,
      },
      header: {
        background: colours.black.main,
      },
      userButton: {
        background: colours.gray.dark,
        border: colours.gray.dark,
        content: colours.white.main,
      },
      userProfile: {
        name: colours.white.main,
        role: colours.white.main,
      },
      envHeader: {
        color: colours.white.main,
      },
    },
  },
});
