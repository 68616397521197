import * as msal from '@azure/msal-browser';

const config = {
  auth: {
    authority: process.env.REACT_APP_APP_AUTHORITY,
    clientId: process.env.REACT_APP_APP_ID,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
  redirectUri: window.location.origin,
};

const graphTokenRequest = {
  scopes: ['User.Read'],
  redirectUri: window.location.origin,
};

const apiTokenRequest = {
  scopes: [process.env.REACT_APP_API_REQUEST_TOKEN],
  redirectUri: window.location.origin,
};

const LoginType = {
  LoginRedirect: 0,
  LoginPopup: 1,
};

const AuthenticationState = {
  Authenticated: 'Authenticated',
  UnAuthenticated: 'UnAuthenticated',
  InProgress: 'InProgress',
};

class AuthProvider extends msal.PublicClientApplication {
  constructor(logintype, config) {
    super(config);
    this.loginType = logintype;
    this.authenticationState = AuthenticationState.UnAuthenticated;
    this.username = null;
  }

  getAuthAccount() {
    if (this.username) {
      return this.getAccount({username: this.username});
    }
    const accounts = super.getAllAccounts();
    if (accounts && accounts.length === 1) {
      this.username = accounts[0].username;
      return accounts[0];
    }
  }

  //This is used to get token for api call.
  async getToken(request, account) {
    const requestAccount = account || this.getAuthAccount();

    request = {...request, account: requestAccount};

    return super.acquireTokenSilent(request).catch((error) => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        if (this.loginType === LoginType.LoginRedirect) {
          return super.acquireTokenRedirect(request);
        } else {
          return super.acquireTokenPopup(request);
        }
      }
      throw error;
    });
  }

  _handleResponse(tokenResponse) {
    if (tokenResponse) {
      this.authenticationState = AuthenticationState.Authenticated;
      return tokenResponse;
    }
    const account = this.getAuthAccount();
    if (account) {
      this.authenticationState = AuthenticationState.InProgress;
      return this.getToken(loginRequest, account)
        .then((response) => this._handleResponse(response))
        .catch((error) => {
          if (error instanceof msal.AuthError) {
            // eslint-disable-next-line no-console
            console.error('Error occured during getToken', error);
          }
          throw error;
        });
    }
    return null;
  }

  // use this promise to handle the response and error.
  async handleRedirectPromise() {
    return super.handleRedirectPromise().then((response) => this._handleResponse(response));
  }

  async login() {
    if (this.loginType === LoginType.LoginPopup) {
      return super.loginPopup(loginRequest);
    } else if (this.loginType === LoginType.LoginRedirect) {
      return super.loginRedirect(loginRequest);
    }
  }

  async logout() {
    const logoutRequest = {
      account: super.getAccount({username: this.username}),
    };
    super.logoutRedirect(logoutRequest);
  }
}

const authProvider = new AuthProvider(LoginType.LoginRedirect, config);
await authProvider.initialize();

export {authProvider, graphTokenRequest, loginRequest, apiTokenRequest, AuthenticationState};
