import React from 'react';
import {connectCurrentRefinements} from 'react-instantsearch-core';
import {LinkButton} from '../../Button';

const ClearRefinements = ({items, refine, onClearCriteria}) => {
  if (items.length === 0) {
    return null;
  }
  return (
    <LinkButton
      onClick={() => {
        refine(items);
        onClearCriteria();
      }}
    >
      Clear all selected criteria
    </LinkButton>
  );
};

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);
export default CustomClearRefinements;
