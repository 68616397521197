import React, {FC, useEffect} from 'react';
import {useBlocker} from 'react-router-dom';

type Props = {
  condition: boolean;
  message: () => void;
};

const Prompt: FC<Props> = ({condition, message}) => {
  const blocker = useBlocker(condition);
  useEffect(() => {
    try {
      if (blocker.state === 'unblocked') {
        return;
      }
      if (blocker.state === 'blocked') {
        blocker?.proceed();
        message();
      }
    } catch (error) {}
  }, [blocker, message]);

  return <div data-testid="route-change-prompt" />;
};

export default Prompt;
