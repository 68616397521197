import React, {FC, useContext, useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {api, del, get} from '../../utils/Request';
import {DealFormValues, DealFormBag, AttachmentType} from '../../types';
import FieldGrid from '../Form/FieldGrid';
import FieldLabel from '../Form/FieldLabel';
import FormStep from '../Form/FormStep';
import OptionalIndicator from '../Form/OptionalIndicator';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import FilesUploader from '../DragDrop/FilesUploader';
import Attachment from '../Attachment/Attachment';

type Props = {
  step: number;
  title: string;
  validationSchema: any; //required
  totalStep: number;
  handleBack: (values: DealFormValues, bag: DealFormBag) => void;
  handleNext: (values: DealFormValues, bag: DealFormBag) => void;
  style?: React.CSSProperties;
};

type UploadResponse = AxiosResponse<AttachmentType[]>;

const StepTagsAndAttachments: FC<Props> = ({step, title, totalStep, handleBack, handleNext}) => {
  const bag = useFormikContext<DealFormValues>();
  const {
    values: {id},
  } = bag;
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }
    showLoading();
    get(api(Consts.Api.DealAttachments.replace(':dealId', `${id}`)))
      .then((response: UploadResponse) => {
        setAttachments(response.data);
        alertService.clear(defaultAlertId);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [id, hideLoading, showLoading]);

  function uploadComplete(response: UploadResponse) {
    setAttachments((prevAttachments) => prevAttachments.concat(response.data));
  }

  function deleteAttachment(attachmentId: AttachmentType['id']) {
    del(api(Consts.Api.DealAttachment).replace(':dealId', id).replace(':id', attachmentId))
      .then(() => {
        setAttachments((prevAttachments) => prevAttachments.filter((x) => x.id !== attachmentId));
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }

  return (
    <>
      <FormStep step={step} title={title}>
        <FieldGrid item xs={12}>
          <FieldLabel fullWidth>
            Add Attachments
            <OptionalIndicator />
          </FieldLabel>
          {attachments !== null && (
            <FilesUploader
              maxSizeInMb={20}
              multiple={true}
              apiEndpoint={api(Consts.Api.DealAttachments.replace(':dealId', `${id}`))}
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'image/jpg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // for docx
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
                'message/rfc822': ['.eml'], // for eml
                'text/csv': ['.csv'], // for csv
                'application/vnd.ms-outlook': ['.msg'], // for msg
              }}
              title="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
              onComplete={uploadComplete}
              noFiles={attachments.length === 0}
            >
              <div>
                {attachments.map((attachment, index) => {
                  return (
                    <Attachment key={index} fileName={attachment.fileName}>
                      <IconButton
                        onClick={() => {
                          deleteAttachment(attachment.id);
                        }}
                        size="large"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Attachment>
                  );
                })}
              </div>
            </FilesUploader>
          )}
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={handleBack}
        handleNext={handleNext}
        step={step}
        totalStep={totalStep}
      />
    </>
  );
};

export default StepTagsAndAttachments;
