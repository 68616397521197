import * as Yup from 'yup';
import Consts from '../../../app/Consts';
import {formatDate} from '../../../utils/DateUtils';

export const stepSPIVDetailsValidationSchema = Yup.object({
  claimVendorName: Yup.string(),
  claimVendorActive: Yup.boolean(),
  claimVendorCode: Yup.string().required(Consts.ValidationMessage.Required),
  description: Yup.string()
    .required(Consts.ValidationMessage.Required)
    .test(
      'desc-control-validation',
      Consts.ValidationMessage.DealNonPrintableCharsError,
      function (value) {
        // eslint-disable-next-line no-control-regex
        return !/[\u0000-\u001F\u007F]/g.test(value ?? '');
      }
    ),
  departmentNumber: Yup.string().nullable().required(Consts.ValidationMessage.Required),
  startAt: Yup.date()
    .required(Consts.ValidationMessage.Required)
    .nullable()
    .typeError(Consts.ValidationMessage.Date.FormatError)
    .test(
      'cut-off date check',
      'The start date cannot be set before the cut-off date',
      function (value) {
        const cutoffDate = this.parent[Consts.ConfigNameEnum.DealCutoffDate];
        const lockDate = this.parent[Consts.ConfigNameEnum.DealLockDate];
        if (cutoffDate && lockDate && value && new Date() > lockDate && value < cutoffDate) {
          return this.createError({
            message: `The start date cannot be set before the cut-off date (${formatDate(
              cutoffDate
            )})`,
          });
        }
        return true;
      }
    ),
  endAt: Yup.date()
    .min(Yup.ref('startAt'), Consts.ValidationMessage.Date.EndDateRangeError)
    .max(Consts.Date.MaxEndDateMonthsFromNow, Consts.ValidationMessage.Date.MaxEndDateRangeError)
    .required(Consts.ValidationMessage.Required)
    .nullable()
    .typeError(Consts.ValidationMessage.Date.FormatError),
});
