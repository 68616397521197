import React from 'react';
import {ConfirmModal} from './Modal';

const FinanceAccountClearModal = ({open, onClose}) => {
  return (
    <ConfirmModal title="Finance Account Cleared" open={open} onOk={onClose} disableBackdropClick>
      The finance account(s) that you selected were cleared because of your changes, please
      re-select on the "Finance Details" page.
    </ConfirmModal>
  );
};

export default FinanceAccountClearModal;
