import React from 'react';
import {styled} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const SpinnerContainer = styled('div')`
  position: absolute;
  left: 0;
  z-index: 4000;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = ({loading}) => {
  return loading ? (
    <SpinnerContainer>
      <CircularProgress size={60} />
    </SpinnerContainer>
  ) : null;
};

export default Spinner;
