import React, {useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import {useAppSelector} from '../../app/store';
import {selectProfileData} from '../../app/selectors';
import {authProvider} from '../../utils/AuthProvider';

const UserName = styled('div')`
  color: ${(props) => props.theme.palette.domainSpecific.userProfile.name};
  font-size: 1.125rem;
  margin-bottom: 0.1875rem;
  white-space: nowrap;
`;

const UserRole = styled('div')`
  color: ${(props) => props.theme.palette.domainSpecific.userProfile.role};
  font-size: 0.875rem;
`;

const UserButton = styled(Button)`
  background: ${(props) => props.theme.palette.domainSpecific.userButton.background};
  width: 3.4375rem;
  height: 3.4375rem;
  border: 2px solid ${(props) => props.theme.palette.domainSpecific.userButton.border};
  border-radius: 1rem;
  opacity: 1;
  transform: matrix(1, 0.02, -0.02, 1, 0, 0);
  color: ${(props) => props.theme.palette.domainSpecific.userButton.content};
  min-width: auto;
  &.MuiButton-text {
    padding: 6px 8px;
  }
`;

const StyledUserProfile = styled(Grid)`
  float: right;
`;

const UserProfile = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const profileData = useAppSelector(selectProfileData);

  function handleClick(_event: React.MouseEvent<HTMLButtonElement>) {
    setOpen((prevOpen) => !prevOpen);
  }

  const handleClose = () => {
    setOpen(false);
  };

  function logout() {
    authProvider.logout();
  }

  function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <StyledUserProfile
      container
      spacing={4}
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Grid item>
        <UserName>{profileData.displayName}</UserName>
        <UserRole>{profileData.jobTitle}</UserRole>
      </Grid>
      <Grid item>
        <UserButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </UserButton>

        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={logout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </StyledUserProfile>
  );
};

export default UserProfile;
