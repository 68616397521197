import React, {FC} from 'react';

import {styled} from '@mui/material/styles';

const WarnInfoContainer = styled('div')`
  text-align: center;
  margin-top: 1.875rem;
`;
const WarnInfoList = styled('div')`
  display: inline-block;
  text-align: left;
`;
const WarnInfo = styled('div')`
  display: flex;
  align-items: center;
  > span {
    font-size: 1rem;
    margin-left: 0.25rem;
  }
`;

type Props = {
  warns?: {Icon: React.ReactNode; Message: string}[];
};

const ClaimWarnList: FC<Props> = ({warns = []}) => {
  if (warns.length === 0) {
    return null;
  }
  return (
    <WarnInfoContainer>
      <WarnInfoList>
        {warns.map((x, index) => (
          <WarnInfo key={index}>
            {x.Icon} <span>{x.Message}</span>
          </WarnInfo>
        ))}
      </WarnInfoList>
    </WarnInfoContainer>
  );
};

export default ClaimWarnList;
