import React, {FC, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import {TimeField as MUITimeField, TimeFieldProps} from '@mui/x-date-pickers/TimeField';
import {useField, useFormikContext} from 'formik';
import isValid from 'date-fns/isValid';
import * as Yup from 'yup';
import {utcToTZDate, formatToISOByTZ} from '../../utils/DateUtils';

const PREFIX = 'TimeField';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    marginTop: 0,
    marginBottom: 0,
  },

  [`& .${classes.icon}`]: {
    padding: 0,
  },
}));

type Props = Omit<TimeFieldProps<Date>, 'name' | 'placeholder'> & {
  name: string;
  placeholder?: string;
  errorText?: string;
  validationSchema?:
    | any
    | ((props: {startTime?: MUIParsableDate; endTime?: MUIParsableDate}) => any);
  onChanged?: (date: MUIParsableDate) => void;
};

// formik value/setValue should always be utc date string
const TimeField: FC<Props> = ({
  name,
  placeholder,
  fullWidth,
  validationSchema,
  disabled,
  errorText,
  onChanged,
}) => {
  type FormType = Yup.InferType<typeof validationSchema>;
  const {values} = useFormikContext<FormType>();
  const [field, meta, helpers] = useField({name});
  const {onBlur, ...restField} = field;
  const {setValue} = helpers;

  const atDate =
    name === 'startTime' ? values.startAt : values.endAt ?? formatToISOByTZ(new Date());

  const inputDisabled = useMemo(() => {
    if (disabled) {
      return true;
    } else if (!isValid(new Date(atDate))) {
      if (Boolean(field.value)) {
        setValue(null);
      }
      return true;
    }
  }, [atDate, setValue, field.value, disabled]);

  const helperText = useMemo(() => {
    const {touched, error} = meta;
    if (touched && error) {
      return error;
    }
    if (errorText) {
      return errorText;
    }
  }, [meta, errorText]);

  // on validation error, set to initial value unless that is also invalid, then set to null
  const setToInitialOrNull = async () => {
    try {
      await validationSchema?.validate({[name]: meta.initialValue});
      setValue(meta.initialValue);
    } catch (error) {
      setValue(null);
    }
  };

  const handleChange = (value: Date | string | null) => {
    if (value === null) {
      setValue(null);
      return;
    }
    const valueAsDate = new Date(value);
    const hours = Number(valueAsDate.getHours());
    const minutes = Number(valueAsDate.getMinutes());
    const timeValue = new Date(atDate);
    if (isValid(timeValue) && isValid(value)) {
      timeValue.setHours(hours);
      timeValue.setMinutes(minutes);
      setValue(formatToISOByTZ(timeValue));
      onChanged?.(formatToISOByTZ(timeValue));
    } else if (isValid(new Date(value))) {
      setValue(formatToISOByTZ(new Date(value)));
      onChanged?.(formatToISOByTZ(new Date(value)));
    }
  };

  const handleInputBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const {value} = event.target;
    const timeValue = new Date(atDate);
    const [time, period] = value.split(' ');
    const [hrString, minString] = time.split(':');

    let hours = parseInt(hrString);
    const minutes = parseInt(minString);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    timeValue.setHours(hours);
    timeValue.setMinutes(minutes);

    if (!isValid(timeValue)) {
      setValue(null);
      return;
    }
    try {
      await validationSchema?.validate({[name]: timeValue});
      setValue(formatToISOByTZ(timeValue));
    } catch (error) {
      setToInitialOrNull();
    }
  };

  const formAttributes = {
    ...restField,
    value: field.value ? utcToTZDate(field.value) : null,
    onChange: handleChange,
  };
  return (
    <Root>
      <MUITimeField
        fullWidth={fullWidth}
        hiddenLabel
        onBlur={handleInputBlur}
        slotProps={{
          textField: {
            placeholder,
            helperText,
            error: !!helperText,
            disabled: inputDisabled,
          },
        }}
        disabled={inputDisabled}
        {...formAttributes}
      />
    </Root>
  );
};

export default TimeField;
