import React from 'react';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import {
  DealsIcon,
  ContractAgreementIcon,
  ClaimsIcon,
  HomeIcon,
  DraftIcon,
  RebatesIcon,
  WorldIcon,
  AdjustmentsIcon,
  UnreleasedAmountsIcon,
} from '../Icons';
import NavList, {NavItem} from './NavList';
import Consts from '../../app/Consts';
import {hasAnyRole, hasRole} from '../../app/Permission';
import {useAppSelector} from '../../app/store';
import {selectProfileRoles} from '../../app/selectors';

const PREFIX = 'LeftNav';

const classes = {
  appBar: `${PREFIX}-appBar`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerContainer: `${PREFIX}-drawerContainer`,
  divider: `${PREFIX}-divider`,
  content: `${PREFIX}-content`,
  icon: `${PREFIX}-icon`,
  iconWorld: `${PREFIX}-iconWorld`,
  draftIcon: `${PREFIX}-draftIcon`,
  claimsIcon: `${PREFIX}-claimsIcon`,
  adjustmentIcon: `${PREFIX}-adjustmentIcon`,
  unreleasedAmountsIcon: `${PREFIX}-unreleasedAmountsIcon`,
};

const StyledDrawer = styled(Drawer)(({theme}) => ({
  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    borderRight: '1px solid #0000001a',
  },

  [`& .${classes.drawerPaper}`]: {
    width: `calc(${drawerWidth}px - 1px)`,
    position: 'unset',
  },

  [`& .${classes.drawerContainer}`]: {
    overflow: 'auto',
  },

  [`& .${classes.divider}`]: {
    marginTop: '32px',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  [`& .${classes.icon}`]: {
    height: 19,
  },

  [`& .${classes.iconWorld}`]: {
    height: 24,
  },

  [`& .${classes.draftIcon}`]: {
    height: 16,
    paddingLeft: '5px',
  },

  [`& .${classes.claimsIcon}`]: {
    height: 19,
    paddingLeft: '3px',
  },

  [`& .${classes.adjustmentIcon}`]: {
    width: 28,
    height: 30,
  },

  [`& .${classes.unreleasedAmountsIcon}`]: {
    height: 24,
    width: 24,
  },
}));

const drawerWidth = 300;

function LeftNav() {
  const roles = useAppSelector(selectProfileRoles) || [];

  const myHomeLinks: NavItem[] = [
    {
      link: Consts.RouterPath.MyDeals,
      text: 'My Deals',
      icon: <DealsIcon className={classes.icon} />,
      show: hasRole(Consts.UserRoleEnum.ViewDealsAndContractAgreements, roles),
    },
    {
      link: Consts.RouterPath.MyContractAgreements,
      text: 'My Contract Agreements',
      icon: <ContractAgreementIcon className={classes.icon} />,
      show: hasRole(Consts.UserRoleEnum.ViewDealsAndContractAgreements, roles),
    },
    {
      link: Consts.RouterPath.MyRebates,
      text: 'My Rebates',
      icon: <RebatesIcon className={classes.icon} />,
      show: hasRole(Consts.UserRoleEnum.ViewDealsAndContractAgreements, roles),
    },
    {
      link: Consts.RouterPath.MyDrafts,
      text: 'My Drafts',
      icon: <DraftIcon className={classes.draftIcon} />,
      show:
        hasRole(Consts.UserRoleEnum.ViewDealsAndContractAgreements, roles) &&
        hasAnyRole(
          [Consts.UserRoleEnum.AddOrUpdateDeals, Consts.UserRoleEnum.AddOrUpdateContractAgreements],
          roles
        ),
    },
    {
      link: Consts.RouterPath.MyClaims,
      text: 'My Claims',
      icon: <ClaimsIcon className={classes.claimsIcon} />,
      show: hasAnyRole(
        [Consts.UserRoleEnum.ManageContractAgreementClaims, Consts.UserRoleEnum.ManageDealClaims],
        roles
      ),
    },
  ];

  const allItemsLinks: NavItem[] = [
    {
      link: Consts.RouterPath.Deals,
      text: 'All Deals',
      icon: <DealsIcon className={classes.icon} />,
      show: hasRole(Consts.UserRoleEnum.ViewDealsAndContractAgreements, roles),
    },
    {
      link: Consts.RouterPath.ContractAgreements,
      text: 'All Contract Agreements',
      icon: <ContractAgreementIcon className={classes.icon} />,
      show: hasRole(Consts.UserRoleEnum.ViewDealsAndContractAgreements, roles),
    },
    {
      link: Consts.RouterPath.Rebates,
      text: 'All Rebates',
      icon: <RebatesIcon className={classes.icon} />,
      show: hasRole(Consts.UserRoleEnum.ViewDealsAndContractAgreements, roles),
    },
    {
      link: Consts.RouterPath.Adjustments,
      text: 'All Adjustments',
      icon: <AdjustmentsIcon className={classes.adjustmentIcon} />,
      show: hasRole(Consts.UserRoleEnum.ApproveDealsAndContractAgreements, roles),
    },
    {
      link: Consts.RouterPath.Drafts,
      text: 'All Drafts',
      icon: <DraftIcon className={classes.draftIcon} />,
      show:
        hasRole(Consts.UserRoleEnum.ViewDealsAndContractAgreements, roles) &&
        hasAnyRole([Consts.UserRoleEnum.AddOrUpdateContractAgreements], roles),
    },
    {
      link: Consts.RouterPath.Claims,
      text: 'All Claims',
      icon: <ClaimsIcon className={classes.icon} />,
      show: hasAnyRole(
        [Consts.UserRoleEnum.ManageContractAgreementClaims, Consts.UserRoleEnum.ManageDealClaims],
        roles
      ),
    },
    {
      link: Consts.RouterPath.UnreleasedAmounts,
      text: 'Unreleased Amounts',
      icon: <UnreleasedAmountsIcon className={classes.unreleasedAmountsIcon} />,
      show: hasRole(Consts.UserRoleEnum.ManageUnreleasedAmounts, roles),
    },
  ];

  return (
    <StyledDrawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      sx={{
        height: '100%',
      }}
    >
      <div className={classes.drawerContainer}>
        <NavList
          headerText={'My Home'}
          headerIcon={<HomeIcon className={classes.icon} />}
          items={myHomeLinks.filter((x) => x.show)}
        />
        <Divider className={classes.divider} />
        <NavList
          headerText={'All JB Hi-Fi'}
          headerIcon={<WorldIcon className={classes.iconWorld} />}
          items={allItemsLinks.filter((x) => x.show)}
        />
        <Divider className={classes.divider} />
      </div>
    </StyledDrawer>
  );
}
export default LeftNav;
