import {hasRole} from '../Permission';
import {RootState} from '../store';
import Consts from '../Consts';
import {Department} from '../../types';
import {ProfileData} from '../profileReducer';

export const selectProfileData = (state: RootState): ProfileData => state.profile.data;
export const selectProfileLoading = (state: RootState): boolean => state.profile.loading;
export const selectProfileError = (state: RootState): any => state.profile.error;
export const selectProfileRoles = (state: RootState): string[] | undefined =>
  state.profile.data?.roles;
export const selectProfileHasRoles = (state: RootState): boolean =>
  (state.profile.data?.roles ?? []).length > 0;
export const selectUserAllowedToBulkUploadEditRebateClaims = (state: RootState): boolean =>
  hasRole(Consts.UserRoleEnum.BulkUploadEditRebateClaims, selectProfileRoles(state));
export const selectLoggedInStaffCode = (state: RootState): number => state.profile.data?.staffCode;
export const selectProfileBuyerDepartments = (state: RootState): Department[] | undefined | null =>
  state.profile.data?.buyerDepartments;
export const selectLoggedInStaffDisplayName = (state: RootState): string =>
  state.profile.data?.displayName ?? '';
