import * as Yup from 'yup';
import * as R from 'ramda';
import Consts from '../../app/Consts';
import {getDisplayAmountValue, roundToTwoDecimalPlaces} from '../../utils/AmountUtils';
import {AccountOption} from '../../types';

const stepFinanceDetailsValidationSchema = Yup.object({
  claimInterval: Yup.string()
    .required(Consts.ValidationMessage.Required)
    .typeError(Consts.ValidationMessage.Required)
    .when(['isOngoing'], function (isOngoing, schema) {
      if (isOngoing) {
        return schema.oneOf(
          R.values(Consts.RebateClaimIntervalEnum).filter(
            (x) => x !== Consts.RebateClaimIntervalEnum.EndOfRebate
          ),
          'The selected value is not in the select options'
        );
      }
      return schema.oneOf(R.values(Consts.RebateClaimIntervalEnum));
    }),
  splitAccounts: Yup.array()
    .min(1, 'Select one finance account at least')
    .required()
    .test('account-duplicates', "The finance account can't be the same", function (value) {
      let allAccountIds = value?.filter((x) => x.accountId).map((x) => x.accountId) ?? [];
      let hasDuplicates = R.any(
        (x) => x.length > 1,
        R.values(R.groupBy(R.identity, allAccountIds))
      );
      return !hasDuplicates;
    })
    .test('total-amount', "The total amount doesn't match", function (value) {
      const totalSum = value
        ?.map((x) => x.amount)
        .reduce((a, b) => roundToTwoDecimalPlaces(a * 1 + b * 1), 0);
      const matches = totalSum === this.parent.amount * 1;
      if (!matches) {
        return this.createError({
          message: `The sum of finance accounts amount (${getDisplayAmountValue(
            totalSum
          )}) is not equal to the total amount (${getDisplayAmountValue(this.parent.amount)})`,
        });
      }
      return true;
    })
    .when(
      ['amount', 'accountOptions'],
      // @ts-ignore yup types are not correct, works fine
      function (amount, accountOptions, schema) {
        return schema.of(
          Yup.object().shape({
            accountId: Yup.number()
              .required(Consts.ValidationMessage.Required)
              .test(
                'value not in option list',
                'The selected account is not in the select options',
                function (value) {
                  if (!accountOptions.find((x: AccountOption) => x.id === value)) {
                    return this.createError({
                      message: `The selected account ${this.parent.accountName} is not in the select options`,
                    });
                  }
                  return true;
                }
              ),
            amount: Yup.number()
              .required(Consts.ValidationMessage.Required)
              .test(
                'splitAccounts-amount',
                `The value must not exceed the total amount value(${getDisplayAmountValue(
                  amount
                )})`,
                function (value = amount) {
                  if (value >= 0) {
                    return value <= amount;
                  } else {
                    return value >= amount;
                  }
                }
              ),
          })
        );
      }
    ),
});

export default stepFinanceDetailsValidationSchema;
