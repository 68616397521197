import React from 'react';
import {styled} from '@mui/material/styles';
import Button from '../Button/Button';
import Typography from '@mui/material/Typography';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 80px 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px #00000029;
`;

const CreateButton = styled(Button)`
  width: 240px;
  height: 70px;
  background-color: #000000;
  color: #ffffff;
  margin-right: 0 !important;
  margin-top: 35px;
`;

const IconContainer = styled('div')`
  padding-bottom: 35px;
`;

const Title = styled(Typography)`
  font-size: 28px;
`;

const Subtitle = styled(Typography)`
  font-size: 18px;
`;

const EmptyDataMessageBox = ({onClick, icon, title, subtitle, buttonLabel, ...props}) => {
  return (
    <Container {...props}>
      <IconContainer>{icon}</IconContainer>
      <Title variant="h5">{title}</Title>
      <Subtitle variant="body1">{subtitle}</Subtitle>
      <CreateButton onClick={onClick}>{buttonLabel}</CreateButton>
    </Container>
  );
};

export {EmptyDataMessageBox};
