import React, {useCallback} from 'react';
import {useField} from 'formik';
import {styled} from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import * as R from 'ramda';
import Consts from '../../../app/Consts';
import AutoCompleteSearch from './AutoCompleteSearch';
import config from '../../../app/Config';

const StaticVendorField = styled(TextField)`
  background-color: #f2f4f5;
`;
const InactiveText = styled('span')`
  font-style: italic;
  color: #626262;
`;
const keyPath = ['code'];

const VendorSearchField = ({confirm, disabled, onConfirmChange = () => {}, ...props}) => {
  const [field, meta, helpers] = useField(props.name);
  const {setValue} = helpers;
  const getSelectedValueCallback = useCallback((item) => {
    return {code: item.code, name: item.name, isActive: item.isActive};
  }, []);
  const renderDisplayTextCallback = useCallback(
    (item) => `${item.name}${item.isActive ? '' : ' (Inactive)'}`,
    []
  );
  const renderListItemCallback = useCallback((hit) => {
    return (
      <>
        {hit.name} {hit.isActive ? null : <InactiveText>&nbsp;(Inactive)</InactiveText>}
      </>
    );
  }, []);

  function getErrorMessage() {
    if (meta.touched && meta.error && R.path(keyPath, meta.error)) {
      return R.path(keyPath, meta.error);
    }
  }

  const onSelectedCallback = useCallback(
    (item) => {
      if (item) {
        setValue({code: item.code, name: item.name, isActive: item.isActive});
      } else {
        setValue({code: '', name: '', isActive: null});
      }
    },
    [setValue]
  );

  return (
    <>
      {disabled ? (
        <StaticVendorField
          disabled
          type="text"
          variant="outlined"
          fullWidth
          value={field.value.name}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <AutoCompleteSearch
          indexName={Consts.AlgoliaIndex.ClaimVendors}
          {...props}
          defaultRefinement={field.value.name}
          defaultValue={field.value.code ? field.value : null}
          noResultMessage="We haven't found any claim vendors that match the text you entered."
          placeholder="Start typing a claim vendor"
          filters={`entityCode:"${config.entityCode}" AND NOT code:"${Consts.Narta.Code}" AND isActive:1`}
          getSelectedValue={getSelectedValueCallback}
          renderDisplayText={renderDisplayTextCallback}
          renderListItem={renderListItemCallback}
          errorMessage={getErrorMessage()}
          onSelected={onSelectedCallback}
          confirm={confirm}
          onConfirm={onConfirmChange}
        />
      )}
    </>
  );
};

export default VendorSearchField;
