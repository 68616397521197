import React from 'react';
import Consts from '../../../app/Consts';
import {ContractAgreementForm} from '../../../components/ContractAgreementForm';

const CreateContractAgreement = () => {
  const initialValues = {
    claimVendor: {
      code: '',
      name: '',
      isActive: false,
    },
    ownedByStaffCode: null,
    description: '',
    startAt: null,
    endAt: null,
    rebateValueCount: 0,
    status: Consts.AgreementStatusEnum.Draft,
    hasRaisedClaim: false,
    hasActiveClaim: false,
    isOngoing: false,
    groupClaimsOnSingleInvoice: false,
  };
  return (
    <ContractAgreementForm title="Add A New Contract Agreement" initialValues={initialValues} />
  );
};

export default CreateContractAgreement;
