import React, {FC, useCallback, useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {useDropzone} from 'react-dropzone';
import axios, {
  CancelTokenSource,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosProgressEvent,
} from 'axios';
import {AlertType} from '../../app/AlertService';
import config from '../../app/Config';
import {post} from '../../utils/Request';
import {AttachmentUploadResponse} from '../../types';
import {ErrorBox} from '../Alert';
import {OutlinedButton} from '../Button';
import {AttachmentsIcon} from '../Icons';

const PREFIX = 'FilesUploader';

export const classes = {
  cancelIcon: `${PREFIX}-cancelIcon`,
  circularProgress: `${PREFIX}-circularProgress`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  emptyContainer: `${PREFIX}-emptyContainer`,
  headingButton: `${PREFIX}-headingButton`,
  headingContainer: `${PREFIX}-headingContainer`,
  root: `${PREFIX}-root`,
  subTitle: `${PREFIX}-subTitle`,
  title: `${PREFIX}-title`,
  rejectionsContainer: `${PREFIX}-rejectionsContainer`,
  bottomButtonContainer: `${PREFIX}-bottomButtonContainer`,
  bottomButton: `${PREFIX}-bottomButton`,
  children: `${PREFIX}-children`,
};

const StyledDropZoneContainer = styled('div')(({theme}) => ({
  width: '100%',
  padding: '1.875rem 1.25rem',
  border: '0.0625rem solid #dedede',
  background: '#f7f9fa padding-box',
  [`& .${classes.circularProgress}`]: {
    marginLeft: '0.3125rem',
  },
  [`& .${classes.cancelIcon}`]: {
    color: theme.palette.error.main,
    fontSize: '1.875rem',
  },
  [`& .${classes.rejectionsContainer}`]: {
    margin: '2rem 0',
  },
  [`& .${classes.bottomButtonContainer}`]: {
    marginBottom: '1.5rem',
  },
  [`& .${classes.bottomButton}`]: {
    marginLeft: 0,
  },
  [`& .${classes.headingButton}`]: {
    marginLeft: 0,
  },
  [`& .${classes.children}`]: {
    marginBottom: '1.5rem',
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({theme}) => ({
  '&.MuiLinearProgress-root': {
    height: '1rem',
    borderRadius: '0.5rem',
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.palette.background.default,
  },
}));

const CancelToken = axios.CancelToken;
let source: CancelTokenSource;

const EmptyDropZoneContainer = styled('div')`
  min-height: 22.75rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1.25rem 0;
`;
const ProgressContainer = styled('div')`
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
`;

const ProgressBarContainer = styled('div')`
  display: flex;
  > div {
    flex: 1;
  }
  width: 100%;
  align-items: center;
`;

const ProgressMessageContainer = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  color: #626262;
  margin-bottom: 1rem;
`;

const DropzoneTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 500;
`;
const DropzoneIcon = styled('div')`
  margin-bottom: 1rem;
`;

const DragDropTitle = styled(DropzoneTitle)`
  margin-bottom: 1rem;
`;

const CompleteMessage = 'Complete';
const ErrorMessage = 'Error happened';
const FileRejectionContainer = styled('div')`
  text-align: left;
  margin: 1.25rem 0;
`;
const StyledOutlinedButton = styled(OutlinedButton)`
  margin-left: 0;
`;

type FileRejection = {
  file: File;
  errors: {
    code: string;
    message: string;
  }[];
};

type Props = {
  accept?: Record<string, string[]>;
  additionalData?: Record<string, any>;
  apiEndpoint: string | string[];
  button?: React.ComponentType<any>;
  buttonLabel?: string;
  children?: React.ReactNode;
  continueAddButtonLabel?: string;
  heading?: string;
  icon?: React.ComponentType<any>;
  maxSizeInMb?: number;
  multiple?: boolean;
  noFiles?: boolean;
  onComplete?: (response: AxiosResponse<AttachmentUploadResponse[]>) => void;
  onError?: (error: any) => void;
  showBottomButton?: boolean;
  showHeadingButton?: boolean;
  style?: Record<string, React.CSSProperties>;
  subTitle?: string;
  title?: string;
};

const FilesUploader: FC<Props> = ({
  accept,
  additionalData,
  apiEndpoint,
  button: ButtonComponent = StyledOutlinedButton,
  buttonLabel = 'Upload',
  children,
  continueAddButtonLabel = 'Add files',
  heading = '',
  icon: Icon = AttachmentsIcon,
  maxSizeInMb = Infinity, //size in MB
  multiple = false,
  noFiles = true,
  onComplete,
  onError,
  showBottomButton = true,
  showHeadingButton = false,
  style,
  subTitle = '',
  title = 'Add Files',
}) => {
  const uploadProgressRef = useRef(0);
  const [progressMessage, setProgressMessage] = useState('Processing...');
  const [files, setFiles] = useState<File[]>([]);
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);
  const rejectionsRef = useRef<HTMLDivElement | null>(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setFileRejections(fileRejections);
      if (acceptedFiles.length === 0) {
        if (rejectionsRef.current && rejectionsRef.current.scrollIntoView) {
          rejectionsRef.current.scrollIntoView({block: 'center'});
        }
        return;
      }

      const files = multiple ? acceptedFiles : [acceptedFiles[0]];
      setFiles(files);

      let data = new FormData();
      for (const file of files) {
        data.append('file', file);
      }
      if (additionalData) {
        data.append('data', JSON.stringify(additionalData));
      }

      const uploadFile = async (
        endpoint: string
      ): Promise<AxiosResponse<AttachmentUploadResponse[]> | null> => {
        source = CancelToken.source();
        const requestConfig: AxiosRequestConfig = {
          onUploadProgress: function (progressEvent: AxiosProgressEvent) {
            const progress = progressEvent?.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 100;
            uploadProgressRef.current = progress;
            setProgressMessage(progress === 100 ? 'Processing...' : `${progress}% complete`);
          },
          cancelToken: source.token,
          params: {
            entityCode: config.entityCode,
          },
          // https://github.com/axios/axios/issues/4406
          transformRequest: (data: any) => data,
        };

        try {
          const response = await post(endpoint, data, requestConfig);
          return response;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      };

      const uploadFiles = async () => {
        try {
          if (typeof apiEndpoint === 'string') {
            const response = await uploadFile(apiEndpoint);
            if (response) {
              onComplete?.(response);
            }
          } else if (Array.isArray(apiEndpoint)) {
            const responses = await Promise.all(
              apiEndpoint.map(async (endpoint) => {
                const response = await uploadFile(endpoint);
                return response;
              })
            );
            const combinedResponses = responses
              .filter(
                (response): response is AxiosResponse<AttachmentUploadResponse[]> =>
                  response !== null
              )
              .flatMap((response: AxiosResponse<AttachmentUploadResponse[]>) => response.data);
            onComplete?.({data: combinedResponses} as AxiosResponse<AttachmentUploadResponse[]>);
          } else {
            throw new Error('Invalid apiEndpoint prop');
          }

          setProgressMessage('');
          setFiles([]);
          if (rejectionsRef.current && rejectionsRef.current.scrollIntoView) {
            rejectionsRef.current.scrollIntoView({block: 'center'});
          }
        } catch (error) {
          setProgressMessage(ErrorMessage);
          setFiles([]);
          onError?.(error);
        }
      };

      await uploadFiles();
    },
    [apiEndpoint, onComplete, onError, additionalData, multiple]
  );

  const cancelUpload = () => {
    if (source) {
      source.cancel('Operation canceled by the user.');
    }
    setProgressMessage('');
    setFiles([]);
  };
  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop,
    accept: accept,
    noClick: true,
    noKeyboard: true,
    multiple: multiple,
    maxSize: maxSizeInMb * 1024 * 1024,
  });
  function getEmptyDropZoneContainer() {
    if (noFiles) {
      const subtitle = subTitle
        ? subTitle
        : maxSizeInMb === Infinity
        ? null
        : `${maxSizeInMb} MB limit per file`;
      return (
        <EmptyDropZoneContainer
          style={style?.emptyContainer ?? {}}
          className={classes.emptyContainer}
        >
          <DropzoneIcon>
            <Icon />
          </DropzoneIcon>
          <Stack direction="column" spacing={3} alignItems="center">
            <DragDropTitle className={classes.title}>{title}</DragDropTitle>
            {subtitle ? <Box sx={{fontSize: '1.125rem'}}>{subtitle}</Box> : null}
            <div>{getRejections()}</div>
            <ButtonComponent
              onClick={(event) => {
                event.preventDefault();
                open();
              }}
            >
              {buttonLabel}
            </ButtonComponent>
          </Stack>
        </EmptyDropZoneContainer>
      );
    }
  }

  function getRejections() {
    if (fileRejections.length > 0) {
      return (
        <FileRejectionContainer ref={rejectionsRef} className={classes.rejectionsContainer}>
          <ErrorBox
            alertTitle="The following files are not uploaded:"
            type={AlertType.Error}
            sx={{alignItems: 'start'}}
          >
            {fileRejections.map((rejection, index) => {
              const messages = rejection.errors.map((x) => {
                if (x.code === 'file-too-large') {
                  return `File is larger than ${maxSizeInMb} MB`;
                }
                return x.message;
              });
              return (
                <div key={index}>
                  {rejection.file.name}: {messages.join('; ')}
                </div>
              );
            })}
          </ErrorBox>
        </FileRejectionContainer>
      );
    }
  }

  function getHeading() {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{marginBottom: '2rem'}}
      >
        {heading ? <Typography variant="h5">{heading}</Typography> : null}
        {showHeadingButton ? (
          <ButtonComponent onClick={open} className={classes.headingButton}>
            {continueAddButtonLabel}
          </ButtonComponent>
        ) : null}
      </Stack>
    );
  }

  function getChildren() {
    if (!noFiles) {
      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        open();
      };

      return (
        <>
          <div className={classes.children}>{children}</div>
          {files && files.length > 0 ? (
            <ProgressContainer>
              <DropzoneTitle>Uploading your file</DropzoneTitle>
              <ProgressBarContainer>
                <div>
                  <StyledLinearProgress variant="determinate" value={uploadProgressRef.current} />
                </div>
                <IconButton onClick={cancelUpload} size="large">
                  <CancelIcon classes={{root: classes.cancelIcon}} />
                </IconButton>
              </ProgressBarContainer>
              {progressMessage ? (
                <ProgressMessageContainer>
                  <span>{progressMessage}</span>
                  {uploadProgressRef.current === 100 &&
                    progressMessage !== CompleteMessage &&
                    progressMessage !== ErrorMessage && (
                      <CircularProgress size={30} classes={{root: classes.circularProgress}} />
                    )}
                </ProgressMessageContainer>
              ) : null}
            </ProgressContainer>
          ) : showBottomButton ? (
            <div className={classes.bottomButtonContainer}>
              <ButtonComponent onClick={handleClick} className={classes.bottomButton}>
                {continueAddButtonLabel}
              </ButtonComponent>
            </div>
          ) : null}
        </>
      );
    }
  }

  return (
    <StyledDropZoneContainer
      style={style?.container ?? {}}
      {...getRootProps()}
      className={classes.root}
      data-testid="dropzone"
    >
      <input {...getInputProps()} />
      {getHeading()}
      {getChildren()}
      {getEmptyDropZoneContainer()}
    </StyledDropZoneContainer>
  );
};

export default FilesUploader;
