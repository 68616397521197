import {isFuture, isValid} from 'date-fns';
import Consts from '../../app/Consts';
import {
  Config,
  CreateSpivValueRequest,
  DataMode,
  InitialSPIVFormValues,
  ValidSPIVFormValues,
  LocalSPIVValue,
  ValidLocalSPIVValue,
} from '../../types';

export const spivDisabled = ({
  status = '',
  claims = [],
  startAt,
}: InitialSPIVFormValues | ValidSPIVFormValues) => {
  const isCancellable = Boolean(
    claims?.every?.((c) => c?.status === Consts.ClaimStatusEnum.ReadyToProcess) ?? true
  );
  const isDeletable = isValid(new Date(startAt as string))
    ? isFuture(new Date(startAt as string))
    : true;
  const finishedStatus = status === Consts.AgreementStatusEnum.Finished;
  const finishedDisabled = finishedStatus && !isDeletable && !isCancellable;

  return {
    fields: {
      // page 1 details
      nartaSwitch: finishedDisabled,
      claimVendor: finishedDisabled,
      description: finishedDisabled,
      departmentNumber: finishedDisabled,
      startAt: finishedDisabled,
      endAt: finishedDisabled,
      locationCriteria: finishedDisabled,
      // page 2 values
      suppliers: finishedDisabled,
      // page 3 finance details
      claimDaysAfterExpiry: finishedDisabled,
      claimInterval: finishedDisabled,
      excludeReturns: finishedDisabled,
    },
    actions: {
      editSpivValues: finishedDisabled,
      addSpivValue: finishedDisabled,
      deleteSpivValue: finishedDisabled,
      bulkUploadValues: finishedDisabled,
      // call put spivs/:id on next step details form step one
      disableUpdateOnNextStepOne: finishedDisabled,
      // call post suppliers on next step values form step two
      disableUpdateOnNextStepTwo: finishedDisabled,
    },
  };
};

export const getDealCapType = (capType: LocalSPIVValue['capType']) => {
  return Consts.DealCapType.find((item) => item.value === capType) ?? Consts.DealCapType[0];
};
export const getSpivValueRequestData = (rowData: ValidLocalSPIVValue): CreateSpivValueRequest => {
  const {capType, dataMode, id, productDescription, ticketPrice, capValue, ...restData} = rowData;
  return {
    ...restData,
    ...(!Number(capValue) ? {} : {capType: getDealCapType(capType).value, capValue}),
  };
};
export const createNewRowData = (
  agreementId: number | undefined,
  isClaimVendorGstFree?: boolean
): LocalSPIVValue => ({
  dataMode: DataMode.Add,
  agreementId,
  id: undefined,
  amount: undefined,
  amountType: Consts.AmountTypeEnum.ValuePerUnit,
  capType: undefined,
  capValue: undefined,
  gstType: isClaimVendorGstFree ? Consts.GstTypeEnum.Free : Consts.GstTypeEnum.Exclusive,
  minSellPrice: undefined,
  minSellPriceType: Consts.SPIVMinSellPriceTypeEnum.GoSell,
  payableIncentiveAmountToStaff: undefined,
  productCode: undefined,
  productDescription: undefined,
  ticketPrice: undefined,
});

export const isDuplicateSKUError = (errorMsg: string) =>
  /There is already an existing SPIV value with the product code/.test(errorMsg);

export const formatErrorMsg = (error: any): string => {
  const decimalError = 'Could not convert string to decimal';
  const arithmeticError = 'Arithmetic overflow error converting numeric to data type numeric.';
  const numberInputErrorMessage = 'Please enter a number less than 100,000,000.';

  if (error?.response?.data?.detail) {
    const containsExpectedError =
      error.response.data.detail.includes(arithmeticError) ||
      error.response.data.detail.includes(decimalError);

    return containsExpectedError ? numberInputErrorMessage : error.response.data.detail;
  }
  return error.message;
};

export const calculateSpivWrittenAmount = (
  configs: Config | undefined,
  claimAmount: string
): number | undefined => {
  if (isNaN(Number(claimAmount))) {
    return undefined;
  }
  const validClaimAmount = Number(claimAmount);

  return Math.floor(validClaimAmount * Number(configs?.SpivWrittenAmountPercentage ?? 1));
};

export const getIncentiveAmountMessage = (
  configs: Config | undefined,
  incentiveAmount: number | undefined,
  claimAmount: number | undefined
): string => {
  const CONFIG_PERCENTAGE_THRESHOLD = 0.01;
  const validIncentiveAmount = Math.floor(Number(incentiveAmount));
  const percentage = Number(configs?.SpivWrittenAmountPercentage ?? 1);
  const validClaimAmount = Number(claimAmount);

  if (percentage === 1 || isNaN(validClaimAmount) || isNaN(validIncentiveAmount)) {
    return '';
  }

  const maxIncentiveAmount = Math.floor(
    validClaimAmount * (percentage + CONFIG_PERCENTAGE_THRESHOLD)
  );
  const minIncentiveAmount = Math.floor(
    validClaimAmount * (percentage - CONFIG_PERCENTAGE_THRESHOLD)
  );

  if (validIncentiveAmount < minIncentiveAmount) {
    return `Value is less than ${percentage * 100}% of claim amount. This will impact your O&A`;
  } else if (validIncentiveAmount > maxIncentiveAmount) {
    return `Value is higher than ${percentage * 100}% of claim amount. This will impact your O&A`;
  }

  return '';
};
