import React, {FC} from 'react';
import {ConfirmModal} from '.';

const styles = {
  okButton: {
    width: '21.25rem',
  },
  cancelButton: {
    width: '21.25rem',
  },
};

type BaseModalProps = {
  entityActionType: string;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  cancelText?: string;
  okText?: string;
  children?: React.ReactNode;
};

const DeleteAgreementOrRebateConfirmModal: FC<BaseModalProps> = ({
  entityActionType,
  open,
  onOk,
  onCancel,
  cancelText,
  okText,
  children,
  ...props
}) => {
  return (
    <ConfirmModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText ?? 'Confirm and Delete'}
      cancelText={cancelText ?? 'Cancel'}
      styles={styles}
      {...props}
    >
      {children ?? (
        <div>
          If you proceed, this {entityActionType} will be deleted overnight and cannot be reversed.
        </div>
      )}
    </ConfirmModal>
  );
};

const DeleteAgreementOrRebateWithActiveClaimConfirmModal: FC<BaseModalProps> = ({
  entityActionType,
  open,
  onOk,
  onCancel,
  ...props
}) => {
  return (
    <ConfirmModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Proceed"
      cancelText="Go back"
      styles={styles}
      {...props}
    >
      <div>
        Deleting this {entityActionType} will also delete any associated claims that are active (not
        yet raised).
      </div>
    </ConfirmModal>
  );
};

const DeleteAgreementorRebateWithClaimForbiddenModal: FC<Omit<BaseModalProps, 'onCancel'>> = ({
  entityActionType,
  open,
  onOk,
  ...props
}) => {
  return (
    <ConfirmModal
      open={open}
      onOk={onOk}
      okText="Close"
      {...props}
      title={`Cannot delete ${entityActionType}`}
    >
      <div>
        This {entityActionType} cannot be deleted as there has already been an associated claim
        raised.
      </div>
    </ConfirmModal>
  );
};

export {
  DeleteAgreementOrRebateConfirmModal,
  DeleteAgreementOrRebateWithActiveClaimConfirmModal,
  DeleteAgreementorRebateWithClaimForbiddenModal,
};
