import React, {FC, useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as R from 'ramda';
import {InstantSearch, Configure} from 'react-instantsearch-dom';
import Consts from '../../../app/Consts';
import config from '../../../app/Config';
import {QueryCriteria, Facet} from '../../../types';
import {SearchClient} from '../../../utils/algoliaSearchClient';
import {Button} from '../../Button';
import CustomSelect from '../../Select/CustomSelect';
import SelectorCloseConfirmation from '../../Modal/SelectorCloseConfirmation';
import CustomStats from './CustomStats';
import ExcludeRefinementList from './ExcludeRefinementList';
import IncludeRefinementList from './IncludeRefinementList';
import CustomCurrentRefinements from './CustomCurrentRefinements';
import CriteriaSection from './CriteriaSection';
import NoResultErrorStats from './NoResultErrorStats';

const PREFIX = 'LocationSelector';

const classes = {
  dialogPaper: `${PREFIX}-dialogPaper`,
  hidden: `${PREFIX}-hidden`,
  dialogAction: `${PREFIX}-dialogAction`,
  dialogContent: `${PREFIX}-dialogContent`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.dialogPaper}`]: {
    left: '25%',
    width: '50%',
  },

  [`& .${classes.hidden}`]: {
    display: 'none',
  },

  [`& .${classes.dialogAction}`]: {
    padding: '1rem 1.5rem',
  },

  [`& .${classes.dialogContent}`]: {
    padding: '1rem 2.5rem',
  },
}));

const searchClient = new SearchClient();
const stateFacet = Consts.AlgoliaFacet.State.Index;
const stateFacetLabel = Consts.AlgoliaFacet.State.Label;
const locationTypeFacet = Consts.AlgoliaFacet.LocationType.Index;
const locationTypeFacetlabel = Consts.AlgoliaFacet.LocationType.Label;
const locationFacet = Consts.AlgoliaFacet.Location.Index;
const locationFacetLabel = Consts.AlgoliaFacet.Location.Label;

const CriteriaSectionHeaderContainer = styled('div')`
  margin-top: 2.5rem;
  margin-bottom: 2rem;
`;
const CriteriaSectionHeaderTitle = styled('div')`
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 1.125rem;
`;
const CriteriaSectionHeaderSubTitle = styled('div')`
  font-size: 1rem;
  color: #626262;
  > strong {
    color: #000;
  }
`;

const CriteriaSelectorContainer = styled('div')`
  display: flex;
`;
const CriteriaSelect = styled(CustomSelect)`
  flex: 0 0 12rem;
  margin-right: 1rem;
`;
const CriteriaRefinementListContainer = styled('div')`
  flex: 1;
`;

const StyledDialogTitle = styled('div')`
  font-size: 2rem;
  font-weight: 500;
  display: flex;
  padding: 3rem;
  justify-content: center;
  align-items: center;
  > span {
    flex-grow: 1;
  }
`;
const ApplyCriteriaButton = styled(Button)`
  width: 22rem;
`;

const inclusionFacetLimit = 20;
const inclusionFacets = [
  {
    label: locationTypeFacetlabel,
    value: locationTypeFacet,
    pluralLabel: Consts.AlgoliaFacet.LocationType.PluralLabel,
  },
  {
    label: stateFacetLabel,
    value: stateFacet,
    pluralLabel: Consts.AlgoliaFacet.State.PluralLabel,
  },
  {
    label: locationFacetLabel,
    value: locationFacet,
    pluralLabel: Consts.AlgoliaFacet.Location.PluralLabel,
  },
];
const exclusionFacets = [
  {
    label: locationTypeFacetlabel,
    value: locationTypeFacet,
    pluralLabel: Consts.AlgoliaFacet.LocationType.PluralLabel,
  },
  {
    label: stateFacetLabel,
    value: stateFacet,
    pluralLabel: Consts.AlgoliaFacet.State.PluralLabel,
  },
  {
    label: locationFacetLabel,
    value: locationFacet,
    pluralLabel: Consts.AlgoliaFacet.Location.PluralLabel,
  },
];

const defaultCriteria = {
  indexName: Consts.AlgoliaIndex.Locations,
  filters: `NOT ${locationTypeFacet}:"Do Not Use" AND entityCode:${config.entityCode}`,
  resultCount: 0,
  facetInclusions: [
    {
      name: locationTypeFacet,
      facetType: Consts.AlgoliaFacet.LocationType.Type,
      values: [],
    },
    {
      name: stateFacet,
      facetType: Consts.AlgoliaFacet.State.Type,
      values: [],
    },
    {
      name: locationFacet,
      facetType: Consts.AlgoliaFacet.Location.Type,
      values: [],
    },
  ],
  facetExclusions: [
    {
      name: locationTypeFacet,
      facetType: Consts.AlgoliaFacet.LocationType.Type,
      values: [],
    },
    {
      name: stateFacet,
      facetType: Consts.AlgoliaFacet.State.Type,
      values: [],
    },
    {
      name: locationFacet,
      facetType: Consts.AlgoliaFacet.Location.Type,
      values: [],
    },
  ],
};

type RefineMentListItem = {
  label: string;
  value: string[];
  count: number;
  isRefined: boolean;
};

type AlgoliaFacet = {
  Index: string;
  Type: string;
  Label: string;
  PluralLabel: string;
  Placeholder: string;
  Composited?: boolean;
};

type Props = {
  open: boolean;
  locationCriteria: QueryCriteria | null;
  handleClose: () => void;
  onApplyCriteria?: (criteria: QueryCriteria) => void;
  scrollType?: 'paper';
  readOnly?: boolean;
} & DialogProps;

const LocationSelector: FC<Props> = ({
  open,
  locationCriteria,
  handleClose,
  onApplyCriteria,
  scrollType = 'paper',
  readOnly = false,
  ...dialogProps
}) => {
  const noResultRef = useRef<HTMLDivElement | null>(null);
  const [visibleInclusionFacet, setVisibleInclusionFacet] = useState(inclusionFacets[0]);
  const [visibleExclusionFacet, setVisibleExclusionFacet] = useState(exclusionFacets[0]);
  const [criteria, setCriteria] = useState(locationCriteria || defaultCriteria);
  const [closeConfirmModalOpen, setCloseConfirmModalOpen] = useState(false);

  function setDraftCriteria(value: QueryCriteria): void {
    const facetInclusions = value.facetInclusions.map((x) => ({
      name: x.name,
      facetType: x.facetType,
      values: [...(x?.values ?? [])],
    }));

    const facetExclusions = value.facetExclusions.map((x) => ({
      name: x.name,
      facetType: x.facetType,
      values: [...(x?.values ?? [])],
    }));

    const newCriteria = {
      indexName: value.indexName,
      filters: value.filters,
      facetInclusions,
      facetExclusions,
      resultCount: value.resultCount,
    };
    setCriteria(newCriteria);
  }

  function applyCriteria() {
    if (criteria?.resultCount === 0) {
      if (noResultRef.current) {
        noResultRef.current.scrollIntoView({behavior: 'smooth'});
      }
      return;
    }
    if (onApplyCriteria) {
      onApplyCriteria(criteria);
    }
  }
  function onDeleteFacetValue(attribute: AlgoliaFacet['Index'], value: string, inclusive: boolean) {
    if (inclusive) {
      const facetIndex = criteria.facetInclusions.findIndex((x: Facet) => x.name === attribute);
      if (facetIndex > -1) {
        const facet = criteria.facetInclusions[facetIndex];
        const newFacetInclusions = [...criteria.facetInclusions];
        newFacetInclusions[facetIndex] = {
          ...facet,
          values: facet.values?.filter((x) => x !== value),
        };

        setDraftCriteria({
          ...criteria,
          facetInclusions: newFacetInclusions,
        });
      }
    } else {
      const facetIndex = criteria.facetExclusions.findIndex((x: Facet) => x.name === attribute);
      if (facetIndex > -1) {
        const facet = criteria.facetExclusions[facetIndex];
        const newFacetExclusions = [...criteria.facetExclusions];
        newFacetExclusions[facetIndex] = {
          ...facet,
          values: facet.values?.filter((x) => x !== value),
        };

        setDraftCriteria({
          ...criteria,
          facetExclusions: newFacetExclusions,
        });
      }
    }
  }

  function onRefine(item: RefineMentListItem, attribute: AlgoliaFacet['Index']) {
    let facet = R.find(R.propEq('Index', attribute), R.values(Consts.AlgoliaFacet));
    if (!facet) {
      return;
    }
    const exclusions = {
      name: attribute,
      facetType: facet.Type,
      values: item.value.filter((val: string) => val.startsWith('-')),
    };
    const inclusions = {
      name: attribute,
      facetType: facet.Type,
      values: item.value.filter((val: string) => !val.startsWith('-')),
    };
    setDraftCriteria({
      ...criteria,
      facetInclusions: [
        ...criteria.facetInclusions.filter((x) => x.name !== attribute),
        inclusions,
      ],
      facetExclusions: [
        ...criteria.facetExclusions.filter((x) => x.name !== attribute),
        exclusions,
      ],
    });
  }

  function onResult(count: number) {
    setDraftCriteria({...criteria, resultCount: count});
  }
  function handleDefaultRefinement(facet: AlgoliaFacet, inclusive?: boolean): string[] {
    if (inclusive) {
      return [...(criteria.facetInclusions.find((x) => x.name === facet.Index)?.values ?? [])];
    } else {
      return [...(criteria.facetExclusions.find((x) => x.name === facet.Index)?.values ?? [])];
    }
  }
  function getFacetLabelByFacetName(label: string, attribute: AlgoliaFacet['Index']) {
    const facet = Object.values(Consts.AlgoliaFacet).find(
      (facet: AlgoliaFacet) => facet.Composited && facet.Index === attribute
    );

    if (facet) {
      return renderLabel(facet, label);
    }
    return label;
  }
  function renderLabel(facet: AlgoliaFacet, label: string) {
    if (facet.Index === locationFacet) {
      return label.replace('--', ' ');
    } else if (facet.Composited) {
      return label.split('--')[1];
    }
    return label;
  }

  function getListItem(facet: AlgoliaFacet, inclusive?: boolean): JSX.Element {
    const defaultRefinement = handleDefaultRefinement(facet, inclusive);
    if (inclusive) {
      return (
        <div
          className={
            visibleInclusionFacet && visibleInclusionFacet.value === facet.Index
              ? ''
              : classes.hidden
          }
        >
          <IncludeRefinementList
            attribute={facet.Index}
            defaultRefinement={defaultRefinement}
            limit={inclusionFacetLimit}
            onRefine={onRefine}
            placeholder={facet.Placeholder}
            renderLabel={(label: string) => renderLabel(facet, label)}
            searchable
          />
        </div>
      );
    } else {
      return (
        <div
          className={
            visibleExclusionFacet && visibleExclusionFacet.value === facet.Index
              ? ''
              : classes.hidden
          }
        >
          <ExcludeRefinementList
            attribute={facet.Index}
            defaultRefinement={defaultRefinement}
            limit={inclusionFacetLimit * 2}
            onRefine={onRefine}
            placeholder={facet.Placeholder}
            renderLabel={(label: string) => renderLabel(facet, label)}
            searchable
          />
        </div>
      );
    }
  }

  function getInclusionCriteria() {
    return (
      <CriteriaSection title="Add Inclusion Criteria" readOnly={readOnly}>
        <CriteriaSelectorContainer>
          <CriteriaSelect
            options={inclusionFacets}
            defaultValue={inclusionFacets[0]}
            onChanged={setVisibleInclusionFacet}
          />
          <CriteriaRefinementListContainer>
            {getListItem(Consts.AlgoliaFacet.State, true)}
            {getListItem(Consts.AlgoliaFacet.LocationType, true)}
            {getListItem(Consts.AlgoliaFacet.Location, true)}
          </CriteriaRefinementListContainer>
        </CriteriaSelectorContainer>
      </CriteriaSection>
    );
  }
  function getExcludeCriteria() {
    return (
      <CriteriaSection title="Add Exclusion Criteria" readOnly={readOnly}>
        <CriteriaSelectorContainer>
          <CriteriaSelect
            options={exclusionFacets}
            defaultValue={exclusionFacets[0]}
            onChanged={setVisibleExclusionFacet}
          />
          <CriteriaRefinementListContainer>
            {getListItem(Consts.AlgoliaFacet.State)}
            {getListItem(Consts.AlgoliaFacet.LocationType)}
            {getListItem(Consts.AlgoliaFacet.Location)}
          </CriteriaRefinementListContainer>
        </CriteriaSelectorContainer>
      </CriteriaSection>
    );
  }
  function closeSelector() {
    if (readOnly) {
      handleClose();
      return;
    }
    const hasIncusions = R.any((facet) => !R.isEmpty(facet.values), criteria.facetInclusions || []);
    const hasExclusions = R.any(
      (facet) => !R.isEmpty(facet.values),
      criteria.facetExclusions || []
    );

    if (
      R.equals(locationCriteria, criteria) ||
      (!locationCriteria && !hasIncusions && !hasExclusions)
    ) {
      handleClose();
    } else {
      setCloseConfirmModalOpen(true);
    }
  }
  return (
    <StyledDialog
      open={open}
      onClose={closeSelector}
      scroll={scrollType}
      classes={{paper: classes.dialogPaper}}
      {...dialogProps}
    >
      <InstantSearch searchClient={searchClient} indexName={Consts.AlgoliaIndex.Locations}>
        <StyledDialogTitle>
          <span>Location Selector</span>
          <IconButton onClick={closeSelector} size="large">
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers={scrollType === 'paper'} classes={{root: classes.dialogContent}}>
          <Configure filters={criteria.filters} highlightPreTag="p" highlightPostTag="p" />
          <CriteriaSectionHeaderContainer>
            <CriteriaSectionHeaderTitle>
              Include all locations that meet the following criteria:
            </CriteriaSectionHeaderTitle>
            <CriteriaSectionHeaderSubTitle>
              If you don't select any criteria, the deal will apply to{' '}
              <strong>All Locations.</strong>
            </CriteriaSectionHeaderSubTitle>
          </CriteriaSectionHeaderContainer>
          {getInclusionCriteria()}
          <CustomCurrentRefinements
            renderLabel={getFacetLabelByFacetName}
            criteria={inclusionFacets}
            inclusive
            onDelete={onDeleteFacetValue}
            readOnly={readOnly}
          />

          <CriteriaSectionHeaderContainer>
            <CriteriaSectionHeaderTitle>
              Exclude all locations that meet the following criteria:
            </CriteriaSectionHeaderTitle>
          </CriteriaSectionHeaderContainer>
          {getExcludeCriteria()}
          <CustomCurrentRefinements
            renderLabel={getFacetLabelByFacetName}
            criteria={exclusionFacets}
            inclusive={false}
            onDelete={onDeleteFacetValue}
            readOnly={readOnly}
          />
          <div ref={noResultRef}>
            <NoResultErrorStats resultLabel="location" pluralResultLabel="locations" />
          </div>
        </DialogContent>
        <DialogActions classes={{root: classes.dialogAction}}>
          <CustomStats resultLabel="location" pluralResultLabel="locations" onResult={onResult} />
          {!readOnly && (
            <ApplyCriteriaButton onClick={applyCriteria}>Apply Criteria</ApplyCriteriaButton>
          )}
        </DialogActions>
      </InstantSearch>
      <SelectorCloseConfirmation
        open={closeConfirmModalOpen}
        onCancel={() => setCloseConfirmModalOpen(false)}
        onOk={handleClose}
      />
    </StyledDialog>
  );
};

export default LocationSelector;
