import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {api, get} from '../utils/Request';
import {Department} from '../types';
import Consts from './Consts';
import config from './Config';

export interface ProfileData {
  id?: string;
  companyName?: string | null;
  department?: string;
  employeeId?: string;
  jobTitle?: string | null;
  mail?: string;
  displayName?: string;
  userPrincipalName?: string;
  usageLocation?: string;
  country?: string | null;
  officeLocation?: string | null;
  staffCode: number;
  roles?: string[];
  buyerDepartments?: Department[] | null;
}

export interface ProfileState {
  data: ProfileData;
  error: any;
  loading: boolean;
}
const emptyProfileData: Partial<ProfileData> = {};
export const initialState: ProfileState = {
  data: emptyProfileData as never,
  error: null,
  loading: true,
};

const getProfile = createAsyncThunk('getProfile', async (arg, thunkAPI) => {
  return get(api(Consts.Api.MyProfile), {
    params: {
      entityCode: config.entityCode,
    },
  }).then((response) => response.data);
});

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled.type, (state, action: any) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getProfile.rejected.type, (state, action: any) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

const profileReducer = profileSlice.reducer;
export default profileReducer;
export {getProfile};
