import * as Yup from 'yup';
import Consts from '../../../app/Consts';
import {isNullish} from '../../../utils';

export const spivValuesValidationSchema = Yup.object().shape({
  productCode: Yup.string().required(Consts.ValidationMessage.Required),
  amount: Yup.number()
    .typeError('Invalid format (use numbers only)')
    .required(Consts.ValidationMessage.Required)
    .min(0, 'The value must be greater than or equal to 0')
    .nullable(),
  amountType: Yup.string().required(Consts.ValidationMessage.Required),
  gstType: Yup.string().required(Consts.ValidationMessage.Required),
  minSellPrice: Yup.number()
    .when('minSellPriceType', {
      is: Consts.SPIVMinSellPriceTypeEnum.MinSellPrice,
      then: Yup.number()
        .typeError('Invalid format (use numbers only)')
        .required(Consts.ValidationMessage.Required)
        .min(0, 'The value must be greater than or equal to 0'),
    })
    .nullable(),
  payableIncentiveAmountToStaff: Yup.number()
    .typeError('Invalid format (use numbers only)')
    .required(Consts.ValidationMessage.Required)
    .min(0.01, Consts.ValidationMessage.PositiveValue)
    .test(
      'payableIncentiveAmountToStaff',
      'The Value is greater than ticket price',
      function (value) {
        const ticketPrice = this.resolve(Yup.ref('ticketPrice'));
        if (isNullish(ticketPrice) || isNullish(value)) {
          return true;
        }
        return Number(value as number) <= Number(ticketPrice as number);
      }
    ),
});

export const stepSPIVValuesValidationSchema = Yup.object({
  spivValues: Yup.object().shape({
    data: Yup.array().min(1, 'Please save at least one SPIV value before continuing').required(),
  }),
});
