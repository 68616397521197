import React, {useContext} from 'react';
import {styled} from '@mui/material/styles';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import config from '../../../app/Config';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode} from '../../../app/selectors';
import {get, api} from '../../../utils/Request';
import {OutlinedButton} from '../../Button';

const DownloadButton = styled(OutlinedButton)`
  width: 220px;
  background-color: #000000;
  color: #ffffff;
  width: 220px;
  height: 52px;
  border-radius: 0;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

interface DownloadRebateCsvButtonProps {
  my: boolean;
  requestData: any;
}

export default function DownloadRebateCsvButton(props: DownloadRebateCsvButtonProps) {
  const {my, requestData} = props;
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);

  const downloadRebates = () => {
    showLoading();
    get(api(Consts.Api.RebatesDownload), {
      params: {
        entityCode: config.entityCode,
        ownedByStaffCode: my ? loggedInStaffCode : null,
        ...requestData,
      },
      responseType: 'blob',
    })
      .then(({data}) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(new Blob([data]));
        link.href = url;
        link.download = my ? 'MyRebates.csv' : 'AllRebates.csv';
        link.click();
        link.remove();
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };
  return <DownloadButton onClick={downloadRebates}>Download CSV Report</DownloadButton>;
}
