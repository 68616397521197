/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import * as R from 'ramda';
import {RebateListingFilter} from '../../../app/rebatesReducer';
import {useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode, selectLoggedInStaffDisplayName} from '../../../app/selectors';
import {Owner} from '../../../app/ownersReducer';
import {FinanceAccount} from '../../../app/financialAccountsReducer';
import Consts from '../../../app/Consts';
import {DateType, isValidDate} from '../../../utils/DateUtils';
import FieldLabel from '../../Form/FieldLabel';
import DatePicker from '../../Form/DatePicker';
import SideFilters from '../../SideFilters/SideFilters';
import {LinkButton} from '../../Button';
import {CustomAutoComplete} from '../../CustomAutoComplete';
import {VendorSearch} from '../../Form/Agolia';
import OwnersSearch from '../../Form/OwnersSearch/OwnersSearch';
import FinancialAccounts from '../../Form/FinancialAccountsSearch/FinancialAccountsSearch';

const FilterGrid = styled(Grid)`
  margin-top: 30px;
`;
const ClearGrid = styled(Grid)`
  margin-top: 10px;
`;

export interface RebateListingSideFilterProps {
  my: boolean;
  defaultSelection: RebateListingFilter;
  onChange(filter: any): void;
  onClose(): void;
}

const RebateListingSideFilter = (props: RebateListingSideFilterProps) => {
  const {defaultSelection, onChange, onClose, my} = props;
  const [selection, setSelection] = useState(defaultSelection);
  const firstUpdate = useRef(true);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const displayName = useAppSelector(selectLoggedInStaffDisplayName);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const dateIsSelectedCorrectly = (date: string | null) => {
      return date === null || isValidDate(date);
    };
    if (dateIsSelectedCorrectly(selection?.startAt) && dateIsSelectedCorrectly(selection?.endAt)) {
      onChange(selection);
    }
  }, [selection]);

  const filters = [
    {
      label: 'Claim Vendor',
      field: (
        <VendorSearch
          defaultValue={selection.claimVendor}
          onChange={(item) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, claimVendor: item};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Claim Frequency',
      field: (
        <CustomAutoComplete
          options={Consts.RebateClaimInterval}
          value={
            Consts.RebateClaimInterval.find((ci) => ci.value === selection.claimFrequency) ?? null
          }
          getOptionLabel={(option: any) => option.label}
          name="claimFrequency"
          onChange={(_: any, option: any) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                claimFrequency: option?.value,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Finance Acc.',
      field: (
        <FinancialAccounts
          value={selection.financeAccount}
          onChange={(_: any, option: FinanceAccount | null) => {
            setSelection((prevSelection) => {
              const newSelection: RebateListingFilter = {
                ...prevSelection,
                financeAccount: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Start Date',
      field: (
        <DatePicker
          value={selection.startAt}
          dateType={DateType.RangeStart}
          placeholder="Choose a start date"
          onChanged={(value: string) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, startAt: value};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'End Date',
      field: (
        <DatePicker
          dateType={DateType.RangeEnd}
          value={selection.endAt}
          placeholder="Choose an end date"
          onChanged={(value: any) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, endAt: value};
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Owner',
      field: (
        <OwnersSearch
          value={my ? {name: displayName ?? '', code: loggedInStaffCode} : selection.owner}
          my={my}
          onChange={(_: any, option: Owner | null) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                owner: option,
              };
              return newSelection;
            });
          }}
        />
      ),
    },
  ];

  const onClear = () => {
    setSelection((prevSelection) => {
      const newSelection = R.mapObjIndexed((value, key, obj) => {
        return null;
      }, prevSelection) as RebateListingFilter;

      if (my) {
        newSelection.owner = prevSelection.owner;
      }

      return newSelection;
    });
  };

  const filtersHaveValue = (relevantFilters: any) => {
    return R.any((x) => !!x, R.values(relevantFilters));
  };

  const relevantFiltersHaveValue = () => {
    const {status, ...relevantFilters} = selection;

    if (my) {
      const {owner, ...selectionWithoutOwners} = relevantFilters;
      return R.any((x) => !!x, R.values(selectionWithoutOwners));
    }
    return filtersHaveValue(relevantFilters);
  };

  return (
    <SideFilters onClose={onClose}>
      {relevantFiltersHaveValue() && (
        <ClearGrid container justifyContent="flex-end">
          <LinkButton name="clearFilters" onClick={onClear}>
            Clear
          </LinkButton>
        </ClearGrid>
      )}

      {filters.map((item) => (
        <FilterGrid key={item.label}>
          <FieldLabel>{item.label}</FieldLabel>
          {item.field}
        </FilterGrid>
      ))}
    </SideFilters>
  );
};

export default RebateListingSideFilter;
