import React, {FC, useEffect, useState} from 'react';
import {SPIVSummaryResponse} from '../../../types';
import Consts from '../../../app/Consts';
import SummaryDetailsComponent from '../../../components/SummaryDetailsComponent';
import {getFormattedDateTime} from '../../../utils/DateUtils';

type Props = {
  spivData: SPIVSummaryResponse;
};

const SPIVSummaryDetails: FC<Props> = ({spivData}) => {
  const [details, setDetails] = useState<{title: string; value?: string}[]>([]);
  useEffect(() => {
    const isCancelled = spivData.status === Consts.AgreementStatusEnum.Cancelled;
    setDetails([
      {
        title: 'Claim Vendor',
        value: spivData.claimVendorName,
      },
      {
        title: 'Department',
        value: spivData.departmentDescription,
      },
      {
        title: 'Deal Type',
        value: 'SPIV',
      },
      {
        title: 'Start Date',
        value: getFormattedDateTime(spivData.startAt),
      },
      {
        title: 'End Date',
        value: getFormattedDateTime(spivData.endAt),
      },
      {
        title: 'Owner',
        value: `${spivData.ownedByStaffCode} - ${spivData.ownedByStaffName}`,
      },
      {
        title: 'Created Date',
        value: getFormattedDateTime(spivData.createdAt),
      },
      {
        title: isCancelled ? 'Cancelled Date' : 'Last Modified Date',
        value: getFormattedDateTime(spivData.lastModifiedAt),
      },
      {
        title: isCancelled ? 'Cancelled By' : 'Last Modified By',
        value: `${spivData.lastModifiedByStaffCode} - ${spivData.lastModifiedByStaffName}`,
      },
    ]);
  }, [spivData]);

  return (
    <SummaryDetailsComponent
      id={spivData.id}
      details={details}
      status={spivData.status}
      desc={spivData.description}
      staffCode={spivData.ownedByStaffCode}
      staffName={spivData.ownedByStaffName}
      hideOwner
      type="SPIV"
    />
  );
};

export default SPIVSummaryDetails;
