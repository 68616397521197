import React from 'react';
import {ConfirmModal} from './Modal';

const styles = {
  okButton: {
    width: '340px',
  },
  cancelButton: {
    width: '340px',
  },
};

const SelectorCloseConfirmation = ({open, onOk, onCancel, ...otherProps}) => {
  return (
    <ConfirmModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Close selector and ignore changes"
      cancelText="Go back to selector"
      styles={styles}
      {...otherProps}
    >
      <div>
        If you close the selector without selecting the 'Apply Criteria' button, your changes will
        be lost. What would you like to do?
      </div>
    </ConfirmModal>
  );
};

export default SelectorCloseConfirmation;
