import * as Yup from 'yup';
import Consts from '../../app/Consts';
import * as R from 'ramda';

const stepDealValuesValidationSchema = Yup.object({
  dealType: Yup.mixed().oneOf(
    R.map(R.prop('value'), Consts.StandardDealTypes),
    Consts.ValidationMessage.Required
  ),
  dealValues: Yup.array()
    .min(1, 'Please save at least one deal value before continuing')
    .required(),
});
export default stepDealValuesValidationSchema;
