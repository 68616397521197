import React, {useState, useEffect, useCallback} from 'react';
import {authProvider, AuthenticationState} from '../../utils/AuthProvider';

type TokenResponseAccount = {
  homeAccountId: string;
  environment: string;
  tenantId: string;
  username: string;
  localAccountId: string;
  name: string;
  idTokenClaims: {
    aud: string;
    iss: string;
    iat: number;
    nbf: number;
    exp: number;
    name: string;
    nonce: string;
    oid: string;
    preferred_username: string;
    rh: string;
    sub: string;
    tid: string;
    uti: string;
    ver: string;
  };
};

type InnerProps = {
  account: TokenResponseAccount | null;
  accessToken: string | null;
  authenticationState: string;
  error: string | null;
  login: () => void;
  logout: () => void;
};

type TokenResponse = {
  account: TokenResponseAccount;
  accessToken: string;
};

const AzureADApp = (Component: React.ComponentType<InnerProps>) => {
  const AzureAdAppComponent = () => {
    const [authenticationState, setAuthenticationState] = useState(
      authProvider.authenticationState
    );
    const [account, setAccount] = useState<TokenResponseAccount | null>(null);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const loginCallBack = useCallback(() => {
      if (authenticationState !== AuthenticationState.UnAuthenticated || error) {
        return;
      }
      setAuthenticationState(AuthenticationState.InProgress);
      authProvider.login();
    }, [authenticationState, error]);

    async function logout() {
      authProvider.logout();
    }

    useEffect(() => {
      function handleResponse(tokenResponse: TokenResponse | null) {
        if (tokenResponse) {
          setAccount(tokenResponse.account);
          setAccessToken(tokenResponse.accessToken);
          setAuthenticationState(AuthenticationState.Authenticated);
        } else {
          loginCallBack();
        }
      }

      authProvider
        .handleRedirectPromise()
        .then(handleResponse)
        .catch((authError) => {
          setAuthenticationState(AuthenticationState.UnAuthenticated);
          setError(authError.errorMessage);
        });
    }, [loginCallBack]);

    if (authenticationState === AuthenticationState.InProgress) {
      return <div>authenticating...</div>;
    }
    return (
      <Component
        account={account}
        accessToken={accessToken}
        authenticationState={authenticationState}
        error={error}
        login={() => {
          authProvider.login();
        }}
        logout={logout}
      />
    );
  };
  return AzureAdAppComponent;
};
export default AzureADApp;
