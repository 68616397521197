import Consts from '../app/Consts';
import {tzTimeToUtc} from './DateUtils';

function transformConfigs(configs) {
  const lockDateString = configs[Consts.ConfigNameEnum.DealLockDate];
  const lockDate = tzTimeToUtc(lockDateString);

  const cutoffDateString = configs[Consts.ConfigNameEnum.DealCutoffDate];
  const cutoffDate = tzTimeToUtc(cutoffDateString);

  return {
    ...configs,
    [Consts.ConfigNameEnum.DealLockDate]: lockDate,
    [Consts.ConfigNameEnum.DealCutoffDate]: cutoffDate,
  };
}

export {transformConfigs};
