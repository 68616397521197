import React, {FC, useCallback} from 'react';
import {useFormikContext} from 'formik';
import {styled} from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {ValidSPIVFormValues} from '../../types';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import AutoCompleteSearch from '../Form/Agolia/AutoCompleteSearch';

const StaticVendorField = styled(TextField)`
  background-color: #f2f4f5;
`;
const InactiveText = styled('span')`
  font-style: italic;
  color: #626262;
`;

type SearchItem = {
  code: string;
  name: string;
  isActive: number;
};

type Props = {
  confirm: React.ElementType | null;
  disabled?: boolean;
  onConfirmChange: (value: boolean) => void;
  id: string;
  fullWidth?: boolean;
};

const SPIVClaimVendorSearch: FC<Props> = ({confirm, disabled, onConfirmChange, ...props}) => {
  const bag = useFormikContext<ValidSPIVFormValues>();
  const {
    values: {claimVendorName, claimVendorActive},
    setFieldValue,
    errors,
    touched,
  } = bag;
  const getSelectedValueCallback = useCallback((item: SearchItem) => {
    return {code: item.code, name: item.name, isActive: item.isActive};
  }, []);

  const renderDisplayTextCallback = useCallback(
    (item: SearchItem) => {
      if (typeof item === 'string') {
        return `${item}${claimVendorActive ? '' : ' (Inactive)'}`;
      } else {
        return `${item.name}${item.isActive ? '' : ' (Inactive)'}`;
      }
    },
    [claimVendorActive]
  );

  const renderListItemCallback = useCallback((hit: SearchItem) => {
    return (
      <>
        {hit.name} {hit.isActive ? null : <InactiveText>&nbsp;(Inactive)</InactiveText>}
      </>
    );
  }, []);

  const getErrorMessage = () => {
    if (touched.claimVendorCode && errors.claimVendorCode) {
      return errors.claimVendorCode;
    }
    return '';
  };

  const onSelectedCallback = useCallback(
    (item?: SearchItem) => {
      if (item) {
        setFieldValue('claimVendorCode', item.code);
        setFieldValue('claimVendorName', item.name);
        setFieldValue('claimVendorActive', item.isActive);
      } else {
        setFieldValue('claimVendorCode', '');
        setFieldValue('claimVendorName', '');
        setFieldValue('claimVendorActive', null);
      }
    },
    [setFieldValue]
  );

  return (
    <>
      {disabled ? (
        <StaticVendorField
          disabled
          type="text"
          variant="outlined"
          fullWidth
          value={claimVendorName}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <AutoCompleteSearch
          indexName={Consts.AlgoliaIndex.ClaimVendors}
          {...props}
          defaultRefinement={claimVendorName}
          defaultValue={claimVendorName ?? null}
          noResultMessage="We haven't found any claim vendors that match the text you entered."
          placeholder="Start typing a claim vendor"
          filters={`entityCode:"${config.entityCode}" AND NOT code:"${Consts.Narta.Code}" AND isActive:1`}
          getSelectedValue={getSelectedValueCallback}
          renderDisplayText={renderDisplayTextCallback}
          renderListItem={renderListItemCallback}
          errorMessage={getErrorMessage()}
          onSelected={onSelectedCallback}
          confirm={confirm}
          onConfirm={onConfirmChange}
        />
      )}
    </>
  );
};

export default SPIVClaimVendorSearch;
