import React, {useContext, useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import {styled} from '@mui/material/styles';

import {getProfile} from './app/profileReducer';
import {getConfigs} from './app/configReducer';
import LoadingContext from './app/LoadingContext';
import {useAppDispatch, useAppSelector} from './app/store';
import {
  selectProfileHasRoles,
  selectProfileLoading,
  selectProfileError,
  selectConfigsError,
  selectConfigsLoading,
} from './app/selectors';
import {AuthenticationState} from './utils/AuthProvider';
import AzureADSecuredApp from './components/Auth/AzureADSecuredApp';
import {MessageBody, MessageContainer, PageContainer} from './components/Container';
import NoAppPermission from './components/NoPermission/NoAppPermission';
import router from './Routes/router';

const StyledRootContainer = styled('div')`
  display: flex;
  margin-top: ${(props) => props.theme.mixins.toolbar.height};
  height: ${(props) => `calc(100% - ${props.theme.mixins.toolbar.height})`};
`;

const AppContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.palette.background.page};
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
  position: fixed;
`;

function App({authenticationState, error, login}: any) {
  const profileLoading = useAppSelector(selectProfileLoading);
  const profileError = useAppSelector(selectProfileError);
  const profileHasRoles = useAppSelector(selectProfileHasRoles);
  const configError = useAppSelector(selectConfigsError);
  const configsLoading = useAppSelector(selectConfigsLoading);

  const {showLoading, hideLoading} = useContext(LoadingContext);
  const dispatch = useAppDispatch();

  const showNoPermission = !profileHasRoles && !profileLoading;

  useEffect(() => {
    if (authenticationState === AuthenticationState.Authenticated && !error) {
      dispatch(getProfile());
      dispatch(getConfigs());
    }
  }, [dispatch, authenticationState, error]);

  // each loading state increments/decrements loadingCount: number independently
  useEffect(() => {
    if (configsLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [configsLoading, showLoading, hideLoading]);

  useEffect(() => {
    if (profileLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [profileLoading, showLoading, hideLoading]);

  if (error || profileError || configError) {
    return (
      <PageContainer>
        <MessageContainer>
          <MessageBody>
            <div>An error happened, please try to login again.</div>
            <button onClick={login}>Login</button>
          </MessageBody>
        </MessageContainer>
      </PageContainer>
    );
  }

  return (
    <StyledRootContainer>
      {showNoPermission ? (
        <PageContainer>
          <NoAppPermission />
        </PageContainer>
      ) : (
        <AppContainer>
          <RouterProvider router={router} />
        </AppContainer>
      )}
    </StyledRootContainer>
  );
}

export default AzureADSecuredApp(App);
