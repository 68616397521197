import {Subject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

const alertSubject = new Subject();
const defaultAlertId = 'page-top-alert';

const AlertType = {
  Success: 'Success',
  Info: 'Info',
  Warning: 'Warning',
  Error: 'Error',
};
export {defaultAlertId, AlertType};

export const alertService = {
  onAlert,
  alert,
  clear,
  info,
  warn,
  success,
  error,
};

type AlertOptions = {
  id?: string;
  autoClose?: boolean;
  keepAfterRouteChange?: boolean;
  type?: string;
  response?: any;
};

export type AlertArgs = AlertOptions & {
  message: string;
};

function onAlert(id = defaultAlertId): Observable<AlertArgs> {
  return alertSubject.asObservable().pipe(filter((x: any) => x && x.id === id));
}

function info(message: string, options?: AlertOptions) {
  alert({...(options ?? {}), type: AlertType.Info, message});
}
function warn(message: string, options?: AlertOptions) {
  alert({...(options ?? {}), type: AlertType.Warning, message});
}
function success(message: string, options?: AlertOptions) {
  alert({...(options ?? {}), type: AlertType.Success, message});
}
function error(message: string, options?: AlertOptions) {
  alert({...(options ?? {}), type: AlertType.Error, message});
}

function alert(alert: AlertArgs) {
  alert.id = alert.id || defaultAlertId;
  alert.type = alert.type || AlertType.Error;
  alert.autoClose = alert.autoClose || false;
  alertSubject.next(alert);
}

function clear(id = defaultAlertId) {
  alertSubject.next({id});
}
