import Consts from '../../app/Consts';
import {ValidMixAndMatchFormValues, InitialMixAndMatchFormValues} from '../../types';

const bundleTypes = [
  Consts.MixAndMatchTypeEnum.BuyXPlusYOrMoreForPercentageOffEach,
  Consts.MixAndMatchTypeEnum.BuyXPlusYOrMoreForDollar,
];
export const isBundleType = (type: string | null) => !!type && bundleTypes.includes(type);

export const mnmDisabled = ({
  claimingStatus = '',
  hasFinalClaim = false,
}: ValidMixAndMatchFormValues | InitialMixAndMatchFormValues) => ({
  fields: {
    // page 1 details
    description: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    startAt:
      hasFinalClaim ||
      [Consts.AgreementStatusEnum.InProgress, Consts.AgreementStatusEnum.Finished].includes(
        claimingStatus
      ),
    startTime:
      hasFinalClaim ||
      [Consts.AgreementStatusEnum.InProgress, Consts.AgreementStatusEnum.Finished].includes(
        claimingStatus
      ),
    endAt: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    endTime: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    locationCriteria: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    receiptDescription: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    departments: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    // page 2 values
    amount: hasFinalClaim,
    type:
      hasFinalClaim ||
      [Consts.AgreementStatusEnum.InProgress, Consts.AgreementStatusEnum.Finished].includes(
        claimingStatus
      ),
    groups: {
      amountType: false,
      amount: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      quantity: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      quantityLimit: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      claimOnGroup: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      products: {
        claimAmount: hasFinalClaim,
        claimAmountType: hasFinalClaim,
        gstType: hasFinalClaim,
        productCode: claimingStatus === Consts.AgreementStatusEnum.Finished,
      },
    },
    // page 3 finance details
    financeDetails: {
      claimAmount: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      claimAmountType: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      claimDaysAfterExpiry: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      claimInterval: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      claimVendorCode: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      claimVendorName: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      excludeReturns: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      gstType: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
      comment: false,
      externalPromotionId: false,
      merchandisingPurchaseOrderNumber: false,
      supplierApprovalNumber: false,
    },
  },
  actions: {
    editProduct: hasFinalClaim && claimingStatus === Consts.AgreementStatusEnum.Finished,
    addProduct: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    removeProduct: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    addGroup: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    removeGroup: hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    disableUpdateOnNextStepOne:
      hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
    disableUpdateOnNextStepTwo:
      hasFinalClaim || claimingStatus === Consts.AgreementStatusEnum.Finished,
  },
});
