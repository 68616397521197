import React, {FC, Fragment, useEffect, useCallback, useContext, useState} from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {AxiosResponse} from 'axios';
import {useFormikContext} from 'formik';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {get, api, put} from '../../../utils/Request';
import {
  MixAndMatchFinanceDetailsResponse,
  ValidMixAndMatchFormValues,
  MixAndMatchFormBag,
  EditMixAndMatchFinanceDetailsRequest,
} from '../../../types';
import FormStep from '../../Form/FormStep';
import FieldGrid from '../../Form/FieldGrid';
import StepperFormActionSection from '../../Form/StepperFormActionSection';
import FinanceDetailsAccordion from './FinanceDetailsAccordion';

type Props = {
  step: number;
  title: string;
  validationSchema: any;
  totalStep: number;
  onBack: (values: ValidMixAndMatchFormValues, bag: MixAndMatchFormBag) => void;
  onNext: (values: ValidMixAndMatchFormValues, bag: MixAndMatchFormBag) => void;
  style?: React.CSSProperties;
};

export const getNextRequestData = (
  values: ValidMixAndMatchFormValues
): EditMixAndMatchFinanceDetailsRequest => ({
  mixAndMatchId: values.id,
  editMixAndMatchFinanceDetailsRequests: values.financeDetails ?? [],
});

const StepMixAndMatchFinanceDetails: FC<Props> = ({
  step,
  title,
  validationSchema,
  totalStep,
  onBack,
  onNext,
}) => {
  const [loading, setLoading] = useState(true);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    values: {id: mixAndMatchId, financeDetails, description},
    setFieldValue,
  } = bag;

  const fetchFinanceDetails = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<MixAndMatchFinanceDetailsResponse> = await get(
        api(Consts.Api.MixAndMatchFinanceDetails.replace(':id', `${mixAndMatchId}`))
      );

      setFieldValue(
        'financeDetails',
        // should be able to remove this when api catches up
        response.data.data?.map((item) => ({
          ...(item?.claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry
            ? {claimDaysAfterExpiry: ''}
            : {}),
          ...item,
        }))
      );
      setLoading(false);
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to load finance details', response: error.resposne},
      });
    } finally {
      hideLoading();
    }
  }, [setFieldValue, showLoading, hideLoading, mixAndMatchId]);

  useEffect(() => {
    setFieldValue('financeDetails', []);
    fetchFinanceDetails();
  }, [fetchFinanceDetails, setFieldValue]);

  const handleNext = useCallback(async () => {
    const requestData = getNextRequestData(bag.values);
    showLoading();
    try {
      await put(
        api(Consts.Api.MixAndMatchFinanceDetails.replace(':id', `${mixAndMatchId}`)),
        requestData
      );
      alertService.clear(defaultAlertId);
      onNext(bag.values, bag);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: error.message, response: error.response},
      });
    } finally {
      hideLoading();
    }
  }, [showLoading, hideLoading, mixAndMatchId, bag, onNext]);

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Box sx={{fontWeight: 'bold'}}>Deal name:</Box>
        <Box>{description}</Box>
      </Stack>
      <Stack direction="row" spacing={1} sx={{paddingBottom: '2rem'}}>
        <Box sx={{fontWeight: 'bold'}}>Deal ID:</Box>
        <Box>{mixAndMatchId}</Box>
      </Stack>
      <FormStep step={step} title={title}>
        <FieldGrid item xs={12}>
          <Stack spacing={2}>
            {loading ? null : (
              <>
                {financeDetails?.map((financeDetail, index) => (
                  <Fragment key={index}>
                    <FinanceDetailsAccordion financeDetail={financeDetail} first={index === 0} />
                  </Fragment>
                ))}
              </>
            )}
          </Stack>
        </FieldGrid>
        <StepperFormActionSection
          handleBack={onBack}
          handleNext={handleNext}
          step={step}
          totalStep={totalStep}
        />
      </FormStep>
    </>
  );
};

export default StepMixAndMatchFinanceDetails;
