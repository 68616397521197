import React, {FC, useEffect, useState} from 'react';
import {useFormikContext, FormikTouched, FormikErrors} from 'formik';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Consts from '../../../../app/Consts';
import {MixAndMatchFinanceDetails, ValidMixAndMatchFormValues} from '../../../../types';
import {isNullish} from '../../../../utils';
import {CustomAutoComplete} from '../../../CustomAutoComplete';
import InputField from '../../../Form/InputField';
import {mnmDisabled} from '../../mixAndMatchUtils';
import ClaimVendorSearch, {ClaimVendorSearchItem} from './ClaimVendorSearch';
import ClaimAmountField from './ClaimAmountField';
import Label from './Label';
import FinanceDetailsProducts from './FinanceDetailsProducts';
import Attachments from '../../Attachments';

type Props = {
  financeDetail: MixAndMatchFinanceDetails;
  first: boolean;
};

const FinanceDetailAccordion: FC<Props> = ({financeDetail, first}) => {
  const [expanded, setExpanded] = useState(first);
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    values: {financeDetails},
    setFieldValue,
    errors,
    touched,
  } = bag;

  const index = financeDetails?.findIndex((cd) => cd.id === financeDetail.id);

  const disabledFields = mnmDisabled(bag.values).fields;
  const indexErrors = (
    errors?.financeDetails as FormikErrors<ValidMixAndMatchFormValues['financeDetails']>
  )?.[index as number];
  const indexTouched = (
    touched?.financeDetails as FormikTouched<ValidMixAndMatchFormValues['financeDetails']>
  )?.[index as number];

  useEffect(() => {
    if (!expanded) {
      setExpanded(
        !isNullish(errors?.financeDetails?.[index as number]) &&
          !isNullish(
            (
              touched?.financeDetails as FormikTouched<ValidMixAndMatchFormValues['financeDetails']>
            )?.[index as number]
          )
      );
    }
  }, [errors, touched, index, expanded]);

  const claimIntervalErrorText = indexTouched?.claimInterval ? indexErrors?.claimInterval : '';
  const gstTypeErrorText = indexTouched?.gstType ? indexErrors?.gstType : '';

  const handleChange = (_event: React.SyntheticEvent, _isExpanded: boolean) => {
    setExpanded((ex) => !ex);
  };

  if (!financeDetail || index === -1) {
    return null;
  }
  const {
    claimVendorName,
    groupDescription,
    excludeReturns,
    title,
    claimOnGroup,
    claimInterval,
    claimDaysAfterExpiry,
  } = financeDetail;

  const unsavedTitle =
    !!claimVendorName && !!groupDescription ? `${claimVendorName} - ${groupDescription}` : title;
  const handleUpdate = (field: string, value: any) => {
    setFieldValue(field, value);
  };

  const handleReturnsChange = (_: React.SyntheticEvent, checked: boolean) => {
    handleUpdate(`financeDetails[${index}].excludeReturns`, checked);
  };

  const handleGSTTypeChange = (
    _: React.SyntheticEvent,
    option: {label: string; value: string} | null
  ) => {
    setFieldValue(`financeDetails[${index}].gstType`, option?.value ?? null);
  };

  const handleClaimIntervalChange = (
    _: React.SyntheticEvent,
    option: {label: string; value: string} | null
  ) => {
    handleUpdate(`financeDetails[${index}].claimInterval`, option?.value ?? '');
    setFieldValue(`financeDetails[${index}].claimDaysAfterExpiry`, '');
  };

  const handleClaimVendorChange = (item?: ClaimVendorSearchItem) => {
    handleUpdate(`financeDetails[${index}].claimVendorCode`, item?.code ?? '');
    handleUpdate(`financeDetails[${index}].claimVendorName`, item?.name ?? '');
  };

  return (
    <Accordion
      data-testid="finance-details-accordion"
      expanded={expanded}
      onChange={handleChange}
      slotProps={{transition: {unmountOnExit: true}}}
      sx={{
        background: '#F5F5F5',
        padding: '1rem',
        borderRadius: '1rem',
        '&:before': {display: 'none'},
      }}
    >
      <AccordionSummary sx={{background: 'white'}} expandIcon={<ExpandMoreIcon />}>
        <Typography>{unsavedTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{border: '1px solid #CCCCCC'}}>
        <Stack spacing={5} sx={{marginTop: '2rem'}}>
          <ClaimVendorSearch
            name={`financeDetails[${index}].claimVendorName`}
            onSelect={handleClaimVendorChange}
            disabled={disabledFields.financeDetails.claimVendorCode}
          />
          <Stack spacing={2} direction="row">
            {claimOnGroup === Consts.MixAndMatchGroupClaimedEnum.ClaimVendor ? (
              <ClaimAmountField
                onChange={handleUpdate}
                detailKey={`financeDetails[${index}]`}
                disabled={disabledFields.financeDetails.claimAmount}
              />
            ) : null}
            {claimOnGroup === Consts.MixAndMatchGroupClaimedEnum.ClaimVendor ? (
              <Stack spacing={1} direction="column" sx={{width: '100%'}}>
                <Label htmlFor="gstType">GST Type</Label>
                <CustomAutoComplete
                  fullWidth
                  value={
                    Consts.GstType.find((item) => item.value === financeDetail.gstType) ?? null
                  }
                  options={Consts.GstType}
                  onChange={handleGSTTypeChange}
                  id={`financeDetails[${index}].gstType`}
                  errorText={gstTypeErrorText}
                  disabled={disabledFields.financeDetails.gstType}
                />
              </Stack>
            ) : null}
          </Stack>
          <Stack spacing={1} direction="column">
            <Label htmlFor="supplierApprovalNumber">Supplier Approval Number (Optional)</Label>
            <InputField name={`financeDetails[${index}].supplierApprovalNumber`} fullWidth />
          </Stack>
          <Stack spacing={1} direction="column">
            <Label htmlFor="merchandisingPurchaseOrderNumber">
              Merch PO Number / Internal ID (Optional)
            </Label>
            <InputField
              name={`financeDetails[${index}].merchandisingPurchaseOrderNumber`}
              fullWidth
            />
          </Stack>
          <Stack spacing={1} direction="column">
            <Label htmlFor="externalPromotionId">Advertising / STACK Promo ID (Optional)</Label>
            <InputField name={`financeDetails[${index}].externalPromotionId`} fullWidth />
          </Stack>
          <Stack spacing={1} direction="column">
            <Label htmlFor="comments">Comments (Optional)</Label>
            <InputField name={`financeDetails[${index}].comment`} fullWidth multiline rows={4} />
          </Stack>
          <Stack spacing={1} direction="column">
            <Typography variant="h6" sx={{paddingTop: '0.75rem'}}>
              {groupDescription}
            </Typography>
            <FinanceDetailsProducts
              financeDetailsId={financeDetail.id}
              showClaimAmount={claimOnGroup === Consts.MixAndMatchGroupClaimedEnum.SKU}
            />
          </Stack>
          <Attachments financeDetailId={financeDetail.id} />
          <Stack spacing={1} direction="column">
            <Label htmlFor="claimInterval">When is this deal going to be claimed?</Label>
            <CustomAutoComplete
              value={
                Consts.DealClaimInterval.find((ci) => ci.value === financeDetail.claimInterval) ??
                null
              }
              options={Consts.DealClaimInterval}
              onChange={handleClaimIntervalChange}
              id={`financeDetails[${index}].claimInterval`}
              errorText={claimIntervalErrorText}
              disabled={disabledFields.financeDetails.claimInterval}
            />
            {claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry ? (
              <Stack spacing={1} direction="column">
                <Label htmlFor="claimDaysAfterExpiry" sx={{marginTop: '1.25rem !important'}}>
                  Number of days past expiry date
                </Label>
                <InputField
                  id={`financeDetails[${index}].claimDaysAfterExpiry`}
                  name={`financeDetails[${index}].claimDaysAfterExpiry`}
                  value={claimDaysAfterExpiry ?? ''}
                  data-testid="claim-days-after-expiry"
                  disabled={disabledFields.financeDetails.claimDaysAfterExpiry}
                />
              </Stack>
            ) : null}
          </Stack>
          <FormControlLabel
            control={<Checkbox checked={excludeReturns} />}
            onChange={handleReturnsChange}
            label="Exclude returns when claiming (*Note including returns will reduce margin)"
            sx={{
              '& > span:first-of-type': {
                paddingLeft: 0,
              },
            }}
            disabled={disabledFields.financeDetails.excludeReturns}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default FinanceDetailAccordion;
