/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as R from 'ramda';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {
  debounceSearchForOwners,
  Owner,
  searchOwners,
  SearchOwnersRequest,
} from '../../../app/ownersReducer';
import {SearchIcon} from '../../Icons/Icon';

export interface OwnersSearchProps {
  value: Owner | null;
  my: boolean;
  onChange: (_: any, option: Owner | null) => void;
}

function OwnersSearch(props: OwnersSearchProps) {
  const {value, my, onChange} = props;
  const {owners, loadingOwners} = useAppSelector((store) => store.owners);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (my) {
      return;
    }
    const ownersRequest: SearchOwnersRequest = {
      text: value?.name ?? '',
      pageSize: 20,
    };

    dispatch(searchOwners(ownersRequest));
  }, []);

  return (
    <Autocomplete
      popupIcon={null}
      options={owners}
      value={value}
      getOptionLabel={(option: Owner) => option.name}
      loading={loadingOwners}
      filterOptions={(x: any) => x}
      filterSelectedOptions={false}
      onInputChange={(_: any, newInputValue: string) => {
        if (my) {
          return;
        }
        debounceSearchForOwners(dispatch, newInputValue);
      }}
      onChange={(e: any, value: Owner | null) => onChange(e, value)}
      isOptionEqualToValue={(option, value) => {
        return R.equals(option, value);
      }}
      disabled={my}
      renderInput={(params) => {
        return (
          <TextField
            variant="outlined"
            {...params}
            sx={{paddingRight: 0}}
            placeholder="All owners"
            name="owners"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    {loadingOwners && <CircularProgress color="inherit" size={20} />}
                    <SearchIcon
                      style={{
                        position: 'absolute',
                        right: 18,
                        top: 18,
                        width: 17.49,
                        height: 17.49,
                      }}
                    />
                  </InputAdornment>
                </>
              ),
            }}
          />
        );
      }}
    />
  );
}

export default OwnersSearch;
