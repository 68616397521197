import React from 'react';
import Consts from '../../app/Consts';
import {useAppDispatch, useAppSelector} from '../../app/store';
import {UnreleasedAmountsTabType, updateType} from '../../app/unreleasedAmountsReducer';
import {PageTabs} from '../PageTabs';
import TabComponent from '../TabComponent';

function UnreleasedHeader() {
  const dispatch = useAppDispatch();
  const {tabType: type} = useAppSelector((state) => state.unreleasedAmounts);

  const isLoading = useAppSelector(
    (state) => state.unreleasedProducts.isLoading || state.unreleasedRebates.isLoading
  );
  const anyReleasedProducts = useAppSelector(
    (state) => state?.unreleasedProducts?.data?.filter((x) => x.markedForRelease).length > 0
  );
  const anyReleasedRebates = useAppSelector(
    (state) => state?.unreleasedRebates?.data?.filter((x) => x.markedForRelease).length > 0
  );

  return (
    <PageTabs
      title="Unreleased Amounts"
      value={type === UnreleasedAmountsTabType.None ? false : type.toString()}
      ariaLabel="Filter unreleased amounts by type"
      onChange={(_, newType) => {
        dispatch(updateType(newType));
      }}
      tabsList={[]}
    >
      <TabComponent
        key="Product"
        label="By Product"
        value="Product"
        disabled={isLoading || anyReleasedRebates}
        tooltipText={Consts.UnreleasedAmountsInactiveMessage.Product}
      />
      <TabComponent
        key="Rebate"
        label="By Rebate"
        value="Rebate"
        disabled={isLoading || anyReleasedProducts}
        tooltipText={Consts.UnreleasedAmountsInactiveMessage.Rebate}
      />
    </PageTabs>
  );
}

export default UnreleasedHeader;
