import React, {FC, useContext, useEffect, useState, useCallback} from 'react';
import {AxiosResponse} from 'axios';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {api, get, del} from '../../utils/Request';
import {AttachmentType} from '../../types';
import {DeleteActionButton} from '../Table';
import {DownloadActionButton} from '../Table/ActionIconButton';
import {Button as UploadButton} from '../Button';
import FilesUploader, {classes as fileUploaderClasses} from '../DragDrop/FilesUploader';
import Attachment from '../Attachment/Attachment';
import AuthRequired from '../Auth/RequireAuth';

const AddAttachmentButton = styled(Button)`
  display: flex;
  flex-direction: row;
  color: ${({theme}) => theme.palette.expandHint.main};
  font-weight: 400;
  gap: 0.5rem;
  height: fit-content;
  justify-content: flex-start;
  padding: 0;
`;

const PREFIX = 'FilesUploader';

export const classes = {
  cancelIcon: `${PREFIX}-cancelIcon`,
  circularProgress: `${PREFIX}-circularProgress`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  emptyContainer: `${PREFIX}-emptyContainer`,
  headingButton: `${PREFIX}-headingButton`,
  headingContainer: `${PREFIX}-headingContainer`,
  root: `${PREFIX}-root`,
  subTitle: `${PREFIX}-subTitle`,
  title: `${PREFIX}-title`,
};

const FileUploaderRoot = styled('div')(() => ({
  width: '100%',
  [`& .${fileUploaderClasses.root}`]: {
    border: 'none !important',
    padding: '0 !important',
  },
  [`& .${fileUploaderClasses.headingButton}`]: {
    alignSelf: 'flex-end',
  },
  [`& .${fileUploaderClasses.emptyContainer}`]: {
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 6px #00000029',
  },
}));

type Props = {
  financeDetailId: number;
  show?: boolean;
};

type UploadResponse = AxiosResponse<AttachmentType[]>;

const Attachments: FC<Props> = ({financeDetailId, show}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [showUploader, setShowUploader] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  useEffect(() => {
    setShowUploader(attachments?.length > 0 || Boolean(show));
  }, [attachments, show]);

  const loadAttachments = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<AttachmentType[]> = await get(
        api(Consts.Api.Attachments).replace(':id', financeDetailId) +
          '?referenceType=MixAndMatchFinanceDetails'
      );
      setAttachments(response.data ?? []);
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to load attachments', response: error.response},
      });
    } finally {
      hideLoading();
    }
  }, [financeDetailId, hideLoading, showLoading]);

  useEffect(() => {
    loadAttachments();
  }, [loadAttachments]);

  const uploadComplete = (response: UploadResponse) => {
    setAttachments((prevAttachments) => [...prevAttachments, ...(response.data ?? [])]);
  };

  const handleAddAttachmentClick = () => {
    if (attachments?.length === 0) {
      setShowUploader((prev) => !prev);
    }
  };

  const deleteAttachment = async (attachmentId: AttachmentType['id']) => {
    try {
      showLoading();
      await del(
        api(Consts.Api.Attachment).replace(':id', attachmentId) +
          '?referenceType=MixAndMatchFinanceDetails'
      );
      setAttachments((prevAttachments) => prevAttachments.filter((x) => x.id !== attachmentId));
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const downloadAttachment = async (attachment: AttachmentType) => {
    try {
      showLoading();

      const {data} = await get(
        api(Consts.Api.AttachmentDownload).replace(':id', attachment.id) +
          '?referenceType=MixAndMatchFinanceDetails',
        {
          responseType: 'blob',
        }
      );

      const link = document.createElement('a');
      const url = URL.createObjectURL(new Blob([data]));
      link.href = url;
      link.download = attachment.fileName;
      link.click();
      link.remove();
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Box sx={{width: '100%'}}>
      {attachments?.length === 0 && !showUploader ? (
        <AddAttachmentButton variant="text" onClick={handleAddAttachmentClick}>
          <AddCircleOutlineOutlinedIcon />
          Add Attachments
        </AddAttachmentButton>
      ) : null}
      {showUploader ? (
        <FileUploaderRoot>
          <FilesUploader
            maxSizeInMb={20}
            multiple={true}
            apiEndpoint={
              api(Consts.Api.Attachment).replace(':id', financeDetailId) +
              '?referenceType=MixAndMatchFinanceDetails'
            }
            accept={{
              'image/png': [],
              'image/jpeg': [],
              'image/jpg': [],
              'application/pdf': [],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // for docx
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
              'message/rfc822': ['.eml'], // for eml
              'text/csv': ['.csv'], // for csv
              'application/vnd.ms-outlook': ['.msg'], // for msg
            }}
            title="There are no attachments in this deal yet."
            subTitle="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
            onComplete={uploadComplete}
            noFiles={attachments.length === 0}
            style={{container: {padding: 0}}}
            button={UploadButton as any}
            buttonLabel="Add your first attachment"
            continueAddButtonLabel="Add New Attachment"
            heading="Attachments"
          >
            <div>
              {attachments?.map?.((attachment, index) => (
                <Attachment
                  key={index}
                  fileName={attachment.fileName}
                  sx={{padding: '0.625rem 1.875rem'}}
                >
                  <DownloadActionButton
                    style={{
                      color: 'green',
                    }}
                    onClick={() => {
                      downloadAttachment(attachment);
                    }}
                  ></DownloadActionButton>
                  <AuthRequired
                    requiredRoles={{all: [Consts.UserRoleEnum.AddOrUpdateDeals]}}
                    alt={null}
                  >
                    <DeleteActionButton
                      style={{marginLeft: '1rem'}}
                      onClick={() => {
                        deleteAttachment(attachment.id);
                      }}
                    ></DeleteActionButton>
                  </AuthRequired>
                </Attachment>
              ))}
            </div>
          </FilesUploader>
        </FileUploaderRoot>
      ) : null}
    </Box>
  );
};

export default Attachments;
