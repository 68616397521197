import React, {FC, useMemo} from 'react';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import {useField} from 'formik';
import {CustomAutoComplete, Props as AutoCompleteProps} from '../CustomAutoComplete';

type Props = AutoCompleteProps & {
  onChanged?: (value: any) => void;
  defaultOption?: any;
  valueFunc?: (x: any) => any;
  labelFunc?: (x: any) => any;
  name: string;
  loading?: boolean;
};

const SelectField: FC<Props> = ({
  defaultOption,
  errorText,
  labelFunc,
  loading,
  name,
  onChanged,
  options,
  placeholder,
  valueFunc,
  ...autoCompleteProps
}) => {
  const [field, meta, helpers] = useField({name});
  const {setValue} = helpers;

  const handleLabel = useMemo(
    () => labelFunc ?? ((option: any) => option.label ?? ''),
    [labelFunc]
  );
  const handleValue = useMemo(() => valueFunc ?? ((option: any) => option.value), [valueFunc]);
  const selectedOption = useMemo(
    () => options.find((x) => handleValue(x) === field.value) || defaultOption || null,
    [field.value, options, defaultOption, handleValue]
  );

  const errorMessage = useMemo(() => {
    const {touched, error} = meta;
    const invalidOption = field.value && options.every((option) => option.value !== field.value);
    if (error && (invalidOption || touched)) {
      return errorText || meta.error;
    }
  }, [meta, options, field.value, errorText]);

  return (
    <CustomAutoComplete
      value={selectedOption}
      name={name}
      options={options}
      getOptionLabel={(option: any) => handleLabel(option) ?? ''}
      onChange={(_event, option) => {
        setValue(option ? handleValue(option) : null);
        onChanged?.(option ? handleValue(option) : null);
      }}
      renderInput={(params: TextFieldProps) => (
        <TextField
          {...params}
          variant="outlined"
          type="text"
          error={!!errorMessage}
          helperText={errorMessage}
          placeholder={placeholder}
          name={name}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
      loading={loading}
      {...autoCompleteProps}
    />
  );
};
export default SelectField;
