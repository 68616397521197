import React, {FC} from 'react';
import {ButtonProps} from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {styled} from '@mui/material/styles';

const Button = styled('button')`
  background-color: ${({theme}) => theme.palette.domainSpecific.addButton.background};
  color: ${({theme}) => theme.palette.domainSpecific.addButton.color};
  width: 16.125rem;
  height: 4.375rem;
  font-weight: 700;
  border: none;
  font-size: 1rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: unset;
  &:hover {
    background-color: #626262;
    cursor: pointer;
    > hr {
      background-color: #cbd2d8;
      width: 0.5px;
    }
  }
`;

const IconContainer = styled('span')`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.25rem;
`;

const LabelContainer = styled('span')`
  flex-grow: 1;
`;

const StyledDivider = styled(Divider)`
  background-color: #676767;
  width: 1.5px;
  height: auto;
  align-self: stretch;
  border: none;
  margin: 0;
  flex-shrink: 0;
`;

type Props = {
  icon: React.ElementType;
  children: React.ReactNode;
} & ButtonProps;

const IconButton: FC<Props> = ({icon: Component, children, ...otherProps}) => (
  <Button type="button" {...otherProps}>
    <IconContainer>
      <Component />
    </IconContainer>
    <StyledDivider orientation="vertical" flexItem />
    <LabelContainer>{children}</LabelContainer>
  </Button>
);

export default IconButton;
