import React from 'react';
import Grid from '@mui/material/Grid';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {clearProductFilters} from '../../../app/unreleasedProductsReducer';
import {selectProductNumberOfFilters} from '../../../app/selectors';
import {LinkButton} from '../../Button';

function ClearProductFiltersButton() {
  const dispatch = useAppDispatch();
  const selectedProductFiltersCount = useAppSelector(selectProductNumberOfFilters);

  return (
    <>
      {selectedProductFiltersCount > 0 ? (
        <Grid container justifyContent="flex-end" sx={{marginTop: '0.625rem'}}>
          <LinkButton
            onClick={() => {
              dispatch(clearProductFilters());
            }}
          >
            Clear
          </LinkButton>
        </Grid>
      ) : null}
    </>
  );
}

export default ClearProductFiltersButton;
