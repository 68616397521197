import Consts from '../../app/Consts';
import {hasRole} from '../../app/Permission';
import {FinanceAccount} from '../../app/financialAccountsReducer';
import {ClaimSideFilters} from '../../app/claimsReducer';
import {getDealType, getRebateType, getDealSubType} from '../../utils/TypesStatusUtils';
import {
  ClaimListItemViewModel,
  Department,
  ListPageParamOptions,
  ClaimTypeRequestParam,
} from '../../types';

export type GetClaimsOptions = ListPageParamOptions<ClaimSideFilters>;

export const transformClaimTypes = (types: ClaimTypeRequestParam[] | null) =>
  types?.map((type) => {
    const {agreementType, rebateType, agreementSubType} = type.value;
    return `${agreementType}:${rebateType || ''}:${
      agreementSubType || Consts.AgreementSubTypeEnum.Standard
    }`;
  });

export const mapGetClaimsParams = (options: GetClaimsOptions, staffCode?: number) => {
  let result: any = {};
  const type = options?.types ? transformClaimTypes(options.types) : null;
  const departmentNumber = options?.departments?.map((x: Department) => x.number) ?? null;
  const financeAccountId = options?.financeAccounts?.map((x: FinanceAccount) => x.id) ?? null;
  const claimVendorCode = options?.claimVendor?.code ?? null;
  const claimFrequency = options?.claimFrequency?.value ?? null;
  const startAt = options?.startAt ?? null;
  const endAt = options?.endAt ?? null;
  const ownedByStaffCode = staffCode ? staffCode : options?.owner?.code ?? null;
  const paymentPriority = options?.paymentPriority ?? null;
  const excludeClaimVendorCode = options?.excludeNartaInternational ? Consts.Narta.Code : null;
  const onlyReturnAdjustedClaims = options?.onlyReturnAdjustedClaims ?? null;
  const claimStatus = options?.status ?? null;
  const searchText = options?.searchText ?? null;
  const totalCount = !isNaN(options?.totalCount) ? options.totalCount : null;
  const totalPages = !isNaN(options?.totalPages) ? options.totalPages : null;
  const pageSize = !isNaN(options?.pageSize) ? options.pageSize : null;
  const currentPage = !isNaN(options?.currentPage) ? options.currentPage : null;
  const orderBy =
    options?.orderBy && options?.orderByDirection
      ? `${options.orderBy}:${options.orderByDirection}`
      : null;

  const queryParams = [
    {name: 'type', value: type},
    {name: 'departmentNumber', value: departmentNumber},
    {name: 'financeAccountId', value: financeAccountId},
    {name: 'claimVendorCode', value: claimVendorCode},
    {name: 'claimFrequency', value: claimFrequency},
    {name: 'startAt', value: startAt},
    {name: 'endAt', value: endAt},
    {name: 'ownedByStaffCode', value: ownedByStaffCode},
    {name: 'paymentPriority', value: paymentPriority},
    {name: 'excludeClaimVendorCode', value: excludeClaimVendorCode},
    {name: 'onlyReturnAdjustedClaims', value: onlyReturnAdjustedClaims},
    {name: 'status', value: claimStatus},
    {name: 'searchText', value: searchText},
    {name: 'totalPages', value: totalPages},
    {name: 'pageSize', value: pageSize},
    {name: 'currentPage', value: currentPage},
    {name: 'totalCount', value: totalCount},
    {name: 'orderBy', value: orderBy},
  ];

  queryParams.forEach((x) => {
    if (x.value !== undefined && x.value !== null && x.value?.length !== 0) {
      result[x.name] = x.value;
    }
  });
  return result;
};

export const getGSTType = ({totalClaimAmount, claimTaxAmount}: ClaimListItemViewModel) => {
  if (totalClaimAmount !== 0 && claimTaxAmount === 0) {
    return ' free';
  } else if (claimTaxAmount !== 0) {
    return ' inc.';
  } else {
    return ' ex.';
  }
};

export const showRowSelect = (rowData: ClaimListItemViewModel, roles?: string[]): boolean => {
  const canManageDealClaim = hasRole(Consts.UserRoleEnum.ManageDealClaims, roles);
  const canManageRebateClaim = hasRole(Consts.UserRoleEnum.ManageContractAgreementClaims, roles);
  if (canManageDealClaim && !canManageRebateClaim) {
    return ![
      Consts.ClaimStatusEnum.Finalised,
      Consts.ClaimStatusEnum.Invoiced,
      Consts.ClaimStatusEnum.NoClaimRaised,
      Consts.ClaimStatusEnum.Raised,
    ].includes(rowData.status);
  }
  if (canManageDealClaim && canManageRebateClaim) {
    return ![Consts.ClaimStatusEnum.NoClaimRaised, Consts.ClaimStatusEnum.Raised].includes(
      rowData.status
    );
  }
  return false;
};

export const displayClaimType = (rowData: ClaimListItemViewModel) => {
  if (Boolean(rowData.parentClaimId)) {
    return '';
  } else if (rowData.agreementType === Consts.AgreementTypeEnum.Deal) {
    return `Deal: ${
      rowData.agreementSubType === Consts.AgreementSubTypeEnum.Standard
        ? getDealType(rowData.rebateType)
        : getDealSubType(rowData.agreementSubType)
    }`;
  } else {
    return `Rebate: ${getRebateType(rowData.rebateType)}`;
  }
};
