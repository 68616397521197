import React, {FC} from 'react';
import {ButtonProps} from '@mui/material/Button';
import {styled} from '@mui/material/styles';

const StyledButton = styled('button')`
  background-color: ${({theme}) => theme.palette.background.button};
  color: ${({theme}) => theme.palette.button.main};
  width: 15rem;
  height: 4.375rem;
  font-weight: 700;
  border: none;
  font-size: 1rem;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

type Props = {
  children: React.ReactNode;
} & ButtonProps;

const Button: FC<Props> = ({children, ...props}) => (
  <StyledButton {...props}>{children}</StyledButton>
);

export default Button;
