import {styled} from '@mui/material/styles';
import PageContentContainer from './PageContentContainer';

const MessageContainer = styled(PageContentContainer)`
  text-align: center;
  margin-top: 8rem;
`;
const MessageHeader = styled('div')`
  font-size: 1.75rem;
  font-weight: 500;
  margin-top: 2rem;
`;

const MessageBody = styled('div')`
  font-size: 1.125rem;
  margin-top: 1.3125rem;
  line-height: 2.25rem;
`;

export default MessageContainer;
export {MessageHeader, MessageBody};
