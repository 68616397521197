import {connectCurrentRefinements} from 'react-instantsearch-dom';
import React from 'react';
import * as R from 'ramda';
import Tag from './../../Button/Tag';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const TagWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;
const RefinementTitle = styled('div')`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const RefinementContainer = styled('div')`
  margin-top: 50px;
`;

const CurrentRefinements = ({
  items,
  refine,
  renderLabel = (label, attribute) => label,
  createURL,
  inclusive = true,
  criteria,
  onDelete = (attribute, value) => {},
  readOnly = false,
  ...otherProps
}) => {
  const uniqItems = R.uniqWith(R.eqProps('attribute'))(items);

  function itemKey(label) {
    return `${label} -  ${inclusive ? 'inclusive' : 'exclusive'}`;
  }
  function displayLabel(label, attribute) {
    let newLabel = inclusive ? label : label.slice(1);
    return renderLabel(newLabel, attribute);
  }
  function refinementTitle(item) {
    if (criteria) {
      const option = criteria.find((option) => option.value === item.attribute);
      return option.pluralLabel;
    }
    return item.label;
  }
  function renderList(item) {
    const filteredItems = item.items.filter((nested) =>
      inclusive ? nested.label[0] !== '-' : nested.label[0] === '-'
    );
    if (filteredItems && filteredItems.length > 0) {
      return (
        <>
          <RefinementTitle>{refinementTitle(item)}</RefinementTitle>
          <TagWrapper>
            {filteredItems
              .filter((nested) => (inclusive ? nested.label[0] !== '-' : nested.label[0] === '-'))
              .map((nested) => (
                <Tag
                  key={itemKey(nested.label)}
                  label={displayLabel(nested.label, item.attribute)}
                  onClose={(event) => {
                    event.preventDefault();
                    refine(nested.value);
                    onDelete(item.attribute, nested.label, inclusive);
                  }}
                  readOnly={readOnly}
                />
              ))}
          </TagWrapper>
          <Divider />
        </>
      );
    }
    return null;
  }
  return (
    <div>
      {uniqItems.map((item) => (
        <RefinementContainer key={itemKey(item.label)}>{renderList(item)}</RefinementContainer>
      ))}
    </div>
  );
};

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);

export default CustomCurrentRefinements;
