import React from 'react';
import {styled} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import {useField} from 'formik';

const PREFIX = 'StaticField';

const classes = {
  input: `${PREFIX}-input`,
};

const StyledInputBase = styled(InputBase)(() => ({
  [`& .${classes.input}`]: {
    height: '1rem',
    caretColor: 'transparent',
  },
}));

export default function StaticField(props) {
  const [field] = useField(props);

  return <StyledInputBase readOnly {...field} {...props} classes={classes} />;
}
