import React from 'react';
import NoPermission from './NoPermission';
import SupportMessage from './SupportMessage';

const NoAppPermission = () => {
  return (
    <NoPermission title="You don't have access to Rebates.">
      <div>It looks like you haven't been given the correct permissions to access Rebates.</div>
      <SupportMessage />
    </NoPermission>
  );
};

export default NoAppPermission;
