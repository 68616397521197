import React, {FC, useState, useEffect, useCallback, useContext} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {getDisplayAmountValue} from '../../../utils';
import {api, get} from '../../../utils/Request';
import {
  MixAndMatchSummaryGroupProductsResponse,
  MixAndMatchSummaryGroupProduct,
  MixAndMatchFinanceDetailsSummary,
  Pagination,
} from '../../../types';
import PageNavigator from '../../../components/Table/Pagination/PageNavigator';
import PageCounter from '../../../components/Table/Pagination/PageCounter';
import PageSetting from '../../../components/Table/Pagination/PageSetting';
import SectionContainer from '../../../components/Table/Pagination/SectionContainer';

const PREFIX = 'MixAndMatchSummaryValuesTable';

const classes = {
  tableHeading: `${PREFIX}-tableHeading`,
  tableHeadingCell: `${PREFIX}-tableHeadingCell`,
  tableCell: `${PREFIX}-tableCell`,
  tableFirstRow: `${PREFIX}-tableFirstRow`,
  tableRow: `${PREFIX}-tableRow`,
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableHeading}`]: {
    backgroundColor: '#000000',
    color: '#FFFFFF',
  },
  [`& .${classes.tableHeadingCell}`]: {
    color: '#FFFFFF',
  },
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.tableFirstRow}`]: {
    paddingLeft: '1.875rem',
  },
  [`& .${classes.tableRow}`]: {
    boxShadow: '0px 3px 6px #00000029',
  },
});

const CellContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;

const TablePaginationContainer = styled(SectionContainer)`
  justify-content: space-between;
  height: 3.75rem;
  padding: 1.25rem 1.125rem 0;
  background-color: #f2f4f5;

  input,
  .MuiSelect-select {
    background-color: #ffffff;
  }
`;

type CustomTableColumn = {
  field: string;
  title: string | React.ReactNode;
  render?: (rowData: MixAndMatchSummaryGroupProduct, rowIndex: number) => React.ReactNode;
  hide?: boolean;
};

type Props = {
  valuesResponse: MixAndMatchSummaryGroupProductsResponse;
  financeDetailsId: number;
};

const MixAndMatchSummaryValuesTable: FC<Props> = ({valuesResponse, financeDetailsId}) => {
  const [data, setData] = useState<MixAndMatchSummaryGroupProduct[]>([]);
  const [pagination, setPagination] = useState<Pagination>(Consts.DefaultPagination);
  const {showLoading, hideLoading} = useContext(LoadingContext);

  useEffect(() => {
    const {data, ...nextPagination} = valuesResponse ?? {};
    setData(data);
    setPagination(nextPagination);
  }, [valuesResponse]);

  const getColumns = () => {
    const columns: CustomTableColumn[] = [
      {
        field: 'id',
        title: 'ID',
        render: (rowData) => rowData.id,
      },
      {
        field: 'claimAmount',
        title: 'Claim Amount',
        render: renderAmountColumn,
      },
      {
        field: 'gstType',
        title: 'GST',
        render: (rowData) => rowData.gstType,
      },
      {
        field: 'sku',
        title: 'SKU',
        render: (rowData) =>
          rowData.productCode ? `${rowData.productCode} ${rowData.productDescription ?? ''}` : null,
      },
      {
        field: 'ticketPrice',
        title: 'Ticket Price',
        render: (rowData) => getDisplayAmountValue(rowData.ticketPrice, '$'),
      },
      {
        field: 'amountSoldOrPurchased',
        title: 'Amount Sold',
        render: (rowData) => rowData.amountSoldOrPurchased,
      },
      {
        field: 'accruedTotal',
        title: 'Accrued Total (ex)',
        render: (rowData) => getDisplayAmountValue(rowData.accruedAmount, '$'),
      },
      {
        field: 'gstTotal',
        title: 'GST Total',
        render: (rowData) => getDisplayAmountValue(rowData.gstAmount, '$'),
      },
    ];
    return columns;
  };

  const loadMixAndMatchValues = useCallback(
    async (pagination: Partial<Pagination>) => {
      try {
        showLoading();
        const response: AxiosResponse<MixAndMatchFinanceDetailsSummary> = await get(
          api(
            Consts.Api.MixAndMatchFinanceDetailsSummary.replace(
              ':financeDetailsId',
              `${financeDetailsId}`
            )
          ),
          {
            params: pagination ?? {},
          }
        );
        const {mixAndMatchGroupProducts} = response.data ?? {};
        const {data, ...rest} = mixAndMatchGroupProducts ?? {};
        setData(data as unknown as MixAndMatchSummaryGroupProduct[]); // TODO: Fix type when api response is fixed
        setPagination(rest);
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load deal values', response: error.response},
        });
      } finally {
        hideLoading();
      }
    },
    [financeDetailsId, showLoading, hideLoading]
  );

  const renderAmountColumn = (rowData: MixAndMatchSummaryGroupProduct) => {
    const unit = Consts.AmountType.find((x) => x.value === rowData.claimAmountType);
    if (unit?.value === Consts.AmountTypeEnum.FixedAmount) {
      return getDisplayAmountValue(rowData.claimAmount, '$');
    }
    if (unit?.value === Consts.AmountTypeEnum.ValuePerUnit) {
      return getDisplayAmountValue(rowData.claimAmount, '$', ' per unit');
    }
    return getDisplayAmountValue(rowData.claimAmount, '', '%');
  };

  const onChangePage = (currentPage: Pagination['currentPage']) => {
    loadMixAndMatchValues({currentPage, pageSize: pagination.pageSize});
  };

  const onChangePageSize = (pageSize: Pagination['pageSize']) => {
    loadMixAndMatchValues({currentPage: 1, pageSize});
  };

  const renderCell = (
    column: CustomTableColumn,
    row: MixAndMatchSummaryGroupProduct,
    rowIndex: number
  ): React.ReactNode | string | number | null => {
    if (column.render) {
      return <CellContainer>{column.render(row, rowIndex)}</CellContainer>;
    }
    return row[column.field as keyof MixAndMatchSummaryGroupProduct] as string | number | null;
  };

  const renderRow = (row: MixAndMatchSummaryGroupProduct, rowIndex: number) => (
    <TableRow className={classes.tableRow} key={rowIndex}>
      {getColumns().map((column, columnIndex) => (
        <TableCell className={columnIndex === 0 ? classes.tableFirstRow : ''} key={columnIndex}>
          {renderCell(column, row, rowIndex)}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <StyledTableContainer>
      <Table>
        <TableHead className={classes.tableHeading}>
          <TableRow>
            {getColumns().map((column, index) => (
              <TableCell
                className={
                  index === 0
                    ? `${classes.tableHeadingCell} ${classes.tableFirstRow}`
                    : classes.tableHeadingCell
                }
                key={index}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{data?.map((row, index) => renderRow(row, index))}</TableBody>
      </Table>
      <TablePaginationContainer>
        <PageCounter pagination={pagination} />
        <PageNavigator pagination={pagination} onChangePage={onChangePage} />
        <PageSetting pagination={pagination} onChangePageSize={onChangePageSize} />
      </TablePaginationContainer>
    </StyledTableContainer>
  );
};

export default MixAndMatchSummaryValuesTable;
