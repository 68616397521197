import React from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const IconLabel = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 33px;
    margin-right: 10px;
  }

  h5 {
    font-size: 16px;
    text-transform: uppercase;
  }
`;

const IconLabelComponent = ({icon, label, ...props}) => {
  return (
    <IconLabel {...props}>
      {icon}
      <Typography variant="h5">{label}</Typography>
    </IconLabel>
  );
};

export {IconLabelComponent};
