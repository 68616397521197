import React, {FC, useEffect, useContext, useCallback} from 'react';
import {useFormikContext} from 'formik';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {AxiosResponse} from 'axios';
import {api, get, put} from '../../utils/Request';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {
  ValidSPIVFormValues,
  SPIVFormBag,
  SpivFinanceDetailsResponse,
  EditSpivFinanceDetailsRequest,
  EntityActionType,
} from '../../types';
import {CustomAutoComplete} from '../CustomAutoComplete';
import FieldGrid from '../Form/FieldGrid';
import FieldLabel from '../Form/FieldLabel';
import FormStep from '../Form/FormStep';
import InputField from '../Form/InputField';
import OptionalIndicator from '../Form/OptionalIndicator';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';
import SPIVDetailsHeader from './SPIVDetailsHeader';
import {spivDisabled} from './spivUtils';

export const getNextRequestData = (values: ValidSPIVFormValues): EditSpivFinanceDetailsRequest => ({
  comment: values.comment ?? '',
  claimDaysAfterExpiry: values.claimDaysAfterExpiry,
  claimInterval: values.claimInterval,
  supplierApprovalNumber: values.supplierApprovalNumber ?? '',
  excludeReturns: Boolean(values.excludeReturns),
});

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  onBack: (values: ValidSPIVFormValues, bag: SPIVFormBag) => void;
  onNext: (values: ValidSPIVFormValues, bag: SPIVFormBag) => void;
  style?: React.CSSProperties;
};

const StepFinanceDetails: FC<Props> = ({step, title, totalStep, onBack, onNext}) => {
  const bag = useFormikContext<ValidSPIVFormValues>();
  const {
    values: {
      claimDaysAfterExpiry,
      claimInterval,
      excludeReturns,
      hasActiveClaim,
      hasRaisedClaim,
      id,
      supplierApprovalNumber,
    },
    setFieldValue,
    errors,
    touched,
  } = bag;

  const {showLoading, hideLoading} = useContext(LoadingContext);
  const claimIntervalErrorText = touched?.claimInterval ? errors?.claimInterval : '';

  const {fields: disabledFields} = spivDisabled(bag.values);

  const fetchFinanceDetails = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<SpivFinanceDetailsResponse> = await get(
        api(Consts.Api.SpivFinanceDetails.replace(':id', `${id}`))
      );

      Object.keys(response.data).forEach((key) => {
        const value = response.data?.[key as keyof SpivFinanceDetailsResponse];
        if (value !== null) {
          setFieldValue(key, value);
        }
      });
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to load finance details', response: error.resposne},
      });
    } finally {
      hideLoading();
    }
  }, [setFieldValue, showLoading, hideLoading, id]);

  useEffect(() => {
    fetchFinanceDetails();
  }, [fetchFinanceDetails]);

  const handleNext = async (values: ValidSPIVFormValues, bag: SPIVFormBag) => {
    try {
      showLoading();
      const requestData = getNextRequestData(values);
      await put(api(Consts.Api.SpivFinanceDetails.replace(':id', `${id}`)), requestData);
      alertService.clear(defaultAlertId);
      onNext(values, bag);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to update finance details', response: error.resposne},
      });
    } finally {
      hideLoading();
    }
  };

  const onDealClaimIntervalChanged = (claimInterval: string | null) => {
    if (claimInterval !== Consts.DealClaimIntervalEnum.DaysAfterExpiry) {
      setFieldValue('claimDaysAfterExpiry', '');
    }
  };

  const handleClaimIntervalChange = (
    _: React.SyntheticEvent,
    option: {label: string; value: string} | null
  ) => {
    const claimInterval = option?.value ?? '';
    setFieldValue('claimInterval', claimInterval);
    onDealClaimIntervalChanged(claimInterval);
  };

  const handleReturnsChange = (_: React.SyntheticEvent, checked: boolean) => {
    setFieldValue('excludeReturns', checked);
  };

  return (
    <>
      <SPIVDetailsHeader />
      <FormStep step={step} title={title}>
        <ActiveClaimWarning
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
          entityActionType={EntityActionType.Deal}
        />
        <FieldGrid item xs={12}>
          <Stack spacing={5}>
            <Stack spacing={1} direction="column">
              <FieldLabel fullWidth htmlFor="claimInterval">
                When is this SPIV going to be claimed?
              </FieldLabel>
              <CustomAutoComplete
                value={Consts.SPIVClaimInterval.find((ci) => ci.value === claimInterval) ?? null}
                options={Consts.SPIVClaimInterval}
                onChange={handleClaimIntervalChange}
                id="claimInterval"
                name="claimInterval"
                disabled={disabledFields.claimInterval}
                errorText={claimIntervalErrorText}
              />
              {claimInterval === Consts.DealClaimIntervalEnum.DaysAfterExpiry ? (
                <Stack spacing={1} direction="column">
                  <FieldLabel
                    htmlFor="claimDaysAfterExpiry"
                    fullWidth
                    sx={{marginTop: '1.25rem !important'}}
                  >
                    Number of days past expiry date
                  </FieldLabel>
                  <InputField
                    id="claimDaysAfterExpiry"
                    name="claimDaysAfterExpiry"
                    disabled={disabledFields.claimDaysAfterExpiry}
                    value={claimDaysAfterExpiry ?? ''}
                  />
                </Stack>
              ) : null}
              <FormControlLabel
                control={<Checkbox checked={excludeReturns} />}
                onChange={handleReturnsChange}
                label="Exclude returns when claiming (*Note including returns will reduce margin)"
                disabled={disabledFields.excludeReturns}
                sx={{
                  '& > span:first-of-type': {
                    paddingLeft: 0,
                  },
                }}
              />
            </Stack>
            <Stack spacing={1} direction="column">
              <FieldLabel htmlFor="supplierApprovalNumber" fullWidth>
                Supplier approval number <OptionalIndicator />
              </FieldLabel>
              <InputField
                id="supplierApprovalNumber"
                name="supplierApprovalNumber"
                fullWidth
                value={supplierApprovalNumber ?? ''}
              />
            </Stack>
            <Stack spacing={1} direction="column">
              <FieldLabel htmlFor="comment" fullWidth>
                Comments <OptionalIndicator />
              </FieldLabel>
              <InputField id="comment" name="comment" fullWidth multiline minRows={4} maxRows={4} />
            </Stack>
          </Stack>
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={onBack}
        handleNext={handleNext}
        step={step}
        totalStep={totalStep}
      />
    </>
  );
};

export default StepFinanceDetails;
