import React from 'react';
import {styled} from '@mui/material/styles';
import {getDisplayAmountValue} from '../../utils/AmountUtils';
import Consts from '../../app/Consts';

const PREFIX = 'SplitIconAmountComponent';

const classes = {
  root: `${PREFIX}-root`,
  contentFlex: `${PREFIX}-contentFlex`,
  amount: `${PREFIX}-amount`,
};

const Root = styled('div')(() => ({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    backgroundColor: theme.palette.gray.extraLight,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #0000001F',
    padding: '50px 44px',
    boxSizing: 'border-box',
    margin: '15px 0',
    minWidth: 'fit-content',
  },
  [`& .${classes.contentFlex}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: '10px',
  },
  [`& .${classes.amount}`]: {
    fontSize: '40px',
  },
}));

const SplitIconAmountComponent = ({icon, label, amount, taxAmount = 0, gstType, ...rest}) => {
  const postfix =
    gstType === Consts.GstTypeEnum.Free
      ? ' GST free'
      : gstType === Consts.GstTypeEnum.Exclusive
      ? ' ex.'
      : gstType === Consts.GstTypeEnum.Inclusive
      ? ' inc.'
      : '';
  return (
    <Root className={classes.root} {...rest}>
      <div>{icon}</div>
      <div className={classes.contentFlex}>
        <span>{label}</span>
        <span className={classes.amount}>{getDisplayAmountValue(amount, '$', postfix)}</span>
        {gstType === Consts.GstTypeEnum.Inclusive && (
          <span className={classes.accrualTaxAmount}>
            {getDisplayAmountValue(taxAmount, '$')} GST
          </span>
        )}
      </div>
    </Root>
  );
};

export {SplitIconAmountComponent};
