import React from 'react';
import Consts from '../../app/Consts';
import {ITSupportLink} from '../Link';

const SupportMessage = () => {
  return (
    <div>
      <span>To gain access, contact {Consts.ItSupport} at </span>
      <ITSupportLink />.
    </div>
  );
};
export default SupportMessage;
