import React, {FC, useState, useContext} from 'react';
import Stack from '@mui/material/Stack';
import {useFormikContext} from 'formik';
import LoadingContext from '../../../app/LoadingContext';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId, AlertType} from '../../../app/AlertService';
import {ValidMixAndMatchFormValues} from '../../../types';
import {api, put} from '../../../utils/Request';
import {StyledMaterialSwitch} from '../../Form/Switch';
import {ErrorBox} from '../../Alert';
import CustomRadioGroup from '../../Form/RadioGroup';
import FieldGrid from '../../Form/FieldGrid';
import FieldLabel from '../../Form/FieldLabel';
import GroupConfirmModal from '../GroupConfirmModal';
import {mnmDisabled} from '../mixAndMatchUtils';

type Props = {
  groupId: number;
};

const ClaimGroupSwitch: FC<Props> = ({groupId}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [onConfirmClaimOnGroup, setOnConfirmClaimOnGroup] = useState(
    Consts.MixAndMatchGroupClaimedEnum.NoClaim
  );
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<ValidMixAndMatchFormValues>();
  const {
    values: {groups, claimingStatus, type},
    setFieldValue,
  } = bag;
  const disabledFields = mnmDisabled(bag.values).fields;
  const group = groups?.find((g) => g.id === groupId);
  // single groups default to SKU and should not be allowed to set to noclaim
  const showSwitch = !!groups && groups.length > 1;
  const claimOnGroup = group?.claimOnGroup ?? Consts.MixAndMatchGroupClaimedEnum.NoClaim;
  const checked = Boolean(claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.NoClaim);

  const updateGroup = (claimOnGroup: string) => {
    const clearClaimAmounts = claimOnGroup !== Consts.MixAndMatchGroupClaimedEnum.SKU;
    const nextGroups = groups?.map((g) => {
      if (g.id === groupId) {
        if (clearClaimAmounts) {
          return {
            ...g,
            claimOnGroup,
            products: {
              ...g.products,
              data: g.products?.data?.map((p) => ({
                ...p,
                claimAmount: null,
                claimAmountType: null,
              })),
            },
          };
        } else {
          return {...g, claimOnGroup};
        }
      }
      return g;
    });
    setFieldValue('groups', nextGroups);
  };

  const handleUpdateClaimOnGroup = async (nextClaimOnGroup: string) => {
    try {
      showLoading();
      updateGroup(nextClaimOnGroup);
      await put(api(Consts.Api.MixAndMatchClaimOnGroup.replace(':id', `${groupId}`)), {
        claimOnGroup: nextClaimOnGroup,
      });
    } catch (error: any) {
      updateGroup(claimOnGroup);
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked && Boolean(group?.products?.data?.length)) {
      setOnConfirmClaimOnGroup(Consts.MixAndMatchGroupClaimedEnum.NoClaim);
      setConfirmModalOpen(true);
    } else {
      const nextClaimOnGroup = event.target.checked
        ? Consts.MixAndMatchGroupClaimedEnum.SKU
        : Consts.MixAndMatchGroupClaimedEnum.NoClaim;
      handleUpdateClaimOnGroup(nextClaimOnGroup);
    }
  };

  const handleClaimModeChange = (mode: string) => {
    if (mode === Consts.MixAndMatchGroupClaimedEnum.ClaimVendor) {
      handleUpdateClaimOnGroup(mode);
    } else if (mode === Consts.MixAndMatchGroupClaimedEnum.SKU) {
      if (
        claimingStatus !== Consts.AgreementStatusEnum.Draft &&
        group?.claimOnGroup === Consts.MixAndMatchGroupClaimedEnum.ClaimVendor &&
        type !== 'Unknown'
      ) {
        setOnConfirmClaimOnGroup(Consts.MixAndMatchGroupClaimedEnum.SKU);
        setConfirmModalOpen(true);
      } else {
        handleUpdateClaimOnGroup(mode);
      }
    }
  };

  const handleModalConfirm = () => {
    setConfirmModalOpen(false);
    handleUpdateClaimOnGroup(onConfirmClaimOnGroup);
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        {showSwitch ? (
          <Stack direction="column">
            <FieldLabel>Claim against this product / group of products?</FieldLabel>
            <StyledMaterialSwitch
              checked={checked}
              color="primary"
              onChange={handleSwitchChange}
              disabled={disabledFields.groups.claimOnGroup}
            />
          </Stack>
        ) : null}
        {checked ? (
          <FieldGrid item xs={12}>
            <FieldLabel>Add Claim Amount/s by Claim Vendor or SKU?</FieldLabel>
            <CustomRadioGroup
              name="claimOnGroup"
              options={[
                {label: 'SKU', value: Consts.MixAndMatchGroupClaimedEnum.SKU},
                {label: 'Claim Vendor', value: Consts.MixAndMatchGroupClaimedEnum.ClaimVendor},
              ]}
              value={claimOnGroup}
              onChanged={(mode) => {
                handleClaimModeChange(mode as string);
              }}
              disabled={disabledFields.groups.claimOnGroup}
            />
          </FieldGrid>
        ) : null}
        {claimOnGroup === Consts.MixAndMatchGroupClaimedEnum.ClaimVendor ? (
          <FieldGrid container>
            <FieldGrid item xs={6} sx={{marginBottom: '0rem', paddingLeft: '0.25rem'}} />
            <FieldGrid item xs={6} sx={{marginBottom: '0rem', paddingLeft: '0.25rem'}}>
              <ErrorBox
                type={AlertType.Info}
                sx={{
                  border: 'solid 1px #005EAB',
                  padding: '0.25rem 1rem',
                }}
              >
                <div>Claim amounts per vendor can be added on the following screen</div>
              </ErrorBox>
            </FieldGrid>
          </FieldGrid>
        ) : null}
      </Stack>
      <GroupConfirmModal
        onOk={handleModalConfirm}
        onCancel={() => setConfirmModalOpen(false)}
        open={confirmModalOpen}
        okText={
          onConfirmClaimOnGroup === Consts.MixAndMatchGroupClaimedEnum.SKU
            ? 'Confirm and Delete'
            : 'Continue'
        }
        title="Are you sure?"
        body={
          onConfirmClaimOnGroup === Consts.MixAndMatchGroupClaimedEnum.SKU
            ? 'This action will permanently clear any Finance details including claim amounts entered on the following page. Would you like to continue?'
            : 'This action will permanently clear any claim amounts that you have entered into this product group. Would you like to continue?'
        }
      />
    </>
  );
};

export default ClaimGroupSwitch;
