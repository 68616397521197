import * as Yup from 'yup';
import Consts from '../../../app/Consts';
import {isBundleType} from '../mixAndMatchUtils';

export const stepMixAndMatchValuesValidationSchema = Yup.object({
  type: Yup.string().required(Consts.ValidationMessage.Required).nullable(),
  groups: Yup.array().required('At least one group must be added to this mix and match deal'),
  amount: Yup.number()
    .min(0.01, Consts.ValidationMessage.PositiveValue)
    .typeError(Consts.ValidationMessage.NumberOnly)
    .when('type', {
      is: isBundleType,
      then: Yup.number().required(Consts.ValidationMessage.Required).nullable(),
    })
    .nullable(),
  amountType: Yup.string()
    .when('type', {
      is: isBundleType,
      then: Yup.string().required(Consts.ValidationMessage.Required).nullable(),
    })
    .nullable(),
});
