import React, {useEffect, useState, useContext, useCallback, useMemo} from 'react';
import {styled} from '@mui/material/styles';
import {useParams, useNavigate} from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import isValidDate from 'date-fns/isValid';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {alertService, AlertType, defaultAlertId} from '../../../app/AlertService';
import LoadingContext from '../../../app/LoadingContext';
import {useAppSelector} from '../../../app/store';
import {selectLoggedInStaffCode} from '../../../app/selectors';
import {
  ClaimListResponse,
  Config,
  DealAgreementSummaryResponse,
  EntityActionType,
} from '../../../types';
import {api, get, post, del} from '../../../utils/Request';
import {getFormattedDateTime} from '../../../utils/DateUtils';
import {getDealType} from '../../../utils/TypesStatusUtils';
import {useFeatureFlag} from '../../../utils/featureFlags';
import {
  CreateDealIcon,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  MoneyGraphIcon,
  ReverseIcon,
} from '../../../components/Icons';
import SummaryDetailsComponent from '../../../components/SummaryDetailsComponent';
import {TableTabPanel} from '../../../components/TableTabPanel';
import LocationSelector from '../../../components/Form/Agolia/LocationSelector';
import {SplitIconAmountComponent} from '../../../components/SplitIconAmountComponent';
import {TabsComponent} from '../../../components/TabsComponent';
import {HeadingComponent} from '../../../components/HeadingComponent';
import {
  DeleteAgreementOrRebateConfirmModal,
  DeleteAgreementOrRebateWithActiveClaimConfirmModal,
  DeleteAgreementorRebateWithClaimForbiddenModal,
} from '../../../components/Modal';
import EditAgreementOrRebateConfirmModal from '../../../components/Modal/EditAgreementOrRebateConfirmModal';
import {ErrorBox} from '../../../components/Alert';
import DealInformationTabContent from './DealInformationTabContent';
import DealAttachmentsTabContent from './DealAttachmentsTabContent';
import DealClaimsTabContent from './DealClaimsTabContent';
import DealLinkedDealsTabContent from './DealLinkedDealsTabContent';

const PREFIX = 'DealSummary';

const classes = {
  root: `${PREFIX}-root`,
  pageFlexColumn: `${PREFIX}-pageFlexColumn`,
  contentWrapper: `${PREFIX}-contentWrapper`,
  fixWidthContainerSm: `${PREFIX}-fixWidthContainerSm`,
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.pageFlexColumn}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
  },

  [`& .${classes.contentWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 1.625rem 0.5rem',
    boxShadow: 'none',
    border: 'none',
    boxSizing: 'border-box',
  },

  [`& .${classes.fixWidthContainerSm}`]: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
  },

  [`& .${classes.tabPanelRoot}`]: {
    position: 'relative',
    backgroundColor: theme.palette.gray.extraLight,
    padding: '5.25rem 3rem 6.25rem',
  },
}));

const TabsIndex = {
  DealInformation: 'DealInformation',
  Attachments: 'Attachments',
  Claims: 'Claims',
  LinkedDeals: 'LinkedDeals',
};

type HeadingButton = {
  label: string;
  icon: React.ReactNode;
  style: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  role: string;
  disabled?: boolean;
  disabledText?: string;
  isLocked?: boolean;
};

type DealDetails = {
  title: string;
  value: string;
};

export const isDealLocked = (configs: Config | undefined, endAt: string): boolean => {
  if (!configs) {
    return false;
  }
  const lockDate = new Date(configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs]);
  const cutoffDate = new Date(
    configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs]
  );
  if (isValidDate(lockDate) && isValidDate(cutoffDate)) {
    return new Date() >= lockDate && new Date(endAt) < cutoffDate;
  }
  return false;
};

const DealSummary = () => {
  const {id = ''} = useParams<{id: string}>();

  const navigate = useNavigate();
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const configs = useAppSelector((state) => state.configs.data);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const dealReversalFeatureEnabled = useFeatureFlag(
    Consts.FeatureFlags.EnableDealReversal
  )?.enabled;

  const [selectedTab, setSelectedTab] = useState(TabsIndex.DealInformation);
  const [dealData, setDealData] = useState<DealAgreementSummaryResponse | undefined>();
  const [dealDetails, setDealDetails] = useState<DealDetails[]>([]);
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);
  const [openDeleteWithClaimConfirmModal, setOpenDeleteWithClaimConfirmModal] = useState(false);
  const [openDeleteWithClaimForbiddenModal, setOpenDeleteWithClaimForbiddenModal] = useState(false);
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [raisedClaimsResponse, setRaisedClaimsResponse] = useState<ClaimListResponse | null>(null);
  const [ctrlKey, setCtrlKey] = useState(false);

  const claimPagination = useMemo(
    () => ({
      ...Consts.DefaultPagination,
      pageSize: Consts.PageSize[0],
    }),
    []
  );
  const isPromo = dealData?.dealValues?.data?.some((d) => (d.promoPrice ?? 0) > 0);

  const Tabs = useMemo(
    () => [
      {
        value: TabsIndex.DealInformation,
        label: 'Deal Information',
      },
      {
        value: TabsIndex.Attachments,
        label: 'Attachments',
      },
      ...(dealData?.hasClaims && dealReversalFeatureEnabled
        ? [
            {
              value: TabsIndex.Claims,
              label: 'Claims',
            },
          ]
        : []),
      ...(dealData?.hasLinkedDeals && dealReversalFeatureEnabled
        ? [
            {
              value: TabsIndex.LinkedDeals,
              label: 'Linked Deals',
            },
          ]
        : []),
    ],
    [dealData?.hasClaims, dealData?.hasLinkedDeals, dealReversalFeatureEnabled]
  );

  const deleteDeal = () => {
    showLoading();
    del(api(Consts.Api.DealAgreement.replace(':id', id)))
      .then(() => {
        navigate(Consts.RouterPath.MyDeals);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  const editDeal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const routerPath = Consts.RouterPath.EditDeal.replace(':id', id);
      if (event.ctrlKey) {
        window.open(routerPath, '_blank');
      } else {
        navigate(routerPath);
      }
    },
    [navigate, id]
  );

  const handleNavAction = useCallback(
    (type: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.persist();
      setCtrlKey(event.ctrlKey);
      if (type === Consts.SummaryPageActionTypes.DUPLICATE) {
        showLoading();
        const requestData = {
          agreementId: id,
          createdByStaffCode: loggedInStaffCode,
        };
        post(api(Consts.Api.DealDuplicate), requestData)
          .then((response) => {
            navigate(Consts.RouterPath.EditDeal.replace(':id', response.data.id));
          })
          .catch((error) => {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          })
          .finally(() => {
            hideLoading();
          });
      } else if (type === Consts.SummaryPageActionTypes.EDIT) {
        showLoading();
        get(api(Consts.Api.DealAgreementClaims.replace(':id', id)), {
          params: {
            status: Consts.ClaimStatusEnum.Raised,
          },
        })
          .then((response: AxiosResponse<ClaimListResponse>) => {
            const data = response.data;
            if (data.totalCount === 0) {
              editDeal(event);
            } else {
              setRaisedClaimsResponse(data);
              setOpenEditModal(true);
            }
          })
          .catch((error) => {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          })
          .finally(() => {
            hideLoading();
          });
      } else if (type === Consts.SummaryPageActionTypes.DELETE) {
        showLoading();
        get(api(Consts.Api.DealAgreementClaims.replace(':id', id)), {
          param: {
            ...claimPagination,
          },
        })
          .then((response: AxiosResponse<ClaimListResponse>) => {
            if (response.data.totalCount === 0) {
              setOpenDeleteConfirmModal(true);
            } else {
              if (response.data.totalRaisedCount > 0) {
                setOpenDeleteWithClaimForbiddenModal(true);
              } else {
                setOpenDeleteWithClaimConfirmModal(true);
              }
            }
          })
          .catch((error) => {
            alertService.alert({
              ...{message: error.message, response: error.response},
              id: defaultAlertId,
            });
          })
          .finally(() => {
            hideLoading();
          });
      } else if (type === Consts.SummaryPageActionTypes.REVERSE) {
        navigate(Consts.RouterPath.DealReversal.replace(':id', id));
      }
    },
    [claimPagination, editDeal, hideLoading, navigate, id, loggedInStaffCode, showLoading]
  );

  const headingButtons: HeadingButton[] = useMemo(() => {
    if (!dealData) {
      return [];
    }
    const buttons: HeadingButton[] = [
      ...(dealData.isReversible && dealReversalFeatureEnabled
        ? [
            {
              label: 'Reverse Deal',
              icon: <ReverseIcon style={{width: '1.3125rem'}} />,
              style: {color: '#626262', fontWeight: 400},
              onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                handleNavAction(Consts.SummaryPageActionTypes.REVERSE, event),
              role: Consts.UserRoleEnum.AddOrUpdateDeals,
              isLocked: isDealLocked(configs, dealData.endAt),
            },
          ]
        : []),
      {
        label: 'Duplicate Deal',
        icon: <DuplicateIcon style={{width: '1.3125rem'}} />,
        style: {color: '#626262', fontWeight: 400},
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleNavAction(Consts.SummaryPageActionTypes.DUPLICATE, event),
        role: Consts.UserRoleEnum.AddOrUpdateDeals,
      },
    ];
    if (!dealData.isDeleted) {
      buttons.push(
        {
          label: 'Edit Deal',
          icon: <EditIcon style={{width: '1.3125rem'}} />,
          style: {color: '#626262', fontWeight: 400},
          onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleNavAction(Consts.SummaryPageActionTypes.EDIT, event),
          role: Consts.UserRoleEnum.AddOrUpdateDeals,
          isLocked: isDealLocked(configs, dealData?.endAt),
        },
        {
          label: 'Delete Deal',
          icon: <DeleteIcon style={{width: '1.3125rem'}} />,
          style: {color: '#d0021b', fontWeight: 400},
          onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleNavAction(Consts.SummaryPageActionTypes.DELETE, event),
          role: Consts.UserRoleEnum.DeleteDeals,
          isLocked: isDealLocked(configs, dealData.endAt),
          disabled: !dealData.isDeletable,
          disabledText: 'Cannot be deleted as there are related invoiced and/or raised claim(s).',
        }
      );
    }
    return buttons;
  }, [dealData, handleNavAction, configs, dealReversalFeatureEnabled]);

  const setData = useCallback(
    (responseData: DealAgreementSummaryResponse) => {
      setDealData(responseData);
      setDealDetails([
        {
          title: 'Claim Vendor',
          value: responseData.claimVendorName,
        },
        {
          title: 'Department',
          value: responseData.departmentDescription,
        },
        {
          title: 'Deal Type',
          value: getDealType(responseData.dealValues.dealType) ?? '',
        },
        {
          title: 'Start Date',
          value: getFormattedDateTime(responseData.startAt),
        },
        {
          title: 'End Date',
          value: getFormattedDateTime(responseData.endAt),
        },
        {
          title: 'Owner',
          value: `${responseData.ownedByStaffCode} - ${responseData.ownedByStaffName}`,
        },
        {
          title: 'Last Modified Date',
          value: getFormattedDateTime(responseData.lastModifiedAt),
        },
        {
          title: 'Last Modified By',
          value: `${responseData.lastModifiedByStaffCode} - ${responseData.lastModifiedByStaffName}`,
        },
      ]);
      hideLoading();
    },
    [hideLoading]
  );

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.DealAgreementSummary.replace(':id', id)))
      .then(({data}: AxiosResponse<DealAgreementSummaryResponse>) => {
        setData(data);
      })
      .catch((error) => {
        hideLoading();
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [hideLoading, id, setData, showLoading]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleUpdateDealData = (dataUpdate: DealAgreementSummaryResponse) => {
    setDealData((prevData) => ({...prevData, ...dataUpdate}));
  };

  return (
    <Root className={classes.root}>
      {dealData ? (
        <div className={classes.pageFlexColumn}>
          <Container maxWidth={false}>
            {dealData.isDeleted ? (
              <ErrorBox type={AlertType.Info}>
                This deal is deleted - it will be removed overnight
              </ErrorBox>
            ) : null}
            <HeadingComponent
              headingIcon={<CreateDealIcon />}
              headingLabel="Deal"
              buttons={headingButtons}
              style={dealData.isDeleted ? {paddingTop: '0px'} : {}}
            />
            <Paper className={classes.contentWrapper}>
              <SummaryDetailsComponent
                id={dealData.id}
                details={dealDetails}
                status={dealData.status}
                desc={dealData.description}
                staffCode={dealData.ownedByStaffCode}
                staffName={dealData.ownedByStaffName}
                generatePricebookUpdate={dealData?.generatePricebookUpdate}
                isPromo={isPromo}
                isReversed={dealData.isReversed}
                type="Deal"
                hideOwner
              />

              <div className={classes.fixWidthContainerSm}>
                <SplitIconAmountComponent
                  icon={<MoneyGraphIcon />}
                  label="Total Amount Accrued"
                  amount={dealData.amount}
                  taxAmount={dealData.accrualTaxAmount ?? 0}
                  gstType={dealData.gstType}
                />
              </div>
            </Paper>
          </Container>

          <div>
            <TabsComponent
              tabs={Tabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
            />

            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.DealInformation}
              className={classes.tabPanelRoot}
            >
              {selectedTab === TabsIndex.DealInformation && dealData ? (
                <DealInformationTabContent
                  dealData={dealData}
                  onUpdate={handleUpdateDealData}
                  onLocationClick={() => setLocationSelectorOpen(true)}
                />
              ) : null}
            </TableTabPanel>
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.Attachments}
              className={classes.tabPanelRoot}
            >
              {selectedTab === TabsIndex.Attachments ? <DealAttachmentsTabContent /> : null}
            </TableTabPanel>
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.Claims}
              className={classes.tabPanelRoot}
            >
              {selectedTab === TabsIndex.Claims ? <DealClaimsTabContent /> : null}
            </TableTabPanel>
            <TableTabPanel
              value={selectedTab}
              index={TabsIndex.LinkedDeals}
              className={classes.tabPanelRoot}
            >
              {selectedTab === TabsIndex.LinkedDeals ? <DealLinkedDealsTabContent /> : null}
            </TableTabPanel>
          </div>
          {locationSelectorOpen ? (
            <LocationSelector
              fullScreen
              locationCriteria={dealData.locationCriteria}
              open={locationSelectorOpen}
              handleClose={() => setLocationSelectorOpen(false)}
              readOnly
            />
          ) : null}
          <DeleteAgreementOrRebateConfirmModal
            entityActionType={EntityActionType.Deal}
            open={openDeleteConfirmModal}
            onCancel={() => setOpenDeleteConfirmModal(false)}
            onOk={() => {
              setOpenDeleteConfirmModal(false);
              deleteDeal();
            }}
          />
          <DeleteAgreementOrRebateWithActiveClaimConfirmModal
            entityActionType={EntityActionType.Deal}
            open={openDeleteWithClaimConfirmModal}
            onCancel={() => setOpenDeleteWithClaimConfirmModal(false)}
            onOk={() => {
              setOpenDeleteWithClaimConfirmModal(false);
              setOpenDeleteConfirmModal(true);
            }}
          />
          <DeleteAgreementorRebateWithClaimForbiddenModal
            entityActionType={EntityActionType.Deal}
            open={openDeleteWithClaimForbiddenModal}
            onOk={() => setOpenDeleteWithClaimForbiddenModal(false)}
          />
          <EditAgreementOrRebateConfirmModal
            entityActionType={EntityActionType.Deal}
            referenceId={id}
            ctrlKey={ctrlKey}
            open={openEditModal}
            claimsResponse={raisedClaimsResponse}
            onOk={() => {
              setOpenEditModal(false);
            }}
            onCancel={() => setOpenEditModal(false)}
          />
        </div>
      ) : null}
    </Root>
  );
};

export default DealSummary;
