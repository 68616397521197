import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface TableColumn {
  id: string;
  label: string;
  sortable?: boolean;
  render?: (rowData: any, rowIndex: number) => void;
  minWidth?: number;
}

export const advanceModalStyles = {
  okButton: {
    marginLeft: '20px',
    width: '200px',
  },
  cancelButton: {
    width: '200px',
  },
  root: {
    width: 'auto',
    overflow: 'auto',
  },
  actionSection: {
    justifyContent: 'center',
  },
};

export const ActionBarContent = styled(Paper)`
  margin: 40px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
  border: none;
  height: 80px;
  background-color: transparent;
`;

export const selectedRebateData = createSelector(
  (state: RootState) => state.unreleasedRebates,
  (unreleasedRebates) => unreleasedRebates.data.filter((rebate) => rebate.selected)
);

export const selectedRebateDataCount = createSelector(
  (state: RootState) => state.unreleasedRebates,
  (unreleasedRebates) => unreleasedRebates.data.filter((rebate) => rebate.selected).length
);

export const selectedProductData = createSelector(
  (state: RootState) => state.unreleasedProducts,
  (unreleasedProducts) => unreleasedProducts.data.filter((product) => product.selected)
);

export interface PageChange {
  currentPage: number;
}

export interface PageSizeChange {
  pageSize: number;
  currentPage: number;
}
