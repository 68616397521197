import React from 'react';
import {styled} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const StyledTab = styled(Tab)(({theme}) => ({
  '&.MuiTab-root': {
    width: '100%',
    maxWidth: '400px',
    fontSize: '20px',
    color: theme.palette.gray.dark,
    padding: '30px',
    backgroundColor: theme.palette.gray.extraLight,
    borderRight: '1px solid #CBD2D8',
    opacity: 0.7,
  },
  '&.Mui-selected': {
    color: theme.palette.orange.main,
    opacity: 1,
  },
}));

const StyledTabs = styled(Tabs)(({theme}) => ({
  '& .MuiTabs-indicator': {
    top: '0',
    height: '6px',
    backgroundColor: theme.palette.orange.main,
  },
}));

const TabsComponent = ({tabs, selectedTab, handleTabChange, ...props}) => (
  <StyledTabs value={selectedTab} onChange={handleTabChange} aria-label="tabs options" {...props}>
    {tabs.length &&
      tabs.map((tab, index) => <StyledTab key={index} value={tab.value} label={tab.label} />)}
  </StyledTabs>
);

export {TabsComponent};
