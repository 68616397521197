import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import Consts from '../../app/Consts';

export const StyledButton = styled(Button)(() => ({
  '&.MuiButton-root': {
    backgroundColor: '#000',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#626262',
    },
  },
}));

type Props = {
  id: string;
};

const AdjustClaimButton: FC<Props> = ({id}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(Consts.RouterPath.ClaimAdjustment.replace(':id', id));
  };
  return (
    <StyledButton variant="contained" onClick={handleClick}>
      Adjust Claim
    </StyledButton>
  );
};

export default AdjustClaimButton;
