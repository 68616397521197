import React, {FC, useState} from 'react';
import {styled} from '@mui/material/styles';
import {AxiosResponse} from 'axios';
import Consts from '../../../app/Consts';
import {api} from '../../../utils/Request';
import {ClaimBulkUploadResponse} from '../../../types';
import {OutlinedButton} from '../../Button';
import RequireAuth from '../../Auth/RequireAuth';
import {BulkUploadModal, DownloadTemplatesModal} from '../../Modal';

export const BulkUploadButton = styled(OutlinedButton)(() => ({
  [`&.MuiButton-root`]: {
    width: '250px !important',
    backgroundColor: '#000 !important',
    color: '#FFF !important',
    '&:hover': {
      backgroundColor: '#626262 !important',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.24) !important',
    },
  },
}));

type Props = {
  onUploadComplete: (response?: AxiosResponse<ClaimBulkUploadResponse>) => void;
};

const BulkUploadRebateClaims: FC<Props> = ({onUploadComplete}) => {
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);

  const handleUploadComplete = (
    uploadResponse?: AxiosResponse<ClaimBulkUploadResponse>,
    keepModalOpen?: boolean
  ) => {
    onUploadComplete(uploadResponse);
    setBulkUploadModalOpen(Boolean(keepModalOpen));
  };

  return (
    <RequireAuth
      requiredRoles={{
        all: [Consts.UserRoleEnum.BulkUploadEditRebateClaims],
      }}
      alt={null}
    >
      <>
        <BulkUploadButton
          onClick={() => {
            setBulkUploadModalOpen(true);
          }}
        >
          Bulk Upload Claims
        </BulkUploadButton>

        <DownloadTemplatesModal
          title={'Download Excel Templates'}
          fileNames={[
            'Bulk Upload Rebate Claims Template.xlsx',
            'Bulk Upload Deal Claims Template.xlsx',
          ]}
          open={templatesModalOpen}
          onClose={() => {
            setTemplatesModalOpen(false);
          }}
        />

        <BulkUploadModal
          title="Bulk Update Claims"
          uploadUrl={api(Consts.Api.ClaimsBulkUpload)}
          open={bulkUploadModalOpen}
          onClose={() => {
            setBulkUploadModalOpen(false);
          }}
          onOpenTemplate={() => {
            setBulkUploadModalOpen(false);
            setTemplatesModalOpen(true);
          }}
          onComplete={handleUploadComplete}
        />
      </>
    </RequireAuth>
  );
};

export default BulkUploadRebateClaims;
