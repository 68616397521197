import React, {useState, useEffect, useCallback, useRef} from 'react';
import {AxiosResponse} from 'axios';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {
  AdjustmentListItem,
  Order,
  PaginatedDataResponse,
  AdjustmentRequestParams,
  TableColumn,
} from '../../types';
import {api, del, get} from '../../utils/Request';
import {isNegative} from '../../utils/AmountUtils';
import {formatDate} from '../../utils/DateUtils';
import {createOrderFromParams} from '../../utils/common';
import RequireAuth from '../Auth/RequireAuth';
import {SearchInputField} from '../SearchInputField';
import ButtonLink from '../Button/ButtonLink';
import Spinner from '../Spinner/Spinner';
import {PageHeading} from '../PageHeading';
import {RedSpan} from '../RedSpan';
import {DeleteActionButton} from '../Table';
import {Amount} from '../Amount';
import {SimpleDataTable} from '../SimpleDataTable';
import FiltersIndicator from '../FiltersIndicator';
import ListFilterContainer from '../ListFilterContainer';
import AdjustmentSideFilter from './AdjustmentSideFilter';
import DownloadAdjustmentCsvButton from './DownloadAdjustmentCsvButton';

const PREFIX = 'AdjustmentsListing';

const classes = {
  root: `${PREFIX}-root`,
  containerRoot: `${PREFIX}-containerRoot`,
  containerWidthLg: `${PREFIX}-containerWidthLg`,
};

const Root = styled('div')({
  width: '100%',
  [`& .${classes.containerRoot}`]: {
    paddingLeft: '3rem',
    paddingRight: '2.5rem',
  },
  [`& .${classes.containerWidthLg}`]: {
    maxWidth: '125rem',
  },
});

const StyledTableContainer = styled('div')`
  margin: 3rem 0;
`;

type AdjustmentsResponse = PaginatedDataResponse<AdjustmentListItem[]>;
type FilterSelection = {
  adjustmentType: string | null;
  startAt: string | null;
  endAt: string | null;
};

const AdjustmentsListing = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<AdjustmentListItem[]>([]);
  const [pagination, setPagination] = useState(Consts.DefaultPagination);
  const [requestData, setRequestData] = useState<AdjustmentRequestParams>({
    ...Consts.DefaultPagination,
    orderBy: 'createdAt:desc',
  });
  const [order, setOrder] = useState<Order>({
    orderBy: 'createdAt',
    orderByDirection: 'desc',
  });
  const [openSideFilters, setOpenSideFilters] = useState(false);

  const [filterSelection, setFilterSelection] = useState<FilterSelection>({
    adjustmentType: null,
    startAt: null,
    endAt: null,
  });
  const filterRef = useRef<HTMLDivElement>(null);

  const getFilters = (selection: FilterSelection): Partial<FilterSelection> => {
    const filters = Object.entries(selection).reduce(
      (acc: {[x: string]: string}, [key, value]: [string, string | null]) => {
        if (value !== null) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    return filters;
  };

  const updateFilters = (selection: FilterSelection) => {
    setFilterSelection(selection);
    const filters = getFilters(selection);
    setRequestData(({adjustmentType, startAt, endAt, ...pagination}) => {
      return {
        ...pagination,
        currentPage: 1,
        ...filters,
      };
    });
  };

  const columns: TableColumn<AdjustmentListItem>[] = [
    {
      id: 'startAt',
      label: 'Start Date',
      minWidth: 88,
      render: (rowData: AdjustmentListItem) => formatDate(rowData.startAt),
      sortable: true,
    },
    {
      id: 'endAt',
      label: 'End Date',
      minWidth: 88,
      render: (rowData: AdjustmentListItem) => formatDate(rowData.endAt),
      sortable: true,
    },
    {
      id: 'adjustmentType',
      label: 'Adjustment Type',
      render: (rowData: AdjustmentListItem) => {
        const adjustmentType = rowData.agreementId
          ? Consts.AdjustmentTypeEnum.Agreement
          : Consts.AdjustmentTypeEnum.Sku;

        const foundItem = Consts.AdjustmentType.find((item) => item.value === adjustmentType);
        return foundItem ? foundItem.label : null;
      },
    },
    {
      id: 'sku/agreement',
      label: 'SKU/Agreement',
      render: (rowData: AdjustmentListItem) => {
        if (rowData.agreementId) {
          return `${rowData.agreementId} - ${rowData.agreementVendorName} ${rowData.agreementDescription}`;
        }
        return `${rowData.productCode} ${rowData.productDescription}`;
      },
    },
    {
      id: 'reason',
      label: 'Adjustment Reason',
      render: (rowData: AdjustmentListItem) => {
        const foundItem = Consts.AdjustmentReason.find((item) => item.value === rowData.reason);
        return foundItem ? foundItem.label : null;
      },
    },
    {
      id: 'amount',
      label: 'Amount',
      render: (rowData: AdjustmentListItem) => <Amount value={rowData.amount} />,
      sortable: true,
    },
    {
      id: 'effectOnNetPurchases',
      label: 'Effect on Net Purchases',
      render: (rowData: AdjustmentListItem) => {
        const floatValue = rowData.amount;
        if (floatValue === null || isNaN(floatValue) || floatValue === 0) {
          return <span>{Consts.EffectOnNetPurchases.None}</span>;
        }
        const negative = isNegative(floatValue);
        const effectText = negative
          ? Consts.EffectOnNetPurchases.Decrease
          : Consts.EffectOnNetPurchases.Increase;
        return negative ? <RedSpan>{effectText}</RedSpan> : <span>{effectText}</span>;
      },
    },
    {
      id: 'gstType',
      label: 'GST Type',
      minWidth: 120,
      render: (rowData: AdjustmentListItem) => {
        const foundItem = Consts.GstType.find((item) => item.value === rowData.gstType);
        return foundItem ? foundItem.label : null;
      },
    },
    {
      id: 'createdAt',
      label: 'Created',
      render: (rowData: AdjustmentListItem) => {
        return (
          <>
            <div>{formatDate(rowData.createdAt)}</div>
            <div>{`${rowData.createdByStaffCode} - ${rowData.createdByStaffName}`}</div>
          </>
        );
      },
      sortable: true,
    },
    {
      id: 'action',
      label: '',
      render: (rowData: AdjustmentListItem) => {
        return rowData.canDelete ? <DeleteActionButton onClick={() => onDelete(rowData)} /> : null;
      },
    },
    {
      additionalLine: 1,
      id: 'note',
      isShown: (rowData: AdjustmentListItem) => !!rowData.note,
      colspan: 7,
      colspanLeft: 2,
      colspanRight: 1,
      style: {
        fontStyle: 'italic',
        opacity: 0.7,
        paddingTop: 0,
      },
    },
  ];
  const onDelete = (rowData: AdjustmentListItem) => {
    setLoading(true);
    if (!rowData.id) {
      return;
    }
    del(api(Consts.Api.Adjustment.replace(':id', `${rowData.id}`)))
      .then(() => {
        setLoading(false);
        alertService.clear(defaultAlertId);
        setRequestData((prevRequestData) => {
          const totalPagesAfterDeletion =
            Math.ceil((pagination.totalCount - 1) / pagination.pageSize) || 1;
          let currentPage = pagination.currentPage;
          if (pagination.totalPages > totalPagesAfterDeletion) {
            currentPage = currentPage - 1 || 1;
          }
          return {
            ...prevRequestData,
            currentPage: currentPage,
          };
        });
      })
      .catch((error) => {
        setLoading(false);
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  };

  const setData = useCallback((responseData: AdjustmentsResponse) => {
    const data = responseData.data || [];
    setRows(data);
    setLoading(false);
    setPagination({
      totalPages: responseData.totalPages,
      currentPage: responseData.currentPage,
      pageSize: responseData.pageSize,
      totalCount: responseData.totalCount,
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    get(api(Consts.Api.Adjustments), {
      params: {
        entityCode: config.entityCode,
        ...requestData,
      },
    })
      .then((response: AxiosResponse<AdjustmentsResponse>) => {
        setData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      });
  }, [requestData, setData]);

  const searchByText = (value: string) => {
    setRequestData((prevRequestData) => ({
      ...prevRequestData,
      currentPage: 1,
      searchText: value,
    }));
  };

  const refreshData = (data: Partial<AdjustmentRequestParams>) => {
    setRequestData((prevRequestData) => ({
      ...prevRequestData,
      ...data,
    }));
  };

  const handleOrderBy = (order: Pick<Order, 'orderBy'>) => {
    const newOrderBy = order.orderBy;
    setRequestData((prevRequestData) => ({
      ...prevRequestData,
      orderBy: newOrderBy,
    }));
    createOrderFromParams(newOrderBy, setOrder);
  };

  const toggleSideFilters = () => {
    if (!openSideFilters) {
      filterRef?.current?.scrollIntoView?.({block: 'start', behavior: 'smooth'});
    }
    setOpenSideFilters((open) => !open);
  };

  return (
    <Root ref={filterRef}>
      <PageHeading title="All Adjustments" />
      <Grid container wrap="nowrap">
        {openSideFilters && (
          <Grid item>
            <AdjustmentSideFilter
              defaultSelection={filterSelection}
              onClose={() => setOpenSideFilters(false)}
              onChange={updateFilters}
            />
          </Grid>
        )}
        <Container
          maxWidth="lg"
          classes={{
            root: classes.containerRoot,
            maxWidthLg: classes.containerWidthLg,
          }}
        >
          <ListFilterContainer>
            <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
              <FiltersIndicator
                count={Object.values(filterSelection).filter(Boolean).length}
                onClick={toggleSideFilters}
              />
              <SearchInputField
                placeholder="Search adjustments"
                width="26rem"
                onSearch={searchByText}
              />
            </Stack>
            <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
              <DownloadAdjustmentCsvButton requestData={requestData} />
              <RequireAuth
                requiredRoles={{all: [Consts.UserRoleEnum.ApproveDealsAndContractAgreements]}}
                alt={null}
              >
                <ButtonLink url={Consts.RouterPath.CreateAdjustment}>Add New Adjustment</ButtonLink>
              </RequireAuth>
            </Stack>
          </ListFilterContainer>
          <StyledTableContainer>
            <Spinner loading={loading} />
            <SimpleDataTable
              columns={columns}
              rows={rows}
              pagination={pagination}
              onChangePage={refreshData}
              onChangePageSize={refreshData}
              onOrderBy={handleOrderBy}
              sortOrderBy={order.orderBy}
              sortOrder={order.orderByDirection}
            />
          </StyledTableContainer>
        </Container>
      </Grid>
    </Root>
  );
};

export default AdjustmentsListing;
