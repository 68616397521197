import React, {useEffect, useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {useAppDispatch, useAppSelector} from '../../app/store';
import {
  selectUnreleasedLoading,
  selectUnreleasedAmountsTabType,
  selectProductNumberOfFilters,
  selectRebateNumberOfFilters,
  selectProductDataCount,
  selectRebateDataCount,
} from '../../app/selectors';
import {UnreleasedAmountsTabType, updateType} from '../../app/unreleasedAmountsReducer';
import {
  searchUnreleasedRebates,
  SearchUnreleasedRebatesRequest,
  toggleShowRebateAdvanceModal,
  updateRebateSearchText,
} from '../../app/unreleasedRebatesReducer';
import {
  searchUnreleasedProducts,
  SearchUnreleasedProductsRequest,
  toggleShowProductAdvanceModal,
  updateProductSearchText,
} from '../../app/unreleasedProductsReducer';
import ListFilterContainer from '../ListFilterContainer';
import FiltersIndicator from '../FiltersIndicator';
import {AdvanceButton} from '../Button';
import Spinner from '../Spinner/Spinner';
import SideFilters from '../SideFilters/SideFilters';
import {SearchInputField} from '../SearchInputField';
import UnreleasedHeader from './UnreleasedHeader';
import UnreleasedProductTable from './Product/UnreleasedProductTable';
import UnreleasedAmountsRebateTable from './Rebate/UnreleasedRebateTable';
import AdvanceProductsModal from './Product/AdvanceProductsModal';
import AdvanceRebatesModal from './Rebate/AdvanceRebatesModal';
import ByProductFilters from './Product/ByProductFilters';
import ByRebateFilters from './Rebate/ByRebateFilters';

const PREFIX = 'UnreleasedListing';

const classes = {
  containerRoot: `${PREFIX}-containerRoot`,
  containerWidthLg: `${PREFIX}-containerWidthLg`,
};

const RootContent = styled('div')`
  width: 100%;
`;

const StyledRootContent = styled(RootContent)({
  [`& .${classes.containerRoot}`]: {
    paddingLeft: '3rem',
    paddingRight: '2.5rem',
  },

  [`& .${classes.containerWidthLg}`]: {
    maxWidth: '125rem',
  },
});

const UnreleasedListing = () => {
  const [openSideFilters, setOpenSideFilters] = useState(false);
  const dispatch = useAppDispatch();
  const tabType = useAppSelector(selectUnreleasedAmountsTabType);
  const isLoading = useAppSelector(selectUnreleasedLoading);
  const selectedProductFiltersCount = useAppSelector(selectProductNumberOfFilters);
  const selectedRebateFiltersCount = useAppSelector(selectRebateNumberOfFilters);
  const productCount = useAppSelector(selectProductDataCount);
  const rebateCount = useAppSelector(selectRebateDataCount);
  const filterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const rebatesRequest: SearchUnreleasedRebatesRequest = {
      searchText: '',
      pageSize: 10,
      currentPage: 1,
      agreementType: null,
      rebateType: null,
      startAt: null,
      endAt: null,
      orderBy: 'unreleasedRebate:desc',
    };
    const productsRequest: SearchUnreleasedProductsRequest = {
      searchText: '',
      pageSize: 10,
      currentPage: 1,
      seasonCode: null,
      supplierNumber: null,
      departmentNumbers: [],
      orderBy: 'unreleasedRebate:desc',
    };
    dispatch(searchUnreleasedRebates(rebatesRequest));
    dispatch(searchUnreleasedProducts(productsRequest));
    return () => {
      dispatch(updateType(UnreleasedAmountsTabType.None));
    };
  }, [dispatch]);

  const toggleSideFilters = () => {
    if (!openSideFilters) {
      filterRef?.current?.scrollIntoView?.({block: 'start', behavior: 'smooth'});
    }
    setOpenSideFilters((open) => !open);
  };

  return (
    <StyledRootContent ref={filterRef}>
      <UnreleasedHeader />
      <Grid container wrap="nowrap">
        <Spinner loading={isLoading} />
        {openSideFilters ? (
          <Grid item>
            <SideFilters onClose={() => setOpenSideFilters(false)}>
              {tabType === UnreleasedAmountsTabType.Product ? <ByProductFilters /> : null}
              {tabType === UnreleasedAmountsTabType.Rebate ? <ByRebateFilters /> : null}
            </SideFilters>
          </Grid>
        ) : null}
        <Grid item sx={{width: '100%'}}>
          <Container
            maxWidth="lg"
            classes={{
              root: classes.containerRoot,
              maxWidthLg: classes.containerWidthLg,
            }}
          >
            <ListFilterContainer>
              {tabType === UnreleasedAmountsTabType.Product ? (
                <>
                  <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                    <FiltersIndicator
                      count={selectedProductFiltersCount}
                      onClick={toggleSideFilters}
                    />
                    <SearchInputField
                      placeholder="Search unreleased amounts"
                      width="26rem"
                      onSearch={(value: string) => {
                        dispatch(updateProductSearchText(value));
                      }}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                    <AdvanceButton
                      onClick={() => {
                        dispatch(toggleShowProductAdvanceModal());
                      }}
                      disabled={isLoading || productCount <= 0}
                    >
                      ({productCount}) Release Amounts
                    </AdvanceButton>
                  </Stack>
                </>
              ) : null}
              {tabType === UnreleasedAmountsTabType.Rebate ? (
                <>
                  <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                    <FiltersIndicator
                      count={selectedRebateFiltersCount}
                      onClick={toggleSideFilters}
                    />
                    <SearchInputField
                      placeholder="Search unreleased amounts"
                      width="26rem"
                      onSearch={(value: string) => {
                        dispatch(updateRebateSearchText(value));
                      }}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} useFlexGap sx={{flexWrap: 'wrap'}}>
                    <AdvanceButton
                      onClick={() => {
                        dispatch(toggleShowRebateAdvanceModal());
                      }}
                      disabled={isLoading || rebateCount <= 0}
                    >
                      ({rebateCount}) Release Amounts
                    </AdvanceButton>
                  </Stack>
                </>
              ) : null}
            </ListFilterContainer>
            {tabType === UnreleasedAmountsTabType.Product ? (
              <>
                <UnreleasedProductTable />
                <AdvanceProductsModal />
              </>
            ) : null}

            {tabType === UnreleasedAmountsTabType.Rebate ? (
              <>
                <UnreleasedAmountsRebateTable />
                <AdvanceRebatesModal />
              </>
            ) : null}
          </Container>
        </Grid>
      </Grid>
    </StyledRootContent>
  );
};

export default UnreleasedListing;
