import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

export interface TagProps {
  label: string;
  onClose: (event: any) => void;
  readOnly?: boolean;
}

const TagContainer = styled('div')<TagProps>`
  display: flex;
  align-items: center;
  height: 2.5rem;
  margin: 0.125rem 0.625rem 0.625rem 0.125rem;
  line-height: 22px;
  background-color: ${(props) => (props.readOnly ? '#f2f2f2' : '#fafafa')};
  border: 1px solid #e8e8e8;
  border-radius: 1.5rem;
  box-sizing: content-box;
  padding-left: 1.5rem;
  padding-right: ${(props) => (props.readOnly ? '1.5rem' : '0rem')};
  outline: 0;
  font-size: 1rem;
  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }
`;
const Label = styled('span')<TagProps>`
  padding-right: ${(props) => (props.readOnly ? 0 : '1rem')};
`;
const CloseIconButton = styled(IconButton)`
  width: 2.625rem;
  height: 2.625rem;
  text-align: center;
  display: flex;
  border-radius: 0% 50% 50% 0%;
`;

const CloseIconDisplay = styled(CloseIcon)`
  margin-right: auto;
  margin-left: auto;
  width: 1.5rem;
  height: 1.5rem;
`;

const Tag: FC<TagProps> = (props) => {
  const {label, onClose, readOnly} = props;
  return (
    <TagContainer {...props}>
      <Label {...props}>{label}</Label>
      {!readOnly && (
        <>
          <Divider orientation="vertical" flexItem />
          <CloseIconButton onClick={onClose} data-testid="close-button" disableRipple>
            <CloseIconDisplay />
          </CloseIconButton>
        </>
      )}
    </TagContainer>
  );
};

export default Tag;
