import React, {Fragment, FC} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Consts from '../../app/Consts';
import {formatDate} from '../../utils/DateUtils';
import {getWritebackType} from '../../utils/TypesStatusUtils';
import {ClaimDetailsViewModel} from '../../types';
import DetailItem from './DetailItem';
import EditableApprovalNumber from './EditableApprovalNumber';
import {detailedCellWidth} from './ClaimExpandedView';

const PREFIX = 'ClaimPeriodView';

const classes = {
  root: `${PREFIX}-root`,
  headerRoot: `${PREFIX}-headerRoot`,
  middleRow: `${PREFIX}-middleRow`,
  detailCellRoot: `${PREFIX}-detailCellRoot`,
};

const StyledTable = styled(Table)(() => ({
  [`& .${classes.root}`]: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid  rgba(224, 224, 224, 1)',
  },

  [`& .${classes.headerRoot}`]: {
    border: '1px solid #000',
    textAlign: 'center',
    backgroundColor: '#000',
    color: '#fff',
  },

  [`& .${classes.middleRow}`]: {
    borderBottom: 'none',
  },

  [`& .${classes.detailCellRoot}`]: {
    border: '1px solid rgba(224, 224, 224, 1)',
    width: `${detailedCellWidth}rem`,
    minWidth: `${detailedCellWidth}rem`,
    padding: 0,
    verticalAlign: 'top',
    backgroundColor: 'rgba(128, 128, 128, .1)',
  },
}));

export const CellMode = {
  Fixed: 'Fixed',
  Calculated: 'Calculated',
  Editable: 'Editable',
};

type Column = {
  label: string | React.ReactNode;
  render?: (data: ClaimDetailsViewModel) => React.ReactNode;
  style?: React.CSSProperties;
  mode?: string;
  colSpan?: number;
  rowSpan?: number;
};

type Periods = {
  title: string;
  startAt: string;
  endAt: string;
  columns: Column[];
};

type Props = {
  fixedTableLayout?: boolean;
  periods: Periods[];
  onRebateApprovalNumberUpdated?: (approvalNumber: string) => Promise<void>;
  data: ClaimDetailsViewModel;
};

const ClaimPeriodView: FC<Props> = ({
  fixedTableLayout = false,
  periods = [],
  onRebateApprovalNumberUpdated,
  data,
}) => {
  const isAdjustment = Boolean(data.parentClaimId);

  if (periods === null || periods.length === 0) {
    return null;
  }
  const getCellStyle = (currentStyle: React.CSSProperties, mode?: string) => {
    switch (mode) {
      case CellMode.Fixed:
        return {...currentStyle, backgroundColor: 'rgba(128, 128, 128, 0.4)'};
      case CellMode.Calculated:
        return {...currentStyle, backgroundColor: 'rgba(128, 128, 128, .1)'};
      case CellMode.Editable:
        return {...currentStyle, backgroundColor: '#FFF'};
      default:
        return currentStyle;
    }
  };
  const renderLeftView = (data: ClaimDetailsViewModel) => {
    let viewList = [];
    if (isAdjustment) {
      viewList = [
        {
          title: 'Finance Account:',
          render: (data: ClaimDetailsViewModel) =>
            data.financeAccounts && data.financeAccounts.join(', '),
        },
        {
          title: 'Invoice Number:',
          render: (data: ClaimDetailsViewModel) => data.invoiceNumber ?? '',
        },
      ];
    } else if (data.agreementType === Consts.AgreementTypeEnum.ContractAgreement) {
      viewList = [
        {
          title: 'Agreement:',
          render: (data: ClaimDetailsViewModel) =>
            `${data.agreementId} - ${data.agreementDescription}`,
        },
        {
          title: 'Finance Account:',
          render: (data: ClaimDetailsViewModel) =>
            data.financeAccounts && data.financeAccounts.join(', '),
        },
        {
          title: 'Invoice Number:',
          render: (data: ClaimDetailsViewModel) => data.invoiceNumber ?? '',
        },
        {
          title: 'Approval Number:',
          render: (data: ClaimDetailsViewModel) => (
            <EditableApprovalNumber
              isEditable={data.isEditable}
              approvalNumber={data.approvalNumber}
              onApprovalNumberUpdated={onRebateApprovalNumberUpdated}
            />
          ),
        },
        {
          title: 'Writebacks included:',
          render: (data: ClaimDetailsViewModel) => getWritebackType(data.writebackType) ?? '',
        },
        {title: 'Department:', render: (data: ClaimDetailsViewModel) => data.departmentDescription},
        {title: 'Internal Notes:', render: (data: ClaimDetailsViewModel) => data.agreementComment},
      ];
    } else {
      viewList = [
        {
          title: 'Finance Account:',
          render: (data: ClaimDetailsViewModel) =>
            data.financeAccounts && data.financeAccounts.join(', '),
        },
        {
          title: 'Invoice Number:',
          render: (data: ClaimDetailsViewModel) => data.invoiceNumber ?? '',
        },
        {title: 'Approval Number:', render: (data: ClaimDetailsViewModel) => data.approvalNumber},
        {title: 'Department:', render: (data: ClaimDetailsViewModel) => data.departmentDescription},
        {title: 'Internal Notes:', render: (data: ClaimDetailsViewModel) => data.agreementComment},
      ];
    }

    return (
      <Grid
        container
        direction="column"
        sx={{
          height: '100%',
          '& > div': {borderBottom: '1px solid #CCC'},
          '& > div:last-of-type': {borderBottom: 'none'},
        }}
        alignContent="space-between"
        justifyContent="space-evenly"
      >
        {viewList.map((item, index) => (
          <DetailItem title={item.title} key={index}>
            {item.render(data)}
          </DetailItem>
        ))}
      </Grid>
    );
  };
  const periodCount = periods.length;
  return (
    <StyledTable sx={{tableLayout: fixedTableLayout ? 'fixed' : ''}}>
      <TableBody>
        {periods.map((period, index) => {
          return (
            <Fragment key={index}>
              <TableRow>
                {index === 0 ? (
                  <TableCell classes={{root: classes.detailCellRoot}} rowSpan={periodCount * 3}>
                    {renderLeftView(data)}
                  </TableCell>
                ) : null}
                <TableCell colSpan={10} classes={{root: classes.headerRoot}}>
                  {period.title} ({period.startAt && formatDate(period.startAt)} -{' '}
                  {period.endAt && formatDate(period.endAt)})
                </TableCell>
              </TableRow>

              <TableRow>
                {period.columns.map((column, colIndex) => {
                  const style = getCellStyle(
                    {...column.style, verticalAlign: 'top', width: '10%'},
                    column.mode
                  );
                  return (
                    <TableCell
                      style={style || {}}
                      classes={{root: `${classes.root} ${classes.middleRow}`}}
                      key={colIndex}
                      colSpan={column.colSpan || 1}
                      rowSpan={column.rowSpan || 1}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {period.columns.map((column, colIndex) => {
                  const style = getCellStyle({...column.style, width: '10%'}, column.mode);
                  return (
                    <TableCell
                      style={style}
                      key={colIndex}
                      classes={{
                        root: `${classes.root} ${
                          periodCount - 1 === index ? '' : classes.middleRow
                        }`,
                      }}
                      colSpan={column.colSpan || 1}
                      rowSpan={column.rowSpan || 1}
                    >
                      {column.render && column.render(data)}
                    </TableCell>
                  );
                })}
              </TableRow>
            </Fragment>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default ClaimPeriodView;
