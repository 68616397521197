import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useRouteError,
} from 'react-router-dom';
import Consts from '../app/Consts';
import Adjustments from '../pages/All/Adjustments';
import ClaimAdjustment from '../pages/ClaimAdjustment';
import Claims from '../pages/All/Claims';
import ContractAgreementSummary from '../pages/MyHome/ContractAgreementSummary';
import ContractAgreements from '../pages/All/ContractAgreements';
import CreateAdjustment from '../pages/MyHome/CreateAdjustment';
import CreateContractAgreement from '../pages/MyHome/CreateContractAgreement';
import CreateDeal from '../pages/MyHome/CreateDeal';
import CreateMixAndMatch from '../pages/MyHome/CreateMixAndMatch';
import CreateRebate from '../pages/MyHome/CreateRebate';
import CreateSPIV from '../pages/MyHome/CreateSPIV';
import DealSummary from '../pages/MyHome/DealSummary';
import Deals from '../pages/All/Deals';
import DealsPage from '../pages/MyHome/MyDeals';
import Drafts from '../pages/All/Drafts';
import EditContractAgreement from '../pages/MyHome/EditContractAgreement';
import EditDeal from '../pages/MyHome/EditDeal';
import EditMixAndMatch from '../pages/MyHome/EditMixAndMatch';
import EditRebate from '../pages/MyHome/EditRebate';
import EditSPIV from '../pages/MyHome/EditSPIV';
import Home from '../pages/Home';
import Layout from './Layout';
import MyClaims from '../pages/MyHome/MyClaims';
import MyContractAgreements from '../pages/MyHome/MyContractAgreements';
import MyDrafts from '../pages/MyHome/MyDrafts';
import MyRebates from '../pages/MyHome/MyRebates';
import RebateSummary from '../pages/MyHome/RebateSummary';
import Rebates from '../pages/All/Rebates';
import RequireAuth from '../components/Auth/RequireAuth';
import SPIVSummary from '../pages/MyHome/SPIVSummary';
import MixAndMatchSummary from '../pages/MyHome/MixAndMatchSummary';
import UnreleasedAmountsPage from '../pages/All/UnreleasedAmounts';
import CreateDealReversal from '../pages/MyHome/CreateDealReversal';

const ExposeRoutesError = () => {
  const error = useRouteError();
  throw error;
};

export const AppRoutes = (
  <Route element={<Layout />} ErrorBoundary={ExposeRoutesError}>
    <Route path={Consts.RouterPath.Home} element={<Home />} />
    <Route
      path={Consts.RouterPath.CreateDeal}
      element={
        <RequireAuth
          requiredRoles={{
            any: [
              Consts.UserRoleEnum.ViewDealsAndContractAgreements,
              Consts.UserRoleEnum.AddOrUpdateDeals,
            ],
          }}
        >
          <CreateDeal />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.EditDeal}
      element={
        <RequireAuth
          requiredRoles={{
            all: [
              Consts.UserRoleEnum.ViewDealsAndContractAgreements,
              Consts.UserRoleEnum.AddOrUpdateDeals,
            ],
          }}
        >
          <EditDeal />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.CreateMixAndMatch}
      element={
        <RequireAuth
          requiredRoles={{
            any: [Consts.UserRoleEnum.ManageMixAndMatches],
          }}
        >
          <CreateMixAndMatch />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.EditMixAndMatch}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ManageMixAndMatches],
          }}
        >
          <EditMixAndMatch />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.MixAndMatchSummary}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements],
          }}
        >
          <MixAndMatchSummary />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.SPIVSummary}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements],
          }}
        >
          <SPIVSummary />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.CreateSPIV}
      element={
        <RequireAuth
          requiredRoles={{
            any: [Consts.UserRoleEnum.ManageSpivs],
          }}
        >
          <CreateSPIV />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.EditSpiv}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ManageSpivs],
          }}
        >
          <EditSPIV />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.CreateContractAgreement}
      element={
        <RequireAuth
          requiredRoles={{
            all: [
              Consts.UserRoleEnum.ViewDealsAndContractAgreements,
              Consts.UserRoleEnum.AddOrUpdateContractAgreements,
            ],
          }}
        >
          <CreateContractAgreement />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.EditContractAgreement}
      element={
        <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.AddOrUpdateContractAgreements]}}>
          <EditContractAgreement />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.MyDeals}
      element={
        <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements]}}>
          <DealsPage />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.MyContractAgreements}
      element={
        <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements]}}>
          <MyContractAgreements />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.ContractAgreements}
      element={
        <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements]}}>
          <ContractAgreements />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.MyRebates}
      element={
        <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements]}}>
          <MyRebates />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.Rebates}
      element={
        <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements]}}>
          <Rebates />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.MyClaims}
      element={
        <RequireAuth
          requiredRoles={{
            any: [
              Consts.UserRoleEnum.ManageContractAgreementClaims,
              Consts.UserRoleEnum.ManageDealClaims,
            ],
          }}
        >
          <MyClaims />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.Claims}
      element={
        <RequireAuth
          requiredRoles={{
            any: [
              Consts.UserRoleEnum.ManageContractAgreementClaims,
              Consts.UserRoleEnum.ManageDealClaims,
            ],
          }}
        >
          <Claims />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.ClaimAdjustment}
      element={
        <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.BulkUploadEditRebateClaims]}}>
          <ClaimAdjustment />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.Deals}
      element={
        <RequireAuth requiredRoles={{all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements]}}>
          <Deals />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.MyDrafts}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements],
            any: [
              Consts.UserRoleEnum.AddOrUpdateDeals,
              Consts.UserRoleEnum.AddOrUpdateContractAgreements,
            ],
          }}
        >
          <MyDrafts />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.Drafts}
      element={
        <RequireAuth
          requiredRoles={{
            all: [
              Consts.UserRoleEnum.ViewDealsAndContractAgreements,
              Consts.UserRoleEnum.AddOrUpdateContractAgreements,
            ],
          }}
        >
          <Drafts />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.DealSummary}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements],
          }}
        >
          <DealSummary />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.ContractAgreementSummary}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements],
          }}
        >
          <ContractAgreementSummary />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.CreateRebate}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.AddOrUpdateContractAgreements],
          }}
        >
          <CreateRebate />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.EditRebate}
      element={
        <RequireAuth
          requiredRoles={{
            all: [
              Consts.UserRoleEnum.AddOrUpdateContractAgreements,
              Consts.UserRoleEnum.ViewDealsAndContractAgreements,
            ],
          }}
        >
          <EditRebate />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.RebateSummary}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ViewDealsAndContractAgreements],
          }}
        >
          <RebateSummary />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.CreateAdjustment}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ApproveDealsAndContractAgreements],
          }}
        >
          <CreateAdjustment />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.Adjustments}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ApproveDealsAndContractAgreements],
          }}
        >
          <Adjustments />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.UnreleasedAmounts}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.ManageUnreleasedAmounts],
          }}
        >
          <UnreleasedAmountsPage />
        </RequireAuth>
      }
    />
    <Route
      path={Consts.RouterPath.DealReversal}
      element={
        <RequireAuth
          requiredRoles={{
            all: [Consts.UserRoleEnum.AddOrUpdateContractAgreements],
          }}
        >
          <CreateDealReversal />
        </RequireAuth>
      }
    />
    <Route path="*" element={<Home />} />
  </Route>
);

const router = createBrowserRouter(createRoutesFromElements(AppRoutes));

export default router;
