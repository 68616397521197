import React from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import IconLabelButton from '../Button/IconLabelButton';
import {IconLabelComponent} from '../IconLabelComponent';
import RequireAuth from '../Auth/RequireAuth';
import {LinkButton} from '../Button';

const Link = styled(LinkButton)`
  color: #da6a00;
  text-decoration: none;
`;

const PREFIX = 'HeadingComponent';

const classes = {
  root: `${PREFIX}-root`,
  headingRoot: `${PREFIX}-headingRoot`,
  subHeadingRoot: `${PREFIX}-subHeadingRoot`,
  buttonGroup: `${PREFIX}-buttonGroup`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 26px',
    border: 'none',
    boxShadow: 'none',
  },

  [`& .${classes.headingRoot}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  [`& .${classes.subHeadingRoot}`]: {
    padding: '15px 0 0',
    color: theme.palette.gray.dark,
  },

  [`& .${classes.buttonGroup}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const HeadingComponent = ({
  headingIcon,
  headingLabel,
  buttons,
  subheading = '',
  subheadingLinkUrl = '',
  subheadingLink = '',
  buttonGroupStyle = {},
  ...props
}) => {
  const navigate = useNavigate();

  const linkToPage = () => {
    navigate(subheadingLinkUrl);
  };
  return (
    <StyledPaper className={classes.root} {...props}>
      <div className={classes.headingRoot}>
        <IconLabelComponent icon={headingIcon} label={headingLabel} />
        <div className={classes.buttonGroup} style={buttonGroupStyle}>
          {buttons.length
            ? buttons.map((button, index) =>
                button.isLocked || !button.role ? null : (
                  <RequireAuth requiredRoles={{all: [button.role]}} alt={null} key={index}>
                    <IconLabelButton
                      label={button.label}
                      icon={button.icon}
                      style={button.style}
                      disabled={button.disabled}
                      disabledText={button.disabledText}
                      onClick={button.onClick}
                    />
                  </RequireAuth>
                )
              )
            : null}
        </div>
      </div>
      {subheading ? (
        <div className={classes.subHeadingRoot}>
          {subheading} <Link onClick={linkToPage}>{subheadingLink}</Link>
        </div>
      ) : null}
    </StyledPaper>
  );
};

export {HeadingComponent};
