import React, {useState, useEffect, FC} from 'react';
import {styled} from '@mui/material/styles';
import {AxiosResponse, AxiosError} from 'axios';
import {GroupProductBulkUploadResponse} from '../../../../types';
import {StaticButton} from '../../../Button';
import Dropzone from '../../../DragDrop/Dropzone';
import {Modal} from '../../../Modal';
import BulkUploadErrorModal from '../../../Modal/BulkUploadErrorModal';
import BulkUploadInfoModal from './BulkUploadInfoModal';

const TemplateLinkButton = styled(StaticButton)`
  padding: 0;
  line-height: unset;
  vertical-align: unset;
`;

type Props = {
  title: string;
  uploadUrl: string;
  open: boolean;
  onClose: () => void;
  onOpenTemplate: () => void;
  onComplete: (response?: GroupProductBulkUploadResponse, keepModalOpen?: boolean) => void;
  onReupload?: () => void;
  additionalData?: any[];
  children?: React.ReactNode;
};

const MixAndMatchValuesBulkUploadModal: FC<Props> = ({
  title,
  uploadUrl,
  additionalData,
  open,
  onClose,
  onOpenTemplate,
  onComplete,
  onReupload,
}) => {
  const [modalOpen, setModalOpen] = useState(open);
  const [uploadErrorModalOpen, setUploadErrorModalOpen] = useState(false);
  const [response, setResponse] = useState<
    AxiosResponse<GroupProductBulkUploadResponse> | undefined
  >();
  const [uploadWarningModalOpen, setUploadWarningModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const onUploadError = (error: AxiosError<GroupProductBulkUploadResponse>) => {
    setResponse(error.response);
    setModalOpen(false);
    setUploadErrorModalOpen(true);
  };

  const onUploadComplete = (response?: AxiosResponse<GroupProductBulkUploadResponse>) => {
    const warning = response?.data?.warning;
    const hasWarnings = Boolean(warning?.lineWarnings && warning.lineWarnings.length > 0);
    const skuOverlaps =
      response?.data?.mixAndMatchGroupProductsResponse?.flatMap((gp) => gp.skuOverlap) ?? [];
    const hasSKUOverlaps = skuOverlaps.length > 0;

    setModalOpen(!hasWarnings && !hasSKUOverlaps);
    setUploadWarningModalOpen(hasWarnings || hasSKUOverlaps);
    onComplete(response?.data, hasWarnings || hasSKUOverlaps);
    if (hasWarnings || hasSKUOverlaps) {
      setResponse(response);
    }
  };

  const renderSubtitle = () => (
    <div>
      <span>Use our handy </span>
      <TemplateLinkButton onClick={onOpenTemplate}>Excel templates</TemplateLinkButton>
      <span> to upload all your data in one go.</span>
    </div>
  );

  const handleWarningUploadAgain = () => {
    setUploadWarningModalOpen(false);
    setModalOpen(true);
    onReupload?.();
  };

  const handleWarningContinue = () => {
    setUploadWarningModalOpen(false);
    setModalOpen(false);
  };

  return (
    <>
      <Modal title={title} open={modalOpen} onClose={onClose} renderSubtitle={renderSubtitle}>
        <Dropzone
          apiEndpoint={uploadUrl}
          additionalData={additionalData}
          onError={onUploadError}
          onComplete={onUploadComplete}
        />
      </Modal>
      <BulkUploadErrorModal
        open={uploadErrorModalOpen}
        response={response}
        onReupload={() => {
          setUploadErrorModalOpen(false);
          setModalOpen(true);
        }}
        onOpenTemplate={() => {
          setUploadErrorModalOpen(false);
          onOpenTemplate();
        }}
        onClose={() => {
          setUploadErrorModalOpen(false);
          onClose();
        }}
      />
      <BulkUploadInfoModal
        open={uploadWarningModalOpen}
        data={response?.data}
        onReupload={handleWarningUploadAgain}
        onClose={handleWarningContinue}
      />
    </>
  );
};

export default MixAndMatchValuesBulkUploadModal;
