import React from 'react';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const PREFIX = 'TableCellUnitField';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputFocused: `${PREFIX}-inputFocused`,
  disabled: `${PREFIX}-disabled`,
  notchedOutline: `${PREFIX}-notchedOutline`,
};

const StyledTextField = styled(TextField)(({theme}) => ({
  [`&.${classes.root}`]: {
    flexGrow: 0,
    borderRadius: 'unset',
    caretColor: 'transparent',
    fontSize: '15px',
    backgroundColor: '#F2F4F5',
    '&:focus': {
      border: 'none',
      outline: 0,
    },
  },

  [`& .${classes.input}`]: {
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    caretColor: 'transparent',
    height: '16px',
    color: 'initial',
  },

  [`& .${classes.inputRoot}`]: {
    borderRadius: 'unset',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      outline: 0,
    },
  },

  [`& .${classes.inputFocused}`]: {
    border: 'none',
    outline: 0,
  },

  [`& .${classes.disabled}`]: {
    borderColor: '#CBD2D8',
    '&& $notchedOutline': {
      borderColor: '#CBD2D8',
    },
  },

  [`& .${classes.notchedOutline}`]: {
    borderColor: '#CBD2D8',
  },
}));

export default function TableCellUnitField(props) {
  const styleProps = {
    InputProps: {
      classes: {
        root: classes.inputRoot,
        input: classes.input,
        focused: classes.inputFocused,
        disabled: classes.disabled,
        notchedOutline: classes.notchedOutline,
      },
    },
    inputProps: {
      readOnly: true,
      tabIndex: -1,
    },
  };

  return (
    <StyledTextField
      variant="outlined"
      fullWidth
      disabled
      {...props}
      classes={{root: classes.root}}
      {...styleProps}
    />
  );
}
