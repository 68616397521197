import React, {FC} from 'react';
import {ButtonProps} from '@mui/material/Button';
import StaticButton from './StaticButton';
import {UploadIcon} from '../Icons';

type Props = {
  children: React.ReactNode;
} & ButtonProps;

const BulkUploadIconButton: FC<Props> = ({children, ...props}) => (
  <StaticButton type="button" startIcon={<UploadIcon />} {...props}>
    {children}
  </StaticButton>
);

export default BulkUploadIconButton;
