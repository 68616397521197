import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {api, get} from '../utils/Request';
import Consts from './Consts';
import config from './Config';
import _debounce from 'lodash/debounce';

export interface FinanceAccount {
  id: number;
  name: string;
}

export interface FinanceAccountsState {
  financialAccounts: FinanceAccount[];
  financialAccountsError: any;
  loadingFinancialAccounts: boolean;
}

export const initialState: FinanceAccountsState = {
  financialAccounts: [],
  financialAccountsError: null,
  loadingFinancialAccounts: false,
};

export interface SearchFinancialAccountsRequest {
  text: string;
  pageSize: number;
}

export const debounceSearchForFinancialAccounts = _debounce(
  (dispatch: any, inputText?: string) => searchForFinancialAccounts(dispatch, inputText),
  250
);

const searchForFinancialAccounts = (dispatch: any, inputText = '') => {
  const request: SearchFinancialAccountsRequest = {
    text: inputText,
    pageSize: 20,
  };
  dispatch(searchFinancialAccounts(request));
};

export const searchFinancialAccounts = createAsyncThunk(
  'searchFinancialAccounts',
  async (request: SearchFinancialAccountsRequest, thunkAPI) => {
    return get(api(Consts.Api.FinancialAccounts), {
      params: {
        entityCode: config.entityCode,
        searchText: request.text,
        pageSize: request.pageSize,
      },
    }).then((response) => response.data);
  }
);

const financialAccountsSlice = createSlice({
  name: 'financialAccouunts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchFinancialAccounts.pending.type, (state) => {
        state.loadingFinancialAccounts = true;
      })
      .addCase(searchFinancialAccounts.fulfilled.type, (state, action: any) => {
        state.loadingFinancialAccounts = false;
        state.financialAccounts = action.payload.data;
      })
      .addCase(searchFinancialAccounts.rejected.type, (state, action: any) => {
        state.loadingFinancialAccounts = false;
        state.financialAccountsError = action.error;
      });
  },
});

const financialAccountsReducer = financialAccountsSlice.reducer;
export default financialAccountsReducer;
