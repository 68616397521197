import React, {FC, useState} from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import {useField, useFormikContext} from 'formik';

type Props = {
  options: Array<{label: string; value: string | boolean}>;
  confirm?: FC<{open: boolean; onCancel: () => void; onOk: () => void}> | null;
  verticalDisplay?: boolean;
  onConfirm?: (newValue: string | boolean) => void;
  disabled?: boolean;
  name: string;
  value: any;
};

const RadioGroupField: FC<Props> = ({
  options,
  confirm: ConfirmModal = null,
  verticalDisplay = false,
  onConfirm,
  disabled = false,
  name,
  value,
  ...props
}) => {
  const [field, meta] = useField({name, ...props});

  function renderError() {
    const {touched, error} = meta;
    if (touched && error) {
      return error;
    }
  }
  const [open, setOpen] = useState(false);
  const [valueToChange, setValueToChange] = useState<string | null>(null);
  const {values, setFieldValue} = useFormikContext<any>();

  function onOk() {
    setOpen(false);
    if (valueToChange) {
      setFieldValue(name, valueToChange);
      onConfirm?.(valueToChange);
      setValueToChange(null);
    }
  }
  function onClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (ConfirmModal) {
      setOpen(true);
      setValueToChange(event.target.value);
    } else {
      setFieldValue(name, event.target.value);
    }
  }
  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={!!meta.touched && !!meta.error}
      disabled={disabled}
      sx={{
        '& .MuiFormGroup-root': {
          flexDirection: verticalDisplay ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          label: {
            flex: verticalDisplay ? 'auto' : '1',
            border: '1px solid #CBD2D8',
            backgroundColor: '#FFF',
            height: '50px',
            width: '100%',
            margin: verticalDisplay ? '0 0 5px 0' : '0 8px 0 0',
            '&:last-child': verticalDisplay
              ? {}
              : {
                  margin: 0,
                },
            '& .MuiRadio-root': {
              height: '100%',
            },
          },
        },
      }}
    >
      <RadioGroup name={field.name} value={values[field.name]} onChange={onClick}>
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            value={option.value}
            control={<Radio checked={option.value === values[field.name]} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{renderError()}</FormHelperText>
      {ConfirmModal && (
        <ConfirmModal
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          onOk={onOk}
        />
      )}
    </FormControl>
  );
};

export default RadioGroupField;
