import React, {FC} from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {useFormikContext} from 'formik';
import {ValidSPIVFormValues} from '../../types';

const SPIVDetailsHeader: FC = () => {
  const {
    values: {description, id},
  } = useFormikContext<ValidSPIVFormValues>();
  return (
    <>
      <Stack direction="row" spacing={1}>
        <Box sx={{fontWeight: 'bold'}}>Deal Name:</Box>
        <Box>{description || '-'}</Box>
      </Stack>
      <Stack direction="row" spacing={1} sx={{paddingBottom: '2rem'}}>
        <Box sx={{fontWeight: 'bold'}}>Deal ID:</Box>
        <Box>{id || '-'}</Box>
      </Stack>
    </>
  );
};

export default SPIVDetailsHeader;
