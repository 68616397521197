import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export enum UnreleasedAmountsTabType {
  None = 'false',
  Product = 'Product',
  Rebate = 'Rebate',
}

export interface UnreleasedAmountsState {
  showFilter: boolean;
  tabType: UnreleasedAmountsTabType;
}

export const initialState: UnreleasedAmountsState = {
  showFilter: false,
  tabType: UnreleasedAmountsTabType.None,
};

const unreleasedAmountsSlice = createSlice({
  name: 'unreleasedAmounts',
  initialState,
  reducers: {
    toggleFilterVisibility(state) {
      state.showFilter = !state.showFilter;
    },
    updateType(state, action: PayloadAction<UnreleasedAmountsTabType>) {
      state.tabType = action.payload;
    },
  },
});

const unreleasedAmountsReducer = unreleasedAmountsSlice.reducer;
export const {toggleFilterVisibility, updateType} = unreleasedAmountsSlice.actions;
export default unreleasedAmountsReducer;
