import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import {Button} from '../Button';
import {Modal} from '.';
import Divider from '@mui/material/Divider';
import ErrorSection, {
  SystemError,
  EmptyFileError,
  InvalidFileFormatError,
  ErrorListItem,
  ErrorList,
} from './ErrorSection';
import Consts from '../../app/Consts';
import {WarnIcon} from '../Icons';

const ActionContainer = styled('div')`
  margin-top: 40px;
`;

const GeneralError = {
  InvalidFileFormat: 'InvalidFileFormat',
  EmptyFile: 'EmptyFile',
  NoFile: 'Nofile',
  Others: 'Others',
};

const BulkUploadErrorModal = ({open, response, onClose, onOpenTemplate = () => {}}) => {
  const [currentErrror, setCurrentError] = useState(Consts.ProductBulkUploadErrorType.None);
  const errors = (response && response.data && response.data.errors) || {};
  const renderSubtitle = () => {
    if (errors.duplicates || errors.lineErrors) {
      return <div>Please fix the errors below and upload the excel file again.</div>;
    }
  };
  const renderSystemError = () => {
    if (!response || response.status !== 400) {
      return <SystemError errorMessage={response && response.data && response.detail} />;
    }
  };

  const renderGeneralError = (errors) => {
    if (errors.generalError) {
      switch (errors.generalError) {
        case GeneralError.EmptyFile:
          return <EmptyFileError />;
        case GeneralError.InvalidFileFormat:
          return <InvalidFileFormatError linkText="the template" onOpenTemplate={onOpenTemplate} />;
        default:
          return <SystemError />;
      }
    }
  };
  const renderLineErrors = (errors) => {
    if (errors.lineErrors && errors.lineErrors.length > 0) {
      let count = errors.lineErrors.length;
      let expanded = currentErrror === Consts.ProductBulkUploadErrorType.LineErrors;
      return (
        <div>
          <ErrorSection
            title={`${count} ${count > 1 ? 'lines have errors' : 'line has errors'}`}
            toggleButtonText="View Errors"
            expanded={expanded}
            onToggle={() => {
              setCurrentError(
                expanded
                  ? Consts.ProductBulkUploadErrorType.None
                  : Consts.ProductBulkUploadErrorType.LineErrors
              );
            }}
          >
            {expanded && (
              <ErrorList>
                {errors.lineErrors.map((lineError) => {
                  let message = lineError.errorMessages.join(', ');
                  return (
                    <ErrorListItem key={lineError.line}>
                      Line {lineError.line} - {message}
                    </ErrorListItem>
                  );
                })}
              </ErrorList>
            )}
          </ErrorSection>
        </div>
      );
    }
  };
  const renderLineWarnings = (errors) => {
    const lineWarnings =
      errors.warning && errors.warning.lineWarnings ? errors.warning.lineWarnings : [];

    if (lineWarnings && lineWarnings.length > 0) {
      let count = lineWarnings.length;
      let expanded = currentErrror === Consts.ProductBulkUploadErrorType.LineWarnings;
      return (
        <div>
          <ErrorSection
            title={`${count} ${count > 1 ? 'lines have warnings' : 'line has warnings'}`}
            toggleButtonText="View Warnings"
            expanded={expanded}
            sectionIcon={WarnIcon}
            onToggle={() => {
              setCurrentError(
                expanded
                  ? Consts.ProductBulkUploadErrorType.None
                  : Consts.ProductBulkUploadErrorType.LineWarnings
              );
            }}
          >
            {expanded && (
              <ErrorList>
                {lineWarnings.map((lineError) => {
                  let message = lineError.warningMessages.join(', ');
                  return (
                    <ErrorListItem key={lineError.line}>
                      Line {lineError.line} - {message}
                    </ErrorListItem>
                  );
                })}
              </ErrorList>
            )}
          </ErrorSection>
        </div>
      );
    }
  };
  function joinLineNos(lineNos) {
    if (lineNos.length === 1) {
      return lineNos[0];
    }
    return lineNos
      .slice(0, -1)
      .join(', ')
      .concat(' and ', lineNos[lineNos.length - 1]);
  }
  const renderDuplicateErrors = (errors) => {
    if (errors.duplicates && errors.duplicates.length > 0) {
      let count = errors.duplicates.length;
      let expanded = currentErrror === Consts.ProductBulkUploadErrorType.Duplicates;
      return (
        <div>
          <ErrorSection
            title={`${count} ${count > 1 ? 'sets' : 'set'} of duplicate entries`}
            toggleButtonText="View Duplicates"
            expanded={expanded}
            onToggle={() => {
              setCurrentError(
                expanded
                  ? Consts.ProductBulkUploadErrorType.None
                  : Consts.ProductBulkUploadErrorType.Duplicates
              );
            }}
          >
            {expanded && (
              <ErrorList>
                {errors.duplicates.map((duplicate, index) => {
                  return (
                    <ErrorListItem key={index}>
                      Lines {joinLineNos(duplicate)} are duplicates
                    </ErrorListItem>
                  );
                })}
              </ErrorList>
            )}
          </ErrorSection>
        </div>
      );
    }
  };
  return (
    <Modal
      title={'Upload Unsuccessful'}
      open={open}
      onClose={onClose}
      renderSubtitle={renderSubtitle}
    >
      {renderSystemError()}
      {renderGeneralError(errors)}
      {renderLineErrors(errors)}
      {renderDuplicateErrors(errors)}
      {renderLineWarnings(errors)}
      <Divider />
      <ActionContainer>
        <Button onClick={onClose}>Close</Button>
      </ActionContainer>
    </Modal>
  );
};

export default BulkUploadErrorModal;
