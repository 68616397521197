import axios from 'axios';
import {apiTokenRequest, authProvider, graphTokenRequest} from '../AuthProvider';
import {getDomainConfig} from '../domainUtil';

//interceptor to handle mock api for testing
const DEBUG = process.env.NODE_ENV === 'development';
axios.interceptors.request.use((config) => {
  if (DEBUG) {
    const MockApi = process.env.REACT_APP_MOCK_API_URL;
    if (!MockApi) {
      return config;
    }
    const url = config.url;
    //api call
    if (url.indexOf(`${process.env.REACT_APP_APIM_URL}`) !== -1) {
      if (url.indexOf('/Algolia/') === -1) {
        const newUrl = url.replace(process.env.REACT_APP_APIM_URL, MockApi);
        const newConfig = {...config, url: newUrl};
        return newConfig;
      }
    }
  }
  return config;
});

async function getAuthHeaders(tokenRequest) {
  const token = await authProvider.getToken(tokenRequest);
  const headers = {
    Authorization: 'Bearer ' + token?.accessToken,
  };
  return headers;
}

export const buildConfig = async (config) => {
  const {headers: multiTenancyHeaders} = getDomainConfig();
  const defaultRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      ...multiTenancyHeaders,
    },
    cache: false,
    responseType: 'json',
  };
  const {tokenRequest, headers: newHeaders} = config;
  const authHeaders = await getAuthHeaders(tokenRequest || apiTokenRequest);
  const token = await authProvider.getToken(graphTokenRequest);
  const newConfig = {
    ...defaultRequestConfig,
    ...config,
    headers: {
      ...defaultRequestConfig.headers,
      ...newHeaders,
      ...authHeaders,
      graphToken: token?.accessToken,
    },
  };
  delete newConfig.tokenRequest;
  return newConfig;
};

const get = async (url, config = {}) => {
  const newConfig = await buildConfig(config);
  return axios.get(url, newConfig);
};

const post = async (url, data, config = {}) => {
  const newConfig = await buildConfig(config);
  return axios.post(url, data, newConfig);
};

const put = async (url, data, config = {}) => {
  const newConfig = await buildConfig(config);
  return axios.put(url, data, newConfig);
};

const del = async (url, config = {}) => {
  const newConfig = await buildConfig(config);
  return axios.delete(url, newConfig);
};

const PathSeperator = '/';
const api = (url) => {
  if (url.startsWith(process.env.REACT_APP_APIM_URL)) {
    return url;
  }
  let host = process.env.REACT_APP_APIM_URL;
  if (host.slice(-1) === PathSeperator) {
    host = host.slice(0, -1);
  }
  if (url.slice(0, 1) === PathSeperator) {
    url = url.slice(1);
  }

  return [host, url].join(PathSeperator);
};

export {get, post, put, del, api};
