import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {
  UnreleasedRebateType,
  UnreleasedType,
  updateEndDateFilter,
  updateRebateTypeFilter,
  updateStartDateFilter,
  updateTypeFilter,
} from '../../../app/unreleasedRebatesReducer';
import {DateType, isValidDate} from '../../../utils/DateUtils';
import {CustomAutoComplete} from '../../CustomAutoComplete';
import DatePicker from '../../Form/DatePicker';
import FieldLabel from '../../Form/FieldLabel';
import ClearRebateFiltersButton from './ClearRebateFiltersButton';

const FilterGrid = styled(Grid)`
  margin-top: 1.875rem;
`;
function ByRebateFilters() {
  const dispatch = useAppDispatch();
  const {typeFilter, rebateTypeFilter, startDateFilter, endDateFilter} = useAppSelector(
    (x) => x.unreleasedRebates
  );

  return (
    <Box data-testid="unreleased-rebate-filter">
      <ClearRebateFiltersButton />
      <FilterGrid>
        <FieldLabel>Type</FieldLabel>
        <CustomAutoComplete
          options={[UnreleasedType.ContractAgreement, UnreleasedType.Deal]}
          value={typeFilter}
          getOptionLabel={(option: UnreleasedType | null) => option ?? ''}
          placeholder="All Types"
          onChange={(_: any, option: UnreleasedType | null) => {
            dispatch(updateTypeFilter(option));
          }}
          name="type"
        />
      </FilterGrid>
      <FilterGrid>
        <FieldLabel>Rebate Type</FieldLabel>
        <CustomAutoComplete
          options={[
            UnreleasedRebateType.Sales,
            UnreleasedRebateType.Purchases,
            UnreleasedRebateType.FixedAmount,
          ]}
          value={rebateTypeFilter}
          getOptionLabel={(option: UnreleasedRebateType | null) => option ?? ''}
          placeholder="All Rebate Types"
          onChange={(_: any, option: UnreleasedRebateType | null) => {
            dispatch(updateRebateTypeFilter(option));
          }}
          name="rebateType"
        />
      </FilterGrid>
      <FilterGrid>
        <FieldLabel>Start Date</FieldLabel>
        <DatePicker
          value={startDateFilter}
          dateType={DateType.RangeStart}
          placeholder={'Choose a start date'}
          onChanged={(value: string | null) => {
            if (value === null || isValidDate(value)) {
              dispatch(updateStartDateFilter(value));
            }
          }}
        />
      </FilterGrid>
      <FilterGrid>
        <FieldLabel>End Date</FieldLabel>
        <DatePicker
          value={endDateFilter}
          dateType={DateType.RangeStart}
          placeholder="Choose an end date"
          onChanged={(value: string | null) => {
            if (value === null || isValidDate(value)) {
              dispatch(updateEndDateFilter(value));
            }
          }}
        />
      </FilterGrid>
    </Box>
  );
}

export default ByRebateFilters;
