import Checkbox from '@mui/material/Checkbox';
import {styled} from '@mui/material/styles';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {
  resetUnreleasedRebates,
  searchUnreleasedRebates,
  SearchUnreleasedRebatesRequest,
  toggleRebateSelected,
  UnreleasedRebateData,
  updateRebatesCurrentPage,
  updateRebatesOrderBy,
  updateRebatesPageSize,
} from '../../../app/unreleasedRebatesReducer';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {formatDate} from '../../../utils/DateUtils';
import {Pagination, TableColumn} from '../../../types';
import {SimpleDataTable} from '../../SimpleDataTable';
import {TableTabPanel} from '../../TableTabPanel';

const PREFIX = 'UnreleasedAmountsRebateTable';

const classes = {
  tabPanelRoot: `${PREFIX}-tabPanelRoot`,
};

const StyledTableTabPanel = styled(TableTabPanel)(() => ({
  [`&.${classes.tabPanelRoot}`]: {
    position: 'relative',
    margin: '3.125rem 0',
  },
}));

const MarkedForReleaseCheckbox = styled(Checkbox)(({theme}) => ({
  '&.Mui-disabled': {
    color: theme.palette.green.main,
  },
}));

function UnreleasedAmountsRebateTable() {
  const dispatch = useAppDispatch();

  const {tabType} = useAppSelector((s) => s.unreleasedAmounts);

  const {
    data,
    typeFilter,
    rebateTypeFilter,
    startDateFilter,
    endDateFilter,
    searchText,
    orderBy,
    pageSize,
    totalCount,
    totalPages,
    currentPage,
  } = useAppSelector((s) => s.unreleasedRebates);

  const pagination = {pageSize, totalCount, totalPages, currentPage};
  const columns: TableColumn[] = [
    {
      id: 'selection',
      label: '',
      render: (rowData: UnreleasedRebateData, rowIndex) => {
        return (
          <>
            {rowData.markedForRelease ? (
              <MarkedForReleaseCheckbox checked disabled />
            ) : (
              <Checkbox
                checked={rowData.selected}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                  dispatch(toggleRebateSelected(rowData.id));
                }}
              />
            )}
          </>
        );
      },
    },
    {id: 'agreementId', label: 'Agreement ID', sortable: true},
    {id: 'agreementDescription', label: 'Agreement Description'},
    {id: 'agreementType', label: 'Type', sortable: true},
    {id: 'rebateType', label: 'Rebate Type', sortable: true},
    {id: 'rebateId', label: 'Rebate ID', sortable: true},
    {id: 'rebateDescription', label: 'Rebate Description'},
    {
      id: 'startDate',
      label: 'Start Date',
      sortable: true,
      render: (rowData: UnreleasedRebateData, index) => formatDate(rowData.startDate),
    },
    {
      id: 'endDate',
      label: 'End Date',
      sortable: true,
      render: (rowData: UnreleasedRebateData, index) => formatDate(rowData.endDate),
    },
    {
      id: 'unreleasedRebate',
      label: 'Unreleased Rebate',
      sortable: true,
      render: (rowData: UnreleasedRebateData, rowIndex) => {
        return getDisplayAmountValue(rowData.unreleasedRebate, '$');
      },
    },
  ];

  useEffect(() => {
    const request: SearchUnreleasedRebatesRequest = {
      searchText,
      pageSize,
      currentPage,
      agreementType: typeFilter,
      rebateType: rebateTypeFilter,
      startAt: startDateFilter,
      endAt: endDateFilter,
      orderBy: orderBy,
    };
    dispatch(searchUnreleasedRebates(request));
  }, [
    dispatch,
    tabType,
    typeFilter,
    rebateTypeFilter,
    startDateFilter,
    endDateFilter,
    searchText,
    currentPage,
    pageSize,
    orderBy,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetUnreleasedRebates());
    };
  }, [dispatch]);
  return (
    <StyledTableTabPanel className={classes.tabPanelRoot} data-testid="unreleased-rebates-table">
      <SimpleDataTable
        columns={columns}
        rows={data}
        pagination={pagination}
        onChangePage={({currentPage}: Pick<Pagination, 'currentPage'>) => {
          dispatch(updateRebatesCurrentPage(currentPage));
        }}
        onChangePageSize={({pageSize}: Pick<Pagination, 'pageSize'>) => {
          dispatch(updateRebatesCurrentPage(1));
          dispatch(updateRebatesPageSize(pageSize));
        }}
        onOrderBy={(e) => {
          dispatch(updateRebatesOrderBy(e.orderBy));
        }}
        sortOrderBy="amount"
        sortOrder="desc"
      />
    </StyledTableTabPanel>
  );
}

export default UnreleasedAmountsRebateTable;
