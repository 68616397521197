import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import * as R from 'ramda';
import FieldLabel from '../Form/FieldLabel';
import DatePicker from '../Form/DatePicker';
import {DateType, isValidDate} from '../../utils/DateUtils';
import SideFilters from '../SideFilters/SideFilters';
import {LinkButton} from '../Button';
import {CustomAutoComplete} from '../CustomAutoComplete';

const adjustmentTypeOptions = ['Sku', 'Agreement'];
const FilterGrid = styled(Grid)`
  margin-top: 30px;
`;
const ClearGrid = styled(Grid)`
  margin-top: 10px;
`;

export interface AdjustmentSideFilterProps {
  defaultSelection: {
    adjustmentType: string | null;
    startAt: string | null;
    endAt: string | null;
  };
  onChange(filter: any): void;
  onClose(): void;
}

const AdjustmentSideFilter = (props: AdjustmentSideFilterProps) => {
  const {defaultSelection, onChange, onClose} = props;
  const [selection, setSelection] = useState(defaultSelection);

  const filters = [
    {
      label: 'Adjustment Type',
      field: (
        <CustomAutoComplete
          options={adjustmentTypeOptions}
          value={selection.adjustmentType}
          getOptionLabel={(option: string) => option}
          name="adjustmentType"
          onChange={(event: any, option: string, reason: any) => {
            setSelection((prevSelection) => {
              const newSelection = {
                ...prevSelection,
                adjustmentType: option,
              };
              onChange(newSelection);
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'Start Date',
      field: (
        <DatePicker
          value={selection.startAt}
          dateType={DateType.RangeStart}
          placeholder={'Choose a start date'}
          onChanged={(value: string) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, startAt: value};
              if (value === null || isValidDate(value)) {
                onChange(newSelection);
              }
              return newSelection;
            });
          }}
        />
      ),
    },
    {
      label: 'End Date',
      field: (
        <DatePicker
          dateType={DateType.RangeEnd}
          value={selection.endAt}
          placeholder={'Choose an end date'}
          onChanged={(value: string) => {
            setSelection((prevSelection) => {
              const newSelection = {...prevSelection, endAt: value};
              if (value === null || isValidDate(value)) {
                onChange(newSelection);
              }
              return newSelection;
            });
          }}
        />
      ),
    },
  ];

  const onClear = () => {
    setSelection((prevSelection) => {
      const newSelection = R.mapObjIndexed((value, key, obj) => null, prevSelection);
      onChange(newSelection);
      return newSelection;
    });
  };

  const anyFilterHasValue = () => {
    return R.any((x) => !!x, R.values(selection));
  };

  return (
    <SideFilters onClose={onClose}>
      {anyFilterHasValue() && (
        <ClearGrid container justifyContent="flex-end">
          <LinkButton onClick={onClear}>Clear</LinkButton>
        </ClearGrid>
      )}

      {filters.map((item, index) => (
        <FilterGrid key={index}>
          <FieldLabel>{item.label}</FieldLabel>
          {item.field}
        </FilterGrid>
      ))}
    </SideFilters>
  );
};

export default AdjustmentSideFilter;
