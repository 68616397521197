import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import isAfter from 'date-fns/isAfter';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {useAppSelector} from '../../../app/store';
import {selectConfigsData} from '../../../app/selectors';
import {toStartOfTZDayUtc, toEndOfTZDayUtc} from '../../../utils/DateUtils';
import {api, get} from '../../../utils/Request';
import {MixAndMatchDetails, Config, InitialMixAndMatchFormValues} from '../../../types';
import {PageContainer, PageContentContainer} from '../../../components/Container';
import MixAndMatchForm from '../../../components/MixAndMatchForm';
import {ITSupportLink} from '../../../components/Link';

const EditMixAndMatch = () => {
  const configs = useAppSelector(selectConfigsData) as Config;
  let {id = ''} = useParams<{id: string}>();
  const [initialValues, setInitialValues] = useState<InitialMixAndMatchFormValues | null>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const updateFormValues = useCallback(
    (data: MixAndMatchDetails) => {
      setInitialValues({
        id: parseInt(id),
        departments: data.departments,
        description: data.description,
        endAt:
          data.isEndTimeSpecified && data.endAt
            ? toEndOfTZDayUtc(data.endAt).toISOString()
            : data.endAt,
        isEndTimeSpecified: data.isEndTimeSpecified,
        endTime: data.isEndTimeSpecified ? data.endAt : undefined,
        locationCriteria: data.locationCriteria,
        isStartTimeSpecified: data.isStartTimeSpecified,
        receiptDescription: data.receiptDescription,
        startAt:
          data.isStartTimeSpecified && data.startAt
            ? toStartOfTZDayUtc(data.startAt).toISOString()
            : data.startAt,
        startTime: data.isStartTimeSpecified ? data.startAt : undefined,
        claimingStatus: data.claimingStatus ?? Consts.AgreementStatusEnum.Draft,
        draftMode: !!data.claimingStatus
          ? data.claimingStatus === Consts.AgreementStatusEnum.Draft
          : true,
        type: data.type,
        DealCutoffDate: configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs],
        DealLockDate: configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs],
        financeDetails: data.financeDetails ?? [],
      });
    },
    [id, configs]
  );

  function isLocked(initialValues: InitialMixAndMatchFormValues | null) {
    if (!configs || !initialValues) {
      return false;
    }
    if (initialValues.claimingStatus === Consts.AgreementStatusEnum.Draft) {
      return false;
    }
    const cutoffDate = configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs];
    const lockDate = configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs];
    return (
      cutoffDate &&
      lockDate &&
      initialValues.endAt &&
      isAfter(new Date(), new Date(lockDate)) &&
      isAfter(new Date(cutoffDate), new Date(initialValues.endAt))
    );
  }

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.MixAndMatchInfo.replace(':id', id)))
      .then((response: AxiosResponse<MixAndMatchDetails>) => {
        alertService.clear(defaultAlertId);
        updateFormValues(response.data);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [showLoading, hideLoading, id, updateFormValues]);

  if (isLocked(initialValues)) {
    return (
      <PageContainer>
        <PageContentContainer>
          The mix & match is locked and cannot be edited anymore. If you want to unlock it, please
          contact {Consts.ItSupport} at <ITSupportLink />
        </PageContentContainer>
      </PageContainer>
    );
  }
  return initialValues ? (
    <MixAndMatchForm title="Edit Mix & Match" initialValues={initialValues} />
  ) : null;
};

export default EditMixAndMatch;
