import React, {FC} from 'react';
import {getDisplayAmountValue, isNegative} from '../../utils/AmountUtils';
import {RedSpan} from '../RedSpan';

type Props = {
  value?: number | null;
  prefix?: string;
  postfix?: string;
  digits?: number;
};

const Amount: FC<Props> = ({value, prefix = '$', postfix = '', digits = 2}) => {
  if (value === null || value === undefined || isNaN(value)) {
    return null;
  }
  const negative = isNegative(value);
  let displayValue = getDisplayAmountValue(value, prefix, postfix, digits);
  return negative ? <RedSpan>{displayValue}</RedSpan> : displayValue;
};

export default Amount;
