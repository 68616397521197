import React, {FC, useEffect, useState, useRef} from 'react';
import {AxiosResponse} from 'axios';
import {Department} from '../../../types';
import {api, get} from '../../../utils/Request';
import Consts from '../../../app/Consts';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import useDelayLoading from '../../Hook/useDelayLoading';
import config from '../../../app/Config';
import {CustomAutoComplete} from '../../CustomAutoComplete';

type Props = {
  value: Department[] | null;
  onChange: (_: any, option: Department[]) => void;
  onDataLoad?: (data: Department[]) => void;
  name?: string;
  errorText?: string;
  disabled?: boolean;
};

const DepartmentMultipleSearch: FC<Props> = ({
  value,
  onChange,
  name = 'department',
  onDataLoad,
  errorText,
  disabled,
}) => {
  const dataFetchedRef = useRef(false);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [departmentLoading, setDepartmentLoading] = useDelayLoading(false);

  useEffect(() => {
    if (!dataFetchedRef.current) {
      setDepartmentLoading(true);
      dataFetchedRef.current = true;
      get(api(Consts.Api.Departments), {params: {entityCode: config.entityCode}})
        .then((response: AxiosResponse<Department[]>) => {
          setDepartments(response.data);
          onDataLoad?.(response.data);
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        })
        .finally(() => {
          setDepartmentLoading(false);
        });
    }
  }, [setDepartmentLoading, setDepartments, onDataLoad]);

  return (
    <CustomAutoComplete
      options={departments}
      value={value}
      placeholder={value && value.length > 0 ? '' : 'All departments'}
      name={name}
      multiple
      getOptionLabel={(option: Department) => option.description}
      loading={departmentLoading}
      onChange={onChange}
      errorText={errorText}
      disabled={disabled || (departmentLoading && departments.length === 0)}
    />
  );
};

export default DepartmentMultipleSearch;
