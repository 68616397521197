import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {api, get} from '../utils/Request';
import Consts from './Consts';
import config from './Config';
import {Config} from '../types';
import {transformConfigs} from '../utils/ConfigsUtil';

const getConfigs = createAsyncThunk('getConfigs', async (arg, thunkAPI) => {
  return get(api(Consts.Api.Configs), {
    params: {
      entityCode: config.entityCode,
    },
  }).then((response) => response.data);
});

export interface ConfigState {
  data: Config | undefined;
  error: any;
  loading: boolean;
}

export const initialState: ConfigState = {
  data: undefined,
  error: null,
  loading: true,
};

const configSlice = createSlice({
  name: 'configs',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigs.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getConfigs.fulfilled.type, (state, action: any) => {
        state.loading = false;
        state.data = transformConfigs(action.payload);
      })
      .addCase(getConfigs.rejected.type, (state, action: any) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

const configsReducer = configSlice.reducer;
export default configsReducer;
export {getConfigs};
