import React, {FC} from 'react';
import Stack from '@mui/material/Stack';
import {EditIcon} from '../../../../components/Icons';
import IconLabelButton from '../../../../components/Button/IconLabelButton';
import {CancelActionButton, SaveActionButton} from '../../../../components/Table';

type Props = {
  editing: boolean;
  onCancel: () => void;
  onEdit: () => void;
  onSave: () => void;
  saveDisabled: boolean;
};

const EditActions: FC<Props> = ({onCancel, onEdit, onSave, editing, saveDisabled}) => {
  if (editing) {
    return (
      <Stack direction="row" spacing={2}>
        <SaveActionButton
          size="small"
          type="submit"
          onClick={onSave}
          disabled={saveDisabled}
          style={{
            height: '2rem',
            width: '2rem',
            padding: '0.25rem',
            borderRadius: '1rem !important',
          }}
          data-testid="save-button"
        />
        <CancelActionButton
          size="small"
          onClick={onCancel}
          style={{
            height: '2rem',
            width: '2rem',
            padding: '0.25rem',
            borderRadius: '1rem !important',
          }}
          data-testid="cancel-button"
        />
      </Stack>
    );
  }
  return (
    <IconLabelButton
      sx={{
        fontSize: '1rem',
        fontWeight: 'normal',
        padding: '0.375rem',
        lineHeight: 'normal',
        textDecoration: 'underline',
        height: 'unset',
        svg: {
          height: '1rem',
          width: '1rem',
        },
      }}
      icon={<EditIcon />}
      label="Edit"
      onClick={onEdit}
      data-testid="edit-button"
    />
  );
};

export default EditActions;
