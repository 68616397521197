import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Consts from '../../../app/Consts';

const PageSetting = ({pagination, onChangePageSize = (size) => {}}) => {
  return (
    <div>
      <span style={{paddingRight: '0.3125rem'}}>Rows: </span>
      <Select
        value={pagination.pageSize ?? Consts.PageSize[0]}
        onChange={(event) => {
          onChangePageSize(event.target.value);
        }}
        variant="outlined"
        sx={{'div:first-of-type': {padding: '0.625rem 0.875rem'}}}
      >
        {Consts.PageSize.map((size) => {
          return (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default PageSetting;
