import claimsReducer from './claimsReducer';
import configsReducer from './configReducer';
import contractAgreementsReducer from './contractAgreementsReducer';
import dealsReducer from './dealsReducer';
import financialAccountsReducer from './financialAccountsReducer';
import ownersReducer from './ownersReducer';
import profileReducer from './profileReducer';
import rebatesReducer from './rebatesReducer';
import suppliersReducer from './suppliersReducer';
import unreleasedAmountsReducer from './unreleasedAmountsReducer';
import unreleasedProductsReducer from './unreleasedProductsReducer';
import unreleasedRebatesReducer from './unreleasedRebatesReducer';

const reducer = {
  claims: claimsReducer,
  configs: configsReducer,
  contractAgreements: contractAgreementsReducer,
  deals: dealsReducer,
  financialAccounts: financialAccountsReducer,
  owners: ownersReducer,
  profile: profileReducer,
  rebates: rebatesReducer,
  suppliers: suppliersReducer,
  unreleasedAmounts: unreleasedAmountsReducer,
  unreleasedProducts: unreleasedProductsReducer,
  unreleasedRebates: unreleasedRebatesReducer,
};

export default reducer;
