import React, {FC, useState, useEffect, useCallback, useContext} from 'react';
import {AxiosResponse} from 'axios';
import qs from 'qs';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {api, get} from '../../../../utils/Request';
import {getDisplayAmountValue} from '../../../../utils/AmountUtils';
import {FinanceDetailsProductsResponse, MixAndMatchProduct, Pagination} from '../../../../types';
import {alertService, defaultAlertId} from '../../../../app/AlertService';
import Consts from '../../../../app/Consts';
import LoadingContext from '../../../../app/LoadingContext';
import PageNavigator from '../../../Table/Pagination/PageNavigator';
import PageCounter from '../../../Table/Pagination/PageCounter';
import PageSetting from '../../../Table/Pagination/PageSetting';
import SectionContainer from '../../../Table/Pagination/SectionContainer';

const TablePaginationContainer = styled(SectionContainer)`
  justify-content: space-between;
  height: 3.75rem;
  paddingtop: 1.25rem;
  background-color: #f2f4f5;
  input,
  .MuiSelect-select {
    background-color: #ffffff;
  }
`;

type Props = {
  financeDetailsId: number;
  showClaimAmount: boolean;
};

const defaultPagination = {...Consts.DefaultPagination, pageSize: 10};

const FinanceDetailsProducts: FC<Props> = ({financeDetailsId, showClaimAmount}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [data, setData] = useState<MixAndMatchProduct[]>([]);
  const [pagination, setPagination] = useState(defaultPagination);

  const fetchProducts = useCallback(
    async (params?: Partial<Pagination>) => {
      try {
        showLoading();
        const response: AxiosResponse<FinanceDetailsProductsResponse> = await get(
          api(
            Consts.Api.MixAndMatchFinanceDetailsProducts.replace(
              ':financeDetailsId',
              `${financeDetailsId}`
            )
          ),
          {
            params: params ?? {
              currentPage: defaultPagination.currentPage,
              pageSize: defaultPagination.pageSize,
            },
            paramsSerializer: (params: Partial<Pagination>) =>
              qs.stringify(params, {skipNulls: true, arrayFormat: 'repeat'}),
          }
        );

        const {data, ...pagination} = response.data;
        setData(data);
        setPagination(pagination);
      } catch (error: any) {
        alertService.alert({
          id: defaultAlertId,
          ...{message: 'Failed to load finance details products', response: error.response},
        });
      } finally {
        hideLoading();
      }
    },
    [financeDetailsId, showLoading, hideLoading]
  );

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const onPagination = useCallback(
    (next?: Partial<Pagination>) => {
      const params = {
        currentPage: next?.currentPage ?? pagination.currentPage,
        pageSize: next?.pageSize ?? pagination.pageSize,
      };
      fetchProducts(params);
    },
    [pagination, fetchProducts]
  );

  const onChangePage = (currentPage: number) => {
    onPagination({currentPage});
  };

  const onChangePageSize = (pageSize: number) => {
    onPagination({currentPage: 1, pageSize});
  };
  const formattedAmount = (item: MixAndMatchProduct) => {
    const amount = item.claimAmount ?? '';
    const prefix = item.claimAmountType === Consts.AmountTypeEnum.ValuePerUnit ? '$' : '';
    const postfix = item.claimAmountType === Consts.AmountTypeEnum.Percentage ? '%' : '';
    return getDisplayAmountValue(amount, prefix, postfix);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{paddingLeft: 0}}>SKU</TableCell>
            <TableCell>Model Number</TableCell>
            {showClaimAmount ? <TableCell>Claim Amount</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell sx={{paddingLeft: 0}}>{item.description}</TableCell>
              <TableCell>{item.modelNumber}</TableCell>
              {showClaimAmount ? <TableCell>{formattedAmount(item)}</TableCell> : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePaginationContainer>
        <PageCounter pagination={pagination} />
        <PageNavigator pagination={pagination} onChangePage={onChangePage} />
        <PageSetting pagination={pagination} onChangePageSize={onChangePageSize} />
      </TablePaginationContainer>
    </TableContainer>
  );
};

export default FinanceDetailsProducts;
