import React, {FC} from 'react';
import {useField} from 'formik';
import {styled} from '@mui/material/styles';
import TextField, {TextFieldProps} from '@mui/material/TextField';

const PREFIX = 'InputField';

const classes = {
  input: `${PREFIX}-input`,
};

const StyledTextField = styled(TextField)(() => ({
  [`& .${classes.input}`]: {
    height: '1rem',
  },
  '& .MuiFormHelperText-root': {
    width: '100%',
  },
}));

type Props = TextFieldProps & {
  errorText?: string;
  name: string;
};

const InputField: FC<Props> = ({errorText, name, multiline, ...rest}) => {
  const [field, meta] = useField(name);
  const {value, ...fieldProps} = field;

  const {touched, error} = meta;
  const helperText = touched && error ? error : undefined;

  const styleProps = {
    InputProps: {
      classes: {
        input: multiline ? '' : classes.input,
      },
    },
  };
  return (
    <StyledTextField
      variant="outlined"
      type="text"
      error={!!meta.touched && !!meta.error}
      helperText={helperText}
      multiline={multiline}
      value={value ?? ''}
      {...fieldProps}
      {...rest}
      {...styleProps}
    />
  );
};

export default InputField;
