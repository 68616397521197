import React, {useCallback, useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Consts from '../../../app/Consts';
import AutoCompleteSearch from './AutoCompleteSearch';
import config from '../../../app/Config';

const PREFIX = 'SkuSearch';

const classes = {
  root: `${PREFIX}-root`,
  inputBase: `${PREFIX}-inputBase`,
  input: `${PREFIX}-input`,
};

const StyledAutoCompleteSearch = styled(AutoCompleteSearch)(() => ({
  [`&.${classes.root}`]: {
    borderRadius: 'unset',
  },

  [`& .${classes.inputBase}`]: {
    borderRadius: 'unset',
  },

  [`& .${classes.input}`]: {
    height: '16px',
    fontSize: '15px',
    color: 'initial',
  },
}));

const SkuSearch = ({
  defaultValue,
  errorMessage,
  onChanged,
  placeholder = 'Start typing a SKU',
  ...props
}) => {
  const [sku, setSku] = useState(defaultValue);
  const getSelectedValueCallback = useCallback((hit) => {
    return {
      code: hit.sku,
      name: hit.title,
      ticketPrice: hit.product.ticketPrice,
      suppliers: hit.product.suppliers || [],
      modelNumber: hit.product.modelNumber,
    };
  }, []);
  const renderDisplayTextCallback = useCallback((item) => {
    return item && item.code ? `${item.code} ${item.name}` : '';
  }, []);
  const renderListItemCallback = useCallback((hit) => {
    return `${hit.sku} ${hit.title}`;
  }, []);

  useEffect(() => {
    setSku(defaultValue);
  }, [defaultValue]);

  const onSelected = useCallback(
    (item) => {
      setSku(item);
      onChanged(item);
    },
    [onChanged]
  );
  return (
    <StyledAutoCompleteSearch
      indexName={Consts.AlgoliaIndex.Products}
      {...props}
      InputProps={{
        classes: {
          root: classes.inputBase,
          input: classes.input,
        },
      }}
      defaultRefinement={sku && sku.name}
      defaultValue={sku}
      noResultMessage="We haven't found any SKU that match the text you entered."
      placeholder={placeholder}
      filters={`entityCode:"${config.entityCode}"`}
      getSelectedValue={getSelectedValueCallback}
      renderDisplayText={renderDisplayTextCallback}
      renderListItem={renderListItemCallback}
      errorMessage={errorMessage}
      onSelected={onSelected}
    />
  );
};

export default SkuSearch;
