import React, {useContext} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingContext from '../../app/LoadingContext';
import {styled} from '@mui/material/styles';

const SpinnerContainer = styled('div')`
  position: fixed;
  left: 0;
  z-index: 4000;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageSpinner = () => {
  const {loadingCount} = useContext(LoadingContext);

  return (
    <>
      {loadingCount > 0 && (
        <SpinnerContainer>
          <CircularProgress size={60} />
        </SpinnerContainer>
      )}
    </>
  );
};

export default PageSpinner;
