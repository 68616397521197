import React, {FC, useState, useMemo, useEffect} from 'react';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {DateValidationError} from '@mui/x-date-pickers/models';
import isSameDay from 'date-fns/isSameDay';
import {utcToTZDate, formatToISOByTZ, DateType, isValidDate} from '../../utils/DateUtils';
import Consts from '../../app/Consts';

export type ParsableDate = string | Date | null | undefined;

type Props = {
  dateType: string;
  errorMessage?: string;
  maxDate?: string;
  minDate?: string;
  onAccept?: (date: ParsableDate) => void;
  onChanged: (date?: any) => void;
  placeholder?: string;
  showError?: boolean;
  value?: ParsableDate;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  id?: string;
};

const DatePicker: FC<Props> = ({
  dateType = DateType.Default,
  errorMessage = undefined,
  maxDate,
  minDate,
  onAccept,
  onChanged,
  placeholder,
  showError = true,
  onBlur,
  value,
  fullWidth = true,
  disabled,
  id,
}) => {
  const [error, setError] = useState<DateValidationError | null>(null);
  const [dateValue, setDateValue] = useState<Date | null>(value ? utcToTZDate(value) : null);

  const displayedErrorMessage = useMemo(() => {
    if (!showError) {
      return null;
    }
    switch (error) {
      case 'invalidDate': {
        return Consts.ValidationMessage.Date.FormatError;
      }

      default: {
        return errorMessage;
      }
    }
  }, [error, showError, errorMessage]);

  // required to keep picker textfield value in sync with value prop
  useEffect(() => {
    const valuePropDate = value ? utcToTZDate(value) : null;
    if (
      valuePropDate &&
      dateValue &&
      isValidDate(valuePropDate) &&
      !isSameDay(valuePropDate, dateValue)
    ) {
      setDateValue(valuePropDate);
    } else if (valuePropDate === null && dateValue !== null) {
      setDateValue(null);
    }
  }, [value, dateValue]);

  const handleChange = (date: ParsableDate) => {
    if (date) {
      const result = formatToISOByTZ(new Date(date), dateType);
      onChanged(result);
      setDateValue(utcToTZDate(result));
    } else {
      onChanged(undefined);
      setDateValue(null);
    }
  };

  return (
    <DesktopDatePicker
      format="dd/MM/yyyy"
      minDate={minDate ? utcToTZDate(minDate) : undefined}
      maxDate={maxDate ? utcToTZDate(maxDate) : undefined}
      value={dateValue ? dateValue : null}
      onChange={handleChange}
      onAccept={onAccept}
      onError={(newError) => setError(newError)}
      slotProps={{
        textField: {
          error: !!displayedErrorMessage,
          placeholder,
          fullWidth,
          helperText: displayedErrorMessage,
          value: dateValue ? dateValue : null,
          ...(id ? {id} : {}),
          ...(onBlur ? {onBlur} : {}),
        },
      }}
      sx={{
        width: '100%',
        '& .MuiInputAdornment-root': {
          marginLeft: 0,
        },
      }}
      disabled={disabled}
    />
  );
};

export default DatePicker;
