import React from 'react';
import {OutlinedButton} from '../Button';
import Divider from '@mui/material/Divider';
import {Modal} from '.';
import {ExcelIcon} from '../Icons/Icon';
import {styled} from '@mui/material/styles';

const StyledTemplateItemContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 20px;
`;
const FileName = styled('span')`
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 500;
  flex: 1;
`;
const DownloadLink = styled('a')`
  text-decoration: unset;
`;
const DownloadTemplatesModal = ({open, title, onClose, fileNames = [], ...props}) => {
  function getTemplates() {
    return fileNames.map((item) => {
      let fileNameWithoutExtension = item.split('.').slice(0, -1).join('.');
      return (
        <div key={item}>
          <StyledTemplateItemContainer>
            <ExcelIcon />
            <FileName>{fileNameWithoutExtension}</FileName>
            <DownloadLink href={`${process.env.PUBLIC_URL}\\templates\\${item}`} download>
              <OutlinedButton>Download</OutlinedButton>
            </DownloadLink>
          </StyledTemplateItemContainer>
          <Divider />
        </div>
      );
    });
  }
  return (
    <Modal title={title} open={open} onClose={onClose}>
      <Divider />
      {getTemplates()}
    </Modal>
  );
};

export default DownloadTemplatesModal;
