import React, {FC, useState} from 'react';
import Stack from '@mui/material/Stack';
import {ClaimListItemViewModel} from '../../types';
import {RollbackIcon} from '../Icons';
import RollbackModal from './RollbackModal';

type Props = {
  claim: ClaimListItemViewModel;
  onSuccess: () => void;
};

const RollbackStatus: FC<Props> = ({claim, onSuccess}) => {
  const [openModal, setOpenModal] = useState(false);
  const handleRollback = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpenModal(true);
  };
  const handleOk = () => {
    onSuccess();
    setOpenModal(false);
  };
  return (
    <>
      <Stack
        direction="row"
        gap={1}
        role="button"
        onClick={handleRollback}
        sx={{
          fontWeight: '400',
          textWrap: 'nowrap',
          margin: '0 auto',
          width: 'fit-content',
          textDecoration: 'underline',
          svg: {
            margin: 'auto 0',
          },
          '&:hover': {
            fontWeight: '500',
          },
        }}
      >
        <RollbackIcon data-testid="rollback-icon" />
        Rollback Status
      </Stack>
      <RollbackModal
        claim={claim}
        open={openModal}
        onOk={handleOk}
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
};

export default RollbackStatus;
