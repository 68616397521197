import {styled} from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

const listItemHeight = 56;
const ListContainer = styled('div')`
  position: relative;
`;
const StyledList = styled(List)`
  margin: unset;
  padding: unset;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: #f2f4f5;
  overflow: auto;
  max-height: ${listItemHeight * 6 + 10}px;
`;
const StyledListItem = styled(ListItemButton)`
  border-top: 1px solid #cbd2d8;
  line-height: ${listItemHeight}px;
  box-sizing: border-box;
`;

const NoResultContainer = styled('span')`
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: normal;
  font-style: italic;
  color: #626262;
`;

export {
  ListContainer,
  StyledListItem as ListItem,
  StyledList as List,
  NoResultContainer,
  listItemHeight,
};
