import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const ScrollingActions = styled('div')`
  top: 0;
  z-index: 9999;
  position: sticky;
`;

type Props = {
  children: React.ReactNode;
};

const ListFilterContainer: FC<Props> = ({children}) => (
  <ScrollingActions>
    <Paper
      sx={({palette}) => ({
        margin: '3rem 0 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: palette.background.page,
        boxShadow: 'none',
        border: 'none',
        flexWrap: 'wrap',
        gridGap: '1rem',
      })}
    >
      {children}
    </Paper>
  </ScrollingActions>
);

export default ListFilterContainer;
