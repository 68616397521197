import {
  differenceInDays,
  endOfDay,
  startOfDay,
  startOfMinute,
  isAfter,
  isBefore,
  isEqual,
  isValid,
} from 'date-fns';
import {zonedTimeToUtc, utcToZonedTime} from 'date-fns-tz';
import {getDomainConfig, TenantCountry} from './domainUtil';

const {country} = getDomainConfig();
const DateType = {
  RangeStart: 'rangeStart',
  RangeEnd: 'rangeEnd',
  Default: 'default',
};

const timeZone = country === TenantCountry.NZ ? 'Pacific/Auckland' : 'Australia/Melbourne';

function combineUtcDateTime(
  date: Date | string,
  time: Date | string | undefined | null,
  setSeconds?: boolean
): Date {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (!time) {
    return date;
  }

  if (!date) {
    return new Date(time);
  }

  if (typeof time === 'string') {
    time = new Date(time);
  }
  let datePart = utcToTZDate(date);
  let timePart = utcToTZDate(time);
  datePart.setHours(timePart.getHours());
  datePart.setMinutes(timePart.getMinutes());
  if (setSeconds) {
    datePart.setSeconds(59, 999);
  }
  const utcDateTime = zonedTimeToUtc(datePart, timeZone);
  return utcDateTime;
}

function formatToISOByTZ(date: Date, dateFormatType: string = DateType.Default) {
  try {
    let utcDate;
    if (!isValidDate(date)) {
      return date;
    }
    let d = new Date(date);
    switch (dateFormatType) {
      case DateType.RangeStart:
        utcDate = zonedTimeToUtc(startOfDay(d), timeZone);
        break;
      case DateType.RangeEnd:
        utcDate = zonedTimeToUtc(endOfDay(d), timeZone);
        break;
      default:
        utcDate = zonedTimeToUtc(startOfMinute(d), timeZone);
    }
    return utcDate.toISOString();
  } catch {
    return date;
  }
}

function isValidDate(date: Date | string | undefined | null) {
  if (typeof date === 'string') {
    return isValid(new Date(date));
  }
  return date instanceof Date && isValid(date);
}

// left - right
function daysBetweenInTZ(leftISODateString: string, rightISODateString: string): number {
  if (!isValidDate(leftISODateString) || !isValidDate(rightISODateString)) {
    return NaN;
  }
  const leftDate = utcToTZDate(leftISODateString);
  const rightDate = utcToTZDate(rightISODateString);
  const diff = differenceInDays(leftDate, rightDate);
  return diff;
}

function daysBeforeTodayInTZ(isoDateString: string): number {
  const tzToday = zonedTimeToUtc(new Date(), timeZone).toISOString();
  return daysBetweenInTZ(tzToday, isoDateString);
}

function daysAfterTodayInTZ(isoDateString: string): number {
  const tzToday = zonedTimeToUtc(new Date(), timeZone).toISOString();
  return daysBetweenInTZ(isoDateString, tzToday);
}

function toStartOfTZDayUtc(utcDateString: string): Date {
  var utcDate = new Date(utcDateString);
  const tzDate = utcToTZDate(utcDate);
  return zonedTimeToUtc(startOfDay(tzDate), timeZone);
}

function toEndOfTZDayUtc(utcDateString: string): Date {
  var utcDate = new Date(utcDateString);
  const tzDate = utcToTZDate(utcDate);
  return zonedTimeToUtc(endOfDay(tzDate), timeZone);
}

function tzTimeToUtc(datestring: string): Date | null {
  if (!isValidDate(datestring)) {
    return null;
  }
  const utcDate = zonedTimeToUtc(datestring, timeZone);
  return utcDate;
}

function utcToTZDate(utcDate: Date | string): Date {
  return utcToZonedTime(utcDate, timeZone);
}

function formatDate(isoDate: string | Date | null, treatAsOngoingIfInvalid = true): string {
  if (!isoDate || !isValidDate(isoDate)) {
    return treatAsOngoingIfInvalid ? 'ongoing' : '';
  }
  const utcDate = utcToTZDate(isoDate);
  return `${utcDate.getDate().toString().padStart(2, '0')}/${(utcDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${utcDate.getFullYear()}`;
}

function getTime(isoDate: string): string {
  const utcDate = utcToTZDate(isoDate);

  return `${utcDate.getHours().toString().padStart(2, '0')}:${utcDate
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
}

function getFormattedDateTime(isoDate: string | undefined): string {
  if (!isoDate || !isValidDate(isoDate)) {
    return 'ongoing';
  }
  return `${formatDate(isoDate)} at ${getTime(isoDate)}`;
}

function isDateInRange(startDate: Date, endDate: Date): boolean {
  const today = new Date();
  return (
    (isAfter(today, new Date(startDate)) || isEqual(today, new Date(startDate))) &&
    (isBefore(today, new Date(endDate)) || isEqual(today, new Date(endDate)))
  );
}

export {
  DateType,
  tzTimeToUtc,
  combineUtcDateTime,
  daysAfterTodayInTZ,
  daysBeforeTodayInTZ,
  daysBetweenInTZ,
  formatDate,
  formatToISOByTZ,
  getFormattedDateTime,
  getTime,
  isDateInRange,
  isValidDate,
  toEndOfTZDayUtc,
  toStartOfTZDayUtc,
  utcToTZDate,
};
