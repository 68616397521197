import React from 'react';
import {styled} from '@mui/material/styles';
import Consts from '../../app/Consts';

const PREFIX = 'Status';

const classes = {
  root: `${PREFIX}-root`,
  [Consts.AgreementStatusEnum.InProgress]: `${PREFIX}-${Consts.AgreementStatusEnum.InProgress}`,
  [Consts.AgreementStatusEnum.ReadyToStart]: `${PREFIX}-${Consts.AgreementStatusEnum.ReadyToStart}`,
  [Consts.AgreementStatusEnum.Finished]: `${PREFIX}-${Consts.AgreementStatusEnum.Finished}`,
  [Consts.AgreementStatusEnum.DoNotClaim]: `${PREFIX}-${Consts.AgreementStatusEnum.DoNotClaim}`,
  [Consts.AgreementStatusEnum.Draft]: `${PREFIX}-${Consts.AgreementStatusEnum.Draft}`,
  [Consts.AgreementStatusEnum
    .AwaitingApproval]: `${PREFIX}-${Consts.AgreementStatusEnum.AwaitingApproval}`,
  [Consts.AgreementStatusEnum.Cancelled]: `${PREFIX}-${Consts.AgreementStatusEnum.Cancelled}`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    fontSize: '14px',
    width: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0',
    borderRadius: '5px',
    textAlign: 'center',
  },
  [`&.${classes[Consts.AgreementStatusEnum.InProgress]}`]: {
    color: theme.palette.green.main,
    backgroundColor: theme.palette.green.light,
  },
  [`&.${classes[Consts.AgreementStatusEnum.ReadyToStart]}`]: {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.blue.light,
  },
  [`&.${classes[Consts.AgreementStatusEnum.Finished]}, &.${
    classes[Consts.AgreementStatusEnum.DoNotClaim]
  }, &.${classes[Consts.AgreementStatusEnum.Draft]}`]: {
    color: theme.palette.gray.dark,
    backgroundColor: theme.palette.gray.light,
  },
  [`&.${classes[Consts.AgreementStatusEnum.AwaitingApproval]}`]: {
    color: '#DA6A00',
    backgroundColor: '#FFF1E3',
  },
  [`&.${classes[Consts.AgreementStatusEnum.Cancelled]}`]: {
    color: theme.palette.red.main,
    backgroundColor: theme.palette.red.tint,
    border: `1px solid ${theme.palette.red.main}`,
  },
}));

const statusOptions = {
  ...Consts.AgreementsStatus,
  ...Consts.ClaimStatus,
};

const Status = ({status}) => (
  <Root className={`${classes.root} ${classes?.[status]}`}>{statusOptions?.[status]}</Root>
);

export {Status};
