import React, {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {alertService, defaultAlertId, AlertArgs} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {ITSupportLink} from '../Link';
import ErrorBox from './ErrorBox';

const PREFIX = 'Alert';

const classes = {
  alertMessage: `${PREFIX}-alertMessage`,
  errorCode: `${PREFIX}-errorCode`,
};

const Container = styled('div')`
  position: absolute;
  margin-top: 0.625rem;
  top: 0px;
  left: calc(50% - 21.875rem);
  width: 43.75rem;
  z-index: 9999;
  border-radius: 0px;
`;

const StyledContainer = styled(Container)(({theme}) => ({
  [`& .${classes.alertMessage}`]: {
    fontWeight: 'normal',
  },

  [`& .${classes.errorCode}`]: {
    fontWeight: 500,
  },
}));

const Card = styled('div')`
  margin-bottom: 0.625rem;
`;

type Props = {
  id?: string;
};

const Alert: FC<Props> = ({id = defaultAlertId}) => {
  const location = useLocation();
  const [alerts, setAlerts] = useState<AlertArgs[]>([]);

  useEffect(() => {
    const subscription = alertService.onAlert(id).subscribe((alert) => {
      if (alert.message || alert.response) {
        setAlerts((alerts) => [...alerts, alert]);
        if (alert.autoClose) {
          setTimeout(() => removeAlert(alert), 3000);
        }
      } else {
        setAlerts((alerts) => alerts.filter((x) => x.keepAfterRouteChange));
      }
    });
    if (id) {
      alertService.clear(id);
    }

    return () => {
      subscription.unsubscribe();
    };
  }, [id, location]);

  function removeAlert(alert: AlertArgs) {
    setAlerts((alerts) => alerts.filter((x) => x !== alert));
  }
  function getErrorMessage(alert: AlertArgs) {
    if (alert.message && !alert.response) {
      return alert.message;
    }
    //ProblemDetails error info.
    if (alert.response && alert.response.data && alert.response.data.detail) {
      const data = alert.response.data;
      if (data && data.status !== 400 && data.detail) {
        return data.detail;
      }
    }

    return (
      <span>
        There was a system error (
        <span className={classes.errorCode}>
          {alert.response.status} - {alert.response.statusText}
        </span>
        ). Please try again in a few minutes. If the problem continues, contact {Consts.ItSupport}{' '}
        at <ITSupportLink sx={{color: '#FFF'}} />.
      </span>
    );
  }
  return (
    <StyledContainer data-testid="alert-component">
      {alerts.map((alert, index) => {
        return (
          <Card key={index}>
            <ErrorBox
              classes={{message: classes.alertMessage}}
              variant="filled"
              type={alert.type}
              onClose={() => {
                removeAlert(alert);
              }}
            >
              {getErrorMessage(alert)}
            </ErrorBox>
          </Card>
        );
      })}
    </StyledContainer>
  );
};

export default Alert;
