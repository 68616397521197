import React from 'react';
import Spinner from '../Spinner/Spinner';

const TableTabPanel = ({
  loading = false,
  className = '',
  value = '',
  index = '',
  children,
  ...props
}) => {
  return (
    <div
      {...(className && {className})}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpannel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...props}
    >
      <Spinner loading={loading} />
      {children}
    </div>
  );
};

export {TableTabPanel};
