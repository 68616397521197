import React, {FC} from 'react';
import {styled} from '@mui/material/styles';

const ActionsButtonsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  *:not(:last-child) {
    margin-right: 1rem;
  }
`;

type Props = {
  children: React.ReactNode;
};

const ButtonsContainer: FC<Props> = ({children}) => (
  <ActionsButtonsContainer>{children}</ActionsButtonsContainer>
);

export default ButtonsContainer;
