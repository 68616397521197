import React, {useState, useRef, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Popper from '@mui/material/Popper';
import * as R from 'ramda';
import {
  connectAutoComplete,
  connectStateResults,
  InstantSearch,
  Configure,
} from 'react-instantsearch-dom';
import Consts from '../../../app/Consts';
import {ITSupportLink} from '../../Link';
import searchClient from '../../../utils/algoliaSearchClient';
import {useContainerDimensions} from '../../Hook/useContainerDimensions';
import {LinkButton} from '../../Button';

const PREFIX = 'AutoCompleteSearch';

const classes = {
  popper: `${PREFIX}-popper`,
};

const StyledInstantSearch = styled(InstantSearch)(({theme}) => ({
  [`& .${classes.popper}`]: {
    zIndex: theme.zIndex.popper,
  },
}));

const listItemHeight = 56;

const StyledListContainer = styled(List)`
  margin: unset;
  padding: unset;
  width: 100%;
  z-index: 1;
  background-color: #f2f4f5;
  overflow: auto;
  max-height: ${listItemHeight * 6 + 10}px;
`;

const StyledListItem = styled(ListItem)`
  border-top: 1px solid #cbd2d8;
  line-height: ${listItemHeight - 36}px;
  box-sizing: border-box;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const ListContainer = styled('div')`
  position: relative;
`;

const StyledMessageContainer = styled('span')`
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: normal;
  font-style: italic;
`;

const Autocomplete = ({
  hits,
  currentRefinement,
  refine,
  error,
  noResultMessage,
  fieldProps,
  getSelectedValue,
  renderDisplayText,
  renderListItem,
  onSelected,
  errorMessage,
  defaultValue,
  autoResultListWidth = true,
  confirm: ConfirmModal,
  onConfirm,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [search, setSearch] = useState((defaultValue && renderDisplayText(defaultValue)) || '');
  const searchBoxRef = useRef(null);
  const containerRef = useRef(null);
  const {width} = useContainerDimensions(containerRef);
  const [cursor, setCursor] = useState(0);
  const [listRefs, setListRefs] = useState(0);
  const [valueToChange, setValueToChange] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
    let searchText = (defaultValue && renderDisplayText(defaultValue)) || '';
    setSearch(searchText);
  }, [defaultValue, renderDisplayText]);

  useEffect(() => {
    if (hits.length > 0) {
      setListRefs(hits.map((x) => React.createRef()));
    }
  }, [hits]);

  useEffect(() => {
    if (hits.length === 0 || cursor >= hits.length) {
      setCursor(0);
    }
  }, [hits, cursor]);

  function updateSelectedValue(hit) {
    const selectedValue = getSelectedValue(hit);
    let searchText = renderDisplayText(selectedValue);
    setSearch(searchText);
    setOpen(false);
    setValue(selectedValue);
    if (onSelected) {
      onSelected(selectedValue);
    }
  }
  function onSelect(hit) {
    setOpen(false);
    const selectedValue = getSelectedValue(hit);
    if (R.equals(value, selectedValue)) {
      return;
    }
    if (ConfirmModal) {
      setValueToChange(hit);
      setConfirmModalOpen(true);
    } else {
      setValueToChange(null);
      updateSelectedValue(hit);
    }
  }
  function handleKeyDown(e) {
    if (hits.length <= 0) {
      setCursor(0);
    }
    if (cursor > hits.length) {
      setCursor(0);
    }
    if (e.key === 'ArrowUp' && cursor > 0) {
      setCursor((prevCursor) => {
        let cursor = prevCursor - 1;
        listRefs[cursor].current.scrollIntoView({block: 'nearest'});
        return cursor;
      });
    } else if (e.key === 'ArrowDown' && cursor < hits.length - 1) {
      setCursor((prevCursor) => {
        let cursor = prevCursor + 1;
        listRefs[cursor].current.scrollIntoView({block: 'nearest'});
        return cursor;
      });
    } else if (e.key === 'Enter' && open && hits.length > 0) {
      onSelect(hits[cursor]);
    }
  }

  function clearSearch() {
    setSearch('');
    refine('');
  }
  let inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        {search && !fieldProps.disabled ? (
          <LinkButton type="button" onClick={clearSearch}>
            Clear
          </LinkButton>
        ) : null}
        {!fieldProps.disabled ? <SearchIcon /> : null}
      </InputAdornment>
    ),
  };
  if (fieldProps.InputProps) {
    inputProps = {...fieldProps.InputProps, ...inputProps};
  }

  return (
    <>
      <ListContainer ref={containerRef}>
        <TextField
          onKeyDown={handleKeyDown}
          type="text"
          variant="outlined"
          inputRef={searchBoxRef}
          autoComplete="off"
          {...fieldProps}
          value={search}
          error={!!errorMessage}
          helperText={!open && errorMessage}
          onBlur={(event) => {
            if (search && value) {
              setSearch(renderDisplayText(value));
            } else {
              setValue(null);
              if (onSelected) {
                onSelected(null);
              }
              clearSearch();
            }
            setOpen(false);
          }}
          onClick={() => {
            searchBoxRef.current.focus();
            setOpen(!open);
          }}
          onChange={(event) => {
            setOpen(true);
            setSearch(event.currentTarget.value);
            refine(event.currentTarget.value);
          }}
          InputProps={inputProps}
        />

        {open ? (
          <Popper
            open={open}
            anchorEl={searchBoxRef.current}
            className={classes.popper}
            style={autoResultListWidth ? {minWidth: width} : {width: width}}
            placement="bottom-start"
          >
            <StyledListContainer>
              {hits && hits.length > 0 ? (
                hits.map((hit, index) => {
                  return (
                    <ListItemButton
                      ref={listRefs[index]}
                      selected={cursor === index}
                      key={hit.objectID}
                      onClick={() => onSelect(hit)}
                      onMouseDown={(event) => event.preventDefault()}
                      value={hit}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
                        },
                        borderTop: '1px solid #cbd2d8',
                        lineHeight: `${listItemHeight - 36}px`,
                        boxSizing: 'border-box',
                        paddingTop: '18px',
                        paddingBottom: '18px',
                      }}
                    >
                      {renderListItem(hit)}
                    </ListItemButton>
                  );
                })
              ) : (
                <StyledListItem onMouseDown={(event) => event.preventDefault()}>
                  {error ? (
                    <StyledMessageContainer>
                      <span>
                        We can't seem to connect to our system at the moment. Please try refreshing
                        the page or contact {Consts.ItSupport} at{' '}
                      </span>
                      <ITSupportLink />
                    </StyledMessageContainer>
                  ) : (
                    <StyledMessageContainer>{noResultMessage}</StyledMessageContainer>
                  )}
                </StyledListItem>
              )}
            </StyledListContainer>
          </Popper>
        ) : null}
      </ListContainer>
      {ConfirmModal ? (
        <ConfirmModal
          open={confirmModalOpen}
          onCancel={() => {
            setConfirmModalOpen(false);
          }}
          onOk={() => {
            setConfirmModalOpen(false);
            updateSelectedValue(valueToChange);
            onConfirm?.();
          }}
        />
      ) : null}
    </>
  );
};

const CustomAutoComplete = connectAutoComplete(connectStateResults(Autocomplete));

const AutoCompleteSearch = ({
  indexName,
  defaultRefinement,
  defaultValue,
  noResultMessage,
  filters = '',
  getSelectedValue,
  renderDisplayText = (item) => item.objectID,
  renderListItem = (hit) => {
    return <>{hit.objectID}</>;
  },
  hasError = false,
  errorMessage = '',
  onSelected = (item) => {},
  confirm,
  onConfirm,
  ...otherProps
}) => {
  const handleSelectedValue =
    getSelectedValue ??
    ((hit) => {
      return {objectID: hit.objectID};
    });
  return (
    <StyledInstantSearch indexName={indexName} searchClient={searchClient}>
      <Configure hitsPerPage={20} filters={filters} />
      <CustomAutoComplete
        fieldProps={otherProps}
        defaultRefinement={defaultRefinement}
        noResultMessage={noResultMessage}
        getSelectedValue={handleSelectedValue}
        renderDisplayText={renderDisplayText}
        renderListItem={renderListItem}
        errorMessage={errorMessage}
        onSelected={onSelected}
        defaultValue={defaultValue}
        confirm={confirm ?? null}
        onConfirm={onConfirm}
      />
    </StyledInstantSearch>
  );
};
const MemoizedAutoCompleteSearch = React.memo(AutoCompleteSearch, (prevProps, nextProps) => {
  const {getSelectedValue, renderDisplayText, renderListItem, onSelected, ...restPrevProps} =
    prevProps;
  const {
    getSelectedValue: getSelectedValueNext,
    renderDisplayText: renderDisplayTextNext,
    renderListItem: renderListItemNext,
    onSelected: onSelectedNext,
    ...restNextProps
  } = nextProps;
  return R.equals(restPrevProps, restNextProps);
});
export default MemoizedAutoCompleteSearch;
