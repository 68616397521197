import {getDomainConfig} from '../utils/domainUtil';
const {entityCode} = getDomainConfig();
const config = {
  entityCode,
  // date range warning threshold
  dealStartDateWarningDaysBeforeToday: 45,
  dealStartDateWarningDaysAfterToday: 45,
  dealDateRangeWarningDaysBetween: 92,
  rebateDateRangeWarningDaysBetween: 365,
  //amount warning threshold
  dealPercentageAmountWarningThreshold: 50,
  dealDollarAmountWarningThreshold: 500,
  dealFixedAmountWarningThreshold: 200000,
  dealFixedAmountMax: 3000000,
  rebatePercentageAmountWarningThreshold: 20,
};

export default config;
