import React, {FC, useState} from 'react';
import {useField} from 'formik';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import {QueryCriteria} from '../../types';
import {StaticButton} from '../Button';
import LocationSelector from './Agolia/LocationSelector';
import StaticField from './StaticField';

const LocationGrid = styled(Grid)`
  padding: 1.875rem 0;
`;

type props = {
  id: string;
  name: string;
  disabled?: boolean;
};

const LocationField: FC<props> = (props) => {
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);
  const {setValue} = helpers;
  const applyCriteria = (criteria: QueryCriteria | null) => {
    setValue(criteria);
  };
  return (
    <>
      <Divider />
      <LocationGrid container justifyContent="space-between" alignItems="center">
        <Box flexGrow={1}>
          <StaticField
            fullWidth
            name={field.name}
            value={
              field.value
                ? `${field.value.resultCount} ${
                    field.value.resultCount === 1 ? 'location' : 'locations'
                  } selected (based on your criteria)`
                : 'All locations'
            }
          />
        </Box>
        <StaticButton
          onClick={() => setLocationSelectorOpen(!locationSelectorOpen)}
          disabled={props.disabled}
        >
          Edit Location
        </StaticButton>
      </LocationGrid>
      <Divider />
      {locationSelectorOpen ? (
        <LocationSelector
          fullScreen
          locationCriteria={field.value}
          open={locationSelectorOpen}
          handleClose={() => setLocationSelectorOpen(false)}
          onApplyCriteria={(criteria) => {
            setLocationSelectorOpen(false);
            applyCriteria(criteria);
          }}
        />
      ) : null}
    </>
  );
};

export default LocationField;
