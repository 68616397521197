import React from 'react';
import {ConfirmModal} from '.';

const styles = {
  okButton: {
    width: '340px',
  },
  cancelButton: {
    width: '340px',
  },
};

const ClearRebateSkuSelectionConfirmModal = ({open, onOk, onCancel, ...otherProps}) => {
  return (
    <ConfirmModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Clear data and continue"
      cancelText="Keep my data and go back"
      styles={styles}
      {...otherProps}
    >
      <div>
        This action will permanently clear and replace all the rebate SKUs that you previously
        selected by product selection tool. Would you like to continue?
      </div>
    </ConfirmModal>
  );
};

export default ClearRebateSkuSelectionConfirmModal;
