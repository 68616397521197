import React, {FC, useState, useEffect, useContext, useCallback} from 'react';
import * as R from 'ramda';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import {styled} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Consts from '../../app/Consts';
import config from '../../app/Config';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {
  getDisplayAmountValue,
  getDisplayCommasValue,
  numberWithCommas,
} from '../../utils/AmountUtils';
import {post, api, put, del} from '../../utils/Request';
import {
  DealValueResponse,
  DealValue,
  DealFormValues,
  Pagination,
  SelectOption,
  TError,
  TableErrors,
  RecursiveUndefined,
  DealValueRequest,
} from '../../types';
import ClearDealValueDataConfirmModal from '../Modal/ClearDealValueDataConfirmModal';
import SkuSearch from '../Form/Agolia/SkuSearch';
import ProductSelector from '../Form/Agolia/ProductSelector';
import TablePaginationWithAddButton from '../Table/Pagination/TablePaginationWithAddButton';
import {
  TableCellInputField,
  TableCellUnitField,
  TableCellSelect,
  SaveActionButton,
  CancelActionButton,
  DeleteActionButton,
  EditActionButton,
} from '../Table';
import {BulkUploadIconButton, StaticButton} from '../Button';
import {ExternalLinkIcon, WarnIcon} from '../Icons';
import {FinanceAccountClearModal} from '../Modal';
import GeneratePriceUpdateCheckbox from './GeneratePriceUpdateCheckbox';
import {dealFormDisabled} from './dealFormUtils';

const PREFIX = 'DealValuesTable';

const classes = {
  tableCell: `${PREFIX}-tableCell`,
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
  },
});

const ValidateRule = {
  Required: 'Required',
  NumberOnly: 'NumberOnly',
  PositiveNumber: 'PositiveNumber',
};

const CellContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;
const ActionCellContainer = styled('div')`
  display: flex;
`;
const DataMode = {
  Edit: 'Edit',
  Add: 'Add',
  Display: 'Display',
};

const ApiAction = {
  Add: 'Add',
  Edit: 'Edit',
  Delete: 'Delete',
};
export const isDuplicateSKUError = (errorMsg: string) =>
  /There is already an existing deal value with the product code/.test(errorMsg);

export function formatErrorMsg(error: any): string {
  const decimalError = 'Could not convert string to decimal';
  const arithmeticError = 'Arithmetic overflow error converting numeric to data type numeric.';
  const numberInputErrorMessage = 'Please enter a number less than 100,000,000.';

  if (error?.response?.data?.detail) {
    const containsExpectedError =
      error.response.data.detail.includes(arithmeticError) ||
      error.response.data.detail.includes(decimalError);

    return containsExpectedError ? numberInputErrorMessage : error.response.data.detail;
  }
  return error.message;
}
const getDealCapType = (capType: LocalDealValue['capType']) => {
  return Consts.DealCapType.find((item) => item.value === capType) ?? Consts.DealCapType[0];
};
export type LocalDealValue = RecursiveUndefined<DealValue> & {dataMode?: string};
type ValidLocalDealValue = DealValue & {dataMode?: string};
export const getDealValueRequestData = (rowData: ValidLocalDealValue): DealValueRequest => {
  const {
    capType,
    dataMode,
    financeAccountCleared,
    id,
    isSupplierMismatch,
    productDescription,
    productSuppliers,
    productTotal,
    productTotalType,
    ticketPrice,
    ...restData
  } = rowData;
  return {
    ...restData,
    capType: getDealCapType(capType).value,
  };
};
export const createNewRowData = (
  dealType: string | null,
  dealId: number | undefined,
  isClaimVendorGstFree?: boolean
): LocalDealValue => {
  let amountType =
    dealType === Consts.StandardDealTypeEnum.FixedAmount
      ? Consts.AmountTypeEnum.FixedAmount
      : Consts.AmountTypeEnum.ValuePerUnit;
  let capType;
  if (
    R.includes(dealType, [
      Consts.StandardDealTypeEnum.Sales,
      Consts.StandardDealTypeEnum.PriceProtection,
      Consts.StandardDealTypeEnum.Purchases,
    ])
  ) {
    capType = Consts.DealCapTypeEnum.UnitCount;
  }
  return {
    dataMode: DataMode.Add,
    agreementId: !!dealId ? dealId : undefined,
    id: undefined,
    amount: undefined,
    amountType: amountType,
    capType: capType,
    capValue: undefined,
    financeAccountCleared: false,
    isSupplierMismatch: false,
    gstType: isClaimVendorGstFree ? Consts.GstTypeEnum.Free : Consts.GstTypeEnum.Exclusive,
    productCode: undefined,
    productCriteria: undefined,
    productDescription: undefined,
    productEntityCode: config.entityCode,
    productSuppliers: [],
    productTotal: undefined,
    productTotalType: undefined,
    promoPrice: undefined,
    ticketPrice: undefined,
    type: dealType ?? undefined,
  };
};
type ProductSupplier = {
  supplierName: string;
  supplierNumber: number;
  supplierNameAndNumber: string;
};
type DealValueColumn = {
  field: string | null;
  title?: string | JSX.Element;
  render?: (rowData: LocalDealValue, index: number) => React.ReactNode;
  renderEdit?: (rowData: LocalDealValue, index: number) => JSX.Element | null;
  editStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  hide?: boolean;
  isAction?: boolean;
};
type Props = {
  skuSelectorMode: boolean;
  pagination: Pagination;
  isClaimVendorGstFree?: boolean;
  onPagination?: (currentPage: number, pageSize: number) => void;
  onTableDataUpdating?: (isUpdating: boolean) => void;
  onBulkUpload?: () => void;
};

const DealValuesTable: FC<Props> = ({
  skuSelectorMode,
  pagination,
  isClaimVendorGstFree = false,
  onPagination = (currentPage, pageSize) => {},
  onTableDataUpdating = (isUpdating) => {},
  onBulkUpload = () => {},
}) => {
  const [keepAdding, setKeepAdding] = useState(false);
  const [clearDataWarningModalOpen, setClearDataWarningModalOpen] = useState(false);
  const [financeAccountClearedModalOpen, setFinanceAccountClearedModalOpen] = useState(false);
  const [productSelectorData, setProductSelectorData] = useState<{
    rowData: LocalDealValue;
    rowIndex: number;
  } | null>(null);
  const [data, setData] = useState<LocalDealValue[]>([]);
  const [tableErrors, setTableErrors] = useState<TableErrors>({});
  const [duplicateSKUErrorMsg, setDuplicateSKUErrorMsg] = useState<string | null>(null);
  const [financeAccountClearReason, setFinanceAccountClearReason] = useState<string | null>(null);
  const [valuesSnapshot, setValuesSnapshot] = useState<(DealValue | LocalDealValue)[]>([]);

  const bag = useFormikContext<DealFormValues>();
  const {
    values: {
      claimVendorSuppliers,
      dealType,
      dealValues,
      departmentNumber,
      departments,
      id: dealId,
      isFinalClaimRaised,
      suppliers,
    },
    setFieldValue,
  } = bag;

  const disabled = dealFormDisabled(bag.values);

  useEffect(() => {
    setValuesSnapshot(dealValues);
  }, [dealValues]);

  useEffect(() => {
    setData((prevData) => {
      let newData = prevData.map((prevRowData) => {
        let newRowData = {...prevRowData};
        if (newRowData.dataMode === DataMode.Add) {
          newRowData.productCriteria = null;
          newRowData.productCode = null;
          newRowData.promoPrice = null;
          newRowData.productDescription = null;
        }

        return newRowData;
      });
      return newData;
    });
  }, [skuSelectorMode]);

  useEffect(() => {
    setTableErrors({});
  }, [dealType, dealValues, skuSelectorMode]);

  const getEmptyRowData = useCallback(
    (dealType: string | null, dealId: number | undefined, isClaimVendorGstFree?: boolean) =>
      createNewRowData(dealType, dealId, isClaimVendorGstFree),
    []
  );
  // check if there are unsaved changes
  useEffect(() => {
    const isRowEditing = data.some((item) => item.dataMode === DataMode.Edit);
    const addRowIndex = data.findIndex((item) => item.dataMode === DataMode.Add);
    const dataChanged = Object.values(tableErrors[addRowIndex] || []).some(
      (item) => !!item.touched
    );

    onTableDataUpdating(isRowEditing || dataChanged);
  }, [data, tableErrors, onTableDataUpdating, dealType, dealId]);

  //auto add new row if keepAdding
  useEffect(() => {
    if (dealValues.length === 0 && !isFinalClaimRaised) {
      setKeepAdding(true);
      let newRowData = getEmptyRowData(dealType, dealId, isClaimVendorGstFree);
      setData([newRowData]);
      return;
    }
    const valuesWithSupplierChecks = dealValues.map((x) => {
      const isSupplierMismatch =
        !R.isEmpty(suppliers) &&
        !x.productCriteria &&
        !x.productSuppliers.some((x) => suppliers.some((s) => s.number === x));
      return {...x, isSupplierMismatch: isSupplierMismatch};
    });
    if (keepAdding && !isFinalClaimRaised) {
      setData([
        ...valuesWithSupplierChecks,
        getEmptyRowData(dealType, dealId, isClaimVendorGstFree),
      ]);
    } else {
      setData(valuesWithSupplierChecks);
    }
  }, [
    dealValues,
    getEmptyRowData,
    dealType,
    dealId,
    keepAdding,
    suppliers,
    isFinalClaimRaised,
    isClaimVendorGstFree,
  ]);

  const {showLoading, hideLoading} = useContext(LoadingContext);

  const ValidateWithRules = (
    rules: (string | ((value: any, data: LocalDealValue) => any))[],
    value: any,
    rowData: LocalDealValue
  ) => {
    let errorMessage;
    let isEmptyOrNil = R.isEmpty(value) || R.isNil(value);

    rules.some((rule) => {
      if (rule === ValidateRule.Required && isEmptyOrNil) {
        errorMessage = Consts.ValidationMessage.Required;
        return true;
      } else if (rule === ValidateRule.NumberOnly && !isEmptyOrNil) {
        let hasError = isNaN(value);
        if (hasError) {
          errorMessage = Consts.ValidationMessage.NumberOnly;
          return true;
        }
      } else if (rule === ValidateRule.PositiveNumber && !isEmptyOrNil) {
        let hasError = isNaN(value) || value <= 0;
        if (hasError) {
          errorMessage = Consts.ValidationMessage.PositiveValue;
          return true;
        }
      } else if (typeof rule === 'function') {
        const message = rule(value, rowData);
        if (message) {
          errorMessage = message;
          return true;
        }
      }
      return false;
    });
    return errorMessage;
  };

  const getUpdatedErrors = (
    propName: string,
    errorMessage: string | undefined,
    rowIndex: number,
    errors: TableErrors
  ): TableErrors => {
    if (errorMessage) {
      return R.assocPath([rowIndex, propName, 'error'], errorMessage, errors);
    } else {
      return R.dissocPath([rowIndex, propName, 'error'], errors);
    }
  };

  const shouldShowError = (propName: string, rowIndex: number, errors: TableErrors) => {
    return R.pathSatisfies(
      (prop: TError) => !!(prop && prop.error && prop.touched),
      [rowIndex, propName],
      errors
    );
  };

  const validateRow = (rowData: LocalDealValue, rowIndex: number, propTouched?: string) => {
    let isValid = true;
    setTableErrors((prevTableErrors) => {
      let newErrors = {...prevTableErrors};
      //check amount
      let amountErrorMessage = ValidateWithRules(
        [
          ValidateRule.Required,
          ValidateRule.NumberOnly,
          (value, rowData) => {
            if (rowData.amountType === Consts.AmountTypeEnum.Percentage && value > 100) {
              return Consts.ValidationMessage.PercentageValueWith100;
            }
            if (
              rowData.amountType === Consts.AmountTypeEnum.FixedAmount &&
              value > config.dealFixedAmountMax
            ) {
              return `The value can't be greater than ${getDisplayAmountValue(
                config.dealFixedAmountMax,
                '$'
              )}`;
            }
          },
        ],
        rowData.amount,
        rowData
      );
      newErrors = getUpdatedErrors('amount', amountErrorMessage, rowIndex, newErrors);
      //check capValue
      let capValueRules =
        dealType === Consts.StandardDealTypeEnum.PriceProtection
          ? [ValidateRule.Required, ValidateRule.NumberOnly]
          : [
              ValidateRule.NumberOnly,
              (value: number, rowData: LocalDealValue) => {
                if (rowData.capType === Consts.DealCapTypeEnum.UnitCount) {
                  if (value < 0) {
                    return Consts.ValidationMessage.PositiveValue;
                  }
                } else if (rowData.amount && value * rowData.amount < 0) {
                  return 'The sign of cap value should match the sign of the deal value';
                }
              },
            ];
      let capValueErrorMessage = ValidateWithRules(capValueRules, rowData.capValue, rowData);
      newErrors = getUpdatedErrors('capValue', capValueErrorMessage, rowIndex, newErrors);

      if (skuSelectorMode) {
        //check product code
        let productCodeErrorMessage = ValidateWithRules(
          [ValidateRule.Required],
          rowData.productCode,
          rowData
        );
        newErrors = getUpdatedErrors('productCode', productCodeErrorMessage, rowIndex, newErrors);
        //check promo price
        let promoPriceErrorMessage = ValidateWithRules(
          [
            ValidateRule.NumberOnly,
            ValidateRule.PositiveNumber,
            (value, rowData) => {
              if (
                rowData.ticketPrice !== null &&
                rowData.ticketPrice !== undefined &&
                rowData.ticketPrice < value
              ) {
                return `Promo price is greater than the ticket price by $${
                  value - rowData.ticketPrice
                }`;
              }
            },
          ],
          rowData.promoPrice,
          rowData
        );
        newErrors = getUpdatedErrors('promoPrice', promoPriceErrorMessage, rowIndex, newErrors);
        newErrors = getUpdatedErrors('productCriteria', undefined, rowIndex, newErrors);
      } else {
        //check product criteria
        let productCriteriaErrorMessage = ValidateWithRules(
          [ValidateRule.Required],
          rowData.productCriteria,
          rowData
        );
        newErrors = getUpdatedErrors(
          'productCriteria',
          productCriteriaErrorMessage,
          rowIndex,
          newErrors
        );
        newErrors = getUpdatedErrors('productCode', undefined, rowIndex, newErrors);
        newErrors = getUpdatedErrors('promoPrice', undefined, rowIndex, newErrors);
      }

      if (propTouched) {
        newErrors = R.assocPath([rowIndex, propTouched, 'touched'], true, newErrors);
      } else {
        //update all props to touched if no propTouched provided.
        if (newErrors && R.has(`${rowIndex}`, newErrors)) {
          Object.keys(newErrors[rowIndex]).forEach(
            (key) => (newErrors[rowIndex][key]['touched'] = true)
          );
        }
      }
      if (newErrors && R.has(`${rowIndex}`, newErrors)) {
        const hasError = Object.values(newErrors[rowIndex]).some((x) => x.error);
        if (hasError) {
          isValid = false;
        }
      }

      return newErrors;
    });

    return isValid;
  };
  const onSave = (rowData: LocalDealValue, index: number) => {
    const isValid = validateRow(rowData, index);
    if (!isValid) {
      return;
    }
    setDuplicateSKUErrorMsg(null);
    showLoading();
    const {id, agreementId, ...restData} = rowData;
    const requestData = {
      ...restData,
      capType: getDealCapType(rowData.capType).value,
    };
    put(api(Consts.Api.DealValue.replace(':id', `${id}`)), requestData)
      .then((response: AxiosResponse<DealValueResponse>) => {
        alertService.clear(defaultAlertId);
        if (response.data && response.data.financeAccountCleared) {
          setFinanceAccountClearedModalOpen(true);
          setFieldValue('financeAccountCleared', true);
          setFinanceAccountClearReason(ApiAction.Edit);
        }
        setData((prevData) => {
          let newRowData = {...response.data} as LocalDealValue;
          newRowData.dataMode = DataMode.Display;
          let newData = [...prevData];
          newData.splice(index, 1, newRowData);
          return newData;
        });
        setValuesSnapshot((prevData) => {
          let newRowData = {...response.data} as LocalDealValue;
          newRowData.dataMode = DataMode.Display;
          let newData = [...prevData];
          newData.splice(index, 1, newRowData);
          return newData;
        });
        setTableErrors(R.dissocPath([index], tableErrors));
      })
      .catch((error) => {
        const errMsg = formatErrorMsg(error);
        if (isDuplicateSKUError(errMsg)) {
          setDuplicateSKUErrorMsg(errMsg);
        } else {
          alertService.alert({
            message: errMsg,
          });
        }
      })
      .finally(() => {
        hideLoading();
      });
  };

  const onAdd = (rowData: LocalDealValue, index: number) => {
    setDuplicateSKUErrorMsg(null);
    const isValid = validateRow(rowData, index);
    if (!isValid) {
      return;
    }
    showLoading();
    const validData = rowData as ValidLocalDealValue;
    const requestData = getDealValueRequestData(validData);
    post(api(Consts.Api.DealValues), requestData)
      .then((response: AxiosResponse<DealValueResponse>) => {
        alertService.clear(defaultAlertId);
        if (response.data && response.data.financeAccountCleared) {
          setFinanceAccountClearedModalOpen(true);
          setFieldValue('financeAccountCleared', true);
          setFinanceAccountClearReason(ApiAction.Add);
        }
        onPagination(pagination.currentPage, pagination.pageSize);
        setTableErrors({});
      })
      .catch((error) => {
        const errMsg = formatErrorMsg(error);
        if (isDuplicateSKUError(errMsg)) {
          setDuplicateSKUErrorMsg(errMsg);
        } else {
          alertService.alert({
            message: errMsg,
          });
        }
      })
      .finally(() => {
        hideLoading();
      });
  };

  const onEdit = (rowData: LocalDealValue, index: number) => {
    let newRowData = {...rowData};
    newRowData.dataMode = DataMode.Edit;
    let newData = [...data];
    newData.splice(index, 1, newRowData);
    setData(newData);
  };
  const onDelete = (rowData: LocalDealValue, index: number) => {
    setDuplicateSKUErrorMsg(null);
    if (rowData.dataMode === DataMode.Add) {
      let newData = [...data];
      newData.splice(index, 1);
      setTableErrors(R.dissocPath([index], tableErrors));
      setData(newData);
      setKeepAdding(false);
    } else {
      showLoading();
      del(api(Consts.Api.DealValue.replace(':id', `${rowData.id}`)))
        .then((response: AxiosResponse<Pick<DealValueResponse, 'financeAccountCleared'>>) => {
          alertService.clear(defaultAlertId);
          if (
            response.data &&
            response.data.financeAccountCleared &&
            dealType !== Consts.StandardDealTypeEnum.PriceProtection
          ) {
            setFinanceAccountClearedModalOpen(true);
            setFieldValue('financeAccountCleared', true);
            setFinanceAccountClearReason(ApiAction.Delete);
          } else {
            postDeletion();
          }
        })
        .catch((error) => {
          alertService.alert({
            ...{message: error.message, response: error.response},
            id: defaultAlertId,
          });
        })
        .finally(() => {
          hideLoading();
        });
    }
  };

  const onCancelEdit = (_rowData: LocalDealValue, index: number) => {
    let newRowData = {...valuesSnapshot[index]} as LocalDealValue;
    newRowData.dataMode = DataMode.Display;
    let newData = [...data];
    newData.splice(index, 1, newRowData);
    setTableErrors(R.dissocPath([index], tableErrors));
    setData(newData);
  };

  const calculateProductTotal = (rowData: LocalDealValue): number | undefined => {
    let total;
    if (
      rowData.amount !== undefined &&
      !isNaN(rowData.amount ?? NaN) &&
      rowData.capValue !== undefined &&
      rowData.capValue !== null &&
      !isNaN(rowData.capValue ?? NaN)
    ) {
      total = rowData.amount * rowData.capValue;
    }
    return total;
  };

  const getColumns = (dealType: DealFormValues['dealType']) => {
    const columns: DealValueColumn[] = [
      {
        field: 'isSupplierMismatch',
        render: (rowData) =>
          rowData.isSupplierMismatch ? (
            <Tooltip title={Consts.ValidationMessage.ProductSupplierMismatch} placement="top-start">
              <WarnIcon />
            </Tooltip>
          ) : null,
        style: {
          verticalAlign: 'middle',
          maxWidth: '1.5rem',
          minWidth: '0rem',
          padding: 0,
        },
      },
      {
        field: 'productCode',
        title: renderSkuColumnTitle(),
        render: renderSkuColumn,
        renderEdit: skuSelectorMode ? renderEditSkuColumn : renderEditProductSelectorColumn,
        editStyle: {minWidth: '12.5rem'},
      },
      {
        field: 'amount',
        title:
          dealType === Consts.StandardDealTypeEnum.PriceProtection ? 'Price Variance*' : 'Value*',
        render: renderValueColumn,
        renderEdit: renderEditValueColumn,
        editStyle:
          dealType !== Consts.StandardDealTypeEnum.Sales || !skuSelectorMode
            ? {width: '15.5rem'}
            : {minWidth: '15.5rem'},
      },
      {
        field: 'gstType',
        title: 'GST*',
        render: (rowData) => rowData.gstType,
        renderEdit: renderEditGstColumn,
        editStyle: {minWidth: '7.25rem'},
      },
      {
        field: 'ticketPrice',
        title: 'Ticket/ELQ Price',
        render: (rowData) => renderPriceColumn(rowData.ticketPrice),
        renderEdit: renderEditPriceColumn,
        hide: dealType !== Consts.StandardDealTypeEnum.Sales || !skuSelectorMode,
        editStyle: {minWidth: '7.5rem', maxWidth: '9.5rem'},
      },
      {
        field: 'promoPrice',
        title: <span>Promo Price</span>,
        render: (rowData) => renderPriceColumn(rowData.promoPrice),
        renderEdit: renderEditPromoPriceColumn,
        editStyle: {minWidth: '8.75rem', maxWidth: '9.5rem'},
        hide: dealType !== Consts.StandardDealTypeEnum.Sales || !skuSelectorMode,
      },
      {
        field: 'capValue',
        title:
          dealType === Consts.StandardDealTypeEnum.PriceProtection ? (
            'No. of Agreed Units*'
          ) : (
            <span>Unit or $ Cap (ex. GST)</span>
          ),
        render: renderCapColumn,
        renderEdit: renderEditCapColumn,
        editStyle:
          dealType === Consts.StandardDealTypeEnum.Sales && skuSelectorMode
            ? {minWidth: '10.5rem'}
            : {minWidth: '10.5rem', width: '12.5rem'},
        hide: dealType === Consts.StandardDealTypeEnum.FixedAmount,
      },
      {
        field: 'productTotal',
        title: 'SKU Total',
        render: (rowData) => {
          let total = calculateProductTotal(rowData);
          return renderPriceColumn(total);
        },
        renderEdit: renderEditProductTotal,
        editStyle: {maxWidth: '8rem'},
        hide: dealType !== Consts.StandardDealTypeEnum.PriceProtection,
      },
      {
        field: null,
        title: '',
        render: renderActionColumn,
        style: {width: '6.25rem'},
        isAction: true,
      },
    ];
    return columns.filter((x) => !x.hide);
  };

  const renderEditProductTotal = (rowData: LocalDealValue) => {
    let total = calculateProductTotal(rowData);
    const defaultValue = total !== undefined ? `${total}` : '';
    return (
      <CellContainer>
        <TableCellUnitField defaultValue="$" sx={{maxWidth: '2.5rem'}} />
        <TableCellInputField unitInLeft readOnly value={defaultValue} />
      </CellContainer>
    );
  };
  const renderValueColumn = (rowData: LocalDealValue) => {
    let displayValue = '';
    const unit = R.find(R.propEq('value', rowData.amountType), Consts.AmountType);
    if (dealType === Consts.StandardDealTypeEnum.FixedAmount) {
      displayValue = getDisplayAmountValue(rowData.amount, '$');
    } else if (unit?.value === Consts.AmountTypeEnum.FixedAmount) {
      displayValue = getDisplayAmountValue(rowData.amount, '$');
    } else if (unit?.value === Consts.AmountTypeEnum.ValuePerUnit) {
      displayValue = getDisplayAmountValue(rowData.amount, '$', ' per unit');
    } else {
      displayValue = getDisplayAmountValue(rowData.amount, '', '%');
    }

    return displayValue;
  };
  const getUpdatedData = (rowData: LocalDealValue, propName: string, index: number, value: any) => {
    let newRowData = {...rowData, [propName]: value};
    validateRow(newRowData, index, propName);
    let newData = [...data];
    newData.splice(index, 1, newRowData);
    return newData;
  };
  const showAmountWarning = (rowData: LocalDealValue): boolean => {
    return (
      (rowData.amountType === Consts.AmountTypeEnum.Percentage &&
        !!rowData.amount &&
        rowData.amount > config.dealPercentageAmountWarningThreshold) ||
      (rowData.amountType === Consts.AmountTypeEnum.FixedAmount &&
        !!rowData.amount &&
        rowData.amount > config.dealFixedAmountWarningThreshold) ||
      (rowData.amountType === Consts.AmountTypeEnum.ValuePerUnit &&
        !!rowData.amount &&
        rowData.amount > config.dealDollarAmountWarningThreshold)
    );
  };
  const getAmountWarning = (rowData: LocalDealValue) => {
    if (
      rowData.amountType === Consts.AmountTypeEnum.Percentage &&
      rowData.amount &&
      rowData.amount > config.dealPercentageAmountWarningThreshold
    ) {
      return `The value is greater than ${config.dealPercentageAmountWarningThreshold}%`;
    }
    if (
      rowData.amountType === Consts.AmountTypeEnum.FixedAmount &&
      rowData.amount &&
      rowData.amount > config.dealFixedAmountWarningThreshold
    ) {
      return `The value is greater than ${getDisplayAmountValue(
        config.dealFixedAmountWarningThreshold,
        '$'
      )}`;
    }
    if (
      rowData.amountType === Consts.AmountTypeEnum.ValuePerUnit &&
      rowData.amount &&
      rowData.amount > config.dealDollarAmountWarningThreshold
    ) {
      return `The value is greater than ${getDisplayAmountValue(
        config.dealDollarAmountWarningThreshold,
        '$'
      )}`;
    }
  };
  const renderEditValueColumn = (rowData: LocalDealValue, rowIndex: number) => {
    let defaultType = R.find(R.propEq('value', rowData.amountType), Consts.AmountType) as Record<
      string,
      string
    >;
    let showError = shouldShowError('amount', rowIndex, tableErrors);
    let errorMessage = showError && R.path([rowIndex, 'amount', 'error'], tableErrors);
    let showWarning = showAmountWarning(rowData);
    let warningMessage = getAmountWarning(rowData);
    let defaultLabel = defaultType.label;
    let useStaticUnit = R.includes(rowData.type, [
      Consts.StandardDealTypeEnum.FixedAmount,
      Consts.StandardDealTypeEnum.PriceProtection,
    ]);
    const defaultValue =
      rowData.amount !== null && rowData.amount !== undefined ? `${rowData.amount}` : '';
    return (
      <CellContainer sx={{maxWidth: '250px'}}>
        {useStaticUnit ? (
          <TableCellUnitField
            key={defaultLabel}
            defaultValue={defaultLabel}
            sx={{maxWidth: dealType === Consts.StandardDealTypeEnum.FixedAmount ? '40px' : '100px'}}
          />
        ) : (
          <TableCellSelect
            sx={{minWidth: '100px'}}
            options={Consts.AmountType.filter((x) => x.value !== Consts.AmountTypeEnum.FixedAmount)}
            defaultValue={defaultType}
            onChanged={(option: SelectOption) => {
              const data = getUpdatedData(rowData, 'amountType', rowIndex, option.value);
              data[rowIndex].promoPrice = null;
              setData(data);
            }}
            disabled={disabled.fields.dealValueAmount}
          />
        )}

        <TableCellInputField
          unitInLeft
          fullWidth
          error={showError}
          warning={!showError && showWarning}
          helperText={errorMessage || warningMessage}
          defaultValue={defaultValue}
          placeholder={'Add value'}
          disabled={disabled.fields.dealValueAmount}
          onChanged={(value) => {
            let data = getUpdatedData(rowData, 'amount', rowIndex, value);
            setData(data);
          }}
        />
      </CellContainer>
    );
  };
  const renderEditGstColumn = (rowData: LocalDealValue, index: number) => {
    const defaultValue = R.find(R.propEq('value', rowData.gstType), Consts.GstType);
    const options = isClaimVendorGstFree
      ? Consts.GstType.filter((x) => x.value === Consts.GstTypeEnum.Free)
      : Consts.GstType;
    return (
      <TableCellSelect
        disabled={isClaimVendorGstFree || disabled.fields.dealValueGSTType}
        fullWidth
        sx={{minWidth: '115px'}}
        name="gstType"
        options={options}
        defaultValue={defaultValue}
        onChanged={(option: SelectOption) => {
          let data = getUpdatedData(rowData, 'gstType', index, option.value);
          setData(data);
        }}
      />
    );
  };

  const renderActionColumn = (rowData: LocalDealValue, index: number) => {
    let leftActionButtonStyle = {marginRight: '5px'};
    if (rowData.dataMode === DataMode.Edit) {
      return (
        <ActionCellContainer>
          <SaveActionButton style={leftActionButtonStyle} onClick={() => onSave(rowData, index)} />
          <CancelActionButton onClick={() => onCancelEdit(rowData, index)} />
        </ActionCellContainer>
      );
    }
    if (rowData.dataMode === DataMode.Add) {
      let deleteDisabled = rowData.dataMode === DataMode.Add && data.length === 1;
      return (
        <ActionCellContainer>
          <SaveActionButton
            style={leftActionButtonStyle}
            onClick={() => onAdd(rowData, index)}
            disabled={disabled.actions.addDealValue}
          />
          <DeleteActionButton disabled={deleteDisabled} onClick={() => onDelete(rowData, index)} />
        </ActionCellContainer>
      );
    }

    const editMode =
      rowData.dataMode !== DataMode.Edit && data.some((item) => item.dataMode === DataMode.Edit);

    return (
      <ActionCellContainer>
        <EditActionButton
          style={leftActionButtonStyle}
          disabled={editMode || disabled.actions.editDealValues}
          onClick={() => onEdit(rowData, index)}
        />
        <DeleteActionButton
          disabled={editMode || disabled.actions.deleteDealValue}
          onClick={() => onDelete(rowData, index)}
        />
      </ActionCellContainer>
    );
  };
  const renderCapColumn = (rowData: LocalDealValue) => {
    const unit = R.find(R.propEq('value', rowData.capType), Consts.DealCapType);
    const value = numberWithCommas(rowData.capValue);
    if (!value || !unit) {
      return null;
    }
    if (unit.value === Consts.DealCapTypeEnum.UnitCount) {
      const capValue = rowData.capValue ?? 0;
      return getDisplayCommasValue(capValue, '', capValue > 1 ? ' Units' : ' Unit');
    }
    return getDisplayAmountValue(rowData.capValue, '$');
  };

  const onConfirmClearData = () => {
    setClearDataWarningModalOpen(false);
  };
  const renderSkuColumnTitle = () => {
    return (
      <span>
        SKU*
        <>
          {skuSelectorMode && (
            <BulkUploadIconButton
              onClick={(event) => {
                onBulkUpload();
              }}
              sx={{
                lineHeight: 'normal',
                fontWeight: 'normal',
                paddingLeft: '16px',
                paddingRight: '0',
              }}
              disabled={disabled.actions.dealValueBulkUpload}
            >
              Bulk Upload SKUs
            </BulkUploadIconButton>
          )}
          <ClearDealValueDataConfirmModal
            open={clearDataWarningModalOpen}
            onOk={onConfirmClearData}
            onCancel={() => {
              setClearDataWarningModalOpen(false);
            }}
          />
        </>
      </span>
    );
  };
  const renderPriceColumn = (price: number | null | undefined) => {
    return getDisplayCommasValue(price, '$');
  };
  const renderEditPriceColumn = (rowData: LocalDealValue) => {
    const defaultValue =
      rowData.ticketPrice !== null && rowData.ticketPrice !== undefined
        ? `${rowData.ticketPrice}`
        : '';
    return (
      <CellContainer>
        <TableCellUnitField defaultValue="$" sx={{maxWidth: '2.5rem'}} />
        <TableCellInputField unitInLeft readOnly value={defaultValue} />
      </CellContainer>
    );
  };
  const getPromoPriceWarning = (data: LocalDealValue): null | string => {
    const promoPrice =
      data.promoPrice !== null && data.promoPrice !== undefined ? data.promoPrice : 0;
    const ticketPrice =
      data.ticketPrice !== null && data.ticketPrice !== undefined ? data.ticketPrice : 0;

    if (promoPrice > 0 && ticketPrice > 0) {
      const percentage = (promoPrice / ticketPrice) * 100;
      if (percentage < 80) {
        return 'The value is more than 20% below Ticket/ELQ Price';
      }
    }
    return null;
  };
  const renderEditPromoPriceColumn = (rowData: LocalDealValue, rowIndex: number) => {
    const showError = shouldShowError('promoPrice', rowIndex, tableErrors);
    const showWarning = getPromoPriceWarning(rowData);
    const defaultValue =
      rowData.promoPrice !== null && rowData.promoPrice !== undefined
        ? `${rowData.promoPrice}`
        : '';
    return (
      <CellContainer>
        <TableCellUnitField defaultValue="$" sx={{maxWidth: '2.5rem'}} />
        <TableCellInputField
          unitInLeft
          error={showError}
          warning={!showError && !!showWarning}
          helperText={
            (showError && R.path([rowIndex, 'promoPrice', 'error'], tableErrors)) || showWarning
          }
          defaultValue={defaultValue}
          placeholder={'Add price'}
          disabled={rowData.amountType === Consts.AmountTypeEnum.Percentage}
          onChanged={(value) => {
            let data = getUpdatedData(rowData, 'promoPrice', rowIndex, value);
            setData(data);
          }}
        />
      </CellContainer>
    );
  };

  const renderEditCapColumn = (rowData: LocalDealValue, rowIndex: number) => {
    const defaultType = getDealCapType(rowData.capType);
    let showCapValueError = shouldShowError('capValue', rowIndex, tableErrors);
    let showUnit = dealType !== Consts.StandardDealTypeEnum.PriceProtection;

    const digits = rowData.capType === Consts.DealCapTypeEnum.UnitCount ? 0 : 2;
    // update the cap value to integer if its unit count
    if (
      digits === 0 &&
      rowData.capValue !== null &&
      rowData.capValue !== undefined &&
      !Number.isInteger(rowData.capValue)
    ) {
      let data = getUpdatedData(rowData, 'capValue', rowIndex, Math.round(rowData.capValue));
      rowData = data[rowIndex];
      setData(data);
    }
    const defaultValue =
      rowData.capValue !== null && rowData.capValue !== undefined ? `${rowData.capValue}` : '';

    const regex =
      rowData.capType === Consts.DealCapTypeEnum.UnitCount
        ? /^[\d,]*$/
        : /^(-?[\d,]*(?:\.\d{0,2})?)?$/;

    return (
      <CellContainer>
        {!showUnit ? null : (
          <TableCellSelect
            sx={{minWidth: '75px'}}
            options={Consts.DealCapType}
            defaultValue={defaultType}
            onChanged={(option: SelectOption) => {
              let data = getUpdatedData(rowData, 'capType', rowIndex, option.value);
              setData(data);
            }}
          />
        )}

        <TableCellInputField
          fullWidth
          unitInLeft={showUnit}
          error={showCapValueError}
          digits={digits}
          helperText={
            showCapValueError ? R.path([rowIndex, 'capValue', 'error'], tableErrors) : null
          }
          value={defaultValue}
          placeholder={
            dealType === Consts.StandardDealTypeEnum.PriceProtection
              ? 'Add no. of units'
              : 'Add cap'
          }
          regexValidation={regex}
          onChanged={(value) => {
            let data = getUpdatedData(rowData, 'capValue', rowIndex, value);
            setData(data);
          }}
        />
      </CellContainer>
    );
  };
  const renderSkuColumn = (rowData: LocalDealValue) => {
    if (skuSelectorMode) {
      return rowData.productCode ? `${rowData.productCode} ${rowData.productDescription}` : null;
    } else {
      return rowData.productCriteria
        ? `${rowData.productCriteria.resultCount ?? 0} SKUs selected (based on your criteria)`
        : null;
    }
  };

  const renderEditSkuColumn = (rowData: LocalDealValue, rowIndex: number) => {
    let showError = shouldShowError('productCode', rowIndex, tableErrors);
    return (
      <SkuSearch
        fullWidth
        placeholder="Product search"
        defaultValue={
          rowData.productCode
            ? {
                code: rowData.productCode,
                name: rowData.productDescription,
                ticketPrice: rowData.ticketPrice,
                suppliers,
              }
            : null
        }
        errorMessage={showError && R.path([rowIndex, 'productCode', 'error'], tableErrors)}
        onChanged={(item: {
          code: string;
          name: string;
          ticketPrice: number;
          suppliers: ProductSupplier[];
        }) => {
          setData((prevData) => {
            const editedRow = prevData.find((x) => x.id === rowData.id);
            if (!editedRow || !item) {
              return prevData;
            }

            const isSupplierMismatch =
              suppliers?.length > 0 &&
              item &&
              !item.suppliers?.some((x) => suppliers.some((s) => s.number === x.supplierNumber));

            const nextRow = {
              ...editedRow,
              productCode: item.code,
              productDescription: item.name,
              ticketPrice: item.ticketPrice,
              isSupplierMismatch,
            };
            validateRow(nextRow, rowIndex, 'productCode');
            let newData = [...data];
            newData.splice(rowIndex, 1, nextRow);
            return [...newData];
          });
        }}
      />
    );
  };
  const renderProductSelector = () => {
    if (productSelectorData) {
      const {rowData, rowIndex} = productSelectorData;
      return (
        <ProductSelector
          fullScreen
          open
          productCriteria={rowData.productCriteria}
          defaultBuyerDepartments={departments.filter((x) => x.number === departmentNumber)}
          defaultSuppliers={claimVendorSuppliers}
          handleClose={() => setProductSelectorData(null)}
          onApplyCriteria={(criteria) => {
            setProductSelectorData(null);
            let data = getUpdatedData(rowData, 'productCriteria', rowIndex, criteria);
            data[rowIndex].isSupplierMismatch = false;
            setData(data);
          }}
        />
      );
    }
  };
  const renderEditProductSelectorColumn = (rowData: LocalDealValue, rowIndex: number) => {
    let showError = shouldShowError('productCriteria', rowIndex, tableErrors);
    return (
      <FormControl variant="outlined" error={showError}>
        <StaticButton
          startIcon={<ExternalLinkIcon />}
          sx={{
            height: '3.3125rem',
            fontSize: '0.9375rem',
          }}
          onClick={() => {
            setProductSelectorData({rowData, rowIndex});
          }}
        >
          {rowData.productCriteria
            ? `${rowData.productCriteria.resultCount ?? 0} SKUs selected (based on your criteria)`
            : 'Select Products'}
        </StaticButton>
        <FormHelperText>
          {showError && R.path([rowIndex, 'productCriteria', 'error'], tableErrors)}
        </FormHelperText>
      </FormControl>
    );
  };

  function onChangePage(currentPage: number) {
    onPagination(currentPage, pagination.pageSize);
  }
  function onChangePageSize(pageSize: number) {
    onPagination(1, pageSize);
  }
  function renderCell(
    column: DealValueColumn,
    row: LocalDealValue,
    rowIndex: number
  ): null | React.ReactNode | DealValueColumn['field'] {
    let rowElement = null;
    if ((row.dataMode === DataMode.Add || row.dataMode === DataMode.Edit) && column.renderEdit) {
      rowElement = column.renderEdit(row, rowIndex);
    } else if (column.render) {
      if (column.isAction) {
        rowElement = <ActionCellContainer>{column.render(row, rowIndex)}</ActionCellContainer>;
      } else {
        rowElement = <CellContainer>{column.render(row, rowIndex)}</CellContainer>;
      }
    } else {
      rowElement = (row[column.field as keyof LocalDealValue] as string | null) ?? null;
    }
    return rowElement;
  }
  function renderRow(row: LocalDealValue, rowIndex: number) {
    return (
      <TableRow key={`${dealType}-${rowIndex}`}>
        {getColumns(dealType).map((column, columnIndex) => {
          const displayMode = row.dataMode === DataMode.Display || !row.dataMode;
          let style = column.style;
          if (!displayMode && column.editStyle) {
            style = column.editStyle;
          }
          return (
            <TableCell
              classes={{root: displayMode ? '' : classes.tableCell}}
              key={columnIndex}
              style={style}
            >
              {renderCell(column, row, rowIndex)}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
  function AddRow() {
    let emptyRowData = getEmptyRowData(dealType, dealId, isClaimVendorGstFree);
    setData([...data, emptyRowData]);
    setKeepAdding(true);
  }
  function postDeletion() {
    if (
      pagination.currentPage === pagination.totalPages &&
      dealValues.length === 1 &&
      pagination.currentPage !== 1
    ) {
      onPagination(pagination.currentPage - 1, pagination.pageSize);
    } else {
      onPagination(pagination.currentPage, pagination.pageSize);
    }
  }

  if (!dealType) {
    return null;
  }

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {getColumns(dealType).map((column, index) => (
              <TableCell key={index} style={column.style}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody id="dealValues">
          {data.map((row, index) => renderRow(row, index))}
          {duplicateSKUErrorMsg ? (
            <TableRow>
              <TableCell colSpan={12}>
                <Alert
                  icon={<ErrorIcon height="1.5rem" width="1.5rem" />}
                  severity="error"
                  sx={{padding: '0 1rem', border: 'solid 1px red'}}
                >
                  {duplicateSKUErrorMsg}
                </Alert>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
      <TablePaginationWithAddButton
        addButtonText="Add another deal value"
        disabled={
          data.some((item) => item.dataMode === DataMode.Add) || disabled.actions.addDealValue
        }
        onAdd={AddRow}
        pagination={pagination}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />
      {renderProductSelector()}
      <GeneratePriceUpdateCheckbox
        dealType={dealType}
        dealValues={data}
        disabled={isFinalClaimRaised}
      />
      <FinanceAccountClearModal
        open={financeAccountClearedModalOpen}
        onClose={() => {
          if (financeAccountClearReason === ApiAction.Delete) {
            postDeletion();
          }
          setFinanceAccountClearedModalOpen(false);
        }}
      />
    </StyledTableContainer>
  );
};

export default DealValuesTable;
