import React, {FC, useState, useEffect, useRef, useMemo} from 'react';
import {InstantSearch, Configure} from 'react-instantsearch-dom';
import * as R from 'ramda';
import {styled} from '@mui/material/styles';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import config from '../../../app/Config';
import Consts from '../../../app/Consts';
import searchClient from '../../../utils/algoliaSearchClient';
import {QueryCriteria, Facet} from '../../../types';
import CustomSelect from '../../Select/CustomSelect';
import {Button} from '../../Button';
import SelectorCloseConfirmation from '../../Modal/SelectorCloseConfirmation';
import CustomCurrentRefinements from './CustomCurrentRefinements';
import CustomStats from './CustomStats';
import CriteriaSection from './CriteriaSection';
import ExcludeRefinementList from './ExcludeRefinementList';
import IncludeRefinementList from './IncludeRefinementList';
import NoResultErrorStats from './NoResultErrorStats';

const PREFIX = 'ProductSelector';

const classes = {
  dialogPaper: `${PREFIX}-dialogPaper`,
  hidden: `${PREFIX}-hidden`,
  dialogAction: `${PREFIX}-dialogAction`,
  dialogContent: `${PREFIX}-dialogContent`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.dialogPaper}`]: {
    left: '25%',
    width: '50%',
  },

  [`& .${classes.hidden}`]: {
    display: 'none',
  },

  [`& .${classes.dialogAction}`]: {
    padding: '1rem 1.5rem',
  },

  [`& .${classes.dialogContent}`]: {
    padding: '1rem 2.625rem',
  },
}));

type TooltipProps = {
  disabled?: boolean;
  disabledText?: string;
  children: React.ReactNode;
};

const OptionalTooltip: FC<TooltipProps> = ({disabled, children}) => (
  <>
    {disabled ? (
      <Tooltip
        title="add inclusion and/or exclusion criteria to continue"
        sx={{cursor: 'not-allowed'}}
        placement="top"
      >
        <span>{children}</span>
      </Tooltip>
    ) : (
      children
    )}
  </>
);

const brandFacet = Consts.AlgoliaFacet.ProductBrand.Index;
const brandFacetLabel = Consts.AlgoliaFacet.ProductBrand.Label;
const departmentFacet = Consts.AlgoliaFacet.ProductDepartment.Index;
const departmentFacetlabel = Consts.AlgoliaFacet.ProductDepartment.Label;
const productGroupFacet = Consts.AlgoliaFacet.ProductGroup.Index;
const productGroupFacetlabel = Consts.AlgoliaFacet.ProductGroup.Label;
const supplierFacet = Consts.AlgoliaFacet.ProductSupplier.Index;
const supplierFacetLabel = Consts.AlgoliaFacet.ProductSupplier.Label;
const seasonCodeFacet = Consts.AlgoliaFacet.ProductSeasonCode.Index;
const seasonCodeFacetLabel = Consts.AlgoliaFacet.ProductSeasonCode.Label;
const productSkuFacet = Consts.AlgoliaFacet.ProductSku.Index;
const productSkuFacetLabel = Consts.AlgoliaFacet.ProductSku.Label;

const CriteriaSectionHeaderContainer = styled('div')`
  margin-top: 2.75rem
  margin-bottom: 1.875rem;
`;
const CriteriaSectionHeaderTitle = styled('div')`
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 1.125rem;
`;

const CriteriaSelectorContainer = styled('div')`
  display: flex;
`;
const CriteriaSelect = styled(CustomSelect)`
  flex: 0 0 12.5rem;
  margin-right: 1rem;
`;
const CriteriaRefinementListContainer = styled('div')`
  flex: 1;
`;

const StyledDialogTitle = styled('div')`
  font-size: 2rem;
  font-weight: 500;
  display: flex;
  padding: 2.875rem;
  justify-content: center;
  align-items: center;
  > span {
    flex-grow: 1;
  }
`;
const ApplyCriteriaButton = styled(Button)`
  width: 22rem;
`;

const inclusionFacetLimit = 50;
const inclusionFacets = [
  {
    label: departmentFacetlabel,
    value: departmentFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductDepartment.PluralLabel,
  },
  {
    label: supplierFacetLabel,
    value: supplierFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSupplier.PluralLabel,
  },
  {
    label: brandFacetLabel,
    value: brandFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductBrand.PluralLabel,
  },
  {
    label: seasonCodeFacetLabel,
    value: seasonCodeFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSeasonCode.PluralLabel,
  },
  {
    label: productGroupFacetlabel,
    value: productGroupFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductGroup.PluralLabel,
  },
  {
    label: productSkuFacetLabel,
    value: productSkuFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSku.PluralLabel,
  },
];
const exclusionFacets = [
  {
    label: departmentFacetlabel,
    value: departmentFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductDepartment.PluralLabel,
  },
  {
    label: supplierFacetLabel,
    value: supplierFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSupplier.PluralLabel,
  },
  {
    label: brandFacetLabel,
    value: brandFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductBrand.PluralLabel,
  },
  {
    label: seasonCodeFacetLabel,
    value: seasonCodeFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSeasonCode.PluralLabel,
  },
  {
    label: productGroupFacetlabel,
    value: productGroupFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductGroup.PluralLabel,
  },
  {
    label: productSkuFacetLabel,
    value: productSkuFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSku.PluralLabel,
  },
];

const defaultCriteria = {
  indexName: Consts.AlgoliaIndex.Products,
  filters: `entityCode:${config.entityCode}`,
  facetInclusions: [
    {
      name: departmentFacet,
      facetType: Consts.AlgoliaFacet.ProductDepartment.Type,
      values: [],
    },
    {
      name: supplierFacet,
      facetType: Consts.AlgoliaFacet.ProductSupplier.Type,
      values: [],
    },
    {
      name: brandFacet,
      facetType: Consts.AlgoliaFacet.ProductBrand.Type,
      values: [],
    },
    {
      name: seasonCodeFacet,
      facetType: Consts.AlgoliaFacet.ProductSeasonCode.Type,
      values: [],
    },
    {
      name: productGroupFacet,
      facetType: Consts.AlgoliaFacet.ProductGroup.Type,
      values: [],
    },
    {
      name: productSkuFacet,
      facetType: Consts.AlgoliaFacet.ProductSku.Type,
      values: [],
    },
  ],
  facetExclusions: [
    {
      name: departmentFacet,
      facetType: Consts.AlgoliaFacet.ProductDepartment.Type,
      values: [],
    },
    {
      name: supplierFacet,
      facetType: Consts.AlgoliaFacet.ProductSupplier.Type,
      values: [],
    },
    {
      name: brandFacet,
      facetType: Consts.AlgoliaFacet.ProductBrand.Type,
      values: [],
    },
    {
      name: seasonCodeFacet,
      facetType: Consts.AlgoliaFacet.ProductSeasonCode.Type,
      values: [],
    },
    {
      name: productGroupFacet,
      facetType: Consts.AlgoliaFacet.ProductGroup.Type,
      values: [],
    },
    {
      name: productSkuFacet,
      facetType: Consts.AlgoliaFacet.ProductSku.Type,
      values: [],
    },
  ],
};

type RefineMentListItem = {
  label: string;
  value: string[];
  count: number;
  isRefined: boolean;
};

type AlgoliaFacet = {
  Index: string;
  Type: string;
  Label: string;
  PluralLabel: string;
  Placeholder: string;
  Composited?: boolean;
};

type Props = {
  open: boolean;
  productCriteria?: any;
  defaultBuyerDepartments?: any[];
  defaultSuppliers?: any[];
  handleClose: () => void;
  onApplyCriteria?: (productCriteria: QueryCriteria | null) => void;
  scrollType?: 'paper' | 'body';
  readOnly?: boolean;
} & DialogProps;

const ProductSelector: FC<Props> = ({
  open,
  productCriteria,
  defaultBuyerDepartments,
  defaultSuppliers,
  handleClose,
  onApplyCriteria,
  scrollType = 'paper',
  readOnly = false,
  ...dialogProps
}) => {
  const noResultRef = useRef<HTMLDivElement | null>(null);
  const [visibleInclusionFacet, setVisibleInclusionFacet] = useState(inclusionFacets[0]);
  const [visibleExclusionFacet, setVisibleExclusionFacet] = useState(exclusionFacets[0]);
  const [criteria, setCriteria] = useState<QueryCriteria>(productCriteria || defaultCriteria);
  const [closeConfirmModalOpen, setCloseConfirmModalOpen] = useState(false);
  const [resultCount, setResultCount] = useState(0);

  const noFacetsSelected = useMemo(
    () =>
      criteria?.facetInclusions.every((x) => x.values?.length === 0) &&
      criteria?.facetExclusions.every((x) => x.values?.length === 0),
    [criteria]
  );

  function setDraftCriteria(value: QueryCriteria): void {
    const facetInclusions = value.facetInclusions.map((x) => ({
      name: x.name,
      facetType: x.facetType,
      values: [...(x?.values ?? [])],
    }));

    const facetExclusions = value.facetExclusions.map((x) => ({
      name: x.name,
      facetType: x.facetType,
      values: [...(x?.values ?? [])],
    }));

    const newCriteria = {
      indexName: value.indexName,
      filters: value.filters,
      facetInclusions,
      facetExclusions,
      // algolia nbHits returns as all sku count where no facets are selected, we don't want all skus by default
      resultCount: noFacetsSelected ? 0 : value.resultCount,
    };
    setCriteria(newCriteria);
  }

  useEffect(() => {
    if (!productCriteria) {
      const criteria: QueryCriteria = {...defaultCriteria};
      const supplierFacetInclusion = criteria.facetInclusions.find((x) => x.name === supplierFacet);
      const departmentFacetInclusion = criteria.facetInclusions.find(
        (x: Facet) => x?.name === departmentFacet
      );
      const inclusionSuppliers = (defaultSuppliers ?? []).map((x) => `${x.number}--${x.name}`);
      const inclusionDepartments = (defaultBuyerDepartments ?? []).map(
        (x) => `${x.number}--${x.description}`
      );
      const newCriteria = {
        ...criteria,
        facetInclusions: [
          {...supplierFacetInclusion, values: inclusionSuppliers},
          {...departmentFacetInclusion, values: inclusionDepartments},
        ],
      };
      setCriteria(newCriteria);
    } else {
      setCriteria(productCriteria);
    }
  }, [productCriteria, defaultSuppliers, defaultBuyerDepartments]);

  const onRefine = (item: RefineMentListItem, attribute: AlgoliaFacet['Index']) => {
    const facet = R.find(R.propEq('Index', attribute), R.values(Consts.AlgoliaFacet));
    if (!facet) {
      return;
    }
    const exclusions = {
      name: attribute,
      facetType: facet.Type,
      values: item.value.filter((val: string) => val.startsWith('-')),
    };
    const inclusions = {
      name: attribute,
      facetType: facet.Type,
      values: item.value.filter((val: string) => !val.startsWith('-')),
    };
    setDraftCriteria({
      ...criteria,
      facetInclusions: [
        ...criteria.facetInclusions.filter((x) => x.name !== attribute),
        inclusions,
      ],
      facetExclusions: [
        ...criteria.facetExclusions.filter((x) => x.name !== attribute),
        exclusions,
      ],
    });
  };

  const applyCriteria = () => {
    if (resultCount === 0) {
      if (noResultRef.current) {
        noResultRef.current.scrollIntoView({behavior: 'smooth'});
      }
    } else if (onApplyCriteria) {
      onApplyCriteria({...criteria, resultCount} as QueryCriteria);
    }
  };

  const onDeleteFacetValue = (
    attribute: AlgoliaFacet['Index'],
    value: string,
    inclusive: boolean
  ) => {
    if (inclusive) {
      const facetIndex = criteria.facetInclusions.findIndex((x: Facet) => x.name === attribute);
      if (facetIndex > -1) {
        const facet = criteria.facetInclusions[facetIndex];
        const newFacetInclusions = [...criteria.facetInclusions];
        newFacetInclusions[facetIndex] = {
          ...facet,
          values: facet.values?.filter((x) => x !== value),
        };

        setDraftCriteria({
          ...criteria,
          facetInclusions: newFacetInclusions,
        });
      }
    } else {
      const facetIndex = criteria.facetExclusions.findIndex((x: Facet) => x.name === attribute);
      if (facetIndex > -1) {
        const facet = criteria.facetExclusions[facetIndex];
        const newFacetExclusions = [...criteria.facetExclusions];
        newFacetExclusions[facetIndex] = {
          ...facet,
          values: facet.values?.filter((x) => x !== value),
        };

        setDraftCriteria({
          ...criteria,
          facetExclusions: newFacetExclusions,
        });
      }
    }
  };

  const onResult = (count: number) => {
    setResultCount(count);
  };

  const getListItem = (facet: AlgoliaFacet, inclusive?: boolean) => {
    const defaultRefinement = handleDefaultRefinement(facet, inclusive);
    if (inclusive) {
      return (
        <div
          className={
            visibleInclusionFacet && visibleInclusionFacet.value === facet.Index
              ? ''
              : classes.hidden
          }
        >
          <IncludeRefinementList
            attribute={facet.Index}
            defaultRefinement={defaultRefinement}
            limit={inclusionFacetLimit}
            onRefine={onRefine}
            placeholder={facet.Placeholder}
            renderLabel={(label: string) => renderLabel(facet, label)}
            searchable
          />
        </div>
      );
    } else {
      return (
        <div
          className={
            visibleExclusionFacet && visibleExclusionFacet.value === facet.Index
              ? ''
              : classes.hidden
          }
        >
          <ExcludeRefinementList
            attribute={facet.Index}
            defaultRefinement={defaultRefinement}
            limit={inclusionFacetLimit * 2}
            onRefine={onRefine}
            placeholder={facet.Placeholder}
            renderLabel={(label: string) => renderLabel(facet, label)}
            searchable
          />
        </div>
      );
    }
  };

  const getFacetLabelByFacetName = (label: string, attribute: AlgoliaFacet['Index']) => {
    const facet = Object.values(Consts.AlgoliaFacet).find(
      (facet: AlgoliaFacet) => facet.Composited && facet.Index === attribute
    );

    if (facet) {
      return renderLabel(facet, label);
    }
    return label;
  };

  const renderLabel = (facet: AlgoliaFacet, label: string) => {
    if (facet.Index === productSkuFacet) {
      return label.replace('--', ' ');
    } else if (facet.Composited) {
      return label.split('--')[1];
    }
    return label;
  };

  const handleDefaultRefinement = (facet: AlgoliaFacet, inclusive?: boolean) => {
    if (inclusive) {
      return [...(criteria.facetInclusions.find((x) => x.name === facet.Index)?.values ?? [])];
    } else {
      return [...(criteria.facetExclusions.find((x) => x.name === facet.Index)?.values ?? [])];
    }
  };

  const getInclusionCriteria = () => (
    <CriteriaSection title="Add Inclusion Criteria" readOnly={readOnly}>
      <CriteriaSelectorContainer>
        <CriteriaSelect
          options={inclusionFacets}
          defaultValue={inclusionFacets[0]}
          onChanged={setVisibleInclusionFacet}
        />
        <CriteriaRefinementListContainer>
          {getListItem(Consts.AlgoliaFacet.ProductDepartment, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSupplier, true)}
          {getListItem(Consts.AlgoliaFacet.ProductBrand, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSeasonCode, true)}
          {getListItem(Consts.AlgoliaFacet.ProductGroup, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSku, true)}
        </CriteriaRefinementListContainer>
      </CriteriaSelectorContainer>
    </CriteriaSection>
  );

  const getExcludeCriteria = () => (
    <CriteriaSection title="Add Exclusion Criteria" readOnly={readOnly}>
      <CriteriaSelectorContainer>
        <CriteriaSelect
          options={exclusionFacets}
          defaultValue={exclusionFacets[0]}
          onChanged={setVisibleExclusionFacet}
        />
        <CriteriaRefinementListContainer>
          {getListItem(Consts.AlgoliaFacet.ProductDepartment)}
          {getListItem(Consts.AlgoliaFacet.ProductSupplier)}
          {getListItem(Consts.AlgoliaFacet.ProductBrand)}
          {getListItem(Consts.AlgoliaFacet.ProductSeasonCode)}
          {getListItem(Consts.AlgoliaFacet.ProductGroup)}
          {getListItem(Consts.AlgoliaFacet.ProductSku)}
        </CriteriaRefinementListContainer>
      </CriteriaSelectorContainer>
    </CriteriaSection>
  );

  const closeSelector = () => {
    if (readOnly) {
      handleClose();
      return;
    }
    const hasIncusions = R.any((facet) => !R.isEmpty(facet.values), criteria.facetInclusions || []);
    const hasExclusions = R.any(
      (facet) => !R.isEmpty(facet.values),
      criteria.facetExclusions || []
    );

    if (
      R.equals(productCriteria, criteria) ||
      (!productCriteria && !hasIncusions && !hasExclusions)
    ) {
      handleClose();
    } else {
      setCloseConfirmModalOpen(true);
    }
  };

  const onClearCriteria = () => {
    setDraftCriteria(defaultCriteria);
  };

  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={closeSelector}
      scroll={scrollType}
      classes={{paper: classes.dialogPaper}}
      {...dialogProps}
    >
      <InstantSearch searchClient={searchClient} indexName={Consts.AlgoliaIndex.Products}>
        <StyledDialogTitle>
          <span>Product Selector</span>
          <IconButton onClick={closeSelector} size="large">
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers={scrollType === 'paper'} classes={{root: classes.dialogContent}}>
          <Configure filters={criteria.filters} highlightPreTag="p" highlightPostTag="p" />
          <CriteriaSectionHeaderContainer>
            <CriteriaSectionHeaderTitle>
              Include all SKUs that meet the following criteria:
            </CriteriaSectionHeaderTitle>
          </CriteriaSectionHeaderContainer>

          {getInclusionCriteria()}
          <CustomCurrentRefinements
            renderLabel={getFacetLabelByFacetName}
            criteria={inclusionFacets}
            inclusive
            onDelete={onDeleteFacetValue}
            readOnly={readOnly}
          />

          <CriteriaSectionHeaderContainer>
            <CriteriaSectionHeaderTitle>
              Exclude all SKUs that meet the following criteria:
            </CriteriaSectionHeaderTitle>
          </CriteriaSectionHeaderContainer>

          {getExcludeCriteria()}
          <CustomCurrentRefinements
            renderLabel={getFacetLabelByFacetName}
            criteria={exclusionFacets}
            inclusive={false}
            onDelete={onDeleteFacetValue}
            readOnly={readOnly}
          />
          <div ref={noResultRef}>
            <NoResultErrorStats resultLabel="SKU" pluralResultLabel="SKUs" />
          </div>
        </DialogContent>
        <DialogActions classes={{root: classes.dialogAction}}>
          <CustomStats
            resultLabel="SKU"
            pluralResultLabel="SKUs"
            onResult={onResult}
            canClearCriteria={!readOnly}
            onClearCriteria={onClearCriteria}
            noSelection={noFacetsSelected}
          />
          {!readOnly ? (
            <OptionalTooltip disabled={noFacetsSelected}>
              <ApplyCriteriaButton onClick={applyCriteria} disabled={noFacetsSelected}>
                Apply Criteria
              </ApplyCriteriaButton>
            </OptionalTooltip>
          ) : null}
        </DialogActions>
      </InstantSearch>
      <SelectorCloseConfirmation
        open={closeConfirmModalOpen}
        onCancel={() => setCloseConfirmModalOpen(false)}
        onOk={handleClose}
      />
    </StyledDialog>
  );
};

export default ProductSelector;
