import React, {FC, useContext, useEffect, useState, useCallback} from 'react';
import {useFormikContext} from 'formik';
import {AxiosResponse} from 'axios';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import Consts from '../../app/Consts';
import {api, del, get} from '../../utils/Request';
import {isNullish} from '../../utils';
import {ValidSPIVFormValues, DealFormBag, AttachmentType} from '../../types';
import FieldGrid from '../Form/FieldGrid';
import FieldLabel from '../Form/FieldLabel';
import FormStep from '../Form/FormStep';
import OptionalIndicator from '../Form/OptionalIndicator';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import FilesUploader from '../DragDrop/FilesUploader';
import Attachment from '../Attachment/Attachment';
import SPIVDetailsHeader from './SPIVDetailsHeader';

type Props = {
  step: number;
  title: string;
  totalStep: number;
  onBack: (values: ValidSPIVFormValues, bag: DealFormBag) => void;
  onNext: (values: ValidSPIVFormValues, bag: DealFormBag) => void;
  style?: React.CSSProperties;
};

type UploadResponse = AxiosResponse<AttachmentType[]>;

const StepSPIVAttachments: FC<Props> = ({step, title, totalStep, onBack, onNext}) => {
  const bag = useFormikContext<ValidSPIVFormValues>();
  const {
    values: {id},
  } = bag;
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [attachments, setAttachments] = useState<AttachmentType[]>([]);

  const loadAttachments = useCallback(async () => {
    try {
      showLoading();
      const response: AxiosResponse<AttachmentType[]> = await get(
        api(Consts.Api.Attachments).replace(':id', id) + '?referenceType=Agreement'
      );
      setAttachments(response.data ?? []);
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        id: defaultAlertId,
        ...{message: 'Failed to load attachments', response: error.response},
      });
    } finally {
      hideLoading();
    }
  }, [id, hideLoading, showLoading]);

  useEffect(() => {
    loadAttachments();
  }, [loadAttachments]);

  const uploadComplete = (response: UploadResponse) => {
    setAttachments((prevAttachments) => [...prevAttachments, ...(response.data ?? [])]);
  };

  const deleteAttachment = async (attachmentId: AttachmentType['id']) => {
    try {
      showLoading();
      await del(
        api(Consts.Api.Attachment).replace(':id', attachmentId) + '?referenceType=Agreement'
      );
      setAttachments((prevAttachments) => prevAttachments.filter((x) => x.id !== attachmentId));
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <SPIVDetailsHeader />
      <FormStep step={step} title={title}>
        <FieldGrid item xs={12}>
          <FieldLabel fullWidth>
            Add Attachments <OptionalIndicator />
          </FieldLabel>
          {!isNullish(attachments) ? (
            <FilesUploader
              maxSizeInMb={20}
              multiple={true}
              apiEndpoint={
                api(Consts.Api.Attachment).replace(':id', id) + '?referenceType=Agreement'
              }
              accept={{
                'image/png': [],
                'image/jpeg': [],
                'image/jpg': [],
                'application/pdf': [],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [], // for docx
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [], // for xlsx
                'message/rfc822': ['.eml'], // for eml
                'text/csv': ['.csv'], // for csv
                'application/vnd.ms-outlook': ['.msg'], // for msg
              }}
              title="Add EML, MSG, PDF, CSV, DOCX, XLSX, JPEG or PNG files"
              onComplete={uploadComplete}
              noFiles={attachments.length === 0}
            >
              <div>
                {attachments?.map?.((attachment, index) => (
                  <Attachment key={index} fileName={attachment.fileName}>
                    <IconButton
                      data-testid="delete-attachment"
                      onClick={() => {
                        deleteAttachment(attachment.id);
                      }}
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Attachment>
                ))}
              </div>
            </FilesUploader>
          ) : null}
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={onBack}
        handleNext={onNext}
        step={step}
        totalStep={totalStep}
      />
    </>
  );
};

export default StepSPIVAttachments;
