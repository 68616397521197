import React, {FC, useContext, useEffect, useState, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {AxiosResponse} from 'axios';
import * as Yup from 'yup';
import {useFormikContext} from 'formik';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isPast from 'date-fns/isPast';
import add from 'date-fns/add';
import {
  DateType,
  daysAfterTodayInTZ,
  daysBeforeTodayInTZ,
  daysBetweenInTZ,
  combineUtcDateTime,
  isValidDate,
  formatDate,
} from '../../utils/DateUtils';
import {post, api, put} from '../../utils/Request';
import LoadingContext from '../../app/LoadingContext';
import {alertService, AlertType, defaultAlertId} from '../../app/AlertService';
import config from '../../app/Config';
import Consts from '../../app/Consts';
import {useAppSelector} from '../../app/store';
import {selectLoggedInStaffCode, selectConfigsData} from '../../app/selectors';
import {
  Config,
  CreateEditMixAndMatchInfoRequest,
  Department,
  InitialMixAndMatchFormValues,
  MixAndMatchFormBag,
  EntityActionType,
  ValidMixAndMatchFormValues,
  MixAndMatchInfoResponse,
} from '../../types';
import InputField from '../Form/InputField';
import FieldLabel from '../Form/FieldLabel';
import FieldGrid from '../Form/FieldGrid';
import LocationField from '../Form/LocationField';
import DatePickerField from '../Form/DatePickerField';
import FormStep from '../Form/FormStep';
import StepperFormActionSection from '../Form/StepperFormActionSection';
import DepartmentMultipleSearch from '../Form/DepartmentMultipleSearch';
import TimeField from '../Form/TimeField';
import {SaveActionButton, EditActionButton} from '../Table';
import {ExpandButton} from '../Button';
import ActiveClaimWarning from '../Claim/ActiveClaimWarning';
import {ErrorBox} from '../Alert';
import WebPromoTag from './WebPromoTag';
import {mnmDisabled} from './mixAndMatchUtils';

const PREFIX = 'StepMixAndMatchDetails';

const classes = {
  hidden: `${PREFIX}-hidden`,
};

const Root = styled('div')(() => ({
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
}));

export const noFutureStartAtValidationSchema = (
  configs: Config | undefined,
  startTime: InitialMixAndMatchFormValues['startTime']
) =>
  Yup.object().shape({
    startAt: Yup.date().test('future start check', function (value) {
      if (value && isAfter(combineUtcDateTime(value, startTime), new Date())) {
        return this.createError({
          message: `The start date (${formatDate(value)}) cannot be set in the future`,
        });
      }
      return true;
    }),
  });

export const noFutureStartTimeValidationSchema = (
  startAt: InitialMixAndMatchFormValues['startAt']
) =>
  Yup.object().shape({
    startTime: Yup.date()
      .nullable()
      .typeError(Consts.ValidationMessage.Time.FormatError)
      .test('start-time-validation', 'Start time cannot be in the future', (value) => {
        if (startAt) {
          const combinedStartDateTime = combineUtcDateTime(startAt, value);
          if (isValidDate(combinedStartDateTime)) {
            return isBefore(combinedStartDateTime, new Date());
          }
        }
        return true;
      }),
  });

type Props = {
  step: number;
  title: string;
  validationSchema?: any;
  totalStep: number;
  onBack: (values: InitialMixAndMatchFormValues, bag: MixAndMatchFormBag) => void;
  onNext: (values: InitialMixAndMatchFormValues, bag: MixAndMatchFormBag) => void;
  style?: React.CSSProperties;
};

const StepMixAndMatchDetails: FC<Props> = ({step, title, totalStep, onBack, onNext}) => {
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const bag = useFormikContext<InitialMixAndMatchFormValues>();
  const initialStartDate = useRef<Date>();

  const {
    setFieldValue,
    values: {
      claimingStatus,
      endAt,
      endTime,
      hasActiveClaim,
      hasRaisedClaim,
      id,
      initialEndDateTime,
      isStartTimeSpecified,
      isEndTimeSpecified,
      receiptDescription,
      startAt,
      startTime,
    },
    errors: {startAt: startAtError, endAt: endAtError},
    handleBlur,
  } = bag;
  const [departments, setDepartments] = useState<Department[]>([]);
  const [showTimePicker, setShowTimePicker] = useState<boolean>(!!startTime || !!endTime);
  const [showNoFutureStartInfo, setShowNoFutureStartInfo] = useState(false);
  const [showNoFutureStartError, setShowNoFutureStartError] = useState(false);
  const [receiptDescriptionDisabled, setReceiptDescriptionDisabled] = useState(true);
  const loggedInStaffCode = useAppSelector(selectLoggedInStaffCode);
  const configs = useAppSelector(selectConfigsData);

  const {
    fields: disabledFields,
    actions: {disableUpdateOnNextStepOne},
  } = mnmDisabled(bag.values);
  const showStartDateDaysBeforeWarning =
    startAt && daysBeforeTodayInTZ(startAt) >= config.dealStartDateWarningDaysBeforeToday;
  const showStartDateDaysAfterWarning =
    startAt && daysAfterTodayInTZ(startAt) >= config.dealStartDateWarningDaysAfterToday;
  const showDateRangeWarning =
    startAt && endAt && daysBetweenInTZ(endAt, startAt) > config.dealDateRangeWarningDaysBetween;
  const mixAndMatchInProgress = claimingStatus === Consts.AgreementStatusEnum.InProgress;

  const startInPast = Boolean(initialStartDate.current && isPast(initialStartDate.current));
  const noFutureStartCriteriaMet =
    startInPast && claimingStatus === Consts.AgreementStatusEnum.InProgress;

  const selectedDepartments =
    departments?.filter((x: Department) => bag.values.departments?.includes(x.number)) ?? [];

  const minEndDate = mixAndMatchInProgress
    ? new Date().toISOString()
    : !isValidDate(startAt)
    ? undefined
    : combineUtcDateTime(new Date(startAt as Date | string), startTime, true).toISOString();
  const minStartDate = new Date().toISOString();

  useEffect(() => {
    if (
      [startAtError, endAtError].includes(Consts.ValidationMessage.Date.MinimumTenMinutesFromNow)
    ) {
      setShowTimePicker(true);
    } else {
      setShowTimePicker(!!isStartTimeSpecified || !!isEndTimeSpecified);
    }
  }, [startAtError, endAtError, isStartTimeSpecified, isEndTimeSpecified]);

  useEffect(() => {
    setFieldValue('isStartTimeSpecified', isValidDate(startTime));
  }, [startTime, setFieldValue]);

  useEffect(() => {
    setFieldValue('isEndTimeSpecified', isValidDate(endTime));
  }, [endTime, setFieldValue]);

  useEffect(() => {
    if (!initialStartDate.current && startAt) {
      initialStartDate.current = combineUtcDateTime(startAt, startTime);
    }
  }, [startAt, startTime]);

  useEffect(() => {
    if (!isValidDate(initialEndDateTime) && isValidDate(endAt)) {
      setFieldValue(
        'initialEndDateTime',
        combineUtcDateTime(endAt as string, endTime, true).toISOString()
      );
    }
  }, [endAt, endTime, initialEndDateTime, setFieldValue]);

  useEffect(() => {
    if (startAt) {
      const combinedStartAt = combineUtcDateTime(startAt, startTime);
      if (isValidDate(combinedStartAt)) {
        const showError = noFutureStartCriteriaMet && isAfter(combinedStartAt, new Date());
        setShowNoFutureStartError(showError);
        if (endAt) {
          const combinedEndAt = combineUtcDateTime(endAt, endTime, true);
          if (isValidDate(combinedEndAt)) {
            setShowNoFutureStartInfo(noFutureStartCriteriaMet);
          }
        }
      }
    }
  }, [noFutureStartCriteriaMet, startAt, startTime, endAt, endTime, claimingStatus]);

  useEffect(() => {
    let hasId = id && id > 0;
    if (!hasId) {
      setFieldValue('ownedByStaffCode', loggedInStaffCode);
    }
  }, [setFieldValue, loggedInStaffCode, id]);

  const createOrEditMixAndMatch = async (
    values: ValidMixAndMatchFormValues,
    bag: MixAndMatchFormBag
  ) => {
    showLoading();
    const startAt = combineUtcDateTime(values.startAt, values.startTime).toISOString();
    const endAt = combineUtcDateTime(values.endAt, values.endTime, true).toISOString();
    const departments = bag.values.departments?.filter((x) => x !== undefined);

    if (values.id && values.id > 0) {
      const requestData: Omit<CreateEditMixAndMatchInfoRequest, 'createdByStaffCode'> = {
        departments,
        description: values.description,
        endAt,
        isEndTimeSpecified: !!values.endTime,
        isStartTimeSpecified: !!values.startTime,
        locationCriteria: values.locationCriteria,
        receiptDescription: values.receiptDescription,
        startAt,
      };
      try {
        alertService.clear(defaultAlertId);
        if (!disableUpdateOnNextStepOne) {
          const response: AxiosResponse<MixAndMatchInfoResponse> = await put(
            api(Consts.Api.MixAndMatchInfo.replace(':id', `${values.id}`)),
            requestData
          );
          const initialEndDateTime = response.data.endAt;
          setFieldValue('initialEndDateTime', initialEndDateTime);
          onNext({...values, ...response.data, initialEndDateTime}, bag);
        } else {
          onNext(values, bag);
        }
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    } else {
      const requestData: CreateEditMixAndMatchInfoRequest = {
        departments,
        description: values.description,
        endAt,
        isEndTimeSpecified: !!values.endTime,
        isStartTimeSpecified: !!values.startTime,
        locationCriteria: values.locationCriteria,
        receiptDescription: values.receiptDescription,
        startAt,
      };

      try {
        const response: AxiosResponse<MixAndMatchInfoResponse> = await post(
          api(Consts.Api.MixAndMatchCreate),
          requestData
        );
        setFieldValue('id', response.data.id);
        alertService.clear(defaultAlertId);
        onNext({...values, ...response.data}, bag);
      } catch (error: any) {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      } finally {
        hideLoading();
      }
    }
  };

  const handleLoadDepartments = (data: Department[]) => {
    if (departments?.length === 0) {
      setDepartments(data);
    }
  };

  const handleSelectDepartments = (_event: any, options: Department[]) => {
    setFieldValue(
      'departments',
      options.map((x) => x.number)
    );
  };

  const handleDescriptionBlur = (e: any) => {
    handleBlur(e);
    const description = e.target.value;
    if (!receiptDescription) {
      setFieldValue('receiptDescription', description.slice(0, 40));
    }
  };

  const handleSaveReceiptDescription = () => {
    handleBlur({target: {name: 'receiptDescription', value: receiptDescription}});
    setReceiptDescriptionDisabled(true);
  };

  return (
    <Root>
      <FormStep step={step} title={title}>
        <ActiveClaimWarning
          entityActionType={EntityActionType.MixAndMatch}
          hasActiveClaim={hasActiveClaim}
          hasRaisedClaim={hasRaisedClaim}
        />
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="description">Mix & Match Description</FieldLabel>
          <InputField
            id="description"
            name="description"
            fullWidth
            placeholder="Add your description here"
            disabled={disabledFields.description}
            onBlur={handleDescriptionBlur}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel
            htmlFor="receiptDescription"
            sx={{'span:first-of-type': {fontWeight: 'normal', marginLeft: '0.75rem'}}}
          >
            Receipt Description
            <span>(*This description will be visible on the receipt. 40 character limit)</span>
          </FieldLabel>
          <Stack direction="row" spacing={1}>
            <InputField
              id="receiptDescription"
              name="receiptDescription"
              fullWidth
              placeholder="Add receipt description here"
              disabled={disabledFields.receiptDescription || receiptDescriptionDisabled}
            />
            {receiptDescriptionDisabled ? (
              <EditActionButton
                style={{width: '3rem', height: '3rem'}}
                onClick={() => setReceiptDescriptionDisabled(false)}
                disabled={disabledFields.receiptDescription}
                data-testid="edit-receipt-description"
              />
            ) : (
              <SaveActionButton
                style={{width: '3rem', height: '3rem'}}
                onClick={handleSaveReceiptDescription}
                data-testid="save-receipt-description"
              />
            )}
          </Stack>
          <Box sx={{paddingTop: '0.5rem'}}>
            <WebPromoTag />
          </Box>
        </FieldGrid>
        <FieldGrid item xs={12}>
          <FieldLabel htmlFor="departments">Departments</FieldLabel>
          <DepartmentMultipleSearch
            value={selectedDepartments}
            onChange={handleSelectDepartments}
            onDataLoad={handleLoadDepartments}
            name="departments"
            errorText={bag.touched.departments ? bag.errors.departments : undefined}
            disabled={disabledFields.departments}
          />
        </FieldGrid>
        <FieldGrid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FieldLabel htmlFor="startAt" fullWidth>
                Start Date
              </FieldLabel>
              <DatePickerField
                dateType={DateType.RangeStart}
                id="startAt"
                name="startAt"
                minDate={minStartDate}
                maxDate={
                  noFutureStartCriteriaMet ? add(new Date(), {days: -1}).toISOString() : undefined
                }
                placeholder="Type or choose a start date"
                validationSchema={
                  noFutureStartCriteriaMet
                    ? noFutureStartAtValidationSchema(configs, startTime)
                    : undefined
                }
                fullWidth
                disabled={disabledFields.startAt}
              />
            </Grid>
            <Grid item xs={6}>
              <FieldLabel htmlFor="endAt" fullWidth>
                End Date
              </FieldLabel>
              <DatePickerField
                dateType={DateType.RangeEnd}
                id="endAt"
                name="endAt"
                placeholder="Type or choose an end date"
                maxDate={Consts.Date.MaxEndDateMonthsFromNow}
                minDate={minEndDate}
                fullWidth
                disabled={disabledFields.endAt}
              />
            </Grid>
          </Grid>
          {showNoFutureStartError ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Error} data-testid="no-future-start-error">
                <div>
                  Sorry, you cannot set the start date or time in the future after a mix & match has
                  started, please pick another date to continue. If this mix & match needs to end,
                  please delete
                </div>
              </ErrorBox>
            </Grid>
          ) : null}
          {showStartDateDaysAfterWarning ||
          showStartDateDaysBeforeWarning ||
          showDateRangeWarning ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Warning}>
                {showStartDateDaysBeforeWarning ? (
                  <div>
                    The start date is equal to or more than{' '}
                    {config.dealStartDateWarningDaysBeforeToday} days before today.
                  </div>
                ) : null}
                {showStartDateDaysAfterWarning ? (
                  <div>
                    The start date is equal to or more than{' '}
                    {config.dealStartDateWarningDaysBeforeToday} days after today.
                  </div>
                ) : null}
                {showDateRangeWarning ? (
                  <div>
                    The difference between start date and end date is greater than{' '}
                    {config.dealDateRangeWarningDaysBetween} days.
                  </div>
                ) : null}
              </ErrorBox>
            </Grid>
          ) : null}
          {!showNoFutureStartError && showNoFutureStartInfo ? (
            <Grid item xl={12}>
              <ErrorBox type={AlertType.Info} data-testid="no-future-start-info">
                <div>
                  Start date or time in the future cannot be selected after a mix & match has
                  started, please pick a date to continue. If this mix & match needs to end, please
                  delete.
                </div>
              </ErrorBox>
            </Grid>
          ) : null}
          <ExpandButton
            onClick={() => setShowTimePicker(true)}
            className={showTimePicker ? classes.hidden : ''}
            sx={{paddingLeft: 0}}
            disabled={disabledFields.startTime && disabledFields.endTime}
          >
            Add a start time and / or end time
          </ExpandButton>
        </FieldGrid>
        {showTimePicker ? (
          <Grid container spacing={1}>
            <FieldGrid item xs={6}>
              <FieldLabel htmlFor="startTime" fullWidth>
                Start Time
              </FieldLabel>
              <TimeField
                id="startTime"
                name="startTime"
                placeholder="Enter a start time"
                fullWidth
                validationSchema={
                  noFutureStartCriteriaMet ? noFutureStartTimeValidationSchema(startAt) : undefined
                }
                disabled={disabledFields.startTime}
              />
            </FieldGrid>
            <FieldGrid item xs={6}>
              <FieldLabel htmlFor="endTime" fullWidth>
                End Time
              </FieldLabel>
              <TimeField
                id="endTime"
                name="endTime"
                placeholder="Enter an end time"
                fullWidth
                disabled={disabledFields.endTime}
                errorText={bag.errors.endTime}
              />
            </FieldGrid>
          </Grid>
        ) : null}
        <FieldGrid item xs={12}>
          <FieldLabel>Location Assignment</FieldLabel>
          <LocationField
            id="locationCriteria"
            name="locationCriteria"
            disabled={disabledFields.locationCriteria}
          />
        </FieldGrid>
      </FormStep>
      <StepperFormActionSection
        handleBack={onBack}
        handleNext={createOrEditMixAndMatch}
        step={step}
        totalStep={totalStep}
      />
    </Root>
  );
};

export default StepMixAndMatchDetails;
