const getErrorPaths = (errors: any): string[] => {
  const touchedFields: string[] = [];

  const traverseErrors = (error: any, path: string) => {
    if (Array.isArray(error)) {
      error.forEach((item, index) => {
        if (item) {
          traverseErrors(item, `${path}[${index}]`);
        }
      });
    } else if (typeof error === 'object' && error !== null) {
      Object.keys(error).forEach((key) => {
        const fieldPath = `${path}.${key}`;
        traverseErrors(error[key], fieldPath);
      });
    } else {
      touchedFields.push(path.replace('.', ''));
    }
  };

  traverseErrors(errors, '');

  return touchedFields;
};

const getTouchedPaths = (touched: any, path?: string) => {
  let touchedPaths: string[] = [];

  for (const key in touched) {
    if (touched.hasOwnProperty(key)) {
      const newPath = path ? `${path}.${key}` : key;

      if (typeof touched[key] === 'object' && touched[key] !== null) {
        if (Array.isArray(touched[key])) {
          // eslint-disable-next-line no-loop-func
          touched[key].forEach((item: any, index: number) => {
            const arrayPath = `${newPath}[${index}]`;
            touchedPaths = touchedPaths.concat(getTouchedPaths(item, arrayPath));
          });
        } else {
          touchedPaths = touchedPaths.concat(getTouchedPaths(touched[key], newPath));
        }
      } else if (touched[key] === true) {
        touchedPaths.push(newPath);
      }
    }
  }
  return touchedPaths;
};

// required for uncontrolled nested fields where the field path is not equal to the field name
export const getTouchedErrors = (errors: any, touched: any): string[] => {
  const errorFields = getErrorPaths(errors);
  const touchedFields = getTouchedPaths(touched);
  return errorFields.filter((field) => touchedFields.includes(field));
};
