import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {AxiosResponse} from 'axios';
import isAfter from 'date-fns/isAfter';
import {alertService, defaultAlertId} from '../../../app/AlertService';
import Consts from '../../../app/Consts';
import LoadingContext from '../../../app/LoadingContext';
import {useAppSelector} from '../../../app/store';
import {selectConfigsData} from '../../../app/selectors';
import {toStartOfTZDayUtc, toEndOfTZDayUtc} from '../../../utils/DateUtils';
import {api, get} from '../../../utils/Request';
import {DealAgreementResponse, Config, DealFormValues} from '../../../types';
import {PageContainer, PageContentContainer} from '../../../components/Container';
import DealForm from '../../../components/DealForm/DealForm';
import {ITSupportLink} from '../../../components/Link';

const EditDeal = () => {
  // configs are added to form values so they can be accessed as this.parent in the validation schema
  const configs = useAppSelector(selectConfigsData) as Config;
  let {id = ''} = useParams<{id: string}>();
  const [initialValues, setInitialValues] = useState<DealFormValues | null>(null);
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const updateFormValues = useCallback(
    (data: DealAgreementResponse) => {
      setInitialValues({
        id: parseInt(id),
        claimVendor: {
          code: data.claimVendorCode,
          name: data.claimVendorName,
          isActive: data.claimVendorActive,
        },
        DealCutoffDate: configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs],
        DealLockDate: configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs],
        claimDaysAfterExpiry: 0,
        claimInterval: Consts.DealClaimIntervalEnum.EndOfRebate,
        claimVendorSuppliers: data.claimVendorSuppliers,
        comment: '',
        dealType: data.dealType,
        dealValueCount: data.dealValueCount,
        dealValues: [],
        departmentNumber: data.departmentNumber ? data.departmentNumber : null,
        departments: [],
        description: data.description,
        draftMode: data.status === Consts.AgreementStatusEnum.Draft,
        endAt: data.endTimeSpecified ? toEndOfTZDayUtc(data.endAt).toISOString() : data.endAt,
        endTime: data.endTimeSpecified ? data.endAt : null,
        externalPromotionIds: '',
        financeAccountCleared: false,
        generatePricebookUpdate: data.generatePricebookUpdate,
        hasActiveClaim: data.hasActiveClaim,
        hasRaisedClaim: data.hasRaisedClaim,
        isClaimVendorGstFree: false,
        isFinalClaimRaised: data.isFinalClaimRaised,
        locationCriteria: data.locationCriteria,
        merchandisingPurchaseOrderNumber: null,
        ownedByStaffCode: data.ownedByStaffCode,
        promotionId: data.promotionId,
        splitAccountAmountType: Consts.SplitAccountAmountTypeEnum.Percentage,
        splitAccounts: [],
        startAt: data.startTimeSpecified
          ? toStartOfTZDayUtc(data.startAt).toISOString()
          : data.startAt,
        startTime: data.startTimeSpecified ? data.startAt : null,
        status: data.status,
        supplierApprovalNumber: '',
        suppliers: [],
        totalAmount: 0,
      });
    },
    [configs, id]
  );

  function isLocked(initialValues: DealFormValues | null) {
    if (!configs || !initialValues) {
      return false;
    }
    if (initialValues.status === Consts.AgreementStatusEnum.Draft) {
      return false;
    }
    const cutoffDate = configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs];
    const lockDate = configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs];
    return (
      cutoffDate &&
      lockDate &&
      initialValues.endAt &&
      isAfter(new Date(), new Date(lockDate)) &&
      isAfter(new Date(cutoffDate), new Date(initialValues.endAt))
    );
  }

  useEffect(() => {
    showLoading();
    get(api(Consts.Api.DealAgreement.replace(':id', id)))
      .then((response: AxiosResponse<DealAgreementResponse>) => {
        alertService.clear(defaultAlertId);
        updateFormValues(response.data);
      })
      .catch((error) => {
        alertService.alert({
          ...{message: error.message, response: error.response},
          id: defaultAlertId,
        });
      })
      .finally(() => {
        hideLoading();
      });
  }, [showLoading, hideLoading, id, updateFormValues]);

  if (isLocked(initialValues)) {
    return (
      <PageContainer>
        <PageContentContainer>
          The deal is locked and cannot be edited anymore. If you want to unlock it, please contact{' '}
          {Consts.ItSupport} at <ITSupportLink />
        </PageContentContainer>
      </PageContainer>
    );
  }
  return initialValues ? <DealForm title="Edit Deal" initialValues={initialValues} /> : null;
};

export default EditDeal;
