import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import {useFormikContext} from 'formik';
import {InitialMixAndMatchFormValues} from '../../types';

const WebPromoTag: FC = () => {
  const bag = useFormikContext<InitialMixAndMatchFormValues>();
  const webPromoText = bag.values.receiptDescription?.split(':')[1];
  if (!webPromoText) {
    return null;
  }
  return (
    <Typography>
      ("{<strong>{webPromoText}</strong>}" will appear on the website as a tag on the PDP pages.)
    </Typography>
  );
};

export default WebPromoTag;
