import React, {FC, useContext, useEffect, useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Consts from '../../app/Consts';
import LoadingContext from '../../app/LoadingContext';
import {alertService, defaultAlertId} from '../../app/AlertService';
import {InitialMixAndMatchFormValues, ValidMixAndMatchFormValues} from '../../types';
import {api, put} from '../../utils/Request';
import StepperForm from '../Form/StepperForm';
import {
  stepMixAndMatchDetailsValidationSchema,
  stepMixAndMatchValuesValidationSchema,
  stepMixAndMatchFinanceDetailsValidationSchema,
} from './validationSchema';
import StepMixAndMatchDetails from './StepMixAndMatchDetails';
import StepMixAndMatchValues from './StepMixAndMatchValues';
import StepMixAndMatchFinanceDetails from './StepMixAndMatchFinanceDetails';

const formSteps = ['Mix & Match Details', 'Add Mix & Match Values', 'Finance Details'];
const breakPoint = 1840;
const StyledStepperForm = styled(StepperForm)(({theme}) => ({
  '&.PageContentContainer-root': {
    [theme.breakpoints.up(breakPoint)]: {
      width: '94rem',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    minWidth: '80rem',
    width: 'auto',
    display: 'inline-block',
    paddingLeft: '4.5rem',
    paddingRight: '4.5rem',
    marginLeft: '2.25rem',
    marginRight: '2.25rem',
  },
}));

type Props = {
  title: string;
  initialValues: InitialMixAndMatchFormValues;
  indexOverride?: number;
};

const MixAndMatchForm: FC<Props> = ({title, initialValues, indexOverride}) => {
  const navigate = useNavigate();
  const {showLoading, hideLoading} = useContext(LoadingContext);
  const [creationFinished, setCreationFinished] = useState(false);
  const [dealId, setDealId] = useState<number>();

  useEffect(() => {
    // true once, after deal finalisation
    if (dealId && creationFinished) {
      navigate(Consts.RouterPath.MixAndMatchSummary.replace(':id', `${dealId}`));
    }
  }, [dealId, creationFinished, navigate]);

  // validationSchema props are passed to Formik via getStepContent props and StepperForm
  // not via Step form components
  const getStepContent = (
    index: number,
    handleBack: (values: InitialMixAndMatchFormValues) => void,
    handleNext: (values: InitialMixAndMatchFormValues) => void
  ) => {
    const contentIndex = indexOverride ?? index;
    switch (contentIndex) {
      case 0:
        return (
          <StepMixAndMatchDetails
            step={1}
            title="Mix & Match Details"
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
            validationSchema={stepMixAndMatchDetailsValidationSchema}
          />
        );
      case 1:
        return (
          <StepMixAndMatchValues
            step={2}
            title="Add Mix & Match Values"
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
            validationSchema={stepMixAndMatchValuesValidationSchema}
            style={{minWidth: '64rem', width: '80rem'}}
          />
        );
      case 2:
        return (
          <StepMixAndMatchFinanceDetails
            step={3}
            title="Finance Details"
            totalStep={formSteps.length}
            onBack={handleBack}
            onNext={handleNext}
            validationSchema={stepMixAndMatchFinanceDetailsValidationSchema}
          />
        );
      default:
        return null;
    }
  };

  const finishMixAndMatchCreation = async (id: number) => {
    try {
      showLoading();
      await put(api(Consts.Api.MixAndMatchFinishCreation.replace(':id', `${id}`)));
      setCreationFinished(true);
      setDealId(id);
      alertService.clear(defaultAlertId);
    } catch (error: any) {
      alertService.alert({
        ...{message: error.message, response: error.response},
        id: defaultAlertId,
      });
    } finally {
      hideLoading();
    }
  };

  const handleSubmit = async (values: ValidMixAndMatchFormValues) => {
    if (!values.draftMode) {
      await finishMixAndMatchCreation(values.id);
    } else {
      navigate(Consts.RouterPath.MixAndMatchSummary.replace(':id', `${values.id}`));
    }
  };

  const handleShowRouteChangeMessage = useCallback(
    (values: InitialMixAndMatchFormValues): boolean =>
      !creationFinished &&
      !!values?.id &&
      values?.claimingStatus === Consts.AgreementStatusEnum.Draft,
    [creationFinished]
  );

  return (
    <StyledStepperForm
      title={title}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      steps={formSteps}
      getStepContent={getStepContent}
      routeChangeMessage="Your mix & match has been saved to My Drafts"
      showRouteChangeMessage={handleShowRouteChangeMessage}
      indexOverride={indexOverride}
    />
  );
};

export default MixAndMatchForm;
