import React, {FC, useEffect} from 'react';
import Stack from '@mui/material/Stack';
import {useField} from 'formik';
import Consts from '../../../../app/Consts';
import config from '../../../../app/Config';
import {isNullish, getDisplayAmountValue} from '../../../../utils';
import {TableCellInputField} from '../../../Table';
import NumberFormatInput, {PercentageFormatField} from '../../../NumberFormatInput';
import {CustomAutoComplete} from '../../../CustomAutoComplete';
import Label from './Label';

type Props = {
  onChange: (name: string, value: string) => void;
  detailKey: string;
  disabled?: boolean;
};

const ClaimAmountField: FC<Props> = ({onChange, detailKey, disabled}) => {
  const [{value: claimAmount, ...claimAmountFieldProps}, claimAmountMeta] = useField(
    `${detailKey}.claimAmount`
  );
  const [{value: claimAmountType}, claimAmountTypeMeta, {setValue: setClaimAmountTypeValue}] =
    useField(`${detailKey}.claimAmountType`);

  const {touched: claimAmountTouched, error: claimAmountError} = claimAmountMeta;
  const {touched: claimAmountTypeTouched, error: claimAmountTypeError} = claimAmountTypeMeta;

  useEffect(() => {
    if (isNullish(claimAmountType)) {
      setClaimAmountTypeValue(Consts.AmountTypeEnum.ValuePerUnit);
    }
  }, [claimAmountType, setClaimAmountTypeValue]);

  const getAmountWarning = () => {
    if (
      claimAmountType === Consts.AmountTypeEnum.Percentage &&
      claimAmount &&
      claimAmount > config.dealPercentageAmountWarningThreshold
    ) {
      return `The value is greater than ${config.dealPercentageAmountWarningThreshold}%`;
    }
    if (
      claimAmountType === Consts.AmountTypeEnum.ValuePerUnit &&
      claimAmount &&
      claimAmount > config.dealDollarAmountWarningThreshold
    ) {
      return `The value is greater than ${getDisplayAmountValue(
        config.dealDollarAmountWarningThreshold,
        '$'
      )}`;
    }
  };
  const showError = claimAmountTouched && claimAmountError;
  const showWarning =
    (claimAmountType === Consts.AmountTypeEnum.Percentage &&
      !!claimAmount &&
      claimAmount > config.dealPercentageAmountWarningThreshold) ||
    (claimAmountType === Consts.AmountTypeEnum.ValuePerUnit &&
      !!claimAmount &&
      claimAmount > config.dealDollarAmountWarningThreshold);
  const helperText = showError ? claimAmountError : showWarning ? getAmountWarning() : undefined;
  const amountTypeErrorText =
    claimAmountTypeTouched && claimAmountTypeError ? claimAmountTypeError : undefined;

  const digits = claimAmountType === Consts.AmountTypeEnum.Percentage ? 0 : 2;
  const regex = digits ? /^(?!\.)(-?[\d,]*(?:\.\d{0,2})?)?$/ : /^(?!\.)(-?[\d,]*)?$/;

  const handleAmountTypeChange = (
    _: React.SyntheticEvent,
    option: {label: string; value: string} | null
  ) => {
    onChange(`${detailKey}.claimAmountType`, option?.value ?? '');
  };

  const Input =
    claimAmountType === Consts.AmountTypeEnum.Percentage
      ? PercentageFormatField
      : NumberFormatInput;

  return (
    <Stack spacing={1}>
      <Label htmlFor="claimAmount">Claim Amount</Label>
      <Stack sx={{display: 'flex', alignItems: 'flex-start', flexDirection: 'row'}}>
        <CustomAutoComplete
          fullWidth
          value={Consts.ClaimAmountType.find((item) => item.value === claimAmountType) ?? null}
          options={Consts.ClaimAmountType}
          onChange={handleAmountTypeChange}
          id={`${detailKey}.claimAmountType`}
          sx={{
            '&.MuiAutocomplete-root': {
              width: '7rem',
            },
          }}
          disableClearable
          errorText={amountTypeErrorText}
          disabled={disabled}
        />
        <TableCellInputField
          error={!!claimAmountError && !!claimAmountTouched}
          warning={!showError && showWarning}
          helperText={helperText}
          placeholder="Add value"
          regexValidation={regex}
          digits={digits}
          sx={{width: '14rem'}}
          value={claimAmount ?? ''}
          {...claimAmountFieldProps}
          InputProps={{
            inputComponent: Input as any,
          }}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};

export default ClaimAmountField;
