import React, {FC} from 'react';
import Alert, {AlertProps} from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import {AlertType} from '../../app/AlertService';

type Props = AlertProps & {
  alertTitle?: string;
  type?: string;
  children?: React.ReactNode;
};
const ErrorBox: FC<Props> = ({children, alertTitle = null, type, ...rest}) => {
  const getIcon = (type?: string): React.ReactNode => {
    switch (type) {
      case AlertType.Error:
        return <CancelIcon />;
      case AlertType.Warning:
        return <ErrorIcon />;
      default:
        return null;
    }
  };

  const getSeverity = (type?: string) => {
    switch (type) {
      case AlertType.Error:
        return 'error';
      case AlertType.Info:
        return 'info';
      case AlertType.Success:
        return 'success';
      default:
        return 'warning';
    }
  };

  return (
    <Alert severity={getSeverity(type)} icon={getIcon(type)} {...rest}>
      {alertTitle ? <AlertTitle>{alertTitle}</AlertTitle> : null}
      {children}
    </Alert>
  );
};

export default ErrorBox;
