import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import type {ReactPlugin as ReactPluginType} from '@microsoft/applicationinsights-react-js';
import type {
  ApplicationInsights as ApplicationInsightsType,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';

let reactPlugin: ReactPluginType | null = null;
let appInsights: ApplicationInsightsType | null = null;

type TelemetryService = {
  reactPlugin: ReactPluginType | null;
  appInsights: ApplicationInsightsType | null;
  initialize: () => void;
};

const createTelemetryService = (): TelemetryService => {
  const initialize = (): void => {
    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        disableFetchTracking: false,
        extensions: [reactPlugin],
        enableAutoRouteTracking: false,
        enableDebug: process.env.REACT_APP_REBATES_ENV === 'development',
        connectionString:
          process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING ||
          "InstrumentationKey='';IngestionEndpoint='';LiveEndpoint=''",
      },
    });

    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
      env.tags = env.tags || [];
      env.tags['ai.application.ver'] = process.env.REACT_APP_AI_APPLICATION_VERSION;
      env.tags['ai.cloud.role'] = 'rebates-ui';
    });
  };

  return {reactPlugin, appInsights, initialize};
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
