import React from 'react';
import {styled} from '@mui/material/styles';

const StyledSpan = styled('span')`
  color: ${({theme}) => theme.palette.gray.dark};
  font-weight: normal;
`;

const OptionalIndicator = (props) => {
  return <StyledSpan {...props}>(Optional)</StyledSpan>;
};

export default OptionalIndicator;
