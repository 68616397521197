import React, {FC} from 'react';
import {styled} from '@mui/material/styles';
import Button, {ButtonProps} from '@mui/material/Button';

const StyledButton = styled(Button)`
  color: ${({theme}) => theme.palette.expandHint.main};
  font-size: 1rem;
  font-weight: unset;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: unset;
  &:hover {
    cursor: pointer;
    background-color: unset;
  }
`;

type Props = {
  children: React.ReactNode;
} & ButtonProps;

const StaticButton: FC<Props> = ({children, ...otherProps}) => (
  <StyledButton disableRipple {...otherProps}>
    {children}
  </StyledButton>
);

export default StaticButton;
