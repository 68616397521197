import React, {FC} from 'react';
import {ConfirmModal} from '../Modal';

const styles = {
  okButton: {
    width: '255px',
  },
  cancelButton: {
    width: '255px',
  },
  root: {
    width: '520px',
  },
};

type Props = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};

const EditDealConfirmModal: FC<Props> = ({open, onOk, onCancel}) => (
  <ConfirmModal
    open={open}
    onOk={onOk}
    onCancel={onCancel}
    okText="Go to deal and edit"
    cancelText="Go back"
    styles={styles}
  >
    <div>
      <div>
        To edit the claim amount for a deal you need to change the original deal. (e.g. add a $
        amount cap)
      </div>
    </div>
  </ConfirmModal>
);

export default EditDealConfirmModal;
