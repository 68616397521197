import React, {FC} from 'react';
import {ConfirmModal} from '../Modal';

type Props = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  title?: string;
  body: React.ReactNode;
  okText?: string;
  cancelText?: string;
};

const GroupConfirmModal: FC<Props> = ({open, onOk, onCancel, title, body, okText, cancelText}) => (
  <ConfirmModal
    title={title}
    open={open}
    onOk={onOk}
    okText={okText ?? 'Continue'}
    cancelText={cancelText ?? 'Cancel'}
    onCancel={onCancel}
  >
    {body}
  </ConfirmModal>
);

export default GroupConfirmModal;
