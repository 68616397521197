import React from 'react';
import Consts from '../../../app/Consts';
import {useAppSelector} from '../../../app/store';
import {selectConfigsData} from '../../../app/selectors';
import MixAndMatchForm from '../../../components/MixAndMatchForm';
import {InitialMixAndMatchFormValues} from '../../../types';

const CreateMixAndMatch = () => {
  // configs are added to form values so they can be accessed as this.parent in the validation schema
  const configs = useAppSelector(selectConfigsData);
  if (!configs) {
    return null;
  }
  const initialValues: InitialMixAndMatchFormValues = {
    id: undefined,
    departments: [],
    description: '',
    draftMode: true,
    endAt: undefined,
    endTime: undefined,
    groups: [],
    isEndTimeSpecified: false,
    isStartTimeSpecified: false,
    locationCriteria: null,
    receiptDescription: '',
    startAt: undefined,
    startTime: undefined,
    claimingStatus: Consts.AgreementStatusEnum.Draft,
    type: '',
    DealCutoffDate: configs[Consts.ConfigNameEnum.DealCutoffDate as keyof typeof configs],
    DealLockDate: configs[Consts.ConfigNameEnum.DealLockDate as keyof typeof configs],
  };
  return <MixAndMatchForm title="Add A New Mix & Match" initialValues={initialValues} />;
};

export default CreateMixAndMatch;
