import React from 'react';
import {useAppDispatch, useAppSelector} from '../../../app/store';
import {
  advanceUnreleasedProducts,
  searchUnreleasedProducts,
  SearchUnreleasedProductsRequest,
  toggleShowProductAdvanceModal,
  UnreleasedProductData,
} from '../../../app/unreleasedProductsReducer';
import {AdvanceUnreleasedAmountsRequest} from '../../../app/unreleasedRebatesReducer';
import {selectProductDataCount} from '../../../app/selectors';
import {getDisplayAmountValue} from '../../../utils/AmountUtils';
import {TableColumn} from '../../../types';
import {ConfirmModal} from '../../Modal';
import {SimpleDataTable} from '../../SimpleDataTable';
import Spinner from '../../Spinner/Spinner';
import {advanceModalStyles, selectedProductData} from '../Common';

const columns: TableColumn<UnreleasedProductData>[] = [
  {id: 'productCode', label: 'Product Code'},
  {id: 'productDescription', label: 'Product Description'},
  {id: 'departmentNumber', label: 'Department Number'},
  {id: 'departmentName', label: 'Department Name'},
  {id: 'supplierNumber', label: 'Supplier Number'},
  {id: 'supplierName', label: 'Supplier Name'},
  {id: 'seasonCode', label: 'Season Code'},
  {
    id: 'unreleasedRebate',
    label: 'Unreleased Rebate',
    render: (rowData: UnreleasedProductData) => {
      return getDisplayAmountValue(rowData.unreleasedRebate, '$');
    },
  },
];

function AdvanceProductsModal() {
  const dispatch = useAppDispatch();
  const {
    showAdvanceModal,
    departmentFilters,
    supplierFilter,
    seasonCodeFilter,
    searchText,
    pageSize,
    currentPage,
    orderBy,
    isAdvancing,
  } = useAppSelector((s) => s.unreleasedProducts);
  const selectedRows = useAppSelector(selectedProductData);
  const selectedCount = useAppSelector(selectProductDataCount);

  async function advanceAndSearchProducts() {
    const advanceRequest: AdvanceUnreleasedAmountsRequest = {
      ids: selectedRows.map((x) => x.id),
    };
    await dispatch(advanceUnreleasedProducts(advanceRequest));
    const request: SearchUnreleasedProductsRequest = {
      searchText,
      pageSize,
      currentPage,
      orderBy,
      seasonCode: seasonCodeFilter,
      supplierNumber: supplierFilter?.number ?? null,
      departmentNumbers: departmentFilters.map((x) => x.number),
    };
    dispatch(searchUnreleasedProducts(request));
  }

  return (
    <ConfirmModal
      styles={advanceModalStyles}
      title="Review and Confirm"
      renderSubtitle={() => (
        <>
          <div>The below unreleased amounts ({selectedCount}) are about to be released. </div>
          <b>
            This cannot be undone. Once a Product has been released, no Rebates can be released
            until tomorrow.
          </b>
          <div>Please review and ensure all details are correct.</div>
        </>
      )}
      open={showAdvanceModal}
      onOk={async () => {
        await advanceAndSearchProducts();
      }}
      onCancel={() => {
        dispatch(toggleShowProductAdvanceModal());
      }}
      okText="Proceed"
      cancelText="Go back"
    >
      <Spinner loading={isAdvancing} />
      <div>
        <SimpleDataTable
          columns={columns}
          rows={selectedRows}
          highlightColumn={undefined}
          pagination={undefined}
        />
      </div>
    </ConfirmModal>
  );
}

export default AdvanceProductsModal;
