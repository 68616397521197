import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';
import qs from 'qs';
import {Department} from '../types';
import {api, get, post} from '../utils/Request';
import {alertService} from './AlertService';
import config from './Config';
import Consts from './Consts';
import {Supplier} from './suppliersReducer';
import {AdvanceUnreleasedAmountsRequest} from './unreleasedRebatesReducer';

export enum SeasonCode {
  'A',
  'AW',
  'B',
  'BTO',
  'BYOD',
  'CHART - HO',
  'COM',
  'COMQ',
  'COMW',
  'CTO',
  'D',
  'DEMO',
  'E',
  'EDU',
  'EL',
  'FIRM SALE',
  'M',
  'MIC',
  'MICQ',
  'MISC',
  'M+',
  'MW',
  'MW+',
  'NR',
  'NRV',
  'O',
  'P',
  'Q',
  'QT2',
  'S',
  'S1',
  'T',
  'T1',
  'T2',
  'T3',
  'T4',
  'W',
  'X',
  'Z',
  'Z+',
}
export interface UnreleasedProductData {
  id: string;
  markedForRelease: boolean;
  selected: boolean;
  productCode: number;
  productDescription: string;
  departmentNumber: number;
  departmentName: string;
  supplierNumber: number;
  supplierName: string;
  seasonCode: string;
  unreleasedRebate: number;
}

export interface UnreleasedProductsState {
  data: UnreleasedProductData[];
  isLoading: boolean;
  isAdvancing: boolean;
  showAdvanceModal: boolean;
  searchText: string | null;
  orderBy: string;

  totalCount: number;
  totalPages: number;
  pageSize: number;
  currentPage: number;

  departmentFilters: Department[];
  supplierFilter: Supplier | null;
  seasonCodeFilter: SeasonCode | null;
}

export const initialState: UnreleasedProductsState = {
  data: [],
  isLoading: false,
  isAdvancing: false,
  showAdvanceModal: false,
  searchText: null,
  orderBy: 'unreleasedRebate:desc',

  totalCount: 0,
  totalPages: 0,
  pageSize: 10,
  currentPage: 1,

  departmentFilters: [],
  supplierFilter: null,
  seasonCodeFilter: null,
};

const sliceName = 'unreleasedProducts';

export const advanceUnreleasedProducts = createAsyncThunk(
  `${sliceName}/advanceUnreleasedProducts`,
  async (request: AdvanceUnreleasedAmountsRequest, thunkAPI) => {
    const data = request.ids.map((id) => {
      return {
        id,
        type: 'Product',
      };
    });
    return post(api(Consts.Api.UnreleasedAmountsAdvance + `?entityCode=${config.entityCode}`), {
      data,
    });
  }
);

export interface SearchUnreleasedProductsRequest {
  searchText: string | null;
  pageSize: number;
  currentPage: number;
  orderBy: string;
  seasonCode: SeasonCode | null;
  supplierNumber: number | null;
  departmentNumbers: number[];
}
export interface SearchUnreleasedProductsResponse {
  data: UnreleasedProductData[];
  pageSize: number;
  currentPage: number;
  totalCount: number;
  totalPages: number;
}

export const searchUnreleasedProducts = createAsyncThunk(
  `${sliceName}/searchUnreleasedProducts`,
  async (request: SearchUnreleasedProductsRequest, thunkAPI) => {
    const {
      searchText,
      pageSize,
      seasonCode,
      supplierNumber,
      orderBy,
      currentPage,
      departmentNumbers,
    } = request;
    return get(api(Consts.Api.UnreleasedAmountsProducts), {
      params: {
        searchText,
        orderBy,
        pageSize,
        currentPage,
        seasonCode,
        supplierNumber,
        departmentNumber: departmentNumbers,
      },
      paramsSerializer: (params: any) => {
        const result = qs.stringify(params, {skipNulls: true, arrayFormat: 'repeat'});
        return result;
      },
    }).then((response: AxiosResponse<SearchUnreleasedProductsResponse>) => {
      const {data, ...restOfResponse} = response.data;

      const result: SearchUnreleasedProductsResponse = {
        data: data.map((rebate) => {
          return {...rebate, selected: false};
        }),
        ...restOfResponse,
      };

      return result;
    });
  }
);

const unreleasedProductsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    updateProductSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    updateDepartmentFilter(state, action: PayloadAction<Department[]>) {
      state.departmentFilters = action.payload;
    },
    updateSupplierFilter(state, action: PayloadAction<Supplier | null>) {
      state.supplierFilter = action.payload;
    },
    updateSeasonCodeFilter(state, action: PayloadAction<SeasonCode | null>) {
      state.seasonCodeFilter = action.payload;
    },
    clearProductFilters(state) {
      state.departmentFilters = [];
      state.seasonCodeFilter = null;
      state.supplierFilter = null;
    },
    toggleProductSelected(state, action: PayloadAction<string>) {
      const existingRebate = state.data.filter((x) => x.id === action.payload)[0];
      if (existingRebate) {
        existingRebate.selected = !existingRebate.selected;
      }
    },
    toggleShowProductAdvanceModal(state) {
      state.showAdvanceModal = !state.showAdvanceModal;
    },
    updateProductPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    updateProductCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    updateProductOrderBy(state, action: PayloadAction<string>) {
      state.orderBy = action.payload;
    },
    resetUnreleasedProducts() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUnreleasedProducts.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUnreleasedProducts.fulfilled.type, (state, action: any) => {
        state.data = action.payload.data;
        state.pageSize = action.payload.pageSize;
        state.currentPage = action.payload.currentPage;
        state.totalCount = action.payload.totalCount;
        state.totalPages = action.payload.totalPages;
        state.isLoading = false;
      })
      .addCase(searchUnreleasedProducts.rejected.type, (state, action: any) => {
        state.isLoading = false;
        alertService.error('Failed to search Unreleased Amounts by products.');
      })
      .addCase(advanceUnreleasedProducts.pending.type, (state, action: any) => {
        state.isAdvancing = true;
      })
      .addCase(advanceUnreleasedProducts.fulfilled.type, (state, action: any) => {
        state.isAdvancing = false;
        state.showAdvanceModal = false;
      })
      .addCase(advanceUnreleasedProducts.rejected.type, (state, action: any) => {
        state.isAdvancing = false;
        state.showAdvanceModal = false;
        alertService.error('Failed to Advance Rebate Unreleased Amounts.');
      });
  },
});

const unreleasedProductsReducer = unreleasedProductsSlice.reducer;
export const {
  updateProductSearchText,
  updateDepartmentFilter,
  updateSeasonCodeFilter,
  clearProductFilters,
  updateSupplierFilter,
  toggleProductSelected,
  toggleShowProductAdvanceModal,
  updateProductPageSize,
  updateProductCurrentPage,
  updateProductOrderBy,
  resetUnreleasedProducts,
} = unreleasedProductsSlice.actions;
export default unreleasedProductsReducer;
